import * as ActionTypes from '../ActionTypes';
import axiosInstance, { getInstance } from '../../../network/apis/interceptor';
import { CommonUtils } from '../../../utils/CommonUtils';
import { ApiRelativePaths, RetryApi } from '../../../utils/GlobalConstants';


let ApiPaths: any = ApiRelativePaths;


export const setGoalListData = (goalListData: any) => {
    return {
        type: ActionTypes.SET_GOAL_LIST_DATA,
        goalListData: goalListData
    }
};


export const setGoalData = (goalData: any) => {
    return {
        type: ActionTypes.SET_GOAL_DATA,
        goalData: goalData
    }
};

export const setGoalGuideList = (goalGuideList: any) => {
    return {
        type: ActionTypes.SET_GOAL_GUIDE_LIST,
        goalGuideList: goalGuideList
    }
};

export const setGoalTooltipList = (goalTooltipList: any) => {
    return {
        type: ActionTypes.SET_GOAL_TOOLTIP_LIST,
        goalTooltipList: goalTooltipList
    }
};


export const setGoalTitleCheck = (goalTitleCheck: any) => {
    return {
        type: ActionTypes.SET_CHECK_GOAL_TITLE,
        goalTitleCheck: goalTitleCheck
    }
};

export const getManageGoalData = (params: any, url_path: any, caseType: any, apiErrorFlag: string, firstTimeload?: boolean) => {
    return new Promise((resolve, reject) => {
        let path = ApiPaths[url_path];
        let data:any = {};
        let paramObj = {};
        let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
        getInstance('goal/manage-goal/');

        axiosInstance.get(generatedURL)
            .then(res => {
                data = {
                    "result": "success",
                    "data": res.data.data,
                    "firstTimeload": firstTimeload ?? false,
                    "requestUUID": res.data.requestUUID,
                };

            })
            .catch(err => {
                data = {
                    "result": "error",
                    "data": err,
                    "firstTimeload": firstTimeload ?? false,
                };
            })
            .then(() => {
                const callActionDispatch = () => {
                    switch (caseType) {
                        case ActionTypes.SET_GOAL_LIST_DATA:
                            resolve(setGoalListData(data));
                            break;

                        case ActionTypes.SET_GOAL_DATA:
                            resolve(setGoalData(data));
                            break;

                        case ActionTypes.SET_GOAL_GUIDE_LIST:
                                resolve(setGoalGuideList(data));
                                break;

                        case ActionTypes.SET_CHECK_GOAL_TITLE:
                                resolve(setGoalTitleCheck(data));
                                break;

                        default:
                            break;
                    }
                }

                if (data.result === 'retry') {
                    setTimeout(() => {
                        callActionDispatch();
                    }, RetryApi.TIMEOUT);
                } else {
                    callActionDispatch();
                }

            });
    });
}

export const getManageGoalDataToolTip = (params: any, url_path: any, caseType: any, apiErrorFlag: string, firstTimeload?: boolean) => {
    return new Promise((resolve, reject) => {
        let path = ApiPaths[url_path];
        let data:any = {};
        let paramObj = {};
        let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
        getInstance('goal/manage-goal/');

        axiosInstance.get(generatedURL)
            .then(res => {
                data = {
                    "result": "success",
                    "data": res.data.data,
                    "firstTimeload": firstTimeload ?? false,
                };

            })
            .catch(err => {
                data = {
                    "result": "error",
                    "data": err,
                    "firstTimeload": firstTimeload ?? false,
                };
            })
            .then(() => {
                const callActionDispatch = () => {
                    switch (caseType) {
                        case ActionTypes.SET_GOAL_LIST_DATA:
                            resolve(setGoalListData(data));
                            break;

                        case ActionTypes.SET_GOAL_DATA:
                            resolve(setGoalData(data));
                            break;

                        case ActionTypes.SET_GOAL_GUIDE_LIST:
                                resolve(setGoalGuideList(data));
                                break;

                        case ActionTypes.SET_CHECK_GOAL_TITLE:
                                resolve(setGoalTitleCheck(data));
                                break;
                        
                        case ActionTypes.SET_GOAL_TOOLTIP_LIST:
                                resolve(setGoalTooltipList(data));
                                break;

                        default:
                            break;
                    }
                }

                if (data.result === 'retry') {
                    setTimeout(() => {
                        callActionDispatch();
                    }, RetryApi.TIMEOUT);
                } else {
                    callActionDispatch();
                }

            });
    });
}