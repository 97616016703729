import React from "react";
import "./SectionHeader.scss";

const ContainerTooltip = (props: any) => {
  const { div1Classes, div2Classes, hTagClasses, children } = props;

  if (div1Classes && div2Classes) {
    return (
      <div className={div1Classes}>
        <div className={div2Classes}>
          <h5 className={hTagClasses}>{children}</h5>
        </div>
      </div>
    );
  } else if (div1Classes || div2Classes) {
    return (
      <div className={`${div1Classes || ""} ${div2Classes || ""}`}>
        <h5 className={hTagClasses}>{children}</h5>
      </div>
    );
  } else {
    return <h5 className={hTagClasses}>{children}</h5>;
  }
};

export default ContainerTooltip;
