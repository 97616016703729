import React, { useRef, useState, useEffect, useCallback } from 'react';


import withRouter from "hoc/withRouter";
import featureDetailReducer, { FeatureDetailState } from "store/Reducers/AppInsights/featureDetail";

import { CommonComponentState, LanguageState } from 'store/Reducers';
import { useDispatch, useSelector } from 'react-redux';
import * as ActionTypes from '../../../store/Actions/ActionTypes';
import * as featureDetailActions from '../../../store/Actions/index';
import { withReducer } from "../../../store/withReducer";

import SectionHeader from 'components/Layouts/SectionHeader';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import "./FeatureDetail.scss";

import qs from 'querystring';

import Datepicker from 'components/Calender/Datepicker';
import SectionFilters from 'components/Layouts/SectionFilters';
import SectionButtons from 'components/Layouts/SectionButtons';
import ExportToPdf from 'components/ExportToPdf/ExportToPdf';
import SectionRefresh from 'components/Layouts/SectionRefresh';
import CardContainer from 'components/Layouts/CardContainer';
import ContainerTooltip from 'components/Layouts/ContainerTooltip';
import SectionTooltip from 'components/SectionTooltip/SectionTooltip';
import Loader from 'components/Loader/Loader';
import LineChart from 'components/Chart/nvd3Charts/LineChart';
import { ChartColors } from 'utils/GlobalConstants';
import Heatmap from 'components/Chart/Heatmap';
import BrowserStats from 'components/BrowserStats/BrowserStats';
import { CommonUtils } from 'utils/CommonUtils';
import Label from "components/Label/Label";
import TreeStructure from 'components/TreeStructure/TreeStructure';
import FeatureListModal from 'components/CommonModal/FeatureModal/FeatureListModal';

import CommonTable from 'components/Table/CommonTable';
import CommonModal from 'components/CommonModal/CommonModal';
import { InsightLabels } from 'labels';
import { useErrorBoundary } from 'react-error-boundary';
import UserInfoSection from 'components/Layouts/UserInfoSection';
import { Dropdown, ButtonGroup, Button } from 'react-bootstrap';

let urlData: any = {};

// Type whatever you expect in 'this.props.match.params.*'
type PathParamsType = {
    // param1: string,
}

interface CurrentState {
    featureDetail: FeatureDetailState,
    commonComponentData: CommonComponentState,
    languageData: LanguageState
}



const FeatureDetails: React.FC = (props: any) => {

    const { showBoundary } = useErrorBoundary();

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)

    const location = useLocation();
    const navigate = useNavigate();
    let urlData: any = {};
    const query = new URLSearchParams(location.search);
    query.forEach((v, k) => {
        urlData[k] = v;
    });
    const PITracking = localStorage.getItem('PI-TRACKING');

    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];
    const dispatch = useDispatch();
    const componentRef = useRef() as React.RefObject<HTMLDivElement>;
    let fetched_details = useSelector((state: CurrentState) => state);
    const { feature_code } = useParams();
    const featureCode = feature_code;
    const lineChartMargin = {
        top: "50",
        right: "50",
        bottom: "50",
        left: "75",
    }

    const linechart_height = 400;


    ///States :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::-------------
    const [pageData, setPageData] = useState({
        pageTitle: ''
    });

    const [loaderBtn, setLoaderBtn] = useState({
        loader0: false,
        loader1: false,
        loader2: false,
        loader3: false,
        loader4: false,
        loader5: false,
        disabled: false
    });


    const [heatmapChart, setHeatmapChart] = useState({
        heatmapFetched: false,
        heatMapData: [],
        days: [],
        errorMsg: ''
    });

    const [usageData, setUsageData] = useState({
        pieChartDataFetched: false,
        usageTableDataFetched: false,
        errorMsg: ''
    });

    const [featureDetailStatsChart, setFeatureDetailStatsChart] = useState({
        loadFeatureDetailStatsChartData: [],
        loadFeatureDetailStatsChartOptions: [],
        featureDetailStatsChartDataFetched: false,
        featureDetailStatsChart: {},
        featureDetailStatsChartFetched: false,
    });

    const [errorCount, setErrorCount] = useState({
        errorCount: [0, 0, 0, 0, 0, 0, 0],
        showMaintenance_flag: false
    });

    const [featureList, setFeatureList] = useState({
        data: [],
        appliedData: []
    })

    const [userEngagementStatsChart, setUserEngagementStatsChart] = useState({
        loadUserEngagementStatsChart: [],
        loadUserEngagementStatsChartOptions: [],
        userEngagementStatsChartDataFetched: false,
        userEngagementStatsChart: {},
        userEngagementStatsChartFetched: false,
    })

    const [userEngagementStatsSummaryTable, setUserEngagementStatsSummaryTable] = useState({
        userEngagementStatsSummaryDataFetched: false,
        searchQuery: '',
        sortKey: 'feature_interactions',
        filterLabels: [],
        page: 1,
        showExport: true,
        defaultSort: {
            'sortColumn_index': 2,
            'sortValue': 'desc'
        },
        sortValue: 'desc',
        limit: 5,
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 5,
            currentPage: 0
        },
        firstLoad: true,
        hideDeleted: 0
    });

    const [modalDetails, setModalDetails] = useState({
        isModalOpen: false,
        userFullName: ''
    });

    const [userEngagementFeatureDetailChart, setUserEngagementFeatureDetailChart] = useState({
        loadUserEngagementFeatureDetailChart: [],
        loadUserEngagementFeatureDetailChartOptions: [],
        userEngagementFeatureDetailChartFetched: false,
        userEngagementFeatureDetailChart: {},
    })
    ///End States :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::-------------

    ///Start USEEFFECTS :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::--------
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
    }, [fetched_details.languageData.languageData])

    //First time Load start
    useEffect(() => {
        if (insightsLbls !== undefined) {
            document.title = insightsLbls['featureDetail'];
        }

        window.scrollTo(0, 0);
        CommonUtils.LeftPanelHighlight(2, 1, 0, true, false);
    }, [insightsLbls])

    useEffect(() => {
        try {
            let component_path: any[] = location.pathname.split('/');
            component_path = component_path.filter(function (el) {
                return el != '';
            });

            let queryParams = {
                app_id: fetched_details?.commonComponentData.appsData.appId,
                app_code: fetched_details?.commonComponentData.appsData.appCode,
                fdate: fetched_details?.commonComponentData.datesData.fdate,
                tdate: fetched_details?.commonComponentData.datesData.tdate,
            }
            navigate({
                pathname: "/app-insights/feature/feature-detail/" + component_path[component_path.length - 1] + '/',
                search: qs.stringify(queryParams),
            });


            if (fetched_details?.commonComponentData.datesData.source === 'component') {
                if (fetched_details?.commonComponentData.datesData.url?.includes('feature-detail')) {
                    getFeatureDetailStatsChartData();
                    getUserTimeChartData();
                    getUsageStats('count', 'asc', 0, fetched_details?.commonComponentData.datesData.fdate, fetched_details?.commonComponentData.datesData.tdate);
                    // getFeatureListData();
                    getUserEngagementStatsChartData();
                    PITracking === "1" && getUserEngagementSummaryTableData();
                }
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.commonComponentData.datesData, featureList.data])

    // First time Load END

    //LineChart 
    useEffect(() => {
        try {
            if (
                fetched_details.featureDetail.featureDetailStatsChartData.data !== undefined &&
                fetched_details.featureDetail.featureDetailStatsChartData.result === "success"
            ) {

                setFeatureDetailStatsChart((prevState: any) => {
                    return {
                        ...prevState,
                        featureDetailStatsChartFetched: true,
                        loadFeatureDetailStatsChartData: fetched_details.featureDetail.featureDetailStatsChartData.data
                    }
                })

                setPageData({
                    ...pageData,
                    pageTitle: fetched_details.featureDetail.featureDetailStatsChartData.data.featureTitle ? fetched_details.featureDetail.featureDetailStatsChartData.data.featureTitle : '',
                });
                apiCatchError(0, 0);
                enableExportbtn(0, true);
            } else if (fetched_details.featureDetail.featureDetailStatsChartData.result === 'retry') {
                getFeatureDetailStatsChartData('retry');
            } else if (fetched_details.featureDetail.featureDetailStatsChartData.result === 'error') {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;
                if (fetched_details.featureDetail.featureDetailStatsChartData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }
                setFeatureDetailStatsChart((prevState: any) => {
                    return {
                        ...prevState,
                        featureDetailStatsChartFetched: true,
                        errorMsg: errorMsg
                    }
                });
                setPageData({
                    ...pageData,
                    pageTitle: '',
                });

                apiCatchError(0, errorFlag);
                enableExportbtn(0, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.featureDetail.featureDetailStatsChartData])

    //heatmap
    useEffect(() => {
        try {
            if (
                fetched_details.featureDetail.userVisit.data !== undefined &&
                fetched_details.featureDetail.userVisit.result === "success"
            ) {
                let days: any = [];
                let daysArr: any = [];
                let chartData: any = [];

                chartData[0] = fetched_details.featureDetail.userVisit.data;
                if (chartData[0]['chartData'].length > 0 && chartData[0].chartTimes.length > 0) {
                    for (let i = 0; i < chartData[0]['chartData'].length; i++) {
                        daysArr.push(chartData[0]['chartData'][i]['day']);
                    }
                    days[0] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

                    setHeatmapChart((prevState: any) => {
                        return {
                            ...prevState,
                            heatmapFetched: true,
                            heatMapData: [...chartData],
                            days: [...days]
                        }
                    })
                    apiCatchError(1, 0);
                    enableExportbtn(1, true);
                }
            } else if (fetched_details.featureDetail.userVisit.result === 'retry') {
                getUserTimeChartData('retry');
            } else if (fetched_details.featureDetail.userVisit.result === 'error') {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;
                if (fetched_details.featureDetail.userVisit.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }
                setHeatmapChart((prevState: any) => {
                    return {
                        ...prevState,
                        heatmapFetched: true,
                        errorMsg: errorMsg
                    }
                });
                apiCatchError(1, errorFlag);
                enableExportbtn(1, false);
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details.featureDetail.userVisit])

    //OS,screen resolution and browser graph data
    useEffect(() => {
        try {
            if (fetched_details.featureDetail.userSystemData.data !== undefined && fetched_details.featureDetail.userSystemData.result === 'success') {

                setUsageData((prevState: any) => {
                    return {
                        ...prevState,
                        pieChartDataFetched: true,
                        usageTableDataFetched: true,
                    }
                })
                apiCatchError(2, 0);
                enableExportbtn(2, true);
                enableExportbtn(3, true);
                enableExportbtn(4, true);
            } else if (fetched_details.featureDetail.userSystemData.result === 'retry') {
                getUsageStats('count', 'asc', 0, fetched_details?.commonComponentData.datesData.fdate, fetched_details?.commonComponentData.datesData.tdate, 'retry');
            } else if (fetched_details.featureDetail.userSystemData.result === 'error') {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;
                if (fetched_details.featureDetail.userSystemData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }
                setUsageData((prevState: any) => {
                    return {
                        ...prevState,
                        pieChartDataFetched: true,
                        usageTableDataFetched: true,
                        errorMsg: errorMsg
                    }
                })
                apiCatchError(3, errorFlag);
                enableExportbtn(2, false);
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details.featureDetail.userSystemData]);



    // Export PDF btn
    useEffect(() => {
        try {
            if (loaderBtn.loader0 && loaderBtn.loader1 && loaderBtn.loader2 && loaderBtn.loader3 && loaderBtn.loader4 && loaderBtn.loader5) {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: false,
                    };
                });
            } else {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: true,
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [loaderBtn.loader0, loaderBtn.loader1, loaderBtn.loader2, loaderBtn.loader4, loaderBtn.loader3, loaderBtn.loader5])

    useEffect(() => {
        try {
            if (
                fetched_details?.featureDetail?.featureListData?.data !== undefined &&
                fetched_details?.featureDetail?.featureListData?.result === "success"
            ) {
                setFeatureList((prevState: any) => {
                    return {
                        ...prevState,
                        data: fetched_details?.featureDetail?.featureListData?.data,
                        dataFetched: true
                    }
                })
            } else if (fetched_details?.featureDetail?.featureListData?.result === 'retry') {

            } else if (fetched_details?.featureDetail?.featureListData?.result === 'error') {
                setFeatureList((prevState: any) => {
                    return {
                        ...prevState,
                        data: [],
                        dataFetched: false
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.featureDetail?.featureListData])

    //User engagement line chart
    useEffect(() => {
        try {
            if (
                fetched_details.featureDetail.userEngagementStatsChartData.data !== undefined &&
                fetched_details.featureDetail.userEngagementStatsChartData.result === "success"
            ) {
                setUserEngagementStatsChart((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementStatsChartDataFetched: true,
                        loadUserEngagementStatsChart: fetched_details.featureDetail.userEngagementStatsChartData.data
                    }
                })
                apiCatchError(3, 0);
                enableExportbtn(5, true);
            } else if (fetched_details.featureDetail.userEngagementStatsChartData.result === 'retry') {
                getUserEngagementStatsChartData('retry');
            } else if (fetched_details.featureDetail.userEngagementStatsChartData.result === 'error') {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;
                if (fetched_details.featureDetail.userEngagementStatsChartData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }
                setUserEngagementStatsChart((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementStatsChartDataFetched: true,
                        errorMsg: errorMsg
                    }
                });

                apiCatchError(3, errorFlag);
                enableExportbtn(0, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.featureDetail.userEngagementStatsChartData])

    //User engagement summary table
    useEffect(() => {
        try {
            if (
                fetched_details.featureDetail.userEngagementStatsSummaryData.data !== undefined &&
                fetched_details.featureDetail.userEngagementStatsSummaryData.result === "success"
            ) {

                setUserEngagementStatsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementStatsSummaryDataFetched: true,
                    };
                });
                apiCatchError(4, 0);
                enableExportbtn(5, true);
            } else if (fetched_details.featureDetail.userEngagementStatsSummaryData.result === 'retry') {
                getUserEngagementSummaryTableData('retry');
            } else if (
                fetched_details.featureDetail.userEngagementStatsSummaryData.result === "error"
            ) {
                setUserEngagementStatsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementStatsSummaryDataFetched: true,
                    };
                });
                apiCatchError(4, 1);
                enableExportbtn(5, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.featureDetail.userEngagementStatsSummaryData]);

    useEffect(() => {
        try {
            // fetched_details?.commonComponentData.datesData.dateSelection = 15 (Custom Date option)
            if (fetched_details?.commonComponentData.datesData?.url?.includes('feature-detail')) {
                enableExportbtn(5, false);
                getUserEngagementSummaryTableData();
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [
        userEngagementStatsSummaryTable.sortKey,
        userEngagementStatsSummaryTable.sortValue,
        userEngagementStatsSummaryTable.defaultSort,
        userEngagementStatsSummaryTable.page,
        userEngagementStatsSummaryTable.searchQuery,
        userEngagementStatsSummaryTable.limit,
        userEngagementStatsSummaryTable.filterLabels,
    ]);

    //Modal Chart
    useEffect(() => {
        try {
            if (
                fetched_details.featureDetail.userEngagementFeatureDetailChartData.data !== undefined &&
                fetched_details.featureDetail.userEngagementFeatureDetailChartData.result === "success"
            ) {
                setUserEngagementFeatureDetailChart((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementFeatureDetailChartFetched: true,
                        loadUserEngagementFeatureDetailChart: fetched_details.featureDetail.userEngagementFeatureDetailChartData.data
                    }
                })
                apiCatchError(5, 0);
            } else if (fetched_details.featureDetail.userEngagementFeatureDetailChartData.result === 'retry') {
                getUserEngagementFeatureDetailChart('retry');
            } else if (fetched_details.featureDetail.userEngagementFeatureDetailChartData.result === 'error') {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;
                if (fetched_details.featureDetail.userEngagementFeatureDetailChartData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }
                setUserEngagementFeatureDetailChart((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementFeatureDetailChartFetched: true,
                        errorMsg: errorMsg
                    }
                });

                apiCatchError(5, errorFlag);
                enableExportbtn(0, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.featureDetail.userEngagementFeatureDetailChartData])


    ///End USEEFFECTS :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::--------


    /// Start functions :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::-


    /// API CALLS ------------------------------------------------------------------------------------------

    const getFeatureDetailStatsChartData = (apiErrorFlag?: string) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
            "feature_code": featureCode,
            "step_id": featureList.data
        };

        featureDetailActions.getFeatureDetailData(
            params,
            "FEATURE_STATS_CHART",
            ActionTypes.SET_FEATURE_DETAIL_STATS_CHART,
            apiErrorFlag ?? '',
        ).then((res: any) => dispatch(res));
    }

    const getUserTimeChartData = (apiErrorFlag?: string) => {
        const params = {
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
            "time_zone": timeZone,
            // "app_code": urlData['app_code'],
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "feature_code": featureCode,
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            "step_id": featureList.data
        };
        featureDetailActions.getFeatureDetailData(
            params,
            'FEATURE_DETAIL_HEATMAP',
            ActionTypes.SET_FEATURE_DETAIL_HEATMAP,
            apiErrorFlag ?? '').then((res: any) => dispatch(res));
    }



    const getUsageStats = (sortBy: string, order: string, optn: number, fdate: any, tdate: any, apiErrorFlag?: string) => {
        let params;
        if (optn === 2) {
            params = {
                "start_date": fdate + "_00:00:00",
                "end_date": tdate + "_23:59:59",
                "time_zone": timeZone,
                "app_code": fetched_details?.commonComponentData.appsData.appCode,
                "sort_by": sortBy,
                "order": order,
                "feature_code": featureCode,
                "app_id": fetched_details?.commonComponentData.appsData.appId
            }
        }
        else {
            params = {
                "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
                "end_date": fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
                "time_zone": timeZone,
                "app_code": fetched_details?.commonComponentData.appsData.appCode,
                "sort_by": sortBy,
                "order": order,
                "feature_code": featureCode,
                "app_id": fetched_details?.commonComponentData.appsData.appId,
                "step_id": featureList.data
            };
        }
        featureDetailActions.getFeatureDetailData(
            params,
            'FEATURE_DETAIL_USAGE_STATS',
            ActionTypes.USAGE_STATS,
            apiErrorFlag ?? '').then((res: any) => dispatch(res));

    }

    const getUserEngagementStatsChartData = (apiErrorFlag?: string) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
            "feature_code": featureCode,
            "step_id": featureList.data
            // {API_HOST}}/feature/v1/insight/user/engagement/graph/?start_date=2023-10-01_05:30:00&end_date=2023-10-25_06:30:00&app_code=C2D9DC5D-FA9B-E911-ABC4-00155D60E896&time_zone=utc+0530&feature_code=34911&event_source=player&event_source=creator&step_id=127590&step_id=127591
        };

        featureDetailActions.getFeatureDetailData(
            params,
            "USER_ENGAGEMENT_STATS",
            ActionTypes.SET_USER_ENGAGEMENT_STATS_CHART,
            apiErrorFlag ?? '',
        ).then((res: any) => dispatch(res));
    }


    const getUserEngagementSummaryTableData = (apiErrorFlag?: string) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
            "page_size": userEngagementStatsSummaryTable.limit,
            "sort_by": userEngagementStatsSummaryTable.sortKey,
            "order": userEngagementStatsSummaryTable.sortValue,
            "page_index": userEngagementStatsSummaryTable.page,
            "filter_label": userEngagementStatsSummaryTable.filterLabels,
            "feature_code": featureCode,
            "step_id": featureList.data,
            "search_text": userEngagementStatsSummaryTable.searchQuery,
        };

        PITracking === "1" && featureDetailActions.getFeatureDetailData(
            params,
            "USER_ENGAGEMENT_STATS_SUMMARY",
            ActionTypes.SET_USER_ENGAGEMENT_STATS_SUMMARY,
            apiErrorFlag ?? '', undefined,
            userEngagementStatsSummaryTable.firstLoad
        ).then((res: any) => dispatch(res));
    };

    const getUserEngagementFeatureDetailChart = (userId: string, apiErrorFlag?: string) => {

        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
            "feature_code": featureCode,
            "step_id": featureList.data,
            "user_id": userId,
        };

        featureDetailActions.getFeatureDetailData(
            params,
            "USER_ENGAGEMENT_FEATURE_DETAIL_STATS",
            ActionTypes.SET_USER_ENGAGEMENT_FEATURE_DETAIL_STATS,
            apiErrorFlag ?? '',
        ).then((res: any) => dispatch(res));
    }
    ///End API calls

    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) => (
                        i === index ? option : item
                    ))
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }


    const refreshSections = (optn: number) => {
        try {
            if (optn === 0) {
                setHeatmapChart((prevState: any) => {
                    return {
                        ...prevState,
                        heatmapFetched: false,
                    }
                });
                setFeatureDetailStatsChart((prevState: any) => {
                    return {
                        ...prevState,
                        featureDetailStatsChartFetched: false,
                    }
                })
                getFeatureDetailStatsChartData();
                getUserTimeChartData();
                enableExportbtn(0, false);
                enableExportbtn(1, false);

            }
            else if (optn === 1) {
                enableExportbtn(2, false);
                setUsageData((prevState: any) => {
                    return {
                        ...prevState,
                        pieChartDataFetched: false,
                        usageTableDataFetched: false,
                    }
                })

                getUsageStats('count', 'asc', optn, fetched_details?.commonComponentData.datesData.fdate, fetched_details?.commonComponentData.datesData.tdate);
            }
            else if (optn === 2) {
                enableExportbtn(3, false);
                setUsageData((prevState: any) => {
                    return {
                        ...prevState,
                        pieChartDataFetched: false,
                        usageTableDataFetched: false,
                    }
                })

                getUsageStats('count', 'asc', optn, fetched_details?.commonComponentData.datesData.fdate, fetched_details?.commonComponentData.datesData.tdate);
            }
            else if (optn === 3) {
                enableExportbtn(4, false);
                setUsageData((prevState: any) => {
                    return {
                        ...prevState,
                        pieChartDataFetched: false,
                        usageTableDataFetched: false,
                    }
                })

                getUsageStats('count', 'asc', optn, fetched_details?.commonComponentData.datesData.fdate, fetched_details?.commonComponentData.datesData.tdate);
            }
            else if (optn === 4) {

                enableExportbtn(5, false);
                setUserEngagementStatsChart((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementStatsChartDataFetched: false,
                    }
                })
                getUserEngagementStatsChartData();
            }
            else if (optn === 5) {

                enableExportbtn(5, false);
                
                setUserEngagementStatsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementStatsSummaryDataFetched: false,
                    }
                })
                getUserEngagementSummaryTableData();
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const enableExportbtn = (loaderNumber: number, val: boolean) => {
        try {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    ['loader' + loaderNumber]: val
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const refreshBrowser = useCallback((optn: number) => {
        try {
            enableExportbtn(2, false);
            refreshSections(2)
        } catch (error) {
            showBoundary(error)
        }

    }, []);

    const getSortEvent = useCallback((renderVal: boolean, optn: string, sortBy: string, order: string) => {
        try {
            getUsageStats(sortBy, order, 0, fetched_details?.commonComponentData.datesData.fdate, fetched_details?.commonComponentData.datesData.tdate);

        } catch (error) {
            showBoundary(error)
        }
    }, []);

    const getStepIds = (data: any) => {
        try {
            setHeatmapChart((prevState: any) => {
                return {
                    ...prevState,
                    heatmapFetched: false,
                    heatMapData: [],
                    days: [],
                    errorMsg: ''
                }
            })

            setUsageData((prevState: any) => {
                return {
                    ...prevState,
                    pieChartDataFetched: false,
                    usageTableDataFetched: false,
                    errorMsg: ''
                }
            })

            setFeatureDetailStatsChart((prevState: any) => {
                return {
                    ...prevState,
                    loadFeatureDetailStatsChartData: [],
                    loadFeatureDetailStatsChartOptions: [],
                    featureDetailStatsChartDataFetched: false,
                    featureDetailStatsChart: {},
                    featureDetailStatsChartFetched: false,
                }
            })

            setUserEngagementStatsChart((prevState: any) => {
                return {
                    ...prevState,
                    loadUserEngagementStatsChart: [],
                    userEngagementStatsChartDataFetched: false,
                    userEngagementStatsChart: {},
                    loadUserEngagementStatsChartOptions: []
                }
            })

            setUserEngagementStatsSummaryTable((prevState: any) => {
                return {
                    ...prevState,
                    userEngagementStatsSummaryDataFetched: false,
                }
            })

            setFeatureList((prevState: any) => {
                return {
                    ...prevState,
                    data: data.stepData,
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const getAppliedList = () => {
        return featureList?.data;
    }
    const getLabelData = (data: any) => {
    }

    const handleTableEvents = useCallback((option: string, data: any) => {
        try {
            switch (option) {
                case "routeToDetail":
                    setUserEngagementFeatureDetailChart((prevState: any) => {
                        return {
                            ...prevState,
                            userEngagementFeatureDetailChartFetched: false
                        }
                    })
                    getUserEngagementFeatureDetailChart(data.userID);
                    openFeatureDetailModal(data.userFullName);
                    break;

                case "sortColumn":
                    setUserEngagementStatsSummaryTable((prevState: any) => {
                        return {
                            ...prevState,
                            sortKey: data.sortKey,
                            sortValue: data.sortValue,
                            page: 1,
                            defaultSort: {
                                sortColumn_index: data.index,
                                sortValue: data.sortValue,
                            },
                            firstLoad: false
                        }
                    });
                    break;

                case "changePage":
                    setUserEngagementStatsSummaryTable((prevState: any) => {
                        return {
                            ...prevState,
                            page: data + 1,
                            firstLoad: false
                        }
                    });
                    break;

                case "searchTxt":
                    setUserEngagementStatsSummaryTable((prevState: any) => {
                        return {
                            ...prevState,
                            page: 1,
                            searchQuery: data,
                            firstLoad: false
                        }
                    });
                    break;

                case "applyLabels":
                    setUserEngagementStatsSummaryTable((prevState: any) => {
                        return {
                            ...prevState,
                            page: 1,
                            filterLabels: data,
                            firstLoad: false
                        };
                    });
                    break;

                case "changeEntries":
                    setUserEngagementStatsSummaryTable((prevState: any) => {
                        return {
                            ...prevState,
                            page: 1,
                            limit: data,
                            tablePagination: {                   // object that we want to update
                                ...prevState.tablePagination,    // keep all other key-value pairs
                                perPage: data       // update the value of specific key
                            },
                            firstLoad: false
                        }
                    });
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [userEngagementStatsSummaryTable]);


    const openFeatureDetailModal = (userFullName: string) => {
        try {
        } catch (error) {
            showBoundary(error)
        }
        setModalDetails((prevState) => {
            return {
                ...prevState,
                isModalOpen: true,
                userFullName: userFullName,
            }
        });
    }

    const closeModal = () => {
        try {
        } catch (error) {
            showBoundary(error)
        }
        setModalDetails((prevState) => {
            return {
                ...prevState,
                isModalOpen: false
            }
        })
    }

    const navigateToMainPage = () => {
        let queryParams = {
            app_id: fetched_details?.commonComponentData.appsData.appId,
            app_code: fetched_details?.commonComponentData.appsData.appCode,
            fdate: fetched_details?.commonComponentData.datesData.fdate,
            tdate: fetched_details?.commonComponentData.datesData.tdate,
        }
        navigate({
            pathname: `/app-insights/feature/`,
            search: qs.stringify(queryParams)
        })
    }
    /// End functions :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::-

    return (
        <section className="demo featureDetailSection width100" ref={componentRef}>

            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <SectionHeader>
                        <li>
                            <span className="cursorPointer" onClick={() => navigateToMainPage()}>{insightsLbls['feature']}</span>
                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                        </li>
                        <li className="active">{insightsLbls['featureDetail']}</li>
                    </SectionHeader>
                </div>

                <SectionFilters>
                    <FeatureListModal dispatchFeatureList={getStepIds} appliedList={getAppliedList} featureCode={featureCode}></FeatureListModal>
                    <Datepicker source={'component'} />
                </SectionFilters>
            </div>

            {/* <SectionButtons sectionTitle={pageData.pageTitle} svgImage={"pages"} className={"marginTop-50"}>
                <ExportToPdf
                    componentRef={componentRef}
                    source={insightsLbls['featureDetail']}
                    widthDividedBy={10}
                    disabled={loaderBtn.disabled}
                ></ExportToPdf>
            </SectionButtons> */}

            <div className="marginTop-60 exportBtn-div">
                <Dropdown as={ButtonGroup}>
                    <Button variant="info">{insightsLbls.export}</Button>
                    <Dropdown.Toggle split variant="success" id="dropdown-custom-2" />
                    <Dropdown.Menu className="super-colors">
                        <Dropdown.Item eventKey="1" >
                            <ExportToPdf
                                componentRef={componentRef}
                                source={insightsLbls['featureDetail']}
                                widthDividedBy={10}
                                disabled={loaderBtn.disabled}
                            ></ExportToPdf>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <UserInfoSection
                startDate={fetched_details?.commonComponentData?.datesData.fdate}
                endDate={fetched_details?.commonComponentData?.datesData.tdate}
                messageText={pageData.pageTitle}>
            </UserInfoSection>


            {/* {featureList.dataFetched ? (
                 <Label
                 labeldata={featureList.data}
                 getLabelData={getLabelData}
                ></Label>
                ) 
                : (<div>LOADING</div>)
            } */}




            <div className="featureDetailStatsSection" id="featureDetailStatsSection">
                {
                    // <SectionRefresh sectionTitle={insightsLbls.userActivity} refreshClass={""}>
                    //     <i
                    //         className="fa fa-refresh pull-right pointer row displayFlex"
                    //         aria-hidden="true" title={insightsLbls.refresh}
                    //         onClick={() => refreshSections(0)}>
                    //     </i>
                    // </SectionRefresh>
                }

                <div className="dashboardTiles flex-column section-wrapper borderBottom8 borderTop col-lg-12 padding0 height535 blockImportant">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            {<SectionTooltip tooltipText={
                                {
                                    "subTitle": insightsLbls.activity,
                                    "tooltipText": insightsLbls.lineChartTooltipFeatureDetail,
                                    "childern": []
                                }
                            } placement="right"></SectionTooltip>
                            }
                            <button className="pointer refreshBtn" onClick={() => refreshSections(0)}> {insightsLbls.refresh}</button>

                        </ContainerTooltip>
                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                            {featureDetailStatsChart.featureDetailStatsChartFetched ? (
                                errorCount.errorCount[0] === 0 ? (
                                    featureDetailStatsChart.loadFeatureDetailStatsChartData.length === 0 ? (
                                        <div className="noDataText-font textCenter-absolute">
                                            {insightsLbls.noRecordFound}
                                        </div>
                                    ) : (

                                        <LineChart
                                            chartEntities={['featureInteractions']}
                                            optionEntities={
                                                [
                                                    {
                                                        key: insightsLbls.featureInteractions,
                                                        color: ChartColors.color1, area: true
                                                    }
                                                ]}
                                            chartData={fetched_details?.featureDetail?.featureDetailStatsChartData?.data.graphData}
                                            value_type={"count"}
                                            height={linechart_height}
                                            margin={lineChartMargin}
                                            yAxisLabel={insightsLbls.yAxisLabelCount}
                                            refId="chatbot_conversationResponse">
                                        </LineChart>

                                    )
                                ) : (
                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                        {insightsLbls.serviceNotAvailable}
                                    </h5>
                                )
                            ) : (
                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <Loader></Loader>
                                    </div>
                                </div>
                            )}
                        </div>
                    </CardContainer>
                    <div className="col-md-12 col-sm-12 padding0 page_section">
                        <div className="heatCharts-container displayFLex">
                            <CardContainer
                                div1Classes={"col-sm-12 col-xs-12 col-md-12 col-lg-12 padding0"}
                                div2Classes={
                                    "card icon-text section-wrapper chart-wrapper3 borderTop marginTop-0 width100"
                                }
                                div3Classes={"card-body page-view-card"}>
                                <ContainerTooltip
                                    div1Classes={"graph-title displayFlex"}
                                    div2Classes={"col-lg-8 col-md-8 col-sm-8 col-8"}
                                    hTagClasses={
                                        "section-heading drop-off-funnel headingText floatLeft"
                                    }>
                                    <SectionTooltip tooltipText={{
                                        "subTitle": insightsLbls.whenUsersVisit,
                                        "tooltipText": insightsLbls.heatmapTooltipFeatureDetail,
                                        "childern": []
                                    }} placement="right"></SectionTooltip>

                                </ContainerTooltip>
                                <div className="user-timer chart-wrapper4">
                                    <div
                                        id="userTimeChart"
                                        className="timesChart width100 margin-0-auto height450 marginTop-30">
                                        {heatmapChart.heatmapFetched ? (
                                            errorCount.errorCount[1] === 0 ? (
                                                heatmapChart.heatMapData.length === 0 ? (
                                                    <div
                                                        id="noBubbleData"
                                                        className="noDataText-font textCenter-absolute">
                                                        {insightsLbls.noRecordFound}
                                                    </div>
                                                ) : (
                                                    <Heatmap
                                                        chartData={heatmapChart.heatMapData[0]["chartData"]}
                                                        chartTimes={
                                                            heatmapChart.heatMapData[0]["chartTimes"]
                                                        }
                                                        days={heatmapChart.days[0]}
                                                    ></Heatmap>
                                                )
                                            ) : (
                                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                                    {heatmapChart.errorMsg}
                                                </h5>
                                            )
                                        ) : (
                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative">
                                                <div className="displayFlex alignCenter margin-0-auto">
                                                    <Loader></Loader>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </CardContainer>
                        </div>
                    </div>
                </div>


                <div className="dashboardTiles  borderTop col-lg-12 page_section padding0 height535 blockImportant marginTop-20">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop borderBottom height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                        <div className=" margin-0-Auto statCards">
                            <div className="col-sm-12 col-md-12 col-lg-12 displayBlock height50">
                                <button className="pointer refreshBtn pull-right" onClick={() => refreshSections(2)}> {insightsLbls.refresh}</button>
                            </div>
                            <React.Fragment>
                                {/* <SectionRefresh sectionTitle={insightsLbls.activity} refreshClass={"marginTop-30"}>
                                    <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls.refresh} onClick={() => refreshSections(1)}></i>
                                </SectionRefresh> */}
                                {loaderBtn.loader2 ? (
                                    errorCount.errorCount[2] === 0 ? (
                                        <>
                                            <ContainerTooltip
                                                div1Classes={"graph-title displayFlex paddingTop-10 paddingLeft-20"}
                                                div2Classes={""}
                                                hTagClasses={"section-heading drop-off-funnel headingText row"}
                                            >
                                                {
                                                    <SectionTooltip tooltipText={
                                                        {
                                                            "subTitle": insightsLbls.webBrowser,
                                                            "tooltipText": insightsLbls.webBrowserTooltip,
                                                            "childern": []
                                                        }
                                                    } placement="right"></SectionTooltip>
                                                }
                                            </ContainerTooltip>
                                            <BrowserStats
                                                statsData={fetched_details.featureDetail.userSystemData.data}
                                                calUsageData={getSortEvent}
                                                refreshSection={refreshBrowser}
                                                refreshSectionOption={2}
                                                fdate={fetched_details?.commonComponentData.datesData.fdate}
                                                tdate={fetched_details?.commonComponentData.datesData.tdate}
                                                serviceAvailable={errorCount.errorCount[2]}
                                                type="browser"
                                            ></BrowserStats>


                                            <ContainerTooltip
                                                div1Classes={"graph-title displayFlex paddingTop-10 paddingLeft-20"}
                                                div2Classes={""}
                                                hTagClasses={"section-heading drop-off-funnel headingText row"}
                                            >
                                                {
                                                    <SectionTooltip tooltipText={
                                                        {
                                                            "subTitle": insightsLbls.operatingSystem,
                                                            "tooltipText": insightsLbls.osTooltip,
                                                            "childern": []
                                                        }
                                                    } placement="right"></SectionTooltip>
                                                }
                                            </ContainerTooltip>
                                            <BrowserStats
                                                statsData={fetched_details.featureDetail.userSystemData.data}
                                                calUsageData={getSortEvent}
                                                refreshSection={refreshBrowser}
                                                refreshSectionOption={2}
                                                fdate={fetched_details?.commonComponentData.datesData.fdate}
                                                tdate={fetched_details?.commonComponentData.datesData.tdate}
                                                serviceAvailable={errorCount.errorCount[2]}
                                                type="os"
                                            ></BrowserStats>

                                            <ContainerTooltip
                                                div1Classes={"graph-title displayFlex paddingTop-10 paddingLeft-20"}
                                                div2Classes={""}
                                                hTagClasses={"section-heading drop-off-funnel headingText row"}
                                            >
                                                {
                                                    <SectionTooltip tooltipText={
                                                        {
                                                            "subTitle": insightsLbls.screenResolution,
                                                            "tooltipText": insightsLbls.screenResolutionTooltip,
                                                            "childern": []
                                                        }
                                                    } placement="right"></SectionTooltip>
                                                }
                                            </ContainerTooltip>
                                            <BrowserStats
                                                statsData={fetched_details.featureDetail.userSystemData.data}
                                                calUsageData={getSortEvent}
                                                refreshSection={refreshBrowser}
                                                refreshSectionOption={2}
                                                fdate={fetched_details?.commonComponentData.datesData.fdate}
                                                tdate={fetched_details?.commonComponentData.datesData.tdate}
                                                serviceAvailable={errorCount.errorCount[2]}
                                                type="screen_resolution"
                                                loading={false}
                                            ></BrowserStats>
                                        </>

                                    ) : (
                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                            {usageData.errorMsg}
                                        </h5>
                                    )
                                ) : <BrowserStats
                                    loading
                                ></BrowserStats>}
                            </React.Fragment>
                        </div>
                    </CardContainer>
                </div>

                <div className=" marginTop-30 margin-0-Auto statCards" id="userEngagementSection">
                    {
                        // <SectionRefresh sectionTitle={insightsLbls.userEngagement} refreshClass={""}>
                        //     <i
                        //         className="fa fa-refresh pull-right pointer row displayFlex"
                        //         aria-hidden="true" title={insightsLbls.refresh}
                        //         onClick={() => refreshSections(4)}>
                        //     </i>
                        // </SectionRefresh>
                    }

                    <div className="dashboardTiles  borderTop col-lg-12 page_section padding0 height535 blockImportant">
                        <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                            div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop borderBottom height100-percent"}
                            div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                            <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                {<SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.activity,
                                        "tooltipText": insightsLbls.lineChartTooltipUserEngagement,
                                        "childern": []
                                    }
                                } placement="right"></SectionTooltip>
                                }
                                <button className="pointer refreshBtn" onClick={() => refreshSections(4)}> {insightsLbls.refresh}</button>
                            </ContainerTooltip>
                            <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                                {userEngagementStatsChart.userEngagementStatsChartDataFetched ? (
                                    errorCount.errorCount[3] === 0 ? (
                                        userEngagementStatsChart.loadUserEngagementStatsChart.length === 0 ? (
                                            <div className="noDataText-font textCenter-absolute">
                                                {insightsLbls.noRecordFound}
                                            </div>
                                        ) : (

                                            <LineChart
                                                chartEntities={['featureInteractions', 'featureUsers']}
                                                optionEntities={
                                                    [
                                                        {
                                                            key: insightsLbls.featureInteractions,
                                                            color: ChartColors.color3, area: true
                                                        },
                                                        {
                                                            key: 'Feature Users',
                                                            color: ChartColors.color4, area: true
                                                        }
                                                    ]}
                                                chartData={fetched_details?.featureDetail?.userEngagementStatsChartData?.data.graphData}
                                                value_type={"count"}
                                                height={linechart_height}
                                                margin={lineChartMargin}
                                                yAxisLabel={insightsLbls.yAxisLabelCount}
                                                refId="chatbot_conversationResponse">
                                            </LineChart>

                                        )
                                    ) : (
                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                            {insightsLbls.serviceNotAvailable}
                                        </h5>
                                    )
                                ) : (
                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader></Loader>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </CardContainer>
                    </div>
                    {PITracking === "1" && <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant">
                        <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                            div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                            div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                            <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                {
                                    <SectionTooltip tooltipText={
                                        {
                                            "subTitle": insightsLbls.userEngagementSummary,
                                            "tooltipText": insightsLbls.tableActivitiesOfFeature,
                                            "childern": [
                                                {
                                                    "key": insightsLbls.userName + ":",
                                                    "value": insightsLbls.userNameTooltip
                                                },
                                                {
                                                    "key": insightsLbls.userEmail + ":",
                                                    "value": insightsLbls.userEmailTooltip
                                                },
                                                {
                                                    "key": insightsLbls.interactions + ":",
                                                    "value": insightsLbls.numOfInteractionsforUser
                                                }
                                            ]
                                        }
                                    } placement="right"></SectionTooltip>
                                }
                                <button className="pointer refreshBtn" onClick={() => refreshSections(5)}> {insightsLbls.refresh}</button>
                            </ContainerTooltip>
                            {
                                userEngagementStatsSummaryTable.userEngagementStatsSummaryDataFetched ? (
                                    errorCount.errorCount[4] === 0 ? (
                                        <div className="tabelEditing commonSummaryTable userEngagementSummaryTable">
                                            <CommonTable tableId="1"
                                                data={fetched_details.featureDetail.userEngagementStatsSummaryData.data}
                                                currentPage={userEngagementStatsSummaryTable.page}
                                                barColors="positiveColors"
                                                linkPresent={true}
                                                showExport={false}
                                                showSearch={true}
                                                showEntries={true}
                                                defaultSort={userEngagementStatsSummaryTable.defaultSort}
                                                tablePagination={userEngagementStatsSummaryTable.tablePagination}
                                                tableEvent={handleTableEvents}
                                                modalTable={false}
                                            />
                                        </div>
                                    )
                                        : errorCount.errorCount[1] === 1 ? (
                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                {insightsLbls.serviceNotAvailable}
                                            </h5>
                                        )
                                            : null)
                                    : (
                                        <div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <Loader></Loader>
                                            </div>
                                        </div>
                                    )

                            }
                            {modalDetails.isModalOpen &&
                                <CommonModal modalState={modalDetails.isModalOpen} modalTitle="User Engagement" size={'lg'} footerDisabled={true} exportModal={false}
                                    padding={true} dispatchModalState={closeModal} modalDialogClass={"userEngagement-modal"} key="">
                                    <div className='pb-2'>
                                        <span className='font13 font600'>{modalDetails.userFullName + ' :'}</span>
                                    </div>
                                    <div className="dashboardTiles borderBottom borderTop col-lg-12 page_section padding0 height535 blockImportant">
                                        <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0 marginBottom-20"}
                                            div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                                            div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                                            <ContainerTooltip div1Classes={"padding10"} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                {<SectionTooltip tooltipText={
                                                    {
                                                        "subTitle": insightsLbls.activity,
                                                        "tooltipText": insightsLbls.lineChartTooltipFeatureDetail,
                                                        "childern": []
                                                    }
                                                } placement="right"></SectionTooltip>
                                                }
                                            </ContainerTooltip>
                                            <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                                                {userEngagementFeatureDetailChart.userEngagementFeatureDetailChartFetched ? (
                                                    errorCount.errorCount[5] === 0 ? (
                                                        userEngagementFeatureDetailChart.loadUserEngagementFeatureDetailChart.length === 0 ? (
                                                            <div className="noDataText-font textCenter-absolute">
                                                                {insightsLbls.noRecordFound}
                                                            </div>
                                                        ) : (

                                                            <LineChart
                                                                chartEntities={['featureInteractions']}
                                                                optionEntities={
                                                                    [
                                                                        {
                                                                            key: insightsLbls.featureInteractions,
                                                                            color: ChartColors.color1, area: true
                                                                        },
                                                                        {
                                                                            key: 'Feature Users',
                                                                            color: ChartColors.color2, area: true
                                                                        }
                                                                    ]}
                                                                chartData={fetched_details?.featureDetail?.userEngagementFeatureDetailChartData?.data.graphData}
                                                                value_type={"count"}
                                                                height={linechart_height}
                                                                margin={lineChartMargin}
                                                                yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                refId="chatbot_conversationResponse">
                                                            </LineChart>

                                                        )
                                                    ) : (
                                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                                            {insightsLbls.serviceNotAvailable}
                                                        </h5>
                                                    )
                                                ) : (
                                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                        <div className="displayFlex alignCenter margin-0-auto">
                                                            <Loader></Loader>
                                                        </div>
                                                    </div>
                                                )}

                                            </div>
                                        </CardContainer>
                                    </div>
                                </CommonModal>}
                        </CardContainer>
                    </div>
                    }
                </div>


            </div>
        </section>
    )
}


export default withRouter(withReducer("featureDetail", featureDetailReducer)(FeatureDetails));