import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from '../containers/Dashboard/Dashboard';
import CustomDashboard from '../containers/Dashboard/CustomDashboard';
import Authentication from 'containers/Authentication/Authentication';

// GUIDE INSIGHTS
import GuideAnalytics from '../containers/GuideInsights/GuideAnalytics/GuideAnalytics';
import GuideAnalyticsDetail from '../containers/GuideInsights/GuideAnalyticsDetail/GuideAnalyticsDetail';
import GuideErrors from '../containers/GuideInsights/GuideErrors/GuideErrors';
import GuideErrorsDetail from '../containers/GuideInsights/GuideErrorsDetail/GuideErrorsDetail';


import TutorialGuides from '../containers/GuideInsights/TutorialGuides/TutorialGuides';
import TutorialGuidesDetail from '../containers/GuideInsights/TutorialGuidesDetail/TutorialGuidesDetail';
import PushNotificationsBeacons from '../containers/GuideInsights/PushNotificationBeacons/PushNotificationsBeacons';

import Tooltips from '../containers/GuideInsights/Tooltips/Tooltips';
import TooltipsDetail from '../containers/GuideInsights/Tooltips/TooltipsDetail';
import TaskList from '../containers/GuideInsights/TaskList/TaskList';
import TaskListDetail from '../containers/GuideInsights/TaskList/TaskListDetail';


import UserEngagement from '../containers/GuideInsights/UserEngagement/UserEngagement';
import UserEngagementDetail from '../containers/GuideInsights/UserEngagement/UserEngagementDetail';
import Searches from '../containers/GuideInsights/Searches/Searches';

// APP INSIGHTS
import Page from '../containers/AppInsights/Pages/Pages';
import PageDetail from '../containers/AppInsights/Pages/PageDetails';
import CombinedWorkflows from '../containers/AppInsights/Workflows/CombinedWorkflows/CombinedWorkflows';
import CombinedWorkflowsDetails from '../containers/AppInsights/Workflows/CombinedWorkflows/CombinedWorkflowsDetails';
import Feature from '../containers/AppInsights/Features/Feature';
import FeatureDetails from 'containers/AppInsights/Features/FeatureDetails';



// SURVEY
import Stats from '../containers/Survey/Stats/Stats';
import StatsDetails from '../containers/Survey/Stats/StatsDetail';
import Manage from '../containers/Survey/Manage/Manage';

// CHATBOT
import ChatbotStats from '../containers/ChatBot/Stats/Stats';
import ChatbotStatsDetails from '../containers/ChatBot/Stats/StatsDetails';
import ChatbotManage from '../containers/ChatBot/Manage/manageChatBot';


// AUTOMATION INSIGHTS
import GuideAutomation from '../containers/AutomationInsights/GuideAutomation/GuideAutomation';
import GuideAutomationDetail from '../containers/AutomationInsights/GuideAutomation/GuideAutomationDetails';
import QAAutomation from '../containers/AutomationInsights/QAAutomation/QAAutomation';
import QAAutomationDetail from '../containers/AutomationInsights/QAAutomation/QAAutomationDetail';

// ORGANIZATION
import OrganizationView from '../containers/OrganizationView/OrganizationView';
import OrganizationDetail from '../containers/OrganizationView/OrganizationDetail';

//EXPORT SECTION
import ExportList from '../containers/Export/ExportList';

//BUSINESS INTELLIGENCE TOOLS INTEGRATION SECTION
import BusinessIntelligenceToolsIntegration from '../containers/BusinessIntelligenceToolsIntegration/BusinessIntelligenceToolsIntegration';

//GOAL SECTION
import Goal from 'containers/Goal/Goal';
import GoalDetails from 'containers/Goal/GoalDetails';

//CONTROL CENTRE
import ControlCenter from '../containers/ControlCenter/ControlCenter';

// COMMON
import NotFound from "../containers/NotFound/NotFound";
import Maintenance from "../components/Maintenance/Maintenance";


// const NotFound = React.lazy(() => import("../containers/NotFound/NotFound"))

const RoutePaths = () => {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/dashboard/" />} />


            {/* Dashboard */}
            <Route path="/dashboard/" element={<Dashboard/>} />
            <Route path="/custom-dashboard/:dashboardNumber/" element={<CustomDashboard/>} />


            {/* Guide Insights */}
            <Route path="/guide-insights/guide-analytics/" element={<GuideAnalytics/>} />
            <Route path="/guide-insights/guide-analytics-detail/:guide_id/" element={<GuideAnalyticsDetail/>} />

            <Route path="/guide-insights/guide-errors/" element={<GuideErrors/>} />
            <Route path="/guide-insights/guide-errors-detail/:guide_id/" element={<GuideErrorsDetail/>} />


            <Route path="/guide-insights/search-activities/" element={<Searches/>} />
            <Route path="/guide-insights/tutorial-guides/" element={<TutorialGuides/>}/>
            <Route path="/guide-insights/push-notifications-beacons/" element={<PushNotificationsBeacons/>}/>

            <Route path="/guide-insights/tutorial-guides-detail/:tutorial_id" element={<TutorialGuidesDetail/>}/>
            <Route path="/guide-insights/tooltips/" element={<Tooltips/>} />
            <Route path="/guide-insights/tooltips-detail/:tooltip_id/" element={<TooltipsDetail/>} />


            <Route path="/guide-insights/task-list/" element={<TaskList/>} />
            <Route path="/guide-insights/task-list-detail/:task_list_id/" element={<TaskListDetail/>} />


            <Route path="/guide-insights/user-engagement/" element={<UserEngagement/>} />
            <Route path="/guide-insights/user-engagement-detail/:unique_user_id/" element={<UserEngagementDetail/>} />


            {/* App Insights */}
            <Route path="/app-insights/pages/" element={<Page/>} />
            <Route path="/app-insights/page-detail/:tag_code/" element={<PageDetail/>} />
            <Route path="/app-insights/feature/" element={<Feature/>} />
            <Route path="/app-insights/feature/feature-detail/:feature_code/" element={<FeatureDetails/>} />

            {/* <Route path="/app-insights/workflows/" element={<CombinedWorkflows/>} />
            <Route path="/app-insights/workflows-detail/:workflow_id/:workflow_type/" element={<CombinedWorkflowsDetails/>} /> */}


            {/* Survey */}
            <Route path="/survey/survey/" element={<Stats/>} />
            <Route path="/survey/survey-details/:sentiment_code/" element={<StatsDetails/>} />
            <Route path="/survey/manage-survey/" element={<Manage/>} />


            {/* Chatbot  */}
            <Route path="/chatbot/chatbot/" element={<ChatbotStats/>} />
            <Route path="/chatbot/chatbot-details/:conversation_code" element={<ChatbotStatsDetails/>} />
            <Route path="/chatbot/manage/" element={<ChatbotManage/>} />

       
            {/* Automation Insights */}
            <Route path="/automation-insights/guide-automation/" element={<GuideAutomation/>} />
            <Route path="/automation-insights/automation-detail/:play_code/" element={<GuideAutomationDetail/>} />
            {/* <Route path="/automation-insights/qa-automator/" element={<QAAutomation/>} />
            <Route path="/automation-insights/automator-detail/:suite_id/" element={<QAAutomationDetail/>} /> */}


            {/* Organisation View */}
            {/* <Route path="/organization/org-view/" element={<OrganizationView/>} />
            <Route path="/organization/org-detail/:org_id/" element={<OrganizationDetail/>} /> */}


            {/* Export */}
            <Route path="/export/export-list/" element={<ExportList/>} />

            {/* Business Intelligence Tools Integration */}
            <Route path='/business-intelligence-tools-integrations' element={<BusinessIntelligenceToolsIntegration/>} />

            {/* Goal */}
            <Route path="/goal/manage-goal/" element={<Goal/>} />
            <Route path="/goal/goal-details/:goal_code/" element={<GoalDetails/>} />


            {/* Control Centre */}
            <Route path="/control-center/" element={<ControlCenter/>} />


            <Route path="/maintenance/" element={<Maintenance/>} />

            {/* If login is  */}
            <Route path="/login/" element={localStorage.getItem('IS_AUTHENTICATED') === 'true' || window.location.href.includes('handoff-token') ? <Navigate to="/dashboard/" />:<Authentication/>} />
            {/* <Route path="/login/" element={<Authentication/>} /> */}


            <Route path="*" element={<NotFound/>} />


        </Routes>
    );
}

export default RoutePaths;