import * as actionTypes from '../../Actions/ActionTypes';

export interface GuideAnalyticsDetailState {
    guideAnalyticsDetailGuideMeChartData: any,
    guideAnalyticsDetailGuideMeSourcesChartData: any,
    guideAnalyticsDetailSummaryTableData: any,
    guideAnalyticsDetailStepsFlowChartData: any,
    guideAnalyticsDetailDropoffFunnelChartData: any,
    guideAnalyticsDetailFunnelStepsList: any
    guideAnalyticsDetailShowMeChartData: any,
    guideAnalyticsDetailShowMeDownloadsChartData: any,
    guideAnalyticsDetailTestMeChartData: any,
    guideAnalyticsDetailDoItForMeChartData: any,
    guideAnalyticsDetailDoItForMeTableData: any,
    guideAnalyticsDetailDoItForMeSourcesChartData: any,
    guideAnalyticsDetailSurveyChartData: any,
    guideAnalyticsDetailSurveyModalData: any,
    guideAnalyticsDetailLinkedTableData: any,
    guideUserActivity: any,
    guideAnalyticsDetailShowMeSourcesChartData: any,
    guideAnalyticsDetailShowMeStepsData: any,
    guideAnalyticsDetailAudioChart: any,
    guideAnalyticsDetailTutorialGuidesChartData: any,
    guideAnalyticsDetailErrorData: any,
}

const initialState = {
    guideAnalyticsDetailGuideMeChartData: [],
    guideAnalyticsDetailGuideMeSourcesChartData: [],
    guideAnalyticsDetailSummaryTableData: [],
    guideAnalyticsDetailStepsFlowChartData: [],
    guideAnalyticsDetailDropoffFunnelChartData: [],
    guideAnalyticsDetailFunnelStepsList: [],
    guideAnalyticsDetailShowMeChartData: [],
    guideAnalyticsDetailShowMeDownloadsChartData: [],
    guideAnalyticsDetailTestMeChartData: [],
    guideAnalyticsDetailDoItForMeChartData: [],
    guideAnalyticsDetailDoItForMeTableData: [],
    guideAnalyticsDetailDoItForMeSourcesChartData: [],
    guideAnalyticsDetailSurveyChartData: [],
    guideAnalyticsDetailSurveyModalData: [],
    guideAnalyticsDetailLinkedTableData: [],
    guideUserActivity: [],
    guideAnalyticsDetailShowMeSourcesChartData: [],
    guideAnalyticsDetailShowMeStepsData: [],
    guideAnalyticsDetailAudioChart: [],
    guideAnalyticsDetailTutorialGuidesChartData: [],
    guideAnalyticsDetailErrorData: []
};


const guideAnalyticsDetailReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SET_GUIDE_ANALYTICS_DETAIL_GUIDEME_CHART_DATA:
            return {
                ...state,
                guideAnalyticsDetailGuideMeChartData: action.guideAnalyticsDetailGuideMeChartData,
            };

        case actionTypes.SET_GUIDE_ANALYTICS_DETAIL_GUIDEME_SOURCES_CHART_DATA:
            return {
                ...state,
                guideAnalyticsDetailGuideMeSourcesChartData: action.guideAnalyticsDetailGuideMeSourcesChartData,
            };

        case actionTypes.SET_GUIDE_ANALYTICS_DETAIL_SUMMARY_TABLE_DATA:
            return {
                ...state,
                guideAnalyticsDetailSummaryTableData: action.guideAnalyticsDetailSummaryTableData,
            };

        case actionTypes.SET_GUIDE_ANALYTICS_DETAIL_STEPS_FLOW_CHART:
            return {
                ...state,
                guideAnalyticsDetailStepsFlowChartData: action.guideAnalyticsDetailStepsFlowChartData,
            };

        case actionTypes.SET_GUIDE_ANALYTICS_DETAIL_DROPOFF_FUNNEL_CHART:
            return {
                ...state,
                guideAnalyticsDetailDropoffFunnelChartData: action.guideAnalyticsDetailDropoffFunnelChartData,
            };

        case actionTypes.SET_GUIDE_ANALYTICS_DETAIL_FUNNEL_STEPS_LIST:
            return {
                ...state,
                guideAnalyticsDetailFunnelStepsList: action.guideAnalyticsDetailFunnelStepsList,
            };

        case actionTypes.SET_GUIDE_ANALYTICS_DETAIL_SHOWME_CHART_DATA:
            return {
                ...state,
                guideAnalyticsDetailShowMeChartData: action.guideAnalyticsDetailShowMeChartData,
            };

        case actionTypes.SET_GUIDE_ANALYTICS_DETAIL_SHOWME_DOWNLOADS_CHART_DATA:
            return {
                ...state,
                guideAnalyticsDetailShowMeDownloadsChartData: action.guideAnalyticsDetailShowMeDownloadsChartData,
            };

        case actionTypes.SET_GUIDE_ANALYTICS_DETAIL_TESTME_CHART_DATA:
            return {
                ...state,
                guideAnalyticsDetailTestMeChartData: action.guideAnalyticsDetailTestMeChartData,
            };

        case actionTypes.SET_GUIDE_ANALYTICS_DETAIL_DOITFORME_CHART_DATA:
            return {
                ...state,
                guideAnalyticsDetailDoItForMeChartData: action.guideAnalyticsDetailDoItForMeChartData,
            };

        case actionTypes.SET_GUIDE_ANALYTICS_DETAIL_DOITFORME_TABLE_DATA:
            return {
                ...state,
                guideAnalyticsDetailDoItForMeTableData: action.guideAnalyticsDetailDoItForMeTableData,
            };

        case actionTypes.SET_GUIDE_ANALYTICS_DETAIL_DOITFORME_SOURCES_CHART_DATA:
            return {
                ...state,
                guideAnalyticsDetailDoItForMeSourcesChartData: action.guideAnalyticsDetailDoItForMeSourcesChartData,
            };

        case actionTypes.SET_GUIDE_ANALYTICS_DETAIL_SURVEY_CHART_DATA:
            return {
                ...state,
                guideAnalyticsDetailSurveyChartData: action.guideAnalyticsDetailSurveyChartData,
            };

        case actionTypes.SET_GUIDE_ANALYTICS_DETAIL_SURVEY_MODAL:
            return {
                ...state,
                guideAnalyticsDetailSurveyModalData: action.guideAnalyticsDetailSurveyModalData,
            };

        case actionTypes.SET_GUIDE_ANALYTICS_DETAIL_LINKED_TABLE_DATA:
            return {
                ...state,
                guideAnalyticsDetailLinkedTableData: action.guideAnalyticsDetailLinkedTableData,
            };

        case actionTypes.SET_GUIDE_USER_ACTIVITY:
            return {
                ...state,
                guideUserActivity: action.guideUserActivity,
            };

        case actionTypes.SET_GUIDE_ANALYTICS_DETAIL_SHOWME_SOURCES_CHART_DATA:
            return {
                ...state,
                guideAnalyticsDetailShowMeSourcesChartData: action.guideAnalyticsDetailShowMeSourcesChartData,
            };

        case actionTypes.SET_GUIDE_ANALYTICS_DETAIL_SHOWME_STEPS_DATA:
            return {
                ...state,
                guideAnalyticsDetailShowMeStepsData: action.guideAnalyticsDetailShowMeStepsData,
            };

        case actionTypes.SET_GUIDE_ANALYTICS_DETAIL_AUDIO_CHART:
            return {
                ...state,
                guideAnalyticsDetailAudioChart: action.guideAnalyticsDetailAudioChart,
            };
        
        case actionTypes.SET_GUIDE_ANALYTICS_DETAIL_TUTORIAL_GUIDES_CHART_DATA:
            return {
                ...state,
                guideAnalyticsDetailTutorialGuidesChartData: action.guideAnalyticsDetailTutorialGuidesChartData,
            };

        case actionTypes.SET_GUIDE_ANALYTICS_ERROR_DETAILS:
            return {
                ...state,
                guideAnalyticsDetailErrorData: action.guideAnalyticsDetailErrorData,
            };

        default:
            return state;
    }
};

export default guideAnalyticsDetailReducer;