import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { Link } from 'react-router-dom';
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation, useParams } from 'react-router-dom';

// Redux Imports
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { withReducer } from "store/withReducer";
import { CommonComponentState, LanguageState } from "store/Reducers";
import * as ActionTypes from "../../../store/Actions/ActionTypes";
import * as chatBotStatsDetailsActions from "store/Actions/index";
import { getCall, getCallExportExcel } from "utils/ApiCallActions";

import { CommonUtils } from "utils/CommonUtils";
import { ChartColors } from "utils/GlobalConstants";
import qs from 'querystring';
// SCSS Imports
import "./StatsDetails.scss";

import SectionTooltip from "components/SectionTooltip/SectionTooltip";
import SectionRefresh from "components/Layouts/SectionRefresh";
import CardContainer from "components/Layouts/CardContainer";
import ContainerTooltip from "components/Layouts/ContainerTooltip";
import Loader from "components/Loader/Loader";
import SectionHeader from "components/Layouts/SectionHeader";
import Datepicker from "components/Calender/Datepicker";
import SectionButtons from "components/Layouts/SectionButtons";
import ExportToPdf from "components/ExportToPdf/ExportToPdf";
import Maintenance from "components/Maintenance/Maintenance";
import SectionFilters from "components/Layouts/SectionFilters";

import chatBotStatsDetailsReducer, { ChatBotStatsDetailsState } from "store/Reducers/ChatBot/chatBotStatsDetails";
import LineChart from "components/Chart/nvd3Charts/LineChart";
import ConversationResponse from "components/ConversationResponse/ConversationResponse";
import ReactPaginate from "react-paginate";
import ConversationModal from "components/CommonModal/ConversationModal/ConversationModal";
import { InsightLabels } from "labels";
import { useErrorBoundary } from 'react-error-boundary';
import { v4 as uuidv4 } from 'uuid';
import UserInfoSection from "components/Layouts/UserInfoSection";
import { Dropdown, ButtonGroup, Button } from "react-bootstrap";
import Pagination from "components/Pagination/Pagination";

interface CurrentState {
    commonComponentData: CommonComponentState,
    chatBotStatsDetails: ChatBotStatsDetailsState,
    languageData: LanguageState

}

const StatsDetails: React.FC = (props: any) => {

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const { showBoundary } = useErrorBoundary();

    const location = useLocation();
    const navigate = useNavigate();


    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];
    let fetched_details = useSelector((state: CurrentState) => state);

    let urlData: any = {};

    const query = new URLSearchParams(location.search);
    query.forEach((v, k) => {
        urlData[k] = v;
    });

    const { conversation_code } = useParams();
    const conversationCode = conversation_code;

    const dispatch = useDispatch();

    const lineChartMargin = useMemo(() => {
        return {
            top: "50",
            right: "50",
            bottom: "50",
            left: "75",
        };
    }, [])

    let linechart_height = 320;
    const responsePerPage = 5;
    const componentRef = useRef() as React.RefObject<HTMLDivElement>;

    const [pageIndex, setPageIndex] = useState(0);

    const [errorCount, setErrorCount] = useState({
        errorCount: [0, 0,],
        showMaintenance_flag: false
    });

    const [loaderBtn, setLoaderBtn] = useState({
        loader0: false,
        loader1: false,
        disabled: true,
    });

    const [chatBotStatsDetailsChart, setChatBotStatsDetailsChart] = useState({
        loadChartData: [],
        chatBotStatsDetailsChartDataFetched: false,
        errorMsg: ''
    });

    const [chatBotStatsDetailsResponseList, setChatBotStatsDetailsResponseList] = useState({
        chatBotStatsDetailsResponseListData: [],
        chatBotStatsDetailsResponseListDataFetched: false,
        firstLoad: true,
        hideDeleted: false,
        errorMsg: ''
    });

    const [chatBotTitle, setChatBotTitle] = useState('');

    const [modalDetails, setModalDetails] = useState({
        isModalOpen: false,
        responseCode: "",
        userName: '',
        userEmail: '',
        timestamp: ''
    });

    const closeModal = () => {
        setModalDetails((prevState) => {
            return {
                ...prevState,
                isModalOpen: false,
                responseCode: "",
                userName: '',
                userEmail: '',
                timestamp: ''
            }
        })
    }

    const [request_uuid, setUuid] = useState({
        value: uuidv4()
    })
    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if(fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
    },[fetched_details.languageData.languageData])
    
    const getChatBotStatsDetailsChartData = (apiErrorFlag?: string) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "conversation_code": conversationCode
        };

        chatBotStatsDetailsActions.getChatBotStatsDetailsData(
            params,
            "CHATBOT_STATS_CHART",
            ActionTypes.SET_CHATBOT_STATS_DETAILS_CHART_DATA,
            apiErrorFlag ?? '',
        ).then((res: any) => dispatch(res));
    };

    const getChatBotStatsDetailsResponseListData = (apiErrorFlag?: string) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "conversation_code": conversationCode
        };

        chatBotStatsDetailsActions.getChatBotStatsDetailsData(
            params,
            "CHATBOT_STATS_DETAILS_RESPONSE_LIST",
            ActionTypes.SET_CHATBOT_STATS_DETAILS_RESPONSE_LIST_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    };

    const exportExcel = (data: any) => {
        let params = {
            "app_code": urlData["app_code"],
            "time_zone": timeZone,
            "start_date": urlData["fdate"] + "_00:00:00",
            "end_date": urlData["tdate"] + "_23:59:59",
        };
        getCallExportExcel(params, 'CHATBOT_STATS_DETAILS_RESPONSE_LIST', data);
    }

    //Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const enableExportPdf = (loaderNumber: number, val: boolean) => {
        try {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    ['loader' + loaderNumber]: val
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    }

    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) =>
                        i === index ? option : item
                    ),
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    const refreshSections = (optn: number) => {
        try {
            enableExportPdf(optn, false);
            if (optn === 0) {
                setChatBotStatsDetailsChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadChartData: [],
                        chatBotStatsDetailsChartDataFetched: false,
                    };
                });
                getChatBotStatsDetailsChartData();
            } else if (optn === 1) {
                setChatBotStatsDetailsResponseList((prevState: any) => {
                    return {
                        ...prevState,
                        chatBotStatsDetailsResponseListDataFetched: false,
                        firstLoad: true,
                        hideDeleted: false,
                    };
                });
                getChatBotStatsDetailsResponseListData();
            }
        } catch (error) {
            showBoundary(error)
        }

    };

    // UseEffects @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    //Bar Chart
    useEffect(() => {
        try {
            if (
                fetched_details?.chatBotStatsDetails?.chatBotStatsDetailsChartData?.data !== undefined &&
                fetched_details?.chatBotStatsDetails?.chatBotStatsDetailsChartData?.result === "success"
            ) {
                let chatBotChartData: any = [];

                chatBotChartData = fetched_details.chatBotStatsDetails.chatBotStatsDetailsChartData.data.graphData;

                setChatBotStatsDetailsChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadChartData: [...chatBotChartData],
                        chatBotStatsDetailsChartDataFetched: true,
                        errorMsg: ''
                    };
                });
                apiCatchError(0, 0);
                enableExportPdf(0, true)

            } else if (fetched_details?.chatBotStatsDetails?.chatBotStatsDetailsChartData?.result === 'retry') {
                getChatBotStatsDetailsChartData('retry');
            } else if (
                fetched_details?.chatBotStatsDetails?.chatBotStatsDetailsChartData?.result === "error"
            ) {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;
                if (fetched_details.chatBotStatsDetails.chatBotStatsDetailsChartData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }

                setChatBotStatsDetailsChart((prevState: any) => {
                    return {
                        ...prevState,
                        chatBotStatsDetailsChartDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(0, errorFlag);
                enableExportPdf(0, false)
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details?.chatBotStatsDetails?.chatBotStatsDetailsChartData]);

    // List
    useEffect(() => {
        try {
            if (
                fetched_details?.chatBotStatsDetails?.chatBotStatsDetailsResponseListData?.data !== undefined &&
                fetched_details?.chatBotStatsDetails?.chatBotStatsDetailsResponseListData?.result === "success"
            ) {
                setChatBotStatsDetailsResponseList((prevState: any) => {
                    return {
                        ...prevState,
                        chatBotStatsDetailsResponseListData: fetched_details.chatBotStatsDetails.chatBotStatsDetailsResponseListData.data,
                        chatBotStatsDetailsResponseListDataFetched: true,
                        errorMsg: ''
                    };
                });

                setChatBotTitle(fetched_details.chatBotStatsDetails.chatBotStatsDetailsResponseListData.data.conversationTitle);
                apiCatchError(1, 0);
                enableExportPdf(1, true);
            } else if (fetched_details?.chatBotStatsDetails?.chatBotStatsDetailsResponseListData?.result === 'retry') {
                getChatBotStatsDetailsResponseListData('retry');
            } else if (fetched_details?.chatBotStatsDetails?.chatBotStatsDetailsResponseListData?.result === "error") {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;
                if (fetched_details.chatBotStatsDetails.chatBotStatsDetailsResponseListData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }

                setChatBotStatsDetailsResponseList((prevState: any) => {
                    return {
                        ...prevState,
                        chatBotStatsDetailsResponseListDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(1, errorFlag);
                enableExportPdf(1, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.chatBotStatsDetails?.chatBotStatsDetailsResponseListData]);

    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (insightsLbls !== undefined) {
            document.title = insightsLbls['chatBotDetail'];
        }

        window.scrollTo(0, 0);
        CommonUtils.LeftPanelHighlight(4, 0, 0, true, false);
    }, [insightsLbls])

    useEffect(() => {
        try {
            let queryParams: any = {
                app_id: +urlData['redirect'] === 1 ? urlData['app_id'] : fetched_details?.commonComponentData.appsData.appId,
                app_code: +urlData['redirect'] === 1 ? urlData['app_code'] : fetched_details?.commonComponentData.appsData.appCode,
                fdate: fetched_details?.commonComponentData.datesData.fdate,
                tdate: fetched_details?.commonComponentData.datesData.tdate,
            }

            let component_path: any[] = location.pathname.split('/');
            component_path = component_path.filter(function (el) {
                return el != '';
            });

            navigate({
                pathname: "/chatBot/chatbot-details/" + component_path[component_path.length - 1] + '/',
                search: qs.stringify(queryParams),
            });

            if (fetched_details?.commonComponentData.datesData.source === 'component' && urlData['app_code'] == localStorage.getItem('switch_app_code') && fetched_details?.commonComponentData.datesData?.url?.includes('chatbot-details')) {
                getChatBotStatsDetailsChartData();
                getChatBotStatsDetailsResponseListData();
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details?.commonComponentData.datesData]);


    // Call summary table api on table related actions changes
    // useEffect(() => {
    //     if (fetched_details?.chatBotStatsDetails?.chatBotStatsDetailsResponseListData?.data) {
    //         enableExportPdf(1, false);
    //         getChatBotStatsDetailsResponseListData();
    //     }
    // }, [
    //     chatBotStatsDetailsResponseList.chatBotStatsDetailsResponseListDataFetched,
    //     chatBotStatsDetailsResponseList.firstLoad,
    //     chatBotStatsDetailsResponseList.hideDeleted
    // ]);

    // Export PDF Btn
    useEffect(() => {
        try {
            if (loaderBtn.loader0 && loaderBtn.loader1) {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: false,
                    };
                });
            }
            else {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: true,
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [loaderBtn.loader0, loaderBtn.loader1])


    useEffect(() => {
        try {
            const result = errorCount.errorCount.every((val) => val === 1);
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    showMaintenance_flag: result
                }
            })
        } catch (error) {
            showBoundary(error)
        }


    }, [errorCount.errorCount])

    const handlePageChange = (e: any) => {
        try {
            setPageIndex(e);
        } catch (error) {
            showBoundary(error)
        }

    }

    const openConversationModal = (responseCode: any, userName: any, userEmail: any, timestamp: any) => {
        try {
            setModalDetails((prevState) => {
                return {
                    ...prevState,
                    isModalOpen: true,
                    responseCode: responseCode,
                    userName: userName,
                    userEmail: userEmail,
                    timestamp: timestamp
                }
            });
        } catch (error) {
            showBoundary(error)
        }

    }

    const navigateToMainPage = () => {
        let queryParams = {
            app_id: fetched_details?.commonComponentData.appsData.appId,
            app_code: fetched_details?.commonComponentData.appsData.appCode,
            fdate: fetched_details?.commonComponentData.datesData.fdate,
            tdate: fetched_details?.commonComponentData.datesData.tdate,
        }
        navigate({
            pathname: `/chatbot/chatbot/`,
            search: qs.stringify(queryParams)
        })
    }

    return (
        <section className="demo surveyStatsDetails width100" ref={componentRef}>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <SectionHeader>
                        <li>
                            <span className="cursorPointer" onClick={() => navigateToMainPage()}>{insightsLbls['chatBot']}</span>
                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                        </li>
                        <li className="active">{insightsLbls['chatBotDetail']}</li>
                    </SectionHeader>
                </div>

                <SectionFilters>
                    <Datepicker source={'component'} />
                </SectionFilters>
            </div>

            {/* <SectionButtons sectionTitle={chatBotTitle} svgImage={"surveys"} className={'marginTop-50'}> */}
                {/* <ExportToPdf
                    componentRef={componentRef}
                    source={"ChatBotStats"}
                    widthDividedBy={12}
                    disabled={loaderBtn.disabled}
                ></ExportToPdf> */}
            {/* </SectionButtons> */}


            <div className="marginTop-60 exportBtn-div">
                <Dropdown as={ButtonGroup}>
                    <Button variant="info">{insightsLbls.export}</Button>
                    <Dropdown.Toggle split variant="success" id="dropdown-custom-2" />
                    <Dropdown.Menu className="super-colors">
                        <Dropdown.Item eventKey="1" >
                            <ExportToPdf
                                componentRef={componentRef}
                                source={"ChatBotStats"}
                                widthDividedBy={12}
                                disabled={loaderBtn.disabled}
                            ></ExportToPdf>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <UserInfoSection
                startDate={fetched_details?.commonComponentData?.datesData.fdate}
                endDate={fetched_details?.commonComponentData?.datesData.tdate}
                messageText={chatBotTitle}>
            </UserInfoSection>

            <div id="chatBotStatsDetailsSection">
                {
                    // <SectionRefresh sectionTitle={insightsLbls.chatBotDetail} refreshClass={""}>
                    //     <i
                    //         className="fa fa-refresh pull-right pointer row displayFlex"
                    //         aria-hidden="true" title={insightsLbls['refresh']}
                    //         onClick={() => refreshSections(0)}>
                    //     </i>
                    // </SectionRefresh>
                }

                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 minHeight450 height100-percent row marginLR-0"}>
                        <div className="col-sm-12 paddingTop-20 overflowX chartdiv search_section section-wrapper-right minHeight450 no-border-right">
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                <SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.chatBotDetail,
                                        "tooltipText": insightsLbls.lineChartConversationStatTooltip,
                                        "childern": []
                                    }
                                } placement="right"></SectionTooltip>
                                <button className="pointer refreshBtn" onClick={() => refreshSections(0)}> {insightsLbls.refresh}</button>

                            </ContainerTooltip>

                            <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section no-padding">

                                {chatBotStatsDetailsChart.chatBotStatsDetailsChartDataFetched ? (
                                    errorCount.errorCount[0] === 0 ? (
                                        chatBotStatsDetailsChart.loadChartData.length === 0 ? (
                                            <div className="noDataText-font textCenter-absolute">
                                                {insightsLbls.noRecordFound}
                                            </div>
                                        ) : (

                                            <LineChart
                                                chartEntities={['conversationsResponse']}
                                                optionEntities={
                                                    [
                                                        {
                                                            key: insightsLbls.conversationsResponse,
                                                            color: ChartColors.color1, area: true
                                                        }
                                                    ]}
                                                chartData={fetched_details?.chatBotStatsDetails?.chatBotStatsDetailsChartData?.data?.graphData}
                                                value_type={"count"}
                                                height={linechart_height}
                                                margin={lineChartMargin}
                                                yAxisLabel={insightsLbls.yAxisLabelCount}
                                                refId="chatbot_conversationResponse">
                                            </LineChart>

                                        )
                                    ) : (
                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                            {insightsLbls.serviceNotAvailable}
                                        </h5>
                                    )
                                ) : (
                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader></Loader>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </CardContainer>
                </div>
                {
                    // <SectionRefresh sectionTitle={insightsLbls["chatBotResponse"]} refreshClass={"marginTop-30"}>
                    //     <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls['refresh']} onClick={() => refreshSections(1)}></i>
                    // </SectionRefresh>
                }
                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section displayContent">
                            <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                <SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls["chatBotResponse"],
                                        "tooltipText": insightsLbls['userResponses'],
                                        "childern": []
                                    }
                                } placement="right"></SectionTooltip>
                                <button className="pointer refreshBtn" onClick={() => refreshSections(1)}> {insightsLbls.refresh}</button>

                            </ContainerTooltip>

                            {chatBotStatsDetailsResponseList.chatBotStatsDetailsResponseListDataFetched ? (
                                errorCount.errorCount[1] === 0 ? (
                                    chatBotStatsDetailsResponseList.chatBotStatsDetailsResponseListData.length === 0 ? (
                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <div className="noDataText-font textCenter-absolute">
                                                    {insightsLbls.noRecordFound}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            {(chatBotStatsDetailsResponseList.chatBotStatsDetailsResponseListData["responseDetails"] as []).map((val, ind) => {
                                                if (ind >= (pageIndex * responsePerPage) && ind < ((pageIndex + 1) * responsePerPage)) {
                                                    return <ConversationResponse data={val} openConversationModal={() => openConversationModal(val["responseCode"], val["userName"],
                                                     val["userEmail"], val["timestamp"])}></ConversationResponse>
                                                }
                                            })}
                                            <div className="paginationContainer">
                                                {/* <ReactPaginate
                                                    previousLabel={<span aria-hidden="true">«</span>}
                                                    nextLabel={<span className={"nexBtn" + props.cardId} aria-hidden="true">»</span>}
                                                    breakLabel={"..."}
                                                    breakClassName={"break-me"}
                                                    pageCount={chatBotStatsDetailsResponseList.chatBotStatsDetailsResponseListData["totalResponseCount"] / responsePerPage}
                                                    marginPagesDisplayed={2}
                                                    pageRangeDisplayed={responsePerPage}
                                                    onPageChange={(event) => handlePageChange(event)}
                                                    containerClassName={"pagination"}
                                                    activeClassName={"active"}
                                                /> */}
                                                <Pagination
                                                    pageNumber={0}
                                                    showEntries={false}
                                                    totalCount={chatBotStatsDetailsResponseList.chatBotStatsDetailsResponseListData["totalResponseCount"]}
                                                    pageLimit={responsePerPage}
                                                    handleChange={(event)=> handlePageChange(event)}
                                                    rowsLabel={insightsLbls.rowsPerPage}
                                                    changeLimit={(event)=> {}}
                                                 />
                                            </div>
                                            {modalDetails.isModalOpen &&
                                                <ConversationModal
                                                    data={modalDetails}
                                                    closeConversationModal={closeModal}
                                                    appCode={fetched_details?.commonComponentData.appsData.appCode}
                                                    conversationCode={conversationCode}
                                                    responseCode={modalDetails.responseCode}></ConversationModal>}
                                        </>
                                    )
                                ) : (
                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                {chatBotStatsDetailsResponseList.errorMsg}
                                            </h5>
                                        </div>
                                    </div>
                                )
                            ) : (
                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <Loader></Loader>
                                    </div>
                                </div>
                            )}


                        </div>
                    </CardContainer>
                </div>

            </div>

            <div className="overlayCursor" id="overlayCursor"></div>
            {errorCount.showMaintenance_flag && (
                <Maintenance></Maintenance>
            )}
        </section>

    )
}
export default withRouter(withReducer("chatBotStatsDetails", chatBotStatsDetailsReducer)(StatsDetails));
