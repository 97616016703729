// Modified by: Mrunal Mane
// On: 2024-05-10
// Reason: Removed language dependancy from useEffect (props)

import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';
import SVG from "react-inlinesvg";

import { useDispatch, useSelector } from 'react-redux';
import { DatesState, AppsState, EventSourceState, CommonComponentState, LanguageState } from 'store/Reducers';
import Loader from 'components/Loader/Loader';
import * as ActionTypes from "store/Actions/ActionTypes";
import * as userDetailActions from "store/Actions/index";


import './UserActivity.scss';
import CommonModal from '../CommonModal';
import { GuideAnalyticsDetailState } from 'store/Reducers/GuideInsights/guideAnalyticsDetail';
import { TooltipsDetailState } from 'store/Reducers/GuideInsights/tooltipsDetail';
import Filter from 'components/Filter/Filter';
import ReactPaginate from 'react-paginate';
import { InsightLabels } from 'labels';
import { useErrorBoundary } from 'react-error-boundary';
interface CurrentState {
    dates: DatesState,
    guideAnalyticsDetail: GuideAnalyticsDetailState,
    tooltipsDetails: TooltipsDetailState,
    apps: AppsState,
    eventSource: EventSourceState,
    commonComponentData: CommonComponentState,
    languageData: LanguageState
}


const UserActivity = (props: any) => {

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const { showBoundary } = useErrorBoundary();


    const dispatch = useDispatch();

    let fetched_details = useSelector((state: CurrentState) => state);

    let fetched_guide_details: any = {};


    const data = useSelector((state: CurrentState) => state);
    if (props.source === 'guide') {
        fetched_guide_details = data.guideAnalyticsDetail.guideUserActivity;
    } else {
        fetched_guide_details = data.tooltipsDetails.tooltipsDetailUserActivity;
    }


    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];

    const [currentPage, setCurrentPage] = useState(0);
    const PER_PAGE = 10;




    const colors = [{ char: 'A', color: '#AF9595' },
    { char: 'B', color: '#EBADAD' },
    { char: 'C', color: '#FE9469' },
    { char: 'D', color: '#FFA477' },
    { char: 'E', color: '#FFA54D' },
    { char: 'F', color: '#ECC084' },
    { char: 'G', color: '#C5FF6C' },
    { char: 'H', color: '#A1C87A' },
    { char: 'I', color: '#8DE5BA' },
    { char: 'J', color: '#4E9FFE' },
    { char: 'K', color: '#D18CFF' },
    { char: 'L', color: '#FF4DFF' },
    { char: 'M', color: '#C0FF00' },
    { char: 'N', color: '#00FF7F' },
    { char: 'O', color: '#E9967A' },
    { char: 'P', color: '#FFE5B4' },
    { char: 'Q', color: '#FF66CC' },
    { char: 'R', color: '#F88379' },
    { char: 'S', color: '#FBEC5D' },
    { char: 'T', color: '#FAD6A5' },
    { char: 'U', color: '#99CCFF' },
    { char: 'V', color: '#FFFFCC' },
    { char: 'W', color: '#FFFF99' },
    { char: 'X', color: '#E0E0E0' },
    { char: 'Y', color: '#A5BBCA' },
    { char: 'Z', color: '#BEA5CA' }];




    let eventBy = [
        { key: 'GuideMe', value: 'GuideMe' },
        // { key: 'ShowMe', value: 'ShowMe' },
        { key: 'DoItForMe', value: 'DoItForMe' },

    ];

    const [activityModal, setActivityModal] = useState({
        open: false,
        title: '',
        data: [],
        dataFetched: false,
        guideFlag: true,
        pageCount: 0,
        currentPage: 0,
        searchText: '',
        forcePage: 0
    });

    const [errorCount, setErrorCount] = useState({
        errorCount: [0],
    });


    // useEffect(() => {
    //     if (activityModal.open) {
    //         if (props.source === 'guide') {
    //             getUserActivity('guide', '', true);
    //         } else {
    //             getUserActivity('tooltip', '', true);
    //         }
    //     }
    // }, [activityModal.open])

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        try {
            setActivityModal((prevState: any) => {
                return {
                    ...prevState,
                    open: true,
                }
            });
        } catch (error) {
            showBoundary(error)
        }

    }, [props])

    const closeSurveyModal = () => {
        try {
            setActivityModal((prevState: any) => {
                return {
                    ...prevState,
                    open: false,
                };
            });
            props.closeModal();
        } catch (error) {
            showBoundary(error)
        }

    }


    const getUserActivity = (type: string, searchText: string, firstTimeLoadFlag: boolean) => {
        try {
            switch (type) {
                case 'guide':
                    {
                        let params = {
                            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
                            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
                            "time_zone": timeZone,
                            "app_code": fetched_details?.commonComponentData.appsData.appCode,
                            "guide_id": props.guideId,
                            'search_text': encodeURIComponent(searchText),
                            "enable_real_time": "",
                            'exclude_weekends': fetched_details.dates.excludeFlag,
                            "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceGuides,
                            "env_code": fetched_details?.commonComponentData?.envFilter?.envCode ?? '',
                            "play_type": 'guide',
                            'page_index': activityModal.currentPage + 1,
                            'page_size': 10,
                        };

                        userDetailActions.getGuideAnalyticsDetailData(
                            params,
                            "ACTIVITY",
                            ActionTypes.SET_GUIDE_USER_ACTIVITY,
                            '',
                            firstTimeLoadFlag,
                        ).then((res: any) => dispatch(res));

                        setActivityModal((prevState: any) => {
                            return {
                                ...prevState,
                                guideFlag: true
                            }
                        })
                    }
                    break;

                case 'doitforme':
                    {
                        let params = {
                            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
                            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
                            "time_zone": timeZone,
                            "app_code": fetched_details?.commonComponentData.appsData.appCode,
                            "guide_id": props.guideId,
                            'search_text': encodeURIComponent(searchText),
                            "enable_real_time": "",
                            'exclude_weekends': fetched_details.dates.excludeFlag,
                            "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceGuides,
                            "env_code": fetched_details?.commonComponentData?.envFilter?.envCode ?? '',
                            "play_type": 'do_it_for_me',
                            'page_index': activityModal.currentPage + 1,
                            'page_size': 10,
                        };

                        userDetailActions.getGuideAnalyticsDetailData(
                            params,
                            "ACTIVITY",
                            ActionTypes.SET_GUIDE_USER_ACTIVITY,
                            '',
                            firstTimeLoadFlag,
                        ).then((res: any) => dispatch(res));

                        setActivityModal((prevState: any) => {
                            return {
                                ...prevState,
                                guideFlag: false
                            }
                        })
                    }
                    break;

                case 'showme':
                    {
                        let params = {
                            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
                            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
                            "time_zone": timeZone,
                            'page_index': 1,
                            'page_size': 10,
                            'search_text': encodeURIComponent(searchText),
                            'sort_by': 'last_played_on',
                            'order': 'desc',
                            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag
                        };

                        userDetailActions.getGuideAnalyticsDetailData(
                            params,
                            "SHOW_SUMMARY_USER",
                            ActionTypes.SET_GUIDE_USER_ACTIVITY,
                            '',
                            false,
                            props.guideId
                        ).then((res: any) => dispatch(res));

                        setActivityModal((prevState: any) => {
                            return {
                                ...prevState,
                                guideFlag: false
                            }
                        })
                    }
                    break;

                case 'tooltip':
                    {
                        let params = {
                            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
                            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
                            "time_zone": timeZone,
                            "tooltip_id": props.guideId,
                            "event_source": fetched_details.eventSource.eventSourceTooltips,
                            "page_index": 1,
                            "page_size": 10,
                            "enable_real_time": true,
                            "app_code": fetched_details?.commonComponentData.appsData.appCode,
                            'search_text': encodeURIComponent(searchText)
                        };

                        userDetailActions.getTooltipsDetailData(
                            params,
                            "TOOLTIPS_DETAIL_USER_ACTIVITY",
                            ActionTypes.SET_TOOLTIPS_DETAIL_USER_ACTIVITY,
                            '',
                            false,
                        ).then((res: any) => dispatch(res));

                        setActivityModal((prevState: any) => {
                            return {
                                ...prevState,
                                guideFlag: true
                            }
                        })
                    }
                    break;


                default:

                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    useEffect(() => {
        try {
            if (
                fetched_guide_details.data !== undefined &&
                fetched_guide_details.result === "success"
            ) {

                setActivityModal((prevState: any) => {
                    return {
                        ...prevState,
                        data: fetched_guide_details.data.userData,
                        dataFetched: true,
                    }
                })

                apiCatchError(0, 0);
            } else if (fetched_guide_details.result === "error") {
                setActivityModal((prevState: any) => {
                    return {
                        ...prevState,
                        data: [],
                        dataFetched: true,

                    }
                })
                apiCatchError(0, 1);

            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_guide_details])

    // useEffect(() => {

    //     if (
    //         fetched_guide_details.data !== undefined &&
    //         fetched_guide_details.result === "success"
    //     ) {
    //         const offset = currentPage * PER_PAGE;
    //         const currentPageData = fetched_guide_details.data.userData
    //             .slice(offset, offset + PER_PAGE)


    //         setActivityModal((prevState: any) => {
    //             return {
    //                 ...prevState,
    //                 data: currentPageData
    //             }
    //         })
    //     }


    // }, [fetched_guide_details, currentPage]);


    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) =>
                        i === index ? option : item
                    ),
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    const getChar = (name: string) => {
        return name ? name.charAt(0) : '';
    }

    const getBgColor = (name: string) => {
        try {
            if (name) {
                let charUpper = name.charAt(0).toUpperCase();
                return colors.filter((item: any) => item.char === charUpper)[0].color;
            } else {
                return '';
            }
        } catch (error) {
            showBoundary(error)
        }
    }

    const handleClick = useCallback((option: string, data: any) => {
        try {
            const elem = document.getElementById('serachInput') as HTMLInputElement;
            if (elem) {
                elem.value = '';
            }

            if (data === 'GuideMe') {
                setActivityModal((prevState: any) => {
                    return {
                        ...prevState,
                        data: [],
                        dataFetched: false,
                        guideFlag: true
                    }
                })
                if (props.source === 'guide') {
                    getUserActivity('guide', '', true);
                } else {
                    getUserActivity('tooltip', '', true);
                }

            } else if (data === 'DoItForMe') {
                setActivityModal((prevState: any) => {
                    return {
                        ...prevState,
                        data: [],
                        dataFetched: false,
                        guideFlag: false
                    }
                })

                if (props.source === 'guide') {
                    getUserActivity('doitforme', '', true)
                }
            } else {
                setActivityModal((prevState: any) => {
                    return {
                        ...prevState,
                        data: [],
                        dataFetched: false,
                        guideFlag: false
                    }
                })
            }

        } catch (error) {
            showBoundary(error)
        }

    }, [activityModal]);

    const onSearchText = (e: ChangeEvent<HTMLInputElement>) => {
        try {
            setActivityModal((prevState: any) => {
                return {
                    ...prevState,
                    dataFetched: false,
                    searchText: e.target.value,
                    currentPage: 0
                }
            })

            if (props.source === 'guide') {
                getUserActivity(activityModal.guideFlag ? 'guide' : 'doitforme', e.target.value, false)
            } else {
                getUserActivity('tooltip', e.target.value, false)

            }
        } catch (error) {
            showBoundary(error)
        }
    };

    function handlePageClick(e: any) {
        try {
            setActivityModal((prevState: any) => {
                return {
                    ...prevState,
                    currentPage: e.selected,
                    forcePage: e.selected
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    }

    useEffect(() => {
        try {
            setActivityModal((prevState: any) => {
                return {
                    ...prevState,
                    dataFetched: false,
                }
            })
            getUserActivity(activityModal.guideFlag ? 'guide' : 'doitforme', activityModal.searchText, false)
        } catch (error) {
            showBoundary(error)
        }
    }, [activityModal.currentPage])

    return (
        <div>
            <CommonModal
                modalState={activityModal.open}
                dispatchModalState={closeSurveyModal}
                modalTitle={insightsLbls.activityDetails}
                size={'lg'}
                footerDisabled={true}
                exportModal={true}
                padding={true}
                modalDialogClass={'userActivity-modal'}>
                {/* displayGrid */}
                <div className="">
                    <div className="col-lg-12 paddingLR-35 searchBox inline-block minHeight90">
                        {/* <p className="guideTitle userActivity-title">
                            {props.source === 'guide'
                                ? (<Icon img={'img/guidePlay.svg'} className="guidePlay-icon-table marginRight-5" />)
                                : (<Icon img={'img/tooltip.svg'} className="guidePlay-icon-table marginRight-5" />)
                            }

                            <b>{props.guideName}</b>
                        </p> */}


                        <p className="displayFlex search-field-wrapper floatRT alignCenter">
                            {props.source === 'guide' && (
                                <Filter data={eventBy}
                                    filterName={"Type"}
                                    filterID={1}
                                    filterDisabled={false}
                                    clickEvent={handleClick}>
                                </Filter>
                            )}

                            <input type="text"
                                className="searchIcon form-control round-input floatRight searchPosition"
                                placeholder={insightsLbls.searchUser}
                                autoComplete="off"
                                onChange={(event) => onSearchText(event)}
                                id="serachInput"
                                disabled={fetched_guide_details.firstTimeload && fetched_guide_details.data.totalCount === 0}
                            />
                        </p>
                    </div>

                    {activityModal.dataFetched ? (
                        errorCount.errorCount[0] === 0 ? (
                            activityModal.data.length === 0 ? (
                                <div className="minHeight300">
                                    <div className="noDataText-font textCenter-absolute">
                                        {insightsLbls.noRecordFound}
                                    </div>
                                </div>
                            ) : (
                                <div className="user-modal">

                                    {activityModal.data.map((option: any, index: number) => {
                                        return (
                                            <div className="displayFlex userCard" key={index}>
                                                <div className="displayFlex col-sm-6 col-md-6 col-lg-6 col-6">
                                                    <div>
                                                        {
                                                            // (option.full_name !== " " && option.full_name.length > 0) && 
                                                            (
                                                                <div className="userInitial textTransform" style={{ backgroundColor: getBgColor(option.displayName.length > 0 ? option.displayName : option.user_id) }}>
                                                                    <span className="textTransform">{getChar(option.displayName.length > 0 ? option.displayName : option.user_id)}</span>
                                                                </div>
                                                            )}
                                                    </div>
                                                    <div className="userDetails">
                                                        <p className="marginBottom-5 font14 userName">{option.displayName}</p>
                                                        <p className="marginBottom-5 font13">{option.userEmail}</p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-6 col-6 displayBlock">

                                                    {activityModal.guideFlag
                                                        ? (
                                                            <div className="guide-status" >
                                                                {/* {activityModal.guideFlag} */}

                                                                {props.source === 'tooltip'
                                                                    ? (
                                                                        <span className=" completedSpan">{option.count} {insightsLbls.tooltipPlay} </span>
                                                                    )
                                                                    : (
                                                                        <>
                                                                            <span className=" completedSpan">{option.completedCount} {insightsLbls.completed}</span>
                                                                            <span className=" playedSpan">{option.playedCount} {insightsLbls.played}</span>
                                                                        </>
                                                                    )}
                                                            </div>
                                                        )
                                                        : (
                                                            (
                                                                <div className="guide-status" >
                                                                    <span className=" completedSpan">{option.completedCount} {insightsLbls.completed}</span>
                                                                    <span className=" playedSpan">{option.playedCount} {insightsLbls.played}</span>
                                                                </div>
                                                            )
                                                            // <div className="guide-status">
                                                            //     {/* {activityModal.guideFlag} */}
                                                            //     <span className="videoSpan">{option.guide_video_count} {insightsLbls.video}</span>
                                                            //     <span className="gifSpan">{option.guide_gif_count} {insightsLbls.gif}</span>
                                                            //     <span className="slideshowSpan">{option.guide_slideshow_count} {insightsLbls.slideshow}</span>
                                                            // </div>

                                                        )
                                                    }


                                                    {/* <div className="playDetails">
                                                        {props.source === 'guide' &&
                                                            (
                                                                <div className="marginBottom-5 lastPlay-text">{insightsLbls.lastPlayedOn} {option.last_played_on}</div>
                                                            )}
                                                    </div> */}
                                                </div>
                                            </div>
                                        )
                                    })}

                                    <div className="paginationContainer">
                                        <ReactPaginate
                                            previousLabel={<span aria-hidden="true">«</span>}
                                            nextLabel={<span className={"nexBtn"} aria-hidden="true">»</span>}
                                            pageCount={fetched_guide_details?.data?.totalCount / 10}
                                            onPageChange={(event) => handlePageClick(event)}
                                            containerClassName={"pagination"}
                                            previousLinkClassName={"pagination__link"}
                                            nextLinkClassName={"pagination__link"}
                                            disabledClassName={"pagination__link--disabled"}
                                            activeClassName={"pagination__link--active"}
                                            breakLabel={"..."}
                                            breakClassName={"break-me"}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            forcePage={activityModal.forcePage} />
                                    </div>
                                </div>
                            )
                        ) : (
                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                {insightsLbls.serviceNotAvailable}
                            </h5>
                        )
                    ) : (
                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                            <div className="displayFlex alignCenter margin-0-auto">
                                <Loader></Loader>
                            </div>
                        </div>
                    )}
                </div>
            </CommonModal>
        </div>
    )

};

export default React.memo(UserActivity);