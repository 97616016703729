import * as actionTypes from '../../Actions/ActionTypes';

export interface PageState {
    blockChartData: any,
    usageData: any,
    summaryDataUntagged: any,
    summaryDataTagged: any,
    labelList: any,
}



const initialState = {
    blockChartData: [],
    usageData: [],
    summaryDataUntagged: [],
    summaryDataTagged: [],
    labelList: [],
};


export const pageReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SET_BLOCK_CHART:
            return {
                ...state,
                blockChartData: action.blockChartData,
            };

        case actionTypes.SET_USAGE_DATA:
            return {
                ...state,
                usageData: action.usageData,
            };

        case actionTypes.SET_PAGE_SUMMARY_UNTAGGED:
            return {
                ...state,
                summaryDataUntagged: action.summaryDataUntagged,
            };

        case actionTypes.SET_PAGE_SUMMARY_TAGGED:
            return {
                ...state,
                summaryDataTagged: action.summaryDataTagged,
            };

        case actionTypes.SET_LABEL_LIST:
            return {
                ...state,
                labelList: action.labelList,
            };

        case actionTypes.POST_TAG:
            return {
                ...state,
                tagData: action.tagData,
            };

        // case actionTypes.ON_PAGE_ENTER:
        //   return {
        //     blockChartData: [],
        //     usageData: [],
        //     summaryDataUntagged: [],
        //     summaryDataTagged: [],
        //     labelList: [],
        //   };

        default:
            return state;
    }
};




export interface PagePostState {
    tagData: any
}

const initialPostState = {
    tagData: []
};

export const pagePostReducer = (state = initialPostState, action: any) => {
    switch (action.type) {
        case actionTypes.POST_TAG:
            return {
                ...state,
                tagData: action.tagData,
            };
        default:
            return state;
    }
};

