import * as ActionTypes from '../ActionTypes';
import axiosInstance, { getInstance } from '../../../network/apis/interceptor';
import { CommonUtils } from '../../../utils/CommonUtils';
import {  ApiRelativePaths, RetryApi } from '../../../utils/GlobalConstants';


let ApiPaths: any = ApiRelativePaths;


export const setUserEngagementGuideMeChart = (userEngagementGuideMeChartData: any) => {
    return {
        type: ActionTypes.SET_USER_ENGAGEMENT_GUIDEME_CHART_DATA,
        userEngagementGuideMeChartData: userEngagementGuideMeChartData
    }
};

export const setUserEngagementShowMeChart = (userEngagementShowMeChartData: any) => {
    return {
        type: ActionTypes.SET_USER_ENGAGEMENT_SHOWME_CHART_DATA,
        userEngagementShowMeChartData: userEngagementShowMeChartData
    }
};

export const setUserEngagementTestMeChart = (userEngagementTestMeChartData: any) => {
    return {
        type: ActionTypes.SET_USER_ENGAGEMENT_TESTME_CHART_DATA,
        userEngagementTestMeChartData: userEngagementTestMeChartData
    }
};

export const setUserEngagementDoItForMeChart = (userEngagementDoItForMeChartData: any) => {
    return {
        type: ActionTypes.SET_USER_ENGAGEMENT_DO_IT_FOR_ME_CHART_DATA,
        userEngagementDoItForMeChartData: userEngagementDoItForMeChartData
    }
};

export const setUserEngagementTutorialGuidesChart = (userEngagementTutorialGuidesChartData: any) => {
    return {
        type: ActionTypes.SET_USER_ENGAGEMENT_TUTORIAL_GUIDES_CHART_DATA,
        userEngagementTutorialGuidesChartData: userEngagementTutorialGuidesChartData
    }
};

export const setUserEngagementSummaryTable = (userEngagementSummaryTableData: any) => {
    return {
        type: ActionTypes.SET_USER_ENGAGEMENT_SUMMARY_TABLE_DATA,
        userEngagementSummaryTableData: userEngagementSummaryTableData
    }
};

export const setUserEngagementUserChartData = (userEngagementUserChartData: any) => {
    return {
        type: ActionTypes.SET_USER_ENGAGEMENT_USER_CHART_DATA,
        userEngagementUserChartData: userEngagementUserChartData
    }
};


export const getUserEngagementData = (params: any, url_path: any, caseType: any, apiErrorFlag: string, firstTimeload?: boolean, optionSelection?:number) => {
    return new Promise((resolve, reject) => {
        let path = ApiPaths[url_path];
        let data:any = {};
        let paramObj = {};
        let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
        getInstance('guide-insights/user-engagement/');


        axiosInstance.get(generatedURL)
        .then(res => {
            data = {
              "result": "success",
              "data": res.data.data,
              "firstTimeload": firstTimeload ?? false,
              "optionSelection": optionSelection ?? -1,
              "requestUUID": res.data.requestUUID,
            };
        })
        .catch(err => {
            if (apiErrorFlag === '') {
                data = {
                    "result": "retry",
                    "data": err,
                    "firstTimeload": firstTimeload ?? false,
                };
            } else {
                data = {
                    "result": "error",
                    "data": err,
                    "firstTimeload": firstTimeload ?? false,
                };
            }
        })
        .then(() => {
            const callActionDispatch = () => {
                switch (caseType) {
                    case ActionTypes.SET_USER_ENGAGEMENT_GUIDEME_CHART_DATA:
                        resolve(setUserEngagementGuideMeChart(data));
                        break;

                    case ActionTypes.SET_USER_ENGAGEMENT_SHOWME_CHART_DATA:
                        resolve(setUserEngagementShowMeChart(data));
                        break;

                    case ActionTypes.SET_USER_ENGAGEMENT_TESTME_CHART_DATA:
                        resolve(setUserEngagementTestMeChart(data));
                        break;

                    case ActionTypes.SET_USER_ENGAGEMENT_DO_IT_FOR_ME_CHART_DATA:
                        resolve(setUserEngagementDoItForMeChart(data));
                        break;

                    case ActionTypes.SET_USER_ENGAGEMENT_TUTORIAL_GUIDES_CHART_DATA:
                        resolve(setUserEngagementTutorialGuidesChart(data));
                        break;

                    case ActionTypes.SET_USER_ENGAGEMENT_SUMMARY_TABLE_DATA:
                        resolve(setUserEngagementSummaryTable(data));
                        break;

                    case ActionTypes.SET_USER_ENGAGEMENT_USER_CHART_DATA:
                        // if (firstTimeload) {
                        //     resolve(setUserEngagementUserChartData({}));
                        // }
                        resolve(setUserEngagementUserChartData(data));
                        break;

                    default:
                        break;
                }
            }

            if (data.result === 'retry') {
                setTimeout(() => {
                    callActionDispatch();
                }, RetryApi.TIMEOUT);
            } else {
                callActionDispatch();
            }
        });   
    });
}