import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import withRouter from "hoc/withRouter";

import { Button, ButtonGroup, Dropdown, Tab, Tabs } from "react-bootstrap";

// Redux Imports
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { withReducer } from "store/withReducer";
import { DomainState, EventSourceState, EnvFilterState, CommonComponentState, LanguageState } from "store/Reducers";
import * as ActionTypes from "../../../store/Actions/ActionTypes";
import { GuideAnalyticsDetailState } from "store/Reducers/GuideInsights/guideAnalyticsDetail";
import guideAnalyticsDetailReducer from "store/Reducers/GuideInsights/guideAnalyticsDetail";
import * as guideAnalyticsDetailActions from "store/Actions/index";
import { getCallExportExcel, getCall } from "utils/ApiCallActions";


import qs from 'querystring';
import { CommonUtils } from "utils/CommonUtils";
import { ChartColors, RetryApi } from "utils/GlobalConstants";
// import NVD3Chart from "react-nvd3";


// SCSS Imports
import "./GuideAnalyticsDetail.scss";


import SectionTooltip from "components/SectionTooltip/SectionTooltip";
import SectionRefresh from "components/Layouts/SectionRefresh";
import CardContainer from "components/Layouts/CardContainer";
import ContainerTooltip from "components/Layouts/ContainerTooltip";
import Loader from "components/Loader/Loader";
import SectionHeader from "components/Layouts/SectionHeader";
import Datepicker from "components/Calender/Datepicker";
import SectionButtons from "components/Layouts/SectionButtons";
import ExportToPdf from "components/ExportToPdf/ExportToPdf";
import CommonTable from "components/Table/CommonTable";
import RadialChart from "components/Chart/apexCharts/ApexRadialChart";
import Sankeychart from "components/Chart/Sankeychart";
import FunnelComponent from "components/Funnel/Funnel";
import CommonModal from "components/CommonModal/CommonModal";
import GuideSurvey from "components/CommonModal/GuideSurvey/GuideSurvey";
import UserActivity from "components/CommonModal/UserActivity/UserActivity";
import Maintenance from "components/Maintenance/Maintenance";
import LineChart from "components/Chart/nvd3Charts/LineChart";
import BarChart from "components/Chart/nvd3Charts/BarChart";
import Piechart from 'components/Chart/nvd3Charts/Piechart'
import EnvironmentFilter from "components/EnvironmentFilter/EnvironmentFilter";
import { useErrorBoundary } from 'react-error-boundary';



import insightLables, { getSelectedLabels } from "utils/stringConstants/trans_utils";
import SectionFilters from "components/Layouts/SectionFilters";
import { InsightLabels } from "labels";
import UserInfoSection from "components/Layouts/UserInfoSection";

interface CurrentState {
    domain: DomainState,
    guideAnalyticsDetail: GuideAnalyticsDetailState,
    eventSource: EventSourceState,
    envFilter: EnvFilterState,
    commonComponentData: CommonComponentState,
    languageData: LanguageState

}

const GuideAnalyticsDetail: React.FC = (props: any) => {
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)

    const componentRef = useRef() as React.RefObject<HTMLDivElement>;

    const location = useLocation();
    const navigate = useNavigate();
    const { guide_id } = useParams();


    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];
    let urlData: any = {};
    const query = new URLSearchParams(location.search);
    query.forEach((v, k) => {
        urlData[k] = v;
    });
    const guideId = guide_id;

    let fetched_details = useSelector((state: CurrentState) => state);

    const dispatch = useDispatch();



    let lineChartMargin = {
        top: "50",
        right: "50",
        bottom: "50",
        left: "65",
    }
    let linechart_height = 400;

    const GuideMeMount = useRef(false);
    const GuideMeSourcesMount = useRef(false);
    const ShowMeMount = useRef(false);
    const ShowMeDownloadsMount = useRef(false);
    const TestMeMount = useRef(false);
    const DoItForMeMount = useRef(false);
    const DoItForMeSourcesMount = useRef(false);
    const SurveyMount = useRef(false);
    const SummaryMount = useRef(false);
    const ShowMeSourcesMount = useRef(false);
    const audioChartMount = useRef(false);


    const [guideTitle, setGuideTitle] = useState('');

    const [parameter, setParameter] = useState({
        paramter: "",
        source: "",
    });

    const [errorCount, setErrorCount] = useState({
        errorCount: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        showMaintenance_flag: false
    });

    const [loaderBtn, setLoaderBtn] = useState({
        loader0: false,
        loader1: false,
        loader2: false,
        loader3: false,
        loader4: false,
        loader5: false,
        loader6: false,
        disabled: true,
    });

    const [guideDetail, setGuideDetail] = useState({
        guideStatus: '',
        guideColor: '',
        guideStatus_flag: false,
        guideName: '',
        guideID: null,
    })

    const [userActivityModal, setUserActivityModal] = useState({
        modalOpen: false
    })

    const [guideAnalyticsDetailGuideMeChart, setGuideAnalyticsDetailGuideMeChart] = useState({
        loadGuideMeChartData: [],
        loadGuideMeChartOptions: [],
        guideAnalyticsDetailGuideMeChartDataFetched: false,
        guideMeChart: {},
        guideMeChartFetched: false,
        errorMsg: ''
    });

    const [guideAnalyticsDetailGuideMeRadialChart, setGuideAnalyticsDetailGuideMeRadialChart] = useState({
        loadGuideMeRadialChartData: [],
        loadGuideMeRadialChartOptions: [],
        guideAnalyticsDetailGuideMeRadialChartDataFetched: false,
        guideMeRadialChart: {},
        guideMeRadialChartFetched: false,
        errorMsg: ''

    });

    const [guideAnalyticsDetailGuideMeSourcesChart, setGuideAnalyticsDetailGuideMeSourcesChart] = useState({
        guideMeSourcesPieChartData: [],
        guideMeSourcesPieDataAvailable: false,
        guideAnalyticsDetailGuideMeSourcesChartDataFetched: false,
        errorMsg: ''

    });

    const [guideAnalyticsDetailAudioChart, setGuideAnalyticsDetailAudioChart] = useState({
        loadAudioGuidanceChartData: [],
        loadAudioChartOptions: [],
        loadVideoChartOptions: [],
        loadSlideshowChartOptions: [],
        guideAnalyticsDetailAudioChartDataFetched: false,
        audioChart: {},
        audioChartFetched: false,
        errorMsg: ''

    });

    const [tabKey, setTabKey] = useState({
        key: 1,
        chart: []
    });

    const [guideAnalyticsDetailSummaryTable, setGuideAnalyticsDetailSummaryTable] = useState({
        tableTotal_count: 0,
        guideAnalyticsDetailSummaryTableDataFetched: false,
        searchQuery: '',
        sortKey: '',
        filterLabels: [],
        page: 1,
        showExport: true,
        defaultSort: {
            'sortColumn_index': 2,
            'sortValue': 'desc'
        },
        sortValue: 'desc',
        limit: 5,
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 5,
            currentPage: 0
        },
        firstLoad: true,
        errorMsg: ''

    });

    const [guideAnalyticsDetailLinkedTable, setGuideAnalyticsDetailLinkedTable] = useState({
        guideAnalyticsDetailLinkedTableDataFetched: false,
        searchQuery: '',
        sortKey: '',
        filterLabels: [],
        page: 1,
        showExport: true,
        defaultSort: {
            'sortColumn_index': 2,
            'sortValue': 'desc'
        },
        sortValue: 'desc',
        limit: 5,
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 5,
            currentPage: 0
        },
        firstLoad: true,
        sourceId: 0,
        guideId: 0,
        errorMsg: ''
    });

    const [linkedModal, setLinkedModal] = useState({
        modalOpen: false,
        guideId: 0,
        sourceId: 0
    })

    const [guideAnalyticsDetailSankeyChart, setGuideAnalyticsDetailSankeyChart] = useState({
        chartDataFetched: false,
        errorMsg: ''
    });

    const [funnelData, setFunnelData] = useState({
        funnelVisualizationParameter: {},
        funnelVisualizationPath: "",
    });

    const [guideAnalyticsDetailFunnelList, setGuideAnalyticsDetailFunnelList] = useState({
        listFetched: false,
        stepListData: [],
        errorMsg: ''
    });

    const [guideAnalyticsDetailShowMeChart, setGuideAnalyticsDetailShowMeChart] = useState({
        loadShowMeChartData: [],
        loadShowMeChartOptions: [],
        guideAnalyticsDetailShowMeChartDataFetched: false,
        showMeDonutChartData: [],
        showMePieChartData: [],
        showMeLineChartData: [],
        showMeDonutDataAvailable: false,
        showMePieDataAvailable: false,
        showMeChart: {},
        showMeChartFetched: false,
        errorMsg: ''
    });

    const [guideAnalyticsDetailShowMeDownloadsChart, setGuideAnalyticsDetailShowMeDownloadsChart] = useState({
        videoT: "",
        dPdfT: "",
        pptT: "",
        gifT: "",
        docxT: "",
        htmlT: "",
        video: "",
        dPdf: "",
        ppt: "",
        gif: "",
        docx: "",
        html: "",
        total: 0,
        guideAnalyticsDetailShowMeDownloadsChartDataFetched: false,
        errorMsg: ''
    });

    const [guideAnalyticsDetailShowMeSourcesChart, setGuideAnalyticsDetailShowMeSourcesChart] = useState({
        showMeSourcesPieChartData: [],
        showMeSourcesPieDataAvailable: false,
        guideAnalyticsDetailShowMeSourcesChartDataFetched: false,
        errorMsg: ''
    });

    const [guideAnalyticsDetailShowMeSteps, setGuideAnalyticsDetailShowMeSteps] = useState({
        dataFetched: false,
        data: [],
        selectedData: [],
        chartClickFlag: false,
        errorMsg: ''
    });

    const [guideAnalyticsDetailTestMeChart, setGuideAnalyticsDetailTestMeChart] = useState({
        loadTestMeChartData: [],
        loadTestMeChartOptions: [],
        guideAnalyticsDetailTestMeChartDataFetched: false,
        testMeChart: {},
        testMeChartFetched: false,
        errorMsg: ''
    });

    const [guideAnalyticsDetailDoItForMeChart, setGuideAnalyticsDetailDoItForMeChart] = useState({
        loadDoItForMeChartData: [],
        loadDoItForMeChartOptions: [],
        guideAnalyticsDoItForMeChartDataFetched: false,
        doItForMeChart: {},
        doItForMeChartFetched: false,
        api: '',
        errorMsg: ''
    });

    const [guideAnalyticsDetailDoItForMeSummaryTable, setGuideAnalyticsDetailDoItForMeSummaryTable] = useState({
        guideAnalyticsDetailSummaryDoItForMeTableDataFetched: false,
        searchQuery: '',
        sortKey: '',
        filterLabels: [],
        page: 1,
        showExport: true,
        defaultSort: {
            'sortColumn_index': 1,
            'sortValue': 'desc'
        },
        sortValue: 'desc',
        limit: 5,
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 5,
            currentPage: 0
        },
        firstLoad: true,
        errorMsg: ''
    });

    const [guideAnalyticsDetailDoItForMeSourcesChart, setGuideAnalyticsDetailDoItForMeSourcesChart] = useState({
        doItForMeSourcesPieChartData: [],
        doItForMeSourcesPieDataAvailable: false,
        guideAnalyticsDetailDoItForMeSourcesChartDataFetched: false,
        errorMsg: ''
    });

    const [guideAnalyticsDetailSurveyChart, setGuideAnalyticsDetailSurveyChart] = useState({
        loadSurveyChartData: [],
        loadSurveyChartOptions: [],
        guideAnalyticsDetailSurveyChartDataFetched: false,
        surveyChart: {},
        surveyChartFetched: false,
        exitSurveyResponse: 0,
        guideStepSurveyResponse: 0,
        guideSurveyResponse: 0,
        errorMsg: ''
    });

    const [guideAnalyticsDetailTutorialGuidesChart, setGuideAnalyticsDetailTutorialGuidesChart] = useState({
        loadTutorialGuidesChartData: [],
        loadTutorialGuidesChartOptions: [],
        guideAnalyticsDetailTutorialGuidesChartDataFetched: false,
        tutorialGuidesChart: {},
        tutorialGuidesChartFetched: false,
        errorMsg: ''
    });

    const [guideSurveyModal, setGuideSurveyModal] = useState({
        modalOpen: false,
        count: 0,
        type: ''
    })

    const [showMeTabKey, setShowMeTabKey] = useState({
        key: 0,
        chart: []
    });


    const [errorModal, setErrorModal] = useState({
        data: [],
        modalOpen: false,
        dataFetched: false,
        stepID: 0,
        errorMsg: '',
        type: ''
    })
    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        if (insightsLbls !== undefined) {
            document.title = insightsLbls['docTitleGuideDetail'];
        }
    }, [insightsLbls])

    useEffect(() => {
        try {
            window.scrollTo(0, 0);

            let queryParams: any = {
                app_id: fetched_details?.commonComponentData.appsData.appId,
                app_code: fetched_details?.commonComponentData.appsData.appCode,
                fdate: fetched_details?.commonComponentData?.datesData?.fdate,
                tdate: fetched_details?.commonComponentData?.datesData?.realTime_tdate,
            }

            let component_path: any[] = location.pathname.split('/');
            component_path = component_path.filter(function (el) {
                return el != '';
            });

            navigate({
                pathname: "/guide-insights/guide-analytics-detail/" + component_path[component_path.length - 1] + '/',
                search: qs.stringify(queryParams),
            });

            if (fetched_details?.commonComponentData?.datesData.source === 'component') {
                if (fetched_details.commonComponentData?.datesData.url?.includes('guide-analytics-detail')&&
                fetched_details?.commonComponentData?.appsData.appCode == localStorage.getItem('switch_app_code')) {
                    getGuideStatus(component_path[component_path.length - 1]);
                    getGuideAnalyticsDetailGuideMeChartData();
                    getGuideAnalyticsDetailGuideMeSourcesChartData();
                    getGuideAnalyticsDetailShowMeSourcesChartData();
                    getGuideAnalyticsDetailAudioChart();
                    getGuideAnalyticsDetailSummaryTableData();
                    getGuideAnalyticsDetailStepsFlowChartData();
                    getGuideAnalyticsDetailFunnelList();
                    getGuideAnalyticsDetailShowMeChartData();
                    getGuideAnalyticsDetailShowMeDownloadsChartData();
                    // guideAnalyticsDetailShowMeStepsData();
                    getGuideAnalyticsDetailTestMeChartData();
                    getGuideAnalyticsDetailDoItForMeChartData();
                    getGuideAnalyticsDetailDoItForMeTableData();
                    getGuideAnalyticsDetailDoItForMeSourcesChartData();
                    getGuideAnalyticsDetailSurveyChartData();
                }
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [fetched_details?.commonComponentData?.datesData]);



    useEffect(() => {
        if (insightsLbls) {
            CommonUtils.LeftPanelHighlight(1, 0, 6, true, false);
            document.title = insightsLbls['docTitleGuideDetail'];
        }
    }, [insightsLbls])
    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getGuideStatus = (guide: any, apiErrorFlag?: string) => {
        try {
            let params = {
                "guide_id": guide,
                "app_id": fetched_details?.commonComponentData.appsData.appId
            };

            getCall(params, "GUIDE_STATUS", apiErrorFlag ?? '').then((data: any) => {
                if (data.result === "success") {
                    if (data.data.is_deleted) {
                        setGuideDetail((prevState: any) => {
                            return {
                                ...prevState,
                                guideStatus: 'Deleted',
                                guideColor: 'colorRed',
                                guideStatus_flag: true,
                            }
                        })
                    } else {
                        if (data.data.is_published) {
                            setGuideDetail((prevState: any) => {
                                return {
                                    ...prevState,
                                    guideStatus: 'Published',
                                    guideColor: 'colorGreen',
                                    guideStatus_flag: true,
                                }
                            })
                        } else {
                            setGuideDetail((prevState: any) => {
                                return {
                                    ...prevState,
                                    guideStatus: 'Unpublished',
                                    guideColor: 'colorGrey',
                                    guideStatus_flag: true,
                                }
                            })
                        }
                    }

                } else if (data.result === "retry") {
                    setTimeout(() => {
                        getGuideStatus(guideId, 'retry')
                    }, RetryApi.TIMEOUT)
                } else {
                    setGuideDetail((prevState: any) => {
                        return {
                            ...prevState,
                            guideStatus: 'Unknown',
                            guideColor: 'colorYellow',
                            guideStatus_flag: true,

                        }
                    })
                }
            });
        } catch (error) {
            showBoundary(error)
        }

    }

    const getGuideAnalyticsDetailGuideMeChartData = (apiErrorFlag?: string) => {
        const params = {
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "time_zone": timeZone,
            'guide_id': guideId,
            "event_source": fetched_details?.eventSource.eventSourceGuides,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            'env_code': fetched_details?.envFilter.envCode ?? '',
            'app_id': fetched_details?.commonComponentData.appsData.appId
        };

        guideAnalyticsDetailActions.getGuideAnalyticsDetailData(
            params,
            "GUIDE_ANALYTICS_DETAILS_GUIDE_ME_CHART",
            ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_GUIDEME_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    };

    const getGuideAnalyticsDetailGuideMeSourcesChartData = (apiErrorFlag?: string) => {
        const params = {
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "time_zone": timeZone,
            'guide_id': guideId,
            "event_source": fetched_details?.eventSource.eventSourceGuides,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            'env_code': fetched_details?.envFilter.envCode ?? '',
            'app_id': fetched_details?.commonComponentData.appsData.appId
        };

        guideAnalyticsDetailActions.getGuideAnalyticsDetailData(
            params,
            "GUIDE_ANALYTICS_DETAILS_GUIDE_ME_SOURCES_CHART",
            ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_GUIDEME_SOURCES_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    };

    const getGuideAnalyticsDetailAudioChart = (apiErrorFlag?: string) => {
        const params = {
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "time_zone": timeZone,
            'guide_id': guideId,
            "event_source": fetched_details?.eventSource.eventSourceGuides,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            'env_code': fetched_details?.envFilter.envCode ?? '',
            'app_id': fetched_details?.commonComponentData.appsData.appId

        };

        guideAnalyticsDetailActions.getGuideAnalyticsDetailData(
            params,
            "GUIDE_ANALYTICS_DETAIL_AUDIO_CHART",
            ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_AUDIO_CHART,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    };

    const getGuideAnalyticsDetailSummaryTableData = (apiErrorFlag?: string) => {
        const params = {
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "time_zone": timeZone,
            'guide_id': guideId,
            "event_source": fetched_details?.eventSource.eventSourceGuides,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            "query": '',
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            "sort_by": 'step_index',
            "order": guideAnalyticsDetailSummaryTable.sortValue,
            "page_index": guideAnalyticsDetailSummaryTable.page,
            "page_size": guideAnalyticsDetailSummaryTable.limit,
            "limit": guideAnalyticsDetailSummaryTable.limit,
            'env_code': fetched_details?.envFilter.envCode ?? ''
        };

        guideAnalyticsDetailActions.getGuideAnalyticsDetailData(
            params,
            "GUIDE_ANALYTICS_DETAILS_SUMMARY_TABLE",
            ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_SUMMARY_TABLE_DATA,
            apiErrorFlag ?? '',
            guideAnalyticsDetailSummaryTable.firstLoad,
        ).then((res: any) => dispatch(res));
    };

    const getGuideAnalyticsDetailLinkedTableData = useCallback((guideId: number, sourceId: number, data: any, apiErrorFlag?: string) => {

        setGuideAnalyticsDetailLinkedTable((prevState: any) => {
            return {
                ...prevState,
                guideAnalyticsDetailLinkedTableDataFetched: false,
                sourceId: sourceId,
                guideId: guideId
            }
        })

        const params = {
            "start_date": data?.datesData?.fdate + "_00:00:00",
            "end_date": data?.datesData?.realTime_tdate + "_23:59:59",
            "time_zone": timeZone,
            'guide_id': guideId,
            "event_source": fetched_details?.eventSource.eventSourceGuides,
            'exclude_weekends': data?.datesData.excludeFlag,
            'enable_real_time': data?.datesData.realTimeCheck,
            'source_guide_id': sourceId,
            "query": '',
            "app_id": data?.appsData?.appId,
            "sort_by": 'step_index',
            "order": guideAnalyticsDetailLinkedTable.sortValue,
            "page_index": guideAnalyticsDetailLinkedTable.page,
            "page_size": guideAnalyticsDetailLinkedTable.limit,
            "limit": guideAnalyticsDetailLinkedTable.limit,
            'env_code': fetched_details?.envFilter.envCode ?? ''
        };

        guideAnalyticsDetailActions.getGuideAnalyticsDetailData(
            params,
            "GUIDE_ANALYTICS_DETAILS_SUMMARY_TABLE",
            ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_LINKED_TABLE_DATA,
            apiErrorFlag ?? ''

        ).then((res: any) => dispatch(res));


    }, [linkedModal]);

    const getGuideAnalyticsDetailStepsFlowChartData = (apiErrorFlag?: string) => {
        let params = {
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "time_zone": timeZone,
            'guide_id': guideId,
            "event_source": fetched_details?.eventSource.eventSourceGuides,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            'env_code': fetched_details?.envFilter.envCode ?? '',
            'app_id': fetched_details?.commonComponentData.appsData.appId

        };

        guideAnalyticsDetailActions.getGuideAnalyticsDetailData(
            params,
            "GUIDE_ANALYTICS_DETAILS_STEPS_FLOW_CHART",
            ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_STEPS_FLOW_CHART,
            apiErrorFlag ?? ''

        ).then((res: any) => dispatch(res));
    };

    const getGuideAnalyticsDetailFunnelList = (apiErrorFlag?: string) => {
        const params = {
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "time_zone": timeZone,
            'guide_id': guideId,
            'app_id': fetched_details?.commonComponentData.appsData.appId,
            "event_source": fetched_details?.eventSource.eventSourceGuides,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
        };

        const funnelVisualizationParameter = {
            start_date: fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            end_date: fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            time_zone: "GMT+0530",
            guide_id: guideId,
            event_source: fetched_details?.eventSource.eventSourceGuides,
            exclude_weekends: fetched_details?.commonComponentData?.datesData.excludeFlag,
            enable_real_time: fetched_details?.commonComponentData?.datesData.realTimeCheck,
            env_code: fetched_details?.envFilter.envCode ?? '',
            app_id: fetched_details?.commonComponentData.appsData.appId
        };

        setFunnelData((prevState: any) => {
            return {
                ...prevState,
                funnelVisualizationParameter: funnelVisualizationParameter,
                funnelVisualizationPath: "GUIDE_ANALYTICS_DETAILS_DROPOFF_FUNNEL_CHART",
            };
        });

        guideAnalyticsDetailActions.getGuideAnalyticsDetailData(
            params,
            "GUIDE_ANALYTICS_DETAILS_FUNNEL_STEPS_LIST",
            ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_FUNNEL_STEPS_LIST,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    };

    const getGuideAnalyticsDetailShowMeChartData = (apiErrorFlag?: string) => {
        const params = {
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "time_zone": timeZone,
            'guide_id': guideId,
            "event_source": fetched_details?.eventSource.eventSourceGuides,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            'env_code': fetched_details?.envFilter.envCode ?? '',
            'app_id': fetched_details?.commonComponentData.appsData.appId

        };

        guideAnalyticsDetailActions.getGuideAnalyticsDetailData(
            params,
            "GUIDE_ANALYTICS_DETAILS_SHOW_ME_CHART",
            ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_SHOWME_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    };

    const getGuideAnalyticsDetailShowMeDownloadsChartData = (apiErrorFlag?: string) => {
        const params = {
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "time_zone": timeZone,
            'guide_id': guideId,
            "event_source": fetched_details?.eventSource.eventSourceGuides,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            'env_code': fetched_details?.envFilter.envCode ?? '',
            'app_id': fetched_details?.commonComponentData.appsData.appId
        };

        guideAnalyticsDetailActions.getGuideAnalyticsDetailData(
            params,
            "GUIDE_ANALYTICS_DETAILS_SHOW_ME_DOWNLOADS_CHART",
            ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_SHOWME_DOWNLOADS_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    };

    const getGuideAnalyticsDetailShowMeSourcesChartData = (apiErrorFlag?: string) => {
        const params = {
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "time_zone": timeZone,
            'guide_id': guideId,
            "event_source": fetched_details?.eventSource.eventSourceGuides,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            'env_code': fetched_details?.envFilter.envCode ?? '',
            'app_id': fetched_details?.commonComponentData.appsData.appId
        };

        guideAnalyticsDetailActions.getGuideAnalyticsDetailData(
            params,
            "GUIDE_ANALYTICS_DETAIL_SHOWME_SOURCES_CHART_DATA",
            ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_SHOWME_SOURCES_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    };

    const guideAnalyticsDetailShowMeStepsData = (apiErrorFlag?: string) => {
        const params = {
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "time_zone": timeZone,
            'guide_id': guideId,
            "event_source": fetched_details?.eventSource.eventSourceGuides,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            'env_code': fetched_details?.envFilter.envCode ?? '',
            'app_id': fetched_details?.commonComponentData.appsData.appId
        };

        guideAnalyticsDetailActions.getGuideAnalyticsDetailData(
            params,
            "GUIDE_ANALYTICS_DETAIL_SHOWME_STEPS_DATA",
            ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_SHOWME_STEPS_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    };

    const getGuideAnalyticsDetailTestMeChartData = (apiErrorFlag?: string) => {
        const params = {
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "time_zone": timeZone,
            'guide_id': guideId,
            "event_source": fetched_details?.eventSource.eventSourceGuides,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            'env_code': fetched_details?.envFilter.envCode ?? '',
            'app_id': fetched_details?.commonComponentData.appsData.appId
        };

        guideAnalyticsDetailActions.getGuideAnalyticsDetailData(
            params,
            "GUIDE_ANALYTICS_DETAILS_TEST_ME_CHART",
            ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_TESTME_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    };

    const getGuideAnalyticsDetailDoItForMeChartData = (apiErrorFlag?: string) => {
        const params = {
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "time_zone": timeZone,
            'guide_id': guideId,
            "event_source": fetched_details?.eventSource.eventSourceGuides,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            'env_code': fetched_details?.envFilter.envCode ?? '',
            'app_id': fetched_details?.commonComponentData.appsData.appId
        };

        guideAnalyticsDetailActions.getGuideAnalyticsDetailData(
            params,
            "GUIDE_ANALYTICS_DETAILS_DOITFOR_ME_CHART",
            ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_DOITFORME_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    };

    const getGuideAnalyticsDetailDoItForMeTableData = (apiErrorFlag?: string) => {
        const params = {
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "time_zone": timeZone,
            'guide_id': guideId,
            "event_source": fetched_details?.eventSource.eventSourceGuides,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            "query": '',
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            "sort_by": "",
            "order": guideAnalyticsDetailDoItForMeSummaryTable.sortValue,
            "page_index": guideAnalyticsDetailDoItForMeSummaryTable.page,
            "page_size": guideAnalyticsDetailDoItForMeSummaryTable.limit,
            "limit": guideAnalyticsDetailDoItForMeSummaryTable.limit,
            'env_code': fetched_details?.envFilter.envCode ?? ''
        };

        guideAnalyticsDetailActions.getGuideAnalyticsDetailData(
            params,
            "GUIDE_ANALYTICS_DETAILS_DOITFOR_ME_SUMMARY_TABLE",
            ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_DOITFORME_TABLE_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    };

    const getGuideAnalyticsDetailDoItForMeSourcesChartData = (apiErrorFlag?: string) => {
        const params = {
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "time_zone": timeZone,
            'guide_id': guideId,
            "event_source": fetched_details?.eventSource.eventSourceGuides,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            'env_code': fetched_details?.envFilter.envCode ?? '',
            'app_id': fetched_details?.commonComponentData.appsData.appId
        };

        guideAnalyticsDetailActions.getGuideAnalyticsDetailData(
            params,
            "GUIDE_ANALYTICS_DETAILS_DOITFOR_ME_SOURCES_CHART",
            ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_DOITFORME_SOURCES_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    }

    const getGuideAnalyticsDetailSurveyChartData = (apiErrorFlag?: string) => {
        const params = {
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "time_zone": timeZone,
            'guide_id': guideId,
            "event_source": fetched_details?.eventSource.eventSourceGuides,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            'env_code': fetched_details?.envFilter.envCode ?? '',
            'app_id': fetched_details?.commonComponentData.appsData.appId
        };

        guideAnalyticsDetailActions.getGuideAnalyticsDetailData(
            params,
            "GUIDE_ANALYTICS_DETAILS_SURVEY_CHART",
            ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_SURVEY_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    };


    const exportExcel = (data: any) => {
        let params = {
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "search_text": encodeURIComponent(guideAnalyticsDetailSummaryTable.searchQuery),
            "time_zone": timeZone,
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            "sort_by": 'step_index',
            "order": guideAnalyticsDetailSummaryTable.sortValue,
            "page_index": guideAnalyticsDetailSummaryTable.page,
            "page_size": guideAnalyticsDetailSummaryTable.limit,
            "event_source": fetched_details.eventSource.eventSourceGuides,
            "limit": guideAnalyticsDetailSummaryTable.limit,
            "guide_id": guideId,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            'env_code': fetched_details?.envFilter.envCode ?? ''

        };
        getCallExportExcel(params, 'GUIDE_ANALYTICS_DETAILS_SUMMARY_TABLE', data);
    }

    const exportLinkedExcel = (data: any) => {
        let params = {
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "search_text": encodeURIComponent(guideAnalyticsDetailLinkedTable.searchQuery),
            "time_zone": timeZone,
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            "sort_by": 'step_index',
            "order": guideAnalyticsDetailLinkedTable.sortValue,
            "page_index": guideAnalyticsDetailLinkedTable.page,
            "page_size": guideAnalyticsDetailLinkedTable.limit,
            "event_source": fetched_details.eventSource.eventSourceGuides,
            "limit": guideAnalyticsDetailLinkedTable.limit,
            "guide_id": linkedModal.guideId,
            "source_guide_id": linkedModal.sourceId,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            'env_code': fetched_details?.envFilter.envCode ?? ''

        };
        getCallExportExcel(params, 'GUIDE_ANALYTICS_DETAILS_SUMMARY_TABLE', data);
    }

    const exportDoItForMeExcel = (data: any) => {
        let params = {
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "time_zone": timeZone,
            'guide_id': guideId,
            "event_source": fetched_details?.eventSource.eventSourceGuides,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            "query": '',
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            "sort_by": guideAnalyticsDetailDoItForMeSummaryTable.sortKey,
            "order": guideAnalyticsDetailDoItForMeSummaryTable.sortValue,
            "page_index": guideAnalyticsDetailDoItForMeSummaryTable.page,
            "page_size": guideAnalyticsDetailDoItForMeSummaryTable.limit,
            "limit": guideAnalyticsDetailDoItForMeSummaryTable.limit,
            'env_code': fetched_details?.envFilter.envCode ?? ''

        };
        getCallExportExcel(params, 'GUIDE_ANALYTICS_DETAILS_DOITFOR_ME_SUMMARY_TABLE', data);
    }

    const getErrorDetailsData = (stepId: number, type: string, apiErrorFlag?: string,) => {
        if (type === 'guideme') {
            const params = {
                "time_zone": timeZone,
                "event_source": fetched_details?.eventSource.eventSourceGuides,
                'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
                "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
                "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
                'guide_id': guideId,
                'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
                'app_id': fetched_details?.commonComponentData.appsData.appId,
                'step_id': stepId
            };

            guideAnalyticsDetailActions.getGuideAnalyticsDetailData(
                params,
                "GUIDE_ANALYTICS_ERROR_DETAILS",
                ActionTypes.SET_GUIDE_ANALYTICS_ERROR_DETAILS,
                ''
            ).then((res: any) => dispatch(res));
        } else {
            const params = {
                "time_zone": timeZone,
                "event_source": fetched_details?.eventSource.eventSourceGuides,
                'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
                "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
                "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
                'guide_id': guideId,
                'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
                'app_id': fetched_details?.commonComponentData.appsData.appId,
                'step_id': stepId
            };

            guideAnalyticsDetailActions.getGuideAnalyticsDetailData(
                params,
                "GUIDE_ANALYTICS_DOITFORME_ERROR_DETAILS",
                ActionTypes.SET_GUIDE_ANALYTICS_ERROR_DETAILS,
                ''
            ).then((res: any) => dispatch(res));
        }

    }

    //Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const refreshSections = (optn: number, type?: string) => {
        try {
            enableExportPdf(optn, false);
            if (optn === 0) {
                switch (type) {
                    case 'lineChart':
                        setGuideAnalyticsDetailGuideMeChart((prevState: any) => {
                            return {
                                ...prevState,
                                guideAnalyticsDetailGuideMeChartDataFetched: false,
                                guideMeChartFetched: false,
                                guideMeChart: {}
                            };
                        });

                        break;

                    case 'pieChart':
                        setGuideAnalyticsDetailGuideMeSourcesChart((prevState: any) => {
                            return {
                                ...prevState,
                                guideAnalyticsDetailGuideMeSourcesChartDataFetched: false,
                            };
                        });
                        break;

                    case 'radialChart':
                        setGuideAnalyticsDetailGuideMeRadialChart((prevState: any) => {
                            return {
                                ...prevState,
                                guideAnalyticsDetailGuideMeRadialChartDataFetched: false,
                                guideMeRadialChartFetched: false,
                                guideMeRadialChart: {}
                            };
                        });

                        break;

                    default:
                        break;
                }

                getGuideAnalyticsDetailGuideMeChartData();
                getGuideAnalyticsDetailGuideMeSourcesChartData();

            }
            else if (optn === 1) {
                setGuideAnalyticsDetailAudioChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDetailAudioChartDataFetched: false,
                        audioChartFetched: false,
                        audioChart: {}
                    };
                });
                getGuideAnalyticsDetailAudioChart();
                handleSwitch(1);
            }
            else if (optn === 2) {
                setGuideAnalyticsDetailSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDetailSummaryTableDataFetched: false,

                        searchQuery: '',
                        sortKey: 'guide_play_count',
                        filterLabels: [],
                        page: 1,
                        showExport: true,
                        defaultSort: {
                            'sortColumn_index': 2,
                            'sortValue': 'desc'
                        },
                        // renderTaggedTable: false,
                        sortValue: 'desc',
                        limit: 5,
                        firstLoad: true,
                        tablePagination: {
                            offset: 0,
                            data: [],
                            perPage: 5,
                            currentPage: 0
                        },
                    };
                });
                // getGuideAnalyticsDetailSummaryTableData("refresh");
            }
            else if (optn === 3) {
                switch (type) {
                    case 'lineChart':
                        setShowMeTabKey((prevState: any) => {
                            return {
                                ...prevState,
                                key: 0
                            }
                        })

                        setGuideAnalyticsDetailShowMeChart((prevState: any) => {
                            return {
                                ...prevState,
                                guideAnalyticsDetailShowMeChartDataFetched: false,
                                showMeChartFetched: false,
                                showMeChart: {}
                            };
                        });
                        getGuideAnalyticsDetailShowMeChartData();

                        break;



                    case 'downloads':


                        setGuideAnalyticsDetailShowMeDownloadsChart((prevState: any) => {
                            return {
                                ...prevState,
                                videoT: "",
                                dPdfT: "",
                                pptT: "",
                                gifT: "",
                                docxT: "",
                                htmlT: "",
                                video: "",
                                dPdf: "",
                                ppt: "",
                                gif: "",
                                docx: "",
                                html: "",
                                total: 0,
                                guideAnalyticsDetailShowMeDownloadsChartDataFetched: false,
                                errorMsg: ''
                            };
                        });
                        getGuideAnalyticsDetailShowMeDownloadsChartData();

                        break;

                    case 'pieChart':
                        setGuideAnalyticsDetailShowMeSourcesChart((prevState: any) => {
                            return {
                                ...prevState,
                                showMeSourcesPieChartData: [],
                                showMeSourcesPieDataAvailable: false,
                                guideAnalyticsDetailShowMeSourcesChartDataFetched: false,
                                errorMsg: ''
                            }
                        })

                        getGuideAnalyticsDetailShowMeSourcesChartData();
                        break;

                    default:
                        break;
                }






                // guideAnalyticsDetailShowMeStepsData();

            }
            else if (optn === 4) {
                setGuideAnalyticsDetailTestMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDetailTestMeChartDataFetched: false,
                        testMeChartFetched: false,
                        testMeChart: {}
                    };
                });
                getGuideAnalyticsDetailTestMeChartData();
            }
            else if (optn === 5) {

                switch (type) {
                    case 'guideChart':
                        setGuideAnalyticsDetailDoItForMeChart((prevState: any) => {
                            return {
                                ...prevState,
                                guideAnalyticsDoItForMeChartDataFetched: false,
                                doItForMeChartFetched: false,
                                doItForMeChart: {}
                            };
                        });
                        getGuideAnalyticsDetailDoItForMeChartData();

                        break;

                    case 'sourcesChart':
                        setGuideAnalyticsDetailDoItForMeSourcesChart((prevState: any) => {
                            return {
                                ...prevState,
                                guideAnalyticsDetailDoItForMeSourcesChartDataFetched: false,
                            };
                        });

                        getGuideAnalyticsDetailDoItForMeSourcesChartData();
                        break;

                    case 'table':
                        setGuideAnalyticsDetailDoItForMeSummaryTable((prevState: any) => {
                            return {
                                ...prevState,
                                guideAnalyticsDetailSummaryDoItForMeTableDataFetched: false,
                                searchQuery: '',
                                sortKey: 'do_it_for_me_play_counts',
                                filterLabels: [],
                                page: 1,
                                showExport: true,
                                defaultSort: {
                                    'sortColumn_index': 1,
                                    'sortValue': 'desc'
                                },
                                sortValue: 'desc',
                                limit: 5,
                                tablePagination: {
                                    offset: 0,
                                    data: [],
                                    perPage: 5,
                                    currentPage: 0
                                },
                                firstLoad: true
                            };
                        });
                        getGuideAnalyticsDetailDoItForMeTableData();

                        break;

                    default:
                        break;
                }





            }
            else if (optn === 6) {
                setGuideAnalyticsDetailSurveyChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDetailSurveyChartDataFetched: false,
                        surveyChartFetched: false,
                        surveyChart: {}
                    };
                });
                getGuideAnalyticsDetailSurveyChartData();
            } else if (optn === 7) {
                setGuideAnalyticsDetailSankeyChart((prevState: any) => {
                    return {
                        ...prevState,
                        chartDataFetched: false,
                        errorMsg: ''
                    };
                });
                getGuideAnalyticsDetailStepsFlowChartData();
            }
        } catch (error) {
            showBoundary(error)
        }

    };

    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) =>
                        i === index ? option : item
                    ),
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    useEffect(() => {
        try {
            if (loaderBtn.loader0 && loaderBtn.loader1 && loaderBtn.loader2 && loaderBtn.loader3 && loaderBtn.loader4 && loaderBtn.loader5 && loaderBtn.loader6) {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: false,
                    };
                });
            }
            else {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: true,
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [loaderBtn.loader0, loaderBtn.loader1, loaderBtn.loader2, loaderBtn.loader3, loaderBtn.loader4, loaderBtn.loader5, loaderBtn.loader6])


    const enableExportPdf = (loaderNumber: number, val: boolean) => {
        try {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    ['loader' + loaderNumber]: val
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const closeModal = () => {
        try {
            setLinkedModal((prevState: any) => {
                return {
                    ...prevState,
                    modalOpen: false
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    }



    const closeGuideSurveyModal = useCallback(() => {
        try {
            setGuideSurveyModal((prevState: any) => {
                return {
                    ...prevState,
                    modalOpen: false
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }, []);

    const openUserAcitivtyModal = () => {
        try {
            setUserActivityModal((prevState: any) => {
                return {
                    ...prevState,
                    modalOpen: true
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }
    const closeUserActivityModal = useCallback(() => {
        try {
            setUserActivityModal((prevState: any) => {
                return {
                    ...prevState,
                    modalOpen: false
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }, []);

    const closeErrorDataModal = () => {
        try {
            setErrorModal((prevState: any) => {
                return {
                    ...prevState,
                    modalOpen: false
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    }

    const getChartData = (data: string) => {
        try {
            setGuideAnalyticsDetailShowMeSteps((prevState: any) => {
                return {
                    ...prevState,
                    selectedData: [],
                    chartClickFlag: false
                }
            })

            switch (data) {
                case 'Slideshow Stats': {
                    let chartData: any = [];
                    chartData[0] = {};
                    chartData[0].data = fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeStepsData.data['slideshowStats'];
                    chartData[0].type = insightsLbls['slideshowStats'];
                    chartData[0].color = ChartColors.color1;

                    setTimeout(() => {
                        setGuideAnalyticsDetailShowMeSteps((prevState: any) => {
                            return {
                                ...prevState,
                                selectedData: chartData[0],
                                chartClickFlag: true
                            }
                        })
                    }, 10);

                }
                    break;

                case 'Video Stats': {
                    let chartData: any = [];
                    chartData[0] = {};
                    chartData[0].data = fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeStepsData.data['videoStats'];
                    chartData[0].type = insightsLbls['videoStats'];
                    chartData[0].color = ChartColors.color2;

                    setTimeout(() => {
                        setGuideAnalyticsDetailShowMeSteps((prevState: any) => {
                            return {
                                ...prevState,
                                selectedData: chartData[0],
                                chartClickFlag: true
                            }
                        })
                    }, 10);

                }
                    break;

                default:
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const handleTableEvents = useCallback((option: string, data: any, type: string) => {
        try {
            switch (option) {
                case "sortColumn":
                    setGuideAnalyticsDetailSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            sortKey: data.sortKey,
                            sortValue: data.sortValue,
                            page: 1,
                            defaultSort: {
                                sortColumn_index: data.index,
                                sortValue: data.sortValue,
                            },
                            firstLoad: false
                        }
                    });
                    break;

                case "changePage":
                    setGuideAnalyticsDetailSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: data + 1,
                            firstLoad: false
                        }
                    });
                    break;

                case "searchTxt":
                    setGuideAnalyticsDetailSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            searchQuery: data,
                            firstLoad: false
                        }
                    });
                    break;

                case "changeEntries":
                    setGuideAnalyticsDetailSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            limit: data,
                            tablePagination: {                   // object that we want to update
                                ...prevState.tablePagination,    // keep all other key-value pairs
                                perPage: data       // update the value of specific key
                            },
                            firstLoad: false
                        }
                    });
                    break;

                case "linkedModal":
                    setLinkedModal((prevState: any) => {
                        return {
                            ...prevState,
                            modalOpen: true,
                            guideId: data.guideId,
                            sourceId: data.sourceId,
                        }
                    })

                    getGuideAnalyticsDetailLinkedTableData(data.guideId, data.sourceId, fetched_details.commonComponentData)
                    break;

                case "exportExcel":
                    exportExcel(data)
                    break;

                case "errorDetails":
                    setErrorModal((prevState: any) => {
                        return {
                            ...prevState,
                            modalOpen: true,
                            dataFetched: false,
                            stepID: data.stepID,
                            type: type
                        }
                    })
                    getErrorDetailsData(data.stepID, type)
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [guideAnalyticsDetailSummaryTable]);

    const handleLinkedTableEvents = useCallback((option: string, data: any) => {
        try {
            switch (option) {
                case "sortColumn":
                    setGuideAnalyticsDetailLinkedTable((prevState) => {
                        return {
                            ...prevState,
                            sortKey: data.sortKey,
                            sortValue: data.sortValue,
                            page: 1,
                            defaultSort: {
                                sortColumn_index: data.index,
                                sortValue: data.sortValue,
                            },
                            firstLoad: false
                        }
                    });
                    break;

                case "changePage":
                    setGuideAnalyticsDetailLinkedTable((prevState) => {
                        return {
                            ...prevState,
                            page: data + 1,
                            firstLoad: false
                        }
                    });
                    break;

                case "searchTxt":
                    setGuideAnalyticsDetailLinkedTable((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            searchQuery: data,
                            firstLoad: false
                        }
                    });
                    break;

                case "changeEntries":
                    setGuideAnalyticsDetailLinkedTable((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            limit: data,
                            tablePagination: {                   // object that we want to update
                                ...prevState.tablePagination,    // keep all other key-value pairs
                                perPage: data       // update the value of specific key
                            },
                            firstLoad: false
                        }
                    });
                    break;

                case "linkedModal":
                    setLinkedModal((prevState: any) => {
                        return {
                            ...prevState,
                            modalOpen: true,
                            guideId: data.guideId,
                            sourceId: data.sourceId,
                        }
                    })
                    getGuideAnalyticsDetailLinkedTableData(data.guideId, data.sourceId, fetched_details.commonComponentData)
                    break;

                case "exportExcel":
                    exportLinkedExcel(data)
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [guideAnalyticsDetailLinkedTable]);

    const handleDoItForMeTableEvents = useCallback((option: string, data: any, type: string) => {
        try {
            switch (option) {
                // case "sortColumn":
                //     setGuideAnalyticsDetailDoItForMeSummaryTable((prevState) => {
                //         return {
                //             ...prevState,
                //             sortKey: data.sortKey,
                //             sortValue: data.sortValue,
                //             page: 1,
                //             defaultSort: {
                //                 sortColumn_index: data.index,
                //                 sortValue: data.sortValue,
                //             },
                //             firstLoad: false
                //         }
                //     });
                //     break;

                case "changePage":
                    setGuideAnalyticsDetailDoItForMeSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: data + 1,
                            firstLoad: false
                        }
                    });
                    break;

                // case "searchTxt":
                //     setGuideAnalyticsDetailDoItForMeSummaryTable((prevState) => {
                //         return {
                //             ...prevState,
                //             page: 1,
                //             searchQuery: data,
                //             firstLoad: false
                //         }
                //     });
                //     break;

                case "changeEntries":
                    setGuideAnalyticsDetailDoItForMeSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            limit: data,
                            tablePagination: {                   // object that we want to update
                                ...prevState.tablePagination,    // keep all other key-value pairs
                                perPage: data       // update the value of specific key
                            },
                            firstLoad: false
                        }
                    });
                    break;

                // case "linkedModal":
                //     setLinkedModal((prevState: any) => {
                //         return {
                //             ...prevState,
                //             modalOpen: true,
                //             guideId: data.guideId,
                //             sourceId: data.sourceId,
                //         }
                //     })

                //     getGuideAnalyticsDetailLinkedTableData(data.guideId, data.sourceId)
                //     break;

                case "errorDetails":
                    setErrorModal((prevState: any) => {
                        return {
                            ...prevState,
                            modalOpen: true,
                            dataFetched: false,
                            stepID: data.stepID,
                            type: type
                        }
                    })
                    getErrorDetailsData(data.stepID, type)
                    break;

                case "exportExcel":
                    exportDoItForMeExcel(data)
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [guideAnalyticsDetailDoItForMeSummaryTable]);

    const handleSwitch = (e: any) => {
        try {
            setTabKey((prevState: any) => {
                return {
                    ...prevState,
                    key: e
                };
            });
        } catch (error) {
            showBoundary(error)
        }
    };


    // UseEffects @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // GuideMeChart
    useEffect(() => {
        try {
            if (
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailGuideMeChartData.data !== undefined &&
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailGuideMeChartData.result === "success"
            ) {
                GuideMeMount.current = true;
                let guideMeChartData: any = [];


                let guideMeRadialUniqueCount: number = 0;
                let guideMeRadialCount: number = 0;
                let guideMeRadialCompleteCount: number = 0;
                let guideMeRadialAverage: number = 0;

                let guideMeRadialChartOptions: any = [];

                guideMeChartData = fetched_details.guideAnalyticsDetail.guideAnalyticsDetailGuideMeChartData.data;

                for (let i = 0; i < guideMeChartData.length; i++) {
                    guideMeRadialUniqueCount += guideMeChartData[i].counts.guide_unique_play;
                    guideMeRadialCount += guideMeChartData[i].counts.guide_play;
                    guideMeRadialCompleteCount += guideMeChartData[i].counts.guide_complete;
                }


                if (guideMeRadialUniqueCount <= 0) {
                    guideMeRadialAverage = 0;
                } else {
                    guideMeRadialAverage = (guideMeRadialCount / guideMeRadialUniqueCount);
                    if (guideMeRadialAverage % 1 > 0.85) {
                        guideMeRadialAverage = Math.ceil(guideMeRadialAverage)
                    } else {
                        guideMeRadialAverage = Math.floor(guideMeRadialAverage)
                    }
                }


                setGuideAnalyticsDetailGuideMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadGuideMeChartData: [...guideMeChartData],
                        guideAnalyticsDetailGuideMeChartDataFetched: true,
                        errorMsg: ''
                    };
                });


                guideMeRadialChartOptions = [
                    {
                        key: insightsLbls ? getRadialChartLabels(insightsLbls['totalPlayed']) : 'Total Played',
                        values: guideMeRadialCount,
                        color: ChartColors.color1,
                    },
                    {
                        key: insightsLbls ? getRadialChartLabels(insightsLbls['uniquePlayed']) : 'Unique Played',
                        values: guideMeRadialUniqueCount,
                        color: ChartColors.color2,
                    },
                    {
                        key: insightsLbls ? getRadialChartLabels(insightsLbls['completed']) : 'Completed',
                        values: guideMeRadialCompleteCount,
                        color: ChartColors.color3,
                    },
                    {
                        key: insightsLbls ? getRadialChartLabels(insightsLbls['avgPlayed']) : 'Average Played',
                        values: guideMeRadialAverage,
                        color: ChartColors.color4
                    }
                ];

                setGuideAnalyticsDetailGuideMeRadialChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadGuideMeRadialChartData: [...guideMeRadialChartOptions],
                        loadGuideMeRadialChartOptions: [...guideMeRadialChartOptions],
                        guideAnalyticsDetailGuideMeRadialChartDataFetched: true,
                        errorMsg: ''
                    };
                });

                apiCatchError(0, 0);
                enableExportPdf(0, true)

            } else if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailGuideMeChartData.result === 'retry') {
                getGuideAnalyticsDetailGuideMeChartData('retry')
            } else if (
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailGuideMeChartData.result === "error"
            ) {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;

                if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailGuideMeChartData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }

                setGuideAnalyticsDetailGuideMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDetailGuideMeChartDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                setGuideAnalyticsDetailGuideMeRadialChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDetailGuideMeRadialChartDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(0, errorFlag);
                enableExportPdf(0, false)
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details.guideAnalyticsDetail.guideAnalyticsDetailGuideMeChartData]);


    // GuideMe Sources
    useEffect(() => {
        try {
            if (
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailGuideMeSourcesChartData.data !== undefined &&
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailGuideMeSourcesChartData.result === "success"
            ) {
                GuideMeSourcesMount.current = true;
                let guideMeSourcesChartData: any = [];
                let total: number = 0;

                let pieDataAvailable: boolean = false;

                let data: any = [];

                guideMeSourcesChartData = fetched_details.guideAnalyticsDetail.guideAnalyticsDetailGuideMeSourcesChartData.data.triggerSources;
                data[0] = {};
                data[0].counts = {}
                data[0].counts.guidePlayedViaBeacon = guideMeSourcesChartData.guidePlayedViaBeacon;
                data[0].counts.guidePlayedViaPush = guideMeSourcesChartData.guidePlayedViaPush;
                data[0].counts.guidePlayedViaPanel = guideMeSourcesChartData.guidePlayedViaPanel;
                data[0].counts.guidePlayedViaUrl = guideMeSourcesChartData.guidePlayedViaUrl;
                total += guideMeSourcesChartData.guidePlayedViaBeacon + guideMeSourcesChartData.guidePlayedViaPush + guideMeSourcesChartData.guidePlayedViaPanel + guideMeSourcesChartData.guidePlayedViaUrl;


                total === 0 ? (pieDataAvailable = false) : (pieDataAvailable = true);
                setGuideAnalyticsDetailGuideMeSourcesChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideMeSourcesPieChartData: [...data],
                        guideMeSourcesPieDataAvailable: pieDataAvailable,
                        guideAnalyticsDetailGuideMeSourcesChartDataFetched: true,
                        errorMsg: ''
                    };
                });
                apiCatchError(1, 0);
                enableExportPdf(0, true)

            } else if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailGuideMeSourcesChartData.result === 'retry') {
                getGuideAnalyticsDetailGuideMeSourcesChartData('retry')
            } else if (
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailGuideMeSourcesChartData.result === "error"
            ) {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;

                if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailGuideMeSourcesChartData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }

                setGuideAnalyticsDetailGuideMeSourcesChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDetailGuideMeSourcesChartDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(1, errorFlag);
                enableExportPdf(0, false)
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details.guideAnalyticsDetail.guideAnalyticsDetailGuideMeSourcesChartData]);


    // AudioChart
    useEffect(() => {
        try {
            if (
                fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailAudioChart?.data !== undefined &&
                fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailAudioChart?.result === "success"
            ) {
                audioChartMount.current = true;
                let audioChartData: any = [];
                audioChartData = fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailAudioChart?.data;


                setGuideAnalyticsDetailAudioChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadAudioGuidanceChartData: [...audioChartData],
                        guideAnalyticsDetailAudioChartDataFetched: true,
                        errorMsg: ''
                    };
                });

                apiCatchError(2, 0);
                enableExportPdf(1, true)

            } else if (fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailAudioChart?.result === 'retry') {
                getGuideAnalyticsDetailAudioChart('retry');
            } else if (fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailAudioChart?.result === "error") {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;

                if (fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailAudioChart?.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }

                setGuideAnalyticsDetailAudioChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDetailAudioChartDataFetched: true,
                        errorMsg: errorMsg
                    };
                });

                apiCatchError(2, errorFlag);
                enableExportPdf(1, false)
            }

        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailAudioChart]);


    // GuideMe Summary Table
    useEffect(() => {
        try {
            if (
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailSummaryTableData.data !== undefined &&
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailSummaryTableData.result === "success"
            ) {
                SummaryMount.current = true;
                setGuideAnalyticsDetailSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDetailSummaryTableDataFetched: true,
                        tableTotal_count: fetched_details.guideAnalyticsDetail.guideAnalyticsDetailSummaryTableData.data.totalCount,
                        errorMsg: ''
                    };
                });
                apiCatchError(3, 0);
                enableExportPdf(2, true);

                setGuideTitle(fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailSummaryTableData?.data?.guideTitle);

            } else if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailSummaryTableData.result === "retry") {
                getGuideAnalyticsDetailSummaryTableData('retry');
            } else if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailSummaryTableData.result === "error") {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;

                if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailSummaryTableData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }
                setGuideAnalyticsDetailSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDetailSummaryTableDataFetched: true,
                        tableTotal_count: 0,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(3, errorFlag);
                enableExportPdf(2, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.guideAnalyticsDetail.guideAnalyticsDetailSummaryTableData]);


    // GuideMe Linked Table
    useEffect(() => {
        try {
            if (
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailLinkedTableData.data !== undefined &&
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailLinkedTableData.result === "success"
            ) {
                SummaryMount.current = true;
                setGuideAnalyticsDetailLinkedTable((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDetailLinkedTableDataFetched: true,
                        errorMsg: ''
                        // sourceId: fetched_details.guideAnalyticsDetail.guideAnalyticsDetailLinkedTableData.data.linkedGuideID,
                        // guideId: fetched_details.guideAnalyticsDetail.guideAnalyticsDetailLinkedTableData.data.guideID

                    };
                });
                apiCatchError(4, 0);
            } else if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailLinkedTableData.result === 'retry') {
                getGuideAnalyticsDetailLinkedTableData(linkedModal.guideId, linkedModal.sourceId, fetched_details.commonComponentData, 'retry');
            } else if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailLinkedTableData.result === "error") {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;

                if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailLinkedTableData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }
                setGuideAnalyticsDetailLinkedTable((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDetailLinkedTableDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(4, errorFlag);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.guideAnalyticsDetail.guideAnalyticsDetailLinkedTableData]);


    // Steps Flow Chart
    useEffect(() => {
        try {
            if (
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailStepsFlowChartData.data !== undefined &&
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailStepsFlowChartData.result === "success"
            ) {
                setGuideAnalyticsDetailSankeyChart((prevState: any) => {
                    return {
                        ...prevState,
                        chartDataFetched: true,
                        errorMsg: ''
                    };
                });
                apiCatchError(5, 0);
                enableExportPdf(2, true)
            } else if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailStepsFlowChartData.result === "retry") {
                getGuideAnalyticsDetailStepsFlowChartData('retry');
            } else if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailStepsFlowChartData.result === "error") {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;

                if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailStepsFlowChartData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }
                setGuideAnalyticsDetailSankeyChart((prevState: any) => {
                    return {
                        ...prevState,
                        chartDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(5, errorFlag);
                enableExportPdf(2, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.guideAnalyticsDetail.guideAnalyticsDetailStepsFlowChartData])

    // Funnel Step List
    useEffect(() => {
        try {
            if (
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailFunnelStepsList.data !== undefined &&
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailFunnelStepsList.result === "success"
            ) {
                setGuideAnalyticsDetailFunnelList((prevState: any) => {
                    return {
                        ...prevState,
                        listFetched: true,
                        stepListData: fetched_details.guideAnalyticsDetail.guideAnalyticsDetailFunnelStepsList.data.guideDetails,
                        errorMsg: ''
                    };
                });
                apiCatchError(6, 0);
                enableExportPdf(2, true)
            } else if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailFunnelStepsList.result === "retry") {
                getGuideAnalyticsDetailFunnelList('retry');
            } else if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailFunnelStepsList.result === "error") {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;

                if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailFunnelStepsList.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }
                setGuideAnalyticsDetailFunnelList((prevState: any) => {
                    return {
                        ...prevState,
                        listFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(6, errorFlag);
                enableExportPdf(2, false)
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.guideAnalyticsDetail.guideAnalyticsDetailFunnelStepsList])

    // ShowMe Chart
    useEffect(() => {
        try {
            if (
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeChartData.data !== undefined &&
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeChartData.result === "success"
            ) {
                ShowMeMount.current = true;
                let showMeChartData: any = [];
                showMeChartData = fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeChartData.data;

                renderDonutChart(showMeChartData);
                renderPieChart(showMeChartData);
                setGuideAnalyticsDetailShowMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDetailShowMeChartDataFetched: true,
                        loadShowMeChartData: [...showMeChartData],
                        errorMsg: ''
                    };
                });

                apiCatchError(7, 0);
                enableExportPdf(3, true)

            } else if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeChartData.result === "retry") {
                getGuideAnalyticsDetailShowMeChartData('retry');
            } else if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeChartData.result === "error") {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;

                if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeChartData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }
                setGuideAnalyticsDetailShowMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDetailShowMeChartDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(7, errorFlag);
                enableExportPdf(3, false)
            }

        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeChartData]);

    // ShowMe Sources
    useEffect(() => {
        try {
            if (
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeSourcesChartData.data !== undefined &&
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeSourcesChartData.result === "success"
            ) {
                ShowMeSourcesMount.current = true;
                let showMeSourcesChartData: any = [];

                let total: number = 0;
                let pieDataAvailable: boolean = false;
                let data: any = [];

                showMeSourcesChartData = fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeSourcesChartData.data.triggerSources;
                data[0] = {};
                data[0].counts = {}
                data[0].counts.showmePlayedViaBeacon = showMeSourcesChartData.showmePlayedViaBeacon;
                data[0].counts.showmePlayedViaPush = showMeSourcesChartData.showmePlayedViaPush;
                data[0].counts.showmePlayedViaPanel = showMeSourcesChartData.showmePlayedViaPanel;
                data[0].counts.showmePlayedViaUrl = showMeSourcesChartData.showmePlayedViaUrl;
                total += showMeSourcesChartData.showmePlayedViaBeacon + showMeSourcesChartData.showmePlayedViaPush + showMeSourcesChartData.showmePlayedViaPanel + showMeSourcesChartData.showmePlayedViaUrl;

                total === 0 ? (pieDataAvailable = false) : (pieDataAvailable = true);
                setGuideAnalyticsDetailShowMeSourcesChart((prevState: any) => {
                    return {
                        ...prevState,
                        showMeSourcesPieChartData: [...data],
                        showMeSourcesPieDataAvailable: pieDataAvailable,
                        guideAnalyticsDetailShowMeSourcesChartDataFetched: true,
                        errorMsg: ''
                    };
                });
                apiCatchError(9, 0);
                // enableExportPdf(3, true);


            } else if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeSourcesChartData.result === "retry") {
                getGuideAnalyticsDetailShowMeSourcesChartData('retry');
            } else if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeSourcesChartData.result === "error") {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;

                if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeSourcesChartData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }
                setGuideAnalyticsDetailShowMeSourcesChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDetailShowMeSourcesChartDataFetched: true,
                        errorMsg: errorMsg
                    };
                });

                apiCatchError(9, errorFlag);
                // enableExportPdf(3, false)
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeSourcesChartData]);

    // ShowMe Steps Data
    useEffect(() => {
        try {
            if (
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeStepsData.data !== undefined &&
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeStepsData.result === "success"
            ) {
                let chartData: any = [];
                chartData[0] = {};
                chartData[0].data = fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeStepsData.data['slideshowStats'];
                chartData[0].type = insightsLbls['slideshowStats'];
                chartData[0].color = ChartColors.color3;

                chartData[1] = {};
                chartData[1].data = fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeStepsData.data['videoStats'];
                chartData[1].type = insightsLbls['videoStats'];
                chartData[1].color = ChartColors.color4;

                setGuideAnalyticsDetailShowMeSteps((prevState: any) => {
                    return {
                        ...prevState,
                        data: chartData,
                        dataFetched: true,
                        selectedData: chartData[0],
                        chartClickFlag: true,
                        errorMsg: ''
                    }
                })
                apiCatchError(10, 0);
            } else if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeStepsData.result === "retry") {
                guideAnalyticsDetailShowMeStepsData('retry');
            } else if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeStepsData.result === "error") {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;

                if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeStepsData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }

                setGuideAnalyticsDetailShowMeSteps((prevState: any) => {
                    return {
                        ...prevState,
                        data: [],
                        dataFetched: true,
                        errorMsg: errorMsg
                    }
                })
                apiCatchError(10, errorFlag);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeStepsData])


    // ShowMe Downloads
    useEffect(() => {
        try {
            if (
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeDownloadsChartData.data !== undefined &&
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeDownloadsChartData.result === "success"
            ) {

                let showMeDownloadsChartData: any = [];
                showMeDownloadsChartData = fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeDownloadsChartData.data;
                showMeDownloadsChartData = CommonUtils.dateSeparator(showMeDownloadsChartData);
                let videoT: any = 0;
                let dPdfT: any = 0;
                let pptT: any = 0;
                let gifT: any = 0;
                let docxT: any = 0;
                let htmlT: any = 0;

                let video: any = 0;
                let dPdf: any = 0;
                let ppt: any = 0;
                let gif: any = 0;
                let docx: any = 0;
                let html: any = 0;
                let total: any = 0;

                for (let i = 0; i < showMeDownloadsChartData.length; i++) {
                    video += showMeDownloadsChartData[i].counts.guide_video_download;
                    dPdf += showMeDownloadsChartData[i].counts.guide_pdf_download;
                    ppt += showMeDownloadsChartData[i].counts.guide_slideshow_download;
                    gif += showMeDownloadsChartData[i].counts.guide_gif_download;
                    docx += showMeDownloadsChartData[i].counts.guide_docx_download;
                    html += showMeDownloadsChartData[i].counts.guide_html_download;

                    videoT += showMeDownloadsChartData[i].counts.guide_video_download;
                    dPdfT += showMeDownloadsChartData[i].counts.guide_pdf_download;
                    pptT += showMeDownloadsChartData[i].counts.guide_slideshow_download;
                    gifT += showMeDownloadsChartData[i].counts.guide_gif_download;
                    docxT += showMeDownloadsChartData[i].counts.guide_docx_download;
                    htmlT += showMeDownloadsChartData[i].counts.guide_html_download;

                    total += showMeDownloadsChartData[i].counts.guide_video_download + showMeDownloadsChartData[i].counts.guide_pdf_download + showMeDownloadsChartData[i].counts.guide_slideshow_download + showMeDownloadsChartData[i].counts.guide_gif_download + showMeDownloadsChartData[i].counts.guide_docx_download + showMeDownloadsChartData[i].counts.guide_html_download;
                }

                videoT = videoT.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                dPdfT = dPdfT.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                pptT = pptT.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                gifT = gifT.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                docxT = docxT.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                htmlT = htmlT.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

                // to find max
                let max1: any = 0
                let max2: any = 0
                max1 = video > gif ? video : gif;
                max2 = ppt > dPdf ? ppt : dPdf;

                let max_val = 0;

                if (max1 > max2) {
                    if (max1 > docx) {
                        max_val = max1;
                    } else {
                        max_val = docx;
                    }
                } else {
                    if (max2 > docx) {
                        max_val = max2;
                    } else {
                        max_val = docx;
                    }
                }

                if (max_val < html) {
                    max_val = html;
                }

                // // to convert values in percentage, by considering max value as 100%
                video = (video / max_val) * 100 + "%";
                gif = (gif / max_val) * 100 + "%";
                ppt = (ppt / max_val) * 100 + "%";
                dPdf = (dPdf / max_val) * 100 + "%";
                docx = (docx / max_val) * 100 + "%";
                html = (html / max_val) * 100 + "%";

                setGuideAnalyticsDetailShowMeDownloadsChart((prevState: any) => {
                    return {
                        ...prevState,
                        videoT: videoT,
                        dPdfT: dPdfT,
                        pptT: pptT,
                        gifT: gifT,
                        docxT: docxT,
                        htmlT: htmlT,
                        video: video,
                        dPdf: dPdf,
                        ppt: ppt,
                        gif: gif,
                        docx: docx,
                        html: html,
                        total: total,
                        guideAnalyticsDetailShowMeDownloadsChartDataFetched: true,
                        errorMsg: ''
                    };
                });
                apiCatchError(8, 0);
                enableExportPdf(3, true)

            } else if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeDownloadsChartData.result === "retry") {
                getGuideAnalyticsDetailShowMeDownloadsChartData('retry');
            } else if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeDownloadsChartData.result === "error") {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;

                if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeDownloadsChartData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }

                setGuideAnalyticsDetailShowMeDownloadsChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDetailShowMeDownloadsChartDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(8, errorFlag);
                // enableExportPdf(3, false)
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details.guideAnalyticsDetail.guideAnalyticsDetailShowMeDownloadsChartData]);


    // TestMe Chart
    useEffect(() => {
        try {
            if (
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailTestMeChartData.data !== undefined &&
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailTestMeChartData.result === "success"
            ) {
                TestMeMount.current = true;
                let testMeChartData: any = [];

                testMeChartData = fetched_details.guideAnalyticsDetail.guideAnalyticsDetailTestMeChartData.data;

                setGuideAnalyticsDetailTestMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadTestMeChartData: [...testMeChartData],
                        guideAnalyticsDetailTestMeChartDataFetched: true,
                        errorMsg: ''
                    };
                });
                apiCatchError(11, 0);
                enableExportPdf(4, true)

            } else if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailTestMeChartData.result === "retry") {
                getGuideAnalyticsDetailTestMeChartData('retry')
            } else if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailTestMeChartData.result === "error") {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;

                if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailTestMeChartData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }

                setGuideAnalyticsDetailTestMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDetailTestMeChartDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(11, errorFlag);
                enableExportPdf(4, false)
            }

        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.guideAnalyticsDetail.guideAnalyticsDetailTestMeChartData]);

    // DoItForMeChart
    useEffect(() => {
        try {
            if (
                fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailDoItForMeChartData?.data !== undefined &&
                fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailDoItForMeChartData?.result === "success"
            ) {
                DoItForMeMount.current = true;
                let doItForMeChartData: any = [];
                doItForMeChartData = fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailDoItForMeChartData.data;

                setGuideAnalyticsDetailDoItForMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadDoItForMeChartData: [...doItForMeChartData],
                        guideAnalyticsDoItForMeChartDataFetched: true,
                        errorMsg: ''
                    };
                });
                apiCatchError(12, 0);
                enableExportPdf(5, true);
            } else if (fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailDoItForMeChartData?.result === "retry") {
                getGuideAnalyticsDetailDoItForMeChartData('retry')
            } else if (fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailDoItForMeChartData?.result === "error") {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;

                if (fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailDoItForMeChartData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }

                setGuideAnalyticsDetailDoItForMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDoItForMeChartDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(12, errorFlag);
                enableExportPdf(5, false)
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailDoItForMeChartData]);

    //DoItForMe Sources
    useEffect(() => {
        try {
            if (
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailDoItForMeSourcesChartData.data !== undefined &&
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailDoItForMeSourcesChartData.result === "success"
            ) {
                DoItForMeSourcesMount.current = true;
                let doItForMeSourcesChartData: any = [];
                let total: number = 0;

                let pieDataAvailable: boolean = false;

                let data: any = [];

                doItForMeSourcesChartData = fetched_details.guideAnalyticsDetail.guideAnalyticsDetailDoItForMeSourcesChartData.data.triggerSources;
                data[0] = {};
                data[0].counts = {}
                data[0].counts.guidePlayedViaBeacon = doItForMeSourcesChartData.guidePlayedViaBeacon;
                data[0].counts.guidePlayedViaPush = doItForMeSourcesChartData.guidePlayedViaPush;
                data[0].counts.guidePlayedViaPanel = doItForMeSourcesChartData.guidePlayedViaPanel;
                data[0].counts.guidePlayedViaUrl = doItForMeSourcesChartData.guidePlayedViaUrl;
                total += doItForMeSourcesChartData.guidePlayedViaBeacon + doItForMeSourcesChartData.guidePlayedViaPush + doItForMeSourcesChartData.guidePlayedViaPanel + doItForMeSourcesChartData.guidePlayedViaUrl;


                total === 0 ? (pieDataAvailable = false) : (pieDataAvailable = true);
                setGuideAnalyticsDetailDoItForMeSourcesChart((prevState: any) => {
                    return {
                        ...prevState,
                        doItForMeSourcesPieChartData: [...data],
                        doItForMeSourcesPieDataAvailable: pieDataAvailable,
                        guideAnalyticsDetailDoItForMeSourcesChartDataFetched: true,
                        errorMsg: ''
                    };
                });
                apiCatchError(1, 0);
                enableExportPdf(0, true)

            } else if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailDoItForMeSourcesChartData.result === 'retry') {
                getGuideAnalyticsDetailDoItForMeSourcesChartData('retry')
            } else if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailDoItForMeSourcesChartData.result === "error") {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;

                if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailDoItForMeSourcesChartData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }

                setGuideAnalyticsDetailDoItForMeSourcesChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDetailDoItForMeSourcesChartDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(1, errorFlag);
                enableExportPdf(0, false)
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details.guideAnalyticsDetail.guideAnalyticsDetailDoItForMeSourcesChartData]);

    //DoItForMeTable
    useEffect(() => {
        try {
            if (
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailDoItForMeTableData.data !== undefined &&
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailDoItForMeTableData.result === "success"
            ) {
                DoItForMeMount.current = true;
                setGuideAnalyticsDetailDoItForMeSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDetailSummaryDoItForMeTableDataFetched: true,
                        errorMsg: ''
                    };
                });
                apiCatchError(13, 0);
                enableExportPdf(5, true);
            } else if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailDoItForMeTableData.result === "retry") {
                getGuideAnalyticsDetailDoItForMeTableData('retry')
            } else if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailDoItForMeTableData.result === "error") {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;

                if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailDoItForMeTableData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }
                setGuideAnalyticsDetailDoItForMeSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDetailSummaryDoItForMeTableDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(13, errorFlag);
                enableExportPdf(5, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.guideAnalyticsDetail.guideAnalyticsDetailDoItForMeTableData]);

    // Survey Chart
    useEffect(() => {
        try {
            if (
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailSurveyChartData.data !== undefined &&
                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailSurveyChartData.result === "success"
            ) {
                SurveyMount.current = true;
                let surveyChartData: any = [];
                surveyChartData = fetched_details.guideAnalyticsDetail.guideAnalyticsDetailSurveyChartData.data.surveyDetails;

                setGuideAnalyticsDetailSurveyChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadSurveyChartData: [...surveyChartData],
                        guideAnalyticsDetailSurveyChartDataFetched: true,
                        exitSurveyResponse: fetched_details.guideAnalyticsDetail.guideAnalyticsDetailSurveyChartData.data.exitSurveyResponse,
                        guideStepSurveyResponse: fetched_details.guideAnalyticsDetail.guideAnalyticsDetailSurveyChartData.data.guideStepSurveyResponse,
                        guideSurveyResponse: fetched_details.guideAnalyticsDetail.guideAnalyticsDetailSurveyChartData.data.guideSurveyResponse,
                        errorMsg: ''
                    };
                });
                apiCatchError(14, 0);
                enableExportPdf(6, true)

            } else if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailSurveyChartData.result === "retry") {
                getGuideAnalyticsDetailSurveyChartData('retry')
            } else if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailSurveyChartData.result === "error") {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;

                if (fetched_details.guideAnalyticsDetail.guideAnalyticsDetailSurveyChartData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }

                setGuideAnalyticsDetailSurveyChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDetailSurveyChartDataFetched: true,
                        errorMsg: errorMsg
                    };
                });
                apiCatchError(14, errorFlag);
                enableExportPdf(6, false)
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [fetched_details.guideAnalyticsDetail.guideAnalyticsDetailSurveyChartData]);


    // Call summary table api on table related actions changes
    useEffect(() => {
        try {
            if (fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailSummaryTableData?.data && fetched_details?.commonComponentData?.datesData.url?.includes('guide-analytics-detail/')) {
                enableExportPdf(2, false);
                getGuideAnalyticsDetailSummaryTableData();
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [
        guideAnalyticsDetailSummaryTable.sortKey,
        guideAnalyticsDetailSummaryTable.sortValue,
        guideAnalyticsDetailSummaryTable.defaultSort,
        guideAnalyticsDetailSummaryTable.page,
        guideAnalyticsDetailSummaryTable.searchQuery,
        guideAnalyticsDetailSummaryTable.limit,
    ]);

    // Call summary link table api on table related actions changes
    useEffect(() => {
        try {
            if (fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailLinkedTableData?.data && fetched_details?.commonComponentData?.datesData.url?.includes('guide-analytics-detail/')) {
                enableExportPdf(4, true);
                getGuideAnalyticsDetailLinkedTableData(linkedModal.guideId, linkedModal.sourceId, fetched_details.commonComponentData);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [
        guideAnalyticsDetailLinkedTable.sortKey,
        guideAnalyticsDetailLinkedTable.sortValue,
        guideAnalyticsDetailLinkedTable.defaultSort,
        guideAnalyticsDetailLinkedTable.page,
        guideAnalyticsDetailLinkedTable.searchQuery,
        guideAnalyticsDetailLinkedTable.limit,
    ]);

    useEffect(() => {
        try {
            if (fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailDoItForMeTableData?.data) {
                enableExportPdf(5, false);
                getGuideAnalyticsDetailDoItForMeTableData();
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [
        guideAnalyticsDetailDoItForMeSummaryTable.sortKey,
        guideAnalyticsDetailDoItForMeSummaryTable.sortValue,
        guideAnalyticsDetailDoItForMeSummaryTable.defaultSort,
        guideAnalyticsDetailDoItForMeSummaryTable.page,
        guideAnalyticsDetailDoItForMeSummaryTable.searchQuery,
        guideAnalyticsDetailDoItForMeSummaryTable.limit,
    ]);

    useEffect(() => {
        try {
            if (
                fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailErrorData?.data !== undefined &&
                fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailErrorData?.result === "success"
            ) {
                setErrorModal((prevState: any) => {
                    return {
                        ...prevState,
                        dataFetched: true,
                        data: fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailErrorData?.data?.stepsData
                    }
                });

                apiCatchError(15, 0);

            } else if (fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailErrorData?.result === "retry") {
                getErrorDetailsData(errorModal.stepID, errorModal.type, 'retry')
            } else if (fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailErrorData?.result === "error") {
                let errorMsg = insightsLbls.serviceNotAvailable;
                let errorFlag = 1;

                if (fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailErrorData.errorCode === 409) {
                    errorMsg = insightsLbls.conflictingParameters;
                    errorFlag = 2;
                }

                setErrorModal((prevState: any) => {
                    return {
                        ...prevState,
                        dataFetched: true,
                        errorMsg: errorMsg,
                        data: []
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailErrorData])


    // Chart Renders and Callbacks @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getRadialChartLabels = useCallback((optns) => {
        if (insightsLbls) {
            return optns;
        }
    }, [insightsLbls])

    const renderDonutChart = useCallback((data: any) => {
        try {
            if (insightsLbls) {
                let video = 0;
                let gif = 0;
                let slideshow = 0;
                let total = 0

                for (let i = 0; i < data.length; i++) {
                    video += data[i].counts.guide_video_play;
                    gif += data[i].counts.guide_gif_play;
                    slideshow += data[i].counts.guide_slideshow_play;
                    total += data[i].counts.guide_video_play + data[i].counts.guide_gif_play + data[i].counts.guide_slideshow_play;
                }
                let dummyDonutData: any = [];

                dummyDonutData = [
                    {
                        key: insightsLbls !== undefined ? insightsLbls['slideshow'] : '',
                        y: slideshow,
                        color: ChartColors.color1
                    },
                    {
                        key: insightsLbls !== undefined ? insightsLbls['video'] : '',
                        y: video,
                        color: ChartColors.color2
                    },
                    {
                        key: insightsLbls !== undefined ? insightsLbls['gif'] : '',
                        y: gif,
                        color: ChartColors.color3
                    },
                ]
                let donutDataAvailable: boolean = false;

                total === 0 ? (donutDataAvailable = false) : (donutDataAvailable = true)

                setGuideAnalyticsDetailShowMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        showMeDonutChartData: dummyDonutData,
                        showMeDonutDataAvailable: donutDataAvailable,
                    };
                });
            }

        } catch (error) {
            showBoundary(error)
        }

    }, [insightsLbls])

    const renderPieChart = (data: any) => {
        try {
            let video = 0;
            let gif = 0;
            let ppt = 0;
            let pdf = 0;
            let docx = 0;
            let html = 0;
            let total = 0;

            for (let i = 0; i < data.length; i++) {
                video += data[i].counts.guide_video_download;
                gif += data[i].counts.guide_gif_download;
                ppt += data[i].counts.guide_slideshow_download;
                pdf += data[i].counts.guide_pdf_download;
                docx += data[i].counts.guide_docx_download;
                html += data[i].counts.guide_html_download;
                total += data[i].counts.guide_video_download + data[i].counts.guide_gif_download + data[i].counts.guide_slideshow_download + data[i].counts.guide_pdf_download + data[i].counts.guide_docx_download + data[i].counts.guide_html_download;
            }

            let dummyPieData: any = [];

            dummyPieData = [
                {
                    key: insightsLbls['downloadPptx'],
                    y: ppt,
                    color: ChartColors.darkturquoise
                },
                {
                    key: insightsLbls['video'],
                    y: video,
                    color: ChartColors.brightred
                },
                {
                    key: insightsLbls['gif'],
                    y: gif,
                    color: ChartColors.mediumpurple
                },
                {
                    key: insightsLbls['pdf'],
                    y: pdf,
                    color: ChartColors.lightgreen
                },
                {
                    key: insightsLbls['docx'],
                    y: docx,
                    color: ChartColors.yellowsandybrown

                },
                {
                    key: insightsLbls['html'],
                    y: html,
                    color: ChartColors.orangesandybrown
                }
            ]

            let pieDataAvailable: boolean = false;

            total === 0 ? (pieDataAvailable = false) : (pieDataAvailable = true)

            setGuideAnalyticsDetailShowMeChart((prevState: any) => {
                return {
                    ...prevState,
                    showMePieChartData: dummyPieData,
                    showMePieDataAvailable: pieDataAvailable,
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    }




    const getPieData = (option: string) => {
        try {
            if (option === 'guide') {
                return guideAnalyticsDetailGuideMeSourcesChart.guideMeSourcesPieChartData
            } else if (option === 'doItForMe') {
                return guideAnalyticsDetailDoItForMeSourcesChart.doItForMeSourcesPieChartData

            } else {
                return guideAnalyticsDetailShowMeSourcesChart.showMeSourcesPieChartData

            }
        } catch (error) {
            showBoundary(error)
        }

    };



    const handleShowMeSwitch = (e: any) => {
        try {
            setShowMeTabKey((prevState: any) => {
                return {
                    ...prevState,
                    key: e
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    const getGuideStatusValue = (value: string) => {
        try {
            switch (value) {
                case "Published":
                    return insightsLbls['published'];

                case "Unpublished":
                    return insightsLbls['unpublished'];

                case "Deleted":
                    return insightsLbls['deleted'];

                default:
                    return insightsLbls['published'];
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const surveyModal = (count: number, type: string) => {
        try {
            if (count === -1 && type === 'view') {
                let count_val = 0;
                let type_val = ''
                if (guideAnalyticsDetailSurveyChart.guideSurveyResponse > 0) {
                    type_val = 'guide_sentiment';
                    count_val = guideAnalyticsDetailSurveyChart.guideSurveyResponse;
                } else if (guideAnalyticsDetailSurveyChart.exitSurveyResponse > 0) {
                    type_val = 'guide_exit_sentiment';
                    count_val = guideAnalyticsDetailSurveyChart.exitSurveyResponse;
                } else if (guideAnalyticsDetailSurveyChart.guideStepSurveyResponse > 0) {
                    type_val = 'guide_step_sentiment';
                    count_val = guideAnalyticsDetailSurveyChart.guideStepSurveyResponse;
                }

                setGuideSurveyModal((prevState: any) => {
                    return {
                        ...prevState,
                        modalOpen: true,
                        count: count_val,
                        type: type_val
                    }
                })
            } else {
                setGuideSurveyModal((prevState: any) => {
                    return {
                        ...prevState,
                        modalOpen: true,
                        count: count,
                        type: type
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const checkDisable = (value: number) => {
        try {
            return +value === 0 ? ' opaque' : '';
        } catch (error) {
            showBoundary(error)
        }

    }

    const navigateToMainPage = () => {
        let queryParams = {
            app_id: fetched_details?.commonComponentData.appsData.appId,
            app_code: fetched_details?.commonComponentData.appsData.appCode,
            fdate: fetched_details?.commonComponentData?.datesData.fdate,
            tdate: fetched_details?.commonComponentData?.datesData.tdate,
            event_source: fetched_details?.commonComponentData?.eventSource?.eventSourceGuides,
            state: fetched_details?.commonComponentData?.stateFilterData?.stateFilter,
            segment: fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
            env: fetched_details?.commonComponentData?.envFilter?.envCode,
        }

        navigate({
            pathname: `/guide-insights/guide-analytics/`,
            search: decodeURIComponent(qs.stringify(queryParams))
        })
    }

    return (
        <section className="demo guideAnalyticsDetail width100" ref={componentRef}>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <SectionHeader>
                        <li>
                            <span className="cursorPointer" onClick={() => navigateToMainPage()}>{insightsLbls['guidesTab']}</span>
                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                        </li>
                        <li className="active">{insightsLbls['guideDetail']}</li>
                    </SectionHeader>
                </div>

                <SectionFilters>
                    <EnvironmentFilter component={"guideAnalyticsDetail"} disabled={true} />
                    <Datepicker source={'component'} />
                </SectionFilters>
            </div>

            {/* <SectionButtons sectionTitle={guideTitle} svgImage={"guides"} className={'marginTop-50'}>
                {localStorage.getItem('USER_ACTIVITY') === 'true' && (
                    <button type="button" id="userActivity" className="exportPdf-btn userActivityBtn" onClick={() => openUserAcitivtyModal()} data-html2canvas-ignore="true">
                        <i className="fa fa-signal marginRight-5" aria-hidden="true"></i>
                        <span className="width85">User Activity</span>
                    </button>
                )}

                <ExportToPdf
                    componentRef={componentRef}
                    source={"Guide Analytics Detail"}
                    widthDividedBy={12}
                    disabled={loaderBtn.disabled}
                ></ExportToPdf>
            </SectionButtons> */}

            <div className="marginTop-60 exportBtn-div">
                <Dropdown as={ButtonGroup}>
                    <Button variant="info">{insightsLbls.export}</Button>
                    <Dropdown.Toggle split variant="success" id="dropdown-custom-2" />
                    <Dropdown.Menu className="super-colors">
                        <Dropdown.Item eventKey="1" >
                            <ExportToPdf
                                componentRef={componentRef}
                                source={"Guide Analytics Detail"}
                                widthDividedBy={12}
                                disabled={loaderBtn.disabled}
                            ></ExportToPdf>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <UserInfoSection
                startDate={fetched_details?.commonComponentData?.datesData.fdate}
                endDate={fetched_details?.commonComponentData?.datesData.tdate}
                messageText={guideTitle}>

                <div className="displayInlineFlex">
                    {guideDetail.guideStatus_flag
                        ? (<div className="">
                            <span className='infoChip marginLeft-5'>
                                {insightsLbls && (getGuideStatusValue(guideDetail.guideStatus))}
                            </span>
                        </div>)
                        : (
                            <></>
                            // <div className="displayFlex alignCenter margin-0-auto">
                            //     <Loader width={50} height={'auto'} margin={'none'}></Loader>
                            // </div>
                        )
                    }
                    <span className="infoChip marginLeft-5">{insightsLbls['totalSteps']}:- {guideAnalyticsDetailSummaryTable.tableTotal_count}</span>
                </div>
            </UserInfoSection>


            <div id="guideAnalyticsDetailSection">
                {/* {
                    <SectionRefresh sectionTitle={insightsLbls.guideMePlay} refreshClass={"marginTop-30"}>
                        <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls['refresh']} onClick={() => refreshSections(0)}></i>
                    </SectionRefresh>
                } */}

                {/* <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant"> */}
                <div className="col-lg-12 page_section paddingLeft-10 paddingRight-10 justifyCenter height535 displayFlex">
                    <CardContainer div1Classes={"col-md-6 col-xs-6 table-container padding0 marginRight-10"}
                        div2Classes={"card marginTop-0 section-wrapper  icon-text chart-wrapper5 height100-percent"}
                        div3Classes={"col-sm-12 height535 height100-percent row marginLR-0"}>
                        <div className="col-sm-12 paddingTop-20 overflowX chartdiv search_section height535 ">
                            <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                <SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.guidePlayedStats,
                                        "tooltipText": insightsLbls.graphActivitiesOnLiveGuide,
                                        "childern": [
                                            {
                                                "key": insightsLbls.totalPlayed + ":",
                                                "value": insightsLbls.totalNumberOfGuidesPlayed
                                            },
                                            {
                                                "key": insightsLbls.uniquePlayed + ":",
                                                "value": insightsLbls.totalNumberOfUniqueGuidesPlayed
                                            },
                                            {
                                                "key": insightsLbls.completed + ":",
                                                "value": insightsLbls.totalNumberOfCompleteGuidesPlayed
                                            }
                                        ]
                                    }
                                } placement="right"></SectionTooltip>
                                <button className="pointer refreshBtn pull-right" onClick={() => refreshSections(0, 'lineChart')}> {insightsLbls.refresh}</button>

                            </ContainerTooltip>
                            {
                                guideAnalyticsDetailGuideMeChart.guideAnalyticsDetailGuideMeChartDataFetched ? (
                                    errorCount.errorCount[0] === 0 ? (
                                        guideAnalyticsDetailGuideMeChart.loadGuideMeChartData.length === 0 ? (
                                            <div className="noDataText-font textCenter-absolute">
                                                {insightsLbls.noRecordFound}
                                            </div>
                                        ) : (
                                            <LineChart
                                                chartEntities={['guide_play', 'guide_unique_play', 'guide_complete']}
                                                optionEntities={[
                                                    { key: insightsLbls !== undefined ? insightsLbls['totalPlayed'] : '', color: ChartColors.color1, area: true },
                                                    { key: insightsLbls !== undefined ? insightsLbls['uniquePlayed'] : '', color: ChartColors.color2, area: true },
                                                    { key: insightsLbls !== undefined ? insightsLbls['completed'] : '', color: ChartColors.color3, area: true }
                                                ]}
                                                chartData={fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailGuideMeChartData?.data}
                                                value_type={"count"}
                                                height={linechart_height}
                                                margin={lineChartMargin}
                                                yAxisLabel={insightsLbls['yAxisLabelCount']}
                                                refId="guideAnalyticsDetail_guideme">
                                            </LineChart>
                                        )
                                    ) : (
                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                            {guideAnalyticsDetailGuideMeChart.errorMsg}
                                        </h5>
                                    )
                                ) : (
                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative height535">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader></Loader>
                                        </div>
                                    </div>
                                )
                            }
                        </div>


                    </CardContainer>

                    <CardContainer div1Classes={"col-md-6 col-xs-6 table-container padding0 marginLeft-10"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5  height100-percent"}
                        div3Classes={"col-sm-12 height535 height100-percent row marginLR-0"}>

                        <div className="col-sm-12 paddingTop-20 overflowX chartdiv search_section height535">
                            <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                <SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.guidePlayedOverview,
                                        "tooltipText": insightsLbls.radialChartLiveGuides,
                                        "childern": [
                                            {
                                                "key": insightsLbls.totalPlayed + ":",
                                                "value": insightsLbls.totalNumberOfGuidesPlayed
                                            },
                                            {
                                                "key": insightsLbls.uniquePlayed + ":",
                                                "value": insightsLbls.totalNumberOfUniqueGuidesPlayed
                                            },
                                            {
                                                "key": insightsLbls.completed + ":",
                                                "value": insightsLbls.totalNumberOfCompleteGuidesPlayed
                                            },
                                            {
                                                "key": insightsLbls.avgPlayed + ":",
                                                "value": insightsLbls.avgGuidePlayed
                                            }
                                        ]
                                    }
                                } placement="left"></SectionTooltip>
                                <button className="pointer refreshBtn pull-right" onClick={() => refreshSections(0, 'radialChart')}> {insightsLbls.refresh}</button>
                            </ContainerTooltip>
                            {guideAnalyticsDetailGuideMeRadialChart.guideAnalyticsDetailGuideMeRadialChartDataFetched ? (
                                errorCount.errorCount[0] === 0 ? (
                                    guideAnalyticsDetailGuideMeRadialChart.loadGuideMeRadialChartData.length === 0 ? (
                                        <div className="noDataText-font textCenter-absolute">
                                            {insightsLbls.noRecordFound}
                                        </div>
                                    ) : (
                                        <RadialChart
                                            chartData={guideAnalyticsDetailGuideMeRadialChart.loadGuideMeRadialChartData}
                                            width={300}
                                            height={300}>
                                        </RadialChart>
                                    )
                                ) : (
                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                        {guideAnalyticsDetailGuideMeRadialChart.errorMsg}
                                    </h5>
                                )
                            ) : (
                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative height535">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <Loader></Loader>
                                    </div>
                                </div>
                            )}
                        </div>
                    </CardContainer>
                </div>


                <CardContainer
                    div1Classes={"col-sm-12 col-lg-12 col-md-12  padding0 marginTop-20"}
                    div2Classes={"card icon-text section-wrapper marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0"}
                    div3Classes={"card-body height535  overflowY-hidden"}>
                    <div className="col-sm-12  overflowX chartdiv search_section">
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            <SectionTooltip tooltipText={
                                {
                                    "subTitle": insightsLbls.guidePlaySources,
                                    "tooltipText": insightsLbls.pieChartGuideSources,
                                    "childern": []
                                }
                            } placement="right"></SectionTooltip>
                            <button className="pointer refreshBtn pull-right" onClick={() => refreshSections(0, 'pieChart')}> {insightsLbls.refresh}</button>
                        </ContainerTooltip>
                        <div className="minHeight400">
                            {guideAnalyticsDetailGuideMeSourcesChart.guideAnalyticsDetailGuideMeSourcesChartDataFetched ? (
                                errorCount.errorCount[1] === 0 ? (
                                    !guideAnalyticsDetailGuideMeSourcesChart.guideMeSourcesPieDataAvailable ? (
                                        <div className="noDataText-font textCenter-absolute">
                                            {insightsLbls.noRecordFound}
                                        </div>
                                    ) : (
                                        <Piechart
                                            chartData={getPieData('guide')}
                                            chartEntities={['guidePlayedViaBeacon', 'guidePlayedViaPush', 'guidePlayedViaPanel', 'guidePlayedViaUrl']}
                                            optionEntities={[
                                                { key: insightsLbls !== undefined ? insightsLbls['beacons'] : '', color: ChartColors.color1 },
                                                { key: insightsLbls !== undefined ? insightsLbls['pushNotifications'] : '', color: ChartColors.color2 },
                                                { key: insightsLbls !== undefined ? insightsLbls['panel'] : '', color: ChartColors.color3 },
                                                { key: insightsLbls !== undefined ? insightsLbls['url'] : '', color: ChartColors.color4 }
                                            ]}
                                            valueFormat={'value'}
                                            height={300}
                                            width={400}
                                            showLegends={true}
                                            chartType={'pie'}
                                            labelType={'percent'}
                                            pieMargin={{
                                                top: 60
                                            }}>
                                        </Piechart>
                                    )
                                ) : (
                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                        {guideAnalyticsDetailGuideMeSourcesChart.errorMsg}
                                    </h5>
                                )
                            ) : (
                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative height535">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <Loader></Loader>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                </CardContainer>



                {process.env.REACT_APP_WESTPAC === 'false' &&
                    (
                        <>
                            {/* {
                                <SectionRefresh sectionTitle={insightsLbls.audioGuidance} refreshClass={"marginTop-30"}>
                                    <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls['refresh']} onClick={() => refreshSections(1)}></i>
                                </SectionRefresh>
                            } */}
                            {/* <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant"> */}
                            <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0 marginTop-20"}
                                div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                                div3Classes={"col-sm-12 height535 height100-percent padding15"}>
                                <ContainerTooltip div1Classes={"marginBottom-20"} div3Classes={""} hTagClasses={"section-heading headingText"}>
                                    <SectionTooltip tooltipText={
                                        {
                                            "subTitle": insightsLbls.audioGuidance,
                                            "tooltipText": insightsLbls.audioConsumptionGuideMeShowMe,
                                            "childern": [
                                                {
                                                    "key": insightsLbls.withAudio + ":",
                                                    "value": insightsLbls.guideWithAudio1
                                                },
                                                {
                                                    "key": insightsLbls.withouAudio + ":",
                                                    "value": insightsLbls.guideWithoutAudio1
                                                }
                                            ]
                                        }
                                    } placement="right"></SectionTooltip>
                                    <button className="pointer refreshBtn pull-right" onClick={() => refreshSections(1)}> {insightsLbls.refresh}</button>
                                </ContainerTooltip>

                                <div className="minHeight400">
                                    <Tabs
                                        activeKey={tabKey.key}
                                        onSelect={handleSwitch}
                                        id="controlled-tab-example"
                                    >
                                        <Tab eventKey={1} title={insightsLbls['guideMe']}>
                                            {guideAnalyticsDetailAudioChart.guideAnalyticsDetailAudioChartDataFetched ? (
                                                errorCount.errorCount[2] === 0 ? (
                                                    guideAnalyticsDetailAudioChart.loadAudioGuidanceChartData.length === 0 ? (
                                                        <div className="noDataText-font textCenter-absolute">
                                                            {insightsLbls.noRecordFound}
                                                        </div>
                                                    ) : (
                                                        guideAnalyticsDetailAudioChart.audioChartFetched ? <>{guideAnalyticsDetailAudioChart.audioChart}</> :
                                                            (
                                                                <BarChart
                                                                    chartEntities={['with_audio', 'without_audio']}
                                                                    optionEntities={
                                                                        [
                                                                            { key: insightsLbls && insightsLbls['withAudio'], color: ChartColors.color3, area: true },
                                                                            { key: insightsLbls && insightsLbls['withouAudio'], color: ChartColors.color4, area: true }
                                                                        ]}
                                                                    chartData={fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailAudioChart?.data}
                                                                    value_type={"count"}
                                                                    height={linechart_height}
                                                                    margin={lineChartMargin}
                                                                    yAxisLabel={insightsLbls['yAxisLabelCount']}
                                                                    refId="guideAnalyticsDetail_audio"
                                                                    parseIntFlag={true}>
                                                                </BarChart>

                                                            )
                                                    )
                                                ) : (
                                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                                        {guideAnalyticsDetailAudioChart.errorMsg}
                                                    </h5>
                                                )
                                            ) : (
                                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative height535">
                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                        <Loader></Loader>
                                                    </div>
                                                </div>
                                            )}
                                        </Tab>

                                        <Tab eventKey={2} title={insightsLbls['showMe'] + ' ' + insightsLbls['video']}>
                                            {guideAnalyticsDetailAudioChart.guideAnalyticsDetailAudioChartDataFetched ? (
                                                errorCount.errorCount[2] === 0 ? (
                                                    guideAnalyticsDetailAudioChart.loadAudioGuidanceChartData.length === 0 ? (
                                                        <div className="noDataText-font textCenter-absolute">
                                                            {insightsLbls.noRecordFound}
                                                        </div>
                                                    ) : (
                                                        guideAnalyticsDetailAudioChart.audioChartFetched ? <>{guideAnalyticsDetailAudioChart.audioChart}</> :
                                                            (
                                                                <BarChart
                                                                    chartEntities={['video_with_audio', 'video_without_audio']}
                                                                    optionEntities={
                                                                        [
                                                                            { key: insightsLbls && insightsLbls['withAudio'], color: ChartColors.color3, area: true },
                                                                            { key: insightsLbls && insightsLbls['withouAudio'], color: ChartColors.color4, area: true }
                                                                        ]}
                                                                    chartData={fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailAudioChart?.data}
                                                                    value_type={"count"}
                                                                    height={linechart_height}
                                                                    margin={lineChartMargin}
                                                                    yAxisLabel={insightsLbls['yAxisLabelCount']}
                                                                    refId="guideAnalyticsDetail_video"
                                                                    parseIntFlag={true}>
                                                                </BarChart>
                                                            )
                                                    )
                                                ) : (
                                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                                        {guideAnalyticsDetailAudioChart.errorMsg}
                                                    </h5>
                                                )
                                            ) : (
                                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative height535">
                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                        <Loader></Loader>
                                                    </div>
                                                </div>
                                            )}

                                        </Tab>

                                        <Tab eventKey={3} title={insightsLbls['showMe'] + ' ' + insightsLbls['slideshow']}>
                                            {guideAnalyticsDetailAudioChart.guideAnalyticsDetailAudioChartDataFetched ? (
                                                errorCount.errorCount[2] === 0 ? (
                                                    guideAnalyticsDetailAudioChart.loadAudioGuidanceChartData.length === 0 ? (
                                                        <div className="noDataText-font textCenter-absolute">
                                                            {insightsLbls.noRecordFound}
                                                        </div>
                                                    ) : (
                                                        guideAnalyticsDetailAudioChart.audioChartFetched ? <>{guideAnalyticsDetailAudioChart.audioChart}</> :
                                                            (
                                                                <BarChart
                                                                    chartEntities={['slideshow_with_audio', 'slideshow_without_audio']}
                                                                    optionEntities={
                                                                        [
                                                                            { key: insightsLbls && insightsLbls['withAudio'], color: ChartColors.color3, area: true },
                                                                            { key: insightsLbls && insightsLbls['withouAudio'], color: ChartColors.color4, area: true }
                                                                        ]}
                                                                    chartData={fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailAudioChart?.data}
                                                                    value_type={"count"}
                                                                    height={linechart_height}
                                                                    margin={lineChartMargin}
                                                                    yAxisLabel={insightsLbls['yAxisLabelCount']}
                                                                    refId="guideAnalyticsDetail_video"
                                                                    parseIntFlag={true}>
                                                                </BarChart>

                                                            )
                                                    )
                                                ) : (
                                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                                        {guideAnalyticsDetailAudioChart.errorMsg}
                                                    </h5>
                                                )
                                            ) : (
                                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative height535">
                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                        <Loader></Loader>
                                                    </div>
                                                </div>
                                            )}
                                        </Tab>
                                    </Tabs>
                                </div>
                            </CardContainer>

                            {/* {
                                <SectionRefresh sectionTitle={insightsLbls.guideMeDetails} refreshClass={"marginTop-30"}>
                                    <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls['refresh']} onClick={() => refreshSections(2)}></i>
                                </SectionRefresh>
                            } */}
                            <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0 marginTop-20"}
                                div2Classes={"card marginTop-0 section-wrapper borderBottom icon-text chart-wrapper5 borderTop height100-percent"}
                                div3Classes={"col-sm-12 minHeight400 height100-percent padding15"}>
                                <div className="col-sm-12 col-md-12 col-lg-12 padding0  minHeight350">
                                    <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                        <SectionTooltip tooltipText={
                                            {
                                                "subTitle": insightsLbls.detailedSummary,
                                                "tooltipText": insightsLbls.tableOfLiveGuideActivities,
                                                "childern": [
                                                    {
                                                        "key": insightsLbls.stepTitle + ":",
                                                        "value": insightsLbls.titleOfStep
                                                    },
                                                    {
                                                        "key": insightsLbls.stepPlayedCount + ":",
                                                        "value": insightsLbls.numberOfTimesStepPlayed
                                                    },
                                                    {
                                                        "key": insightsLbls.startStep + ":",
                                                        "value": insightsLbls.stepBeganFromCurrentStep
                                                    },
                                                    {
                                                        "key": insightsLbls.endStep + ":",
                                                        "value": insightsLbls.stepEndedOnCurrentStep
                                                    },
                                                    {
                                                        "key": insightsLbls.failedOnStep + ":",
                                                        "value": insightsLbls.stepErrors
                                                    }
                                                ]
                                            }
                                        } placement="right"></SectionTooltip>
                                        <button className="pointer refreshBtn pull-right" onClick={() => refreshSections(2)}> {insightsLbls.refresh}</button>
                                    </ContainerTooltip>
                                    <div className="col-sm-12 paddingTop-20 overflowX chartdiv search_section">
                                        {
                                            guideAnalyticsDetailSummaryTable.guideAnalyticsDetailSummaryTableDataFetched ? (
                                                errorCount.errorCount[3] === 0 ? (
                                                    <div className="commonSummaryTable">
                                                        <CommonTable tableId="linkedTable"
                                                            data={fetched_details.guideAnalyticsDetail.guideAnalyticsDetailSummaryTableData.data}
                                                            currentPage={guideAnalyticsDetailSummaryTable.page}
                                                            barColors=""
                                                            linkPresent={false}
                                                            showExport={true}
                                                            showSearch={false}
                                                            showEntries={true}
                                                            statusFailAction={'guideme'}
                                                            defaultSort={guideAnalyticsDetailSummaryTable.defaultSort}
                                                            tablePagination={guideAnalyticsDetailSummaryTable.tablePagination}
                                                            tableEvent={handleTableEvents}
                                                            firstTimeloadFlag={fetched_details.guideAnalyticsDetail.guideAnalyticsDetailSummaryTableData.firstTimeload}
                                                            modalTable={false}
                                                        />
                                                    </div>
                                                )
                                                    : errorCount.errorCount[3] === 1 ? (
                                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                                            {guideAnalyticsDetailSummaryTable.errorMsg}
                                                        </h5>
                                                    )
                                                        : null)
                                                : (
                                                    <div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
                                                        <div className="displayFlex alignCenter margin-0-auto">
                                                            <Loader></Loader>
                                                        </div>
                                                    </div>
                                                )
                                        }
                                    </div>
                                </div>
                            </CardContainer>

                            {process.env.REACT_APP_WESTPAC === 'false' &&
                                (
                                    <>
                                        <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0 marginTop-20"}
                                            div2Classes={"card marginTop-0 section-wrapper borderBottom icon-text chart-wrapper5 borderTop height100-percent"}
                                            div3Classes={"col-sm-12 minHeight400 height100-percent"}>
                                            <div className="col-sm-12 col-md-12 col-lg-12 padding0 paddingTop-20 minHeight350">
                                                <ContainerTooltip div1Classes={"paddingLeft-15 paddingRight-15"} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                    <SectionTooltip tooltipText={
                                                        {
                                                            "subTitle": insightsLbls.stepsFlow,
                                                            "tooltipText": insightsLbls.sankeyPaths,
                                                            "childern": []
                                                        }
                                                    } placement="right"></SectionTooltip>
                                                    <button className="pointer refreshBtn pull-right" onClick={() => refreshSections(7)}> {insightsLbls.refresh}</button>

                                                </ContainerTooltip>
                                                <div className="col-sm-12 paddingTop-20 overflowX chartdiv search_section padding0">

                                                    <>
                                                        {guideAnalyticsDetailSankeyChart.chartDataFetched ? (
                                                            errorCount.errorCount[5] === 0 ? (
                                                                fetched_details.guideAnalyticsDetail.guideAnalyticsDetailStepsFlowChartData.data.hasOwnProperty('nodes') ? (
                                                                    // <></>
                                                                    <Sankeychart
                                                                        version={'analytics'}
                                                                        chartData={fetched_details.guideAnalyticsDetail.guideAnalyticsDetailStepsFlowChartData.data}>
                                                                    </Sankeychart>
                                                                ) : (
                                                                    <div className="noDataText-font textCenter-absolute">
                                                                        {insightsLbls.noRecordFound}
                                                                    </div>

                                                                )
                                                            ) : (
                                                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                    {guideAnalyticsDetailSankeyChart.errorMsg}
                                                                </h5>
                                                            )
                                                        ) : (
                                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative height535">
                                                                <div className="displayFlex alignCenter margin-0-auto">
                                                                    <Loader></Loader>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                </div>
                                            </div>
                                        </CardContainer>
                                    </>
                                )}


                            <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0 marginTop-20"}
                                div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                                div3Classes={"col-sm-12 height100-percent"}>

                                <div className="col-sm-12 col-md-12 col-lg-12 padding0 paddingTop-20 minHeight260">
                                    <ContainerTooltip div1Classes={"paddingLeft-15 paddingRight-15"} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                        <SectionTooltip tooltipText={
                                            {
                                                "subTitle": insightsLbls.stepDropOff,
                                                "tooltipText": insightsLbls.funnelWarningMsg,
                                                "childern": []
                                            }
                                        } placement="right"></SectionTooltip>
                                        <button className="pointer refreshBtn pull-right" onClick={() => refreshSections(2)}> {insightsLbls.refresh}</button>
                                    </ContainerTooltip>
                                    <div className="col-sm-12 paddingLeft-90 paddingTop-20 overflowX chartdiv search_section paddingBottom-50">
                                        {guideAnalyticsDetailFunnelList.listFetched ? (
                                            errorCount.errorCount[6] === 0 ? (
                                                guideAnalyticsDetailFunnelList.stepListData.length === 0 ? (
                                                    <div className="noDataText-font textCenter-absolute"
                                                    >
                                                        {insightsLbls.noRecordFound}
                                                    </div>
                                                ) : (
                                                    <FunnelComponent
                                                        stepsList={guideAnalyticsDetailFunnelList.stepListData}
                                                        apiParams={funnelData.funnelVisualizationParameter}
                                                        component="guide"
                                                        apiPath={funnelData.funnelVisualizationPath}
                                                        listTitle={insightsLbls['guideSteps']}
                                                    ></FunnelComponent>
                                                )
                                            ) : (
                                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                                    {guideAnalyticsDetailFunnelList.errorMsg}
                                                </h5>
                                            )
                                        ) : (
                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative ">
                                                <div className="displayFlex alignCenter margin-0-auto">
                                                    <Loader></Loader>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </CardContainer>
                            {/* </div> */}


                            {process.env.REACT_APP_WESTPAC === 'false' &&




                                (
                                    <>
                                        {/* SHOWME SECT */}
                                        {
                                            // <SectionRefresh sectionTitle={insightsLbls.showMe} refreshClass={"marginTop-30"}>
                                            //     <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls['refresh']} onClick={() => refreshSections(3)}></i>
                                            // </SectionRefresh>
                                        }

                                        {/* <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant"> */}
                                        <div className="col-lg-12 page_section paddingLeft-10 justifyCenter height535 displayFlex marginTop-20">
                                            <CardContainer div1Classes={"col-md-6 col-xs-6 table-container padding0 marginRight-10"}
                                                div2Classes={"card marginTop-0 section-wrapper  icon-text chart-wrapper5 height100-percent"}
                                                div3Classes={"col-sm-12 height535 height100-percent row marginLR-0"}>
                                                <div className="col-sm-12 paddingTop-20 overflowX chartdiv search_section height535 ">
                                                    <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                        <SectionTooltip tooltipText={
                                                            {
                                                                "subTitle": insightsLbls.played,
                                                                "tooltipText": insightsLbls.graphActivitiesOnShowMe,
                                                                "childern": [
                                                                    {
                                                                        "key": insightsLbls.slideshows + ":",
                                                                        "value": insightsLbls.slideshowPlayedInGivenTimeFrame
                                                                    },
                                                                    {
                                                                        "key": insightsLbls.videos + ":",
                                                                        "value": insightsLbls.totalVideosPlayedInGivenTimeFrame
                                                                    },
                                                                    {
                                                                        "key": insightsLbls.gifs + ":",
                                                                        "value": insightsLbls.totalGIFdPlayedInGivenTimeFrame
                                                                    }
                                                                ]
                                                            }
                                                        } placement="right"></SectionTooltip>
                                                        <button className="pointer refreshBtn pull-right" onClick={() => refreshSections(3, 'lineChart')}> {insightsLbls.refresh}</button>

                                                    </ContainerTooltip>
                                                    {guideAnalyticsDetailShowMeChart.guideAnalyticsDetailShowMeChartDataFetched ? (
                                                        errorCount.errorCount[7] === 0 ? (
                                                            guideAnalyticsDetailShowMeChart.loadShowMeChartData.length === 0 ? (
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            ) : (

                                                                <Tabs
                                                                    activeKey={showMeTabKey.key}
                                                                    onSelect={handleShowMeSwitch}
                                                                    id="showMe-tabs">
                                                                    <Tab eventKey={0} title={insightsLbls['slideshow']}>
                                                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350 minWidth500 overflowX-auto marginTop-30">
                                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                                <LineChart
                                                                                    chartEntities={['guide_slideshow_play', 'guide_slideshow_complete']}
                                                                                    optionEntities={
                                                                                        [
                                                                                            { key: insightsLbls !== undefined ? insightsLbls['play'] : '', color: ChartColors.color1, area: true },
                                                                                            { key: insightsLbls !== undefined ? insightsLbls['completed'] : '', color: ChartColors.color2, area: true }
                                                                                        ]}
                                                                                    chartData={guideAnalyticsDetailShowMeChart.loadShowMeChartData}
                                                                                    value_type={"count"}
                                                                                    height={350}
                                                                                    margin={{
                                                                                        top: "70",
                                                                                        right: "50",
                                                                                        bottom: "20",
                                                                                        left: "75"
                                                                                    }}
                                                                                    width={600}
                                                                                    yAxisLabel={insightsLbls['yAxisLabelCount']}
                                                                                    refId="guideAnalytics_showme_slideshow">
                                                                                </LineChart>
                                                                            </div>
                                                                        </div>
                                                                    </Tab>
                                                                    <Tab eventKey={1} title={insightsLbls['video']}>
                                                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350 minWidth500 overflowX-auto marginTop-30">
                                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                                <LineChart
                                                                                    chartEntities={['guide_video_play', 'guide_video_complete']}
                                                                                    optionEntities={
                                                                                        [
                                                                                            { key: insightsLbls !== undefined ? insightsLbls['play'] : '', color: ChartColors.color1, area: true },
                                                                                            { key: insightsLbls !== undefined ? insightsLbls['completed'] : '', color: ChartColors.color2, area: true }
                                                                                        ]}
                                                                                    chartData={guideAnalyticsDetailShowMeChart.loadShowMeChartData}
                                                                                    value_type={"count"}
                                                                                    height={350}
                                                                                    margin={{
                                                                                        top: "70",
                                                                                        right: "50",
                                                                                        bottom: "20",
                                                                                        left: "75"
                                                                                    }}
                                                                                    width={600}
                                                                                    yAxisLabel={insightsLbls['yAxisLabelCount']}
                                                                                    refId="guideAnalytics_showme_video">
                                                                                </LineChart>
                                                                            </div>
                                                                        </div>
                                                                    </Tab>
                                                                    <Tab eventKey={2} title={insightsLbls['gif']}>
                                                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350 minWidth500 overflowX-auto marginTop-30">
                                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                                <LineChart
                                                                                    chartEntities={['guide_gif_play']}
                                                                                    optionEntities={
                                                                                        [
                                                                                            { key: insightsLbls !== undefined ? insightsLbls['play'] : '', color: ChartColors.color1, area: true }
                                                                                        ]}
                                                                                    chartData={guideAnalyticsDetailShowMeChart.loadShowMeChartData}
                                                                                    value_type={"count"}
                                                                                    height={350}
                                                                                    margin={{
                                                                                        top: "70",
                                                                                        right: "50",
                                                                                        bottom: "20",
                                                                                        left: "75"
                                                                                    }}
                                                                                    width={600}
                                                                                    yAxisLabel={insightsLbls['yAxisLabelCount']}
                                                                                    refId="guideAnalytics_showme_gif">
                                                                                </LineChart>
                                                                            </div>
                                                                        </div>
                                                                    </Tab>
                                                                </Tabs>
                                                            )
                                                        ) : (
                                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                {guideAnalyticsDetailShowMeChart.errorMsg}
                                                            </h5>
                                                        )
                                                    ) : (
                                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight430">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <Loader></Loader>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>


                                            </CardContainer>

                                            <CardContainer div1Classes={"col-md-6 col-xs-6 table-container padding0 marginRight-10"}
                                                div2Classes={"card marginTop-0 section-wrapper  icon-text chart-wrapper5 height100-percent"}
                                                div3Classes={"col-sm-12 height535 height100-percent row marginLR-0"}>
                                                <div className="col-sm-12 paddingTop-20 overflowX chartdiv search_section height535 ">
                                                    <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                        <SectionTooltip tooltipText={
                                                            {
                                                                "subTitle": insightsLbls.downloads,
                                                                "tooltipText": insightsLbls.downloadDistribution,
                                                                "childern": []
                                                            }
                                                        } placement="left"></SectionTooltip>
                                                        <button className="pointer refreshBtn pull-right" onClick={() => refreshSections(3, 'downloads')}> {insightsLbls.refresh}</button>

                                                    </ContainerTooltip>
                                                    {guideAnalyticsDetailShowMeDownloadsChart.guideAnalyticsDetailShowMeDownloadsChartDataFetched ? (
                                                        errorCount.errorCount[8] === 0 ? (
                                                            guideAnalyticsDetailShowMeDownloadsChart.total === 0 ? (
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            ) : (
                                                                <div id="showMePyramid" className="col-lg-12 col-12 padding0">
                                                                    <div className="barsContainer">
                                                                        <div className="progress progress-bar-vertical p1">
                                                                            <div className="progress-bar" role="progressbar" aria-valuenow={100} aria-valuemin={0}
                                                                                aria-valuemax={100} style={{ height: guideAnalyticsDetailShowMeDownloadsChart.ppt }}>
                                                                                <small className="justify-content-center d-flex position-absolute">{guideAnalyticsDetailShowMeDownloadsChart.pptT}</small>
                                                                            </div>
                                                                            <div className="barLegend-text textCenter widht32 textUpperCase">{insightsLbls !== undefined ? insightsLbls['downloadPptx'] : ''}</div>
                                                                        </div>
                                                                        <div className="progress progress-bar-vertical p2">
                                                                            <div className="progress-bar" role="progressbar" aria-valuenow={100} aria-valuemin={0}
                                                                                aria-valuemax={100} style={{ height: guideAnalyticsDetailShowMeDownloadsChart.video }}>
                                                                                <small className="justify-content-center d-flex position-absolute">{guideAnalyticsDetailShowMeDownloadsChart.videoT}</small>
                                                                            </div>
                                                                            <div className="barLegend-text textCenter textUpperCase">{insightsLbls !== undefined ? insightsLbls['video'] : ''}</div>
                                                                        </div>
                                                                        <div className="progress progress-bar-vertical p3">
                                                                            <div className="progress-bar" role="progressbar" aria-valuenow={100} aria-valuemin={0}
                                                                                aria-valuemax={100} style={{ height: guideAnalyticsDetailShowMeDownloadsChart.gif }} >
                                                                                <small className="justify-content-center d-flex position-absolute">{guideAnalyticsDetailShowMeDownloadsChart.gifT}</small>
                                                                            </div>
                                                                            <div className="barLegend-text textCenter widht30 textUpperCase">{insightsLbls !== undefined ? insightsLbls['gif'] : ''}</div>
                                                                        </div>
                                                                        <div className="progress progress-bar-vertical p4">
                                                                            <div className="progress-bar" role="progressbar" aria-valuenow={100} aria-valuemin={0}
                                                                                aria-valuemax={100} style={{ height: guideAnalyticsDetailShowMeDownloadsChart.dPdf }} >
                                                                                <small className="justify-content-center d-flex position-absolute">{guideAnalyticsDetailShowMeDownloadsChart.dPdfT}</small>
                                                                            </div>
                                                                            <div className="barLegend-text textCenter width30 textUpperCase">{insightsLbls !== undefined ? insightsLbls['pdf'] : ''}</div>
                                                                        </div>
                                                                        <div className="progress progress-bar-vertical p5">
                                                                            <div className="progress-bar" role="progressbar" aria-valuenow={100} aria-valuemin={0}
                                                                                aria-valuemax={100} style={{ height: guideAnalyticsDetailShowMeDownloadsChart.docx }} >
                                                                                <small className="justify-content-center d-flex position-absolute">{guideAnalyticsDetailShowMeDownloadsChart.docxT}</small>
                                                                            </div>
                                                                            <div className="barLegend-text textCenter textUpperCase">{insightsLbls !== undefined ? insightsLbls['docx'] : ''}</div>
                                                                        </div>
                                                                        <div className="progress progress-bar-vertical p6">
                                                                            <div className="progress-bar" role="progressbar" aria-valuenow={100} aria-valuemin={0}
                                                                                aria-valuemax={100} style={{ height: guideAnalyticsDetailShowMeDownloadsChart.html }}>
                                                                                <small className="justify-content-center d-flex position-absolute">{guideAnalyticsDetailShowMeDownloadsChart.htmlT}</small>
                                                                            </div>
                                                                            <div className="barLegend-text textCenter textUpperCase">{insightsLbls !== undefined ? insightsLbls['html'] : ''}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        ) : (
                                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                {guideAnalyticsDetailShowMeDownloadsChart.errorMsg}
                                                            </h5>
                                                        )
                                                    ) : (
                                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight430">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <Loader></Loader>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>


                                            </CardContainer>
                                        </div>

                                        <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0 marginTop-20"}
                                            div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderBottom borderTop height100-percent"}
                                            div3Classes={"col-sm-12  minHeight430 height100-percent row marginLR-0"}>

                                            <div className="col-sm-12 paddingTop-20 overflowX chartdiv search_section minHeight430 section-wrapper">
                                                <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                    <SectionTooltip tooltipText={
                                                        {
                                                            "subTitle": insightsLbls.showMePlaySources,
                                                            "tooltipText": insightsLbls.pieChartShowMeModes,
                                                            "childern": []
                                                        }
                                                    } placement="right"></SectionTooltip>
                                                    <button className="pointer refreshBtn pull-right" onClick={() => refreshSections(3, 'pieChart')}> {insightsLbls.refresh}</button>
                                                </ContainerTooltip>
                                                <div className="minHeight400">
                                                    {guideAnalyticsDetailShowMeSourcesChart.guideAnalyticsDetailShowMeSourcesChartDataFetched ? (
                                                        errorCount.errorCount[9] === 0 ? (
                                                            !guideAnalyticsDetailShowMeSourcesChart.showMeSourcesPieDataAvailable ? (
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            ) : (
                                                                <Piechart
                                                                    chartData={getPieData('showme')}
                                                                    chartEntities={['showmePlayedViaBeacon', 'showmePlayedViaPush', 'showmePlayedViaPanel', 'showmePlayedViaUrl']}
                                                                    optionEntities={[
                                                                        { key: insightsLbls !== undefined ? insightsLbls['beacons'] : '', color: ChartColors.color1 },
                                                                        { key: insightsLbls !== undefined ? insightsLbls['pushNotifications'] : '', color: ChartColors.color2 },
                                                                        { key: insightsLbls !== undefined ? insightsLbls['panel'] : '', color: ChartColors.color3 },
                                                                        { key: insightsLbls !== undefined ? insightsLbls['url'] : '', color: ChartColors.color4 }
                                                                    ]}
                                                                    valueFormat={'value'}
                                                                    height={300}
                                                                    width={400}
                                                                    showLegends={true}
                                                                    chartType={'pie'}
                                                                    labelType={'percent'}
                                                                    pieMargin={{
                                                                        top: 60
                                                                    }}>
                                                                </Piechart>
                                                            )
                                                        ) : (
                                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                {guideAnalyticsDetailShowMeSourcesChart.errorMsg}
                                                            </h5>
                                                        )
                                                    ) : (
                                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative height535">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <Loader></Loader>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                        </CardContainer>

                                        {/* TESTME SEC */}
                                        {
                                            // <SectionRefresh sectionTitle={insightsLbls.testMe} refreshClass={"marginTop-30"}>
                                            //     <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls['refresh']} onClick={() => refreshSections(4)}></i>
                                            // </SectionRefresh>
                                        }
                                        <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0 marginTop-20"}
                                            div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                                            div3Classes={"col-sm-12 height535 height100-percent padding15"}>
                                            <ContainerTooltip div1Classes={""} div3Classes={""} hTagClasses={"section-heading headingText"}>
                                                <SectionTooltip tooltipText={
                                                    {
                                                        "subTitle": insightsLbls.testMe,
                                                        "tooltipText": insightsLbls.barGraphTestMe,
                                                        "childern": []
                                                    }
                                                } placement="right"></SectionTooltip>
                                                <button className="pointer refreshBtn pull-right" onClick={() => refreshSections(4)}> {insightsLbls.refresh}</button>

                                            </ContainerTooltip>
                                            <div className="col-sm-12 paddingTop-20 overflowX chartdiv search_section">
                                                {guideAnalyticsDetailTestMeChart.guideAnalyticsDetailTestMeChartDataFetched ? (
                                                    errorCount.errorCount[11] === 0 ? (
                                                        guideAnalyticsDetailTestMeChart.loadTestMeChartData.length === 0 ? (
                                                            <div className="noDataText-font textCenter-absolute">
                                                                {insightsLbls.noRecordFound}
                                                            </div>
                                                        ) : (
                                                            <BarChart
                                                                chartEntities={['test_passed', 'test_failed']}
                                                                optionEntities={
                                                                    [
                                                                        { key: insightsLbls !== undefined ? insightsLbls['pass'] : '', color: ChartColors.lightgreen, area: true },
                                                                        { key: insightsLbls !== undefined ? insightsLbls['fail'] : '', color: ChartColors.brightred, area: true }
                                                                    ]}
                                                                chartData={fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailTestMeChartData?.data}
                                                                value_type={"count"}
                                                                height={linechart_height}
                                                                margin={lineChartMargin}
                                                                yAxisLabel={insightsLbls['yAxisLabelCount']}
                                                                refId="guideAnalyticsDetail_testme"
                                                                parseIntFlag={true}>
                                                            </BarChart>
                                                        )
                                                    ) : (
                                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                                            {guideAnalyticsDetailTestMeChart.errorMsg}
                                                        </h5>
                                                    )
                                                ) : (
                                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative height535">
                                                        <div className="displayFlex alignCenter margin-0-auto">
                                                            <Loader></Loader>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </CardContainer>



                                        {
                                            // <SectionRefresh sectionTitle={insightsLbls.doItForMe} refreshClass={"marginTop-30"}>
                                            //     <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls['refresh']} onClick={() => refreshSections(5)}></i>
                                            // </SectionRefresh>
                                        }

                                        <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0 marginTop-20"}
                                            div2Classes={"card marginTop-0 section-wrapper icon-text borderBottom borderTop height100-percent"}
                                            div3Classes={"col-sm-12 height535 height100-percent padding15"}>
                                            <ContainerTooltip div1Classes={""} div3Classes={""} hTagClasses={"section-heading headingText"}>
                                                <SectionTooltip tooltipText={
                                                    {
                                                        "subTitle": insightsLbls.doItForMe + ' ' + insightsLbls.guidePlayedStats,
                                                        "tooltipText": insightsLbls.guidesPlayedViaDoItForMe,
                                                        "childern": [
                                                            {
                                                                "key": insightsLbls.totalPlayed + ":",
                                                                "value": insightsLbls.totalNumberOfGuidesPlayed
                                                            },
                                                            {
                                                                "key": insightsLbls.uniquePlayed + ":",
                                                                "value": insightsLbls.totalNumberOfUniqueGuidesPlayed
                                                            },
                                                            {
                                                                "key": insightsLbls.completed + ":",
                                                                "value": insightsLbls.totalNumberOfCompleteGuidesPlayed
                                                            }
                                                        ]
                                                    }
                                                } placement="right"></SectionTooltip>
                                                <button className="pointer refreshBtn pull-right" onClick={() => refreshSections(5, 'guideChart')}> {insightsLbls.refresh}</button>

                                            </ContainerTooltip>
                                            <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                                                {guideAnalyticsDetailDoItForMeChart.guideAnalyticsDoItForMeChartDataFetched ? (
                                                    errorCount.errorCount[12] === 0 ? (
                                                        guideAnalyticsDetailDoItForMeChart.loadDoItForMeChartData.length === 0 ? (
                                                            <div className="noDataText-font textCenter-absolute">
                                                                {insightsLbls.noRecordFound}
                                                            </div>
                                                        ) : (<LineChart
                                                            chartEntities={['do_it_for_me_play', 'do_it_for_me_unique_play', 'do_it_for_me_complete']}
                                                            optionEntities={[
                                                                { key: insightsLbls !== undefined ? insightsLbls['totalPlayed'] : '', color: ChartColors.color1, area: true },
                                                                { key: insightsLbls !== undefined ? insightsLbls['uniquePlayed'] : '', color: ChartColors.color2, area: true },
                                                                { key: insightsLbls !== undefined ? insightsLbls['completed'] : '', color: ChartColors.color3, area: true }
                                                            ]}
                                                            chartData={fetched_details?.guideAnalyticsDetail?.guideAnalyticsDetailDoItForMeChartData?.data}
                                                            value_type={"count"}
                                                            height={linechart_height}
                                                            margin={lineChartMargin}
                                                            yAxisLabel={insightsLbls['yAxisLabelCount']}
                                                            refId="guideAnalyticsDetail_doitforme">
                                                        </LineChart>
                                                        )
                                                    ) : (
                                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                                            {guideAnalyticsDetailDoItForMeChart.errorMsg}
                                                        </h5>
                                                    )
                                                ) : (
                                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative height535">
                                                        <div className="displayFlex alignCenter margin-0-auto">
                                                            <Loader></Loader>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </CardContainer>
                                        <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0 marginTop-20"}
                                            div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop  borderBottom height100-percent"}
                                            div3Classes={"col-sm-12  minHeight400 height100-percent padding15"}>
                                            <div className="col-sm-12 overflowX chartdiv search_section">
                                                <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                    <SectionTooltip tooltipText={
                                                        {
                                                            "subTitle": insightsLbls.doItForMeShowMeSources,
                                                            "tooltipText": insightsLbls.pieChartGuideSources,
                                                            "childern": []
                                                        }
                                                    } placement="right"></SectionTooltip>
                                                    <button className="pointer refreshBtn pull-right" onClick={() => refreshSections(5, 'sourcesChart')}> {insightsLbls.refresh}</button>

                                                </ContainerTooltip>
                                                <div className="minHeight400">
                                                    {guideAnalyticsDetailDoItForMeSourcesChart.guideAnalyticsDetailDoItForMeSourcesChartDataFetched ? (
                                                        errorCount.errorCount[9] === 0 ? (
                                                            !guideAnalyticsDetailDoItForMeSourcesChart.doItForMeSourcesPieDataAvailable ? (
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            ) : (
                                                                <Piechart
                                                                    chartData={getPieData('doItForMe')}
                                                                    chartEntities={['guidePlayedViaBeacon', 'guidePlayedViaPush', 'guidePlayedViaPanel', 'guidePlayedViaUrl']}
                                                                    optionEntities={[
                                                                        { key: insightsLbls !== undefined ? insightsLbls['beacons'] : '', color: ChartColors.color1 },
                                                                        { key: insightsLbls !== undefined ? insightsLbls['pushNotifications'] : '', color: ChartColors.color2 },
                                                                        { key: insightsLbls !== undefined ? insightsLbls['panel'] : '', color: ChartColors.color3 },
                                                                        { key: insightsLbls !== undefined ? insightsLbls['url'] : '', color: ChartColors.color4 }
                                                                    ]}
                                                                    valueFormat={'value'}
                                                                    height={300}
                                                                    width={400}
                                                                    showLegends={true}
                                                                    chartType={'pie'}
                                                                    labelType={'percent'}
                                                                    pieMargin={{
                                                                        top: 60
                                                                    }}>
                                                                </Piechart>
                                                            )
                                                        ) : (
                                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                {guideAnalyticsDetailDoItForMeSourcesChart.errorMsg}
                                                            </h5>
                                                        )
                                                    ) : (
                                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative height535">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <Loader></Loader>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </CardContainer>
                                        <CardContainer
                                            div1Classes={"col-sm-12 col-lg-12 col-md-12  padding0 marginTop-20"}
                                            div2Classes={"card icon-text section-wrapper marginTop-0 chart-wrapper1 borderTop col-12 paddingLR-0"}
                                            div3Classes={"card-body height535  overflowY-hidden"}>
                                            <div className="col-sm-12  overflowX chartdiv search_section">
                                                <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                    <SectionTooltip tooltipText={
                                                        {
                                                            "subTitle": insightsLbls.detailedSummary,
                                                            "tooltipText": insightsLbls.doItForMeTableActivities,
                                                            "childern": [
                                                                {
                                                                    "key": insightsLbls.stepTitle + ":",
                                                                    "value": insightsLbls.titleOfStep
                                                                },
                                                                {
                                                                    "key": insightsLbls.stepPlayedCount + ":",
                                                                    "value": insightsLbls.numberOfTimesStepPlayed
                                                                },
                                                                {
                                                                    "key": insightsLbls.humanInterventionCount + ":",
                                                                    "value": insightsLbls.humanInterventionCountTimes
                                                                },
                                                                {
                                                                    "key": insightsLbls.skippedStepCount + ":",
                                                                    "value": insightsLbls.skippedStepCountTimes
                                                                },
                                                                {
                                                                    "key": insightsLbls.failedOnStep + ":",
                                                                    "value": insightsLbls.failedOnStepTimes
                                                                }
                                                            ]
                                                        }
                                                    } placement="right"></SectionTooltip>
                                                    <button className="pointer refreshBtn pull-right" onClick={() => refreshSections(5, 'table')}> {insightsLbls.refresh}</button>

                                                </ContainerTooltip>
                                                <div className="col-sm-12 paddingTop-20 overflowX chartdiv search_section">
                                                    {
                                                        guideAnalyticsDetailDoItForMeSummaryTable.guideAnalyticsDetailSummaryDoItForMeTableDataFetched ? (
                                                            errorCount.errorCount[13] === 0 ? (
                                                                <div className="commonSummaryTable">
                                                                    <CommonTable tableId="doItForMeDetailTable"
                                                                        data={fetched_details.guideAnalyticsDetail.guideAnalyticsDetailDoItForMeTableData.data}
                                                                        currentPage={guideAnalyticsDetailDoItForMeSummaryTable.page}
                                                                        barColors=""
                                                                        linkPresent={false}
                                                                        showExport={true}
                                                                        showSearch={false}
                                                                        showEntries={true}
                                                                        statusFailAction={'doitforme'}
                                                                        defaultSort={guideAnalyticsDetailDoItForMeSummaryTable.defaultSort}
                                                                        tablePagination={guideAnalyticsDetailDoItForMeSummaryTable.tablePagination}
                                                                        tableEvent={handleDoItForMeTableEvents}
                                                                        firstTimeloadFlag={fetched_details.guideAnalyticsDetail.guideAnalyticsDetailDoItForMeTableData.firstTimeload}
                                                                        modalTable={false}
                                                                    />
                                                                </div>
                                                            )
                                                                : errorCount.errorCount[13] === 1 ? (
                                                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                        {guideAnalyticsDetailDoItForMeSummaryTable.errorMsg}
                                                                    </h5>
                                                                )
                                                                    : null)
                                                            : (
                                                                <div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
                                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                                        <Loader></Loader>
                                                                    </div>
                                                                </div>
                                                            )
                                                    }
                                                </div>
                                            </div>
                                        </CardContainer>

                                        {
                                            // <SectionRefresh sectionTitle={insightsLbls.myGuideSurveys} refreshClass={"marginTop-30"}>
                                            //     <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls['refresh']} onClick={() => refreshSections(6)}></i>
                                            // </SectionRefresh>
                                        }

                                        {/* <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant"> */}
                                        <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0 marginTop-20"}
                                            div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                                            div3Classes={"col-sm-12 padding15 height100-percent row marginLR-0"}>
                                            <div className="col-sm-12 col-md-12 col-lg-12 displayBlock height50">
                                                <button className="pointer refreshBtn marginRight-15 pull-right" onClick={() => refreshSections(6)}> {insightsLbls.refresh}</button>
                                            </div>
                                            <div className="col-sm-9 overflowX chartdiv search_section height535 section-wrapper-right">
                                                <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                    <SectionTooltip tooltipText={
                                                        {
                                                            "subTitle": insightsLbls.guideExitStepSurveys,
                                                            "tooltipText": insightsLbls.totalNuberOfGuideAndExitResponses,
                                                            "childern": []
                                                        }
                                                    } placement="right"></SectionTooltip>

                                                </ContainerTooltip>

                                                {guideAnalyticsDetailSurveyChart.guideAnalyticsDetailSurveyChartDataFetched ? (
                                                    errorCount.errorCount[14] === 0 ? (
                                                        guideAnalyticsDetailSurveyChart.loadSurveyChartData.length === 0 ? (
                                                            <div className="noDataText-font textCenter-absolute">
                                                                {insightsLbls.noRecordFound}
                                                            </div>
                                                        ) : (<LineChart
                                                            chartEntities={['guideSurveyResponse', 'exitSurveyResponse', 'guideStepSurveyResponse']}
                                                            optionEntities={[
                                                                { key: insightsLbls !== undefined ? insightsLbls['guideSurvey'] : '', color: ChartColors.color1, area: true },
                                                                { key: insightsLbls !== undefined ? insightsLbls['exitGuideSurvey'] : '', color: ChartColors.color2, area: true },
                                                                { key: insightsLbls !== undefined ? insightsLbls['guideStepSurvey'] : '', color: ChartColors.color3, area: true }
                                                            ]}
                                                            chartData={fetched_details.guideAnalyticsDetail?.guideAnalyticsDetailSurveyChartData?.data?.surveyDetails}
                                                            value_type={"count"}
                                                            height={linechart_height}
                                                            margin={lineChartMargin}
                                                            yAxisLabel={insightsLbls['yAxisLabelCount']}
                                                            refId="guideAnalyticsDetail_survey">
                                                        </LineChart>
                                                        )
                                                    ) : (
                                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                                            {guideAnalyticsDetailSurveyChart.errorMsg}
                                                        </h5>
                                                    )
                                                ) : (
                                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative height535">
                                                        <div className="displayFlex alignCenter margin-0-auto">
                                                            <Loader></Loader>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>


                                            <div className="col-sm-3 paddingTop-20 overflowX chartdiv search_section height535 section-wrapper-left marginTop-20">
                                                <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                    <SectionTooltip tooltipText={
                                                        {
                                                            "subTitle": insightsLbls.responseDistribution,
                                                            "tooltipText": insightsLbls.totalNuberOfGuideAndExitResponses,
                                                            "childern": []
                                                        }
                                                    } placement="left"></SectionTooltip>

                                                </ContainerTooltip>

                                                {guideAnalyticsDetailSurveyChart.guideAnalyticsDetailSurveyChartDataFetched ? (
                                                    errorCount.errorCount[14] === 0 ? (
                                                        guideAnalyticsDetailSurveyChart.loadSurveyChartData.length === 0 ? (
                                                            <div className="noDataText-font textCenter-absolute">
                                                                {insightsLbls.noRecordFound}
                                                            </div>
                                                        ) : (
                                                            <div className="sentimentsCard">
                                                                <div className={"col-lg-12 pointer sentimentsRow guide" + `${guideAnalyticsDetailSurveyChart.guideSurveyResponse === 0 ? ' opaque' : ''}`}
                                                                    onClick={(e) => {
                                                                        guideAnalyticsDetailSurveyChart.guideSurveyResponse !== 0
                                                                            ? surveyModal(guideAnalyticsDetailSurveyChart.guideSurveyResponse, 'guide_sentiment')
                                                                            : e.preventDefault()
                                                                    }}>
                                                                    <div className="marginBottom-0 displayContents">
                                                                        <span className="font20 font600">{guideAnalyticsDetailSurveyChart.guideSurveyResponse}</span>
                                                                        <br />
                                                                        <span className="sentimentDot guideSentiment"></span>
                                                                        {insightsLbls['guideSurvey']}
                                                                    </div>
                                                                </div>


                                                                <div className={`${checkDisable(guideAnalyticsDetailSurveyChart.exitSurveyResponse)} col-lg-12 pointer sentimentsRow exit`} onClick={(e) => {
                                                                    guideAnalyticsDetailSurveyChart.exitSurveyResponse !== 0
                                                                        ? surveyModal(guideAnalyticsDetailSurveyChart.exitSurveyResponse, 'guide_exit_sentiment')
                                                                        : e.preventDefault()
                                                                }}>
                                                                    <div className="marginBottom-0 displayContents">
                                                                        <span className="font20 font600">{guideAnalyticsDetailSurveyChart.exitSurveyResponse}</span>
                                                                        <br />
                                                                        <span className="sentimentDot exitSentiment"></span>
                                                                        {insightsLbls['exitGuideSurvey']}
                                                                    </div>
                                                                </div>

                                                                <div className={`${checkDisable(guideAnalyticsDetailSurveyChart.guideStepSurveyResponse)} col-lg-12 pointer sentimentsRow step`}
                                                                    onClick={(e) => {
                                                                        guideAnalyticsDetailSurveyChart.guideStepSurveyResponse !== 0
                                                                            ? surveyModal(guideAnalyticsDetailSurveyChart.guideStepSurveyResponse, 'guide_step_sentiment')
                                                                            : e.preventDefault()
                                                                    }}>
                                                                    <div className="marginBottom-0 displayContents">
                                                                        <span className="font20 font600">{guideAnalyticsDetailSurveyChart.guideStepSurveyResponse}</span>
                                                                        <br />
                                                                        <span className="sentimentDot stepSentiment"></span>
                                                                        {insightsLbls['guideStepSurvey']}
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-12 pointer viewRow" >
                                                                    <span className={`${checkDisable(guideAnalyticsDetailSurveyChart.guideStepSurveyResponse + guideAnalyticsDetailSurveyChart.guideSurveyResponse + guideAnalyticsDetailSurveyChart.exitSurveyResponse)} floatRight linkColor`}
                                                                        onClick={() => guideAnalyticsDetailSurveyChart.guideStepSurveyResponse + guideAnalyticsDetailSurveyChart.guideSurveyResponse + guideAnalyticsDetailSurveyChart.exitSurveyResponse === 0 ? closeGuideSurveyModal() : surveyModal(-1, 'view')}>{insightsLbls['view']} &#8594;</span>
                                                                </div>


                                                            </div>
                                                        )
                                                    ) : (
                                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                                            {guideAnalyticsDetailSurveyChart.errorMsg}
                                                        </h5>
                                                    )
                                                ) : (
                                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative height535">
                                                        <div className="displayFlex alignCenter margin-0-auto">
                                                            <Loader></Loader>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                        </CardContainer>

                                    </>

                                )}
                        </>
                    )
                }

            </div>


            <CommonModal
                modalState={linkedModal.modalOpen}
                dispatchModalState={closeModal}
                modalTitle={insightsLbls !== undefined ? insightsLbls['linkedTable'] : ''}
                size={'lg'}
                modalDialogClass={'linkedTable-modal'}>

                <>
                    <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">

                        {
                            guideAnalyticsDetailLinkedTable.guideAnalyticsDetailLinkedTableDataFetched ? (
                                errorCount.errorCount[4] === 0 ? (
                                    <div className=" commonSummaryTable linkedSummary-tableDiv">
                                        <CommonTable tableId="linkedTable"
                                            data={fetched_details.guideAnalyticsDetail.guideAnalyticsDetailLinkedTableData.data}
                                            currentPage={guideAnalyticsDetailLinkedTable.page}
                                            barColors=""
                                            showExport={true}
                                            showSearch={false}
                                            showEntries={true}
                                            defaultSort={guideAnalyticsDetailLinkedTable.defaultSort}
                                            tablePagination={guideAnalyticsDetailLinkedTable.tablePagination}
                                            tableEvent={handleLinkedTableEvents}
                                            firstTimeloadFlag={fetched_details.guideAnalyticsDetail.guideAnalyticsDetailLinkedTableData.firstTimeload}
                                            modalTable={true}
                                        />
                                    </div>
                                )
                                    : errorCount.errorCount[4] === 1 ? (
                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                            {guideAnalyticsDetailLinkedTable.errorMsg}
                                        </h5>
                                    )
                                        : null)
                                : (
                                    <div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader></Loader>
                                        </div>
                                    </div>
                                )
                        }
                    </div>
                </>

            </CommonModal>

            {userActivityModal.modalOpen &&
                <UserActivity
                    closeModal={closeUserActivityModal}
                    guideId={guideId}
                    guideName={urlData['source']}
                    source={'guide'}></UserActivity>
            }

            {guideSurveyModal.modalOpen
                ? <GuideSurvey
                    closeModal={closeGuideSurveyModal}
                    surveyCount={guideSurveyModal.count}
                    surveyType={guideSurveyModal.type}
                    guideId={guideId}></GuideSurvey>
                : null}

            <CommonModal
                modalState={errorModal.modalOpen}
                dispatchModalState={closeErrorDataModal}
                modalTitle={'Step Error Details'}
                size={'md'}
                modalDialogClass={''}>

                {errorModal.dataFetched ? (
                    errorCount.errorCount[15] === 0 ? (
                        errorModal.data.length === 0 ? (
                            <div className="noDataText-font textCenter-absolute">
                                {insightsLbls.noRecordFound}
                            </div>
                        ) : (
                            <div className="stepErrorDetails">
                                {errorModal.data.map((item: any) => {
                                    return <div key={item.playSequenceID} className="padding15 errorCard">
                                        <p className="font14 font600 marginBottom-0">{item.errorReason}</p>
                                        <p className="font14 marginBottom-0">{item.timestamp}</p>
                                    </div>
                                })}
                            </div>
                        )
                    ) : (
                        <h5 className="errorCss card-heading font-weight-400 text-center">
                            {errorModal.errorMsg}
                        </h5>
                    )
                ) : (
                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative height535">
                        <div className="displayFlex alignCenter margin-0-auto">
                            <Loader></Loader>
                        </div>
                    </div>
                )}

            </CommonModal>

            <div className="overlayCursor" id="overlayCursor"></div>
            {errorCount.showMaintenance_flag && (
                <Maintenance></Maintenance>
            )}



        </section>
    )
}

export default withRouter(withReducer("guideAnalyticsDetail", guideAnalyticsDetailReducer)(GuideAnalyticsDetail));