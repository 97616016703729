import * as actionTypes from '../../Actions/ActionTypes';

export interface ManageChatBotState {
    chatbotListData: any,
    chatbotData: any
}

const initialState = {
    chatbotListData: [],
    chatbotData: []
};


const manageChatBotReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SET_CHATBOT_LIST:
            return {
                ...state,
                chatbotListData: action.chatbotListData,
            };

        case actionTypes.SET_CHATBOT_DATA:
            return {
                ...state,
                chatbotData: action.chatbotData,
            };


        default:
            return state;
    }
};

export default manageChatBotReducer;