

import React, { useState, useEffect, useRef } from 'react';
import Select, { components } from 'react-select';
import { useSelector } from "react-redux";
import { LanguageState } from "store/Reducers";
import { useErrorBoundary } from 'react-error-boundary';

import './SearchDropdown.scss';
import { InsightLabels } from 'labels';

interface CurrentState {
    languageData: LanguageState
}

const SearchDropdown = (props: any) => {
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);
    let fetched_details = useSelector((state: CurrentState) => state);
    const isInitialMount = useRef(true);

    const [listData, setListData] = useState({
        dataFetched: false,
        data: [],
        placeholder: 'Select Guide'
    })

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
    }, [fetched_details.languageData.languageData])


    

    useEffect(() => {
        try {
            // to handle insightsLbls.allUsers undefined issue
            if(insightsLbls) {
                let list: any = [];

                if (props.list) {
                    const allUsers = { value: "all", label: insightsLbls.allUsers }
                    if (props.source === "searches") {
                        list.push(allUsers)
                    }

                    for (let data of props.list) {
                        if (props.source === "tooltipsDetail") {
                            list.push({ value: data['tooltipID'], label: data['tooltipName'], type: data['tooltipType'] })
                        }
                        else if (props.source === "userEngagementDetail") {
                            list.push({ value: data['guideID'], label: data['guideName'], isTutorialGuide: data['tutorialCount'] > 0 ? (true) : (false) })
                        }
                        else if (props.source === "searches") {
                            list.push({ value: data['searchedByUserEmail'], label: data['searchedByUserDisplayName'] + " ( " + data['searchedByUserEmail'] + " ) " })
                        }
                    }

                    setTimeout(() => {
                        if (list[0].label) {
                            setListData((prevState: any) => {
                                return {
                                    ...prevState,
                                    dataFetched: true,
                                    data: list,
                                    placeholder: list[0].label
                                }
                            })
                            props.dispatchStatus(list[0])
                        }
                    }, 1000);
                }
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [props.list, insightsLbls])

    useEffect(() => {

        setListData((prevState: any) => {
            return {
                ...prevState,
                placeholder: insightsLbls.selectGuide
            }
        })

    }, [])


    const handleInputChange = (guideId: any) => {
        props.dispatchStatus(guideId)
    }

    const { ValueContainer, Placeholder } = components;

    const CustomValueContainer = ({ children, ...props }: any) => {
        return (
            <ValueContainer {...props}>
                <Placeholder {...props}>
                    {props.selectProps.menuIsOpen ? props.selectProps.placeholder : ""}
                </Placeholder>
                {React.Children.map(children, child =>
                    child && child.type !== Placeholder && props.selectProps.menuIsOpen === false ? child : null
                )}
            </ValueContainer>
        );
    };

    const colourStyles = {
        placeholder: (styles: any) => {
            return {
                ...styles,
                paddingLeft: '5px',
                fontSize: '14px',
                fontColor: '#444444'
            };
        },
        input: (styles: any) => {
            return {
                ...styles,
                position: 'absolute',
                left: '13px',
                fontSize: '18px',
                fontColor: '#444444'
            };
        },
        valueContainer: (styles: any) => {
            return {
                ...styles,
                height: '40px',
                backgroundColor: 'transparent',
                border: 'none',
                fontSize: '18px',
                fontColor: '#444444',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                marginTop: '-2px'
            }
        },
        control: (styles: any) => {
            return {
                ...styles,
                backgroundColor: 'transparent',
                border: 'none!important',
                cursor: 'pointer'
            }
        },
        menu: (styles: any) => {
            return {
                ...styles,
                display: 'block',
                paddlingLeft: "10px",
                borderRadius: '4px',
                border: '1px solid #ced4da',
                color: '#454560',

            };
        },
        option: (styles: any) => {
            return {
                ...styles,
                fontSize: '14px',
                padding: '10px 10px',
                cursor: 'pointer',
                borderBottom: '1px solid #ced4da'
            };
        },
    }

    const IndicatorsContainer = (props: any) => {
        return (
            <div style={{ background: 'transparent' }} className="searchDiv">
                <components.IndicatorsContainer {...props}>
                    <i className="fa fa-search" aria-hidden="true"></i>
                </components.IndicatorsContainer>
            </div>
        );
    };
    return (
        <>
            {listData.dataFetched
                ? (<form className="searchForm" id="search_form">
                    <Select
                        components={{ SingleValue: CustomValueContainer, IndicatorsContainer }}
                        defaultValue={listData.data[0]}
                        inputId={props.source === "tooltipsDetail" ? listData.data['tooltipID'] : props.source === "userEngagementDetail" ? listData.data['guideID'] : listData.data['searchedByUserEmail']}
                        name="aria-live-color"
                        options={listData.data}
                        // insightsLbls.searchGuide
                        placeholder={props.source === "tooltipsDetail" ? insightsLbls.searchStep : props.source === "userEngagementDetail" ? insightsLbls.searchGuide : (insightsLbls.searchUser)}
                        styles={colourStyles}
                        onChange={handleInputChange}
                        isSearchable={true}
                        theme={theme => ({
                            ...theme,
                            borderRadius: 2,
                            border: '1px solid #454560',
                            colors: {
                                ...theme.colors,
                                primary25: '#e5e5ff',
                                primary: '#454560',
                            },
                        })}
                    />
                </form>)
                : null
            }
        </>
    );
}
export default SearchDropdown;