// import React, { useState, useEffect, useRef, useCallback } from "react";

// import insightsLabels from "utils/stringConstants/trans_utils";
// let insightsLbls:any = insightsLabels;

// export const QuestionsData =  {
//         "comment": [
//             {
//                 "id": "0",
//                 "q": insightsLbls.commentQnAnySuggestion
//             },
//             {
//                 "id": "1",
//                 "q": insightsLbls.commentQnImprove
//             },
//             {
//                 "id": "2",
//                 "q": insightsLbls.commentQnDescribe
//             }
//         ],
//         "multiselect": [
//             {
//                 "id": "0",
//                 "q": insightsLbls.multiselectFeaturesLiked,
//                 "options": [
//                     insightsLbls.guide,
//                     insightsLbls.downloadingOption,
//                     insightsLbls.tooltip
//                 ]
//             },
//             {
//                 "id": "1",
//                 "q": insightsLbls.multiselectFeaturesUsed,
//                 "options": [
//                     insightsLbls.guide,
//                     insightsLbls.downloadingOption,
//                     insightsLbls.tooltip
//                 ]
//             },
//             {
//                 "id": "2",
//                 "q": insightsLbls.multiselectPlatform,
//                 "options": [
//                     insightsLbls.desktop,
//                     insightsLbls.mobile,
//                     insightsLbls.tablet
//                 ]
//             }
//         ],
//         "range": [
//             {
//                 "id": "0",
//                 "q": insightsLbls.rangeSelect
//             },
//             {
//                 "id": "1",
//                 "q": insightsLbls.rangeProvide
//             }
//         ],
//         "rating": [
//             {
//                 "id": "0",
//                 "q": insightsLbls.rateUs
//             },
//             {
//                 "id": "1",
//                 "q": insightsLbls.satisfiedRate
//             }
//         ],
//         "singleselect": [
//             {
//                 "id": "0",
//                 "q": insightsLbls.singleselectLikeGuide,
//                 "options": [
//                     insightsLbls.btnYes,
//                     insightsLbls.btnNo,
//                     insightsLbls.neutral
//                 ]
//             },
//             {
//                 "id": "1",
//                 "q": insightsLbls.singleselectSuggestGuide,
//                 "options": [
//                     insightsLbls.btnYes,
//                     insightsLbls.btnNo,
//                     insightsLbls.mayBe
//                 ]
//             },
//             {
//                 "id": "2",
//                 "q": insightsLbls.singleselectAccomplishGuide,
//                 "options": [
//                     insightsLbls.btnYes,
//                     insightsLbls.btnNo,
//                     insightsLbls.mayBe
//                 ]
//             }
//         ],
//         "yesno": [
//             {
//                 "id": "0",
//                 "q":insightsLbls.yesNoLikeGuide
//             },
//             {
//                 "id": "1",
//                 "q": insightsLbls.yesNoSuggest
//             },
//             {
//                 "id": "2",
//                 "q": insightsLbls.yesNoAccomplish
//             }
//         ]
// }
    





// export const QuestionsDataSet = getSelectedLabels.then(insightsLbls => {
//     return {
//         "comment": [
//             {
//                 "id": "0",
//                 "q": insightsLbls.commentQnAnySuggestion
//             },
//             {
//                 "id": "1",
//                 "q": insightsLbls.commentQnImprove
//             },
//             {
//                 "id": "2",
//                 "q": insightsLbls.commentQnDescribe
//             }
//         ],
//         "multiselect": [
//             {
//                 "id": "0",
//                 "q": insightsLbls.multiselectFeaturesLiked,
//                 "options": [
//                     insightsLbls.guide,
//                     insightsLbls.downloadingOption,
//                     insightsLbls.tooltip
//                 ]
//             },
//             {
//                 "id": "1",
//                 "q": insightsLbls.multiselectFeaturesUsed,
//                 "options": [
//                     insightsLbls.guide,
//                     insightsLbls.downloadingOption,
//                     insightsLbls.tooltip
//                 ]
//             },
//             {
//                 "id": "2",
//                 "q": insightsLbls.multiselectPlatform,
//                 "options": [
//                     insightsLbls.desktop,
//                     insightsLbls.mobile,
//                     insightsLbls.tablet
//                 ]
//             }
//         ],
//         "range": [
//             {
//                 "id": "0",
//                 "q": insightsLbls.rangeSelect
//             },
//             {
//                 "id": "1",
//                 "q": insightsLbls.rangeProvide
//             }
//         ],
//         "rating": [
//             {
//                 "id": "0",
//                 "q": insightsLbls.rateUs
//             },
//             {
//                 "id": "1",
//                 "q": insightsLbls.satisfiedRate
//             }
//         ],
//         "singleselect": [
//             {
//                 "id": "0",
//                 "q": insightsLbls.singleselectLikeGuide,
//                 "options": [
//                     insightsLbls.btnYes,
//                     insightsLbls.btnNo,
//                     insightsLbls.neutral
//                 ]
//             },
//             {
//                 "id": "1",
//                 "q": insightsLbls.singleselectSuggestGuide,
//                 "options": [
//                     insightsLbls.btnYes,
//                     insightsLbls.btnNo,
//                     insightsLbls.mayBe
//                 ]
//             },
//             {
//                 "id": "2",
//                 "q": insightsLbls.singleselectAccomplishGuide,
//                 "options": [
//                     insightsLbls.btnYes,
//                     insightsLbls.btnNo,
//                     insightsLbls.mayBe
//                 ]
//             }
//         ],
//         "yesno": [
//             {
//                 "id": "0",
//                 "q":insightsLbls.yesNoLikeGuide
//             },
//             {
//                 "id": "1",
//                 "q": insightsLbls.yesNoSuggest
//             },
//             {
//                 "id": "2",
//                 "q": insightsLbls.yesNoAccomplish
//             }
//         ]
//     }
// })



export default {};