import * as ActionTypes from '../ActionTypes';
import axiosInstance, { getInstance } from '../../../network/apis/interceptor';
import { CommonUtils } from '../../../utils/CommonUtils';
import { ApiRelativePaths, RetryApi } from '../../../utils/GlobalConstants';


let ApiPaths: any = ApiRelativePaths;


export const setGuideAnalyticsDetailGuideMeChart = (guideAnalyticsDetailGuideMeChartData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_GUIDEME_CHART_DATA,
        guideAnalyticsDetailGuideMeChartData: guideAnalyticsDetailGuideMeChartData
    }
};

export const setGuideAnalyticsDetailGuideMeSourcesChart = (guideAnalyticsDetailGuideMeSourcesChartData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_GUIDEME_SOURCES_CHART_DATA,
        guideAnalyticsDetailGuideMeSourcesChartData: guideAnalyticsDetailGuideMeSourcesChartData
    }
};

export const setGuideAnalyticsDetailSummaryTable = (guideAnalyticsDetailSummaryTableData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_SUMMARY_TABLE_DATA,
        guideAnalyticsDetailSummaryTableData: guideAnalyticsDetailSummaryTableData
    }
};

export const setGuideAnalyticsDetailStepsFlowChart = (guideAnalyticsDetailStepsFlowChartData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_STEPS_FLOW_CHART,
        guideAnalyticsDetailStepsFlowChartData: guideAnalyticsDetailStepsFlowChartData
    }
};

export const setGuideAnalyticsDetailDropoffFunnelChart = (guideAnalyticsDetailDropoffFunnelChartData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_DROPOFF_FUNNEL_CHART,
        guideAnalyticsDetailDropoffFunnelChartData: guideAnalyticsDetailDropoffFunnelChartData
    }
};

export const setGuideAnalyticsDetailFunnelStepsList = (guideAnalyticsDetailFunnelStepsList: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_FUNNEL_STEPS_LIST,
        guideAnalyticsDetailFunnelStepsList: guideAnalyticsDetailFunnelStepsList
    }
};

export const setGuideAnalyticsDetailShowMeChart = (guideAnalyticsDetailShowMeChartData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_SHOWME_CHART_DATA,
        guideAnalyticsDetailShowMeChartData: guideAnalyticsDetailShowMeChartData
    }
};

export const setGuideAnalyticsDetailShowMeDownloadsChart = (guideAnalyticsDetailShowMeDownloadsChartData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_SHOWME_DOWNLOADS_CHART_DATA,
        guideAnalyticsDetailShowMeDownloadsChartData: guideAnalyticsDetailShowMeDownloadsChartData
    }
};

export const setGuideAnalyticsDetailShowMeSourcesChart = (guideAnalyticsDetailShowMeSourcesChartData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_SHOWME_SOURCES_CHART_DATA,
        guideAnalyticsDetailShowMeSourcesChartData: guideAnalyticsDetailShowMeSourcesChartData
    }
};

export const setGuideAnalyticsDetailTestMeChart = (guideAnalyticsDetailTestMeChartData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_TESTME_CHART_DATA,
        guideAnalyticsDetailTestMeChartData: guideAnalyticsDetailTestMeChartData
    }
};

export const setGuideAnalyticsDetailDoItForMeChart = (guideAnalyticsDetailDoItForMeChartData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_DOITFORME_CHART_DATA,
        guideAnalyticsDetailDoItForMeChartData: guideAnalyticsDetailDoItForMeChartData
    }
};

export const setGuideAnalyticsDetailDoItForMeSummaryTable = (guideAnalyticsDetailDoItForMeTableData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_DOITFORME_TABLE_DATA,
        guideAnalyticsDetailDoItForMeTableData: guideAnalyticsDetailDoItForMeTableData
    }
};

export const setGuideAnalyticsDetailDoItForMeSourcesChart = (guideAnalyticsDetailDoItForMeSourcesChartData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_DOITFORME_SOURCES_CHART_DATA,
        guideAnalyticsDetailDoItForMeSourcesChartData: guideAnalyticsDetailDoItForMeSourcesChartData
    }
};

export const setGuideAnalyticsDetailSurveyChart = (guideAnalyticsDetailSurveyChartData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_SURVEY_CHART_DATA,
        guideAnalyticsDetailSurveyChartData: guideAnalyticsDetailSurveyChartData
    }
};

export const setGuideAnalyticsDetailSurveyModal = (guideAnalyticsDetailSurveyModalData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_SURVEY_MODAL,
        guideAnalyticsDetailSurveyModalData: guideAnalyticsDetailSurveyModalData
    }
};


export const setGuideAnalyticsDetailLinkedTable = (guideAnalyticsDetailLinkedTableData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_LINKED_TABLE_DATA,
        guideAnalyticsDetailLinkedTableData: guideAnalyticsDetailLinkedTableData
    }
};


export const setGuideUserActivity = (guideUserActivity: any) => {
    return {
        type: ActionTypes.SET_GUIDE_USER_ACTIVITY,
        guideUserActivity: guideUserActivity
    }
}

export const setGuideAnalyticsDetailShowMeStepsData = (guideAnalyticsDetailShowMeStepsData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_SHOWME_STEPS_DATA,
        guideAnalyticsDetailShowMeStepsData: guideAnalyticsDetailShowMeStepsData
    }
}

export const setGuideAnalyticsDetailAudioChart = (guideAnalyticsDetailAudioChart: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_AUDIO_CHART,
        guideAnalyticsDetailAudioChart: guideAnalyticsDetailAudioChart
    }
}

export const setGuideAnalyticsDetailTutorialGuidesChart = (guideAnalyticsDetailTutorialGuidesChartData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_TUTORIAL_GUIDES_CHART_DATA,
        guideAnalyticsDetailTutorialGuidesChartData: guideAnalyticsDetailTutorialGuidesChartData
    }
};


export const setGuideAnalyticsDetailErrorData = (guideAnalyticsDetailErrorData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_ERROR_DETAILS,
        guideAnalyticsDetailErrorData: guideAnalyticsDetailErrorData
    }
};

export const getGuideAnalyticsDetailData = (params: any, url_path: any, caseType: any, apiErrorFlag: string, firstTimeload?: boolean, optionalData?: any) => {
    return new Promise((resolve, reject) => {
        let path = ApiPaths[url_path];
        let data:any = {};
        let paramObj = {};
        // let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
        let urlData;

        if (optionalData !== '' && optionalData !== undefined) {
            urlData = urlData = CommonUtils.URLGenerator(params, path + "/" + optionalData, paramObj);
        } else {
            urlData = CommonUtils.URLGenerator(params, path, paramObj);
        }

        getInstance('guide-insights/guide-analytics-detail/');

        axiosInstance.get(urlData)
            .then(res => {
                data = {
                    "result": "success",
                    "data": res.data.data,
                    "firstTimeload": firstTimeload ?? false,
                };
            })
            .catch(err => {
                if (apiErrorFlag === '') {
                    data = {
                        "result": "retry",
                        "data": err,
                        "firstTimeload": firstTimeload ?? false,
                    };
                } else {
                    data = {
                        "result": "error",
                        "data": err,
                        "errorCode":err?.response?.status ?? '',
                        "firstTimeload": firstTimeload ?? false,
                    };
                }
            })
            .then(() => {
                const callActionDispatch = () => {
                    switch (caseType) {
                        case ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_GUIDEME_CHART_DATA:
                            resolve(setGuideAnalyticsDetailGuideMeChart(data));
                            break;

                        case ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_GUIDEME_SOURCES_CHART_DATA:
                            resolve(setGuideAnalyticsDetailGuideMeSourcesChart(data));
                            break;

                        case ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_SUMMARY_TABLE_DATA:
                            resolve(setGuideAnalyticsDetailSummaryTable(data));
                            break;

                        case ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_STEPS_FLOW_CHART:
                            resolve(setGuideAnalyticsDetailStepsFlowChart(data));
                            break;

                        case ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_DROPOFF_FUNNEL_CHART:
                            resolve(setGuideAnalyticsDetailDropoffFunnelChart(data));
                            break;

                        case ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_FUNNEL_STEPS_LIST:
                            resolve(setGuideAnalyticsDetailFunnelStepsList(data));
                            break;

                        case ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_SHOWME_CHART_DATA:
                            resolve(setGuideAnalyticsDetailShowMeChart(data));
                            break;

                        case ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_SHOWME_DOWNLOADS_CHART_DATA:
                            resolve(setGuideAnalyticsDetailShowMeDownloadsChart(data));
                            break;

                        case ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_TESTME_CHART_DATA:
                            resolve(setGuideAnalyticsDetailTestMeChart(data));
                            break;

                        case ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_DOITFORME_CHART_DATA:
                            resolve(setGuideAnalyticsDetailDoItForMeChart(data));
                            break;

                        case ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_DOITFORME_TABLE_DATA:
                            resolve(setGuideAnalyticsDetailDoItForMeSummaryTable(data));
                            break;
                        
                        case ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_DOITFORME_SOURCES_CHART_DATA:
                            resolve(setGuideAnalyticsDetailDoItForMeSourcesChart(data));
                            break;

                        case ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_SURVEY_CHART_DATA:
                            resolve(setGuideAnalyticsDetailSurveyChart(data));
                            break;

                        case ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_SURVEY_MODAL:
                            resolve(setGuideAnalyticsDetailSurveyModal(data));
                            break;

                        case ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_LINKED_TABLE_DATA:
                            resolve(setGuideAnalyticsDetailLinkedTable(data));
                            break;

                        case ActionTypes.SET_GUIDE_USER_ACTIVITY:
                            resolve(setGuideUserActivity(data));
                            break;

                        case ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_SHOWME_SOURCES_CHART_DATA:
                            resolve(setGuideAnalyticsDetailShowMeSourcesChart(data));
                            break;

                        case ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_SHOWME_STEPS_DATA:
                            resolve(setGuideAnalyticsDetailShowMeStepsData(data));
                            break;

                        case ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_AUDIO_CHART:
                            resolve(setGuideAnalyticsDetailAudioChart(data));
                            break;
                        
                        case ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_TUTORIAL_GUIDES_CHART_DATA:
                            resolve(setGuideAnalyticsDetailTutorialGuidesChart(data));
                            break;

                        case ActionTypes.SET_GUIDE_ANALYTICS_ERROR_DETAILS:
                            resolve(setGuideAnalyticsDetailErrorData(data));
                            break;

                        default:
                            break;
                    }  
                }

                if (data.result === 'retry') {
                    setTimeout(() => {
                        callActionDispatch();
                    }, RetryApi.TIMEOUT);
                } else {
                    callActionDispatch();
                }
            });
    });
}