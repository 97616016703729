import  withRouter  from "hoc/withRouter";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { LanguageState } from "store/Reducers";

//SCSS
import "./NotFound.scss";





import { InsightLabels } from "labels";

interface CurrentState {
    languageData: LanguageState
}

const NotFound = (props: any) => {
    let fetched_details = useSelector((state: CurrentState) => state);

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)

    useEffect(() => {
        if(fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
     
    },[fetched_details.languageData.languageData])
    
    const goToHome = () => {
        let app_code = localStorage.getItem('switch_app_code');
        let app_id = localStorage.getItem('switch_app_id');
        if (localStorage.getItem('GUIDEINSIGHTS_VISIBILITY') === 'true' || localStorage.getItem('APPINSIGHTS_VISIBILITY') === 'true') {
            let urlPath = process.env.REACT_APP_HOST;
            window.open('https://'+urlPath + "#/dashboard", "_self");
        } else if (localStorage.getItem('GUIDEINSIGHTS_VISIBILITY') === 'false' && localStorage.getItem('APPINSIGHTS_VISIBILITY') === 'false') {
            let urlPath = process.env.REACT_APP_HOST;
            window.open(urlPath + "#/survey/manage-survey?app_code=" + app_code + "&app_id=" + app_id, "_self")
        } else if (localStorage.getItem('MYBOT_VISIBILITY') === 'true') {
            let urlPath = process.env.REACT_APP_HOST;
            window.open(urlPath + "#/automation-insights/guide-automation?app_code=" + app_code + "&app_id=" + app_id, "_self")
        } 
        // else if (localStorage.getItem('QAAUTOMATOR_VISIBILITY') === 'true') {
        //     let urlPath = process.env.REACT_APP_HOST;
        //     window.open(urlPath + "#/automation-insights/qa-automator?app_code=" + app_code + "&app_id=" + app_id, "_self")
        // } 
        else if (localStorage.getItem('SUPER_ADMIN') === 'true') {
            let urlPath = process.env.REACT_APP_HOST;
            window.open(urlPath + "#/organization/org-view", "_self")
        } else {
            let urlPath = process.env.REACT_APP_HOST;
            window.open(urlPath, "_self")
        }
    }

    return (
        <section className="mainSection">
            <div className="col-lg-12 errorImg">
                <img
                    src={
                        require("../../assets/images/error_page.png")
                            .default
                    }
                />
                <p>{insightsLbls['pageNotFound']}</p>
                <button className="btn btn-fla modal-save-btn loginBtn" onClick={() => goToHome()}>{insightsLbls['goToHome']}</button>
            </div>
        </section>
    )
}

export default withRouter(NotFound);