import * as actionTypes from '../../Actions/ActionTypes';

export interface TutorialGuidesState {
    guideAnalyticsTutorialGuidesChartData: any,
    guideAnalyticsSummaryTableData: any,
}

const initialState = {
    guideAnalyticsTutorialGuidesChartData: [],
    guideAnalyticsSummaryTableData: [],
};


const tutorialGuidesReducer = (state = initialState, action:any) => {
    switch (action.type) {
        case actionTypes.SET_GUIDE_ANALYTICS_TUTORIAL_GUIDES_CHART_DATA:
            return {
            ...state,
            guideAnalyticsTutorialGuidesChartData: action.guideAnalyticsTutorialGuidesChartData,
        };

        case actionTypes.SET_GUIDE_TUTORIAL_SUMMARY_TABLE_DATA:
            return {
                ...state,
                guideAnalyticsSummaryTableData: action.guideAnalyticsSummaryTableData,
            };

        default:
            return state;
    }
};

export default tutorialGuidesReducer;