import * as actionTypes from '../../Actions/ActionTypes';

export interface ManageSurveysState {
    surveyListData: any,
    surveyData: any
}

const initialState = {
    surveyListData: [],
    surveyData: []
};


const manageSurveysReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SET_SURVEY_LIST_DATA:
            return {
                ...state,
                surveyListData: action.surveyListData,
            };

        case actionTypes.SET_SURVEY_DATA:
            return {
                ...state,
                surveyData: action.surveyData,
            };


        default:
            return state;
    }
};

export default manageSurveysReducer;