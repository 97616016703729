import React from "react";
interface IconProps {
    img: string;
    className?: string;
    onClick?: (event?: React.MouseEvent<HTMLImageElement>) => void;
    widthSet?: boolean;
}
const Icon = ({ img, className, onClick= () => {}, widthSet = false }: IconProps) => {
    return <img src={require("../../assets/"+img)} width={widthSet?"22":""} className= {className} onClick={(event) => onClick(event)}/>
}

export default Icon;