import React, { useRef, memo } from "react";
import NVD3Chart from "react-nvd3";
import { CommonUtils } from "utils/CommonUtils";

import { useErrorBoundary } from 'react-error-boundary';

import * as d3 from 'd3';
import cloneDeep from 'lodash/cloneDeep';

const LinechartPageDetail = (props) => {
    const { showBoundary } = useErrorBoundary();
    let lineChartRef = [];
    lineChartRef[props.chartId] = useRef(null);

    let data = [];
    let xAxis_val = [];
    let YAxisLable;
    let XAxisLable;

    try {
        data = cloneDeep(props.options);
        data[0]["values"] = props.chartData;

        for (let i = 0; i < props.chartData.length; i++) {
            xAxis_val.push(props.chartData[i].x);
        }

        if (props.userType == 3) {
            YAxisLable = 'Duration';
        } else {
            YAxisLable = 'Count';
        }

        if (xAxis_val.length > 0) {
            if (xAxis_val[0] != null && xAxis_val[0].includes('|')) {
                XAxisLable = 'Time';
            } else {
                XAxisLable = 'Date';
            }
        }
    } catch (error) {
        showBoundary(error)
    }



    // if (xAxis_val.length > 0) {
    //     var dateStr = xAxis_val[0];
    //     var indexVal = dateStr.indexOf("-");
    //     var rotateDeg;
    //     var bottomVal;
    //     if (indexVal == -1) {
    //         rotateDeg = 0;
    //         bottomVal = 50;

    //     } else {
    //         // rotateDeg = -45;
    //         rotateDeg = 0;
    //         bottomVal = 60;
    //     }
    // }


    return (
        <div>
            <div name="wrapper" id="wrapper_div">
                <NVD3Chart
                    ref={lineChartRef[props.chartId]}

                    type="lineChart"

                    datum={data}

                    showValues={true}

                    wrapLabels={true}
                    useInteractiveGuideline={false}
                    clipEdge={false}

                    x={function (d, i) {
                        return i;
                    }}

                    y={function (d) { return d.y; }}

                    margin={
                        {
                            left: props.margin["left"],
                            right: props.margin["right"],
                            top: props.margin["top"],
                            bottom: props.margin["bottom"],
                        }
                    }


                    xAxis={{
                        axisLabelDistance: 10,
                        tickFormat: function (d) {
                            if (props.xAxis_vals) {
                                if (xAxis_val[d] !== undefined) {
                                    if (xAxis_val[d].includes('|')) {
                                        var el = d3.select(lineChartRef[props.chartId].current);
                                        var p = d3.select(el.parentNode);
                                        p.append("foreignObject")
                                            .attr('x', -25)
                                            .attr("width", 100)
                                            .attr("height", 200)
                                            .append("xhtml:p")
                                            .attr('style', 'word-wrap: break-word; text-align:center;')
                                            .html(`<div style="color:black; font-size: 12px">${xAxis_val[d].split('|')[1]}</div>`);

                                        el.remove();
                                    }

                                    if (xAxis_val[d].includes('-')) {
                                        if (this != null || this != undefined) {
                                            var el = d3.select(this);
                                            var p = d3.select(this.parentNode);

                                            p.append("foreignObject")
                                                .attr('x', -25)
                                                .attr("width", 100)
                                                .attr("height", 200)
                                                .append("xhtml:p")
                                                .attr('style', 'word-wrap: break-word; text-align:center;')
                                                .html(`<div style="color:black; font-size: 12px">${xAxis_val[d].split('-')[0]}<br>${xAxis_val[d].split('-')[1]}</div>`);

                                            el.remove();
                                        }

                                    } else {

                                        var el = d3.select(lineChartRef[props.chartId].current);
                                        var p = d3.select(el.parentNode);
                                        p.append("foreignObject")
                                            .attr('x', -25)
                                            .attr("width", 100)
                                            .attr("height", 200)
                                            .append("xhtml:p")
                                            .attr('style', 'word-wrap: break-word; text-align:center;')
                                            .html(`<div style="color:black; font-size: 12px">${xAxis_val[d]}</div>`);

                                        el.remove();
                                    }
                                    // return xAxis_val[d];
                                }
                            }

                        },
                    }}

                    yAxis={{


                        tickFormat: function (d) {
                            if (props.yAxis_vals) {
                                // return d;
                                if (props.userType == 3) {
                                    d = Number(d);
                                    var h = Math.floor(d / 3600);
                                    var m = Math.floor(d % 3600 / 60);
                                    var s = Math.floor(d % 3600 % 60);
                                    var hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
                                    var mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
                                    var sDisplay = s > 0 ? s + (s == 1 ? "s " : "s ") : "";
                                    return hDisplay + mDisplay + sDisplay;
                                    // return self.timeConverter.transform(d, true);
                                }
                                else {
                                    return (d);
                                }
                            }
                        },
                        axisLabelDistance: 2,
                        showMaxMin: true
                    }}

                    // width={props.width} 

                    height={props.height}

                    clipEdge={false}

                    tooltip={{
                        enabled: props.yAxis_vals,
                        hideDelay: 0,
                        contentGenerator: function (key, d) {
                            d3.selectAll('.nvtooltip').style('opacity', '0');
                            let timeStamp;
                            let string = key.point.xValue;
                            let substring = '|';
                            if (string !== undefined && string.includes(substring)) {
                                timeStamp = key.point.xValue.split('|')[1];
                            }
                            else {
                                timeStamp = (key.point.xValue);
                            }
                            var label;
                            if (props.userType == 3) {

                                var hr = Math.floor(key.point.y / 3600);
                                var min = Math.floor(key.point.y % 3600 / 60);
                                var sec = Math.floor(key.point.y % 3600 % 60);
                                var hDisplay = hr > 0 ? hr + (hr == 1 ? "h " : "h ") : "";
                                var mDisplay = min > 0 ? min + (min == 1 ? "m " : "m ") : "";
                                var sDisplay = sec > 0 ? sec + (sec == 1 ? "s " : "s ") : "";
                                // label = self.timeConverter.transform(key.point.yValue, true);
                                // // return label;
                                label = hDisplay + mDisplay + sDisplay;

                            } else {
                                label = key.point.y;
                            }
                            return `<div><b>${timeStamp}</b><br/><div style="height:10px; display:inline-block; margin-right:5px;width:10px; background-color:${key.point.color}"></div>User Activity <b>${label}</b></div>`;
                        }
                    }}
                />
            </div>
        </div>

    );
}
export default memo(LinechartPageDetail);