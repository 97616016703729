import React, { useEffect, useState, useReducer, useCallback } from "react";
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { BlockPicker } from 'react-color';
import withRouter from "hoc/withRouter";

import { useSelector } from "react-redux";
import { LanguageState } from "store/Reducers";


import $ from 'jquery';
import { useErrorBoundary } from 'react-error-boundary';

import "./Label.scss";
import { InsightLabels } from "labels";

interface CurrentState {
    languageData: LanguageState
}
const Label = (props: any) => {
    const { showBoundary } = useErrorBoundary();
    let fetched_details = useSelector((state: CurrentState) => state);
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);

    let menuShow: string = 'block';

    let options: any = [];

    const [labelVals, selectedLabelVals] = useState({
        data: [],
        flag: 0, //0:- ADD, 1:- REMOVE
        optionData: []
    });

    const [counter, setCounter] = useState({
        chip: 0,
    });

    const [selected_item, set_selected_item] = useState({
        item: '',
        item_index: 0,
        loadFlag: false
    });


    if (props.labeldata !== undefined) {
        try {
            options = []
            for (let i = 0; i < props.labeldata.length; i++) {
                options[i] = [];
                options[i].value = props.labeldata[i].chipName;
                options[i].label = props.labeldata[i].chipName;
                options[i].chipName = props.labeldata[i].chipName;
                options[i].chipColorCode = props.labeldata[i].chipColorCode;
                options[i].chipFontColorCode = props.labeldata[i].chipFontColorCode;
            }

            // selectedLabelVals((prevState:any) => {
            //     return {
            //         ...prevState,
            //         optionData: options
            //     }
            // })
        } catch (error) {
            showBoundary(error)
        }

    }

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        try {
            selectedLabelVals((prevState: any) => {
                return {
                    ...prevState,
                    data: []
                }
            })

            setTimeout(() => {
                if (props.selectedLabels !== undefined && props.selectedLabels !== null) {
                    let caloptn: any = [
                        ...props.selectedLabels
                    ]
                    for (let i = 0; i < props.selectedLabels.length; i++) {
                        caloptn[i] = {};
                        caloptn[i].value = props.selectedLabels[i].chipName;
                        caloptn[i].label = props.selectedLabels[i].chipName;
                        caloptn[i].chipName = props.selectedLabels[i].chipName;
                        caloptn[i].chipColorCode = props.selectedLabels[i].chipColorCode;
                        caloptn[i].chipFontColorCode = props.selectedLabels[i].chipFontColorCode;
                        caloptn[i].set_color = true;
                    }

                    selectedLabelVals((prevState: any) => {
                        return {
                            ...prevState,
                            data: caloptn
                        }
                    })

                    set_selected_item({
                        ...selected_item,
                        loadFlag: true
                    });

                } else {
                    set_selected_item({
                        ...selected_item,
                        loadFlag: true
                    });

                    props.getLabelData([]);

                }
            }, 0);
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.languageData.languageData])


    let i = 0;
    const colourStyles = {
        multiValue: (styles: any, data: any) => {
            return {
                ...styles,
                backgroundColor: (!data.data.hasOwnProperty('chipColorCode') || data.data.chipColorCode === undefined) ? '#d3d3d3' : data.data.chipColorCode,
                color: (!data.data.hasOwnProperty('chipFontColorCode') || data.data.chipFontColorCode === undefined) ? '#000000' : data.data.chipFontColorCode,
                fontSize: '14px',
                padding: '5px 8px',
                borderRadius: '15px',
            };
        },
        multiValueLabel: (styles: any, data: any) => {
            return {
                ...styles,
                backgroundColor: 'transparent',
                color: (!data.data.hasOwnProperty('chipFontColorCode') || data.data.chipFontColorCode === undefined) ? '#000000' : data.data.chipFontColorCode,
            };
        },
        menu: (styles: any) => {
            return {
                ...styles,
                display: 'block',
                paddlingLeft: "10px",
                width: '400px',
                zIndex: 2,
                poaition: 'relative'
            };
        },
        option: (styles: any, { data }: any) => {
            return {
                ...styles,
                fontSize: '14px',
                borderRadius: '15px',
                width: 'max-content',
                margin: '8px 10px',
                backgroundColor: (!data.hasOwnProperty('chipColorCode') || data.chipColorCode === undefined) ? '#d3d3d3' : data.chipColorCode,
                color: (!data.hasOwnProperty('chipFontColorCode') || data.chipFontColorCode === undefined) ? '#000000' : data.chipFontColorCode,

                // backgroundColor:  data.chipColorCode,
                // color: data.chipFontColorCode,
                padding: '5px 10px',
                cursor: 'pointer',
            };
        },
        placeholder: (styles: any) => {
            menuShow = 'block';
            return {
                ...styles,
                paddlingLeft: "10px",
                fontSize: '14px'
            };
        },
        container: (styles: any) => {
            return {
                ...styles,
                paddling: "10px",
            };
        },
    };



    const onChange = useCallback((selectedOptions: any) => {
        try {
            let added_data: any = [...selectedOptions];
            let dummyData: any = [...labelVals.data];

            // addition of chips
            if (labelVals.data.length < selectedOptions.length) {
                selectedLabelVals((prevState: any) => {
                    return {
                        ...prevState,
                        flag: 0
                    }
                })
                dummyData.push(added_data.splice(-1));
            } else {

                selectedLabelVals((prevState: any) => {
                    return {
                        ...prevState,
                        flag: 1
                    }
                })

                if (added_data.length > 0) {
                    dummyData = dummyData.filter((element: any) => {
                        for (let data of added_data) {
                            if (data['label'] === element['chipName']) {
                                return element
                            }
                        }
                    })
                } else {
                    dummyData = [];
                }

            }

            selectedLabelVals((prevState: any) => {
                return {
                    ...prevState,
                    data: dummyData,
                }
            })

            setCounter((prevState: any) => {
                return {
                    ...prevState,
                    chip: dummyData.length
                }
            })
        } catch (error) {
            showBoundary(error)
        }


    }, [labelVals.data])


    useEffect(() => {
        try {
            const lastElem_index = labelVals.data.length - 1;
            let lastItem: any = [];
            lastItem = labelVals.data[lastElem_index] ?? []
            let labeldata1: any = [...labelVals.data];

            if (labelVals.flag === 0) {
                if (lastItem[0]) {
                    if (lastItem[0].hasOwnProperty('chipColorCode') && lastItem[0].hasOwnProperty('chipFontColorCode')) {
                        labeldata1[lastElem_index] = {};
                        labeldata1[lastElem_index].chipName = lastItem[0].value;
                        labeldata1[lastElem_index].chipColorCode = lastItem[0].chipColorCode;
                        labeldata1[lastElem_index].chipFontColorCode = lastItem[0].chipFontColorCode;
                        labeldata1[lastElem_index].set_color = true;

                    } else {
                        labeldata1[lastElem_index] = {};
                        labeldata1[lastElem_index].chipName = lastItem[0].value;
                        labeldata1[lastElem_index].chipColorCode = '#d3d3d3';
                        labeldata1[lastElem_index].chipFontColorCode = '#000000';
                        labeldata1[lastElem_index].set_color = false;
                    }

                    selectedLabelVals((prevState: any) => {
                        return {
                            ...prevState,
                            data: labeldata1
                        }
                    })
                    props.getLabelData(labeldata1);
                }
            } else {
                props.getLabelData(labeldata1);
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [counter.chip, labelVals.data])



    const handleMultiValueClick = useCallback((e: any, props: any) => {
        try {
            let caloptn: any = [...labelVals.data]
            for (let i = 0; i < caloptn.length; i++) {

                if (caloptn[i]['set_color'] === false && caloptn[i]['chipName'] === e.target.innerText) {
                    toggleIsOn();

                    setCoordinates({
                        ...coordinates,
                        x_axis: (e.clientX - 320),
                        y_axis: (e.clientY - 80)
                    });

                    set_selected_item({
                        ...selected_item,
                        item: props.children,
                        item_index: i
                    });
                }
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [labelVals])



    const MultiValueLabel = (props: any) => {
        try {
            return (
                <div onClick={(e) => handleMultiValueClick(e, props)} id={props.children}>
                    <components.MultiValueLabel {...props} />
                </div>
            );
        } catch (error) {
            showBoundary(error)
        }

    }


    const [coordinates, setCoordinates] = useState({
        x_axis: 0,
        y_axis: 0,
    });

    const [selectedColor, setSelectedColor] = useState('#ccc');
    const [isOn, toggleIsOn] = useToggle();

    function useToggle(initialValue = false) {
        return useReducer((state) => !state, initialValue);
    }

    // let colorPicker = {
    //     top: coordinates.y_axis,
    //     left: coordinates.x_axis,
    //     position: "absolute",
    //     zIndex: 2
    // };

    const setColor = (color: any) => {
        try {
            setSelectedColor(color.hex);

            let caloptn: any = [...labelVals.data]

            caloptn[selected_item.item_index]['chipFontColorCode'] = '#494645';
            caloptn[selected_item.item_index]['chipColorCode'] = color.hex;


            selectedLabelVals((prevState: any) => {
                return {
                    ...prevState,
                    data: caloptn
                }
            })


            setTimeout(() => {
                $("#" + selected_item.item).css('background-color', color.hex);
                $("#" + selected_item.item).css('color', 'white');
                $("#" + selected_item.item).children().css('background-color', color.hex);
                $("#" + selected_item.item).parent().css('background-color', color.hex);
            }, 10);

            toggleIsOn();
        } catch (error) {
            showBoundary(error)
        }


    }

    const checkInputVal = (ev: any) => {
        try {
            if (ev.length > 50) {
                return ev.substring(0, 50);
            }
        } catch (error) {
            showBoundary(error)
        }

    }



    return (
        <div className="positonRelative">


            {isOn ? (
                <div style={{
                    top: coordinates.y_axis,
                    left: coordinates.x_axis,
                    position: "absolute",
                    zIndex: 2
                }}>
                    <BlockPicker
                        color={selectedColor}
                        onChangeComplete={setColor}
                    />
                </div>
            )
                : null}


            {selected_item.loadFlag ?
                (
                    <CreatableSelect
                        options={options}
                        isMulti={true}
                        defaultValue={labelVals.data}
                        styles={colourStyles}
                        defaultMenuIsOpen={false}
                        onChange={(ev) => onChange(ev)}
                        components={{ MultiValueLabel }}
                        inputId={"id"}
                        onInputChange={(ev) => checkInputVal(ev)}
                        placeholder={insightsLbls['select']}

                    />
                )
                : null}


        </div>
    );
}
export default React.memo(withRouter(Label));