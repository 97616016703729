import React, { useEffect, useState } from "react";
import NVD3Chart from "react-nvd3";


import * as d3 from "d3";
import { ChartColors } from "utils/GlobalConstants";
import { useSelector } from "react-redux";
import { useErrorBoundary } from 'react-error-boundary';



const HorizontalBarChart = (props) => {
    const { showBoundary } = useErrorBoundary();
    let fetched_details = useSelector((state) => state);

    let horizontalBarChartData = props.barChartData;

    const [insightsLbls, setInsightsLbls] = useState({})

    useEffect(() => {
        if (Object.keys(fetched_details.languageData.languageData).length) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details])

    const [chart, setChart] = useState({
        percentVal: 0
    })


    useEffect(() => {
        try {
            setChart((prevState) => {
                return {
                    ...prevState,
                    percentVal: horizontalBarChartData[0].values.reduce((a, b) => a = a > b.value ? a : b.value, 0)
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }, [props.barChartData, props.barChartLabels])

    const getXAxisLabel = (label, index) => {
        try {
            const finalVal = (horizontalBarChartData[0].values[index].value * 100) / chart.percentVal;
            return "(" + (isNaN(finalVal) ? '0' : finalVal.toFixed(2)) + "%) " + label
        } catch (error) {
            showBoundary(error)
        }

    }

    // const getXAxisLabelWorkflow = (label, index) => {

    //     // let type = '';
    //     // if(horizontalBarChartData[0].values[index].type === 'guide_workflow'){
    //     //     type = 'Guide Workflow';
    //     // } else {
    //     //     type = 'Dynamic Workflow';
    //     // }
    //     return  label;
    // }

    let horizontalBarChartOptions = {
        chart: {
            type: 'multiBarHorizontalChart',
            width: 830,
            height: 350,
            x: function (d) {
                return d.label;
            },
            y: function (d) {
                return Math.round(d.value)
            },

            showControls: false,
            showValues: true,
            showLegend: false,
            duration: 500,
            xAxis: {
                tickFormat: function (key, d) {

                    let xAxisLabel = ""
                    if (key.includes('stepIndex')) {
                        xAxisLabel = key.split('stepIndex', 1);
                        return getXAxisLabel(xAxisLabel, d);

                    } else if (key.includes('workflowType')) {
                        xAxisLabel = key.split('workflowType', 1);
                        // return getXAxisLabelWorkflow(xAxisLabel, d);
                        return xAxisLabel;
                    }
                }
            },
            margin: {
                left: 260,
                top: 0
            },
            yAxis: {
                tickFormat: function (d) {
                    return Math.round(d3.format('.0f')(d));
                }
            },
            barColor: function (d, i) {
                if (d.type === 'guide_workflow') {
                    return ChartColors.slateblue;
                } else if (d.type === 'dynamic_workflow') {
                    return ChartColors.lightpink;
                } else {
                    var colors = d3.scaleOrdinal(d3.schemeCategory10).range();
                    return colors[i];
                }
            },

        }
    }

    horizontalBarChartOptions.chart.tooltip = {
        contentGenerator: function (key) {
            let word_arr = [];
            if (key.data.label.includes('stepIndex')) {
                word_arr = key.data.label.split('stepIndex');
                return `<div>${word_arr[0]}<br/>${insightsLbls['count']}: <b>${key.data.value}</b></div>`;
            } else if (key.data.label.includes('workflowType')) {
                word_arr = key.data.label.split('workflowType');
                return `<div>${word_arr[0]}<br/>${insightsLbls['count']}: <b>${key.data.value}</b></div>`;
            }
        }
    }

    if (props.barChartData[0].values.length === 0) {
        horizontalBarChartOptions.chart.noData = '';
    }

    function mycallbackReady() {
        try {
            d3.selectAll(".funnelBorder-div .tick text").each(function () {
                var text = d3.select(this),
                    words = text.text().split(/\s+/).reverse(),
                    word, line = [],
                    lineNumber = 0,
                    lineHeight = 1.1, // ems
                    y = text.attr("y"),
                    dy = parseFloat(text.attr("dy")),
                    tspan = text.text(null).append("tspan").attr("x", -5).attr("y", y).attr("dy", dy + "em");

                while (word = words.pop()) {

                    if (word.includes('stepIndex')) {
                        const word_arr = word.split('stepIndex');
                        word = word_arr[0];
                    }

                    line.push(word);
                    tspan.text(line.join(" "));

                    // TDOD : Make 80 a dynamic value based on the bar width/height
                    if (tspan.node().getComputedTextLength() > 190) {
                        line.pop();
                        tspan.text(line.join(" "));
                        line = [word];
                        tspan = text.append("tspan").attr("x", -5).attr("y", y).attr("dy", ++lineNumber * lineHeight * 1.5 + "em").text(function () {
                            if (word.includes('stepIndex')) {
                                const word_arr = word.split('stepIndex');
                                word = word_arr[0];
                            }
                            return word;
                        });
                    }
                }
            });
        } catch (error) {
            showBoundary(error)
        }

    }

    return (
        <NVD3Chart
            id="barChart" type="multiBarHorizontalChart" datum={horizontalBarChartData} barColor={horizontalBarChartOptions.chart.barColor}
            margin={horizontalBarChartOptions.chart.margin} showLegend={false}

            showValues={true} showControls={false} height={350} width={800}
            x={horizontalBarChartOptions.chart.x} y={horizontalBarChartOptions.chart.y}
            xAxis={horizontalBarChartOptions.chart.xAxis} yAxis={horizontalBarChartOptions.chart.yAxis}
            tooltip={horizontalBarChartOptions.chart.tooltip} ready={mycallbackReady} valueFormat={d3.format('.0f')} />
    )
}

export default React.memo(HorizontalBarChart);
