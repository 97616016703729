import React, { useState, useEffect, useRef, useCallback } from "react";
import withRouter from "hoc/withRouter";
import { useNavigate } from 'react-router-dom';


// Redux Imports
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { withReducer } from "store/withReducer";
import { CommonComponentState, EventSourceState, UrlOptionState, LanguageState } from "store/Reducers";
import * as ActionTypes from "../../../store/Actions/ActionTypes";
import { UserEngagementState } from "store/Reducers/GuideInsights/userEngagement";
import userEngagementReducer from "store/Reducers/GuideInsights/userEngagement";
import * as userEngagementActions from "store/Actions/index";
import { getCallExportExcel } from "utils/ApiCallActions";


import qs from 'querystring';
import { CommonUtils } from "utils/CommonUtils";
import { ChartColors } from "utils/GlobalConstants";


// SCSS Imports
import "./UserEngagement.scss";


// JSON Imports
import SectionTooltip from "components/SectionTooltip/SectionTooltip";
import SectionRefresh from "components/Layouts/SectionRefresh";
import CardContainer from "components/Layouts/CardContainer";
import ContainerTooltip from "components/Layouts/ContainerTooltip";
import CommonTable from "components/Table/CommonTable";
import CommonExpandableTable from "components/Table/CommonExpandableTable";
import CustomDashboardModal from "components/CommonModal/CustomDashboardModal/CustomDashboardModal";
import Loader from "components/Loader/Loader";
import SectionHeader from 'components/Layouts/SectionHeader';
import Datepicker from 'components/Calender/Datepicker';
import EventSource from 'components/EventSource/EventSource';
import SectionButtons from "components/Layouts/SectionButtons";
import ExportToPdf from "components/ExportToPdf/ExportToPdf";
import UserEngagementLazyLoad from "components/UserEngagementData/UserEngagementLazyLoad";
import Maintenance from "components/Maintenance/Maintenance";
import LineChart from "components/Chart/nvd3Charts/LineChart";
import { useErrorBoundary } from 'react-error-boundary';
// type PathParamsType = {
//     // param1: string,
// }

// // Your component own properties
// type props = RouteComponentProps<PathParamsType> & {
//     // someString: string,
// }



import SectionFilters from "components/Layouts/SectionFilters";
import EnvironmentFilter from "components/EnvironmentFilter/EnvironmentFilter";
import { InsightLabels } from "labels";
import { v4 as uuidv4 } from 'uuid';
import { Dropdown, ButtonGroup, Button } from "react-bootstrap";
import UserInfoSection from "components/Layouts/UserInfoSection";


interface CurrentState {
    // apps: AppsState,
    // domain: DomainState,
    // dates: DatesState,
    userEngagements: UserEngagementState,
    eventSource: EventSourceState,
    urlOption: UrlOptionState,
    commonComponentData: CommonComponentState,
    languageData: LanguageState

}

const UserEngagement: React.FC = (props: any) => {

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const { showBoundary } = useErrorBoundary();

    const navigate = useNavigate();

    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];
    const previousDate_flag = 0;

    const dispatch = useDispatch();


    let fetched_details = useSelector((state: CurrentState) => state);
    let lineChartMargin = {
        top: "50",
        right: "50",
        bottom: "50",
        left: "65",
    };

    let linechart_height = 400;

    const GuideMeMount = useRef(false);
    const ShowMeMount = useRef(false);
    const TestMeMount = useRef(false);
    const DoItForMeMount = useRef(false);
    const TutorialGuidesMount = useRef(false);
    const SummaryMount = useRef(false);

    const [parameter, setParameter] = useState({
        paramter: "",
        source: "",
    });

    const [errorCount, setErrorCount] = useState({
        errorCount: [0, 0, 0, 0, 0, 0, 0],
        showMaintenance_flag: false
    });

    const [loaderBtn, setLoaderBtn] = useState({
        loader0: false,
        loader1: false,
        loader2: false,
        loader3: false,
        loader4: false,
        loader5: false,
        loader6: false,
        disabled: true,
    });

    const [filterValues, setFilterValues] = useState({
        eventSource: "",
        guideStatus: "",
        envFilter: ""
    });

    const [userEngagementGuideMeChart, setUserEngagementGuideMeChart] = useState({
        loadGuideMeChartData: [],
        loadGuideMeChartOptions: [],
        userEngagementGuideMeChartDataFetched: false,
        guideMeChart: {},
        guideMeChartFetched: false,
        api: "",
    });

    const [userEngagementShowMeChart, setUserEngagementShowMeChart] = useState({
        loadShowMeChartData: [],
        loadShowMeChartOptions: [],
        userEngagementShowMeChartDataFetched: false,
        showMeChart: {},
        showMeChartFetched: false,
        api: "",

    });

    const [userEngagementTestMeChart, setUserEngagementTestMeChart] = useState({
        loadTestMeChartData: [],
        loadTestMeChartOptions: [],
        userEngagementTestMeChartDataFetched: false,
        testMeChart: {},
        testMeChartFetched: false,
        api: "",
    });

    const [userEngagementDoItForMeChart, setUserEngagementDoItForMeChart] = useState({
        loadDoItForMeChartData: [],
        loadDoItForMeChartOptions: [],
        userEngagementDoItForMeChartDataFetched: false,
        doItForMeChart: {},
        doItForMeChartFetched: false,
        api: "",
    });

    const [userEngagementTutorialGuidesChart, setUserEngagementTutorialGuidesChart] = useState({
        loadTutorialGuidesChartData: [],
        loadTutorialGuidesChartOptions: [],
        userEngagementTutorialGuidesChartDataFetched: false,
        tutorialGuidesChart: {},
        tutorialGuidesChartFetched: false,
        api: "",
    });

    const [userEngagementSummaryTable, setUserEngagementSummaryTable] = useState({
        userEngagementSummaryTableDataFetched: false,
        searchQuery: '',
        sortKey: 'guideme_play_count',
        filterLabels: [],
        page: 1,
        showExport: true,
        defaultSort: {
            'sortColumn_index': 2,
            'sortValue': 'desc'
        },
        // renderTaggedTable: false,
        sortValue: 'desc',
        limit: 5,
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 5,
            currentPage: 0
        },
        firstLoad: true
    });

    const [userEngagementLazyLoadChart, setUserEngagementLazyLoadChart] = useState({
        lazyLoad_flag: false,
        refreshFlag: false,
    })

    const componentRef = useRef() as React.RefObject<HTMLDivElement>;

    const [dateDisabled, setDateDisabled] = useState(false);

    const [request_uuid, setUuid] = useState({
        value: ''
    })
    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@  
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
            window.scrollTo(0, 0);
            CommonUtils.LeftPanelHighlight(1, 6, 0, true, false);
        }

    }, [fetched_details.languageData.languageData])



    useEffect(() => {
        setFilterValues((prevState: any) => {
            return {
                ...prevState,
                eventSource: fetched_details?.commonComponentData?.eventSource.eventSourceUserEngagement,
                envFilter: fetched_details?.commonComponentData?.envFilter?.envCode
            };
        });

        if (fetched_details?.commonComponentData.appsData.appCode) {
            // getUserEngagementGuideMeChartData();
            // getUserEngagementShowMeChartData();
            // getUserEngagementTestMeChartData();
            // getUserEngagementSummaryTableData('firstLoad');
        }
    }, []);


    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getUserEngagementGuideMeChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceUserEngagement,
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
            "env_code": fetched_details?.commonComponentData?.envFilter?.envCode ?? '',
            'request_uuid': reqUUID
        };

        setUserEngagementGuideMeChart((prevState: any) => {
            return {
                ...prevState,
                api: ["USER_ENGAGEMENT_GUIDE_ME_CHART"],
            }
        })

        userEngagementActions.getUserEngagementData(
            params,
            "USER_ENGAGEMENT_GUIDE_ME_CHART",
            ActionTypes.SET_USER_ENGAGEMENT_GUIDEME_CHART_DATA,
            apiErrorFlag ?? '',
        ).then((res: any) => dispatch(res));
    };

    const getUserEngagementShowMeChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceUserEngagement,
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
            "env_code": fetched_details?.commonComponentData?.envFilter?.envCode ?? '',
            'request_uuid': reqUUID

        };

        setUserEngagementShowMeChart((prevState: any) => {
            return {
                ...prevState,
                api: ["USER_ENGAGEMENT_SHOW_ME_CHART"],
            }
        })

        userEngagementActions.getUserEngagementData(
            params,
            "USER_ENGAGEMENT_SHOW_ME_CHART",
            ActionTypes.SET_USER_ENGAGEMENT_SHOWME_CHART_DATA,
            apiErrorFlag ?? '',
        ).then((res: any) => dispatch(res));
    };

    const getUserEngagementTestMeChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceUserEngagement,
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
            "env_code": fetched_details?.commonComponentData?.envFilter?.envCode ?? '',
            'request_uuid': reqUUID
        };

        setUserEngagementTestMeChart((prevState: any) => {
            return {
                ...prevState,
                api: ["USER_ENGAGEMENT_TEST_ME_CHART"],
            }
        })

        userEngagementActions.getUserEngagementData(
            params,
            "USER_ENGAGEMENT_TEST_ME_CHART",
            ActionTypes.SET_USER_ENGAGEMENT_TESTME_CHART_DATA,
            apiErrorFlag ?? '',
        ).then((res: any) => dispatch(res));
    };

    const getUserEngagementDoItForMeChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceUserEngagement,
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
            "env_code": fetched_details?.commonComponentData?.envFilter?.envCode ?? '',
            'request_uuid': reqUUID
        };

        setUserEngagementDoItForMeChart((prevState: any) => {
            return {
                ...prevState,
                api: ["USER_ENGAGEMENT_DO_IT_FOR_ME_CHART"],
            }
        })

        userEngagementActions.getUserEngagementData(
            params,
            "USER_ENGAGEMENT_DO_IT_FOR_ME_CHART",
            ActionTypes.SET_USER_ENGAGEMENT_DO_IT_FOR_ME_CHART_DATA,
            apiErrorFlag ?? '',
        ).then((res: any) => dispatch(res));
    };

    const getUserEngagementTutorialGuidesChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceUserEngagement,
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
            "env_code": fetched_details?.commonComponentData?.envFilter?.envCode ?? '',
            'request_uuid': reqUUID
        };

        setUserEngagementTutorialGuidesChart((prevState: any) => {
            return {
                ...prevState,
                api: ["USER_ENGAGEMENT_TUTORIAL_GUIDES_CHART"],
            }
        })

        userEngagementActions.getUserEngagementData(
            params,
            "USER_ENGAGEMENT_TUTORIAL_GUIDES_CHART",
            ActionTypes.SET_USER_ENGAGEMENT_TUTORIAL_GUIDES_CHART_DATA,
            apiErrorFlag ?? '',
        ).then((res: any) => dispatch(res));
    };

    const getUserEngagementSummaryTableData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceUserEngagement,
            "page_size": userEngagementSummaryTable.limit,
            "sort_by": userEngagementSummaryTable.sortKey,
            "order": userEngagementSummaryTable.sortValue,
            "page_index": userEngagementSummaryTable.page,
            "query": encodeURIComponent(userEngagementSummaryTable.searchQuery),
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
            "env_code": fetched_details?.commonComponentData?.envFilter?.envCode ?? '',
            'request_uuid': reqUUID
        };

        userEngagementActions.getUserEngagementData(
            params,
            "USER_ENGAGEMENT_SUMMARY_TABLE",
            ActionTypes.SET_USER_ENGAGEMENT_SUMMARY_TABLE_DATA,
            apiErrorFlag ?? '',
            userEngagementSummaryTable.firstLoad
        ).then((res: any) => dispatch(res));
    };

    const exportExcel = (reqUUID, data: any) => {
        let params = {
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.realTime_tdate + "_23:59:59",
            "search_text": encodeURIComponent(userEngagementSummaryTable.searchQuery),
            "time_zone": timeZone,
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "sort_by": userEngagementSummaryTable.sortKey,
            "order": userEngagementSummaryTable.sortValue,
            "page_index": userEngagementSummaryTable.page,
            "page_size": userEngagementSummaryTable.limit,
            "event_source": fetched_details?.commonComponentData?.eventSource.eventSourceUserEngagement,
            "limit": userEngagementSummaryTable.limit,
            'exclude_weekends': fetched_details?.commonComponentData.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData.datesData.realTimeCheck,
            'request_uuid': reqUUID
        };
        getCallExportExcel(params, 'USER_ENGAGEMENT_SUMMARY_TABLE', data);
    }

    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) =>
                        i === index ? option : item
                    ),
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    const enableExportPdf = (loaderNumber: number, val: boolean) => {
        try {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    ['loader' + loaderNumber]: val
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const refreshSections = (optn: number) => {
        try {
            enableExportPdf(optn, false);
            if (optn === 0) {
                if (userEngagementLazyLoadChart.lazyLoad_flag) {
                    setUserEngagementLazyLoadChart((prevState: any) => {
                        return {
                            ...prevState,
                            refreshFlag: !(userEngagementLazyLoadChart.refreshFlag)
                        }
                    })
                }
            }
            else if (optn === 1) {
                setUserEngagementGuideMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementGuideMeChartDataFetched: false,
                        guideMeChartFetched: false,
                        guideMeChart: {}
                    };
                });
                getUserEngagementGuideMeChartData(request_uuid.value);
            }
            else if (optn === 2) {
                setUserEngagementShowMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementShowMeChartDataFetched: false,
                        showMeChartFetched: false,
                        showMeChart: {}
                    };
                });
                getUserEngagementShowMeChartData(request_uuid.value);
            }
            else if (optn === 3) {
                setUserEngagementTestMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementTestMeChartDataFetched: false,
                        testMeChartFetched: false,
                        testMeChart: {}
                    };
                });
                getUserEngagementTestMeChartData(request_uuid.value);
            }
            else if (optn === 4) {
                setUserEngagementDoItForMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementDoItForMeChartDataFetched: false,
                        doItForMeChartFetched: false,
                        doItForMeChart: {}
                    };
                });
                getUserEngagementDoItForMeChartData(request_uuid.value);
            }
            else if (optn === 5) {
                setUserEngagementTutorialGuidesChart((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementTutorialGuidesChartDataFetched: false,
                        tutorialGuidesChartFetched: false,
                        tutorialGuidesChart: {}
                    };
                });
                getUserEngagementTutorialGuidesChartData(request_uuid.value);
            }
            else if (optn === 6) {
                setUserEngagementSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementSummaryTableDataFetched: false,

                        searchQuery: '',
                        sortKey: 'guideme_play_count',
                        filterLabels: [],
                        page: 1,
                        showExport: true,
                        defaultSort: {
                            'sortColumn_index': 2,
                            'sortValue': 'desc'
                        },
                        // renderTaggedTable: false,
                        sortValue: 'desc',
                        limit: 5,
                        firstLoad: true,
                        tablePagination: {
                            offset: 0,
                            data: [],
                            perPage: 5,
                            currentPage: 0
                        },
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }
    };

    const handleTableEvents = useCallback((option: string, data: any) => {
        try {
            switch (option) {
                case "routeToDetail":
                    setParameter((prevState: any) => {
                        return {
                            ...prevState,
                            paramter: data.uniqueUserID,
                            source: data.userDisplayName,
                        };
                    });
                    break;

                case "sortColumn":

                    setUserEngagementSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            sortKey: data.sortKey,
                            sortValue: data.sortValue,
                            page: 1,
                            defaultSort: {
                                sortColumn_index: data.index,
                                sortValue: data.sortValue,
                            },
                            firstLoad: false
                        }
                    });

                    break;

                case "changePage":

                    setUserEngagementSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: data + 1,
                            firstLoad: false
                        }
                    });
                    break;

                case "searchTxt":

                    setUserEngagementSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            searchQuery: data,
                            firstLoad: false
                        }
                    });

                    break;

                case "changeEntries":

                    setUserEngagementSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            limit: data,
                            tablePagination: {                   // object that we want to update
                                ...prevState.tablePagination,    // keep all other key-value pairs
                                perPage: data       // update the value of specific key
                            },
                            firstLoad: false
                        }
                    });
                    break;

                case "exportExcel":
                    exportExcel(request_uuid.value, data)
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [userEngagementSummaryTable]);

    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

    // Sidenav hightlight on scroll

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            setTimeout(() => {
                if (localStorage.getItem('USER_ACTIVITY') === 'true') {
                    if (process.env.REACT_APP_WESTPAC === "false") {
                        if (currentScrollY >= 570 && currentScrollY < 1120) {
                            CommonUtils.LeftPanelHighlight(1, 6, 1, true, false);
                            // optionParam = 'GuideMe'
                        }
                        else if (currentScrollY >= 1120 && currentScrollY < 1680) {
                            CommonUtils.LeftPanelHighlight(1, 6, 2, true, false);
                            // optionParam = 'ShowMe''
                        }
                        else if (currentScrollY >= 1680 && currentScrollY < 2245) {
                            CommonUtils.LeftPanelHighlight(1, 6, 3, true, false);
                            // optionParam = 'TestMe'
                        }
                        else if (currentScrollY >= 2245 && currentScrollY < 2500) {
                            CommonUtils.LeftPanelHighlight(1, 6, 4, true, false);
                            // optionParam = 'DoItForMe'
                        }
                        else if (currentScrollY >= 2500 && currentScrollY < 3000) {
                            CommonUtils.LeftPanelHighlight(1, 6, 5, true, false);
                            // optionParam = 'TutorialGuides'
                        }
                        else if (currentScrollY >= 3000) {
                            CommonUtils.LeftPanelHighlight(1, 6, 6, true, false);
                            // optionParam = 'Summary'
                        }
                        else {
                            CommonUtils.LeftPanelHighlight(1, 6, 0, true, false);
                            // optionParam = 'UserActivity'
                        }
                    } else {
                        if (currentScrollY >= 570 && currentScrollY < 1150) {
                            CommonUtils.LeftPanelHighlight(1, 6, 1, true, false);
                            // optionParam = 'GuideMe'
                        }
                        else if (currentScrollY >= 1150 && currentScrollY < 1700) {
                            CommonUtils.LeftPanelHighlight(1, 6, 5, true, false);
                            // optionParam = 'TutorialGuides'
                        }
                        else if (currentScrollY >= 1700) {
                            CommonUtils.LeftPanelHighlight(1, 6, 6, true, false);
                            // optionParam = 'Summary'
                        }
                        else {
                            CommonUtils.LeftPanelHighlight(1, 6, 0, true, false);
                            // optionParam = 'UserActivity'
                        }
                    }

                }
                else {
                    CommonUtils.LeftPanelHighlight(1, 6, 0, true, false);
                }
            }, 0);
        };

        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => window.removeEventListener("scroll", handleScroll);
    }, [window.scrollY]);

    // Scroll on sidenav click
    useEffect(() => {
        let queryParams = {
            app_id: fetched_details?.commonComponentData.appsData.appId,
            app_code: fetched_details?.commonComponentData.appsData.appCode,
            fdate: fetched_details?.commonComponentData.datesData.fdate,
            tdate: fetched_details?.commonComponentData.datesData.tdate,
            event_source: fetched_details?.commonComponentData?.eventSource.eventSourceUserEngagement,
            env: fetched_details?.commonComponentData?.envFilter?.envCode,
        }
        navigate({
            pathname: `/guide-insights/user-engagement/`,
            search: qs.stringify(queryParams)
        })
        if (localStorage.getItem('USER_ACTIVITY') === 'true') {

            if (process.env.REACT_APP_WESTPAC === "false") {
                if (fetched_details?.urlOption.urlOption === 'UserActivity' || fetched_details?.urlOption.urlOption === '') {
                    // CommonUtils.LeftPanelHighlight(1, 2, 0, true, false);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
                else if (fetched_details?.urlOption.urlOption === 'GuideMe') {
                    // CommonUtils.LeftPanelHighlight(1, 2, 1, true, false);
                    window.scrollTo({ top: 580, behavior: 'smooth' });
                }
                else if (fetched_details?.urlOption.urlOption === 'ShowMe') {
                    // CommonUtils.LeftPanelHighlight(1, 2, 2, true, false);
                    window.scrollTo({ top: 1120, behavior: 'smooth' });
                }
                else if (fetched_details?.urlOption.urlOption === 'TestMe') {
                    // CommonUtils.LeftPanelHighlight(1, 2, 3, true, false);
                    window.scrollTo({ top: 1680, behavior: 'smooth' });
                }
                else if (fetched_details?.urlOption.urlOption === 'DoItForMe') {
                    // CommonUtils.LeftPanelHighlight(1, 2, 4, true, false);
                    window.scrollTo({ top: 2245, behavior: 'smooth' });
                }
                else if (fetched_details?.urlOption.urlOption === 'Tutorial Guides') {
                    // CommonUtils.LeftPanelHighlight(1, 2, 5, true, false);
                    window.scrollTo({ top: 2800, behavior: 'smooth' });
                }
                else {
                    // CommonUtils.LeftPanelHighlight(1, 2, 6, true, false);
                    window.scrollTo({ top: 3300, behavior: 'smooth' });
                }
            } else {
                if (fetched_details?.urlOption.urlOption === 'UserActivity' || fetched_details?.urlOption.urlOption === '') {
                    // CommonUtils.LeftPanelHighlight(1, 2, 0, true, false);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
                else if (fetched_details?.urlOption.urlOption === 'GuideMe') {
                    // CommonUtils.LeftPanelHighlight(1, 2, 1, true, false);
                    window.scrollTo({ top: 740, behavior: 'smooth' });
                }
                else if (fetched_details?.urlOption.urlOption === 'TutorialGuides') {
                    // CommonUtils.LeftPanelHighlight(1, 2, 5, true, false);
                    window.scrollTo({ top: 1300, behavior: 'smooth' });
                }
                else {
                    // CommonUtils.LeftPanelHighlight(1, 2, 6, true, false);
                    window.scrollTo({ top: 1840, behavior: 'smooth' });
                }
            }
        }
        else {
            // CommonUtils.LeftPanelHighlight(1, 2, 0, true, false);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

    }, [fetched_details?.urlOption.urlOption])

    // UseEffect for date, app, filters change
    useEffect(() => {
        try {
            if (Object.keys(fetched_details.commonComponentData.datesData).length !== 0) {
                let queryParams = {
                    app_id: fetched_details?.commonComponentData.appsData.appId,
                    app_code: fetched_details?.commonComponentData.appsData.appCode,
                    fdate: fetched_details?.commonComponentData.datesData.fdate,
                    tdate: fetched_details?.commonComponentData.datesData.tdate,
                    event_source: fetched_details?.commonComponentData?.eventSource.eventSourceUserEngagement,
                    env: fetched_details?.commonComponentData?.envFilter?.envCode,
                    option: fetched_details.urlOption.urlOption,
                }
                navigate({
                    pathname: `/guide-insights/user-engagement/`,
                    search: qs.stringify(queryParams)
                })



                if (fetched_details.commonComponentData.datesData.source === 'component' &&
                    fetched_details?.commonComponentData?.appsData.appCode == localStorage.getItem('switch_app_code') &&
                    fetched_details?.commonComponentData?.envFilter?.listFetched) {
                    // fetched_details?.commonComponentData.datesData.dateSelection = 15 (Custom Date option)
                    if (fetched_details?.commonComponentData.datesData?.url?.includes('user-engagement/')) {

                        const reqUUID = uuidv4();
                        setUuid((prevState: any) => {
                            return {
                                ...prevState,
                                value: reqUUID
                            }
                        })

                        setFilterValues((prevState: any) => {
                            return {
                                ...prevState,
                                eventSource: fetched_details?.commonComponentData?.eventSource.eventSourceUserEngagement,
                            };
                        });

                        setUserEngagementGuideMeChart((prevState: any) => {
                            return {
                                ...prevState,
                                userEngagementGuideMeChartDataFetched: false,
                                guideMeChartFetched: false,
                                guideMeChart: {}
                            };
                        });
                        enableExportPdf(1, false);
                        getUserEngagementGuideMeChartData(reqUUID);

                        setUserEngagementShowMeChart((prevState: any) => {
                            return {
                                ...prevState,
                                userEngagementShowMeChartDataFetched: false,
                                showMeChartFetched: false,
                                showMeChart: {}
                            };
                        });
                        enableExportPdf(2, false);
                        getUserEngagementShowMeChartData(reqUUID);

                        setUserEngagementTestMeChart((prevState: any) => {
                            return {
                                ...prevState,
                                userEngagementTestMeChartDataFetched: false,
                                testMeChartFetched: false,
                                testMeChart: {}
                            };
                        });
                        enableExportPdf(3, false);
                        getUserEngagementTestMeChartData(reqUUID);

                        setUserEngagementDoItForMeChart((prevState: any) => {
                            return {
                                ...prevState,
                                userEngagementDoItForMeChartDataFetched: false,
                                doItForMeChartFetched: false,
                                doItForMeChart: {}
                            };
                        });
                        enableExportPdf(4, false);
                        getUserEngagementDoItForMeChartData(reqUUID);

                        setUserEngagementTutorialGuidesChart((prevState: any) => {
                            return {
                                ...prevState,
                                userEngagementTutorialGuidesChartDataFetched: false,
                                tutorialGuidesChartFetched: false,
                                tutorialGuidesChart: {}
                            };
                        });
                        enableExportPdf(5, false);
                        getUserEngagementTutorialGuidesChartData(reqUUID);

                        setUserEngagementSummaryTable((prevState: any) => {
                            return {
                                ...prevState,
                                userEngagementSummaryTableDataFetched: false,
                                searchQuery: '',
                                sortKey: 'guideme_play_count',
                                filterLabels: [],
                                page: 1,
                                showExport: true,
                                defaultSort: {
                                    'sortColumn_index': 2,
                                    'sortValue': 'desc'
                                },
                                // renderTaggedTable: false,
                                sortValue: 'desc',
                                limit: 5,
                                tablePagination: {
                                    offset: 0,
                                    data: [],
                                    perPage: 5,
                                    currentPage: 0
                                },
                                firstLoad: true
                            };
                        });
                        enableExportPdf(6, false);
                    }
                }

            }
        } catch (error) {
            showBoundary(error)
        }
    }, [fetched_details?.commonComponentData.datesData,
    fetched_details?.commonComponentData?.eventSource.eventSourceUserEngagement,
    fetched_details.languageData.languageData,
    fetched_details?.commonComponentData?.envFilter])

    //GuideMe
    useEffect(() => {
        try {
            if (
                fetched_details.userEngagements.userEngagementGuideMeChartData.data !== undefined &&
                fetched_details.userEngagements.userEngagementGuideMeChartData.result === "success" &&
                fetched_details?.userEngagements.userEngagementGuideMeChartData?.requestUUID === request_uuid.value
            ) {
                GuideMeMount.current = true;
                let guideMeChartData: any = [];
                guideMeChartData = fetched_details.userEngagements.userEngagementGuideMeChartData.data.graphData;
                setUserEngagementGuideMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadGuideMeChartData: [...guideMeChartData],
                        userEngagementGuideMeChartDataFetched: true,
                    };
                });

                apiCatchError(1, 0);
                enableExportPdf(1, true);
            } else if (fetched_details.userEngagements.userEngagementGuideMeChartData.result === 'retry') {
                getUserEngagementGuideMeChartData(request_uuid.value, 'retry');
            } else if (fetched_details.userEngagements.userEngagementGuideMeChartData.result === "error") {
                setUserEngagementGuideMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementGuideMeChartDataFetched: true,
                    };
                });
                apiCatchError(1, 1);
                enableExportPdf(1, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.userEngagements.userEngagementGuideMeChartData]);

    //ShowMe
    useEffect(() => {
        try {
            if (
                fetched_details.userEngagements.userEngagementShowMeChartData.data !== undefined &&
                fetched_details.userEngagements.userEngagementShowMeChartData.result === "success" &&
                fetched_details?.userEngagements.userEngagementShowMeChartData?.requestUUID === request_uuid.value
            ) {
                ShowMeMount.current = true;
                let showMeChartData: any = [];
                showMeChartData = fetched_details.userEngagements.userEngagementShowMeChartData.data.graphData;

                setUserEngagementShowMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadShowMeChartData: [...showMeChartData],
                        userEngagementShowMeChartDataFetched: true,
                    };
                });
                apiCatchError(2, 0);
                enableExportPdf(2, true);
            } else if (fetched_details.userEngagements.userEngagementShowMeChartData.result === 'retry') {
                getUserEngagementShowMeChartData(request_uuid.value, 'retry');
            } else if (fetched_details.userEngagements.userEngagementShowMeChartData.result === "error") {
                setUserEngagementShowMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementShowMeChartDataFetched: true,
                    };
                });
                apiCatchError(2, 1);
                enableExportPdf(2, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.userEngagements.userEngagementShowMeChartData]);

    //TestMe
    useEffect(() => {
        try {
            if (
                fetched_details.userEngagements.userEngagementTestMeChartData.data !== undefined &&
                fetched_details.userEngagements.userEngagementTestMeChartData.result === "success" &&
                fetched_details?.userEngagements.userEngagementTestMeChartData?.requestUUID === request_uuid.value
            ) {
                TestMeMount.current = true;
                let testMeChartData: any = [];
                testMeChartData = fetched_details.userEngagements.userEngagementTestMeChartData.data.graphData;

                setUserEngagementTestMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadTestMeChartData: [...testMeChartData],
                        userEngagementTestMeChartDataFetched: true,
                    };
                });
                apiCatchError(3, 0);
                enableExportPdf(3, true);

            } else if (fetched_details.userEngagements.userEngagementTestMeChartData.result === 'retry') {
                getUserEngagementTestMeChartData(request_uuid.value, 'retry');
            } else if (fetched_details.userEngagements.userEngagementTestMeChartData.result === "error") {
                setUserEngagementTestMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementTestMeChartDataFetched: true,
                    };
                });
                apiCatchError(3, 1);
                enableExportPdf(3, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.userEngagements.userEngagementTestMeChartData]);

    //DoItForMe
    useEffect(() => {
        try {
            if (
                fetched_details.userEngagements.userEngagementDoItForMeChartData.data !== undefined &&
                fetched_details.userEngagements.userEngagementDoItForMeChartData.result === "success"
            ) {
                DoItForMeMount.current = true;
                let doItForMeChartData: any = [];
                doItForMeChartData = fetched_details.userEngagements.userEngagementDoItForMeChartData.data.graphData;
                setUserEngagementDoItForMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadDoItForMeChartData: [...doItForMeChartData],
                        userEngagementDoItForMeChartDataFetched: true,
                    };
                });

                apiCatchError(4, 0);
                enableExportPdf(4, true);
            } else if (fetched_details.userEngagements.userEngagementDoItForMeChartData.result === 'retry') {
                getUserEngagementDoItForMeChartData(request_uuid.value, 'retry');
            } else if (fetched_details.userEngagements.userEngagementDoItForMeChartData.result === "error") {
                setUserEngagementDoItForMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementDoItForMeChartDataFetched: true,
                    };
                });
                apiCatchError(4, 1);
                enableExportPdf(4, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.userEngagements.userEngagementDoItForMeChartData]);

    //TutorialGuides
    useEffect(() => {
        try {
            if (
                fetched_details.userEngagements.userEngagementTutorialGuidesChartData.data !== undefined &&
                fetched_details.userEngagements.userEngagementTutorialGuidesChartData.result === "success"
            ) {
                TutorialGuidesMount.current = true;
                let tutorialGuidesChartData: any = [];
                tutorialGuidesChartData = fetched_details.userEngagements.userEngagementTutorialGuidesChartData.data.graphData;
                setUserEngagementTutorialGuidesChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadTutorialGuidesChartData: [...tutorialGuidesChartData],
                        userEngagementTutorialGuidesChartDataFetched: true,
                    };
                });

                apiCatchError(5, 0);
                enableExportPdf(5, true);
            } else if (fetched_details.userEngagements.userEngagementTutorialGuidesChartData.result === 'retry') {
                getUserEngagementTutorialGuidesChartData(request_uuid.value, 'retry');
            } else if (fetched_details.userEngagements.userEngagementTutorialGuidesChartData.result === "error") {
                setUserEngagementTutorialGuidesChart((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementTutorialGuidesChartDataFetched: true,
                    };
                });
                apiCatchError(5, 1);
                enableExportPdf(5, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.userEngagements.userEngagementTutorialGuidesChartData]);

    //Summary Table
    useEffect(() => {
        try {
            if (
                fetched_details.userEngagements.userEngagementSummaryTableData.data !== undefined &&
                fetched_details.userEngagements.userEngagementSummaryTableData.result === "success"
            ) {
                SummaryMount.current = true;
                setUserEngagementSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementSummaryTableDataFetched: true,
                    };
                });
                apiCatchError(6, 0);
                enableExportPdf(6, true);
            } else if (fetched_details.userEngagements.userEngagementSummaryTableData.result === 'retry') {
                getUserEngagementSummaryTableData(request_uuid.value, 'retry');
            } else if (
                fetched_details.userEngagements.userEngagementSummaryTableData.result === "error"
            ) {
                setUserEngagementSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        userEngagementSummaryTableDataFetched: true,
                    };
                });
                apiCatchError(6, 1);
                enableExportPdf(6, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.userEngagements.userEngagementSummaryTableData]);

    // Call summary table api on table related actions changes
    useEffect(() => {
        try {
            // fetched_details?.commonComponentData.datesData.dateSelection = 15 (Custom Date option)
            if (fetched_details?.commonComponentData.datesData?.url?.includes('user-engagement/') && request_uuid.value) {
                enableExportPdf(6, false);
                getUserEngagementSummaryTableData(request_uuid.value, 'table');
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [
        userEngagementSummaryTable.sortKey,
        userEngagementSummaryTable.sortValue,
        userEngagementSummaryTable.defaultSort,
        userEngagementSummaryTable.page,
        userEngagementSummaryTable.searchQuery,
        userEngagementSummaryTable.limit,
    ]);

    //Export PDF Btn
    useEffect(() => {
        try {
            if (loaderBtn.loader0 && loaderBtn.loader1 && loaderBtn.loader2 && loaderBtn.loader3 && loaderBtn.loader4 && loaderBtn.loader5 && loaderBtn.loader6) {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: false,
                    };
                });
            }
            else {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: true,
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [loaderBtn.loader0, loaderBtn.loader1, loaderBtn.loader2, loaderBtn.loader3, loaderBtn.loader4, loaderBtn.loader5, loaderBtn.loader6])

    // Detail Navigation
    useEffect(() => {
        try {
            if (parameter.paramter !== '') {
                let queryParams = {
                    app_id: fetched_details?.commonComponentData.appsData.appId,
                    app_code: fetched_details?.commonComponentData.appsData.appCode,
                    fdate: fetched_details?.commonComponentData.datesData.fdate,
                    tdate: fetched_details?.commonComponentData.datesData.tdate,
                }
                navigate({
                    pathname: "/guide-insights/user-engagement-detail/" + parameter.paramter + '/',
                    search: qs.stringify(queryParams),
                });
            }

        } catch (error) {
            showBoundary(error)
        }

    }, [parameter])

    // Update Error Count Array
    useEffect(() => {
        try {
            const result = errorCount.errorCount.every((val) => val === 1);

            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    showMaintenance_flag: result
                }
            })
        } catch (error) {
            showBoundary(error)
        }


    }, [errorCount.errorCount])

    // Chart Renders and Callbacks @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

    const LineChartGuideMe = useCallback(() => {
        try {
            setUserEngagementGuideMeChart((prevState: any) => {
                return {
                    ...prevState,
                    guideMeChart: <LineChart
                        chartEntities={['guide_play', 'guide_unique_play', 'guide_complete']}
                        optionEntities={[
                            { key: insightsLbls !== undefined && insightsLbls['totalPlayed'], color: ChartColors.color1, area: false },
                            { key: insightsLbls !== undefined && insightsLbls['uniquePlayed'], color: ChartColors.color2, area: false },
                            { key: insightsLbls !== undefined && insightsLbls['completed'], color: ChartColors.color3, area: false }
                        ]}
                        chartData={fetched_details?.userEngagements?.userEngagementGuideMeChartData?.data?.graphData}
                        value_type={"count"}
                        height={linechart_height}
                        margin={lineChartMargin}
                        yAxisLabel={insightsLbls['yAxisLabelCount']}
                        refId="userEngagement_guideme">
                    </LineChart>,
                    guideMeChartFetched: true,
                };
            });
            return (
                null
            );
        } catch (error) {
            showBoundary(error)
        }


    }, [lineChartMargin, linechart_height]);

    const LineChartShowMe = useCallback(() => {
        try {
            setUserEngagementShowMeChart((prevState: any) => {
                return {
                    ...prevState,
                    showMeChart: <LineChart
                        chartEntities={['showme_total_play', 'showme_unique_play']}
                        optionEntities={[
                            { key: insightsLbls !== undefined && insightsLbls['totalPlayCount'], color: ChartColors.color1, area: false },
                            { key: insightsLbls !== undefined && insightsLbls['uniquePlayCount'], color: ChartColors.color2, area: false }
                        ]}
                        chartData={fetched_details?.userEngagements?.userEngagementShowMeChartData?.data?.graphData}
                        value_type={"count"}
                        height={linechart_height}
                        margin={lineChartMargin}
                        yAxisLabel={insightsLbls['yAxisLabelCount']}
                        refId="userEngagement_showme">
                    </LineChart>,
                    showMeChartFetched: true,
                };
            });
            return (
                null
            );
        } catch (error) {
            showBoundary(error)
        }

    }, [lineChartMargin, linechart_height]);

    const LineChartTestMe = useCallback(() => {
        try {
            setUserEngagementTestMeChart((prevState: any) => {
                return {
                    ...prevState,
                    testMeChart: <LineChart
                        chartEntities={['testme_total_play', 'testme_unique_play']}
                        optionEntities={[
                            { key: insightsLbls !== undefined && insightsLbls['testMeTotalPlayCount'], color: ChartColors.color1, area: false },
                            { key: insightsLbls !== undefined && insightsLbls['testMeUniquePlayCount'], color: ChartColors.color2, area: false }
                        ]}
                        chartData={fetched_details?.userEngagements?.userEngagementTestMeChartData?.data?.graphData}
                        value_type={"count"}
                        height={linechart_height}
                        margin={lineChartMargin}
                        yAxisLabel={insightsLbls['yAxisLabelCount']}
                        refId="userEngagement_testme">
                    </LineChart>,
                    testMeChartFetched: true,
                };
            });
            return (
                null
            );
        } catch (error) {
            showBoundary(error)
        }

    }, [lineChartMargin, linechart_height]);

    const LineChartDoItForMe = useCallback(() => {
        try {
            setUserEngagementDoItForMeChart((prevState: any) => {
                return {
                    ...prevState,
                    doItForMeChart: <LineChart
                        chartEntities={['doitforme_play', 'doitforme_unique_play', 'doitforme_complete']}
                        optionEntities={[
                            { key: insightsLbls !== undefined && insightsLbls['totalPlayed'], color: ChartColors.color1, area: false },
                            { key: insightsLbls !== undefined && insightsLbls['uniquePlayed'], color: ChartColors.color2, area: false },
                            { key: insightsLbls !== undefined && insightsLbls['completed'], color: ChartColors.color3, area: false }
                        ]}
                        chartData={fetched_details?.userEngagements?.userEngagementDoItForMeChartData?.data?.graphData}
                        value_type={"count"}
                        height={linechart_height}
                        margin={lineChartMargin}
                        yAxisLabel={insightsLbls['yAxisLabelCount']}
                        refId="userEngagement_doitforme">
                    </LineChart>,
                    doItForMeChartFetched: true,
                };
            });
            return (
                null
            );
        } catch (error) {
            showBoundary(error)
        }


    }, [lineChartMargin, linechart_height]);

    const LineChartTutorialGuides = useCallback(() => {
        try {
            setUserEngagementTutorialGuidesChart((prevState: any) => {
                return {
                    ...prevState,
                    tutorialGuidesChart: <LineChart
                        chartEntities={['tutorial_play', 'tutorial_unique_play', 'tutorial_complete']}
                        optionEntities={[
                            { key: insightsLbls !== undefined && insightsLbls['totalPlayed'], color: ChartColors.color1, area: false },
                            { key: insightsLbls !== undefined && insightsLbls['uniquePlayed'], color: ChartColors.color2, area: false },
                            { key: insightsLbls !== undefined && insightsLbls['completed'], color: ChartColors.color3, area: false }
                        ]}
                        chartData={fetched_details?.userEngagements?.userEngagementTutorialGuidesChartData?.data?.graphData}
                        value_type={"count"}
                        height={linechart_height}
                        margin={lineChartMargin}
                        yAxisLabel={insightsLbls['yAxisLabelCount']}
                        refId="userEngagement_tutorialGuides">
                    </LineChart>,
                    tutorialGuidesChartFetched: true,
                };
            });
            return (
                null
            );
        } catch (error) {
            showBoundary(error)
        }


    }, [lineChartMargin, linechart_height]);

    const setSectionRefresh = useCallback((flag: boolean) => {
        try {
            setUserEngagementLazyLoadChart((prevState: any) => {
                return {
                    ...prevState,
                    lazyLoad_flag: flag
                }
            })

            if (flag) {
                enableExportPdf(0, true);
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [setUserEngagementLazyLoadChart, enableExportPdf])

    return (
        <section className="demo width100 userEngagementSection" ref={componentRef}>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <SectionHeader>
                        <li className="active">{insightsLbls['userEngagement']}</li>
                    </SectionHeader>
                </div>

                <SectionFilters>
                    <EnvironmentFilter component={"searches"} disabled={false} />
                    <EventSource component={"userEngagement"}></EventSource>
                    <Datepicker dateDisabled={dateDisabled} source={'component'} />
                </SectionFilters>
            </div>

            {/* <SectionButtons sectionTitle={insightsLbls['userEngagement']} svgImage={"userEngagement"} className={'marginTop-50'}>
                <ExportToPdf
                    componentRef={componentRef}
                    source={"User Engagement"}
                    widthDividedBy={12}
                    disabled={loaderBtn.disabled}
                ></ExportToPdf>
            </SectionButtons> */}


            <div className="marginTop-60 exportBtn-div">
                <Dropdown as={ButtonGroup}>
                    <Button variant="info">{insightsLbls.export}</Button>
                    <Dropdown.Toggle split variant="success" id="dropdown-custom-2" />
                    <Dropdown.Menu className="super-colors">
                        <Dropdown.Item eventKey="1" >
                            <ExportToPdf
                                componentRef={componentRef}
                                source={"userEngagement"}
                                widthDividedBy={12}
                                disabled={loaderBtn.disabled}
                            ></ExportToPdf>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <UserInfoSection
                startDate={fetched_details?.commonComponentData?.datesData.fdate}
                endDate={fetched_details?.commonComponentData?.datesData.tdate}
                dateObject={fetched_details?.commonComponentData?.datesData}
                messageText={insightsLbls.userEngagement}>
            </UserInfoSection>
            


            <div id="userEngagementSection">
                {
                    // <SectionRefresh sectionTitle={insightsLbls.userActivity} refreshClass={""}>
                    //     <i className="fa fa-refresh pull-right cursorPointer row displayFlex"
                    //         aria-hidden="true"
                    //         title={insightsLbls['refresh']}
                    //         style={{ cursor: userEngagementLazyLoadChart.lazyLoad_flag ? 'pointer' : 'default' }}
                    //         onClick={() => refreshSections(0)}></i>
                    // </SectionRefresh>
                }
                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height600 blockImportant">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight550 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            <SectionTooltip tooltipText={
                                {
                                    "subTitle": insightsLbls.activity,
                                    "tooltipText": insightsLbls.userActivityGraph,
                                    "childern": [
                                        {
                                            "key": insightsLbls.engagedUsers + ":",
                                            "value": insightsLbls.usersPerformedSomeActivity
                                        }
                                    ]
                                }
                            } placement="right"></SectionTooltip>
                            <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(0)} style={{ cursor: userEngagementLazyLoadChart.lazyLoad_flag ? 'pointer' : 'default' }}> {insightsLbls.refresh}</button>
                        </ContainerTooltip>
                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                            <UserEngagementLazyLoad
                                eventSource={fetched_details?.commonComponentData?.eventSource.eventSourceUserEngagement}
                                dispatchOverlay={setSectionRefresh}
                                refreshFlag={userEngagementLazyLoadChart.refreshFlag}
                                envFilter={fetched_details?.commonComponentData?.envFilter?.envCode}
                                component="userEngagement">
                            </UserEngagementLazyLoad>
                        </div>

                    </CardContainer>
                </div>

                {localStorage.getItem('USER_ACTIVITY') === 'true' && (
                    <>
                        {

                            // <SectionRefresh sectionTitle={insightsLbls.guideMe} refreshClass={"marginTop-30"}>
                            //     <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls['refresh']} onClick={() => refreshSections(1)}></i>
                            //     <CustomDashboardModal
                            //         api={userEngagementGuideMeChart.api}
                            //         eventSource={filterValues.eventSource}
                            //         guideStatus={filterValues.guideStatus}
                            //         chartType={"LineChart"}
                            //         sectionHeader={insightsLbls.guideMe}
                            //         sectionTooltip={
                            //             {
                            //                 "subTitle": insightsLbls.userActivity,
                            //                 "tooltipText": insightsLbls.lineGraphUserEngagement,
                            //                 "childern": [
                            //                     {
                            //                         "key": insightsLbls.guideMeUniquePlayCount + ":",
                            //                         "value": insightsLbls.numberOfUsersPlayedInGuideMode
                            //                     },
                            //                     {
                            //                         "key": insightsLbls.guideMeTotalPlayCount + ":",
                            //                         "value": insightsLbls.numberOfUniqueUsersPlayedInGuideMode
                            //                     },
                            //                     {
                            //                         "key": insightsLbls.guideMeCompletePlayCount + ":",
                            //                         "value": insightsLbls.numberOfTotalUsersPlayedInGuideMode
                            //                     }
                            //                 ]
                            //             }
                            //         }
                            //         sectionType={'type1'}
                            //         guide_filter={false}
                            //         event_filter={true}
                            //         segment_filter={false}
                            //         tooltip_filter={false}
                            //         component={'userEngagement'}
                            //     ></CustomDashboardModal>
                            // </SectionRefresh>
                        }
                        <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant marginTop-20">
                            <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                                div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                                div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                                <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                    <SectionTooltip tooltipText={
                                        {
                                            "subTitle": insightsLbls.guideMe,
                                            "tooltipText": insightsLbls.lineGraphUserEngagement,
                                            "childern": [
                                                {
                                                    "key": insightsLbls.guideMeUniquePlayCount + ":",
                                                    "value": insightsLbls.numberOfUsersPlayedInGuideMode
                                                },
                                                {
                                                    "key": insightsLbls.guideMeTotalPlayCount + ":",
                                                    "value": insightsLbls.numberOfUniqueUsersPlayedInGuideMode
                                                },
                                                {
                                                    "key": insightsLbls.guideMeCompletePlayCount + ":",
                                                    "value": insightsLbls.numberOfTotalUsersPlayedInGuideMode
                                                }
                                            ]
                                        }
                                    } placement="right"></SectionTooltip>


                                    <span>
                                        <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(1)}> {insightsLbls.refresh}</button>
                                        <CustomDashboardModal
                                            api={userEngagementGuideMeChart.api}
                                            eventSource={filterValues.eventSource}
                                            guideStatus={filterValues.guideStatus}
                                            chartType={"LineChart"}
                                            sectionHeader={insightsLbls.guideMe}
                                            sectionTooltip={
                                                {
                                                    "subTitle": insightsLbls.userActivity,
                                                    "tooltipText": insightsLbls.lineGraphUserEngagement,
                                                    "childern": [
                                                        {
                                                            "key": insightsLbls.guideMeUniquePlayCount + ":",
                                                            "value": insightsLbls.numberOfUsersPlayedInGuideMode
                                                        },
                                                        {
                                                            "key": insightsLbls.guideMeTotalPlayCount + ":",
                                                            "value": insightsLbls.numberOfUniqueUsersPlayedInGuideMode
                                                        },
                                                        {
                                                            "key": insightsLbls.guideMeCompletePlayCount + ":",
                                                            "value": insightsLbls.numberOfTotalUsersPlayedInGuideMode
                                                        }
                                                    ]
                                                }
                                            }
                                            sectionType={'type1'}
                                            guide_filter={false}
                                            event_filter={true}
                                            segment_filter={false}
                                            tooltip_filter={false}
                                            component={'userEngagement'}
                                        ></CustomDashboardModal>
                                    </span>
                                </ContainerTooltip>

                                <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">

                                    {userEngagementGuideMeChart.userEngagementGuideMeChartDataFetched ? (
                                        errorCount.errorCount[1] === 0 ? (
                                            userEngagementGuideMeChart.loadGuideMeChartData.length === 0 ? (
                                                <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                        <div className="noDataText-font textCenter-absolute">
                                                            {insightsLbls.noRecordFound}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                userEngagementGuideMeChart.guideMeChartFetched ? <>{userEngagementGuideMeChart.guideMeChart}</> :
                                                    (<LineChartGuideMe></LineChartGuideMe>)
                                            )
                                        ) : (
                                            <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                <div className="displayFlex alignCenter margin-0-auto">
                                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                                        {insightsLbls.serviceNotAvailable}
                                                    </h5>
                                                </div>
                                            </div>
                                        )
                                    ) : (
                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <Loader></Loader>
                                            </div>
                                        </div>
                                    )}
                                </div>

                            </CardContainer>
                        </div>

                        {/* {process.env.REACT_APP_WESTPAC === "false" ? (
                            <SectionRefresh sectionTitle={insightsLbls.showMe} refreshClass={"marginTop-30"}>
                                <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls['refresh']} onClick={() => refreshSections(2)}></i>
                                <CustomDashboardModal
                                    api={userEngagementShowMeChart.api}
                                    eventSource={filterValues.eventSource}
                                    guideStatus={filterValues.guideStatus}
                                    chartType={"LineChart"}
                                    sectionHeader={insightsLbls.showMe}
                                    sectionTooltip={
                                        {
                                            "subTitle": insightsLbls.userActivity,
                                            "tooltipText": insightsLbls.lineGraphUserEngagement,
                                            "childern": [
                                                {
                                                    "key": insightsLbls.totalPlayCount + ":",
                                                    "value": insightsLbls.showMeTotalPlayCount
                                                },
                                                {
                                                    "key": insightsLbls.uniquePlayCount + ":",
                                                    "value": insightsLbls.showMeUniquePlayCount
                                                }
                                            ]
                                        }
                                    }
                                    sectionType={'type1'}
                                    guide_filter={false}
                                    event_filter={true}
                                    segment_filter={false}
                                    tooltip_filter={false}
                                    component={'userEngagement'}

                                ></CustomDashboardModal>
                            </SectionRefresh>
                        ): null} */}

                        {process.env.REACT_APP_WESTPAC === "false" ? (<div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant marginTop-20">
                            <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                                div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                                div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                                <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                    <SectionTooltip tooltipText={
                                        {
                                            "subTitle": insightsLbls.showMe,
                                            "tooltipText": insightsLbls.lineGraphUserEngagement,
                                            "childern": [
                                                {
                                                    "key": insightsLbls.totalPlayCount + ":",
                                                    "value": insightsLbls.showMeTotalPlayCount
                                                },
                                                {
                                                    "key": insightsLbls.uniquePlayCount + ":",
                                                    "value": insightsLbls.showMeUniquePlayCount
                                                }
                                            ]
                                        }
                                    } placement="right"></SectionTooltip>


                                    <span>
                                        <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(2)}> {insightsLbls.refresh}</button>
                                        <CustomDashboardModal
                                            api={userEngagementShowMeChart.api}
                                            eventSource={filterValues.eventSource}
                                            guideStatus={filterValues.guideStatus}
                                            chartType={"LineChart"}
                                            sectionHeader={insightsLbls.showMe}
                                            sectionTooltip={
                                                {
                                                    "subTitle": insightsLbls.userActivity,
                                                    "tooltipText": insightsLbls.lineGraphUserEngagement,
                                                    "childern": [
                                                        {
                                                            "key": insightsLbls.totalPlayCount + ":",
                                                            "value": insightsLbls.showMeTotalPlayCount
                                                        },
                                                        {
                                                            "key": insightsLbls.uniquePlayCount + ":",
                                                            "value": insightsLbls.showMeUniquePlayCount
                                                        }
                                                    ]
                                                }
                                            }
                                            sectionType={'type1'}
                                            guide_filter={false}
                                            event_filter={true}
                                            segment_filter={false}
                                            tooltip_filter={false}
                                            component={'userEngagement'}

                                        ></CustomDashboardModal>
                                    </span>
                                </ContainerTooltip>

                                <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">

                                    {userEngagementShowMeChart.userEngagementShowMeChartDataFetched ? (
                                        errorCount.errorCount[2] === 0 ? (
                                            userEngagementShowMeChart.loadShowMeChartData.length === 0 ? (
                                                <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                        <div className="noDataText-font textCenter-absolute">
                                                            {insightsLbls.noRecordFound}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                userEngagementShowMeChart.showMeChartFetched ? <>{userEngagementShowMeChart.showMeChart}</> :
                                                    (<LineChartShowMe></LineChartShowMe>)
                                            )
                                        ) : (
                                            <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                <div className="displayFlex alignCenter margin-0-auto">
                                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                                        {insightsLbls.serviceNotAvailable}
                                                    </h5>
                                                </div>
                                            </div>
                                        )
                                    ) : (
                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <Loader></Loader>
                                            </div>
                                        </div>
                                    )}
                                </div>

                            </CardContainer>
                        </div>) : null}

                        {/* {process.env.REACT_APP_WESTPAC === "false" ? (
                            <SectionRefresh sectionTitle={insightsLbls.testMe} refreshClass={"marginTop-30"}>
                                <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls['refresh']} onClick={() => refreshSections(3)}
                                ></i>
                                <CustomDashboardModal
                                    api={userEngagementTestMeChart.api}
                                    eventSource={filterValues.eventSource}
                                    guideStatus={filterValues.guideStatus}
                                    chartType={"LineChart"}
                                    sectionHeader={insightsLbls.testMe}
                                    sectionTooltip={{
                                        "subTitle": insightsLbls.userActivity,
                                        "tooltipText": insightsLbls.lineGraphUserEngagement,
                                        "childern": [
                                            {
                                                "key": insightsLbls.testMeUniquePlayCount + ":",
                                                "value": insightsLbls.testMeUserTestCount
                                            },
                                            {
                                                "key": insightsLbls.testMeTotalPlayCount + ":",
                                                "value": insightsLbls.testMeUniqueUserTestCount
                                            }
                                        ]
                                    }}
                                    sectionType={'type1'}
                                    guide_filter={false}
                                    event_filter={true}
                                    segment_filter={false}
                                    tooltip_filter={false}
                                    component={'userEngagement'}

                                ></CustomDashboardModal>
                            </SectionRefresh>
                        ) : null

                        } */}
                        {process.env.REACT_APP_WESTPAC === "false" ? (<div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant marginTop-20">
                            <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                                div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                                div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                                <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                    <SectionTooltip tooltipText={
                                        {
                                            "subTitle": insightsLbls.testMe,
                                            "tooltipText": insightsLbls.lineGraphUserEngagement,
                                            "childern": [
                                                {
                                                    "key": insightsLbls.testMeUniquePlayCount + ":",
                                                    "value": insightsLbls.testMeUserTestCount
                                                },
                                                {
                                                    "key": insightsLbls.testMeTotalPlayCount + ":",
                                                    "value": insightsLbls.testMeUniqueUserTestCount
                                                }
                                            ]
                                        }
                                    } placement="right"></SectionTooltip>


                                    <span>
                                        <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(3)}> {insightsLbls.refresh}</button>
                                        <CustomDashboardModal
                                            api={userEngagementTestMeChart.api}
                                            eventSource={filterValues.eventSource}
                                            guideStatus={filterValues.guideStatus}
                                            chartType={"LineChart"}
                                            sectionHeader={insightsLbls.testMe}
                                            sectionTooltip={{
                                                "subTitle": insightsLbls.userActivity,
                                                "tooltipText": insightsLbls.lineGraphUserEngagement,
                                                "childern": [
                                                    {
                                                        "key": insightsLbls.testMeUniquePlayCount + ":",
                                                        "value": insightsLbls.testMeUserTestCount
                                                    },
                                                    {
                                                        "key": insightsLbls.testMeTotalPlayCount + ":",
                                                        "value": insightsLbls.testMeUniqueUserTestCount
                                                    }
                                                ]
                                            }}
                                            sectionType={'type1'}
                                            guide_filter={false}
                                            event_filter={true}
                                            segment_filter={false}
                                            tooltip_filter={false}
                                            component={'userEngagement'}

                                        ></CustomDashboardModal>
                                    </span>
                                </ContainerTooltip>
                                <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                                    {userEngagementTestMeChart.userEngagementTestMeChartDataFetched ? (
                                        errorCount.errorCount[3] === 0 ? (
                                            userEngagementTestMeChart.loadTestMeChartData.length === 0 ? (
                                                <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                        <div className="noDataText-font textCenter-absolute">
                                                            {insightsLbls.noRecordFound}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                userEngagementTestMeChart.testMeChartFetched ? <>{userEngagementTestMeChart.testMeChart}</> :
                                                    (<LineChartTestMe></LineChartTestMe>)
                                            )
                                        ) : (
                                            <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                <div className="displayFlex alignCenter margin-0-auto">
                                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                                        {insightsLbls.serviceNotAvailable}
                                                    </h5>
                                                </div>
                                            </div>
                                        )
                                    ) : (
                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <Loader></Loader>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </CardContainer>
                        </div>) : null}
                        {/* 
                        {
                            process.env.REACT_APP_WESTPAC === "false" ? (
                                <SectionRefresh sectionTitle={insightsLbls.doItForMe} refreshClass={"marginTop-30"}>
                                    <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls['refresh']} onClick={() => refreshSections(4)}></i>
                                    <CustomDashboardModal
                                        api={userEngagementDoItForMeChart.api}
                                        eventSource={filterValues.eventSource}
                                        guideStatus={filterValues.guideStatus}
                                        chartType={"LineChart"}
                                        sectionHeader={insightsLbls.doItForMe}
                                        sectionTooltip={
                                            {
                                                "subTitle": insightsLbls.userActivity,
                                                "tooltipText": insightsLbls.lineGraphUserEngagement,
                                                "childern": [
                                                    {
                                                        "key": insightsLbls.doItForMeUniquePlayCount + ":",
                                                        "value": insightsLbls.doItForMeUserPlayCount
                                                    },
                                                    {
                                                        "key": insightsLbls.doItForMeTotalPlayCount + ":",
                                                        "value": insightsLbls.doItForMeUniqueUserPlayCount
                                                    },
                                                    {
                                                        "key": insightsLbls.doItForMeCompleteCount + ":",
                                                        "value": insightsLbls.doItForMeCompleteUserPlayCount
                                                    }
                                                ]
                                            }
                                        }
                                        sectionType={'type1'}
                                        guide_filter={false}
                                        event_filter={true}
                                        segment_filter={false}
                                        tooltip_filter={false}
                                        component={'userEngagement'}
                                    ></CustomDashboardModal>
                                </SectionRefresh>
                            ) : null

                        } */}
                        {process.env.REACT_APP_WESTPAC === "false" ? (
                            <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant marginTop-20">
                                <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                                    div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                                    div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                                    <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                        <SectionTooltip tooltipText={
                                            {
                                                "subTitle": insightsLbls.doItForMe,
                                                "tooltipText": insightsLbls.lineGraphUserEngagement,
                                                "childern": [
                                                    {
                                                        "key": insightsLbls.doItForMeUniquePlayCount + ":",
                                                        "value": insightsLbls.doItForMeUserPlayCount
                                                    },
                                                    {
                                                        "key": insightsLbls.doItForMeTotalPlayCount + ":",
                                                        "value": insightsLbls.doItForMeUniqueUserPlayCount
                                                    },
                                                    {
                                                        "key": insightsLbls.doItForMeCompleteCount + ":",
                                                        "value": insightsLbls.doItForMeCompleteUserPlayCount
                                                    }
                                                ]
                                            }
                                        } placement="right"></SectionTooltip>


                                        <span>
                                            <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(4)}> {insightsLbls.refresh}</button>
                                            <CustomDashboardModal
                                                api={userEngagementDoItForMeChart.api}
                                                eventSource={filterValues.eventSource}
                                                guideStatus={filterValues.guideStatus}
                                                chartType={"LineChart"}
                                                sectionHeader={insightsLbls.doItForMe}
                                                sectionTooltip={
                                                    {
                                                        "subTitle": insightsLbls.userActivity,
                                                        "tooltipText": insightsLbls.lineGraphUserEngagement,
                                                        "childern": [
                                                            {
                                                                "key": insightsLbls.doItForMeUniquePlayCount + ":",
                                                                "value": insightsLbls.doItForMeUserPlayCount
                                                            },
                                                            {
                                                                "key": insightsLbls.doItForMeTotalPlayCount + ":",
                                                                "value": insightsLbls.doItForMeUniqueUserPlayCount
                                                            },
                                                            {
                                                                "key": insightsLbls.doItForMeCompleteCount + ":",
                                                                "value": insightsLbls.doItForMeCompleteUserPlayCount
                                                            }
                                                        ]
                                                    }
                                                }
                                                sectionType={'type1'}
                                                guide_filter={false}
                                                event_filter={true}
                                                segment_filter={false}
                                                tooltip_filter={false}
                                                component={'userEngagement'}
                                            ></CustomDashboardModal>
                                        </span>

                                    </ContainerTooltip>

                                    <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">

                                        {userEngagementDoItForMeChart.userEngagementDoItForMeChartDataFetched ? (
                                            errorCount.errorCount[4] === 0 ? (
                                                userEngagementDoItForMeChart.loadDoItForMeChartData.length === 0 ? (
                                                    <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                        <div className="displayFlex alignCenter margin-0-auto">
                                                            <div className="noDataText-font textCenter-absolute">
                                                                {insightsLbls.noRecordFound}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    userEngagementDoItForMeChart.doItForMeChartFetched ? <>{userEngagementDoItForMeChart.doItForMeChart}</> :
                                                        (<LineChartDoItForMe></LineChartDoItForMe>)
                                                )
                                            ) : (
                                                <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                                            {insightsLbls.serviceNotAvailable}
                                                        </h5>
                                                    </div>
                                                </div>
                                            )
                                        ) : (
                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                <div className="displayFlex alignCenter margin-0-auto">
                                                    <Loader></Loader>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                </CardContainer>
                            </div>) : null}

                        {/* {
                            <SectionRefresh sectionTitle={insightsLbls.tutorialGuide} refreshClass={"marginTop-30"}>
                                <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls['refresh']} onClick={() => refreshSections(5)}></i>
                                <CustomDashboardModal
                                    api={userEngagementTutorialGuidesChart.api}
                                    eventSource={filterValues.eventSource}
                                    guideStatus={filterValues.guideStatus}
                                    chartType={"LineChart"}
                                    sectionHeader={insightsLbls.tutorialGuide}
                                    sectionTooltip={
                                        {
                                            "subTitle": insightsLbls.userActivity,
                                            "tooltipText": insightsLbls.lineGraphUserEngagement,
                                            "childern": [
                                                {
                                                    "key": insightsLbls.tutorialGuidePlayCount + ":",
                                                    "value": insightsLbls.tutorialGuideUserPlayCount
                                                },
                                                {
                                                    "key": insightsLbls.tutorialGuideUniquePlayCount + ":",
                                                    "value": insightsLbls.tutorialGuideUniqueUserPlayCount
                                                },
                                                {
                                                    "key": insightsLbls.tutorialGuideCompletePlayCount + ":",
                                                    "value": insightsLbls.tutorialGuideCompleteUserPlayCount
                                                }
                                            ]
                                        }
                                    }
                                    sectionType={'type1'}
                                    guide_filter={false}
                                    event_filter={true}
                                    segment_filter={false}
                                    tooltip_filter={false}
                                    component={'userEngagement'}
                                ></CustomDashboardModal>
                            </SectionRefresh>
                        } */}
                        <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant marginTop-20">
                            <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                                div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                                div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                                <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                    <SectionTooltip tooltipText={
                                        {
                                            "subTitle": insightsLbls.tutorialGuide,
                                            "tooltipText": insightsLbls.lineGraphUserEngagement,
                                            "childern": [
                                                {
                                                    "key": insightsLbls.tutorialGuidePlayCount + ":",
                                                    "value": insightsLbls.tutorialGuideUserPlayCount
                                                },
                                                {
                                                    "key": insightsLbls.tutorialGuideUniquePlayCount + ":",
                                                    "value": insightsLbls.tutorialGuideUniqueUserPlayCount
                                                },
                                                {
                                                    "key": insightsLbls.tutorialGuideCompletePlayCount + ":",
                                                    "value": insightsLbls.tutorialGuideCompleteUserPlayCount
                                                }
                                            ]
                                        }
                                    } placement="right"></SectionTooltip>

                                    <span>
                                        <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(5)}> {insightsLbls.refresh}</button>
                                        <CustomDashboardModal
                                            api={userEngagementTutorialGuidesChart.api}
                                            eventSource={filterValues.eventSource}
                                            guideStatus={filterValues.guideStatus}
                                            chartType={"LineChart"}
                                            sectionHeader={insightsLbls.tutorialGuide}
                                            sectionTooltip={
                                                {
                                                    "subTitle": insightsLbls.userActivity,
                                                    "tooltipText": insightsLbls.lineGraphUserEngagement,
                                                    "childern": [
                                                        {
                                                            "key": insightsLbls.tutorialGuidePlayCount + ":",
                                                            "value": insightsLbls.tutorialGuideUserPlayCount
                                                        },
                                                        {
                                                            "key": insightsLbls.tutorialGuideUniquePlayCount + ":",
                                                            "value": insightsLbls.tutorialGuideUniqueUserPlayCount
                                                        },
                                                        {
                                                            "key": insightsLbls.tutorialGuideCompletePlayCount + ":",
                                                            "value": insightsLbls.tutorialGuideCompleteUserPlayCount
                                                        }
                                                    ]
                                                }
                                            }
                                            sectionType={'type1'}
                                            guide_filter={false}
                                            event_filter={true}
                                            segment_filter={false}
                                            tooltip_filter={false}
                                            component={'userEngagement'}
                                        ></CustomDashboardModal>
                                    </span>
                                </ContainerTooltip>

                                <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">

                                    {userEngagementTutorialGuidesChart.userEngagementTutorialGuidesChartDataFetched ? (
                                        errorCount.errorCount[5] === 0 ? (
                                            userEngagementTutorialGuidesChart.loadTutorialGuidesChartData.length === 0 ? (
                                                <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                        <div className="noDataText-font textCenter-absolute">
                                                            {insightsLbls.noRecordFound}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                userEngagementTutorialGuidesChart.tutorialGuidesChartFetched ? <>{userEngagementTutorialGuidesChart.tutorialGuidesChart}</> :
                                                    (<LineChartTutorialGuides></LineChartTutorialGuides>)
                                            )
                                        ) : (
                                            <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                <div className="displayFlex alignCenter margin-0-auto">
                                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                                        {insightsLbls.serviceNotAvailable}
                                                    </h5>
                                                </div>
                                            </div>
                                        )
                                    ) : (
                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <Loader></Loader>
                                            </div>
                                        </div>
                                    )}
                                </div>

                            </CardContainer>
                        </div>

                        {/* {
                            <SectionRefresh sectionTitle={insightsLbls.userEngagementSummaryOverview} refreshClass={"marginTop-30"}>
                                <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls['refresh']} onClick={() => refreshSections(6)}></i>
                            </SectionRefresh>
                        } */}
                        <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant marginTop-20">
                            <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                                div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                                div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                                <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                    {process.env.REACT_APP_WESTPAC === 'false' ? (
                                        <SectionTooltip tooltipText={
                                            {
                                                "subTitle": insightsLbls.userEngagementSummary,
                                                "tooltipText": insightsLbls.userEngagementDetailSummaryTable,
                                                "childern": [
                                                    {
                                                        "key": insightsLbls.guide + ":",
                                                        "value": insightsLbls.userEngagementDetailGuideName
                                                    },
                                                    {
                                                        "key": insightsLbls.guidePlayed + ":",
                                                        "value": insightsLbls.userEngagementDetailTotalGuidePlay
                                                    },
                                                    {
                                                        "key": insightsLbls.showMePlayed + ":",
                                                        "value": insightsLbls.userEngagementDetailShowMePlay
                                                    },
                                                    {
                                                        "key": insightsLbls.testMePlayed + ":",
                                                        "value": insightsLbls.userEngagementDetailTestMePlay
                                                    }
                                                ]
                                            }
                                        } placement="right"></SectionTooltip>
                                    ) : (<SectionTooltip tooltipText={
                                        {
                                            "subTitle": insightsLbls.userEngagementSummary,
                                            "tooltipText": insightsLbls.userEngagementDetailSummaryTable,
                                            "childern": [
                                                {
                                                    "key": insightsLbls.guide + ":",
                                                    "value": insightsLbls.userEngagementDetailGuideName
                                                },
                                                {
                                                    "key": insightsLbls.guidePlayed + ":",
                                                    "value": insightsLbls.userEngagementDetailTotalGuidePlay
                                                }
                                            ]
                                        }
                                    } placement="right"></SectionTooltip>)}

                                    <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(6)}> {insightsLbls.refresh}</button>

                                </ContainerTooltip>
                                {
                                    userEngagementSummaryTable.userEngagementSummaryTableDataFetched ? (
                                        errorCount.errorCount[6] === 0 ? (
                                            <div className="tabelEditing commonSummaryTable">
                                                {/* <CommonTable tableId="1"
                                                    data={fetched_details.userEngagements.userEngagementSummaryTableData.data}
                                                    currentPage={userEngagementSummaryTable.page}
                                                    barColors=""
                                                    linkPresent={true}
                                                    showExport={true}
                                                    showSearch={true}
                                                    showEntries={true}
                                                    defaultSort={userEngagementSummaryTable.defaultSort}
                                                    tablePagination={userEngagementSummaryTable.tablePagination}
                                                    tableEvent={handleTableEvents}
                                                    firstTimeloadFlag={fetched_details.userEngagements.userEngagementSummaryTableData.firstTimeload}
                                                    modalTable={false}
                                                />                                         */}

                                                <CommonExpandableTable tableId="2"
                                                    data={fetched_details.userEngagements.userEngagementSummaryTableData.data}
                                                    currentPage={userEngagementSummaryTable.page}
                                                    barColors=""
                                                    linkPresent={true}
                                                    showExport={true}
                                                    showSearch={true}
                                                    showEntries={true}
                                                    defaultSort={userEngagementSummaryTable.defaultSort}
                                                    tablePagination={userEngagementSummaryTable.tablePagination}
                                                    tableEvent={handleTableEvents}
                                                    firstTimeloadFlag={fetched_details.userEngagements.userEngagementSummaryTableData.firstTimeload}
                                                    modalTable={false}
                                                    source={"userEngagement"}
                                                />

                                            </div>
                                        )
                                            : errorCount.errorCount[6] === 1 ? (
                                                <div className="loaderHeight width100 displayFlex minHeight490 top-30 positionRelative">
                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                                            {insightsLbls.serviceNotAvailable}
                                                        </h5>
                                                    </div>
                                                </div>
                                            )
                                                : null)
                                        : (
                                            <div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
                                                <div className="displayFlex alignCenter margin-0-auto">
                                                    <Loader></Loader>
                                                </div>
                                            </div>
                                        )
                                }
                            </CardContainer>
                        </div>
                    </>
                )}
            </div>

            <div className="overlayCursor" id="overlayCursor"></div>
            {errorCount.showMaintenance_flag && (
                <Maintenance></Maintenance>
            )}

        </section>

    )
}
export default withRouter(withReducer("userEngagements", userEngagementReducer)(UserEngagement));