import React, { useEffect, useState, useCallback } from 'react';


import { getAssimaURLCall } from 'utils/ApiCallActions';


import './Table.scss';

import { useSelector } from "react-redux";
import { LanguageState } from "store/Reducers";
import { useErrorBoundary } from 'react-error-boundary'; import { InsightLabels } from 'labels';
interface CurrentState {
    languageData: LanguageState
}

const Table = (props: any) => {
    let fetched_details = useSelector((state: CurrentState) => state);
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);
    const { showBoundary } = useErrorBoundary();
    let cdnSig = localStorage.getItem('USER_SIGN');
    const environment = process.env.REACT_APP_ENV;

    // const [rowSelection, setRowSelection] = useState({
    //     rowIndex: ''
    // })

    const [state, setState] = useState({
        tableFlag: false,
        tableData: []
    })

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        try {
            if (props.tableData.length) {

                let dummyData: any = props.tableData;
                const domainVal = process.env.REACT_APP_ANALYTICS_HOST;
                if (environment === 'assima') {
                    for (let i = 0; i < props.tableData.length; i++) {
                        let logo = props.tableData[i].appIcon;
                        dummyData[i] = props.tableData[i];
                        dummyData[i].appDummyIcon = logo;
                        if (logo) {
                            if (environment === 'assima' && !logo?.includes(domainVal) && !logo?.includes('data:image/png')) {
                                dummyData[i].appIcon = '';
                            }
                        }
                    }

                    setState((prevState: any) => {
                        return {
                            ...prevState,
                            tableData: dummyData,
                            tableFlag: true
                        }
                    });

                    let dummyNew = dummyData;
                    for (let i = 0; i < dummyData.length; i++) {
                        let logo1 = dummyData[i].appDummyIcon;
                        const domainVal = process.env.REACT_APP_ANALYTICS_HOST;

                        if (logo1) {
                            if (!logo1.includes(domainVal) && !logo1.includes('data:image/png')) {
                                setState((prevState: any) => {
                                    return {
                                        ...prevState,
                                        tableFlag: false
                                    }
                                });

                                if (logo1.includes('X-Amz-Algorithm')) {
                                    logo1 = logo1.split('?X-Amz-Algorithm')[0];
                                }
                                getAssimaURLCall(logo1, 'appImg').then((data: any) => {
                                    if (data && data.result === 'success' && data.type === 'appImg') {
                                        dummyNew[i].appIcon = data?.data?.url;
                                        setState((prevState: any) => {
                                            return {
                                                ...prevState,
                                                tableData: dummyNew,
                                                tableFlag: true
                                            }
                                        });
                                    }
                                })
                            }
                        }
                    }

                } else {
                    setState((prevState: any) => {
                        return {
                            ...prevState,
                            tableData: props.tableData,
                            tableFlag: true
                        }
                    });
                }
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details.languageData.languageData])

    const getStyleClass = () => {
        return "table table-responsive color_table appUsage-dashboard-table table-fixed-column " 
        + (props.addCustomClass ? (process.env.REACT_APP_WESTPAC == 'false' ? 'customTable' : 'customTable-westpac') : '');
    }

    // const getClass = useCallback((index: number, type: string) => {
    //     if (index === 0) {
    //         if (type === 'tbodyRow') {
    //             return 'th-left-border positionRelative displayFlex alignCenter';
    //         } else {
    //             return 'th-left-border'
    //         }
    //     } else if (index + 1 === props.colHeaders.length) {
    //         return 'th-right-border';
    //     }
    // }, [props.tableData])

    return (
        <div className="tableContainer">

            {state.tableFlag
                ? (<table className={getStyleClass()} id={"appTable" + props.id}>
                    <thead className="bg-secondary sorting-header">
                        {props.superHeaders.length > 0 &&
                            <tr className='superHeaderRow'>
                                {props.superHeaders.map((data: any, index: number) => {
                                    return (
                                        <th colSpan={data.colspan} key={"suHe" + index} style={{ width: data.width }}>
                                            <span>
                                                {data.title}
                                            </span>
                                        </th>
                                    );
                                })}
                            </tr>
                        }
                        <tr>
                            {props.colHeaders.map((data: any, index: number) => {
                                return (
                                    <th col-span={data.colWidth} key={index} style={{ width: data.width }}>
                                        <span>
                                            {data.title}
                                        </span>
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            state.tableData.length !== 0
                                ? (<>
                                    {state.tableData?.map((tableData: any, ind: any) => {
                                        return (
                                            <tr
                                                key={ind}
                                                id={"tableRow_" + ind}
                                                className=''>
                                                {props.colKeys.map((data: any, index: any) => {
                                                    return (
                                                        <td
                                                            style={{
                                                                width: data.width,
                                                            }} key={"tablerow_" + ind + "_" + index} >

                                                            {/* Table row color */}
                                                            {tableData[data.color]
                                                                ? <span style={{ backgroundColor: tableData[data.color] }} className="tableRow-color"></span>
                                                                : null
                                                            }

                                                            {/* Table row image */}
                                                            {tableData[data.image]
                                                                ? [
                                                                    ((tableData[data.image] !== "") && (tableData[data.image].includes('http:') || tableData[data.image].includes('https:'))
                                                                        ? <img src={tableData[data.image] + cdnSig} className="appIcon" />
                                                                        : <img src={require("../../assets/images/organization_42x42.png")} className="appIcon" />
                                                                    )
                                                                ]
                                                                : null
                                                            }

                                                            {/* Table row title */}
                                                            <span>{tableData[data.key]}</span>
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        )
                                    })}
                                </>)
                                : (<tr>{insightsLbls.noRecordFound}</tr>)}

                    </tbody>
                </table>)
                : null}


        </div>
    )
};

export default React.memo(Table);
