import * as actionTypes from '../../Actions/ActionTypes';

export interface GoalDetailsState {
    goalDetailsLineChartData: any,
    goalDetailsProgressBarData: any,
    goalDetailsWatcherData: any
}

const initialState = {
    goalDetailsLineChartData: [],
    goalDetailsProgressBarData: [],
    goalDetailsWatcherData:[]
};


const goalDetailsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SET_GOAL_DETAILS_LINE_CHART_DATA:
            return {
                ...state,
                goalDetailsLineChartData: action.goalDetailsLineChartData,
            };

        case actionTypes.SET_GOAL_DETAILS_PROGRESS_BAR_DATA:
            return {
                ...state,
                goalDetailsProgressBarData: action.goalDetailsProgressBarData,
            };
        case actionTypes.SET_GOAL_DETAILS_WATCHER_DATA:
            return {
                ...state,
                goalDetailsWatcherData: action.goalDetailsWatcherData,
            }

        default:
            return state;
    }
};

export default goalDetailsReducer;