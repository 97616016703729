// Modified by: Mrunal Mane
// On: 2024-05-10 05:48 PM
// Reason: Removed language dependancy from useEffect initial state render

import React, { useState, useEffect, useRef, useCallback } from "react";
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, ButtonGroup, Dropdown, Tab, Tabs } from "react-bootstrap";

import { Modal } from 'react-bootstrap';
import { toast } from "react-toastify";


// Redux Imports
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { CommonComponentState, DomainState, LanguageState } from "../../../store/Reducers";
import { getCall, getCallExportExcel, postCall } from '../../../utils/ApiCallActions';
import { PageState, PagePostState } from "../../../store/Reducers/AppInsights/pages";
import * as ActionTypes from '../../../store/Actions/ActionTypes';
import * as pageActions from '../../../store/Actions/index';
import { withReducer } from "../../../store/withReducer";
import { pageReducer } from "../../../store/Reducers/AppInsights/pages";


// Common 
import qs from 'querystring';
import $ from 'jquery';
import { CommonUtils } from "../../../utils/CommonUtils";
import { AppDomains, RetryApi } from '../../../utils/GlobalConstants';
import SectionTooltip from '../../../components/SectionTooltip/SectionTooltip';


// SCSS Imports
import "./Page.scss";


// Components
import SectionRefresh from "../../../components/Layouts/SectionRefresh";
import ContainerTooltip from "../../../components/Layouts/ContainerTooltip";
import CardContainer from "../../../components/Layouts/CardContainer"
import Loader from '../../../components/Loader/Loader';
import CommonTable from '../../../components/Table/CommonTable';
import PageTaggedChart from '../../../components/PagesTaggedCharts/PageTaggedChart';
import PageUntaggedChart from '../../../components/PagesTaggedCharts/PageUntaggedChart';
import Blockchart from "components/Chart/Blockchart";
import SectionHeader from "../../../components/Layouts/SectionHeader";
import SectionButtons from "../../../components/Layouts/SectionButtons";
import Datepicker from '../../../components/Calender/Datepicker';
import BrowserStats from "../../../components/BrowserStats/BrowserStats";
import Rules from "../../../components/Rules/Rules";
import ExportToPdf from "../../../components/ExportToPdf/ExportToPdf";
import Label from "../../../components/Label/Label";
import Maintenance from "components/Maintenance/Maintenance";
import SectionFilters from "components/Layouts/SectionFilters";
import { useErrorBoundary } from 'react-error-boundary';
import { InsightLabels } from "labels";
import { v4 as uuidv4 } from 'uuid';
import Icon from "components/Icon/Icon";
import UserInfoSection from "components/Layouts/UserInfoSection";


type PathParamsType = {
    // param1: string,
}

interface CurrentState {
    domain: DomainState,
    pages: PageState,
    pagePost: PagePostState,
    commonComponentData: CommonComponentState,
    languageData: LanguageState
}

let rules_data: any[];
let labels_data: any[];
let asset_rulesD: any[];


const Pages: React.FC = (props: any) => {
    const isInitialMount = useRef(true);

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const { showBoundary } = useErrorBoundary();

    let domains: any = [];
    domains = AppDomains.domains;

    const location = useLocation();
    const navigate = useNavigate();

    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];


    const dispatch = useDispatch();
    const componentRef = useRef() as React.RefObject<HTMLDivElement>;

    const [domainCount, setDomainCount] = useState(0);

    let fetched_details = useSelector((state: CurrentState) => state);

    const [parameter, setParameter] = useState('');

    const [errorCount, setErrorCount] = useState({
        errorCount: [0, 0, 0],
        showMaintenance_flag: false
    })

    const [loaderBtn, setLoaderBtn] = useState({
        loader0: false,
        loader1: false,
        loader2: false,
        disabled: true,
    });


    const [pageTagData, setPageTagData] = useState({
        taggedFlag: true,
        searchQuery: '',
        sortKey: 'page_views',
        filterLabels: [],
        loadPageSummaryTable: false,
        pageContributionDataFetched: false,
        pageUntaggedContributionDataFetched: false,
        page: 1,
        showTagged: true,
        showExport: false,
        defaultSort: {
            'sortColumn_index': 2,
            'sortValue': 'desc'
        },
        sortValue: 'desc',
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 10,
            currentPage: 0
        },
        firstLoad: true

    })


    const [blockChart, setBlockChart] = useState({
        chartDataFetched: false,
    })


    const [usageData, setUsageData] = useState({
        pieChartDataFetched: false,
        usageTableDataFetched: false,
        firstLoadUsage: true,
        sortBy: "count",
        sortOrder: "asc",
    })


    const [rulesModal, setRulesModal] = useState({
        show_rules_modal: false,
        rulesSave: false,
        labelData: [],
        labelFlag: false,
        pageTagTitle: null,
        optionalFlag: false
    })

    const [pageTab, setPageTab] = useState({
        key: 1,
        chart: []
    });

    let urlData: any = {};
    const query = new URLSearchParams(location.search);
    query.forEach((v, k) => {
        urlData[k] = v;
    });

    const [request_uuid, setUuid] = useState({
        value: uuidv4()
    })
    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        document.title = insightsLbls.pages;
        if (insightsLbls) {
            document.title = insightsLbls.pages;

        }
        window.scrollTo(0, 0);
        CommonUtils.LeftPanelHighlight(2, 0, 0, false, false);
    }, [insightsLbls])

    useEffect(() => {
        try {
            if (isInitialMount.current) {
                let tag_page: number = 0;
                if (urlData?.tag_page !== null && urlData?.tag_page !== undefined && urlData?.tag_page !== '' &&
                    (urlData?.tag_page === '1' || urlData?.tag_page === 1)) {
                    handleShow()
                    tag_page = 1;
                }
                navigationFn(tag_page)
                getLabels();
                isInitialMount.current = false;
            }
            

        } catch (error) {
            showBoundary(error)
        }
    }, []);

    const navigationFn = (tagPageFlag: any) => {
        try {
            let queryParams = {
                app_id: fetched_details?.commonComponentData.datesData?.appId,
                app_code: fetched_details?.commonComponentData.appsData?.appCode,
                fdate: fetched_details?.commonComponentData.datesData.fdate,
                tdate: fetched_details?.commonComponentData.datesData.realTime_tdate,
                tag_page: tagPageFlag
            }
            navigate({
                pathname: `/app-insights/pages/`,
                search: qs.stringify(queryParams)
            })
        } catch (error) {
            showBoundary(error)
        }

    }


    // useEffect(()=>{
    // },[fetched_details?.commonComponentData.datesData])

    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getLabels = async (apiErrorFlag?: string) => {

        getCall('', 'GET_LABEL_LIST', apiErrorFlag ?? '').then((data: any) => {
            if (data.result === 'success') {
                setRulesModal((prevState: any) => {
                    return {
                        ...prevState,
                        labelData: data.data,
                        labelFlag: true
                    }
                })
            } else if (data.result === 'retry') {
                setTimeout(() => {
                    getLabels('retry')
                }, RetryApi.TIMEOUT)
            }
        })
    }

    const getPageTableData = (reqUUID, option: string, apiErrorFlag?: string) => {
        let params = {
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
            "time_zone": timeZone,
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "search_text": encodeURIComponent(pageTagData.searchQuery),
            "sort_by": pageTagData.sortKey,
            "order": pageTagData.sortValue,
            "page_index": pageTagData.page,
            "page_size": 10,
            "filter_label": pageTagData.filterLabels,
            'request_uuid': reqUUID
        };

        if (option === 'tagged') {
            pageActions.getPageData(
                params,
                "PAGE_SUMMARY_TAGGED",
                ActionTypes.SET_PAGE_SUMMARY_TAGGED,
                apiErrorFlag ?? '',
                pageTagData.firstLoad
            ).then((res: any) => dispatch(res));
        } else {
            pageActions.getPageData(
                params,
                "PAGE_SUMMARY_UNTAGGED",
                ActionTypes.SET_PAGE_SUMMARY_UNTAGGED,
                apiErrorFlag ?? '',
                pageTagData.firstLoad
            ).then((res: any) => dispatch(res));
        }
    }

    const getBlockChartData = (reqUUID, apiErrorFlag?: string) => {
        let params = {
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
            "time_zone": timeZone,
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            'request_uuid': reqUUID
        };

        pageActions.getPageData(params, 'BLOCK_DATA', ActionTypes.SET_BLOCK_CHART, apiErrorFlag ?? '').then((res: any) => dispatch(res));
    }

    const getUsageStats = (reqUUID, sortBy: string, order: string, optn: number, fdate: any, tdate: any, apiErrorFlag?: string) => {
        let params;
        if (optn === 1) {
            params = {
                "start_date": fdate + "_00:00:00",
                "end_date": tdate + "_23:59:59",
                "time_zone": timeZone,
                "app_code": fetched_details?.commonComponentData.appsData.appCode,
                "sort_by": sortBy,
                "order": order,
                'request_uuid': reqUUID
            }
        }
        else {
            params = {
                "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
                "end_date": fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
                "time_zone": timeZone,
                "app_code": fetched_details?.commonComponentData.appsData.appCode,
                "sort_by": sortBy,
                "order": order,
                'request_uuid': reqUUID
            };
        }
        pageActions.getPageData(params, 'USAGE_STATS', ActionTypes.SET_USAGE_DATA, apiErrorFlag ?? '').then((res: any) => dispatch(res));
    }

    const exportExcel = (reqUUID, data: any) => {
        let params = {
            "start_date": fetched_details?.commonComponentData.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData.datesData.tdate + "_23:59:59",
            "search_text": encodeURIComponent(pageTagData.searchQuery),
            "time_zone": timeZone,
            "app_code": fetched_details?.commonComponentData.appsData.appCode,
            "sort_by": pageTagData.sortKey,
            "order": pageTagData.sortValue,
            "page_index": pageTagData.page,
            "page_size": 5,
            'request_uuid': reqUUID
        };
        getCallExportExcel(params, 'PAGE_SUMMARY_TAGGED', data);
    }


    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const enableExportPdf = (loaderNumber: number, val: boolean) => {
        try {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    ['loader' + loaderNumber]: val
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) => (
                        i === index ? option : item
                    ))
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const handleClose = () => {
        try {
            setRulesModal((prevState: any) => {
                return {
                    ...prevState,
                    show_rules_modal: false
                }
            })
            navigationFn(0)
        } catch (error) {
            showBoundary(error)
        }

    }

    const handleShow = (data?: any) => {
        try {
            setRulesModal((prevState: any) => {
                return {
                    ...prevState,
                    show_rules_modal: true,
                    rulesSave: true,
                    pageTagTitle: data ?? null,
                }
            })
            navigationFn(1);
        } catch (error) {
            showBoundary(error)
        }

    }

    const switchTab = (option: string) => {
        try {
            if (option === 'tagged') {
                setPageTagData((prevPageState: any) => {
                    return {
                        ...prevPageState,
                        searchQuery: '',
                        page: 1,
                        showTagged: true,
                        taggedFlag: true,
                        showExport: false,
                        sortKey: 'page_views',
                        defaultSort: {
                            'sortColumn_index': 2,
                            'sortValue': 'desc'
                        },
                        pageContributionDataFetched: false,
                        loadPageSummaryTable: false,

                    }
                })
            } else {
                setPageTagData((prevPageState: any) => {
                    return {
                        ...prevPageState,
                        searchQuery: '',
                        page: 1,
                        showTagged: false,
                        taggedFlag: false,
                        showExport: false,
                        defaultSort: {
                            'sortColumn_index': 1,
                            'sortValue': 'desc'
                        },
                        sortKey: 'page_views',
                        pageUntaggedContributionDataFetched: false,
                        loadPageSummaryTable: false,
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const handleTableEvents = useCallback((option: string, data: any) => {
        try {
            switch (option) {
                case "routeToDetail":
                    setParameter(data.tagCode);
                    break;

                case "sortColumn":
                    setPageTagData((prevState) => {
                        return {
                            ...prevState,
                            sortKey: data.sortKey,
                            sortValue: data.sortValue,
                            page: 1,
                            defaultSort: {
                                sortColumn_index: data.index,
                                sortValue: data.sortValue,
                            },
                            firstLoad: false
                        };
                    });
                    break;

                case "changePage":
                    setPageTagData((prevState) => {
                        return {
                            ...prevState,
                            page: data + 1,
                            firstLoad: false
                        };
                    });
                    break;

                case "searchTxt":
                    setPageTagData((prevState: any) => {
                        return {
                            ...prevState,
                            page: 1,
                            searchQuery: data,
                            firstLoad: false,
                        };
                    });
                    break;

                case "applyLabels":
                    setPageTagData((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            filterLabels: data,
                            firstLoad: false
                        };
                    });
                    break;

                case "exportExcel":
                    exportExcel(request_uuid.value, data);
                    break;

                case "tagPage":
                    handleShow(data);
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [pageTagData])



    const refreshSections = (optn: number, fdate: any, tdate: any) => {
        try {
            if (optn === 0) {
                setPageTagData((prevState: any) => {
                    return {
                        ...prevState,
                        showTagged: true,
                        loadPageSummaryTable: false,
                        pageContributionDataFetched: false,
                        searchQuery: "",
                        page: 1,
                        filterLabels: [],
                        sortKey: "page_views",
                        sortValue: "desc",
                        taggedFlag: true,
                        firstLoad: true
                    };
                })

                setPageTab((prevState: any) => {
                    return {
                        ...prevState,
                        key: 1
                    };
                });

                // handled in useEffect for table
                // getPageTableData(true, 'tagged',1);

            } else if (optn === 1) {
                enableExportPdf(1, false);
                setUsageData((prevState: any) => {
                    return {
                        ...prevState,
                        pieChartDataFetched: false,
                        usageTableDataFetched: false
                    }
                });
                getUsageStats(request_uuid.value, 'count', 'asc', optn, fdate, tdate);
            } else if (optn === 2) {
                setBlockChart((prevState: any) => {
                    return {
                        ...prevState,
                        chartDataFetched: false
                    }
                })
                enableExportPdf(2, false);
                getBlockChartData(request_uuid.value);
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const getSortEvent = useCallback((renderVal: boolean, optn: string, sortBy: string, order: string) => {
        try {
            setUsageData((prevState: any) => {
                return {
                    ...prevState,
                    firstLoadUsage: false,
                    sortBy: sortBy,
                    sortOrder: order,
                }
            })
        } catch (error) {
            showBoundary(error)
        }

        // getUsageStats(sortBy, order, 0, fetched_details?.commonComponentData.datesData.fdate, fetched_details?.commonComponentData.datesData.tdate);
    }, [usageData])

    const handleRuleEvents = (optn: any, data: boolean) => {
        try {
            const inputValue = $('#createPageTitle').val();
            if (inputValue === "") {
                data = true;
            }
            if (optn === 'validation') {
                setRulesModal((prevState: any) => {
                    return {
                        ...prevState,
                        rulesSave: data
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const getRuleData = (data: any) => {
        rules_data = data;
    }

    const getLabelData = useCallback((data: any) => {
        try {
            labels_data = [];
            labels_data = data;

            if (data.length) {
                const inputTitleVal = $("#createPageTitle").val();
                setRulesModal((prevState: any) => {
                    return {
                        ...prevState,
                        rulesSave: inputTitleVal === '' ? true : false
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [rulesModal.labelData])

    const onTagTitle = (event: any) => {
        try {
            let inputValue = event.target.value;
            let rulesSave: boolean = false;

            if (!inputValue.replace(/\s/g, '').length) {
                rulesSave = true;
            } else {
                rulesSave = false;
            }

            setRulesModal((prevState: any) => {
                return {
                    ...prevState,
                    rulesSave: rulesSave
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const onTagDesc = (event: any) => {
        try {
            const inputTitleVal = $("#createPageTitle").val();
            setRulesModal((prevState: any) => {
                return {
                    ...prevState,
                    rulesSave: inputTitleVal === '' ? true : false
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const submitTag = () => {
        try {
            asset_rulesD = [];
            for (var i = 0; i < rules_data.length; i++) {
                for (var j = 0; j < rules_data[i].innerRules.length; j++) {
                    if (asset_rulesD[i] === undefined) {
                        asset_rulesD[i] = [];
                    }
                    if (asset_rulesD[i][j] === undefined) {
                        asset_rulesD[i][j] = {};
                    }
                    asset_rulesD[i][j].type = rules_data[i].innerRules[j].type;
                    asset_rulesD[i][j].value = rules_data[i].innerRules[j].value;
                    asset_rulesD[i][j].condition = rules_data[i].innerRules[j].condition;
                    asset_rulesD[i][j].logical_condition = "";
                    asset_rulesD[i][j].name = "";
                    asset_rulesD[i][j].order = j;
                    asset_rulesD[i][j].showValueField = true;
                }
            }

            let data = {
                "tagTitle": $("#createPageTitle").val(),
                "tagDescription": $("#createPageDescription").val(),
                "tagType": "page",
                "tagLabels": labels_data,
                "rules": asset_rulesD,
                "elementDetails": {},
                "parentTagCode": "",
                "appCode": fetched_details?.commonComponentData.appsData.appCode,
            };

            postCall(data, 'TAG_PAGE').then((data: any) => {
                if (data.result === 'success') {
                    handleClose();

                    getPageTableData(request_uuid.value, "tagged");
                    toast.success(insightsLbls.successfullyAdded, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    refreshSections(0, fetched_details?.commonComponentData.datesData.fdate, fetched_details?.commonComponentData.datesData.tdate)
                    setTimeout(() => {
                        toast.dismiss();
                    }, 5000);

                } else if (data.result === 'error') {
                    if (data.error) {
                        toast.error(data.error, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        refreshSections(0, fetched_details?.commonComponentData.datesData.fdate, fetched_details?.commonComponentData.datesData.tdate)
                        setTimeout(() => {
                            toast.dismiss();
                        }, 5000);
                    }
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const refreshBrowser = useCallback((optn: number, fdate: any, tdate: any) => {
        try {
            enableExportPdf(1, false);
            refreshSections(optn, fdate, tdate);
        } catch (error) {
            showBoundary(error)
        }

    }, [])


    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // set Domain
    useEffect(() => {
        try {
            setDomainCount(fetched_details?.domain?.allAppsData?.length)
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.domain])

    // On app code change
    useEffect(() => {
        try {
            window.scrollTo(0, 0);
            if (urlData?.tag_page !== null && urlData?.tag_page !== undefined && urlData?.tag_page !== '' &&
                (urlData?.tag_page === '1' || urlData?.tag_page === 1)) {
                navigationFn(1)
            }
            else {
                navigationFn(0)
            }

        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details?.commonComponentData.appsData.appCode]);

    // On tagged unTagged btn click
    useEffect(() => {
        try {
            // fetched_details?.commonComponentData.datesData.dateSelection = 15 (Custom Date option)
            if (fetched_details?.commonComponentData.datesData?.url?.includes('pages/') || fetched_details?.commonComponentData.datesData.dateSelection === 15) {
                if (pageTagData.showTagged) {
                    getPageTableData(request_uuid.value, "tagged");
                } else {
                    getPageTableData(request_uuid.value, "untagged");
                }
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [
        pageTagData.showTagged,
        pageTagData.sortKey,
        pageTagData.sortValue,
        pageTagData.defaultSort,
        pageTagData.page,
        pageTagData.searchQuery,
        pageTagData.filterLabels
    ]);

    useEffect(() => {
        try {
            // fetched_details?.commonComponentData.datesData.dateSelection = 15 (Custom Date option)
            if (usageData.firstLoadUsage === false) {
                getUsageStats(request_uuid.value, usageData.sortBy, usageData.sortOrder, 0, fetched_details?.commonComponentData.datesData.fdate, fetched_details?.commonComponentData.datesData.tdate);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [
        usageData.sortBy,
        usageData.sortOrder
    ]);

    // SummaryTag data
    useEffect(() => {
        try {
            if (fetched_details.pages?.summaryDataTagged?.data !== undefined && fetched_details.pages?.summaryDataTagged?.result === 'success' &&
                fetched_details.pages?.summaryDataTagged?.requestUUID === request_uuid.value) {
                setPageTagData((prevState: any) => {
                    return {
                        ...prevState,
                        loadPageSummaryTable: true,
                        pageContributionDataFetched: true
                    }
                })
                apiCatchError(0, 0);
                enableExportPdf(0, true);
            } else if (fetched_details.pages?.summaryDataTagged?.result === 'retry') {
                getPageTableData("tagged", 'retry');
            } else if (fetched_details.pages?.summaryDataTagged?.result === 'error') {
                setPageTagData((prevState: any) => {
                    return {
                        ...prevState,
                        loadPageSummaryTable: true,
                        pageContributionDataFetched: true
                    }
                })
                apiCatchError(0, 1);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.pages.summaryDataTagged])

    // SummaryUnTagged data
    useEffect(() => {
        try {
            if (fetched_details?.pages?.summaryDataUntagged?.data !== undefined && fetched_details?.pages?.summaryDataUntagged?.result === 'success' &&
                fetched_details.pages?.summaryDataUntagged?.requestUUID === request_uuid.value) {
                setPageTagData((prevState: any) => {
                    return {
                        ...prevState,
                        loadPageSummaryTable: true,
                        pageUntaggedContributionDataFetched: true
                    }
                })

                apiCatchError(0, 0);
                enableExportPdf(0, true);

            } else if (fetched_details.pages?.summaryDataUntagged?.result === 'retry') {
                getPageTableData("untagged", 'retry');
            } else if (fetched_details?.pages?.summaryDataUntagged?.result === 'error') {
                setPageTagData((prevState: any) => {
                    return {
                        ...prevState,
                        loadPageSummaryTable: true,
                        pageUntaggedContributionDataFetched: true
                    }
                })
                apiCatchError(0, 1);

            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.pages.summaryDataUntagged])

    // BlockChart
    useEffect(() => {
        try {
            if (fetched_details?.pages?.blockChartData?.data !== undefined && fetched_details?.pages?.blockChartData?.result === 'success' &&
                fetched_details.pages?.blockChartData?.requestUUID === request_uuid.value) {
                setBlockChart((prevState: any) => {
                    return {
                        ...prevState,
                        chartDataFetched: true,
                    }
                })
                apiCatchError(2, 0);
                enableExportPdf(2, true);

            } else if (fetched_details?.pages?.blockChartData?.result === 'retry') {
                getBlockChartData('retry');
            } else if (fetched_details?.pages?.blockChartData?.result === 'error') {
                setBlockChart((prevState: any) => {
                    return {
                        ...prevState,
                        chartDataFetched: true,
                    }
                })
                apiCatchError(2, 1);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.pages.blockChartData])


    // UsageData
    useEffect(() => {
        try {

            if (fetched_details?.pages?.usageData?.data !== undefined && fetched_details?.pages?.usageData?.result === 'success' &&
                fetched_details.pages?.usageData?.requestUUID === request_uuid.value) {
                setUsageData((prevState: any) => {
                    return {
                        ...prevState,
                        pieChartDataFetched: true,
                        usageTableDataFetched: true,
                    }
                })
                apiCatchError(1, 0);
                enableExportPdf(1, true);
            } else if (fetched_details?.pages?.blockChartData?.result === 'retry') {
                getUsageStats(request_uuid.value, "count", "asc", 0, fetched_details?.commonComponentData.datesData.fdate, fetched_details?.commonComponentData.datesData.tdate, 'retry');
            } else if (fetched_details?.pages?.usageData?.result === 'error') {
                setUsageData((prevState: any) => {
                    return {
                        ...prevState,
                        pieChartDataFetched: true,
                        usageTableDataFetched: true,
                    }
                })
                apiCatchError(1, 1);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.pages.usageData])

    // Export PDF btn
    useEffect(() => {
        try {
            if (loaderBtn.loader0 && loaderBtn.loader1 && loaderBtn.loader2) {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: false,
                    };
                });
            } else {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: true,
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [loaderBtn.loader0, loaderBtn.loader1, loaderBtn.loader2])

    // Api calls on App, Date change
    useEffect(() => {
        try {
            // fetched_details?.commonComponentData.datesData.dateSelection = 15 (Custom Date option)
            if (fetched_details?.commonComponentData.datesData?.url?.includes('pages/') || fetched_details?.commonComponentData.datesData.dateSelection === 15) {
                const reqUUID = uuidv4();
                setUuid((prevState: any) => {
                    return {
                        ...prevState,
                        value: reqUUID
                    }
                })

                setBlockChart((prevState: any) => {
                    return {
                        ...prevState,
                        chartDataFetched: false
                    }
                })
                setUsageData((prevState: any) => {
                    return {
                        ...prevState,
                        pieChartDataFetched: false,
                        usageTableDataFetched: false,
                        firstLoadUsage: true,
                        sortBy: "count",
                        sortOrder: "asc",
                    }
                })
                setPageTagData((prevState: any) => {
                    return {
                        ...prevState,
                        showTagged: true,
                        loadPageSummaryTable: false,
                        pageContributionDataFetched: false,
                        searchQuery: "",
                        page: 1,
                        filterLabels: [],
                        sortKey: "page_views",
                        sortValue: "desc",
                        taggedFlag: true,
                        firstLoad: true
                    };
                })
                getBlockChartData(reqUUID);

                getUsageStats(reqUUID, "count", "asc", 0, fetched_details?.commonComponentData.datesData.fdate, fetched_details?.commonComponentData.datesData.tdate);
                // getPageTableData("tagged");

                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        loader0: false,
                        loader1: false,
                        loader2: false,
                        disabled: true
                    }
                })
            }
            if (urlData?.tag_page !== null && urlData?.tag_page !== undefined && urlData?.tag_page !== '' &&
                (urlData?.tag_page === '1' || urlData?.tag_page === 1)) {
                navigationFn(1)
            }
            else {
                navigationFn(0)
            }

        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.commonComponentData.datesData, fetched_details?.languageData.languageData])



    // On url parameter change
    useEffect(() => {
        try {
            if (parameter !== '') {
                let queryParams = {

                    app_id: fetched_details?.commonComponentData.appsData.appId,
                    app_code: fetched_details?.commonComponentData.appsData.appCode,
                    fdate: fetched_details?.commonComponentData.datesData.fdate,
                    tdate: fetched_details?.commonComponentData.datesData.tdate,
                }

                navigate({
                    pathname: '/app-insights/page-detail/' + parameter + '/',
                    search: qs.stringify(queryParams),
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [parameter])

    useEffect(() => {
        try {
            const result = errorCount.errorCount.every((val) => val === 1);
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    showMaintenance_flag: result
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }, [errorCount.errorCount])

    const handleShowMeSwitch = (e: any) => {
        try {
            setPageTab((prevState: any) => {
                return {
                    ...prevState,
                    key: e
                };
            });

            if (e == 1) {
                switchTab("tagged")
            } else {
                switchTab("untagged")
            }
        } catch (error) {
            showBoundary(error)
        }

    };

    const TagData = () => {
        try {
            return (
                <>
                    {pageTagData.loadPageSummaryTable ? (
                        errorCount.errorCount[0] === 0 ? (
                            <div className={(pageTagData.showTagged ? "tabelEditing commonSummaryTable pageTable" : "commonSummaryTable pageTable untaggedTable")}>
                                <CommonTable
                                    tableId={(pageTagData.showTagged ? "1" : "2")}
                                    data={
                                        pageTagData.showTagged
                                            ? fetched_details?.pages?.summaryDataTagged?.data
                                            : fetched_details?.pages?.summaryDataUntagged?.data
                                    }
                                    currentPage={pageTagData.page}
                                    barColors=""
                                    linkPresent={pageTagData.showTagged}
                                    showExport={false}
                                    showSearch={true}
                                    showEntries={false}
                                    defaultSort={pageTagData.defaultSort}
                                    tablePagination={pageTagData.tablePagination}
                                    tableEvent={handleTableEvents}
                                    firstTimeloadFlag={
                                        pageTagData.showTagged
                                            ? fetched_details?.pages?.summaryDataTagged?.data?.firstTimeload
                                            : fetched_details?.pages?.summaryDataUntagged?.data?.firstTimeload
                                    }
                                    modalTable={false}
                                />
                            </div>
                        ) : errorCount.errorCount[0] === 1 ? (
                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                {insightsLbls.serviceNotAvailable}
                            </h5>
                        ) : null
                    ) : (
                        <div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
                            <div className="displayFlex alignCenter margin-0-auto">
                                <Loader></Loader>
                            </div>
                        </div>
                    )}
                </>
            )
        } catch (error) {
            showBoundary(error)
        }
    }

    return (
        <section className="demo pageSection width100 pageComponent" ref={componentRef}>
            {/* Breadcrumb */}
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <SectionHeader>
                        <li className="active">{insightsLbls.pages}</li>
                    </SectionHeader>
                </div>

                <SectionFilters>
                    <Datepicker source={'component'} />
                </SectionFilters>
            </div>


            {/* <SectionButtons sectionTitle={insightsLbls.pages} svgImage={"pages"} className={'marginTop-50'}>
                <ExportToPdf
                    componentRef={componentRef}
                    source={"Pages"}
                    widthDividedBy={12}
                    disabled={loaderBtn.disabled}
                ></ExportToPdf>
            </SectionButtons> */}

            <div className="marginTop-60 exportBtn-div">
                <Dropdown as={ButtonGroup}>
                    <Button variant="info">{insightsLbls.export}</Button>
                    <Dropdown.Toggle split variant="success" id="dropdown-custom-2" />
                    <Dropdown.Menu className="super-colors">
                        <Dropdown.Item eventKey="1" >
                            <ExportToPdf
                                componentRef={componentRef}
                                source={"Pages"}
                                widthDividedBy={12}
                                disabled={loaderBtn.disabled}
                            ></ExportToPdf>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <UserInfoSection
                startDate={fetched_details?.commonComponentData?.datesData.fdate}
                endDate={fetched_details?.commonComponentData?.datesData.tdate}
                dateObject={fetched_details?.commonComponentData?.datesData}
                messageText={insightsLbls.pages}>
            </UserInfoSection>

            <div id="pageSection">

                {/* {
                    <SectionRefresh sectionTitle={insightsLbls.pageSummaryOverview} refreshClass={"marginTop-30"}>
                        <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls.refresh} onClick={() => refreshSections(0, fetched_details?.commonComponentData.datesData.fdate, fetched_details?.commonComponentData.datesData.tdate)}></i>
                    </SectionRefresh>
                } */}

                <div className="">
                    <div className="dashboardTiles card section-wrapper borderTop borderBottom8 col-lg-12 page_section padding0 height535 blockImportant">
                        <CardContainer
                            div1Classes={"col-md-12 col-xs-12 table-container padding0 rightBorder"}
                            div2Classes={
                                "marginTop-0 minHeight500 icon-text chart-wrapper5 borderTop height100-percent"
                            }
                            div3Classes={
                                "col-sm-12 cardPadding height100-percent positionRelative displayGrid"
                            }
                        >
                            <ContainerTooltip
                                div1Classes={""}
                                div2Classes={""}
                                hTagClasses={"section-heading headingText"}
                            >
                                {pageTagData.showTagged ? (
                                    <SectionTooltip tooltipText={
                                        {
                                            "subTitle": insightsLbls.pageStats,
                                            "tooltipText": "",
                                            "childern": [
                                                {
                                                    "key": insightsLbls.pageName + ":",
                                                    "value": insightsLbls.noOfPages
                                                },
                                                {
                                                    "key": insightsLbls.labels + ":",
                                                    "value": insightsLbls.labelsFilterTaggedPages
                                                },
                                                {
                                                    "key": insightsLbls.pageView + ":",
                                                    "value": insightsLbls.numberTimesPageViewed
                                                },
                                                {
                                                    "key": insightsLbls.uniqueVisits + ":",
                                                    "value": insightsLbls.numberUniqueVisitorPage
                                                },
                                                {
                                                    "key": insightsLbls.createdBy + ":",
                                                    "value": insightsLbls.createdByText
                                                },
                                                {
                                                    "key": insightsLbls.lastUpdated + ":",
                                                    "value": insightsLbls.lastUpdatedTimespan
                                                }
                                            ]
                                        }
                                    } placement="right"></SectionTooltip>
                                ) : (
                                    <SectionTooltip tooltipText={
                                        {
                                            "subTitle": insightsLbls.pageStats,
                                            "tooltipText": "",
                                            "childern": [
                                                {
                                                    "key": insightsLbls.completeURL + ":",
                                                    "value": insightsLbls.completeURLText
                                                },
                                                {
                                                    "key": insightsLbls.URLVisits + ":",
                                                    "value": insightsLbls.URLVisitsText
                                                },
                                                {
                                                    "key": insightsLbls.uniqueUserVisits + ":",
                                                    "value": insightsLbls.uniqueUserVisitsText
                                                },
                                                {
                                                    "key": insightsLbls.associatedPageTags + ":",
                                                    "value": insightsLbls.associatedPageTagsText
                                                }
                                            ]
                                        }
                                    } placement="right"></SectionTooltip>
                                )

                                }
                                <button className="pointer refreshBtn" onClick={() => refreshSections(0, fetched_details?.commonComponentData.datesData.fdate, fetched_details?.commonComponentData.datesData.tdate)}> {insightsLbls.refresh}</button>

                            </ContainerTooltip>
                            <p className="warningMsg-text-pages">
                                {insightsLbls.pageTagInfo}
                            </p>

                            <div className="createTab-div">
                                <button
                                    id="btn_new"
                                    className="floatRight createPage-btn-square createPageTag-btn btn-primary"
                                    onClick={() => handleShow()}
                                // disabled={domainCount > 0 ? false : true}
                                >
                                    <span>
                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                        {insightsLbls.create} {insightsLbls.tag}
                                    </span>
                                </button>
                            </div>



                            <Tabs
                                activeKey={pageTab.key}
                                onSelect={handleShowMeSwitch}
                                id="showMe-tabs"
                                className="marginTop-30">
                                <Tab eventKey={1} title={insightsLbls.tagged}>

                                    <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                                        {
                                            pageTagData.loadPageSummaryTable ? (
                                                errorCount.errorCount[0] === 0 ? (
                                                    <div className=" commonSummaryTable">
                                                        <CommonTable tableId="1"

                                                            data={fetched_details?.pages?.summaryDataTagged?.data}
                                                            currentPage={pageTagData.page}
                                                            barColors=""
                                                            linkPresent={pageTagData.showTagged}
                                                            showExport={false}
                                                            showSearch={true}
                                                            showEntries={false}
                                                            defaultSort={pageTagData.defaultSort}
                                                            tablePagination={pageTagData.tablePagination}
                                                            tableEvent={handleTableEvents}
                                                            firstTimeloadFlag={fetched_details?.pages?.summaryDataTagged?.data?.firstTimeload}
                                                            modalTable={false}

                                                        />
                                                    </div>
                                                )
                                                    : errorCount.errorCount[0] === 1 ? (
                                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                                            {insightsLbls.serviceNotAvailable}
                                                        </h5>
                                                    )
                                                        : null)
                                                : (
                                                    <div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
                                                        <div className="displayFlex alignCenter margin-0-auto">
                                                            <Loader></Loader>
                                                        </div>
                                                    </div>
                                                )
                                        }
                                    </div>
                                </Tab>
                                <Tab eventKey={2} title={insightsLbls.rawURLActivities}>
                                    <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                                        {
                                            pageTagData.loadPageSummaryTable ? (
                                                errorCount.errorCount[0] === 0 ? (
                                                    <div className=" commonSummaryTable">
                                                        <CommonTable tableId="2"

                                                            data={
                                                                pageTagData.showTagged
                                                                    ? fetched_details?.pages?.summaryDataTagged?.data
                                                                    : fetched_details?.pages?.summaryDataUntagged?.data
                                                            }
                                                            currentPage={pageTagData.page}
                                                            barColors=""
                                                            linkPresent={pageTagData.showTagged}
                                                            showExport={false}
                                                            showSearch={true}
                                                            showEntries={false}
                                                            defaultSort={pageTagData.defaultSort}
                                                            tablePagination={pageTagData.tablePagination}
                                                            tableEvent={handleTableEvents}
                                                            firstTimeloadFlag={
                                                                pageTagData.showTagged
                                                                    ? fetched_details?.pages?.summaryDataTagged?.data?.firstTimeload
                                                                    : fetched_details?.pages?.summaryDataUntagged?.data?.firstTimeload
                                                            }
                                                            modalTable={false}

                                                        />
                                                    </div>
                                                )
                                                    : errorCount.errorCount[0] === 1 ? (
                                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                                            {insightsLbls.serviceNotAvailable}
                                                        </h5>
                                                    )
                                                        : null)
                                                : (
                                                    <div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
                                                        <div className="displayFlex alignCenter margin-0-auto">
                                                            <Loader></Loader>
                                                        </div>
                                                    </div>
                                                )
                                        }
                                    </div>
                                </Tab>
                            </Tabs>
                        </CardContainer>

                        {/* <CardContainer
                            div1Classes={"col-md-3 col-xs-12 table-section leftBorder"}
                            div2Classes={
                                "icon-text borderTop  chart-wrapper-showme minHeight500 height100-percent"
                            }
                            div3Classes={"minHeight500"}
                        >
                            <ContainerTooltip
                                div1Classes={"col-sm-12 page-data-div positionRelative"}
                                div2Classes={""}
                                hTagClasses={" section-heading headingText"}
                            >
                                {pageTagData.taggedFlag
                                    ? <SectionTooltip tooltipText={
                                        {
                                            "subTitle": insightsLbls.pageDistribution,
                                            "tooltipText": insightsLbls.chartRepresentingPage,
                                            "childern": [
                                                {
                                                    "key": insightsLbls.taggedPages + ":",
                                                    "value": insightsLbls.percentageContribution
                                                }
                                            ]
                                        }
                                    } placement="left"></SectionTooltip>
                                    : <SectionTooltip tooltipText={
                                        {
                                            "subTitle": insightsLbls.urlContibution,
                                            "tooltipText": insightsLbls.chartURLActivity,
                                            "childern": [
                                                {
                                                    "key": insightsLbls.rawURLActivitiesURL,
                                                    "value": insightsLbls.comparisonTaggedUntagged
                                                }
                                            ]
                                        }
                                    } placement="left"></SectionTooltip>}
                            </ContainerTooltip>

                            <CardContainer
                                div1Classes={"card-body width-100"}
                                div2Classes={"col-sm-12 nopadding graphWidth"}
                                div3Classes={"positionRelative"}
                            >
                                {pageTagData.pageContributionDataFetched ? (
                                    errorCount.errorCount[0] === 0 ? (
                                        pageTagData.taggedFlag ? (
                                            <PageTaggedChart
                                                totalCount={
                                                    fetched_details.pages.summaryDataTagged.data
                                                        .totalTaggedPages
                                                }
                                                percentClick={
                                                    fetched_details.pages.summaryDataTagged.data
                                                        .pagesWith80PercentViews
                                                }
                                                totalTaggedPages={fetched_details.pages.summaryDataTagged.data.totalTaggedPages}
                                            ></PageTaggedChart>
                                        ) : pageTagData.pageUntaggedContributionDataFetched ? (
                                            errorCount.errorCount[0] === 0 ? (
                                                pageTagData.taggedFlag ? null : (
                                                    <PageUntaggedChart
                                                        taggedCount={fetched_details.pages.summaryDataUntagged.data.taggedCount
                                                        }
                                                        untaggedCount={fetched_details.pages.summaryDataUntagged.data.untaggedCount
                                                        }
                                                    ></PageUntaggedChart>
                                                )
                                            ) : (
                                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                                    {insightsLbls.serviceNotAvailable}
                                                </h5>
                                            )
                                        ) : (
                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight500">
                                                <div className="displayFlex alignCenter margin-0-auto">
                                                    <Loader></Loader>
                                                </div>
                                            </div>
                                        )
                                    ) : (
                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                            {insightsLbls.serviceNotAvailable}
                                        </h5>
                                    )
                                ) : (
                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight500">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader></Loader>
                                        </div>
                                    </div>
                                )}
                            </CardContainer>
                        </CardContainer> */}
                    </div>
                </div>



                {
                    // <SectionRefresh sectionTitle={insightsLbls.pageStats} refreshClass={"marginTop-30"}>
                    //     <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls.refresh} onClick={() => refreshSections(2, fetched_details?.commonComponentData.datesData.fdate, fetched_details?.commonComponentData.datesData.tdate)}></i>
                    // </SectionRefresh>
                }
                <CardContainer
                    div1Classes={"col-sm-12 page_section padding0 marginTop-20"}
                    div2Classes={
                        "card icon-text chart-wrapper5 section-wrapper borderTop marginTop-0 width100"
                    }
                    div3Classes={"card-body page-view-card padding125rem"}
                >
                    <ContainerTooltip
                        div1Classes={""}
                        div2Classes={"col-lg-12 col-md-12 col-sm-12 col-12"}
                        hTagClasses={"section-heading drop-off-funnel headingText"}
                    >
                        {
                            <SectionTooltip tooltipText={
                                {
                                    "subTitle": insightsLbls.pageAcivity,
                                    "tooltipText": insightsLbls.blockDiagramTop50Pages1,
                                    "childern": []
                                }
                            } placement="right"></SectionTooltip>
                        }
                        <button className="pointer refreshBtn" onClick={() => refreshSections(2, fetched_details?.commonComponentData.datesData.fdate, fetched_details?.commonComponentData.datesData.tdate)}> {insightsLbls.refresh}</button>

                    </ContainerTooltip>
                    <div className="minHeight150">
                        <div className="col-sm-12 marginTop-30 detailedGraph minHeight150 height450">
                            {blockChart.chartDataFetched ? (
                                errorCount.errorCount[2] === 0 ? (
                                    fetched_details?.pages?.blockChartData?.data?.pageDetails?.children?.length === 0 ? (
                                        <div
                                            id="noBubbleData"
                                            className="noDataText-font textCenter-absolute"
                                        >
                                            {insightsLbls.noRecordFound}
                                        </div>
                                    ) : (
                                        <Blockchart
                                            blockData={
                                                fetched_details?.pages?.blockChartData?.data
                                                    .pageDetails
                                            }
                                        ></Blockchart>
                                    )
                                ) : (
                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                        {insightsLbls.serviceNotAvailable}
                                    </h5>
                                )
                            ) : (
                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <Loader></Loader>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </CardContainer>


                <CardContainer
                    div1Classes={"col-sm-12 page_section padding0 marginTop-20"}
                    div2Classes={
                        "card icon-text chart-wrapper5 section-wrapper borderTop marginTop-0 width100"
                    }
                    div3Classes={"card-body page-view-card padding125rem"}
                >
                    <ContainerTooltip
                        div1Classes={""}
                        div2Classes={"col-lg-12 col-md-12 col-sm-12 col-12"}
                        hTagClasses={"section-heading drop-off-funnel headingText"}
                    >
                        {
                            <SectionTooltip tooltipText={
                                {
                                    "subTitle": insightsLbls.pageAcivity,
                                    "tooltipText": insightsLbls.blockDiagramTop50Pages1,
                                    "childern": []
                                }
                            } placement="right"></SectionTooltip>
                        }
                        <button className="pointer refreshBtn" onClick={() => refreshSections(1, fetched_details?.commonComponentData.datesData.fdate, fetched_details?.commonComponentData.datesData.tdate)}> {insightsLbls.refresh}</button>

                    </ContainerTooltip>
                    <div className=" margin-0-Auto statCards marginTop-20" id="user_stats">
                    <div className="col-lg-12 padding0 displayContent">
                        <React.Fragment>
                            {/* <SectionRefresh sectionTitle={insightsLbls.pageAcivity} refreshClass={"marginTop-30"}>
                                <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls.refresh} onClick={() => refreshSections(1, fetched_details?.commonComponentData.datesData.fdate, fetched_details?.commonComponentData.datesData.tdate)}></i>
                            </SectionRefresh> */}
                            {usageData.pieChartDataFetched &&
                                usageData.usageTableDataFetched ? (

                                <>

                                    <ContainerTooltip
                                        div1Classes={"graph-title displayFlex paddingTop-10 paddingLeft-20"}
                                        div2Classes={""}
                                        hTagClasses={"section-heading drop-off-funnel headingText"}
                                    >
                                        {
                                            <SectionTooltip tooltipText={
                                                {
                                                    "subTitle": insightsLbls.webBrowser,
                                                    "tooltipText": insightsLbls.webBrowserTooltip,
                                                    "childern": []
                                                }
                                            } placement="right"></SectionTooltip>
                                        }
                                        
                                    </ContainerTooltip>
                                    <BrowserStats
                                        statsData={fetched_details.pages.usageData.data}
                                        calUsageData={getSortEvent}
                                        refreshSection={refreshBrowser}
                                        refreshSectionOption={1}
                                        fdate={fetched_details?.commonComponentData.datesData.fdate}
                                        tdate={fetched_details?.commonComponentData.datesData.tdate}
                                        serviceAvailable={errorCount.errorCount[1]}
                                        type="browser"
                                    ></BrowserStats>


                                    <ContainerTooltip
                                        div1Classes={"graph-title displayFlex paddingTop-10 paddingLeft-20"}
                                        div2Classes={""}
                                        hTagClasses={"section-heading drop-off-funnel headingText row"}
                                    >
                                        {
                                            <SectionTooltip tooltipText={
                                                {
                                                    "subTitle": insightsLbls.operatingSystem,
                                                    "tooltipText": insightsLbls.osTooltip,
                                                    "childern": []
                                                }
                                            } placement="right"></SectionTooltip>
                                        }
                                    </ContainerTooltip>
                                    <BrowserStats
                                        statsData={fetched_details.pages.usageData.data}
                                        calUsageData={getSortEvent}
                                        refreshSection={refreshBrowser}
                                        refreshSectionOption={1}
                                        fdate={fetched_details?.commonComponentData.datesData.fdate}
                                        tdate={fetched_details?.commonComponentData.datesData.tdate}
                                        serviceAvailable={errorCount.errorCount[1]}
                                        type="os"
                                    ></BrowserStats>



                                    <ContainerTooltip
                                        div1Classes={"graph-title displayFlex paddingTop-10 paddingLeft-20"}
                                        div2Classes={""}
                                        hTagClasses={"section-heading drop-off-funnel headingText row"}
                                    >
                                        {
                                            <SectionTooltip tooltipText={
                                                {
                                                    "subTitle": insightsLbls.screenResolution,
                                                    "tooltipText": insightsLbls.screenResolutionTooltip,
                                                    "childern": []
                                                }
                                            } placement="right"></SectionTooltip>
                                        }
                                    </ContainerTooltip>
                                    <BrowserStats
                                        statsData={fetched_details.pages.usageData.data}
                                        calUsageData={getSortEvent}
                                        refreshSection={refreshBrowser}
                                        refreshSectionOption={1}
                                        fdate={fetched_details?.commonComponentData.datesData.fdate}
                                        tdate={fetched_details?.commonComponentData.datesData.tdate}
                                        serviceAvailable={errorCount.errorCount[1]}
                                        type="screen_resolution"
                                    ></BrowserStats>
                                </>

                            ) : <BrowserStats
                                loading
                            ></BrowserStats>}
                        </React.Fragment>
                    </div>
                </div>
                </CardContainer>

                




            </div>

            <Modal
                show={rulesModal.show_rules_modal}
                onHide={handleClose}
                size={'lg'}
                backdrop={false}
            >
                <Modal.Header>
                    <Modal.Title>{insightsLbls.tagPage}</Modal.Title>
                    <button type="button" className="close" onClick={handleClose}>
                        <span aria-hidden="true"><Icon img="img/close-icon-large.svg"></Icon></span>
                        <span className="sr-only">{insightsLbls.close}</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {domainCount > 0
                        ? (<div>
                            <div className="col-lg-12 pageTitle-div allowEdit">
                                <span className="tagModalText">
                                    {insightsLbls.name}<span className="requiredStar">*</span>
                                </span>
                                <div className="width100 displayFlex">
                                    <input
                                        className="width100 padding5 modalTitleEdit"
                                        id="createPageTitle"
                                        placeholder={insightsLbls.nameTagOnPage}
                                        autoComplete="off"
                                        onInput={(event) => onTagTitle(event)}
                                        defaultValue={rulesModal.pageTagTitle ?? ''}
                                    />
                                </div>
                                <div className="width100 marginTop-15">
                                    <span className="tagModalText">
                                        {insightsLbls.description}</span>
                                    <textarea
                                        name="description"
                                        placeholder={insightsLbls.describeTagOnPage}
                                        id="createPageDescription"
                                        className="modalTitleEdit width100"
                                        maxLength={500}
                                        onInput={(event) => onTagDesc(event)}
                                    ></textarea>
                                </div>
                                <div className="width100 marginTop-15">
                                    <span className="tagModalText">{insightsLbls.label} </span>
                                    {rulesModal.labelFlag ? (
                                        <Label
                                            labeldata={rulesModal.labelData}
                                            getLabelData={getLabelData}
                                        ></Label>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-lg-12 overflowAuto height300 paddingTB-20">
                                <div className="font14 ruleOuterDiv">
                                    <div className="toast-left-50-perct">
                                        <Rules
                                            ruleEvent={handleRuleEvents}
                                            ruleData={getRuleData}
                                        ></Rules>
                                    </div>
                                </div>
                            </div>
                        </div>)
                        : (<div className="col-lg-12 noDomainWarning">
                            {insightsLbls.domainMessage}
                        </div>)}
                </Modal.Body>
                <Modal.Footer>
                    <div className="formButtons paddingLR-25 width100">
                        <button
                            className="btn-secondary btn  modal-cancel-btn floatRight marginLeft-10"
                            onClick={() => handleClose()}>
                            {insightsLbls.cancel}
                        </button>
                        <button
                            className="btn btn-primary validateBtn"
                            disabled={rulesModal.rulesSave}
                            onClick={() => submitTag()}>
                            {insightsLbls.save}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>

            <div className="overlayCursor" id="overlayCursor"></div>
            {errorCount.showMaintenance_flag && (
                <Maintenance></Maintenance>
            )}
        </section>
    );
}



export default withRouter(withReducer("pages", pageReducer)(Pages));
