import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { ReactSortable } from "react-sortablejs";
import { getCall } from "../../utils/ApiCallActions";
import { useSelector } from "react-redux";
import { LanguageState } from "store/Reducers";
import $ from 'jquery';
import { cloneDeep } from "lodash";
import HorizontalBarChart from "../Chart/HorizontalBarChart";
import { useErrorBoundary } from 'react-error-boundary';

import './Funnel.scss';
import { InsightLabels } from "labels";
import Icon from "components/Icon/Icon";
import { RetryApi } from "utils/GlobalConstants";

interface CurrentState {
    languageData: LanguageState
}
interface Props {
    stepsList: any[],
    apiParams: any,
    apiPath: string,
    component: string,
    listTitle: string,
}

const FunnelComponent = (props: Props) => {
    let fetched_details = useSelector((state: CurrentState) => state);
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)


    let myFormRef: any = useRef(null) as React.RefObject<HTMLDivElement>;

    const [funnelModalData, setFunnelModalData] = useState({
        counter: 0,
        _stepsLimit: false,
        invalid_form: true,
        submitFlag: false,
        funnel_steps_array: [],
        interested_val: [],
        stepIds: '',
        fetchFunnelData: false,
        stepTitle: [],
        stepName: [],
        oldStepIndex: 0
    })

    const [funnelChartData, setFunnelChartData] = useState({
        barChartData: [],
        showBarChart: false,
    });

    const [openFunnelModal, setFunnelModal] = useState(false);

    const [sortedListFlag, setSortedListFlag] = useState(false);


    const [funnelSortedList, setFunnelSortedList] = useState<any[]>([]);

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    const openFunnelStepsList = () => {
        try {
        } catch (error) {
            showBoundary(error)
        }
        setFunnelSortedList([]);
        setFunnelModal(true)
        if (funnelModalData.stepTitle.length === 0) {


            setFunnelModalData((prevState: any) => {
                return {
                    ...prevState,
                    invalid_form: true,
                }
            })
        } else {

            setFunnelModalData((prevState: any) => {
                return {
                    ...prevState,
                    invalid_form: true,
                }
            })
        }

        // setTimeout(() => {
        //     //   ($("#stpes_selectedOptns") as any).sortable({
        //     //     update: (e: any, ui: any) => {
        //     //       $("#stpes_selectedOptns li").each((i, elm) => {
        //     //         a = $(elm); 
        //     //         let array : any = cloneDeep(stepName);
        //     //         array[i] = a;
        //     //         setFunnelModalData({
        //     //             ...funnelModalData,
        //     //             stepName: array
        //     //         })
        //     //       });
        //     //     }
        //     //   });
        //     //   ($("#stpes_selectedOptns") as any).disableSelection();
        // }, 10);

        if (funnelModalData.submitFlag) {
            setSortedListFlag(false);

            $('#stpes_selectedOptns').append(funnelModalData.stepName);
            setFunnelSortedList([]);
            setTimeout(() => {
                let invalid_form: boolean;

                funnelModalData.counter = funnelModalData.funnel_steps_array.length;
                if (funnelModalData.counter === 0) {
                    invalid_form = true;
                } else {
                    invalid_form = false;
                }



                setFunnelModalData((prevState: any) => {
                    return {
                        ...prevState,
                        stepName: [],
                    }
                })

                let stepNameNew: any = [];
                // $('#stpes_selectedOptns').html("");
                $('.check-container input:checkbox').removeAttr('checked');

                setFunnelSortedList([...funnelSortedList, {

                }])

                for (let i = 0; i < funnelModalData.funnel_steps_array.length; i++) {

                    let arrElem: any = funnelModalData.funnel_steps_array[i];
                    $('#' + arrElem.value).prop('checked', true);

                    let a = {
                        id: arrElem.id,
                        value: arrElem.value,
                        label: arrElem.text
                    }
                    stepNameNew.push(a);
                    // var a = '<li className="li-item" value="' + arrElem.value + '" id="' + arrElem.id + '"><i className="fa fa-sort li-item-icon" aria-hidden="true"></i>' + arrElem.text + '</li>';
                    // $("#stpes_selectedOptns").append(a);
                    // stepNameNew.push($(a));
                }

                // setFunnelModalData((prevState: any) => {
                //     return {
                //         ...prevState,
                //         counter: counter,
                //         _stepsLimit: _stepsLimit,
                //         invalid_form: invalid_form,
                //     }
                // })

                setFunnelSortedList(stepNameNew)
                setSortedListFlag(true);


                setFunnelModalData((prevState: any) => {
                    return {
                        ...prevState,
                        invalid_form: invalid_form,
                        stepName: stepNameNew,
                        counter: stepNameNew.length
                    }
                })
            }, 10);
        } else {
            setFunnelModalData((prevState: any) => {
                return {
                    ...prevState,
                    stepName: [],
                }
            })
        }
    }



    useEffect(() => {

    }, [funnelModalData.counter])

    const checkStep = (event: any, i: number, stepId: number) => {
        try {
        } catch (error) {
            showBoundary(error)
        }
        let counter = 0;
        let invalid_form = false;
        let _stepsLimit = false;
        counter = funnelModalData.counter;

        if (event.target.checked === true) {
            if (counter < 7) {
                counter++;
                _stepsLimit = false;
                stepClick(event.currentTarget, i, stepId);
            } else {
                event.target.checked = false;
                _stepsLimit = true;

            }
        } else {
            counter--;
            _stepsLimit = false;
            stepClick(event.currentTarget, i, stepId)
        }

        if (counter >= 3 && counter <= 7) {
            invalid_form = false;
        } else {
            invalid_form = true;
        }



        setFunnelModalData((prevState: any) => {
            return {
                ...prevState,
                counter: counter,
                _stepsLimit: _stepsLimit,
                invalid_form: invalid_form,
            }
        })

    }

    let selectedSteps: any;
    const stepClick = (event: any, i: number, stepId: number) => {
        try {
        } catch (error) {
            showBoundary(error)
        }
        let stepName: any = funnelModalData.stepName;
        var res: any = event.value.split(",");


        if (event.checked) {

            // $('#stpes_selectedOptns').html("");
            // var a = '<li className="li-item" value="' + res[0] + '" id="' + res[2] + '"><i className="fa fa-sort li-item-icon" aria-hidden="true"></i>' + res[1] + '</li>';

            setFunnelSortedList([...funnelSortedList, {
                id: res[2],
                value: parseInt(res[0]),
                label: res[1]
            }])

            setSortedListFlag(true);

        } else {

            const newList = funnelSortedList.filter((item) =>
                item.value !== stepId
            );

            setFunnelSortedList(newList);
            // $("#stpes_selectedOptns li").each((i) => {
            //     if (res[2] === stepName[i].attr("id")) {
            //         $('#stpes_selectedOptns').html("");
            //         stepName.splice(i, 1);
            //         return false;
            //     }
            // });
        }


        $('#stpes_selectedOptns').append(stepName);

        setFunnelModalData((prevState: any) => {
            return {
                ...prevState,
                stepName: stepName
            }
        })
        // setStepName(stepName);
    }

    // useEffect(() => {
    // }, [funnelSortedList]);


    const submit = (visualizeFunnel = (stepIds: string, apiErrorFlag?: string) => {
        try {
            var parameters = cloneDeep(props.apiParams);

            parameters.step_id = stepIds.replace('&step_id=', '')


            setFunnelChartData({
                ...funnelChartData,
                showBarChart: false
            });

        } catch (error) {
            showBoundary(error)
        }



        getCall(parameters, props.apiPath, apiErrorFlag ?? '').then((data: any) => {
            try {
                if (data.result === 'success') {
                    let cnt = 0;

                    let barData: any = [];
                    if (props.component === 'workflow' || props.component === 'dynamicWorkflow' || props.component === 'combinedWorkflows') {
                        barData = data.data.workflowDetails;

                    } else {
                        barData = data.data.guideDetails;
                    }


                    var linedata: any = [{
                        key: "Step Name",
                        // color: barData.map(function (d: any, i: any) {

                        //     return colors[i];
                        // }),
                        values: barData.map(function (d: any, i: any) {
                            cnt++;
                            // setFunnelChartData({
                            //     ...funnelChartData,
                            //     labelName
                            // });
                            setFunnelChartData({
                                ...funnelChartData,
                            })
                            return { label: d[0] + "stepIndex" + cnt, value: d[1] };
                        })
                    }];
                    if (barData.length !== 0) {

                        setFunnelChartData({
                            ...funnelChartData,
                            barChartData: linedata,
                            showBarChart: true
                        });
                    }
                    else {
                        setFunnelChartData({
                            ...funnelChartData,
                            barChartData: [],
                            showBarChart: true
                        });
                    }
                    setFunnelModal(false);
                } else if (data.result === 'retry') {
                    setTimeout(() => {
                        visualizeFunnel(funnelModalData.stepIds, 'retry')
                    }, RetryApi.TIMEOUT)

                }
            } catch (error) {
                showBoundary(error)
            }

        });

    }) => {
        try {
            const selectedID = [];
            let step_array: any = [];

            let funnel_steps_array: any;
            funnel_steps_array = [];

            let funnelStep_id, funnelStep_value, funnelStep_text;
            $(".li-item").each(function () {
                funnelStep_value = $(this).val();
                funnelStep_id = $(this).attr('id');
                funnelStep_text = $(this).text();
                funnel_steps_array.push({ value: funnelStep_value, id: funnelStep_id, text: funnelStep_text });
            });

            $(".li-item").each(function (index) {
                step_array[index] = $(this).val()
            });

            for (let i = 0; i < step_array.length; i++) {
                selectedID[i] = '&step_id=' + step_array[i];
            }
            const arrString = selectedID.toString();

            const d = arrString.replace(/,/g, '')

            setFunnelModalData((prevState: any) => {
                return {
                    ...prevState,
                    stepIds: arrString.replace(/,/g, ''),
                    fetchFunnelData: false,
                    submitFlag: true,
                    funnel_steps_array: funnel_steps_array,
                    interested_val: step_array
                }
            })

            visualizeFunnel(arrString.replace(/,/g, ''));
        } catch (error) {
            showBoundary(error)
        }

    }

    const clearList = () => {
        try {
            setFunnelModalData((prevState: any) => {
                return {
                    ...prevState,
                    invalid_form: true,
                    counter: 0,
                    stepTitle: [],
                    stepName: [],
                    _stepsLimit: false
                }
            })

            setFunnelSortedList([]);
            setSortedListFlag(false);
            $("label .chk").prop("checked", false);
            $('#stpes_selectedOptns').html("");

            // if (this.form !== undefined) {
            //   this.form.reset();
            // }
        } catch (error) {
            showBoundary(error)
        }

    }

    const closeFunnel = () => {
        try {
            setFunnelModalData((prevState: any) => {
                return {
                    ...prevState,
                    counter: funnelModalData.funnel_steps_array.length
                }
            })
            setFunnelModal(false)
        } catch (error) {
            showBoundary(error)
        }

    }


    return (
        <div className="col-lg-12 col-sm-12">
            {funnelChartData.showBarChart === true ?
                (<div>
                    <div className="openModal-div displayBlock z-index9">
                        <button onClick={() => openFunnelStepsList()} className="openModal-btn">{insightsLbls.editFunnel}</button>
                    </div>
                    <div className={funnelChartData.barChartData.length > 0 ? ("funnelBorder-div positionRelative funnelBorder") : ("funnelBorder-div positionRelative funnelBorder justifyCenter")}>
                        {/* <nvd3 [options]="engagementOptions" [data]="engagementData"></nvd3> */}
                        {/* <Barchart barChartData={funnelModalData.barChartData}></Barchart> */}
                        {funnelChartData.barChartData.length > 0 ? (
                            <React.Fragment>
                                <HorizontalBarChart barChartData={funnelChartData.barChartData} oldStepIndex={funnelModalData.oldStepIndex}></HorizontalBarChart>
                                <Icon img={"img/down_arrow.svg"} className=""/>
                            </React.Fragment>) : (<div className="alignCenter justifyCenter"><div className="displayFlex">{insightsLbls.noRecordFound}</div></div>)}

                    </div>
                </div>)
                : (<div className="col-sm-12 top30 detailedGraph positionRelative displayInlineFlex">
                    <div className="funnelBorder-div positionRelative">
                        <div className="create-funnelDiv col-6" onClick={() => openFunnelStepsList()}>
                            {insightsLbls.clickHereToCreateFunnel}
                        </div>
                    </div>
                </div>)
            }


            <Modal onHide={() => closeFunnel()} show={openFunnelModal} dialogClassName="GuideFunnel-modal">
                <Modal.Header>
                    <Modal.Title>{insightsLbls.step}</Modal.Title>
                    <button type="button" className="close" onClick={() => closeFunnel()} >
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">{insightsLbls.close}</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <form className="stepsForm-div" ref={(el) => myFormRef = el}>
                        <div className="select-stepBox displayInlineFlex">
                            <div className="stepsBox col-lg-5 col-5 row">
                                <div className="guideSteps-text">{props.listTitle}</div>
                                <div className="steps-innerBox">
                                    {props.stepsList.map((step, index) => {
                                        return (
                                            <label className="check-container stpesLabel displayBlock alignCenter funnelSteps" key={step.stepID + '_' + step.stepTitle}>
                                                <input type="checkbox" className="chk checkbox" id={step.stepID} value={step.stepID + ',' + step.stepTitle + ',' + (index + 1)} onClick={(event) => checkStep(event, index, step.stepID)} name="chk" />
                                                <span className="checkmark checkmarkBox-color displayInlineBlock"></span>
                                                <span className="step-span">{step.stepTitle}</span>
                                            </label>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                            <div className="partition col-lg-2 col-2">
                            </div>
                            <div className="col-lg-5 col-5 selected-steps">
                                <div className="guideSteps-text">{insightsLbls.funnelStepsInOrder}</div>
                                <div className="steps-innerBox">

                                    {sortedListFlag
                                        ? (<ul id="stpes_selectedOptns" className="steps-selectedOptns">
                                            <ReactSortable
                                                list={funnelSortedList}
                                                setList={setFunnelSortedList}
                                                group="groupName"
                                                animation={200}
                                                delay={2}>
                                                {funnelSortedList.map((step, index) => {
                                                    return (
                                                        <li className="li-item" value={step.value} id={step.id} key={step.id}>
                                                            <i className="fa fa-sort li-item-icon" aria-hidden="true"></i>
                                                            {step.label}
                                                        </li>
                                                    )
                                                })}
                                            </ReactSortable>
                                        </ul>)
                                        : null}

                                </div>
                            </div>
                        </div>
                        <div className="checkBox-errorMsg marginTop-10">
                            ({insightsLbls.funnelStepsMsg})
                            <br />
                            {insightsLbls.funnelStepsStatsMsg}
                        </div>
                        <br />
                        <div className="displayFlex alignCenter positionRelative">
                            <div className="errorMsg height20">
                                {/* *ngIf="_stepsLimit" */}
                                <span className={(funnelModalData._stepsLimit === false) ? 'displayNone' : ''}>{insightsLbls.max7Steps}</span>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <div className="col-lg-12">
                        <button className="resetFunnel-btn modal-cancel-btn marginLeft-10 btn-secondary floatRight btn" onClick={() => clearList()} type="button">{insightsLbls.clearList}</button>

                        <button className="createFunnel-btn modal-save-btn btn-primary floatRight" disabled={funnelModalData.invalid_form} onClick={() => submit()}>{insightsLbls.submit}</button>
                    </div>
                </Modal.Footer>
            </Modal>

        </div >
    )
}

export default FunnelComponent;
