import React, { ChangeEvent, useEffect, useState } from "react";
import usePagination from "./usePagination.js";
import "./Pagination.scss"
import { InsightLabels } from "labels.js";
import { useSelector } from "react-redux";
import { LanguageState } from "store/Reducers.js";
import Icon from 'components/Icon/Icon';

interface CurrentState {
    languageData: LanguageState
}

const Pagination = (props) => {
    let fetched_details = useSelector((state: CurrentState) => state.languageData);
    const { pageNumber, pageCount, changePage, nextPage, previousPage, currRecordStart, currRecordEnd, changeLimit, limit, gotoFirstPage, gotoLastPage } =
        usePagination(props.totalCount, props.pageLimit, props.pageNumber);
    // let fetched_details = useSelector((state: CurrentState) => state);

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)

    // const current = currRecordStart()

    useEffect(() => {
        if (fetched_details.languageData) {
            setInsightsLbls(fetched_details.languageData);
        }

    }, [fetched_details.languageData.languageData])
    useEffect(() => {
        if (pageNumber !== undefined && pageNumber !== null && pageNumber !== props.pageNumber) {
            props.handleChange(pageNumber)
        }
    }, [pageNumber]);

    useEffect(() => {
        if (limit !== undefined && limit !== null && limit !== props.pageLimit) {
            props.changeLimit(limit)
        }
    }, [limit])

    const pageLimitOptions = [
        { key: "5", value: "5" },
        { key: "10", value: "10" },
        { key: "20", value: "20" },
        { key: "30", value: "30" },
    ];

    return (
        <div className="d-flex justify-content-end align-items-baseline marginTop-15 marginBottom-5">
            {props.showEntries ? (<React.Fragment>
                <p>{insightsLbls.rowsPerPage}</p>
                <select className="limit-select mx-2" name="limit" id="select-limit"
                    onChange={(event) => changeLimit(event.target.value)}
                    value={limit}>
                    {pageLimitOptions.map((option, index) => {
                        return <option value={option.key} key={index}>{option.value}</option>;
                    })}
                </select>
            </React.Fragment>) : (<div className="my-4"></div>)}
            <div className="mx-2"> {currRecordStart() + '-' + currRecordEnd() + ' ' + insightsLbls.of + ' ' + props.totalCount}</div>
            <button disabled={currRecordStart() == 1} onClick={gotoFirstPage} className="pagination-imgs" ><Icon className="pagination-imgs fill-red" img={"img/first-page.svg"} /></button>
            <button disabled={currRecordStart() == 1} onClick={previousPage} className="pagination-imgs" ><Icon className="pagination-imgs" img={"img/left-caret.svg"} /></button>
            <button disabled={currRecordEnd() == props.totalCount} onClick={nextPage} className="pagination-imgs" ><Icon className="pagination-imgs" img={"img/right-caret.svg"} /></button>
            <button disabled={currRecordEnd() == props.totalCount} onClick={gotoLastPage} className="pagination-imgs" ><Icon className="pagination-imgs" img={"img/last-page.svg"} /></button>
        </div>
    );
};

export default Pagination;
