import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useErrorBoundary } from 'react-error-boundary';


import * as d3 from 'd3';
import $ from "jquery";
import * as venn from "assets/js/venn.js";

// let insightsLbls = insightsLabels;


const MultiSelect = (props) => {
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState({})

    let fetched_details = useSelector((state) => state);

    const [tableData, setTableData] = useState({
        showData: false,
        total: 0,
        qResponses: [],
        percentageSort: false,
        valueSort: false,
    })

    useEffect(() => {
        if (Object.keys(fetched_details.languageData.languageData).length) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
    }, [fetched_details])

    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        setTimeout(() => {
            initChart(props.chartData)
        }, 1000);
        if (props.chartData.length > 0) {
            initResponseTable(props.chartData);
        }

        setTableData((prevState) => {
            return {
                ...prevState,
                showData: props.chartData.length ? true : false
            }
        })
    }, [props.chartData])


    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const initResponseTable = (questionData) => {

        try {
            let total = 0;
            let qResponses_data = [];
            let percentage = 0;

            for (let i = 0; i < questionData.length; i++) {
                total = questionData[i].size + total;
            }

            for (let i = 0; i < questionData.length; i++) {
                percentage = (parseFloat(questionData[i].size) / total) * 100;
                qResponses_data.push({ option: questionData[i].label, value: questionData[i].size, percentage: percentage });
            }

            questionData = '';
            setTableData((prevState) => {
                return {
                    ...prevState,
                    showTable: true,
                    total: total,
                    qResponses: qResponses_data
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const initChart = (data) => {
        try {
            $('#venn_one').empty();
            if (data.length !== 0 && venn) {
                // const length = d3.select('#venn_one')[0][0];
                const sets = data;
                const chart = venn.VennDiagram()
                    .width(500)
                    .height(400);

                const div = d3.select('#venn_one').datum(sets).call(chart);
                div.selectAll('text').style('fill', 'black')
                    .style('font-weight', '600')
                    .style('font-size', '14px');

                div.selectAll('.venn-circle path')
                    .style('fill-opacity', .8)
                    .style('stroke-width', 1)
                    .style('stroke-opacity', 1)
                    .style('stroke', '#fff');

                const tooltip = d3.select('#venn_one').append('div')
                    .attr('class', 'venntooltip')
                    .style('position', 'absolute');

                div.selectAll('g')
                    .on('mouseover', function (d, i) {
                        // sort all the areas relative to the current item
                        venn.sortAreas(div, d);

                        // Display a tooltip with the current size
                        tooltip.transition().duration(40).style('opacity', 1);
                        tooltip.text(d.size + ' User(s) selected ' + d.label);

                        // highlight the current path
                        // highlight the current path
                        const selection = d3.select(this).transition('tooltip').duration(400);
                        selection.select('path')
                            .style('stroke-width', 3)
                            .style('fill-opacity', d.sets.length === 1 ? .8 : 0)
                            .style('stroke-opacity', 1);
                    })

                    .on("mousemove", function () {
                        // tooltip.style("left", (event.pageX) + "px")
                        // .style("top", (event.pageY - 28) + "px");
                    })

                    .on('mouseout', function (d, i) {
                        tooltip.transition().duration(2000).style('opacity', 0);
                        const selection = d3.select(this).transition('tooltip').duration(400);
                        selection.select('path')
                            .style('stroke-width', 3)
                            .style('fill-opacity', d.sets.length === 1 ? .8 : 0)
                            .style('stroke-opacity', 1);
                    });
                data = '';
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const sortData = (key, flag, field) => {
        try {
            const table_data = tableData.qResponses;
            if (tableData[field] === true) {
                table_data.sort((a, b) => (a[key] < b[key]) ? 1 : -1)
                setTableData((prevState) => {
                    return {
                        ...prevState,
                        qResponses: table_data,
                        [field]: !flag,
                    }
                })
            } else if (tableData[field] === false) {
                table_data.sort((a, b) => (a[key] > b[key]) ? 1 : -1)
                setTableData((prevState) => {
                    return {
                        ...prevState,
                        qResponses: table_data,
                        [field]: !flag,
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }



    return (
        <>
            <div className="marginTop-60 minWidth800">

                {tableData.showData
                    ? (<>
                        <div style={{ float: 'left' }} className="col-md-12 col-sm-12 col-xs-12 vennDia" id="venn_one" >
                        </div>

                        <div className="col-md-12 col-sm-12 col-xs-12 block-grph-table marginTop-30">
                            <p></p>
                            <div className="col-md-12 col-sm-12 col-xs-12 block-grph-table displayInlineBlock chartTables marginTop-30">
                                <h5>{insightsLbls.responseStats}</h5>
                                <table class=" color_table table table-bordered" >
                                    <thead class="" >
                                        <tr>
                                            <th>{insightsLbls.answerChoices}</th>
                                            <th>
                                                {insightsLbls.responsesTitle}
                                                <i class={"marginLeft-5 fa pointer " + `${tableData.percentageSort ? 'fa-caret-up' : 'fa-caret-down'}`}
                                                    onClick={() => sortData('percentage', tableData.percentageSort, 'percentageSort')}></i>
                                            </th>
                                            <th>
                                                {insightsLbls.responseCount}
                                                <i class={"marginLeft-5 fa pointer " + `${tableData.valueSort ? 'fa-caret-up' : 'fa-caret-down'}`}
                                                    onClick={() => sortData('value', tableData.valueSort, 'valueSort')}></i>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tfoot class="">
                                        <tr>
                                            <td><strong>{insightsLbls.total}</strong></td>
                                            <td>&nbsp;</td>
                                            <td><strong>{tableData.total}</strong></td>
                                        </tr>
                                    </tfoot>
                                    <tbody>

                                        {tableData.qResponses.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{data.option}</td>
                                                    <td>{Math.round(data.percentage)}%</td>
                                                    <td>{data.value}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>)
                    : (<div style={{ textAlign: 'center', paddingBottom: '50px', fontSize: '13px', color: 'black', fontWeight: '600' }}>
                        {insightsLbls['noResponseYet']}
                    </div >)
                }

            </div>
        </>
    )
};

export default React.memo(MultiSelect);
