import React, { useRef, useCallback, useEffect } from "react";
import NVD3Chart from 'react-nvd3';
import { CommonUtils } from "utils/CommonUtils";

import { useErrorBoundary } from 'react-error-boundary';

import * as d3 from "d3";
import $ from "jquery";


const Linechart = (props: any) => {
    const { showBoundary } = useErrorBoundary();
    let lineChartRef = [];
    lineChartRef[props.refId] = useRef(null);

    let max = 0;
    let dates = [];
    if (props.chartData !== undefined) {
        try {
            for (let i = 0; i < props.chartData.length; i++) {
                for (let data in props.chartData[i].counts){
                        if(max < +props.chartData[i].counts[data]){
                            max = props.chartData[i].counts[data]
                        }
                }
                dates.push(props.chartData[i].startTimestamp);
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const getData = useCallback(() => {
        return props.chartOptions
    }, [props.chartOptions])


    return (
        <div>
            <div id={props.refId}>
                <NVD3Chart
                    ref={lineChartRef[props.refId]}
                    type="lineChart"
                    datum={getData}
                    showValues={true}
                    wrapLabels={true}
                    useInteractiveGuideline={false}
                    legend={{
                        maxKeyLength: 100,
                        margin: {
                            right: 0,
                            top: 5,
                            left: -50,
                            bottom: 15,
                        }
                    }
                    }
                    x={function (d: any, i: any) {
                        return i;
                    }}
                    y={function (d: any) {
                        if (d.y !== undefined) {
                            return d.y;
                        }
                    }}
                    margin={{
                        left: props.margin["left"],
                        right: props.margin["right"],
                        top: props.margin["top"],
                        bottom: props.margin["bottom"],
                    }}
                    xAxis={{
                        tickFormat: function (d: any) {
                            try {
                                // return xAxis_val[d];
                                // return props.chartData[d].startTimestamp;
                                if (props?.chartData[d] !== undefined) {
                                    return (props?.chartData[d]?.startTimestamp);
                                }
                            } catch (error) {
                                showBoundary(error)
                            }

                        },
                    }}
                    yAxis={{
                        tickFormat: function (d: any) {
                            try {
                                if (props.value_type === "time") {
                                    d = Number(d);
                                    let h = Math.floor(d / 3600);
                                    let m = Math.floor((d % 3600) / 60);
                                    let s;

                                    if (h === 0) {
                                        s = Math.floor((d % 3600) % 60);
                                    }
                                    var hDisplay = h > 0 ? h + (h === 1 ? " h " : " h ") : "";
                                    var mDisplay = m > 0 ? m + (m === 1 ? " m " : " m ") : "";
                                    var sDisplay = s > 0 ? s + (s === 1 ? " s " : " s ") : "";
                                    return hDisplay + mDisplay + sDisplay;
                                    // return self.timeConverter.transform(d, true);
                                } else {
                                    return Number.isInteger(d)?d:"";
                                }
                            } catch (error) {
                                showBoundary(error)
                            }

                        },
                        axisLabelDistance: 2,
                        axisLabel: props.yAxisLabel,
                        showMaxMin: false,
                    }}
                    yDomain= {[0, max]}
                    width={props.width}
                    height={props.height}
                    clipEdge={false}
                    tooltip={{
                        contentGenerator: function (key: any, item: any) {
                            try {
                                // solution for tooltip stuck issue
                                d3.selectAll('.nvtooltip').style('opacity', 0);

                                let time = 0;
                                if (key.point.startTimeStamp) {
                                    time = key.point.startTimeStamp;
                                } else {
                                    time = key.point.startTimestamp;
                                }
                                if (props.chartData[0].startTimestamp.includes("|")) {
                                    $(".nv-point-paths path, .nv-groups rect").css(
                                        "cursor",
                                        "default"
                                    );
                                    if (props.value_type === "time") {
                                        var hr = Math.floor(key.point.y / 3600);
                                        var min = Math.floor((key.point.y % 3600) / 60);
                                        var sec = Math.floor((key.point.y % 3600) % 60);
                                        var hDisplay =
                                            hr > 0 ? hr + (hr === 1 ? " hr " : " hrs ") : "";
                                        var mDisplay =
                                            min > 0 ? min + (min === 1 ? " min " : " mins ") : "";
                                        var sDisplay =
                                            sec > 0 ? sec + (sec === 1 ? " sec " : " secs ") : "";
                                        var label = hDisplay + mDisplay + sDisplay;
                                        $('.xy-tooltip').css('display', 'none');
                                        $('.xy-tooltip').css('display', 'block');
                                        return `<div><b>${key.point.date} ${time}</b><br/><div style='height:10px; display:inline-block; margin-right:5px;width:10px; background-color:${key.point.color}'></div>${key.series[0].key} <b>${label}</b></div>`;
                                    } else {
                                        $('.xy-tooltip').css('display', 'none');
                                        $('.xy-tooltip').css('display', 'block');
                                        return `<div><b>${key.point.date
                                            } ${time}</b><br/><div style='height:10px; display:inline-block; margin-right:5px;width:10px; background-color:${key.point.color
                                            }'></div>${key.series[0].key
                                            } <b>${key.point.y
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b></div>`;
                                    }
                                } else {
                                    $(" .nv-point-paths path, .nv-groups rect").css(
                                        "cursor",
                                        "default"
                                    );
                                    if (props.value_type === "time") {
                                        const hr = Math.floor(key.point.y / 3600);
                                        const min = Math.floor((key.point.y % 3600) / 60);
                                        const sec = Math.floor((key.point.y % 3600) % 60);
                                        const hDisplay =
                                            hr > 0 ? hr + (hr === 1 ? " hr " : " hrs ") : "";
                                        const mDisplay =
                                            min > 0 ? min + (min === 1 ? " min " : " mins ") : "";
                                        const sDisplay =
                                            sec > 0 ? sec + (sec === 1 ? " sec " : " secs ") : "";
                                        const label = hDisplay + mDisplay + sDisplay;
                                        $('.xy-tooltip').css('display', 'none');
                                        $('.xy-tooltip').css('display', 'block');

                                        return `<div><b>${time}</b><br/><div style='height:10px; display:inline-block; margin-right:5px;width:10px; background-color:${key.point.color}'></div>${key.series[0].key} <b>${label}</b></div>`;
                                    } else {
                                        $('.xy-tooltip').css('display', 'none');
                                        $('.xy-tooltip').css('display', 'block');

                                        return `<div><b>${(time)}</b><br/><div style='height:10px; display:inline-block; margin-right:5px;width:10px; background-color:${key.point.color
                                            }'></div>${key.series[0].key
                                            } <b>${key.point.y}</b></div>`;
                                    }
                                }
                            } catch (error) {
                                showBoundary(error)
                            }

                        }
                    }}

                />
            </div>
        </div>
    );
};
export default React.memo(Linechart);
