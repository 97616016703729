import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { LanguageState } from "store/Reducers";
import { useErrorBoundary } from 'react-error-boundary';

import * as d3 from "d3";


interface CurrentState {
    languageData: LanguageState
}


const Blockchart = ((props: any) => {
    let fetched_details = useSelector((state: CurrentState) => state);
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState({})

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    const blockChartRef = useRef(null);

    try {
        // setTimeout(() => {
        let tree = props.blockData;
        let width = 800;
        const height = 400;

        const color = d3.scaleOrdinal(d3.schemeCategory10);

        let accessToBlockRef = d3.select("#blockChart")
            .append('div')
            .style('position', 'relative');
        const root = d3.treemap().tile(d3.treemapBinary).size([width, height]).padding(1).round(false)(d3.hierarchy(tree).sum(d => d.size).sort((a, b) => b.size - a.size));

        const tooltip = d3.select('body')
            .append('div')
            .style('position', 'absolute')
            .style('z-index', '10')
            .style('display', 'none')
            .style('color', '#222')
            .style('padding', '8px')
            .style('background-color', 'rgba(255, 255, 255, 0.75)')
            .style('border-radius', '4px')
            .style('border', '1px solid #666')
            .style('font', '14px sans-serif')
            .attr('class', 'tooltipVisibility')
            .text('tooltip');

        accessToBlockRef.selectAll(".node")
            .data(root.leaves())
            .enter().append('div')
            .attr('class', 'node')
            .style('border-radius', '6px')
            .style('border', '1px solid white')
            .style('background-color', function (d) {
                return d.data.name === 'Pages' ? '#fff' : color(d.data.name);
            })
            .style("font-size", function (d) {
                let area = (d.x1 - d.x0) * (d.y1 - d.y0);
                let blockFontSize = Math.round(Math.max(2, 0.1 * Math.sqrt(area)));
                if (blockFontSize > 18) {
                    return '18px';
                } else if (blockFontSize <= 8) {
                    return '8px';
                } else {
                    return Math.max(2, 0.1 * Math.sqrt(area)) + 'px';
                }
            })
            .style("visibility", d => d.data.name === 'Pages' ? 'hidden' : 'visible')
            .style('left', (d) => (d.x0) + "px")
            .style('top', (d) => (d.y0) + "px")
            .style('width', d => (d.x1 - d.x0) + "px")
            .style('height', d => (d.y1 - d.y0) + "px")
            .text(function (d) { return d.name; })
            .html(function (d) {
                let blockArea = Math.round((d.x1 - d.x0) * (d.y1 - d.y0));
                if (blockArea <= 3000) {
                    return d['children'] ? null : `<div style='white-space: nowrap;text-overflow: ellipsis;overflow: hidden;padding:4px 0px; font-size:10px;'><b>...</b></div>`;
                } else {
                    return d['children'] ? null : `<div style='white-space: nowrap;text-overflow: ellipsis;overflow: hidden;padding:4px 0px;'>${d.data.name}</div><div><b>${d.data.size} ${insightsLbls['views']}</b></div> `;
                }
            }).on('mouseover', function (d) {
                if (d.target.__data__ != undefined) {
                    tooltip.html(d.target.__data__.data.name + ': ' + `<b>${d.target.__data__.data.size} ${insightsLbls['views']}</b> `);
                }
                tooltip.style('display', 'block');
            })
            .on('mousemove', function (event, d) {
                const pageX = event['pageX'];
                const pageY = event['pageY'];
                return tooltip.style('top', (pageY + 30) + 'px').style('left', (pageX - 50) + 'px');
            })
            .on('mouseout', function () { return tooltip.style('display', 'none'); });
    } catch (error) {
        showBoundary(error)
    }

    return (
        // <></>
        // <MemoizedSubComponent>
        <div ref={blockChartRef} id="blockChart"></div>
        // </MemoizedSubComponent>

    );
})
export default React.memo(Blockchart);