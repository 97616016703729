import React, { useRef, useState, useEffect } from "react";


import $ from "jquery";
import { ChartColors } from '../../utils/GlobalConstants';
import * as d3 from "d3";
import * as d3Sankey from "d3-sankey";
import { useErrorBoundary } from 'react-error-boundary';

import './Sankeychart.scss'


const Sankeychart = (props) => {
    const { showBoundary } = useErrorBoundary();
    const colorArray = [ChartColors.lightgreen, ChartColors.lightseagreen, ChartColors.turquoise, ChartColors.darkturquoise, ChartColors.slateblue, ChartColors.mediumpurple, ChartColors.mediumorchid, ChartColors.brightred, ChartColors.salmon, ChartColors.lightpink, ChartColors.yellowsandybrown, ChartColors.orangesandybrown, ChartColors.skyblue];

    const schemeCategory20 = [
        "#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564e", "#e377c2", "#7f7f7f",
        "#bcbd22", "#17becf", "#c5b0d5", "#7570b3", "#c4463a", "#b2942e", "#d730ab", "#a6611a", "#949494",
        "#666666"
    ]
    useEffect(() => {
        setTimeout(() => {
            if (props.chartData) {
                SankeyChart(props.chartData, {})
            }
        }, 100);
    }, [props.chartData])

    const renderSankey = (version) => {
        try {
            let zoom = {};
            // let drag: any = {};
            let svg = {};
            // Zoom function:
            // Programmatic Pan+Zoom III (Mike Bostock’s Block 7ec977c95910dd026812)
            // https://bl.ocks.org/mbostock/7ec977c95910dd026812
            d3.selectAll('a[data-zoom]').on('click', clicked);
            function clicked() {
                const valueZoom = this.getAttribute('data-zoom');
                if (valueZoom !== '0') {
                    svg.call(zoom);
                    svg.on('wheel.zoom', null);

                    // Record the coordinates (in data space) of the center (in screen space).
                    const center0 = zoom.center(), translate0 = zoom.translate(), coordinates0 = getCoordinates(center0);
                    zoom.scale(zoom.scale() * Math.pow(2, +valueZoom));

                    // Translate back to the center.
                    const center1 = getPoint(coordinates0);
                    zoom.translate([translate0[0] + center0[0] - center1[0], translate0[1] + center0[1] - center1[1]]);

                    svg.transition().duration(750).call(zoom.event);
                } else {
                    // fitZoom();
                }
            }

            function fitZoom() {

                svg.transition().duration(500).call(zoom.translate([-5, 10]).scale(1).event);
            }

            function getCoordinates(point) {

                const scale = zoom.scale(), translate = zoom.translate();
                return [(point[0] - translate[0]) / scale, (point[1] - translate[1]) / scale];
            }

            function getPoint(coordinates) {

                const scale = zoom.scale(), translate = zoom.translate();
                return [coordinates[0] * scale + translate[0], coordinates[1] * scale + translate[1]];
            }

            function showSankey(flowData, widthVal) {

                let node_distance = [];
                let j = 0;
                let linkWidth = 0;
                $('.d3-tip-nodes').remove(); // clear olds tips
                if (flowData && flowData.nodes && flowData.nodes.length) {
                    const l = colorArray.length;
                    for (let i = 0; i < flowData.nodes.length; i++) {
                        flowData.nodes[i].color = colorArray[i % l];
                    }
                }
                const chartBox = {
                    width: widthVal, height: 360
                };
                const margin = { top: 10, right: 10, bottom: 10, left: 20 },
                    width = widthVal - margin.left - 20,
                    height = chartBox.height - margin.top - margin.bottom;


                function zoomed(event) {
                    svg.attr('transform', 'translate(' + event['transform']['x'] + ',' + event['transform']['y'] + ')scale(' + event['scale'] + ')');
                }

                function dragstarted() {
                    d3.select(this).classed('dragging', true);
                }

                function dragged(event, d) {
                    d3.select(this).attr('cx', d.x = event.x).attr('cy', d.y = event.y);
                }

                function dragended() {
                    d3.select(this).classed('dragging', false);
                }

                // drag = d3.drag()
                d3.select(this)
                    .call(
                        d3.drag()
                            .on("start", dragstarted)
                            .on('drag', dragged)
                            .on('end', dragended));
                // d3.drag()
                //     .origin(function (d) { return d; })
                //     .on('dragstart', dragstarted)
                //     .on('drag', dragged)
                //     .on('dragend', dragended);
                zoom = d3.selectAll('body')
                    .call(
                        d3.zoom()
                            // .scaleExtent([0, 5])
                            // .center([width / 2, height / 2])
                            .on("zoom", zoomed));
                // zoom = d3.zoom()
                //     .scaleExtent([0, 5])
                //     .center([width / 2, height / 2])
                //     .on('zoom', zoomed);

                const formatNumber = d3.format(',.0f'),
                    format = function (d) { return formatNumber(d) + ' $'; },
                    color = d3.scaleOrdinal().range(schemeCategory20);

                svg = d3.select('#sankey').append('svg')
                    .attr('class', 'sankey')
                    .attr('width', width + margin.left + margin.right)
                    .attr('height', height + margin.top + margin.bottom)
                    // .call(zoom)
                    // .on('wheel.zoom', null)
                    .append('g')
                    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

                const sankey = d3Sankey.sankey()
                    .nodeWidth(20)
                    .nodePadding(2)
                    .size([width, height]);

                const path = sankey.links();

                sankey
                    .nodes(flowData.nodes)
                    .links(flowData.links)
                // .layout(32);

                const fontScale = d3.scaleLinear().domain(d3.extent(flowData.nodes, function (d) { return d.value; })).range([18, 30]);

                const tooltip = d3.select('body')
                    .append('div')
                    .attr('class', 'tooltipVisibility')

                    .style('position', 'absolute')
                    .style('z-index', '10')
                    .style('display', 'none')
                    .style('color', '#222')
                    .style('padding', '8px')
                    .style('background-color', 'rgba(255, 255, 255, 0.75)')
                    .style('border-radius', '4px')
                    .style('border', '1px solid #666')
                    .style('font', '14px sans-serif')
                    .text('tooltip');

                let pathWidth = [];

                const link = svg.append('g').selectAll('.link')
                    .data(flowData.links)
                    .enter().append('path')
                    .attr('class', 'link')
                    .attr('d', path)
                    .style('stroke', function (d) { return d.source.color; })
                    .style('stroke-width', function (d) {
                        pathWidth.push(Math.round(Math.max(1, d.dy)));
                        return Math.max(1, d.dy);
                    })
                    .sort(function (a, b) { return b.dy - a.dy; });

                link.on('mousemove', function (event) {
                    const pageX = event['pageX'];
                    const pageY = event['pageY'];
                    return tooltip.style('top', (pageY - 10) + 'px').style('left', (pageX + 10) + 'px');
                })
                    .on('mouseover', function (d) {
                        if (version == 'analytics') {
                            tooltip.text(d.source.name + ' --> ' + d.target.name + ': ' + d.value);
                        } else {
                            tooltip.text(d.source.name + ' --> ' + d.target.name + ': ' + d.value);

                        }
                        tooltip.style('display', 'block');
                    })
                    .on('mouseout', function () { return tooltip.style('display', 'none'); });

                const node = svg.append('g').selectAll('.node')
                    .data(flowData.nodes)
                    .enter().append('g')
                    .attr('class', 'node')
                    // .attr('transform', function (d) {
                    //     return 'translate(' + d.x + ',' + d.y + ')';
                    // })
                    .attr('transform', function (d) {
                        node_distance[j] = Math.round(d.x);
                        j++;
                        return 'translate(' + d.x + ',' + d.y + ')';
                    })
                    .call(d3.drag()
                        // .origin(function (d) { return d; })
                        .on('start', function (event) {
                            event.sourceEvent.stopPropagation();  // Disable drag sankey on node select
                            this.parentNode.appendChild(this);
                        })
                        .on('drag', dragmove));

                node.on('mousemove', function (event) {
                    const pageX = event['pageX'];
                    const pageY = event['pageY'];
                    return tooltip.style('top', (pageY - 10) + 'px').style('left', (pageX + 10) + 'px');
                })
                    .on('mouseover', function (d) {
                        if (version == 'analytics') {
                            tooltip.text('Step: ' + d.name + ': ' + d.value);
                            tooltip.style('display', 'block');
                        } else {
                            tooltip.text('Step: ' + d.name + ': ' + d.value);
                            tooltip.style('display', 'block');
                        }
                    })
                    .on('mouseout', function () { return tooltip.style('display', 'none'); });

                node.append('rect')
                    .attr('height', function (d) { return d.dy; })
                    .attr('width', sankey.nodeWidth())
                    .style('fill', function (d) {
                        if (d.color === undefined) {
                            if (version == 'analytics') {
                                return d.color = color(d.name.replace(/ .*/, '')); // get new color if node.color is null

                            } else {
                                return d.color = color(d.name.replace(/ .*/, '')); // get new color if node.color is null

                            }
                        }
                        return d.color;
                    });
                //.style('stroke', function (d) { return d3.rgb(d.color).darker(2); });

                node.append('text')
                    .attr('class', 'nodeValue')
                    .text(function (d) {
                        if (version == 'analytics') {
                            return d.name + '\n' + format(d.value);

                        } else {
                            return d.name + '\n' + format(d.value);

                        }
                    });

                node.selectAll('text.nodeValue')
                    .attr('x', sankey.nodeWidth() / 2)
                    .attr('y', function (d) { return (d.dy / 2); })
                    .text(function (d) { return formatNumber(d.value); })
                    .attr('dy', 5)
                    .attr('text-anchor', 'middle');

                node.append('text')
                    .attr('class', 'nodeLabel')
                    .style('fill', function (d) {
                        return d3.rgb(d.color).darker(2.4);
                    })
                    // .style('font-size', '14px');
                    .style('font-size', function (d) {
                        return fontScale(d.value) + 'px';
                    })
                    .style('font-size', '14px');

                node.selectAll('text.nodeLabel')
                    .attr('x', -6)
                    .attr('y', function (d) { return d.dy / 2; })
                    .attr('dy', '.35em')
                    .attr('text-anchor', 'end')
                    .attr('transform', null)
                    // .text(function (d) { return d.name; })
                    .filter(function (d) { return d.x < width / 2; })
                    .attr('x', 6 + sankey.nodeWidth())
                    .attr('text-anchor', 'start');

                function dragmove(event, d) {
                    d3.select(this)
                        .attr('transform', 'translate(' + d.x + ',' +
                            (d.y = Math.max(0, Math.min(height - d.dy, event.y))) + ')');
                    sankey.relayout();
                    link.attr('d', path);
                }

                node.append("foreignObject")
                    .attr('x', -6)
                    .attr('y', function (d) { return d.dy / 2; })
                    .attr('dy', '.35em')
                    .attr('text-anchor', 'end')
                    .attr('transform', null)
                    .attr("width", function (d, i) {
                        // linkWidth = node_distance[1] - 60;
                        linkWidth = node_distance[i + 1] - node_distance[i];

                        if (linkWidth < 0) {
                            linkWidth = 0;
                            return '0px';

                        } else {
                            linkWidth = node_distance[i + 1] - node_distance[i];
                            return linkWidth + 'px';
                        }
                    })
                    .attr("height", 12)
                    .attr('x', 25)
                    .attr('text-anchor', 'start')
                    .append("xhtml:div")
                    .attr('style', 'word-wrap: break-word; text-align:center;')
                    .html(function (d, i) {

                        if (version == 'analytics') {
                            return `<div><p style="color: black;
                    font-size: 12px;
                    display: block;
                    text-overflow: ellipsis;
                    white-space: nowrap; 
                    text-align: left; 
                    width: ${(node_distance[i + 1] - node_distance[i]) - 25 + 'px'};
                    /* height: 100px; */
                    overflow: hidden;
                    line-height: 1;">${d.name}</p></div>`
                        } else {
                            return `<div><p style="color: black;
                    font-size: 12px;
                    display: block;
                    text-overflow: ellipsis;
                    white-space: nowrap; 
                    text-align: left; 
                    width: ${(node_distance[i + 1] - node_distance[i]) - 25 + 'px'};
                    /* height: 100px; */
                    overflow: hidden;
                    line-height: 1;">${d.name}</p></div>`
                        }
                    });

                // fitZoom();
            }
            showSankey(props.chartData, 1000);
        } catch (error) {
            showBoundary(error)
        }



    }

    function SankeyChart({
        nodes, // an iterable of node objects (typically [{id}, …]); implied by links if missing
        links // an iterable of link objects (typically [{source, target}, …])
    }, {
        format = ",", // a function or format specifier for values in titles
        align = "justify", // convenience shorthand for nodeAlign
        nodeId = d => d.node, // given d in nodes, returns a unique identifier (string)
        nodeGroup, // given d in nodes, returns an (ordinal) value for color
        nodeGroups, // an array of ordinal values representing the node groups
        nodeLabel = d => d.name, // given d in (computed) nodes, text to label the associated rect
        nodeTitle = d => { return `${d.name}\n${format(d.value)}` }, // given d in (computed) nodes, hover text
        nodeAlign = align, // Sankey node alignment strategy: left, right, justify, center
        nodeSort, // comparator function to order nodes
        nodeWidth = 20, // width of node rects
        nodePadding = 2, // vertical separation between adjacent nodes
        nodeLabelPadding = 6, // horizontal separation between node and label
        nodeStroke = d3.scaleOrdinal().range(schemeCategory20), // stroke around node rects
        nodeStrokeWidth, // width of stroke around node rects, in pixels
        nodeStrokeOpacity, // opacity of stroke around node rects
        nodeStrokeLinejoin, // line join for stroke around node rects
        linkSource = ({ source }) => { return source }, // given d in links, returns a node identifier string
        linkTarget = ({ target }) => target, // given d in links, returns a node identifier string
        linkValue = ({ value }) => value, // given d in links, returns the quantitative value
        linkPath = d3Sankey.sankeyLinkHorizontal(), // given d in (computed) links, returns the SVG path
        linkTitle = d => {
            return `${d.source.name} --> ${d.target.name} :  ${format(d.value)}`
        }, // given d in (computed) links
        linkColor = d3.scaleOrdinal().range(colorArray), // source, target, source-target, or static color
        linkStrokeOpacity = 0.5, // link stroke opacity
        linkMixBlendMode = "multiply", // link blending mode
        colors = colorArray, // array of colors
        width = 1000, // outer width, in pixels
        height = 400, // outer height, in pixels
        marginTop = 5, // top margin, in pixels
        marginRight = 1, // right margin, in pixels
        marginBottom = 5, // bottom margin, in pixels
        marginLeft = 1,
        version = 'analytics' // left margin, in pixels
    } = {}) {
        // Convert nodeAlign from a name to a function (since d3-sankey is not part of core d3).
        d3.select("#the_SVG_ID").remove();
        if (typeof nodeAlign !== "function") nodeAlign = {
            left: d3Sankey.sankeyLeft,
            right: d3Sankey.sankeyRight,
            center: d3Sankey.sankeyCenter
        }[nodeAlign] ?? d3Sankey.sankeyJustify;

        // Compute values.
        const LS = d3.map(links, linkSource).map(intern);
        const LT = d3.map(links, linkTarget).map(intern);
        const LV = d3.map(links, linkValue);

        if (nodes === undefined) nodes = Array.from(d3.union(LS, LT), id => ({ id }));
        // console.log(nodes, "nodes");
        const N = d3.map(nodes, nodeId).map(intern);
        // console.log(N, "N");
        const G = nodeGroup == null ? null : d3.map(nodes, nodeGroup).map(intern);

        // Replace the input nodes and links with mutable objects for the simulation.
        nodes = d3.map(nodes, (_, i) => {
            return ({ id: N[i], name: nodes[i].name })
        })
        // console.log(nodes);
        links = d3.map(links, (_, i) => ({ source: LS[i], target: LT[i], value: LV[i] }));

        // Ignore a group-based linkColor option if no groups are specified.
        if (!G && ["source", "target", "source-target"].includes(linkColor)) linkColor = "currentColor";

        // Compute default domains.
        if (G && nodeGroups === undefined) nodeGroups = G;

        // Construct the scales.
        const color = nodeGroup == null ? null : d3.scaleOrdinal(nodeGroups, colors);

        // Compute the Sankey layout.
        d3Sankey.sankey()
            .nodeId(({ index: i }) => N[i])
            .nodeAlign(nodeAlign)
            .nodeWidth(nodeWidth)
            .nodePadding(nodePadding)
            .nodeSort(nodeSort)
            .extent([[marginLeft, marginTop], [width - marginRight, height - marginBottom]])
            ({ nodes, links });

        // Compute titles and labels using layout nodes, so as to access aggregate values.
        if (typeof format !== "function") format = d3.format(format);
        const Tl = nodeLabel === undefined ? N : nodeLabel == null ? null : d3.map(nodes, nodeLabel);
        const Tt = nodeTitle == null ? null : d3.map(nodes, nodeTitle);
        const Lt = linkTitle == null ? null : d3.map(links, linkTitle);

        // A unique identifier for clip paths (to avoid conflicts).
        const uid = `O-${Math.random().toString(16).slice(2)}`;
        const svg = d3.select('#sankey').append('svg')
            .attr("id", "the_SVG_ID")
            .attr("width", width)
            .attr("height", height)
            .attr("viewBox", [0, 0, width, height])
            .attr("style", "max-width: 100%; height: auto; height: intrinsic;");

        const node = svg.append("g")
            .attr("stroke", nodeStroke)
            .attr("stroke-width", nodeStrokeWidth)
            .attr("stroke-opacity", nodeStrokeOpacity)
            .attr("stroke-linejoin", nodeStrokeLinejoin)
            .selectAll("rect")
            .data(nodes)
            .join("rect")
            .attr("x", d => d.x0)
            .attr("y", d => d.y0)
            .attr("height", d => d.y1 - d.y0)
            .attr("width", d => d.x1 - d.x0)
            .attr('fill', function (d) {
                if (d.color === undefined) {
                    if (version == 'analytics') {
                        return d.color = colors[d.index]; // get new color if node.color is null

                    } else {
                        return d.color = colors[d.index]; // get new color if node.color is null

                    }
                }
                return d.color;
            });

        if (G) node.attr("fill", ({ index: i }) => color(G[i]));
        if (Tt) node.append("title").text(({ index: i }) => Tt[i]);

        const link = svg.append("g")
            .attr("fill", "none")
            .attr("stroke-opacity", linkStrokeOpacity)
            .selectAll("g")
            .data(links)
            .join("g")
            .style("mix-blend-mode", linkMixBlendMode);

        if (linkColor === "source-target") link.append("linearGradient")
            .attr("id", d => `${uid}-link-${d.index}`)
            .attr("gradientUnits", "userSpaceOnUse")
            .attr("x1", d => d.source.x1)
            .attr("x2", d => d.target.x0)
            .call(gradient => gradient.append("stop")
                .attr("offset", "0%")
                .attr("stop-color", ({ source: { index: i } }) => color(G[i])))
            .call(gradient => gradient.append("stop")
                .attr("offset", "100%")
                .attr("stop-color", ({ target: { index: i } }) => color(G[i])));

        link.append("path")
            .attr("d", linkPath)
            .attr("stroke", linkColor === "source-target" ? ({ index: i }) => `url(#${uid}-link-${i})`
                : linkColor === "source" ? ({ source: { index: i } }) => color(G[i])
                    : linkColor === "target" ? ({ target: { index: i } }) => color(G[i])
                        : linkColor)
            .attr("stroke-width", ({ width }) => Math.max(1, width))
            .append("title").text(({ index: i }) => Lt[i]);
       
        if (Tl) svg.append("g")
            .attr("font-family", "sans-serif")
            .attr("font-size", 10)
            .selectAll("text")
            .data(nodes)
            .join("text")
            .attr("x", d => d.x0 < width / 2 ? d.x1 + nodeLabelPadding : d.x0 - nodeLabelPadding)
            .attr("y", d => (d.y1 + d.y0) / 2)
            .attr("dy", "0.35em")
            .attr("text-anchor", d => d.x0 < width / 2 ? "start" : "end")
            .text(({ index: i }) => Tl[i]);

        function intern(value) {
            return value !== null && typeof value === "object" ? value.valueOf() : value;
        }
        return Object.assign(svg.node(), { scales: { color } });
    }

    const sankeyChartRef = useRef(null);


    return (
        <>
            <div className="marginTop-10" >
                <div id="sankey" className="sankeybox" ref={sankeyChartRef}>
                    {/* <div className="d3-zoom-controls">
                        <a data-zoom="+0.5" className="btn btn-circle"><span className="fa fa-plus"></span></a>
                        <a data-zoom="-0.5" className="btn btn-circle"><span className="fa fa-minus"></span></a>
                        <a data-zoom="0" className="btn btn-circle"><span className="fa fa-crosshairs"></span></a>
                    </div> */}
                </div>
            </div>
        </>

    );
}
export default React.memo(Sankeychart);
