import * as actionTypes from '../../Actions/ActionTypes';

export interface PageDetailState {
    userActivity: any,
    userVisit: any,
    pageLoadTime: any,
    userSystemData: any,
    pageTag: any,    
    userEngagementStatsChartData: any,
    userEngagementStatsSummaryData: any,
    userEngagementModalChartData: any,
}

const initialState = {
    userActivity: [],
    userVisit: [],
    pageLoadTime: [],
    userSystemData: [],
    pageTag: [],    
    userEngagementStatsChartData: [],
    userEngagementStatsSummaryData: [],
    userEngagementModalChartData: []
};

const pageDetailReducer = (state = initialState, action:any) => {

    switch (action.type) {
        case actionTypes.USER_STATS:
            return {
                ...state,
                userActivity: action.userActivity,
            };

        case actionTypes.PAGE_BLOCK_CHART:
            return {
                ...state,
                userVisit: action.userVisit,
            };
        
        case actionTypes.INSIGHTS_LOADTIME:
            return {
                ...state,
                pageLoadTime: action.pageLoadTime,
            };
        
        case actionTypes.USAGE_STATS:
            return {
                ...state,
                userSystemData: action.userSystemData,
            };

        case actionTypes.TAG_PAGE:
            return {
                ...state,
                pageTag: action.pageTag,
            };

        case actionTypes.SET_PAGE_USER_ENGAGEMENT_STATS_CHART:
            return {
                ...state,
                userEngagementStatsChartData: action.userEngagementStatsChartData,
            };
        case actionTypes.SET_PAGE_USER_ENGAGEMENT_SUMMARY:
            return {
                ...state,
                userEngagementStatsSummaryData: action.userEngagementStatsSummaryData,
            };

        case actionTypes.SET_PAGE_USER_ENGAGEMENT_MODAL_CHART:
            return {
                ...state,
                userEngagementModalChartData: action.userEngagementModalChartData,
            };

        default:
            return state;
    }

}

export default pageDetailReducer;
