// Modified by: Mrunal Mane
// On: 2023-01-31 11:15
// Reason: To include admin portal redirect link


import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from 'react-router-dom';
import withRouter from "hoc/withRouter";

import { useLocation } from "react-router-dom";

import Login from "./Login";
import Otp from "./Otp";
import ForgotPassword from "./ForgotPassword";
import { useErrorBoundary } from 'react-error-boundary';

import './Authentication.scss';

const Authentication: React.FC = (props: any) => {
    const { showBoundary } = useErrorBoundary();
    const location = useLocation();
    const navigate = useNavigate();

    let urlData: any = {};
    useEffect(() => {

        try {
            const query = new URLSearchParams(location.search);
            query.forEach((v, k) => {
                urlData[k] = v;
            });

            if (urlData['handoff-token']) {
                localStorage.setItem('IS_AUTHENTICATED', 'true');
            }

            if ((localStorage.getItem('IS_AUTHENTICATED') === 'false' || localStorage.getItem('IS_AUTHENTICATED') === null)) {
                if (process.env.NODE_ENV === 'production') {
                    if (!window.location.href.includes('firstRedirect')) {
                        window.open(process.env.REACT_APP_ADMIN_URL! + "?redirectURL=" + btoa('/dashboard'), "_self");
                    }
                } else {
                    navigate({
                        pathname: `/login/`,
                    });
                }
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [location.pathname])


    return (
        <React.Fragment>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/otp" element={<Otp />} />
                <Route path="/password" element={<ForgotPassword />} />
            </Routes>
        </React.Fragment>
    )
}
export default withRouter(Authentication);

