export const setAppDetails = (appData: object) => ({
    type: "SET_APP_DETAILS",
    appPayload: appData
})

export const setAppFromDashboard = (appDataFromDashboard: object) => ({
    type: "SET_APP_FROM_DASHBOARD",
    appPayload: appDataFromDashboard
})

export const setDomain = (domainData: object) => ({
    type: "SET_DOMAIN",
    domainPayload: domainData
})

export const setUrlOption = (urlOption: object) => ({
    type: "SET_URL_OPTION",
    urlOptionPayload: urlOption
})

export const setDates = (dateData: object) => ({
    type: "SET_DATES",
    datePayload: dateData
})

export const setExportDates = (exportDateData: object) => ({
    type: "SET_EXPORT_DATES",
    exportDatePayload: exportDateData
})

export const setEventSource = (eventSourceData: object) => ({
    type: "SET_EVENT_SOURCE",
    eventSourcePayload: eventSourceData
})

export const setSegmentsFilter = (segmentsFilterData: object) => ({
    type: "SET_SEGMENTS_FILTER",
    segmentsFilterPayload: segmentsFilterData
})

export const setSectionVisibility = (visibilityData: object) => ({
    type: "SET_SECTION_VISIBILITY",
    visibilityPayload: visibilityData
})

export const setOrg = (orgData: object) => ({
    type: "SET_ORG",
    orgPayload: orgData
})

export const setHandOffDetails = (handoffData: object) => ({
    type: "SET_HANDOFF",
    handoffPayload: handoffData
})

export const setStateFilter = (stateFilterData: string) => ({
    type: "SET_STATE_FILTER",
    stateFilterPayload: stateFilterData
})

export const setExportRequestSourceFilter = (exportRequestSourceFilterData: string) => ({
    type: "SET_EXPORT_REQUEST_SOURCE_FILTER",
    exportRequestSourceFilterPayload: exportRequestSourceFilterData
})


export const setDashboards = (dashboardsData: any) => ({
    type: "SET_DASHBOARDS",
    dashboardsPayload: dashboardsData
})


export const setAuth = (authData: any) => ({
    type: "SET_AUTH",
    authPayload: authData
})


export const setTooltipFilter = (tooltipFilterData: any) => ({
    type: "SET_TOOLTIP_FILTER",
    tooltipFilterPayload: tooltipFilterData
})

export const setEnvFilter = (envFilterData: object) => ({
    type: "SET_ENV_FILTER",
    envFilterPayload: envFilterData
})

export const setCommonComponent = (commonComponentData: object) => ({
    type: "SET_COMMON_COMPONENT",
    commonComponentPayload: commonComponentData
})

export const setLanguage = (languageData: object) => ({
    type: "SET_LANGUAGE",
    languagePayload: languageData
})

export const setNavigationStatus = (navigationStatusData) => ({
    type: "SET_NAVIGATION_STATUS",
    navigationStatusData: navigationStatusData
})
