// Modified by: Mohit Tiwari
// On: 2024-06-07
// Reason: Removed changes for isInitialMount(committed on 10 May,2024), as it blocks search funcitonality

// Modified by: Mrunal Mane
// On: 2024-05-10
// Reason: Removed language dependancy from useEffect (props.listData)

import React, { useEffect, useRef } from 'react';
import { ChangeEvent, useState } from "react";
import { useSelector } from "react-redux";
import { LanguageState } from "store/Reducers";
import { useErrorBoundary } from 'react-error-boundary';

import Loader from 'components/Loader/Loader';


import './ManageChatBot.scss';

import { InsightLabels } from 'labels';
import Datepicker from 'components/Calender/Datepicker';
import SectionFilters from 'components/Layouts/SectionFilters';

interface CurrentState {
    languageData: LanguageState
}

const ChatBotList = (props: any) => {
    // const isInitialMount = useRef(true);

    let fetched_details = useSelector((state: CurrentState) => state);
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);



    const [list, setList] = useState({
        flag: false
    })

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        try {
            // if (isInitialMount.current) {
            if (fetched_details.languageData.languageData) {
                setList((prevState: any) => {
                    return {
                        ...prevState,
                        flag: true
                    }
                })
            }
            // isInitialMount.current = false;

            // }

        } catch (error) {
            showBoundary(error)
        }

    }, [props.listData])

    const onSearchText = (e: ChangeEvent<HTMLInputElement>) => {
        try {
            setList((prevState: any) => {
                return {
                    ...prevState,
                    flag: false
                }
            })
            props.searchList(e.target.value)
        } catch (error) {
            showBoundary(error)
        }

    }

    const handleDelete = (data: string) => {
        props.deleteSentiment(data)
    }

    const handleShare = (title: string, code: string) => {
        props.shareSentiment(title, code)
    }

    return (
        <>
            <SectionFilters>
                <Datepicker source="component"></Datepicker>
            </SectionFilters>

            <div id="category_main_div" className="displayFlex">
                <div className="categoryDiv">
                    <div className="categoryList-div" id="tags_main_div">
                        <div className="searchDiv">
                            <p className="displayFlex marginTop-10">
                                <input type="text"
                                    className="searchIcon form-control round-input searchInput-box"
                                    placeholder={insightsLbls.searchChatbot}
                                    autoComplete="off"
                                    onChange={(event) => onSearchText(event)}
                                    disabled={(props.apiError === 1 || (props.firstTimeLoad && props.listCount === 0)) ? true : false} />
                                <button className="createCat-button" onClick={() => props.dispatchClick()}>
                                    <i className="fa fa-plus font20" aria-hidden="true"></i>
                                </button>
                            </p>
                        </div>
                        <div className="categoryMain-div">
                            {
                                list.flag
                                    ? (
                                        props.apiError === 0 ?
                                            (
                                                <>
                                                    {
                                                        props.listCount ?
                                                            (
                                                                <>
                                                                    {
                                                                        props.listData.map((data: any, index: number) => {
                                                                            return (
                                                                                <div className="row category-card highlightCard" key={index}>
                                                                                    <div className="flex-100 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                                                                        <div className="cat-content-div col-lg-11 col-md-11 col-sm-11 col-xs-11 col-11" onClick={() => props.dispatchEditSurvey(data)}>
                                                                                            <p className="nomargin fontW600 textOverflow cat-info marginBottom-0">
                                                                                                {data.conversationTitle}
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className="app-actions-div col-lg-1 col-md-1 col-sm-1 col-xs-1 col-1 paddingLR-0">
                                                                                            <div className="catEdit-optn">
                                                                                                <div className="optnsContainer displayFlex alignCenter marginTop-10">
                                                                                                    <i className="fa fa-trash editCard-icon margin8" aria-hidden="true" onClick={() => handleDelete(data.conversationCode)}></i>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="alignCenter flex-100 font12">
                                                                                        <div className="col-lg-7 textLeft alignCenter paddingRight-5 paddingLeft-0">
                                                                                            <div className="alignCenter">
                                                                                                <span className="lastUpdatedText">{insightsLbls.lastUpdated}:&nbsp;</span>
                                                                                                <span className="font600 lastUpdated">{data.lastUpdatedOn}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-lg-5 textLeft alignCenter displayFlex paddingRight-5 paddingLeft-0">
                                                                                            {data.isGlobal && (
                                                                                                <>
                                                                                                    <div className="alignCenter globalChip marginRight-15" >
                                                                                                        {insightsLbls.global}
                                                                                                    </div> &nbsp;
                                                                                                </>
                                                                                            )}

                                                                                            <div className="alignCenter questionCount">
                                                                                                {insightsLbls.questions}:&nbsp;<span className="font600">{data.questionCount}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            )
                                                            :
                                                            (
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            )}
                                                </>
                                            )
                                            :
                                            (
                                                <div className="noDataText-font textCenter-absolute">
                                                    {insightsLbls.serviceNotAvailable}
                                                </div>
                                            )
                                    )
                                    :
                                    (<div className="minHeight400">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader></Loader>
                                        </div>
                                    </div>)
                            }
                        </div>
                    </div>
                </div>

                {/* Sentiment Instructions */}
                <div id="chatbot_instructions" className="width100">
                    <div className="sentimentInstruction">
                        <div>{insightsLbls.click} <span className="createCat-button createCat-button-span"><i className="fa fa-plus font20" aria-hidden="true"></i></span>
                            {insightsLbls.iconToCreateNewChatbot}</div>
                        <div>{insightsLbls.clickToEditChatbot}</div>
                    </div>
                </div>
            </div>

        </>
    )
};

export default React.memo(ChatBotList);
