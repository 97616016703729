import * as actionTypes from '../../Actions/ActionTypes';

export interface UserEngagementState {
    userEngagementGuideMeChartData: any,
    userEngagementShowMeChartData: any,
    userEngagementTestMeChartData: any,
    userEngagementDoItForMeChartData: any,
    userEngagementTutorialGuidesChartData: any,
    userEngagementSummaryTableData: any,
    userEngagementUserChartData: any
}

const initialState = {
    userEngagementGuideMeChartData: [],
    userEngagementShowMeChartData: [],
    userEngagementTestMeChartData: [],
    userEngagementDoItForMeChartData: [],
    userEngagementTutorialGuidesChartData: [],
    userEngagementSummaryTableData: [],
    userEngagementUserChartData: []
};


const userEngagementReducer = (state = initialState, action:any) => {
    switch (action.type) {
        case actionTypes.SET_USER_ENGAGEMENT_GUIDEME_CHART_DATA:
            return {
                ...state,
                userEngagementGuideMeChartData: action.userEngagementGuideMeChartData,
            };

        case actionTypes.SET_USER_ENGAGEMENT_SHOWME_CHART_DATA:
            return {
                ...state,
                userEngagementShowMeChartData: action.userEngagementShowMeChartData,
            };

        case actionTypes.SET_USER_ENGAGEMENT_TESTME_CHART_DATA:
            return {
                ...state,
                userEngagementTestMeChartData: action.userEngagementTestMeChartData,
            };

        case actionTypes.SET_USER_ENGAGEMENT_DO_IT_FOR_ME_CHART_DATA:
            return {
                ...state,
                userEngagementDoItForMeChartData: action.userEngagementDoItForMeChartData,
            };

        case actionTypes.SET_USER_ENGAGEMENT_TUTORIAL_GUIDES_CHART_DATA:
            return {
                ...state,
                userEngagementTutorialGuidesChartData: action.userEngagementTutorialGuidesChartData,
            };

        case actionTypes.SET_USER_ENGAGEMENT_SUMMARY_TABLE_DATA:
            return {
                ...state,
                userEngagementSummaryTableData: action.userEngagementSummaryTableData,
            };

        case actionTypes.SET_USER_ENGAGEMENT_USER_CHART_DATA:
            return {
                ...state,
                userEngagementUserChartData: action.userEngagementUserChartData,
            };

        default:
            return state;
    }
};

export default userEngagementReducer;