import * as ActionTypes from '../ActionTypes';
import axiosInstance, {getInstance} from '../../../network/apis/interceptor';
import { CommonUtils } from '../../../utils/CommonUtils';
import { ApiRelativePaths, RetryApi } from '../../../utils/GlobalConstants';


let ApiPaths: any = ApiRelativePaths;
const controller = new AbortController();

export const setGuideAnalyticsGuideMeChart = (guideAnalyticsGuideMeChartData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_GUIDEME_CHART_DATA,
        guideAnalyticsGuideMeChartData: guideAnalyticsGuideMeChartData
    }
};

export const setGuideAnalyticsShowMeChart = (guideAnalyticsShowMeChartData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_SHOWME_CHART_DATA,
        guideAnalyticsShowMeChartData: guideAnalyticsShowMeChartData
    }
};

export const setGuideAnalyticsTestMeChart = (guideAnalyticsTestMeChartData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_TESTME_CHART_DATA,
        guideAnalyticsTestMeChartData: guideAnalyticsTestMeChartData
    }
};

export const setGuideAnalyticsDoItForMeChart = (guideAnalyticsDoItForMeChartData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_DOITFORME_CHART_DATA,
        guideAnalyticsDoItForMeChartData: guideAnalyticsDoItForMeChartData
    }
};

export const setGuideAnalyticsSurveyChart = (guideAnalyticsSurveyChartData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_SURVEY_CHART_DATA,
        guideAnalyticsSurveyChartData: guideAnalyticsSurveyChartData
    }
};

export const setGuideAnalyticsPBChart = (guideAnalyticsPBChartData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_PB_CHART_DATA,
        guideAnalyticsPBChartData: guideAnalyticsPBChartData
    }
};

export const setGuideAnalyticsDoItForMePBChart = (guideAnalyticsDoItForMePBChartData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_DOITFORME_PB_CHART_DATA,
        guideAnalyticsDoItForMePBChartData: guideAnalyticsDoItForMePBChartData
    }
};

export const setGuideAnalyticsTutorialGuidesChart = (guideAnalyticsTutorialGuidesChartData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_TUTORIAL_GUIDES_CHART_DATA,
        guideAnalyticsTutorialGuidesChartData: guideAnalyticsTutorialGuidesChartData
    }
};

export const setGuideAnalyticsSummaryTable = (guideAnalyticsSummaryTableData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_SUMMARY_TABLE_DATA,
        guideAnalyticsSummaryTableData: guideAnalyticsSummaryTableData
    }
};




export const getGuideAnalyticsData = (params: any, url_path: any, caseType: any, apiErrorFlag: string, firstTimeload?: boolean) => {
    return new Promise((resolve, reject) => {
        let path = ApiPaths[url_path];
        let data: any = {};
        let paramObj = {};
        let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);

        getInstance('guide-insights/guide-analytics')
        try{
            axiosInstance.get(generatedURL)
            .then(res => {
                data = {
                    "result": "success",
                    "data": res.data.data,
                    "firstTimeload": firstTimeload ?? false,
                    "requestUUID": res.data.requestUUID,
                };
            })
            .catch(err => {
                if (apiErrorFlag === '') {
                    data = {
                        "result": "retry",
                        "data": err,
                        "firstTimeload": firstTimeload ?? false,
                    };
                } else {
                
                    data = {
                        "result": "error",
                        "data": err,
                        "firstTimeload": firstTimeload ?? false,
                    };
                }
            })
            .then(() => {
                const callActionDispatch = () => {
                    switch (caseType) {
                        case ActionTypes.SET_GUIDE_ANALYTICS_GUIDEME_CHART_DATA:
                            resolve(setGuideAnalyticsGuideMeChart(data));
                            break;

                        case ActionTypes.SET_GUIDE_ANALYTICS_SHOWME_CHART_DATA:
                            resolve(setGuideAnalyticsShowMeChart(data));
                            break;

                        case ActionTypes.SET_GUIDE_ANALYTICS_TESTME_CHART_DATA:
                            resolve(setGuideAnalyticsTestMeChart(data));
                            break;

                        case ActionTypes.SET_GUIDE_ANALYTICS_DOITFORME_CHART_DATA:
                            resolve(setGuideAnalyticsDoItForMeChart(data));
                            break;

                        case ActionTypes.SET_GUIDE_ANALYTICS_SURVEY_CHART_DATA:
                            resolve(setGuideAnalyticsSurveyChart(data));
                            break;

                        case ActionTypes.SET_GUIDE_ANALYTICS_PB_CHART_DATA:
                            resolve(setGuideAnalyticsPBChart(data));
                            break;

                        case ActionTypes.SET_GUIDE_ANALYTICS_DOITFORME_PB_CHART_DATA:
                            resolve(setGuideAnalyticsDoItForMePBChart(data));
                            break;
                            
                        case ActionTypes.SET_GUIDE_ANALYTICS_TUTORIAL_GUIDES_CHART_DATA:
                            resolve(setGuideAnalyticsTutorialGuidesChart(data));
                            break;

                        case ActionTypes.SET_GUIDE_ANALYTICS_SUMMARY_TABLE_DATA:
                            resolve(setGuideAnalyticsSummaryTable(data));
                            break;
                        default:
                            break;
                    }
                }

                if (data.result === 'retry') {
                    setTimeout(() => {
                        callActionDispatch();
                    }, RetryApi.TIMEOUT);
                } else {
                    callActionDispatch();
                }
            });
        } catch(err) {
            console.error('Error:', err.message);
        }
       
    });
}


