import { ApiRelativePaths, RetryApi } from "utils/GlobalConstants";
import * as ActionTypes from '../ActionTypes';
import axiosInstance, { getInstance } from "network/apis/interceptor";
import { CommonUtils } from "utils/CommonUtils";

let ApiPaths: any = ApiRelativePaths;

export const getUserVisit = (userVisit: any) => {
    return {
        type: ActionTypes.SET_FEATURE_DETAIL_HEATMAP,
        userVisit: userVisit
    }
};

export const getUserSystemStats = (userSystemData: any) => {
    return {
        type: ActionTypes.USAGE_STATS,
        userSystemData: userSystemData
    }
};

export const setFeatureDetailStatsChartData = (featureDetailStatsChartData: any) => {
    return {
        type: ActionTypes.SET_FEATURE_DETAIL_STATS_CHART,
        featureStatsChartData: featureDetailStatsChartData
    }
};

export const setFeatureList = (featureListData: any) => {
    return {
        type: ActionTypes.SET_FEATURE_LIST,
        featureListData: featureListData
    }
};

export const setUserEngagementStatsChartData = (userEngagementStatsChartData: any) => {
    return {
        type: ActionTypes.SET_USER_ENGAGEMENT_STATS_CHART,
        userEngagementStatsChartData: userEngagementStatsChartData
    }
};

export const setUserEngagementStatsSummaryData = (userEngagementStatsSummaryData: any) => { 
    return {
        type: ActionTypes.SET_USER_ENGAGEMENT_STATS_SUMMARY,
        userEngagementStatsSummaryData: userEngagementStatsSummaryData
    }
};

export const setUserEngagementFeatureDetailStats = (userEngagementFeatureDetailChartData: any) => {
    return {
        type: ActionTypes.SET_USER_ENGAGEMENT_FEATURE_DETAIL_STATS,
        userEngagementFeatureDetailChartData: userEngagementFeatureDetailChartData
    }
};


export const getFeatureDetailData = (params: any, url_path: any, caseType: any, apiErrorFlag: string, optionalData?:any, firstTimeload?: Boolean) => {
    return new Promise((resolve, reject) => {
        let path = ApiPaths[url_path];
        let data: any = {};
        let paramObj = {};
        let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
        getInstance('feature-detail')

        let urlData;

        if (optionalData !== '' && optionalData !== undefined) {
            urlData = generatedURL + optionalData;
        } else {
            urlData = generatedURL;
        }

        axiosInstance.get(urlData)
            .then(res => {
                data = {
                    "result": "success",
                    "data": res.data.data,
                };
            })
            .catch(err => {
                if (apiErrorFlag === '') {
                    data = {
                        "result": "retry",
                        "data": err,
                    };
                } else {
                    data = {
                        "result": "error",
                        "data": err,
                        "errorCode": err?.response?.status ?? '',
                    };
                }
            })
            .then(() => {
                const callActionDispatch = () => {
                    switch (caseType) {
                        case ActionTypes.USAGE_STATS:
                            resolve(getUserSystemStats(data));
                            break;
                        case ActionTypes.SET_FEATURE_DETAIL_STATS_CHART:
                            resolve(setFeatureDetailStatsChartData(data));
                            break;
                        case ActionTypes.SET_FEATURE_DETAIL_HEATMAP:
                            resolve(getUserVisit(data));
                            break;
                        case ActionTypes.SET_FEATURE_LIST:
                            resolve(setFeatureList(data));
                        break;                    
                    case ActionTypes.SET_USER_ENGAGEMENT_STATS_CHART:                      
                        resolve(setUserEngagementStatsChartData(data));
                        break;                    
                    case ActionTypes.SET_USER_ENGAGEMENT_STATS_SUMMARY:                      
                        resolve(setUserEngagementStatsSummaryData(data));
                        break;
                    case ActionTypes.SET_USER_ENGAGEMENT_FEATURE_DETAIL_STATS:                      
                        resolve(setUserEngagementFeatureDetailStats(data));
                            break;

                        default:
                            break;
                    }
                }

                if (data.result === 'retry') {
                    setTimeout(() => {
                        callActionDispatch();
                    }, RetryApi.TIMEOUT);
                } else {
                    callActionDispatch();
                }

            });
    });
}