import * as actionTypes from '../../Actions/ActionTypes';

export interface SurveyStatsDetailsState {
    surveyStatsDetailsBarChartData: any,
    surveyStatsDetailsQuestionsListData: any,
}

const initialState = {
    surveyStatsDetailsBarChartData: [],
    surveyStatsDetailsQuestionsListData: [],
};


const surveyStatsDetailsReducer = (state = initialState, action:any) => {
    switch (action.type) {
        case actionTypes.SET_SURVEY_STATS_DETAILS_BAR_CHART_DATA:
            return {
                ...state,
                surveyStatsDetailsBarChartData: action.surveyStatsDetailsBarChartData,
            };
        case actionTypes.SET_SURVEY_STATS_DETAILS_QUESTIONS_LIST_DATA:
            return {
                ...state,
                surveyStatsDetailsQuestionsListData: action.surveyStatsDetailsQuestionsListData,
            };

        default:
            return state;
    }
};

export default surveyStatsDetailsReducer;