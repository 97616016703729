import * as actionTypes from '../../Actions/ActionTypes';

export interface GuideAutomationState {
    guideAutomationLineChartData: any,
    guideAutomationSummaryTableData: any,
}

const initialState = {
    guideAutomationLineChartData: [],
    guideAutomationSummaryTableData: [],
};

const guideAutomationReducer = (state = initialState, action:any) => {
    switch (action.type) {
        case actionTypes.SET_GUIDE_AUTOMATION_LINE_CHART_DATA:
            return {
                ...state,
                guideAutomationLineChartData: action.guideAutomationLineChartData,
            };
        case actionTypes.SET_GUIDE_AUTOMATION_SUMMARY_TABLE_DATA:
            return {
                ...state,
                guideAutomationSummaryTableData: action.guideAutomationSummaryTableData,
            };

        default:
            return state;
    }
};

export default guideAutomationReducer;