import React, { useState, useEffect, useRef } from "react";
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation } from 'react-router-dom';

import { Form, FormGroup, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";


import * as passwordAction from "store/Actions/index";
import { useSelector, useDispatch } from "react-redux";
import { withReducer } from "store/withReducer";
import forgotPasswordReducer from "store/Reducers/Authentication/forgotPassword";
import { ForgotPasswordState } from "store/Reducers/Authentication/forgotPassword";


import qs from "querystring";

import mainLogo_1 from "assets/js/images/mainLogo_1";
import mainLogo_2 from "assets/js/images/mainLogo_2";


import Loader from "components/Loader/Loader";


import './Authentication.scss';


// import insightsLabels from "utils/stringConstants/trans_utils";
import { getSelectedLabels } from "utils/stringConstants/trans_utils";
import { InsightLabels } from "labels";
// let insightsLbls = insightsLabels;

// type PathParamsType = {
//     // param1: string,
// }

// // Your component own properties
// type props = RouteComponentProps<PathParamsType> & {
//     // someString: string,
// }

interface CurrentState {
    forgotPassword: ForgotPasswordState,
}


const ForgotPassword: React.FC = (props: any) => {

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)

    useEffect(() => {
        if (insightsLbls) {
            getSelectedLabels.then((data: InsightLabels) => {
                setInsightsLbls(data);
            });
        }
    }, [insightsLbls])

    const currentYear = new Date().getFullYear();
    const componentRef = useRef() as React.RefObject<HTMLDivElement>;
    let fetched_details = useSelector((state: CurrentState) => state.forgotPassword);
    const dispatch = useDispatch();

    const location = useLocation();
    const navigate = useNavigate()

    const [passwordData, setPasswordData] = useState({
        resendFlag: false,
        errorMsg: '',
        loader: false,
        resendMsg: ''
    })

    const [state, setStateValues] = useState({
        emailid: "",
        errors: "",
        errorFlag: false,
        btnDisabled: true
    });

    let urlData: any = {};

    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Forgot Password";
        const queryParams = {};
        navigate({
            pathname: `/password/`,
            search: qs.stringify(queryParams),
        });

        const query = new URLSearchParams(location.search);
        query.forEach((v, k) => {
            urlData[k] = v;
        });
    }, []);


    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const resetPassword = (from: string) => {
        setPasswordData((prevState: any) => {
            return {
                ...prevState,
                loader: true,
                resendMsg: from === 'button' ? 'Password reset Link has been sent' : 'Password reset Link has been resent'
            }
        })

        const data = {
            'email_id': state.emailid,
        };
        passwordAction.getForgotPasswordData(
            data,
            "FORGOT_PASSWORD",
        ).then((res: any) => dispatch(res));
    }


    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const handleChange = (e: any) => {
        let value = e.target.value;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setStateValues((prevState) => {
            return {
                ...prevState,
                emailid: value,
            };
        });

        let error = re.test(value) ? "" : "Please enter a valid email Id."
        setStateValues((prevState) => {
            return {
                ...prevState,
                errors: error,
                btnDisabled: error === 'Please enter a valid email Id.' ? true : false
            };
        });
    };

    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details?.forgotPasswordData?.data !== undefined && fetched_details?.forgotPasswordData?.result === 'success') {
            setPasswordData((prevState: any) => {
                return {
                    ...prevState,
                    loader: false,
                    resendFlag: true
                }
            })

        } else if (fetched_details?.forgotPasswordData?.result === "error") {
            setPasswordData((prevState: any) => {
                return {
                    ...prevState,
                    loader: false,
                    resendFlag: true
                }
            })
        }
    }, [fetched_details])





    return (
        <React.Fragment>
            <section className="forgotPassword" ref={componentRef}>

                <div className="loginContainer">
                    <div className="loginContainerParts">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 loginForm">
                            <React.Fragment>
                                <div className="loginLogo">
                                    <img className="mainLogo" alt="" src={process.env.REACT_APP_ENV === 'prod' ? mainLogo_1 : mainLogo_2} />
                                </div>

                                {passwordData.loader
                                    ? (
                                        <div className="login-loaderHeight width100 displayFlex height100-percent">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <Loader></Loader>
                                            </div>
                                        </div>
                                    )
                                    : (
                                        <>
                                            {passwordData.resendFlag
                                                ? (<div className="w-60">
                                                    <div className="email-sent-text text-center padding0">
                                                        <div className="mb-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                                                                <circle cx="30" cy="30" r="30" fill="#D7FCEF" />
                                                                <path
                                                                    d="M30 9C18.402 9 9 18.402 9 30C9 41.598 18.402 51 30 51C41.598 51 51 41.598 51 30C51 18.402 41.598 9 30 9ZM30 48.2903C19.9518 48.2903 11.7097 40.1566 11.7097 30C11.7097 19.9519 19.8434 11.7097 30 11.7097C40.0482 11.7097 48.2903 19.8434 48.2903 30C48.2903 40.0481 40.1566 48.2903 30 48.2903ZM41.9929 25.0073L26.7103 40.1673C26.3119 40.5625 25.6685 40.56 25.2733 40.1615L18.0014 32.8309C17.6062 32.4324 17.6087 31.7891 18.0071 31.3939L18.7286 30.6783C19.127 30.2831 19.7704 30.2856 20.1656 30.684L26.0063 36.5719L39.846 22.8431C40.2444 22.448 40.8878 22.4505 41.283 22.8489L41.9986 23.5704C42.3939 23.9688 42.3913 24.6121 41.9929 25.0073Z"
                                                                    fill="#38B6A0" />
                                                            </svg>
                                                        </div>
                                                        <h6><strong>{passwordData.resendMsg}</strong></h6>

                                                        <p className="font14">
                                                            If <b>{state.emailid}</b> is associated with {insightsLbls.brandName} ID, you
                                                            should receive an email containing instructions on how to create a new
                                                            password. This link will expire after one hour
                                                        </p>
                                                        <h6 className="mt-3"><strong>Didn't receive the email?</strong></h6>
                                                        <p className="font14">
                                                            Check spam or bulk folders for a message coming from
                                                            {insightsLbls.noReplyEmail}
                                                        </p>
                                                        <div className="mt-3">
                                                            <Link to="/login" className="font-weight-bolder theme-color" >Back to Login</Link>
                                                        </div>
                                                        <div className="my-3">
                                                            <button className="font-weight-bolder theme-color resendPassword" onClick={() => resetPassword('link')}>Resend Link</button>
                                                        </div>
                                                    </div>
                                                </div>)
                                                : (
                                                    <div className="w-50">
                                                        <h3 className="loginHead">Forgot Password</h3>
                                                        <div>
                                                            <Form
                                                                className="font14"
                                                                noValidate
                                                            >
                                                                <FormGroup controlId="formEmail">
                                                                    <div className="font700 marginBottom5">
                                                                        Email Id
                                                                    </div>
                                                                    <FormControl
                                                                        name="emailid"
                                                                        type="email"
                                                                        placeholder="name@domain.com"
                                                                        onInput={handleChange}
                                                                        autoComplete="off"
                                                                    />

                                                                    {state.errors.length != 0 && (<p className="errorMsg">{state.errors}</p>)}
                                                                </FormGroup>
                                                            </Form>

                                                            <FormGroup className="marginTop-25">
                                                                <button
                                                                    className="btn btn-fla modal-save-btn loginBtn width-100"
                                                                    onClick={() => resetPassword('button')}
                                                                    disabled={state.btnDisabled}>Reset Password</button>
                                                                <div className="loginRemember marginTop-10">
                                                                    <div className="pull-left d-flex align-items-center justify-content-between">
                                                                        <Link to={"/login"} className="passwordLink">Back to Login</Link>
                                                                    </div>
                                                                </div>
                                                            </FormGroup>


                                                        </div>

                                                    </div>
                                                )}

                                        </>
                                    )}

                                {/* [className.d-none]="!calling" */}
                                <small className="loginFooter">
                                    Need any help, please mail us at <a href={insightsLbls.supportLink} className="supportLink">{insightsLbls.displaySupportLink}</a><br />
                                    Copyright © {currentYear} - {insightsLbls.brandName} <br /> {insightsLbls.version}
                                </small>
                            </React.Fragment>
                        </div>
                        <div
                            className="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 d-none d-sm-none d-md-none d-lg-flex d-xl-flex align-items-center justify-content-center bg-login">
                            <img height="100%" width="100%" alt="" src={require(`${process.env.REACT_APP_SECTION_IMAGE}`)} />
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
export default withRouter(withReducer("forgotPassword", forgotPasswordReducer)(ForgotPassword));

