import * as actionTypes from '../../Actions/ActionTypes';

export interface ControlCenterState {
    controlCenterData: any,
}

const initialState = {
    controlCenterData: [],
};


const controlCenterReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SET_PREFERENCE_DATA:
            return {
                ...state,
                controlCenterData: action.controlCenterData,
            };

        default:
            return state;
    }
};

export default controlCenterReducer;