// Modified by: Mrunal Mane
// On: 2024-05-13
// Reason: Removed language dependancy from useEffect (surveyFilter.modalOpen)

import React, { useState, useEffect } from 'react';
import { Button, Dropdown } from "react-bootstrap";


import { useSelector } from 'react-redux';
import { DatesState, AppsState, LanguageState } from 'store/Reducers';
import { getCall } from 'utils/ApiCallActions';
import { SurveyStatsDetailsState } from 'store/Reducers/Survey/surveyStatsDetails';


import CommonModal from '../CommonModal';
import Loader from 'components/Loader/Loader';


import './SurveyFilter.scss';
import { RetryApi } from 'utils/GlobalConstants';
import { useErrorBoundary } from 'react-error-boundary';



import { InsightLabels } from 'labels';

interface CurrentState {
    apps: AppsState,
    dates: DatesState,
    surveyStatsDetails: SurveyStatsDetailsState,
    languageData: LanguageState
}


const SurveyFilter = (props: any) => {

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const { showBoundary } = useErrorBoundary();

    let fetched_details = useSelector((state: CurrentState) => state);

    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];

    const [firstTimeValue, setFirstTimeValue] = useState({
        triggerSource: "",
        guideId: 0,
        stepId: 0
    })

    let surveyTypeMap = {
        'all': insightsLbls.all,
        'guide_exit_sentiments': insightsLbls.exitGuideSurvey,
        'guide_sentiments': insightsLbls.guideSurvey,
        'guide_step_sentiments': insightsLbls.guideStepSurvey,
        'public_url': insightsLbls.publicURL,
        'tooltip': insightsLbls.tooltip,
        'generic_survey': insightsLbls.genericSurvey
    };

    //state to manage modal open/close, and hold api data when modal is opened
    const [surveyFilterModal, setSurveyFilterModal] = useState({
        open: false,
        apiResponse: [],
        dataFetched: false,
        serviceAvailable: 0,
    });

    //state to manage survey type
    const [surveyTypeFilter, setSurveyTypeFilter] = useState({
        isOpen: false,
        selectedSurveyType: 'All',
        triggerSource: "",
        menu: [],
    });

    //state to manage first level filter
    const [filter1TypeFilter, setFilter1TypeFilter] = useState({
        isOpen: false,
        selectedOption: 'Select Filter',
        menu: [],
        idmenu: [],
        guideId: 0,
        disabled: true,
    });

    //state to manage second level filter, this will be disabled exit guide survey and all
    const [filter2TypeFilter, setFilter2TypeFilter] = useState({
        isOpen: false,
        selectedOption: 'Select Filter',
        menu: [],
        idmenu: [],
        stepId: 0,
        disabled: true,
    });

    let SurveyTypeMenu = ['All'];

    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        if (surveyFilterModal.open) {
            getSurveyTypes();
            if (Object.keys(fetched_details.languageData.languageData).length) {
                SurveyTypeMenu = [];
                SurveyTypeMenu.push(insightsLbls.all);

                setSurveyTypeFilter((prevState: any) => {
                    return {
                        ...prevState,
                        selectedSurveyType: insightsLbls.all
                    }
                })

                setFilter1TypeFilter((prevState: any) => {
                    return {
                        ...prevState,
                        selectedOption: insightsLbls.selectFilter
                    }
                })

                setFilter2TypeFilter((prevState: any) => {
                    return {
                        ...prevState,
                        selectedOption: insightsLbls.selectFilter
                    }
                })

                surveyTypeMap = {
                    'all': insightsLbls.all,
                    'guide_exit_sentiments': insightsLbls.exitGuideSurvey,
                    'guide_sentiments': insightsLbls.guideSurvey,
                    'guide_step_sentiments': insightsLbls.guideStepSurvey,
                    'public_url': insightsLbls.publicURL,
                    'tooltip': insightsLbls.tooltip,
                    'generic_survey': insightsLbls.genericSurvey
                };
            }
        }
    }, [surveyFilterModal.open]);

    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getSurveyTypes = async (apiErrorFlag?: string) => {
        try {
            const params = {
                "app_code": fetched_details.apps.appCode,
                "time_zone": timeZone,
                "start_date": fetched_details.dates.fdate + "_00:00:00",
                "end_date": fetched_details.dates.realTime_tdate + "_23:59:59",
                "sentiment_code": props.surveyCode,
            }

            setFirstTimeValue({
                triggerSource: props.triggerSource,
                guideId: props.guideId != "" ? props.guideId : 0,
                stepId: props.stepId != "" ? props.stepId : 0
            })

            getCall(params, 'SENTIMENT_STATS_FILTER', apiErrorFlag ?? '').then((res: any) => {
                if (res.result === 'success') {
                    let resultTypes = "";
                    for (let i = 0; i < res.data.filters.length; i++) {
                        // Use the map to retrieve the resultTypes based on surveyTypeName
                        resultTypes = surveyTypeMap[res.data.filters[i].surveyType] || null;
                        if (!SurveyTypeMenu.includes(resultTypes)) {
                            SurveyTypeMenu.push(resultTypes);
                        }
                    }

                    setSurveyFilterModal((prevState: any) => {
                        return {
                            ...prevState,
                            apiResponse: res.data.filters,
                            dataFetched: true,
                            serviceAvailable: 1,
                        };
                    });

                    setSurveyTypeFilter((prevState: any) => {
                        return {
                            ...prevState,
                            menu: SurveyTypeMenu,
                        };
                    });

                    if (props.triggerSource != "") {
                        selectSurveyType(surveyTypeMap[props.triggerSource]);
                        setFirstTimeValue(prevState => {
                            return {
                                ...prevState,
                                triggerSource: ""
                            }
                        })
                    }
                } else if (res.result === 'retry') {
                    setTimeout(() => {
                        getSurveyTypes('retry')
                    }, RetryApi.TIMEOUT)
                } else {
                    setSurveyFilterModal((prevState: any) => {
                        return {
                            ...prevState,
                            dataFetched: true,
                            serviceAvailable: 0,
                        };
                    });
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    }

    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const openSurveyFilterModal = () => {
        setSurveyFilterModal((prevState) => {
            return {
                ...prevState,
                open: true
            }
        })
    }

    const closeSurveyFilterModal = () => {
        setSurveyFilterModal({
            open: false,
            apiResponse: [],
            dataFetched: false,
            serviceAvailable: 0,
        });
        setSurveyTypeFilter({
            isOpen: false,
            selectedSurveyType: 'All',
            triggerSource: "",
            menu: [],
        });
        setFilter1TypeFilter({
            isOpen: false,
            selectedOption: 'Select Filter',
            menu: [],
            idmenu: [],
            guideId: 0,
            disabled: true,
        });
        setFilter2TypeFilter({
            isOpen: false,
            selectedOption: 'Select Filter',
            menu: [],
            idmenu: [],
            stepId: 0,
            disabled: true,
        });
    }

    const applySurveyFilterModal = () => {
        try {
            props.setFilterValues(surveyTypeFilter.triggerSource, filter1TypeFilter.guideId, filter2TypeFilter.stepId)
            setSurveyFilterModal((prevState: any) => {
                return {
                    ...prevState,
                    open: false
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const toggleDropDown = (event: boolean) => {
        try {
            setSurveyTypeFilter((prevState: any) => {
                return {
                    ...prevState,
                    isOpen: event,
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    const toggleDropDown1 = (event: boolean) => {
        try {
            setFilter1TypeFilter((prevState: any) => {
                return {
                    ...prevState,
                    isOpen: event,
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    const toggleDropDown2 = (event: boolean) => {
        try {
            setFilter2TypeFilter((prevState: any) => {
                return {
                    ...prevState,
                    isOpen: event,
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    const selectSurveyType = (surveyType: string) => {
        try {
            const getKeyByValue = (value) => Object.keys(surveyTypeMap).find(key => surveyTypeMap[key] === value) || null;

            setSurveyTypeFilter((prevState: any) => {
                return {
                    ...prevState,
                    triggerSource: getKeyByValue(surveyType),
                    isOpen: false,
                    selectedSurveyType: surveyType,
                };
            });

            setFilter1TypeFilter(prevState => ({
                ...prevState,
                selectedOption: insightsLbls.selectFilter,
                menu: [],
                idmenu: [],
                guideId: 0,
                disabled: [insightsLbls.all, insightsLbls.publicURL].includes(surveyType),
            }));

            setFilter2TypeFilter(prevState => ({
                ...prevState,
                selectedOption: insightsLbls.selectFilter,
                menu: [],
                idmenu: [],
                stepId: 0,
                disabled: true,
            }));
        } catch (error) {
            showBoundary(error)
        }
    };

    const selectFilter1Type = (guideId: number, guideName: string) => {
        try {
            setFilter1TypeFilter((prevState: any) => {
                return {
                    ...prevState,
                    isOpen: false,
                    guideId: guideId,
                    selectedOption: guideName
                };
            });
            setFilter2TypeFilter((prevState: any) => {
                return {
                    ...prevState,
                    disabled: surveyTypeFilter.triggerSource === "guide_exit_sentiments",
                };
            });
        } catch (error) {
            showBoundary(error)
        }
    };

    const selectFilter2Type = (stepId: number, stepName: string) => {
        try {
            setFilter2TypeFilter((prevState: any) => {
                return {
                    ...prevState,
                    isOpen: false,
                    stepId: stepId,
                    selectedOption: stepName
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    useEffect(() => {
        try {
            if (filter1TypeFilter.disabled === false) {
                let guideId: number[] = [];
                let guideNames: string[] = [];
                let apiResponse: any[] = surveyFilterModal.apiResponse
                for (let i = 0; i < surveyFilterModal.apiResponse.length; i++) {
                    if (apiResponse[i].guideID && apiResponse[i].surveyType === surveyTypeFilter.triggerSource) {
                        let addId = false;
                        if (guideId.length === 0) {
                            guideId.push(apiResponse[i].guideID)
                            guideNames.push(apiResponse[i].guideTitle)
                        }
                        else {
                            for (let j = 0; j < guideId.length; j++) {
                                if (guideId[j] === apiResponse[i].guideID) {
                                    addId = false;
                                    break;
                                }
                                else {
                                    addId = true;
                                }
                            }
                            if (addId === true) {
                                guideId.push(apiResponse[i].guideID)
                                guideNames.push(apiResponse[i].guideTitle)
                            }
                        }

                    }
                }

                setFilter1TypeFilter((prevState: any) => {
                    return {
                        ...prevState,
                        menu: guideNames,
                        idmenu: guideId,
                    };
                });
                if (firstTimeValue.guideId != 0) {
                    let index = guideId.findIndex(id => id == firstTimeValue.guideId);
                    selectFilter1Type(firstTimeValue.guideId, index != -1 ? guideNames[index] : insightsLbls.selectFilter)
                    setFirstTimeValue(prevState => {
                        return {
                            ...prevState,
                            guideId: 0
                        }
                    })
                }

            }
        } catch (error) {
            showBoundary(error)
        }

    }, [surveyTypeFilter.triggerSource])

    useEffect(() => {
        try {
            if (filter2TypeFilter.disabled === false) {
                let stepId: number[] = [];
                let stepNames: string[] = [];
                let apiResponse: any[] = surveyFilterModal.apiResponse

                for (let i = 0; i < surveyFilterModal.apiResponse.length; i++) {
                    if (apiResponse[i].guideID === filter1TypeFilter.guideId && apiResponse[i].surveyType === surveyTypeFilter.triggerSource) {
                        let addId = false;
                        if (stepId.length === 0) {
                            stepId.push(apiResponse[i].stepID)
                            stepNames.push(apiResponse[i].stepTitle)
                        }
                        else {
                            for (let j = 0; j < stepId.length; j++) {
                                if (stepId[j] === apiResponse[i].stepID) {
                                    addId = false;
                                    break;
                                }
                                else {
                                    addId = true;
                                }
                            }
                            if (addId === true) {
                                stepId.push(apiResponse[i].stepID)
                                stepNames.push(apiResponse[i].stepTitle)
                            }
                        }
                    }
                }

                setFilter2TypeFilter((prevState: any) => {
                    return {
                        ...prevState,
                        menu: stepNames,
                        idmenu: stepId,
                    };
                });

                if (firstTimeValue.stepId != 0) {
                    let index = stepId.findIndex(id => id == firstTimeValue.stepId);
                    selectFilter2Type(firstTimeValue.stepId, index != -1 ? stepNames[index] : insightsLbls.selectFilter)
                    setFirstTimeValue(prevState => {
                        return {
                            ...prevState,
                            stepId: 0
                        }
                    })
                }
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [filter1TypeFilter.guideId])

    const getSource = (value: string) => value === 'All' ? insightsLbls.all : value;

    return (
        <>
            <div className="SurveyFilterButton marginRight-5">
                <div>
                    <div className="dateRange-title">Survey Filter</div>
                    <Button className="secondary-btn-square cursorPointer" variant="customBorder" onClick={openSurveyFilterModal}>
                        <span className="displayFlex alignCenter justifyContent">
                            <i className="fa fa-filter"></i>
                            {insightsLbls.filter}
                        </span>
                    </Button>
                </div>
            </div>

            <CommonModal
                modalState={surveyFilterModal.open}
                dispatchModalState={closeSurveyFilterModal}
                modalTitle={insightsLbls.surveyFilter}
                dispatchModalCancel={closeSurveyFilterModal}
                dispatchModalOk={applySurveyFilterModal}
                okBtn={insightsLbls.apply}
                cancelBtn={insightsLbls.cancel}
                size={'md'}
                exportModal={false}
                padding={false}
                modalDialogClass={'SurveyFilter-modal'}>
                {
                    (surveyFilterModal.dataFetched === true && Object.keys(fetched_details.languageData.languageData).length) ?
                        (
                            surveyFilterModal.serviceAvailable === 1 ?
                                (
                                    surveyFilterModal.apiResponse.length !== 0 ?
                                        (
                                            <div className="SurveyFilterModalBody">
                                                <div className="SurveyTypeDiv">
                                                    <div className="SurveyTypeDivTitle">
                                                        {insightsLbls.surveyType}:
                                                    </div>
                                                    <div className="SurveyTypeDropdownDiv">
                                                        <Dropdown show={surveyTypeFilter.isOpen} onToggle={(event) => toggleDropDown(event)}>
                                                            <Dropdown.Toggle className={"btn btn-lg btn-outline-primary filter-dropdown"}>
                                                                <span className="dropdown-label">{getSource(surveyTypeFilter.selectedSurveyType)}</span>
                                                                <i aria-hidden="true" className={"switchApp-btn-icon " + (surveyTypeFilter.isOpen ? "fa fa-chevron-up" : "fa fa-chevron-down")}></i>
                                                            </Dropdown.Toggle>
                                                            {
                                                                surveyTypeFilter.isOpen === true ?
                                                                    (
                                                                        <Dropdown.Menu className={"dropdown-menu"}>
                                                                            {
                                                                                surveyTypeFilter.menu.map((source: any, index: number) => {
                                                                                    return (
                                                                                        <div className={`custom-control ${surveyTypeFilter.selectedSurveyType === source ? 'active' : ''}`}
                                                                                            key={index} onClick={() => selectSurveyType(source)}>
                                                                                            <label>
                                                                                                <span>{getSource(source)}</span>
                                                                                            </label>
                                                                                        </div>
                                                                                    );

                                                                                })
                                                                            }
                                                                        </Dropdown.Menu>
                                                                    )
                                                                    :
                                                                    (null)
                                                            }
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <div className="FilterDiv">
                                                    <span className="FilterDivTitle">
                                                        {insightsLbls.filter1Placeholder}:
                                                    </span>
                                                    <div className="FilterDivDropDown">
                                                        <Dropdown show={filter1TypeFilter.isOpen} onToggle={(event) => toggleDropDown1(event)}>
                                                            <Dropdown.Toggle className={"btn btn-lg btn-outline-primary filter-dropdown"} disabled={filter1TypeFilter.disabled}>
                                                                <span className="dropdown-label">{filter1TypeFilter.selectedOption}</span>
                                                                <i aria-hidden="true" className={"switchApp-btn-icon " + (filter1TypeFilter.isOpen ? "fa fa-chevron-up" : "fa fa-chevron-down")}></i>
                                                            </Dropdown.Toggle>
                                                            {
                                                                filter1TypeFilter.isOpen === true ?
                                                                    (
                                                                        <Dropdown.Menu className={"dropdown-menu"}>
                                                                            {
                                                                                filter1TypeFilter.menu.map((source: any, index: number) => {
                                                                                    return (
                                                                                        <div className={`custom-control ${filter1TypeFilter.selectedOption === source ? 'active' : ''}`}
                                                                                            key={index} onClick={() => selectFilter1Type(filter1TypeFilter.idmenu[index], filter1TypeFilter.menu[index])}>
                                                                                            <label>
                                                                                                <span>{source}</span>
                                                                                            </label>
                                                                                        </div>
                                                                                    );

                                                                                })
                                                                            }
                                                                        </Dropdown.Menu>
                                                                    )
                                                                    :
                                                                    (null)
                                                            }
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <div className="FilterDiv">
                                                    <span className="FilterDivTitle">
                                                        {insightsLbls.filter2Placeholder}:
                                                    </span>
                                                    <div className="FilterDivDropDown">
                                                        <Dropdown show={filter2TypeFilter.isOpen} onToggle={(event) => toggleDropDown2(event)}>
                                                            <Dropdown.Toggle className={"btn btn-lg btn-outline-primary filter-dropdown"} disabled={filter2TypeFilter.disabled || (filter2TypeFilter.menu.length === 1 && filter2TypeFilter.menu[0] === undefined)}>
                                                                <span className="dropdown-label">{filter2TypeFilter.selectedOption}</span>
                                                                <i aria-hidden="true" className={"switchApp-btn-icon " + (filter2TypeFilter.isOpen ? "fa fa-chevron-up" : "fa fa-chevron-down")}></i>
                                                            </Dropdown.Toggle>
                                                            {
                                                                filter2TypeFilter.isOpen === true ?
                                                                    (
                                                                        <Dropdown.Menu className={"dropdown-menu"}>
                                                                            {
                                                                                filter2TypeFilter.menu.map((source: any, index: number) => {
                                                                                    return (
                                                                                        <div className={`custom-control ${filter2TypeFilter.selectedOption === source ? 'active' : ''}`}
                                                                                            key={index} onClick={() => selectFilter2Type(filter2TypeFilter.idmenu[index], filter2TypeFilter.menu[index])}>
                                                                                            <label>
                                                                                                <span>{source}</span>
                                                                                            </label>
                                                                                        </div>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </Dropdown.Menu>
                                                                    )
                                                                    :
                                                                    (null)
                                                            }
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        :
                                        (
                                            <div className="loaderHeight width100 displayFlex height100-percent minHeight320">
                                                <div className="displayFlex alignCenter margin-0-auto font-weight-400">
                                                    {insightsLbls.noRecordFound}
                                                </div>
                                            </div>
                                        )
                                )
                                :
                                (
                                    <div className="loaderHeight width100 displayFlex height100-percent minHeight320">
                                        <div className="displayFlex alignCenter margin-0-auto font-weight-400">
                                            {insightsLbls.serviceNotAvailable}
                                        </div>
                                    </div>
                                )
                        )
                        :
                        (
                            <div className="loaderHeight width100 displayFlex height100-percent top-30 minHeight320">
                                <div className="displayFlex alignCenter margin-0-auto">
                                    <Loader></Loader>
                                </div>
                            </div>
                        )
                }

            </CommonModal>
        </>
    )

};

export default React.memo(SurveyFilter);
