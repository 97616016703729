import * as actionTypes from '../../Actions/ActionTypes';

export interface SearchState {
    searchChartData: any,
    searchStatsData: any,
    searchSummary: any,
    searchUserList: any,
}

const initialState = {
    searchChartData: [],
    searchStatsData: [],
    searchSummary: [],
    searchUserList: [],
};


const searchReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SET_SEARCH_CHART_DATA:
            return {
                ...state,
                searchChartData: action.searchChartData,
            };

        case actionTypes.SET_SEARCH_STATS_DATA:
            return {
                ...state,
                searchStatsData: action.searchStatsData,
            };

        case actionTypes.SET_SEARCH_SUMMARY:
            return {
                ...state,
                searchSummary: action.searchSummary,
            };

        case actionTypes.SET_SEARCH_USER_LIST:
            return {
                ...state,
                searchUserList: action.searchUserList,
            };

        default:
            return state;
    }
};

export default searchReducer;