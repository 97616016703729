// Modified by: Mrunal Mane
// On: 2024-05-10 05:48 PM
// Reason: Removed language dependancy from useEffect initial state render

import React from 'react';
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { LanguageState } from "../../store/Reducers";
import { useErrorBoundary } from 'react-error-boundary';
import $ from "jquery";
import Loader from "../Loader/Loader";
import CardContainer from "../Layouts/CardContainer";
import Piechart from "../Chart/apexCharts/ApexPieChart";
import { InsightLabels } from 'labels';
import "../../containers/AppInsights/Pages/Page.scss";

interface CurrentState {
    languageData: LanguageState
}
const BrowserStats = (props: any) => {
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const { showBoundary } = useErrorBoundary();
    let fetched_details = useSelector((state: CurrentState) => state);

    const [usageData, setUsageData] = useState({
        switchOption: 1,
        pieChartDataFetched: false,
        usageTableDataFetched: false,
        display_data: [],
        sortKeyColumnBrowser: 'count',
        sortKeyBrowser: 'asc',
        switchName: 'browser',
        usageSection: 'browser',
    })

    const [singleLoader, setSingleLoader] = useState({
        loader1: false,
    })
    const [tableLoader, setTableLoader] = useState({
        loader: false,
    })

    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    const sortBrowserStats = (value: any) => {
        try {
            setTableLoader((prevState: any) => {
                return {
                    ...prevState,
                    loader: false,
                }
            })

            let sort_key_browser: any;

            if (usageData.sortKeyColumnBrowser === value) {
                if (usageData.sortKeyBrowser === 'desc') {
                    sort_key_browser = 'asc';
                } else {
                    sort_key_browser = 'desc';
                }
            } else {
                sort_key_browser = 'asc';
            }

            setUsageData((prevState: any) => {
                return {
                    ...prevState,
                    sortKeyBrowser: sort_key_browser,
                    sortKeyColumnBrowser: value,
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    }

    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // On props change 
    useEffect(() => {
        try {
            let a = 0, b = 0, c = 0, d = 0;
            let data: any = [];
            if (props.loading) {
                setUsageData((prevState: any) => {
                    return {
                        ...prevState,
                        pieChartDataFetched: false,
                        usageTableDataFetched: false,
                    }
                });
            } else {
                for (let i = 0; i < props.statsData.length; i++) {
                    if (props?.statsData[i]?.type === props.type && props.type === 'browser') {

                        data[a] = [];
                        data[a] = props?.statsData[i];

                        let src;
                        if (props?.statsData[i]?.name !== null && props?.statsData[i]?.name !== null && (props?.statsData[i]?.name).toLowerCase() === ('internet explorer' || 'edge')) {
                            src = 'edge.png';
                        } else if (props?.statsData[i]?.name !== null && (props?.statsData[i]?.name).toLowerCase() === 'chrome') {
                            src = 'chrome.png';
                        } else if (props?.statsData[i]?.name !== null && (props?.statsData[i]?.name).toLowerCase() === 'firefox') {
                            src = 'firefox.png';
                        } else {
                            src = 'browser.png';
                        }
                        data[a].key = src;
                        a++;
                    } else if (props?.statsData[i]?.type === props.type && props.type === 'os') {
                        data[b] = [];
                        data[b] = props?.statsData[i];

                        let src;
                        if (props?.statsData[i]?.name !== null && (props?.statsData[i]?.name).toLowerCase() === ('mac')) {
                            src = 'mac.png';
                        } else if (props?.statsData[i]?.name !== null && (props?.statsData[i]?.name).toLowerCase() === 'windows') {
                            src = 'windows.png';
                        } else if (props?.statsData[i]?.name !== null && (props?.statsData[i]?.name).toLowerCase() === 'linux') {
                            src = 'linux.png';
                        } else {
                            src = 'os.png';
                        }
                        data[b].key = src;
                        b++;
                    } else if (props?.statsData[i]?.type === props.type && props.type === 'screen_resolution') {
                        data[c] = [];
                        data[c] = props?.statsData[i];

                        let src;
                        if (props?.statsData[i]?.name !== null && (props?.statsData[i]?.name).toLowerCase() === ('mobile')) {
                            src = 'mobile.png';
                        } else if (props?.statsData[i]?.name !== null && (props?.statsData[i]?.name).toLowerCase() === 'laptop') {
                            src = 'laptop.png';
                        } else if (props?.statsData[i]?.name !== null && (props?.statsData[i]?.name).toLowerCase() === 'tablet') {
                            src = 'tablet.png';
                        } else {
                            src = 'devices.png';
                        }
                        data[c].key = src;
                        c++;
                    } else if (props?.statsData[i]?.type === props.type) {
                        data[d] = [];
                        data[d] = props?.statsData[i];
                        data[d].key = '';
                        d++;
                    }
                }


                setUsageData((prevState: any) => {
                    return {
                        ...prevState,
                        display_data: data,
                        switchName: props.type,
                        usageSection: props.type,
                    }
                })

                // this.setState({ loader2: true });
                // this.enableExportbtn();
                $('.tableBodyScroll tbody').css('overflow-y', 'auto');
                const el1 = document.getElementsByClassName("table")[0] as HTMLInputElement;
                if (el1 !== undefined) {
                    el1.classList.add('loading');
                }



                setUsageData((prevState: any) => {
                    return {
                        ...prevState,
                        pieChartDataFetched: true,
                        usageTableDataFetched: true,
                    }
                })
                setTableLoader((prevState: any) => {
                    return {
                        ...prevState,
                        loader: true,
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

        // this.setState({ loader2: true });
        // this.enableExportbtn();
        $('.tableBodyScroll tbody').css('overflow-y', 'auto');
        const el1 = document.getElementsByClassName("table")[0] as HTMLInputElement;
        if (el1 !== undefined) {
            el1.classList.add('loading');
        }



        setUsageData((prevState: any) => {
            return {
                ...prevState,
                pieChartDataFetched: true,
                usageTableDataFetched: true,
            }
        })
        setTableLoader((prevState: any) => {
            return {
                ...prevState,
                loader: true,
            }
        })

    }, [props])


    // call parent function on data update
    useEffect(() => {
        try {
            if (usageData.display_data.length > 0) {
                props.calUsageData(false, usageData.switchName, usageData.sortKeyColumnBrowser, usageData.sortKeyBrowser);
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [usageData.sortKeyColumnBrowser, usageData.sortKeyBrowser])

    // Loader
    useEffect(() => {
        try {
            if (usageData.pieChartDataFetched && usageData.usageTableDataFetched) {
                setSingleLoader((prevState: any) => {
                    return {
                        ...prevState,
                        loader1: true,
                    }
                })
            }
            else {
                setSingleLoader((prevState: any) => {
                    return {
                        ...prevState,
                        loader1: false,
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [usageData.pieChartDataFetched, usageData.usageTableDataFetched]);

    return (
        <React.Fragment>
            {singleLoader.loader1 ?
                (
                    props.serviceAvailable == 0 ? (
                        <div className="dashboardTiles section-wrapper borderTop borderBottom col-lg-12 page_section padding0 height535 blockImportant boxLayout">
                            <div className="col-md-5 col-xs-12 padding0">
                                <CardContainer
                                    div1Classes={'icon-text chart-wrapper5 borderTop borderBottom marginTop-0'}
                                    div2Classes={'card-body card-body-2 overflowHidden'}
                                    div3Classes={'col-sm-12 nopadding graphWidth height100-percent margin-0-auto alignCenter'}>
                                    {usageData.display_data.length === 0 ? (
                                        <div className="loaderBackground loaderHeight width100 displayFlex height100-percent positionRelative minHeight250 marginTop-20">
                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                <div className="noDataText-font textCenter-absolute">
                                                    {insightsLbls.noRecordFound}
                                                </div>
                                            </h5>
                                        </div>
                                    )
                                        : (<div>
                                            <Piechart pieData={usageData.display_data} name={usageData.switchName} legendTooltip={false} />
                                        </div>
                                        )}
                                </CardContainer>
                            </div>
                            <div className="col-md-7 col-xs-12 padding0 leftBorder">
                                <CardContainer
                                    div1Classes={'icon-text chart-wrapper5 borderTop borderBottom marginTop-0'}
                                    div2Classes={'card-body card-body-2'}
                                    div3Classes={'col-sm-12 nopadding graphWidth height100-percent usageTable'}>
                                    {usageData.display_data.length === 0 ? (
                                        <div className="loaderBackground loaderHeight width100 displayFlex height100-percent positionRelative minHeight290">
                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                <div className="noDataText-font textCenter-absolute">
                                                    {insightsLbls.noRecordFound}
                                                </div>
                                            </h5>
                                        </div>
                                    )
                                        : (
                                            <table className="table color_table tableBodyScroll appUsage-dashboard-table">
                                                <thead className="bg-secondary sorting-header">
                                                    <tr>
                                                        <th className="th-left-border paddingLeft-20">{insightsLbls.name}
                                                            <span className="hand-pointer displayInlineBlock"
                                                                onClick={() => sortBrowserStats('name')}>
                                                                <i className={(usageData.sortKeyColumnBrowser === 'name' && usageData.sortKeyBrowser === 'asc') ?
                                                                    'active-i fa fa-sort-asc height0 displayBlock' :
                                                                    'fa fa-sort-asc height0 displayBlock'}>
                                                                </i>
                                                                <i className={(usageData.sortKeyColumnBrowser === 'name' && usageData.sortKeyBrowser === 'desc') ?
                                                                    'active-i fa fa-sort-desc height0 displayBlock' :
                                                                    'fa fa-sort-desc height0 displayBlock'}></i>
                                                            </span>
                                                        </th>
                                                        {props.type !== 'screen_resolution' && (<th>{insightsLbls.buildversion}</th>)}
                                                        <th>{insightsLbls.visitors}
                                                            <span className="hand-pointer displayInlineBlock"
                                                                onClick={() => sortBrowserStats('count')}>
                                                                <i className={(usageData.sortKeyColumnBrowser === 'count' && usageData.sortKeyBrowser === 'asc') ?
                                                                    'active-i fa fa-sort-asc height0 displayBlock' :
                                                                    'fa fa-sort-asc height0 displayBlock'}></i>
                                                                <i className={(usageData.sortKeyColumnBrowser === 'count' && usageData.sortKeyBrowser === 'desc') ?
                                                                    'active-i fa fa-sort-desc height0 displayBlock' :
                                                                    'fa fa-sort-desc height0 displayBlock'} ></i>
                                                            </span>
                                                        </th>
                                                        <th className="th-right-border">{insightsLbls.visits}
                                                            <span className="hand-pointer displayInlineBlock"
                                                                onClick={() => sortBrowserStats('visitors')}>
                                                                <i className={(usageData.sortKeyColumnBrowser === 'visitors' && usageData.sortKeyBrowser === 'asc') ?
                                                                    'active-i fa fa-sort-asc height0 displayBlock' :
                                                                    'fa fa-sort-asc height0 displayBlock'}></i>
                                                                <i className={(usageData.sortKeyColumnBrowser === 'visitors' && usageData.sortKeyBrowser === 'desc') ?
                                                                    'active-i fa fa-sort-desc height0 displayBlock' :
                                                                    'fa fa-sort-desc height0 displayBlock'} ></i>
                                                            </span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tableLoader.loader ? (
                                                        usageData.display_data.map((data: any, key: any) => {
                                                            return (
                                                                <tr key={key} id={`${key}`}>
                                                                    <td className={`paddingLeft-20 ${props.type !== 'screen_resolution' ? ' width40' : ' width30'}`}>
                                                                        {(data.key !== '') ?
                                                                            (<img className="pagesIcons" height="20" width="20"
                                                                                src={require(`../../assets/images/pages-icons/${data.key}`)} />) : null}
                                                                        {" " + data.name}
                                                                    </td>
                                                                    {props.type !== 'screen_resolution' && (<td>{data.version}</td>)}
                                                                    <td>{data.count}</td>
                                                                    <td>{data.visitors}</td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) :
                                                        (
                                                            <Loader></Loader>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        )}
                                </CardContainer>

                            </div>
                        </div>)
                        : (
                            <div className="loaderBackground loaderHeight width100 displayFlex height100-percent positionRelative minHeight340">
                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                    <div className="noDataText-font textCenter-absolute">
                                        {insightsLbls.serviceNotAvailable}
                                    </div>
                                </h5>
                            </div>)
                )
                : (<div className="loaderBackground loaderHeight width100 displayFlex height100-percent positionRelative minHeight340">
                    <div className="displayFlex alignCenter margin-0-auto">
                        <Loader></Loader>
                    </div>
                </div>)
            }

            {/* </CardContainer> */}

        </React.Fragment>




    )
}

export default React.memo(BrowserStats)
