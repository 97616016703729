import * as actionTypes from '../../Actions/ActionTypes';

export interface ManageGoalState {
    goalListData: any,
    goalData: any,
    goalGuideList: any,
    goalTooltipList: any,
    goalTitleCheck: any
}

const initialState = {
    goalListData: [],
    goalData: [],
    goalGuideList: [],
    goalTooltipList: [],
    goalTitleCheck: [],
};


const manageGoalsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SET_GOAL_LIST_DATA:
            return {
                ...state,
                goalListData: action.goalListData,
            };

        case actionTypes.SET_GOAL_DATA:
            return {
                ...state,
                goalData: action.goalData,
            };

        case actionTypes.SET_GOAL_GUIDE_LIST:
            return {
                ...state,
                goalGuideList: action.goalGuideList,
            };
        
        case actionTypes.SET_GOAL_TOOLTIP_LIST:
            return {
                ...state,
                goalTooltipList: action.goalTooltipList,
            };

        case actionTypes.SET_CHECK_GOAL_TITLE:
                return {
                    ...state,
                    goalTitleCheck: action.goalTitleCheck,
                };

        default:
            return state;
    }
};

export default manageGoalsReducer;