import * as actionTypes from '../../Actions/ActionTypes';

export interface GuideAutomationDetailsState {
    guideAutomationDescriptionTableData: any,
}

const initialState = {
    guideAutomationDescriptionTableData: [],
};

const guideAutomationDetailsReducer = (state = initialState, action:any) => {
    switch (action.type) {
        case actionTypes.SET_GUIDE_AUTOMATION_DETAILS_DESCRIPTION_TABLE_DATA:
            return {
                ...state,
                guideAutomationDescriptionTableData: action.guideAutomationDescriptionTableData,
            };

        default:
            return state;
    }
};

export default guideAutomationDetailsReducer;