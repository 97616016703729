import * as actionTypes from '../../Actions/ActionTypes';


export interface ForgotPasswordState {
    forgotPasswordData: any
}

const initialState = {
    forgotPasswordData: [],
};

const forgotPasswordReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SET_FORGOT_PASSWORD_DATA:
            return {
                ...state,
                forgotPasswordData: action.forgotPasswordData,
            };
        default:
            return state;
    }
};

export default forgotPasswordReducer;
