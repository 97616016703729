import React from "react";
import "./SectionHeader.scss";


const SectionRefresh = (props: any) => {

    return (
        <div className={props.refreshClass+" col-md-12 col-sm-12 sectionSub-heading padding0"}>
            <h4 className="sectionTitle section-heading headingText displayFlex alignCenter">
                <div className="col-sm-5 section-col-div">
                    {props.sectionTitle?(props.sectionTitle):null}
                </div>
                <div className="col-sm-7 padding0">
                    {props.children?(props.children):null}
                </div>
            </h4>
        </div>

    )
}

export default SectionRefresh;