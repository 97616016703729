import * as ActionTypes from '../ActionTypes';
import axiosInstance, { getInstance } from '../../../network/apis/interceptor';
import { CommonUtils } from '../../../utils/CommonUtils';
import { ApiRelativePaths, RetryApi } from '../../../utils/GlobalConstants';

let ApiPaths: any = ApiRelativePaths;
export const setBlockChart = (blockChartData: any) => {
    return {
        type: ActionTypes.SET_BLOCK_CHART,
        blockChartData: blockChartData
    }
};

export const setUsageData = (usageData: any) => {
    return {
        type: ActionTypes.SET_USAGE_DATA,
        usageData: usageData
    }
};

export const setPageSummaryUntagged = (summaryDataUntagged: any) => {
    return {
        type: ActionTypes.SET_PAGE_SUMMARY_UNTAGGED,
        summaryDataUntagged: summaryDataUntagged
    }
};

export const setPageSummaryTagged = (summaryDataTagged: any) => {
    return {
        type: ActionTypes.SET_PAGE_SUMMARY_TAGGED,
        summaryDataTagged: summaryDataTagged
    }
};

export const setLabelList = (labelList: any) => {
    return {
        type: ActionTypes.SET_LABEL_LIST,
        labelList: labelList
    }
};

export const postTag = (tagData: any) => {
    return {
        type: ActionTypes.POST_TAG,
        tagData: tagData
    }
}

// export const onPageEnter = (onPageEnter:any) => {
//   return {
//     type: ActionTypes.ON_PAGE_ENTER,
//     onPageEnter: true,
//   };
// };

export const getPageData = (params: any, url_path: any, caseType: any, apiErrorFlag: string, firstTimeload?: boolean) => {
    return new Promise((resolve, reject) => {
        let path = ApiPaths[url_path];
        let data:any = {};
        let paramObj = {};
        let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
        getInstance('pages')

        axiosInstance.get(generatedURL)
            .then(res => {
                data = {
                  "result": "success",
                  "data": res.data.data,
                  "firstTimeload": firstTimeload ?? false,
                  "requestUUID": res.data.requestUUID,
                };
            })
            .catch(err => {
                if (apiErrorFlag === '') {
                    data = {
                        "result": "retry",
                        "data": err,
                        "firstTimeload": firstTimeload ?? false,
                    };
                } else {
                    data = {
                        "result": "error",
                        "data": err,
                        "firstTimeload": firstTimeload ?? false,
                    };
                }
            })
            .then(() => {
                const callActionDispatch = () => {
                    switch (caseType) {
                        case ActionTypes.SET_BLOCK_CHART:
                            resolve(setBlockChart(data));
                            break;

                        case ActionTypes.SET_USAGE_DATA:
                            resolve(setUsageData(data));
                            break;

                        case ActionTypes.SET_PAGE_SUMMARY_UNTAGGED:
                            resolve(setPageSummaryUntagged(data));
                            break;

                        case ActionTypes.SET_PAGE_SUMMARY_TAGGED:
                            resolve(setPageSummaryTagged(data));
                            break;

                        case ActionTypes.SET_LABEL_LIST:
                            resolve(setLabelList(data));
                            break;

                        default:
                            break;
                    }
                }
                

                if (data.result === 'retry') {
                    setTimeout(() => {
                        callActionDispatch();
                    }, RetryApi.TIMEOUT);
                } else {
                    callActionDispatch();
                }

            });
    });
}

export const postPageData = (data: any, url_path: string, caseType: any) => {
    return new Promise((resolve, reject) => {
        let params = {};
        let path = ApiPaths[url_path];
        // let host = process.env.REACT_APP_ANALYTICS_HOST;
        let paramObj = {};
        let generatedURL: string;
        let result = {}

        
        generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
        

        axiosInstance.post(generatedURL, data)
            .then(res => {
                result = {
                    "result": "success",
                    "data": res,
                };
            })
            .catch(err => {
                result = {
                    "result": "catch_error",
                    "error": err.response.data.error,
                };
            }).then(() => {
                switch (caseType) {
                    case ActionTypes.POST_TAG:
                        resolve(postTag(result));
                        break;

                    default:
                        break;
                }
            })
    });


}