// Modified by: Mrunal Mane
// On: 2024-05-10
// Reason: Removed language dependancy from useEffect initial load

import React, { useRef, useEffect, useState, useCallback } from 'react';
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import manageGoalsReducer from "store/Reducers/Goal/manageGoals";
import { withReducer } from 'store/withReducer';
import { AppsState, DatesState, EventSourceState, CommonComponentState, LanguageState } from 'store/Reducers';
import { ManageGoalState } from 'store/Reducers/Goal/manageGoals';
import { useSelector, useDispatch } from 'react-redux';
import * as manageGoalActions from "store/Actions/index";
import * as ActionTypes from "store/Actions/ActionTypes";
import { setEventSource } from "store/Actions";
import qs from 'querystring';
import { CommonUtils } from 'utils/CommonUtils';
import { getCall, deleteCall } from "utils/ApiCallActions";
import GoalList from 'components/Goal/GoalList';
import CommonModal from 'components/CommonModal/CommonModal';
import Loader from "components/Loader/Loader";
import './Goal.scss'
import GoalForm from 'components/Goal/GoalForm';
import { InsightLabels } from 'labels';
import { useErrorBoundary } from 'react-error-boundary';
import Datepicker from 'components/Calender/Datepicker';
import { v4 as uuidv4 } from 'uuid';

// type PathParamsType = {
//     // param1: string,
// }

// // Your component own properties
// type props = RouteComponentProps<PathParamsType> & {
//     // someString: string,
// }

interface CurrentState {
    apps: AppsState,
    dates: DatesState,
    eventSource: EventSourceState,
    goal: ManageGoalState,
    commonComponentData: CommonComponentState,
    languageData: LanguageState
}

const Goal: React.FC = (props: any) => {
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)


    const componentRef = useRef() as React.RefObject<HTMLDivElement>;
    const goalListMount = useRef(false);
    const firstTimeMount = useRef(true);
    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];
    let fetched_details = useSelector((state: CurrentState) => state);

    const location = useLocation();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [parameter, setParameter] = useState({
        paramter: "",
        source: "",
    });

    const [goalList, setGoalList] = useState({
        data: [],
        totalCount: 0,
        listSection: true,
        listDataFetched: false,
        apiError: 0,
        searchTxt: '',
        firstLoad: true
    })

    const [editGoalData, setEditGoalData] = useState({
        data: [],
        editDataFetched: false,
        firstTimeLoad: true,
    })

    const [deleteModal, setDeleteModal] = useState({
        open: false,
        goalCode: "",
    })


    let urlData: any = {};
    const query = new URLSearchParams(location.search);
    query.forEach((v, k) => {
        urlData[k] = v;
    });

    const [request_uuid, setUuid] = useState({
        value: uuidv4()
    })
    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        document.title = insightsLbls.goal;
        if (insightsLbls) {

            document.title = insightsLbls.goal;
        }

        setTimeout(() => {
            CommonUtils.LeftPanelHighlight(6, 0, 0, true, false);
        }, 1000)
        window.scrollTo(0, 0);
    }, [insightsLbls])

    useEffect(() => {
        try {
            let goalformFlag: number = 0;

            const reqUUID = uuidv4();
            setUuid((prevState: any) => {
                return {
                    ...prevState,
                    value: reqUUID
                }
            })

            if (urlData?.goal_form !== null && urlData?.goal_form !== undefined && urlData?.goal_form !== '' &&
                (urlData?.goal_form === '1' || urlData?.goal_form === 1)) {
                // firstTimeMount.current = false;
                goalformFlag = 1;
                setEditGoalData((prevState: any) => {
                    return {
                        ...prevState,
                        data: [],
                        editDataFetched: true,
                        firstTimeLoad: true
                    }
                })

                setGoalList((prevState: any) => {
                    return {
                        ...prevState,
                        listSection: false,
                        firstLoad: false
                    }
                })
            } else {
                setGoalList((prevState: any) => {
                    return {
                        ...prevState,
                        listSection: true
                    }
                })
            }
            navigationFn(goalformFlag)

            // let queryParams = {
            //     app_id:fetched_details?.commonComponentData.appsData.appId,
            //     app_code:fetched_details?.commonComponentData.appsData.appCode,
            //     goal_form: goalformFlag
            // }
            // navigate({
            //     pathname: `/goal/manage-goal/`,
            //     search: qs.stringify(queryParams)
            // })
            CommonUtils.LeftPanelHighlight(6, 0, 0, true, false);
            if (fetched_details.commonComponentData.datesData.source === 'component' &&
                fetched_details?.commonComponentData?.appsData.appCode == localStorage.getItem('switch_app_code')) {
                if (fetched_details.commonComponentData.datesData.url.includes('goal/')) {
                    getGoalList(reqUUID);
                }
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [fetched_details.commonComponentData.datesData])

    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getGoalList = useCallback((reqUUID, apiErrorFlag?: string) => {

        let params = {
            search_text: encodeURIComponent(goalList.searchTxt),
            time_zone: timeZone,
            app_code: fetched_details?.commonComponentData.appsData.appCode,
            'request_uuid': reqUUID
            // sort_by: 'last_updated_on',
            // order: 'desc',
            // page_index: 1,
            // page_size: 100,
        };

        manageGoalActions.getManageGoalData(
            params,
            "GOAL_LIST",
            ActionTypes.SET_GOAL_LIST_DATA,
            apiErrorFlag ?? '',
            goalList.firstLoad
        ).then((res: any) => dispatch(res));

    }, [goalList.searchTxt, fetched_details?.commonComponentData.appsData, timeZone, dispatch])

    const deleteGoal = useCallback(() => {
        try {
            let params = {
                time_zone: timeZone,
                "app_code": fetched_details?.commonComponentData.appsData.appCode,
                "goal_code": deleteModal.goalCode
            };
            deleteCall(params, "CREATE_GOAL", "").then((data: any) => {
                if (data.result === "success") {
                    toast.error(insightsLbls.goalDeletedSuccessfully, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setDeleteModal((prevState: any) => {
                        return {
                            ...prevState,
                            open: false,
                        }
                    })

                    getGoalList(request_uuid.value);

                }
            });
        } catch (error) {
            showBoundary(error)
        }

    }, [deleteModal.open])

    const navigationFn = (goalformFlag: any) => {
        let queryParams = {
            app_id: fetched_details?.commonComponentData.appsData.appId,
            app_code: fetched_details?.commonComponentData.appsData.appCode,
            goal_form: goalformFlag
        }
        navigate({
            pathname: `/goal/manage-goal/`,
            search: qs.stringify(queryParams)
        })
    }


    const handleEditGoal = useCallback((data: any, apiErrorFlag?: string) => {
        try {
            setEditGoalData((prevState) => {
                return {
                    ...prevState,
                    data: [],
                    editDataFetched: false,
                    firstTimeLoad: false,
                }
            })

            setGoalList((prevState) => {
                return {
                    ...prevState,
                    listSection: false
                }
            })

            let params = {
                app_code: fetched_details?.commonComponentData.appsData.appCode,
                time_zone: timeZone,
                goal_code: data.goalCode,
            };


            manageGoalActions.getManageGoalData(
                params,
                "GET_GOAL",
                ActionTypes.SET_GOAL_DATA,
                apiErrorFlag ?? '',
            ).then((res: any) => {
                dispatch(res);
            });
            navigationFn(1)
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.apps])

    //Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const openDeleteModal = (data: any) => {
        try {
            setDeleteModal((prevState: any) => {
                return {
                    ...prevState,
                    open: true,
                    goalCode: data,
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const closeDeleteModal = () => {
        try {
            setDeleteModal((prevState: any) => {
                return {
                    ...prevState,
                    open: false
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const serachSurveyList = useCallback((data: any) => {
        try {
            setGoalList((prevState: any) => {
                return {
                    ...prevState,
                    searchTxt: data,
                    firstLoad: false
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }, [])

    const handleClick = useCallback((data?: any) => {
        try {
            setEditGoalData((prevState: any) => {
                return {
                    ...prevState,
                    data: [],
                    editDataFetched: true,
                    firstTimeLoad: true
                }
            })
            setGoalList((prevState: any) => {
                return {
                    ...prevState,
                    listSection: false,
                    firstLoad: false
                }
            })
            navigationFn(1)
        } catch (error) {
            showBoundary(error)
        }

    }, []);

    const handleBack = (data: any) => {
        try {
            let eventSourceValues: object = {
                eventSourceGuides: fetched_details.eventSource.eventSourceGuides,
                eventSourceTutorialGuides: fetched_details?.eventSource?.eventSourceTutorialGuides,
                eventSourceTooltips: fetched_details.eventSource.eventSourceTooltips,
                eventSourceSearches: fetched_details.eventSource.eventSourceSearches,
                eventSourceUserEngagement: fetched_details.eventSource.eventSourceUserEngagement,
                eventSourceDashboard: fetched_details.eventSource.eventSourceDashboard,
                eventSourceGuideAutomation: fetched_details.eventSource.eventSourceGuideAutomation,
                eventSourceGoals: fetched_details.eventSource.eventSourceGoals,
                eventSourceExportEmailGuide: fetched_details.eventSource.eventSourceExportEmailGuide,
                eventSourceGoalsGuides: 'player',
                eventSourceGoalsTooltips: 'player',
                // eventSourceExportEmailTooltip: fetched_details.eventSource.eventSourceExportEmailTooltip,
                // eventSourceExportEmailSearches: fetched_details.eventSource.eventSourceExportEmailSearches,
            };

            dispatch(setEventSource(eventSourceValues));
            setGoalList((prevState: any) => {
                return {
                    ...prevState,
                    listSection: true,
                    firstLoad: false,
                    listDataFetched: false,
                }
            })
            getGoalList(request_uuid.value);
            navigationFn(0);
        } catch (error) {
            showBoundary(error)
        }

    };

    const handleDetailNavigation = (data: any) => {
        try {
            setParameter((prevState: any) => {
                return {
                    ...prevState,
                    paramter: data.goalCode,
                    source: data.goalTitle
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    }



    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        try {
            if (
                fetched_details.goal.goalListData.data !== undefined &&
                fetched_details.goal.goalListData.result === "success" &&
                fetched_details?.goal.goalListData?.requestUUID === request_uuid.value
            ) {
                goalListMount.current = true;
                if (urlData?.goal_form !== null && urlData?.goal_form !== undefined && urlData?.goal_form !== '' &&
                    (urlData?.goal_form === '1' || urlData?.goal_form === 1) && firstTimeMount.current === true) {
                    firstTimeMount.current = false;
                    handleClick();
                } else {

                    setGoalList((prevState: any) => {
                        return {
                            ...prevState,
                            data: fetched_details.goal.goalListData.data,
                            totalCount: fetched_details.goal.goalListData.data.length,
                            listSection:urlData?.goal_form == 1 ? false : true,
                            listDataFetched: true,
                            apiError: 0,
                        }
                    })
                }

            } else if (fetched_details.goal.goalListData.result === 'retry') {
                getGoalList(request_uuid.value, 'retry');
            } else if (fetched_details.goal.goalListData.result === "error") {
                setGoalList((prevState: any) => {
                    return {
                        ...prevState,
                        listDataFetched: true,
                        apiError: 1,
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.goal.goalListData])

    useEffect(() => {
        try {
            if (
                fetched_details.goal.goalData.data !== undefined &&
                fetched_details.goal.goalData.result === "success"
            ) {
                setEditGoalData((prevState: any) => {
                    return {
                        ...prevState,
                        data: { ...fetched_details.goal.goalData.data },
                        editDataFetched: true
                    }
                })

                setGoalList((prevState: any) => {
                    return {
                        ...prevState,
                        listSection: false,
                    }
                })
            } else if (fetched_details.goal.goalData.result === 'retry') {
                handleEditGoal('retry');
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.goal.goalData, handleEditGoal])



    useEffect(() => {
        try {
            if (goalListMount.current === true) {
                getGoalList(request_uuid.value);
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [goalList.searchTxt])

    useEffect(() => {
        try {
            if (parameter.paramter !== '') {
                let queryParams = {
                    app_id: fetched_details?.commonComponentData.appsData.appId,
                    app_code: fetched_details?.commonComponentData.appsData.appCode,
                }
                navigate({
                    pathname: "/goal/goal-details/" + parameter.paramter + '/',
                    search: qs.stringify(queryParams),
                });
            }

        } catch (error) {
            showBoundary(error)
        }

    }, [parameter])

    return (
        <section className="width100" ref={componentRef}>
            <Datepicker source="component" style={{ visibility: 'hidden!important' }}></Datepicker>
            {goalList.listSection
                ? (
                    <div className="categoryDiv">
                        {
                            goalList.listDataFetched === true ?
                                (
                                    <GoalList
                                        listData={goalList?.data["listData"]}
                                        listCount={goalList?.data["listData"].length}
                                        apiError={goalList?.apiError}
                                        searchList={serachSurveyList}
                                        deleteGoal={openDeleteModal}
                                        dispatchEditGoal={handleEditGoal}
                                        dispatchClick={handleClick}
                                        dipatchDetail={handleDetailNavigation}
                                        firstTimeLoad={fetched_details?.goal?.goalListData?.firstTimeload}
                                        deleteGoalFlag={deleteModal.open}
                                    >
                                    </GoalList>
                                )
                                :
                                (
                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader></Loader>
                                        </div>
                                    </div>
                                )
                        }
                    </div>
                )
                : (

                    editGoalData.editDataFetched ?
                        (<GoalForm
                            dispatchBack={handleBack} formData={editGoalData.data} firstTimeLoad={editGoalData.firstTimeLoad}>
                        </GoalForm>)
                        :
                        (
                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight450">
                                <div className="displayFlex alignCenter margin-0-auto">
                                    <Loader></Loader>
                                </div>
                            </div>
                        )

                )


            }

            <CommonModal
                modalState={deleteModal.open}
                dispatchModalState={closeDeleteModal}
                modalTitle={insightsLbls.deleteGoal}
                size={'md'}
                footerDisabled={false}
                exportModal={false}
                padding={false}
                dispatchModalOk={deleteGoal}
                dispatchModalCancel={closeDeleteModal}
                okBtn={insightsLbls.confirm}
                cancelBtn={insightsLbls.cancel}
            >
                <div className="deleteModalBody">
                    <p>{insightsLbls.goalDeleteConfirmation}</p>
                </div>
            </CommonModal>

        </section>
    )
};

export default withRouter(withReducer("goal", manageGoalsReducer)(Goal));

