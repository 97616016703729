import React from 'react';

const Loader=(props:any)=>{
    return(
        <div className="app-spinner">
            {/* .spinner-img */}
            <img className="spinner-img img-fluid" 
                src={require(`${process.env.REACT_APP_LOADER}`)} 
                width={props.width ? props.width : 150}
                height={props.height ? props.height : 'auto'}
                style={{ margin: props.margin === 'none' ? '0' : 'auto' }}
            />
        </div>
    )
};

export default Loader;
