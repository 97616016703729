import * as ActionTypes from '../ActionTypes';
import { ApiRelativePaths } from '../../../utils/GlobalConstants';
import axios from 'axios';



let ApiPaths: any = ApiRelativePaths;

export const setLoginData = (loginData: any) => {
    return {
        type: ActionTypes.SET_LOGIN_DATA,
        loginData: loginData
    }
};






export const getLoginData = (data: any, url_path: string) => {
    return new Promise((resolve, reject) => {
        let path = ApiPaths[url_path];
        let host = process.env.REACT_APP_ANALYTICS_HOST;
        let generatedURL: string;

        generatedURL = generatedURL = host + '/' + path;

    

        axios.post(generatedURL, data, {
            headers: {
                'AppKey': process.env.REACT_APP_APP_KEY,
                'Content-Type': 'application/json',
            }
         })
            .then(res => {
                data = {
                    "result": "success",
                    "data": res.data.data,
                };

            

            })
            .catch(error => {
                data = {
                    "result": "error",
                    "data": error.response?.data,
                };
            })
            
            .then(() => {
                 resolve(setLoginData(data));
                }
            );
    });
}
