
// Modified by: Mrunal Mane
// On: 2024-05-10 05:48 PM
// Reason: Removed language dependancy from useEffects of (props.chartOptions)

import React, { useRef, useCallback, useState, useEffect } from "react";
import NVD3Chart from "react-nvd3";
import { useSelector } from "react-redux";
import { LanguageState } from "../../store/Reducers";
import { useErrorBoundary } from 'react-error-boundary';

import * as d3 from "d3";
import $ from "jquery";
import { InsightLabels } from "labels";
interface CurrentState {
    languageData: LanguageState
}

const Barchart = (props: any) => {
    let fetched_details = useSelector((state: CurrentState) => state);

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const { showBoundary } = useErrorBoundary();

    let barChartRef = [];
    barChartRef[props.refId] = useRef(null);


    let dates = [];
    if (props.chartData !== undefined) {
        try {
            for (let i = 0; i < props.chartData.length; i++) {
                dates.push(props.chartData[i].startTimestamp);
            }
        } catch (error) {
            showBoundary(error)
        }

    }


    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    const getData = useCallback(() => {
        return props.chartOptions
    }, [props.chartOptions])


    return (
        <div>
            <div id={props.refId}>
                <NVD3Chart
                    ref={barChartRef[props.refId]}
                    type="multiBarChart"
                    datum={getData}
                    showValues={true}
                    wrapLabels={false}
                    useInteractiveGuideline={false}
                    clipEdge={false}
                    legend={{
                        maxKeyLength: 100,
                        margin: {
                            right: 0,
                            top: 5,
                            left: -50,
                            bottom: 15,
                        }
                    }
                    }
                    x={function (d: any, i: any) {
                        return i;
                    }}
                    y={function (d: any) {
                        return d.y;
                    }}
                    margin={{
                        left: props.margin["left"],
                        right: props.margin["right"],
                        top: props.margin["top"],
                        bottom: props.margin["bottom"],
                    }}
                    xAxis={{
                        tickFormat: function (d) {
                            if (props.chartData[d] !== undefined) {
                                return (props.chartData[d].startTimestamp);
                            }
                        },
                        showMaxMin: true
                    }}
                    yAxis={{
                        axisLabel: insightsLbls.playCount,
                        axisLabelDistance: 5,
                        tickFormat: function (d) {
                            return Number.isInteger(d)?d:"";
                        },
                    }}
                    duration={50}
                    showControls={false}
                    stacked={true}
                    height={props.height}
                    tooltip={{
                        contentGenerator: function (key: any) {
                            let time;
                            if (key.data.startTimeStamp) {
                                time = key.data.startTimeStamp;
                            } else {
                                time = key.data.startTimestamp;
                            }

                            if (time.includes('|')) {
                                $('.nv-point-paths path, .nv-groups rect').css('cursor', 'default');
                                return `<div><b>${key.data.date} ${time}</b><br/><div style='height:10px; display:inline-block; margin-right:5px;width:10px; background-color:${key.color}'></div>${key.series[0].key} <b>${key.data.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</b></div>`;
                            } else {
                                $(' .nv-point-paths path, .nv-groups rect').css('cursor', 'default');
                                return `<div><b>${(time)}</b><br/><div style='height:10px; display:inline-block; margin-right:5px;width:10px; background-color:${key.color}'></div>${key.series[0].key} <b>${key.data.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</b></div>`;
                            }
                        }
                    }}
                />
            </div>
        </div>
    );
};
export default React.memo(Barchart);