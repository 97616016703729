import * as ActionTypes from '../ActionTypes';
import axiosInstance, { getInstance } from '../../../network/apis/interceptor';
import { CommonUtils } from '../../../utils/CommonUtils';
import { ApiRelativePaths, RetryApi } from '../../../utils/GlobalConstants';


let ApiPaths: any = ApiRelativePaths;


export const setUserEngagementDetailSummaryTable = (userEngagementDetailSummaryTableData: any) => {
    return {
        type: ActionTypes.SET_USER_ENGAGEMENT_DETAIL_SUMMARY_TABLE_DATA,
        userEngagementDetailSummaryTableData: userEngagementDetailSummaryTableData
    }
};

export const setUserEngagementDetailList = (userEngagementDetailListData: any) => {
    return {
        type: ActionTypes.SET_USER_ENGAGEMENT_DETAIL_LIST_DATA,
        userEngagementDetailListData: userEngagementDetailListData
    }
};

export const setUserEngagementDetailTimeline = (userEngagementDetailTimelineData: any) => {
    return {
        type: ActionTypes.SET_USER_ENGAGEMENT_DETAIL_TIMELINE_DATA,
        userEngagementDetailTimelineData: userEngagementDetailTimelineData
    }
};

export const setUserEngagementDetailGuideMeChart = (userEngagementDetailGuideMeChart: any) => {
    return {
        type: ActionTypes.SET_USER_ENGAGEMENT_DETAIL_GUIDEME_CHART,
        userEngagementDetailGuideMeChart: userEngagementDetailGuideMeChart
    }
};

export const setUserEngagementDetailShowMeChart = (userEngagementDetailShowMeChart: any) => {
    return {
        type: ActionTypes.SET_USER_ENGAGEMENT_DETAIL_SHOWME_CHART,
        userEngagementDetailShowMeChart: userEngagementDetailShowMeChart
    }
};

export const setUserEngagementDetailTestMeChart = (userEngagementDetailTestMeChart: any) => {
    return {
        type: ActionTypes.SET_USER_ENGAGEMENT_DETAIL_TESTME_CHART,
        userEngagementDetailTestMeChart: userEngagementDetailTestMeChart
    }
};

export const setUserEngagementDetailDoItForMeChart = (userEngagementDetailDoItForMeChart: any) => {
    return {
        type: ActionTypes.SET_USER_ENGAGEMENT_DETAIL_DO_IT_FOR_ME_CHART,
        userEngagementDetailDoItForMeChart: userEngagementDetailDoItForMeChart
    }
};

export const setUserEngagementDetailTutorialGuidesChart = (userEngagementDetailTutorialGuidesChart: any) => {
    return {
        type: ActionTypes.SET_USER_ENGAGEMENT_DETAIL_TUTORIAL_GUIDES_CHART_DATA,
        userEngagementDetailTutorialGuidesChart: userEngagementDetailTutorialGuidesChart
    }
};

export const setUserEngagementDetailUserName = (userEngagementDetailUserName: any) => {
    return {
        type: ActionTypes.SET_USER_ENGAGEMENT_DETAIL_USER_NAME,
        userEngagementDetailUserName: userEngagementDetailUserName
    }
};

export const getUserEngagementDetailData = (params: any, url_path: any, caseType: any, apiErrorFlag: string, firstTimeload?: boolean) => {
    return new Promise((resolve, reject) => {
        let path = ApiPaths[url_path];
        let data:any = {};
        let paramObj = {};
        let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
        getInstance('user-engagement-detail');


        axiosInstance.get(generatedURL)
        .then(res => {
            data = {
              "result": "success",
              "data": res.data.data,
              "firstTimeload": firstTimeload ?? false,
            };
        })
        .catch(err => {
            if (apiErrorFlag === '') {
                data = {
                    "result": "retry",
                    "data": err,
                    "firstTimeload": firstTimeload ?? false,
                };
            } else {
                data = {
                    "result": "error",
                    "data": err,
                    "errorCode":err?.response?.status ?? '',
                    "firstTimeload": firstTimeload ?? false,
                };
            }
        })
        .then(() => {

            const callActionDispatch = () => {
                switch (caseType) {
                    case ActionTypes.SET_USER_ENGAGEMENT_DETAIL_SUMMARY_TABLE_DATA:
                        resolve(setUserEngagementDetailSummaryTable(data));
                        break;

                    case ActionTypes.SET_USER_ENGAGEMENT_DETAIL_LIST_DATA:
                        resolve(setUserEngagementDetailList(data));
                        break;

                    case ActionTypes.SET_USER_ENGAGEMENT_DETAIL_TIMELINE_DATA:
                        resolve(setUserEngagementDetailTimeline(data));
                        break;

                    case ActionTypes.SET_USER_ENGAGEMENT_DETAIL_GUIDEME_CHART:
                        resolve(setUserEngagementDetailGuideMeChart(data));
                        break;

                    case ActionTypes.SET_USER_ENGAGEMENT_DETAIL_SHOWME_CHART:
                        resolve(setUserEngagementDetailShowMeChart(data));
                        break;

                    case ActionTypes.SET_USER_ENGAGEMENT_DETAIL_TESTME_CHART:
                        resolve(setUserEngagementDetailTestMeChart(data));
                        break;

                    case ActionTypes.SET_USER_ENGAGEMENT_DETAIL_DO_IT_FOR_ME_CHART:
                        resolve(setUserEngagementDetailDoItForMeChart(data));
                        break;

                    case ActionTypes.SET_USER_ENGAGEMENT_DETAIL_TUTORIAL_GUIDES_CHART_DATA:
                        resolve(setUserEngagementDetailTutorialGuidesChart(data));
                        break;

                    case ActionTypes.SET_USER_ENGAGEMENT_DETAIL_USER_NAME:
                            resolve(setUserEngagementDetailUserName(data));
                            break;

                    default:
                        break;
                }
            }
            

            if (data.result === 'retry') {
                setTimeout(() => {
                    callActionDispatch();
                }, RetryApi.TIMEOUT);
            } else {
                callActionDispatch();
            }
            
        });           
    });
}