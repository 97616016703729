
// Modified by: Mrunal Mane
// On: 2024-05-13
// Reason: Removed language dependancy from useEffect (props)

import React, { useState, useRef, useCallback, useEffect, ChangeEvent } from 'react';
import { Tab, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";


import { useSelector } from "react-redux";
import { AppsState, DatesState, EventSourceState, LanguageState } from "store/Reducers"
import { getCallExportExcelModal, getCallExportExcelEmail, postCall } from '../../../utils/ApiCallActions'


import { CommonUtils } from "utils/CommonUtils";
import { ApiRelativePaths } from "utils/GlobalConstants";


import CommonModal from '../CommonModal';
import Datepicker from 'components/Calender/Datepicker';
import AppListDropdown from 'components/AppListDropdown/AppListDropdown';
import Loader from "components/Loader/Loader";
import EventSource from 'components/EventSource/EventSource';
import { useErrorBoundary } from 'react-error-boundary';

import './ExportExcelModal.scss';
import moment from 'moment';




import { InsightLabels } from 'labels';
import Icon from 'components/Icon/Icon';


interface CurrentState {
    dates: DatesState,
    apps: AppsState,
    eventSource: EventSourceState,
    languageData: LanguageState
}

interface EmailInterFace {
    loadTabContent: boolean,
    appsFetched: boolean,
    appList: any[],
    exportList: any[],
    exportListFetched: boolean,
    exportListServiceAvailable: boolean,
    exportBtnDisabled: boolean
}

const ExportExcelModal = (props: any) => {

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const { showBoundary } = useErrorBoundary();

    let showEmailExportExcel = localStorage.getItem("USER_ACTIVITY")!;

    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];
    const fdate: string = localStorage.getItem('FDATE')!
    const tdate: string = localStorage.getItem('TDATE')!
    const exportData = useRef() as React.RefObject<HTMLDivElement>;

    // const dispatch = useDispatch();
    let fetched_details = useSelector((state: CurrentState) => state);
    let fetchedDateDetails = useSelector((state: CurrentState) => state.dates);
    let fetchedAppDetails = useSelector((state: CurrentState) => state.apps);
    let fetchedEventDetails = useSelector((state: CurrentState) => state.eventSource);

    let westpacFlag = 'false';

    const filterBy = [
        { key: "Guide", value: insightsLbls.guide },
        { key: "Tooltip", value: insightsLbls.tooltip }
    ];

    const [exportModal, setExportModal] = useState({
        open: false,
        invalidForm: false,
        status: "",
    });

    const [checkedStatus, setCheckedStatus] = useState({
        guideMeChecked: true,
        showMeChecked: true,
        testMeChecked: true,
        guideMeVal: 1,
        showMeVal: 1,
        testMeVal: 1,
        counter: 3
    });

    const [emailFilterType, setEmailFilterType] = useState({
        emailFilterType: 'Guide',
        emailFilterString: 'exportEmailGuide',
        emailFilterTypeFetched: true,
        exportBtnDisabled: false
    })



    //     const openExportModal = () => {
    //     setExportModal((prevState: any)=>{
    //       return{
    //         ...prevState,
    //         open:true,
    //       };
    //     });
    //   }

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        try {
            setExportModal((prevState: any) => {
                return {
                    ...prevState,
                    open: true,
                }
            });
        } catch (error) {
            showBoundary(error)
        }

    }, [props])

    const closeExportModal = () => {
        try {
            setExportModal((prevState: any) => {
                return {
                    ...prevState,
                    open: false,
                };
            });
            props.closeModal();
        } catch (error) {
            showBoundary(error)
        }
    }


    useEffect(() => {
        try {
            if (emailFilterType.emailFilterType === 'Guide') {
                setEmailFilterType((prevState: any) => {
                    return {
                        ...prevState,
                        emailFilterString: "exportEmailGuide",
                        emailFilterTypeFetched: true,
                    };
                });
            }
            else {
                setEmailFilterType((prevState: any) => {
                    return {
                        ...prevState,
                        emailFilterString: "exportEmailTooltip",
                        emailFilterTypeFetched: true,
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [emailFilterType.emailFilterType])



    const [tabKey, setTabKey] = useState({
        key: 1
    });
    const [exportEmail, setExportEmail] = useState<EmailInterFace>({
        loadTabContent: true,
        appsFetched: false,
        appList: [],
        exportList: [],
        exportListFetched: false,
        exportListServiceAvailable: true,
        exportBtnDisabled: false
    });


    useEffect(() => {
        try {
            if (westpacFlag === 'true') {
                setCheckedStatus((prevState: any) => {
                    return {
                        ...prevState,
                        showMeChecked: false,
                        showMeVal: 0,
                        testMeChecked: false,
                        testMeVal: 0
                    }
                })
            }

            setExportModal((prevState: any) => {
                return {
                    ...prevState,
                    invalidForm: checkedStatus.counter === 0 ? true : false,
                };
            });
        } catch (error) {
            showBoundary(error)
        }



    }, [checkedStatus.counter])


    const checkedState = (e: any) => {
        try {
            // let counter = 3;
            if (e.target.checked === true) {
                setCheckedStatus((prevState: any) => {
                    return {
                        ...prevState,
                        counter: checkedStatus.counter + 1
                    }
                })
            } else {
                setCheckedStatus((prevState: any) => {
                    return {
                        ...prevState,
                        counter: checkedStatus.counter - 1
                    }
                })
            }

            if (e.target.defaultValue === "guideMe") {
                setCheckedStatus((prevState: any) => {
                    if (prevState.guideMeChecked === false) {
                        // Counter.current = Counter.current +1;
                        //counter++
                        return {
                            ...prevState,
                            guideMeChecked: true,
                            guideMeVal: 1
                        };
                    }
                    else {
                        // Counter.current = Counter.current -1;
                        //counter--
                        return {
                            ...prevState,
                            guideMeChecked: false,
                            guideMeVal: 0
                        };
                        // this.guideMe_val = 1;
                    }
                })
            }

            if (e.target.defaultValue === "showMe") {
                setCheckedStatus((prevState: any) => {
                    if (prevState.showMeChecked === false) {
                        // Counter.current = Counter.current +1;
                        //counter++
                        return {
                            ...prevState,
                            showMeChecked: true,
                            showMeVal: 1
                        };
                    }
                    else {
                        // Counter.current = Counter.current -1;
                        //counter--
                        return {
                            ...prevState,
                            showMeChecked: false,
                            showMeVal: 0
                        };
                        // this.guideMe_val = 1;
                    }
                })
            }

            if (e.target.defaultValue === "testMe") {
                setCheckedStatus((prevState: any) => {
                    if (prevState.testMeChecked === false) {
                        // Counter.current = Counter.current +1;
                        //counter++
                        return {
                            ...prevState,
                            testMeChecked: true,
                            testMeVal: 1
                        };
                    }
                    else {
                        // Counter.current = Counter.current -1;
                        //counter--
                        return {
                            ...prevState,
                            testMeChecked: false,
                            testMeVal: 0
                        };
                        // this.guideMe_val = 1;
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const handleSwitch = (e: any) => {
        try {
            if (e === '2') {
                getExportData();
            }
            setTabKey((prevState: any) => {
                return {
                    ...prevState,
                    key: +e,
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };




    const getFinalAppsList = useCallback((appData: any) => {
        try {
            let appIdList: any[] = [];
            appData.map((data: any, key: number) => {
                appIdList.push(data.app_id)
            });

            setExportEmail((prevState: any) => {
                return {
                    ...prevState,
                    appList: appIdList,
                    appsFetched: true,
                };
            });
        } catch (error) {
            showBoundary(error)
        }


    }, []);

    const getExportData = () => {
        try {
            setExportEmail((prevState: any) => {
                return {
                    ...prevState,
                    exportListFetched: false,
                };
            });
            const params = {
                'time_zone': timeZone,
            };
            const data = getCallExportExcelEmail(params, "EXPORT_LIST_METADATA");

            data
                .then((data: any) => {
                    // exportData.current = data;
                    setExportEmail((prevState: any) => {
                        return {
                            ...prevState,
                            exportList: data.data,
                            exportListFetched: true,
                            exportListServiceAvailable: true,
                            exportBtnDisabled: data.data.length !== 0 ? (data.data[0]['job_status'] === '0' ? (true) : (false)) : (false)
                        };
                    });
                })
                .catch(err => {
                    setExportEmail((prevState: any) => {
                        return {
                            ...prevState,
                            exportListFetched: true,
                            exportListServiceAvailable: false,
                        };
                    });
                });
        } catch (error) {
            showBoundary(error)
        }

    }

    const exportExcel = () => {
        try {
            if (exportEmail.appsFetched) {
                let eventSourceArray: any[] = [];
                let eventSourceString: string = "";
                if (emailFilterType.emailFilterType === "Guide") {
                    eventSourceString = fetchedEventDetails.eventSourceExportEmailGuide
                }
                else {
                    // eventSourceString = fetchedEventDetails.eventSourceExportEmailTooltip
                }

                eventSourceString = eventSourceString.replaceAll('event_source=', '')
                eventSourceArray = eventSourceString!.split('&');

                const endDate = moment(tdate).add(1, "days").format("YYYY-MM-DD");

                const data = {
                    'start_timestamp': fdate + "_00:00:00",
                    'end_timestamp': endDate + "_00:00:00",
                    'app_ids': exportEmail.appList,
                    "event_source": eventSourceArray,
                    "guide_type": [emailFilterType.emailFilterType.toLowerCase()],
                    'enable_real_time': true,
                };

                const param = {
                    'time_zone': timeZone,
                };

                let path = ApiRelativePaths.EXPORT_METADATA;
                let paramObj = {};
                let a = CommonUtils.URLGenerator(param, path, paramObj);

                postCall(data, "EXPORT_METADATA", param).then((data: any) => {
                    if (data.result === "success") {

                        toast.success(insightsLbls.requestSent, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                        getExportData();
                    }
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const exportReport = (optn: number) => {
        try {
            const endDate = moment(fetchedDateDetails?.tdate).add(1, "days").format("YYYY-MM-DD");
            if (optn === 1) {
                const params = {
                    "start_date": fetchedDateDetails?.fdate + "_00:00:00",
                    "end_date": endDate + "_23:59:59",
                    'app_id': fetchedAppDetails.appId,
                    'time_zone': timeZone,
                    'query': '',
                    'sort_by': 'guide_title',
                    'include_guideme': checkedStatus.guideMeVal,
                    'include_showme': checkedStatus.showMeVal,
                    'include_testme': checkedStatus.testMeVal,
                    'export_to': 'excel',
                    "event_source": fetchedEventDetails.eventSourceGuides,
                    'enable_real_time': true,

                };

                getCallExportExcelModal(params, 'EXPORT_ALL', false)
            }
            else if (optn === 2) {
                const params = {
                    "start_date": fetchedDateDetails?.fdate + "_00:00:00",
                    "end_date": endDate + "_23:59:59",
                    'app_id': fetchedAppDetails.appId,
                    'time_zone': timeZone,
                    'query': '',
                    'sort_by': 'guide_title',
                    'include_guideme': checkedStatus.guideMeVal,
                    'include_showme': checkedStatus.showMeVal,
                    'include_testme': checkedStatus.testMeVal,
                    'export_to': 'excel',
                    "event_source": fetchedEventDetails.eventSourceGuides,
                    'enable_real_time': true,

                };
                getCallExportExcelModal(params, 'EXPORT_ALL', true)
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const onChangeEntries = (e: ChangeEvent<HTMLSelectElement>) => {
        try {
            setEmailFilterType((prevState: any) => {
                return {
                    ...prevState,
                    emailFilterType: e.target.value,
                    emailFilterTypeFetched: false,
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };


    return (
        <div className="">
            <CommonModal
                modalState={exportModal.open}
                dispatchModalState={closeExportModal}
                modalTitle={insightsLbls.exportExcel}
                size={'lg'}
                footerDisabled={true}
                exportModal={true}
                padding={true}
                modalDialogClass={'exportExcelModal'}
            // dispatchModalOk={getTooltipList}
            // dispatchModalCancel={clearTooltips}
            // okBtn={'Apply'}
            // cancelBtn={'Clear All'}
            >
                <div id="exportExcel_modal">
                    <Tabs
                        activeKey={tabKey.key}
                        onSelect={handleSwitch}
                        id="controlled-tab-example"
                    >
                        <Tab eventKey={1} title="Export Excel">
                            {(tabKey.key === 1) ? (
                                <>
                                    <div className="row marginLR-0">
                                        <div className="col-lg-9 col-9 date-picker-width exportDatepicker displayFlex paddingTB-20 paddingLR-0 minHeight80 spaceBetween">
                                            <div className="alignCenter datePickerContainer marginRight-90">
                                                <Datepicker source={"modal"}></Datepicker>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-3 displayFlex paddingTB-20">
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-12 displayFlex paddingTop-20 paddingBottom-50">
                                        <div className="paddingTop-20 width100 displayFlex">
                                            <div className={process.env.REACT_APP_WESTPAC === 'false' ? 'col-lg-4' : 'col-lg-12'}>
                                                <div className="StepsLabel">
                                                    <Icon className="lableIcons" img={"img/guideme_white.svg"} />
                                                    <div className="div-spans">
                                                        <div className="custom-control custom-checkbox mr-sm-2 check-container">
                                                            <label
                                                                className="labelPM check-container displayFlex alignCenter exportCheck marginBottom-0">
                                                                <span className="exportText-span">{insightsLbls.guideMe}</span>
                                                                <input type="checkbox" checked={checkedStatus.guideMeChecked}
                                                                    className="custom-control-input chk checkbox"
                                                                    id="customControlAutosizing"
                                                                    onChange={checkedState}
                                                                    // (change)="checkedState($event, checkBox)" 
                                                                    name="chk"
                                                                    value="guideMe" />
                                                                <span className="checkmark-export checkmark checkmarkBox-color displayInlineBlock"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {process.env.REACT_APP_WESTPAC === 'false' && (
                                                <div className="col-lg-4">
                                                    <div className="StepsLabel">
                                                        <Icon className="lableIcons" img={"img/showme_white.svg"} />
                                                        <div className="div-spans">
                                                            <div className="custom-control custom-checkbox mr-sm-2 check-container">
                                                                <label
                                                                    className="labelPM check-container displayFlex alignCenter exportCheck marginBottom-0">
                                                                    <span className="exportText-span">{insightsLbls.showMe}</span>
                                                                    <input type="checkbox" checked={checkedStatus.showMeChecked}
                                                                        className="custom-control-input chk checkbox"
                                                                        id="customControlAutosizing1"
                                                                        onChange={checkedState}
                                                                        // (change)="checkedState($event, checkBox)" 
                                                                        name="chk"
                                                                        value="showMe" />
                                                                    <span className="checkmark-export checkmark checkmarkBox-color displayInlineBlock"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {process.env.REACT_APP_WESTPAC === 'false' && (
                                                <div className="col-lg-4">
                                                    <div className="StepsLabel">
                                                        <Icon className="lableIcons" img={"img/testme_white.svg"} />
                                                        <div className="div-spans">
                                                            <div className="custom-control custom-checkbox mr-sm-2 check-container">
                                                                <label
                                                                    className="labelPM check-container displayFlex alignCenter exportCheck marginBottom-0">
                                                                    <span className="exportText-span">{insightsLbls.testMe}</span>
                                                                    <input type="checkbox" checked={checkedStatus.testMeChecked}
                                                                        className="custom-control-input chk checkbox"
                                                                        id="customControlAutosizing2"
                                                                        onChange={checkedState}
                                                                        // (change)="checkedState($event, checkBox)" 
                                                                        name="chk"
                                                                        value="testMe" />
                                                                    <span className="checkmark-export checkmark checkmarkBox-color displayInlineBlock"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                    <div className="modal-footer marginTop-50 paddingTop-50" id="exportModalFooter">
                                        <div className="col-lg-12 displayFlex padding0 exportModal positionRelative">
                                            <div className={westpacFlag === 'true' ? 'col-lg-12 ' : 'col-lg-6 '}>
                                                <button onClick={() => exportReport(1)} className="export-btn btn btn-export btn-export-modal"
                                                    disabled={exportModal.invalidForm}>
                                                    {exportModal.status ?
                                                        <span className="font13">
                                                            <i className="fa fa-download marginRight-3" aria-hidden="true"></i>
                                                            {exportModal.status}
                                                        </span>
                                                        :
                                                        <span className="font13">
                                                            <i className="fa fa-download marginRight-3" aria-hidden="true"></i>
                                                            {insightsLbls.download}
                                                        </span>
                                                    }
                                                </button>
                                            </div>
                                            {westpacFlag === 'true' ? null :
                                                (
                                                    <React.Fragment>
                                                        <div className="vl"></div>
                                                        <div className="or-div">{insightsLbls.or}</div>
                                                        <p className="text font14 font500 excelText">{insightsLbls.excelViewerQn}</p>
                                                        <div className="col-lg-6">
                                                            <button onClick={() => exportReport(2)} className="export-btn btn btn-export btn-export-modal"
                                                                disabled={exportModal.invalidForm}>
                                                                <span className="font13"><i className="fa fa-cloud" aria-hidden="true"></i>{insightsLbls.viewOnline}</span>
                                                            </button>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            }
                                        </div>
                                    </div>
                                </>
                            ) : (null)}

                        </Tab>



                        {(showEmailExportExcel === 'true') && (
                            <Tab eventKey={2} title="Export Via Email">
                                {tabKey.key === 2 ? (
                                    <>
                                        <div className="row paddingLR-20">
                                            <div className="col-lg-12 col-12 exportDatepicker displayFlex paddingTB-20 paddingLR-0 minHeight80 spaceBetween positionRelative">
                                                <div className="alignCenter datePickerContainer marginRight-17">
                                                    <Datepicker source={"modalEmail"}></Datepicker>
                                                </div>

                                                <div className="selectCustomColor displayGrid font13">
                                                    <span className="startAlign">{insightsLbls.type}</span>
                                                    <select
                                                        className="entries-dropdown"
                                                        onChange={(event) => onChangeEntries(event)}
                                                    >
                                                        {filterBy.map((option, index) => {
                                                            return <option value={option.key} key={index}>{option.value}</option>;
                                                        })}
                                                    </select>
                                                </div>

                                                <div>
                                                    {
                                                        emailFilterType.emailFilterTypeFetched === false
                                                            ? (<Loader width={100}></Loader>)
                                                            : (<EventSource component={emailFilterType.emailFilterString}></EventSource>)
                                                    }

                                                    {/* <EventSourceExcelExport type={emailFilterType}></EventSourceExcelExport> */}
                                                    <AppListDropdown finalApps={getFinalAppsList} fromComponent="exportExcelModal"></AppListDropdown>
                                                </div>

                                                <button
                                                    className="btn btn-export marginRight-0 minHeight40"
                                                    data-toggle="modal"
                                                    onClick={() => exportExcel()}
                                                    disabled={exportEmail.appsFetched ? (exportEmail.exportBtnDisabled) : false}
                                                >
                                                    <img
                                                        src={
                                                            require("../../../assets/images/export-excel.png")
                                                        }
                                                        width="22"
                                                        height="auto"
                                                        alt=""
                                                        className="excel-icon"
                                                    />
                                                    {insightsLbls.export}
                                                </button>
                                            </div>


                                        </div>
                                        <div className="row marginLR-0 paddingLeft-5 positionRelative height30 refreshIconDiv">
                                            <i className="fa fa-refresh pull-right pointer row displayFlex refreshIcon" aria-hidden="true" title={insightsLbls.refresh} onClick={() => getExportData()}></i>
                                        </div>
                                        {exportEmail.loadTabContent ? (
                                            <div className="col-lg-12 col-12 displayFlex paddingBottom-40 paddingLR-0 minHeight422 excelExport positionRelative">

                                                {exportEmail.exportBtnDisabled && (
                                                    <div className="errorMsgDiv minHeight35">
                                                        <p className="errorMsg displayBlock">
                                                            {insightsLbls.placeRequestLimitMsg}
                                                        </p>
                                                    </div>
                                                )}


                                                <table className="table color_table scrolldown">
                                                    <thead>
                                                        <tr className="lowerborder">
                                                            <th>{insightsLbls.requestTime}</th>
                                                            <th>{insightsLbls.requestCompleteTime}</th>
                                                            <th>{insightsLbls.jobStatus}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="table-height-export-email ">
                                                        {
                                                            exportEmail.exportListFetched === true ?
                                                                (
                                                                    exportEmail.exportListServiceAvailable === true ?
                                                                        (
                                                                            exportEmail.exportList.length === 0 ?
                                                                                (
                                                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                                                        <div className="noDataText-font textCenter-absolute">
                                                                                            {insightsLbls.noRecordFound}
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                                :
                                                                                (
                                                                                    exportEmail.exportList.map((data: any, key: number) => {
                                                                                        return (<tr className="lowerborder">
                                                                                            <td>{new Intl.DateTimeFormat("en-GB", {
                                                                                                day: 'numeric',
                                                                                                month: 'short',
                                                                                                year: "numeric",
                                                                                                hour: 'numeric',
                                                                                                minute: 'numeric',
                                                                                                timeZone: 'Asia/Kolkata',
                                                                                                hourCycle: "h12",
                                                                                            }).format(new Date(data.request_time))}</td>
                                                                                            <td>{data.request_completed_time === null ? (null)
                                                                                                : (new Intl.DateTimeFormat("en-GB", {
                                                                                                    day: 'numeric',
                                                                                                    month: 'short',
                                                                                                    year: "numeric",
                                                                                                    hour: 'numeric',
                                                                                                    minute: 'numeric',
                                                                                                    timeZone: 'Asia/Kolkata',
                                                                                                    hourCycle: "h12",
                                                                                                }).format(new Date(data.request_completed_time)))}</td>
                                                                                            <td>{data.job_status === "0" ? (<span className="exportPending">{insightsLbls.pending}</span>) : (data.job_status === "1" ? (<span className="exportProcess">{insightsLbls.inProcess}</span>) : (data.job_status === "2" ? (<span className="exportComplete">{insightsLbls.complete}</span>) : (data.job_status === "3" ? (<span className="exportNoData">{insightsLbls.noRecordFound}</span>) : (<span className="exportFail">{insightsLbls.fail}</span>))))}</td>
                                                                                        </tr>)
                                                                                    })
                                                                                )

                                                                        )
                                                                        :
                                                                        (
                                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                                <div className="noDataText-font textCenter-absolute">
                                                                                    {insightsLbls.serviceNotAvailable}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                )
                                                                :
                                                                (
                                                                    <div className="displayFlex alignCenter margin-0-auto">
                                                                        <Loader></Loader>
                                                                    </div>
                                                                )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (
                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight422">
                                                <div className="displayFlex alignCenter margin-0-auto">
                                                    <Loader></Loader>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (null)}

                            </Tab>
                        )}
                    </Tabs>
                </div>
            </CommonModal>
        </div>
    )
};
export default React.memo(ExportExcelModal);
