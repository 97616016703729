import React, { useEffect, useCallback } from 'react';
import { ChangeEvent, useState } from "react";
import { toast } from "react-toastify";
import { Form, FormGroup, FormControl, ButtonToolbar, SplitButton, Dropdown } from "react-bootstrap";
import { AppsState, LanguageState } from 'store/Reducers';
import { useSelector } from 'react-redux';
import { getCall, postCall, putCall } from 'utils/ApiCallActions';
import { useErrorBoundary } from 'react-error-boundary';
import $ from 'jquery';
import Label from "components/Label/Label";
import Loader from 'components/Loader/Loader';
import CommonModal from 'components/CommonModal/CommonModal';
import './ManageChatBot.scss';
import { RetryApi } from 'utils/GlobalConstants';
import { InsightLabels } from 'labels';
import { DndContext, closestCenter } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy, useSortable } from "@dnd-kit/sortable";
import { CSS as cssDndKit } from "@dnd-kit/utilities";
import Datepicker from 'components/Calender/Datepicker';
import SectionFilters from 'components/Layouts/SectionFilters';

interface CurrentState {
	apps: AppsState,
	languageData: LanguageState
}

const ChatBotForm = (props: any) => {
	const { showBoundary } = useErrorBoundary();
	const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);

	const [QuestionsData, setQuestionsData] = useState({
		"comment": [],
		"multiselect": [],
		"range": [],
		"rating": [],
		"singleselect": [],
		"yesno": []
	})

	let fetched_details = useSelector((state: CurrentState) => state.apps);
	let fetched_lang_details = useSelector((state: CurrentState) => state);


	let labels_data: any;

	let commentQns: any = [];
	let multiselectQns: any = [];
	let rangeQns: any = [];
	let ratingQns: any = [];
	let singleselectQns: any = [];
	let yesnoQns: any = [];
	let starSelect = 3;

	const [rulesModal, setRulesModal] = useState({
		labelData: [],
		labelFlag: false,
		loadLabel: false
	})


	const [qnType, setQnType] = useState({
		selectedType: 'Select Type',
		selectedQns: [],
		flag: false,
		selectedPage: 0,
		inputError: false,
		addPage_disabled: true,
		rangeQuestionCount: 0,
		createBtnDisabled: true,
		value: '',
		branchingQuestionCount: []
	})

	const [questionsData, setQuestionData] = useState({
		pageArray: [[]],
		loaderFlag: false,
		setGlobal: false,
		conversationTitle: '',
		labelData: [],
		duplicateFlag: false,
		requestFlag: false,
		editFlag: false,
		conversationCode: ''
	})

	const [analyzerModal, setAnalyzerModal] = useState({
		modalOpen: false,
		listData: [],
		listFetched: false,
		selectedList: [],
		okDisabled: false,
		btnText: 'All',
		analyzer_pgIndex: 0,
		analyzer_qnIndex: 0,
		analyzerFlag: false,
		analyzerSet: false
	})

	const [branchingModal, setBranchingModal] = useState({
		modalOpen: false,
		listData: [],
		listFetched: false,
		selectedList: [],
		okDisabled: false,
		btnText: 'All',
		branching_pgIndex: 0,
		branching_qnIndex: 0,
		branchingFlag: false,
		branchingSet: false,
		branchingSingleOptionsCheck: false,
		tempChecker: ''
	})

	const [refModal, setRefModal] = useState({
		modalOpen: false,
		refDisabled: true,
		optnPgIndex: 0,
		optnQnIndex: 0,
		optnIndex: 0,
		linkVal: '',
		linkText: ''
	})

	const [optionsSortedList, setOptionsSortedList] = useState<any[]>([]);
	const [sortListIndexes, setSortListIndexes] = useState({
		qnIndex: 0,
		pgIndex: 0,
		flag: false,
		loaderFlag: true
	})


	const [options, setOptions] = useState({
		collections: [[0, 1, 2], [0, 1, 2, 3, 4], [0, 1, 2]],
	})

	const [qnOverlay, setQnOverlay] = useState({
		pgStartIndex: -1,
		pgEndIndex: -1,
		overlayFlag: false,
		sourcePageIndex: -1,
		sourceQnIndex: -1,
		targetPageIndex: -1,
		optionIndex: -1,
		sameQnFlag: false,
		linkArray: [],
	})

	useEffect(() => {
		if (fetched_lang_details.languageData.languageData) {
			setInsightsLbls(fetched_lang_details.languageData.languageData);
		}

	}, [fetched_lang_details.languageData.languageData])

	useEffect(() => {
		try {
		} catch (error) {
			showBoundary(error)
		}
		if (insightsLbls.commentQnAnySuggestion) {
			setQuestionsData((prevState: any) => {
				return {
					...prevState,
					"comment": [
						{
							"id": "0",
							"q": insightsLbls.commentQnAnySuggestion
						},
						{
							"id": "1",
							"q": insightsLbls.commentQnImprove
						},
						{
							"id": "2",
							"q": insightsLbls.commentQnDescribe
						}
					],
					"multiselect": [
						{
							"id": "0",
							"q": insightsLbls.multiselectFeaturesLiked,
							"options": [
								insightsLbls.guide,
								insightsLbls.downloadingOption,
								insightsLbls.tooltip
							]
						},
						{
							"id": "1",
							"q": insightsLbls.multiselectFeaturesUsed,
							"options": [
								insightsLbls.guide,
								insightsLbls.downloadingOption,
								insightsLbls.tooltip
							]
						},
						{
							"id": "2",
							"q": insightsLbls.multiselectPlatform,
							"options": [
								insightsLbls.desktop,
								insightsLbls.mobile,
								insightsLbls.tablet
							]
						}
					],
					"range": [
						{
							"id": "0",
							"q": insightsLbls.rangeSelect
						},
						{
							"id": "1",
							"q": insightsLbls.rangeProvide
						}
					],
					"rating": [
						{
							"id": "0",
							"q": insightsLbls.rateUs
						},
						{
							"id": "1",
							"q": insightsLbls.satisfiedRate
						}
					],
					"singleselect": [
						{
							"id": "0",
							"q": insightsLbls.singleselectLikeGuide,
							"options": [
								insightsLbls.btnYes,
								insightsLbls.btnNo,
								insightsLbls.neutral
							]
						},
						{
							"id": "1",
							"q": insightsLbls.singleselectSuggestGuide,
							"options": [
								insightsLbls.btnYes,
								insightsLbls.btnNo,
								insightsLbls.mayBe
							]
						},
						{
							"id": "2",
							"q": insightsLbls.singleselectAccomplishGuide,
							"options": [
								insightsLbls.btnYes,
								insightsLbls.btnNo,
								insightsLbls.mayBe
							]
						}
					],
					"yesno": [
						{
							"id": "0",
							"q": insightsLbls.yesNoLikeGuide
						},
						{
							"id": "1",
							"q": insightsLbls.yesNoSuggest
						},
						{
							"id": "2",
							"q": insightsLbls.yesNoAccomplish
						}
					]
				}
			})
		}
	}, [insightsLbls])


	useEffect(() => {
		try {
		} catch (error) {
			showBoundary(error)
		}
		setQnType((prevState: any) => {
			return {
				...prevState,
				selectedType: insightsLbls.selectType
			}
		})

	}, [])

	useEffect(() => {
		try {
		} catch (error) {
			showBoundary(error)
		}
		getLabels();
		commentQns = QuestionsData['comment'];
		multiselectQns = QuestionsData['multiselect'];
		rangeQns = QuestionsData['range'];
		ratingQns = QuestionsData['rating'];
		singleselectQns = QuestionsData['singleselect'];
		yesnoQns = QuestionsData['yesno'];

		let dummyData: any = questionsData.pageArray;
		setQuestionData((prevState: any) => {
			return {
				...prevState,
				pageArray: dummyData
			}
		})


		// EDIT SURVEY
		if (Object.keys(props.formData).length) {
			setQuestionData((prevState: any) => {
				return {
					...prevState,
					requestFlag: true,
					loaderFlag: true
				}
			})

			let dummyData: any = props.formData['questions'];
			let pageArray: any = [];
			let branchingQuestionCountTemp: any = [];


			for (let i = 0; i < dummyData.length; i++) {

				pageArray[i] = [];
				if (branchingQuestionCountTemp[dummyData[i].pageIndex - 1] === undefined) {
					branchingQuestionCountTemp[dummyData[i].pageIndex - 1] = 0;
				}

				if (dummyData[i]['type'] === 'range') {
					pageArray[dummyData[i].pageIndex - 1].push({
						'optn': getEditType(dummyData[i]['type']),
						'quesText': dummyData[i].question,
						'ques_option': dummyData[i]['options'],
						'pageIndex': dummyData[i].pageIndex,
						'indexInPage': dummyData[i].indexInPage - 1,
						'questionID': dummyData[i]['questionID'],
						'analyzerCheck': dummyData[i].isAnalyzer,
						'isBranchNode': false,
						'editFlag': true
					})
					qnType.rangeQuestionCount++;
				} else if (dummyData[i]['type'] === 'comment') {
					pageArray[dummyData[i].pageIndex - 1].push({
						'optn': getEditType(dummyData[i]['type']),
						'quesText': dummyData[i].question,
						'ques_option': dummyData[i]['options'],
						'pageIndex': dummyData[i].pageIndex,
						'indexInPage': dummyData[i].indexInPage - 1,
						'questionID': dummyData[i]['questionID'],
						'validateEmail': dummyData[i]['validateEmail'],
						'isBranchNode': false,
						'editFlag': true
					})
				} else if (dummyData[i]['type'] === 'yes-no' || dummyData[i]['type'] === 'select') {
					pageArray[dummyData[i].pageIndex - 1].push({
						'optn': getEditType(dummyData[i]['type']),
						'quesText': dummyData[i].question,
						'ques_option': dummyData[i]['options'],
						'pageIndex': dummyData[i].pageIndex,
						'indexInPage': dummyData[i].indexInPage - 1,
						'questionID': dummyData[i]['questionID'],
						'isBranchNode': dummyData[i]['isBranchNode'],
						'analyzerCheck': dummyData[i].isAnalyzer,
						'editFlag': true
					})
					branchingQuestionCountTemp[dummyData[i].pageIndex - 1]++;

				} else {
					pageArray[dummyData[i].pageIndex - 1].push({
						'optn': getEditType(dummyData[i]['type']),
						'quesText': dummyData[i].question,
						'ques_option': dummyData[i]['options'],
						'pageIndex': dummyData[i].pageIndex,
						'indexInPage': dummyData[i].indexInPage - 1,
						'questionID': dummyData[i]['questionID'],
						'isBranchNode': false,
						'editFlag': true
					})
				}

				setQnType((prevState: any) => {
					return {
						...prevState,
						branchingQuestionCount: [...branchingQuestionCountTemp]
					}
				})
			}

			setTimeout(() => {
				let pushArray: any = [];
				for (let data of pageArray) {
					data.length && pushArray.push(data);
				}

				setQuestionData((prevState: any) => {
					return {
						...prevState,
						pageArray: pushArray,
						loaderFlag: false,
						requestFlag: false,
						conversationTitle: props.formData.conversationTitle,
						setGlobal: props.formData.appCode === "" ? true : false,
						editFlag: true,
						conversationCode: props.formData.conversationCode,
						labelData: props.formData.conversationLabels,
					}
				})

				setQnType((prevState: any) => {
					return {
						...prevState,
						createBtnDisabled: true
					}
				})

				setRulesModal((prevState: any) => {
					return {
						...prevState,
						selectedLabels: props.formData.conversationLabels,
						loadLabel: true,
					}
				})

			}, 0);


		} else {
			setQuestionData((prevState: any) => {
				return {
					...prevState,
					editFlag: false,
				}
			})

			setRulesModal((prevState: any) => {
				return {
					...prevState,
					loadLabel: true
				}
			})
		}
	}, [])

	const getLabelData = useCallback((data: any) => {
		try {
		} catch (error) {
			showBoundary(error)
		}
		setQuestionData((prevState: any) => {
			return {
				...prevState,
				labelData: data,
				labelFlag: true,
				selectedLabels: props.formData.conversationLabels,
				loadLabel: true
			}
		})
	}, [rulesModal.labelData])

	const getLabels = async (apiErrorFlag?: string) => {
		try {
		} catch (error) {
			showBoundary(error)
		}
		getCall('', 'GET_LABEL_LIST', apiErrorFlag ?? '').then((data: any) => {
			if (data.result === 'success') {
				setRulesModal((prevState: any) => {
					return {
						...prevState,
						labelData: data.data,
						labelFlag: true,
					}
				})
			} else if (data.result === 'retry') {
				setTimeout(() => {
					getLabels('retry')
				}, RetryApi.TIMEOUT)
			} else {

			}
		})
	}

	const changeQn = (ev: any, pgIndex: number) => {
		try {
		} catch (error) {
			showBoundary(error)
		}
		setQnType((prevState: any) => {
			return {
				...prevState,
				flag: false,
				selectedQns: [],
				value: '',
				inputError: true
			}
		})

		const elem = document.getElementById('qn_lists_' + pgIndex) as HTMLInputElement;
		if (elem) {
			elem.value = '';
		}

		switch (ev) {
			case 'Text':
				setQnType((prevState: any) => {
					return {
						...prevState,
						selectedQns: QuestionsData['comment'],
					}
				})
				break;

			case 'Single Select':
				setQnType((prevState: any) => {
					return {
						...prevState,
						selectedQns: QuestionsData['singleselect'],
					}
				})
				break;

			case 'Multi Select':
				setQnType((prevState: any) => {
					return {
						...prevState,
						selectedQns: QuestionsData['multiselect'],
					}
				})
				break;

			case 'Range':
				setQnType((prevState: any) => {
					return {
						...prevState,
						selectedQns: QuestionsData['range'],
					}
				})
				break;

			case 'Yes-No':
				setQnType((prevState: any) => {
					return {
						...prevState,
						selectedQns: QuestionsData['yesno'],
					}
				})
				break;

			case "3 Star-rating":
			case "3 Star-Rating":
				setQnType((prevState: any) => {
					return {
						...prevState,
						selectedQns: QuestionsData['rating'],
					}
				})
				break;

			default:
				break;
		}


		const inpElem = document.getElementById('qn_lists_' + pgIndex) as HTMLInputElement;
		let impError_flag = true;
		if (inpElem) {
			impError_flag = inpElem.value.length >= 3 ? false : true
		}

		setQnType((prevState: any) => {
			return {
				...prevState,
				selectedType: ev,
				flag: true,
				inputError: impError_flag,
				selectedPage: pgIndex
			}
		})
	}


	const addPage = (e: any) => {
		try {
		} catch (error) {
			showBoundary(error)
		}
		e.preventDefault();

		let dummyData: any = questionsData.pageArray;
		dummyData.push([]);
		setQuestionData((prevState: any) => {
			return {
				...prevState,
				pageArray: dummyData
			}
		})

		setTimeout(() => {
			$('#scrollDiv').animate({ scrollLeft: '+=1120' }, 1000);
		}, 0);

	}

	const deletePage = (index: number) => {
		try {
		} catch (error) {
			showBoundary(error)
		}
		let dummyData: any = questionsData.pageArray;
		dummyData.splice(index, 1);

		let linkData: any = qnOverlay.linkArray;
		for (let i = 0; i < dummyData.length; i++) {
			for (let j = 0; j < dummyData[i].length; j++) {
				if (dummyData[i][j]['pageIndex'] > index) {
					dummyData[i][j]['pageIndex'] = i + 1;
				}

				if (dummyData[i][j]['optn'] === 'Yes-No' || dummyData[i][j]['optn'] === 'Single Select') {
					const len = dummyData[i][j]['ques_option'].length;
					for (let itr1 = 0; itr1 < len; itr1++) {
						if (dummyData[i][j]['ques_option'][itr1]['nextHopOnBranch'] === (index + 1)) {
							dummyData[i][j]['ques_option'][itr1]['nextHopOnBranch'] = 0;
							linkData.push(1);
						} else {
							if (index + 1 < dummyData[i][j]['ques_option'][itr1]['nextHopOnBranch']) {
								dummyData[i][j]['ques_option'][itr1]['nextHopOnBranch'] = dummyData[i][j]['ques_option'][itr1]['nextHopOnBranch'] - 1;
							}
						}
					}
				} else {
					linkData = qnOverlay.linkArray;
				}
			}
		}

		setQnOverlay((prevState: any) => {
			return {
				...prevState,
				linkArray: linkData,
			}
		})


		if (dummyData.length === 0) {
			dummyData.push([]);
		}

		setQuestionData((prevState: any) => {
			return {
				...prevState,
				pageArray: dummyData
			}
		})

		setTimeout(() => {
			$('#scrollDiv').animate({ scrollLeft: '-=1120' }, 1000);
		}, 0);
	}

	const addQuestion = (event: any, pageIndex: number) => {
		try {
		} catch (error) {
			showBoundary(error)
		}
		event.preventDefault();

		setSortListIndexes((prevState: any) => {
			return {
				...prevState,
				flag: false,
				loaderFlag: false
			}
		})

		$('.pageBoder-inner').animate({
			scrollTop: $('.pageBoder-inner')[0].scrollHeight
		}, 'slow');

		setQnType((prevState: any) => {
			return {
				...prevState,
				selectedType: 'Select Type',
			}
		})

		const elem = document.getElementById('qn_lists_' + pageIndex) as HTMLInputElement;
		let qnText = '';
		if (elem) {
			qnText = elem.value;
		}

		let qnOptions: any = [];
		let dummyData: any = questionsData.pageArray;


		let duplicateQnFlag = false;
		for (let i = 0; i < dummyData.length; i++) {
			for (let j = 0; j < dummyData[i].length; j++) {
				if (qnText === dummyData[i][j]['quesText']) {
					duplicateQnFlag = true
				}
			}
		}

		if (!duplicateQnFlag) {
			switch (qnType.selectedType) {
				case 'Single Select':
					let qnData_single = QuestionsData['singleselect'];
					for (let i = 0; i < qnData_single.length; i++) {
						if (qnData_single[i].q === qnText) {
							let optns: any = [];
							for (let data of qnData_single[i].options) {
								optns.push({ option: data, optionReferenceLink: "", nextHopOnBranch: 0 })
							}
							qnOptions = optns;
							break;
						} else {
							qnOptions = [
								{ option: insightsLbls.option + "1", optionReferenceLink: "", nextHopOnBranch: 0 },
								{ option: insightsLbls.option + "2", optionReferenceLink: "", nextHopOnBranch: 0 },
								{ option: insightsLbls.option + "3", optionReferenceLink: "", nextHopOnBranch: 0 }]
						}
					}
					setOptionsSortedList(qnOptions);

					break;
				case 'Multi Select':
					let qnData_multiple = QuestionsData['multiselect'];
					for (let i = 0; i < qnData_multiple.length; i++) {
						if (qnData_multiple[i].q === qnText) {

							let optns: any = [];
							for (let data of qnData_multiple[i].options) {
								optns.push({ option: data })
							}
							qnOptions = optns;
							break;
						} else {
							qnOptions = [
								{ option: insightsLbls.option + "1" },
								{ option: insightsLbls.option + "2" },
								{ option: insightsLbls.option + "3" }]
						}
					}

					setOptionsSortedList(qnOptions);

					break;

				case 'Range':
					qnOptions = [1, 10];
					break;

				case '3 Star-rating':
					qnOptions = [1, 3];
					break;

				case '3 Star-Rating':
					qnOptions = [1, 3];
					break;

				case 'Yes-No':
					qnOptions = [{ option: insightsLbls.yes, optionReferenceLink: "", nextHopOnBranch: 0 }, { option: insightsLbls.no, optionReferenceLink: "", nextHopOnBranch: 0 }];
					break;

				default:
					break;
			}

			let branchFlag = 0;



			if (qnType.selectedType === 'Range') {
				setQnType((prevState: any) => {
					return {
						...prevState,
						rangeQuestionCount: qnType.rangeQuestionCount + 1
					}
				})

				dummyData[pageIndex].push({
					'optn': qnType.selectedType,
					'quesText': qnText,
					'ques_option': qnOptions,
					"pageIndex": pageIndex + 1,
					"indexInPage": questionsData.pageArray[pageIndex].length,
					"analyzerCheck": qnType.rangeQuestionCount === 0 ? true : false,
					"isBranchNode": false,
					'editFlag': false

				});
			} else if (qnType.selectedType === 'Text') {
				dummyData[pageIndex].push({
					'optn': qnType.selectedType,
					'quesText': qnText,
					'ques_option': qnOptions,
					"pageIndex": pageIndex + 1,
					"indexInPage": questionsData.pageArray[pageIndex].length,
					"valiateEmail": false,
					"isBranchNode": false,
					'editFlag': false
				});
			}
			else if (qnType.selectedType === 'Single Select' || qnType.selectedType === 'Yes-No') {
				dummyData[pageIndex].push({
					'optn': qnType.selectedType,
					'quesText': qnText,
					'ques_option': qnOptions,
					"pageIndex": pageIndex + 1,
					"indexInPage": questionsData.pageArray[pageIndex].length,
					"isBranchNode": false,
					'editFlag': false
				});

				let branchingQuestionCountTemp: any = qnType.branchingQuestionCount.slice();

				if (branchingQuestionCountTemp[pageIndex] === undefined) {
					branchingQuestionCountTemp[pageIndex] = 1
				} else branchingQuestionCountTemp[pageIndex]++

				setQnType((prevState: any) => {
					return {
						...prevState,
						branchingQuestionCount: [...branchingQuestionCountTemp]
					}
				})

			} else {
				dummyData[pageIndex].push({
					'optn': qnType.selectedType,
					'quesText': qnText,
					'ques_option': qnOptions,
					"pageIndex": pageIndex + 1,
					"indexInPage": questionsData.pageArray[pageIndex].length,
					"isBranchNode": false,
					'editFlag': false
				});
			}


			let data = dummyData[pageIndex];
			const d = dummyData[pageIndex].length - 2;
			if (d >= 0) {
				if (dummyData[pageIndex][d].isBranchNode) {
					let splitData: any = data;
					let node = splitData.splice(d, 1);


					// to rearrange qns index
					for (let j = 0; j < splitData.length; j++) {
						splitData[j]['indexInPage'] = j;
					}

					let nodeData: any = [];
					for (let i = 0; i < data.length; i++) {
						if (pageIndex === i) {
							nodeData[i] = splitData
						} else {
							nodeData[i] = nodeData[i]
						}
					}

					dummyData[pageIndex].push({
						'optn': node[0].optn,
						'quesText': node[0].quesText,
						'ques_option': node[0].ques_option,
						"pageIndex": pageIndex + 1,
						"indexInPage": dummyData[pageIndex].length,
						"isBranchNode": node[0].isBranchNode,
						'editFlag': node[0].editFlag
					});
				}
			}


			setQuestionData((prevState: any) => {
				return {
					...prevState,
					pageArray: dummyData,
					duplicateFlag: false
				}
			})

			// To clear data-list after adding into array
			const reset_elem = document.getElementById('qn_lists_' + pageIndex) as HTMLInputElement;
			if (reset_elem) {
				reset_elem.value = '';
			}
		} else {
			setQuestionData((prevState: any) => {
				return {
					...prevState,
					duplicateFlag: true
				}
			})
		}

		setSortListIndexes((prevState: any) => {
			return {
				...prevState,
				qnIndex: questionsData.pageArray[pageIndex].length - 1,
				pgIndex: pageIndex
			}
		})
	}



	const getRangeClass = (itemNumber: number) => {
		try {
		} catch (error) {
			showBoundary(error)
		}
		if (itemNumber < 6) {
			return 'redRange';
		}

		if (itemNumber === 6 || itemNumber === 7) {
			return 'yellowRange';

		}

		if (itemNumber === 8 || itemNumber === 9) {
			return 'greenRange';
		}
	}

	const getInputError = (inputVal: ChangeEvent<HTMLInputElement>, pgIndex: number, qnIndex?: any) => {
		try {
		} catch (error) {
			showBoundary(error)
		}
		if (qnIndex >= 0) {
			let dummyData: any = questionsData.pageArray;
			dummyData[pgIndex][qnIndex]['quesText'] = inputVal.target.value;

			setQuestionData((prevState: any) => {
				return {
					...prevState,
					pageArray: dummyData
				}
			})
		}
		setQnType((prevState: any) => {
			return {
				...prevState,
				inputError: (inputVal.target.value.length >= 3) ? false : true,
				selectedPage: pgIndex,
			}
		})
	}

	useEffect(() => {
		try {
		} catch (error) {
			showBoundary(error)
		}
		let disabledFlag = false;
		let dummyData = questionsData.pageArray;
		for (let i = 0; i < dummyData.length; i++) {
			if (dummyData[i].length === 0) {
				disabledFlag = true;
				break;
			}
		}

		setQnType((prevState: any) => {
			return {
				...prevState,
				addPage_disabled: disabledFlag
			}
		})
	}, [questionsData])

	const deleteQn = (pgIndex: number, qnIndex: number, optn: string, isBranchNode: boolean) => {
		try {
		} catch (error) {
			showBoundary(error)
		}
		setQuestionData((prevState: any) => {
			return {
				...prevState,
				loaderFlag: true
			}
		})
		let splitData: any = questionsData.pageArray[pgIndex];
		splitData.splice(qnIndex, 1);


		// to rearrange qns index
		for (let j = 0; j < splitData.length; j++) {
			splitData[j]['indexInPage'] = j;
		}

		let dummyData: any = [];
		for (let i = 0; i < questionsData.pageArray.length; i++) {
			if (pgIndex === i) {
				dummyData[i] = splitData
			} else {
				dummyData[i] = questionsData.pageArray[i]
			}
		}

		if (optn === 'Range') {
			qnType.rangeQuestionCount--;
			let cnt = 0;
			for (let i = 0; i < dummyData.length; i++) {
				for (let j = 0; j < dummyData[i].length; j++) {
					if (dummyData[i][j]['optn'] === 'Range' && dummyData[i][j]['analyzerCheck'] === true) {
						cnt = cnt + 1;
					}
				}
			}

			if (cnt === 0) {
				let checker: Boolean = false
				for (let i = 0; i < dummyData.length; i++) {
					for (let j = 0; j < dummyData[i].length; j++) {
						if (dummyData[i][j]['optn'] === 'Range') {
							dummyData[i][j]['analyzerCheck'] = true;
							checker = true;
							break;
						}
						// break;
					}
					if (checker) break;
				}
			}
		}

		if (optn === 'Single Select' || optn === 'Yes-No') {
			qnType.branchingQuestionCount[pgIndex]--;
			if (isBranchNode) {
				let loopCount = (optn === 'Yes-No') ? 2 : 3;
				let linkData: any = [...qnOverlay.linkArray]
				for (let itr1 = 0; itr1 < loopCount; itr1++) {
					linkData.pop();
				}

				setQnOverlay((prevState: any) => {
					return {
						...prevState,
						linkArray: linkData
					}
				})
			}
		}

		setTimeout(() => {
			setQuestionData((prevState: any) => {
				return {
					...prevState,
					pageArray: dummyData,
					loaderFlag: false
				}
			})

			if (splitData.length === 0) {
				deletePage(pgIndex);
			}
		}, 1000);

	}

	const optnTextChange = (inputVal: ChangeEvent<HTMLInputElement>, pgIndex: number, qnIndex: number, optnIndex: number) => {
		try {
		} catch (error) {
			showBoundary(error)
		}
		let dummyData: any = questionsData.pageArray;
		dummyData[pgIndex][qnIndex]['ques_option'][optnIndex]['option'] = inputVal.target.value;

		setTimeout(() => {
			const inpElem = document.getElementById("input_" + pgIndex + '_' + qnIndex + "_" + optnIndex) as HTMLInputElement;
			if (inpElem) {
				inpElem.focus();
			}
		}, 0);

		setQuestionData((prevState: any) => {
			return {
				...prevState,
				pageArray: dummyData,
			}
		})
	}

	const markGlobal = (selectedVal: ChangeEvent<HTMLInputElement>) => {
		try {
		} catch (error) {
			showBoundary(error)
		}
		setQuestionData((prevState: any) => {
			return {
				...prevState,
				setGlobal: selectedVal.target.checked
			}
		})
	}


	const moveQn = (direction: string, pgIndex: number, qnIndex: number) => {
		try {
		} catch (error) {
			showBoundary(error)
		}
		setQuestionData((prevState: any) => {
			return {
				...prevState,
				loaderFlag: true
			}
		})

		let dummyData: any = questionsData.pageArray;

		let movedData: any = questionsData.pageArray[pgIndex];
		if (direction === 'up') {
			let upData = movedData.splice(qnIndex - 1, 1)[0];
			movedData.splice(qnIndex, 0, upData);
		} else {
			let downData = movedData.splice(qnIndex + 1, 1)[0];
			movedData.splice(qnIndex, 0, downData);
		}

		for (let i = 0; i < movedData.length; i++) {
			movedData[i]['indexInPage'] = i;
		}

		for (let i = 0; i < dummyData.length; i++) {
			if (pgIndex === i) {
				dummyData[i] = movedData;
			}
		}

		setTimeout(() => {
			setQuestionData((prevState: any) => {
				return {
					...prevState,
					pageArray: dummyData,
					loaderFlag: false
				}
			})

		}, 1000);
	}

	const change_conversationTitle = (inputVal) => {
		try {
		} catch (error) {
			showBoundary(error)
		}
		setQuestionData((prevState: any) => {
			return {
				...prevState,
				conversationTitle: inputVal.target.value
			}
		})
	}


	useEffect(() => {
		try {
		} catch (error) {
			showBoundary(error)
		}
		setQnType((prevState: any) => {
			return {
				...prevState,
				createBtnDisabled: (questionsData.conversationTitle.length === 0 || questionsData?.pageArray[0]?.length === 0) ? true : qnOverlay?.linkArray?.length > 0 ? true : false
			}
		})
	}, [questionsData, qnOverlay])

	const createSurvey = () => {
		try {
		} catch (error) {
			showBoundary(error)
		}
		setQuestionData((prevState: any) => {
			return {
				...prevState,
				requestFlag: true
			}
		})

		let dummyData = questionsData.pageArray;
		let qnsArray: any = [];
		let index = 0

		if (questionsData.editFlag === true) {
			for (let i = 0; i < dummyData.length; i++) {
				for (let j = 0; j < dummyData[i].length; j++) {
					qnsArray[index] = {};
					qnsArray[index].indexInPage = dummyData[i][j]['indexInPage'] + 1;
					qnsArray[index].options = dummyData[i][j]['ques_option'];
					qnsArray[index].pageIndex = dummyData[i][j]['pageIndex'];
					qnsArray[index].questionID = dummyData[i][j]['questionID'];
					qnsArray[index].question = dummyData[i][j]['quesText'];
					qnsArray[index].type = getRequiredType(dummyData[i][j]['optn']);
					qnsArray[index].isBranchNode = dummyData[i][j]['isBranchNode'];
					qnsArray[index].isAnalyzer = dummyData[i][j]['analyzerCheck'];
					if (getRequiredType(dummyData[i][j]['optn']) === 'comment') {
						qnsArray[index].validateEmail = dummyData[i][j]['validateEmail'] ?? false;
					}
					index++;
				}
			}
		} else if (questionsData.editFlag === false) {
			for (let i = 0; i < dummyData.length; i++) {
				for (let j = 0; j < dummyData[i].length; j++) {
					qnsArray[index] = {};
					qnsArray[index].indexInPage = dummyData[i][j]['indexInPage'] + 1;
					qnsArray[index].options = dummyData[i][j]['ques_option'];
					qnsArray[index].pageIndex = dummyData[i][j]['pageIndex'];
					qnsArray[index].question = dummyData[i][j]['quesText'];
					qnsArray[index].type = getRequiredType(dummyData[i][j]['optn']);
					qnsArray[index].isBranchNode = dummyData[i][j]['isBranchNode'];
					qnsArray[index].isAnalyzer = dummyData[i][j]['analyzerCheck'];
					if (getRequiredType(dummyData[i][j]['optn']) === 'comment') {
						qnsArray[index].validateEmail = dummyData[i][j]['validateEmail'] ?? false;
					}
					index++;
				}
			}
		}

		if (questionsData.editFlag) {
			let edit_survey = {
				"conversationTitle": questionsData.conversationTitle,
				"appCode": "",
				"conversationLabels": questionsData.labelData,
				"questions": qnsArray,
				"conversationCode": questionsData.conversationCode,
			}

			putCall(edit_survey, 'CREATE_CHATBOT', '').then((data: any) => {
				if (data.result === 'success') {
					toast.success(insightsLbls.successfullyUpdated, {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'dark',
					});
					setTimeout(() => {
						// toast.dismiss();
					}, 5000);

					setQuestionData((prevState: any) => {
						return {
							...prevState,
							requestFlag: false
						}
					})

					props.dispatchBack('fromApi');
				} else if (data.result === 'error') {
					if (data.error) {
						toast.error(data.error, {
							position: "top-right",
							autoClose: 5000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						});
						setTimeout(() => {
							toast.dismiss();
						}, 5000);
					}

					setQuestionData((prevState: any) => {
						return {
							...prevState,
							requestFlag: false
						}
					})
				}
			})
		} else {
			let create_survey = {
				"conversationTitle": questionsData.conversationTitle,
				"appCode": "",
				"conversationLabels": questionsData.labelData,
				"questions": qnsArray
			}
			postCall(create_survey, 'CREATE_CHATBOT').then((data: any) => {
				if (data.result === 'success') {
					toast.success(insightsLbls.successfullyAdded, {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
					setTimeout(() => {
						toast.dismiss();
					}, 5000);

					setQuestionData((prevState: any) => {
						return {
							...prevState,
							requestFlag: false
						}
					})

					props.dispatchBack('fromApi');
				} else if (data.result === 'error') {
					if (data.error) {
						toast.error(data.error, {
							position: "top-right",
							autoClose: 5000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						});
						setTimeout(() => {
							toast.dismiss();
						}, 5000);
					}

					setQuestionData((prevState: any) => {
						return {
							...prevState,
							requestFlag: false
						}
					})
				}
			})
		}
	}


	const getRequiredType = (type: string) => {
		try {
		} catch (error) {
			showBoundary(error)
		}
		switch (type) {
			case 'Text':
				return 'comment'

			case 'Single Select':
				return 'select'

			case 'Multi Select':
				return 'multi-select'

			case 'Range':
				return 'range'

			case 'Yes-No':
				return 'yes-no'

			case '3 Star-rating':
				return 'rating'

			case '3 Star-Rating':
				return 'rating'

			default:
				break;
		}
	}

	const getEditType = (type: string) => {
		try {
		} catch (error) {
			showBoundary(error)
		}
		switch (type) {
			case 'comment':
				return 'Text'

			case 'select':
				return 'Single Select'

			case 'multi-select':
				return 'Multi Select'

			case 'range':
				return 'Range'

			case 'yes-no':
				return 'Yes-No'

			case 'rating':
				return '3 Star-rating'

			default:
				break;
		}
	}

	const closeAnalyzerModal = () => {
		setAnalyzerModal((prevState: any) => {
			return {
				...prevState,
				modalOpen: false
			}
		})
	}

	const closebranchingModal = () => {
		setBranchingModal((prevState: any) => {
			return {
				...prevState,
				modalOpen: false,
				branchingSingleOptionsCheck: false,
				tempChecker: '',
				branchingFlag: false,
			}
		})
	}

	const openModal = (event, pgIndex: number, qnIndex: number) => {
		try {
		} catch (error) {
			showBoundary(error)
		}
		event.preventDefault();
		setAnalyzerModal((prevState: any) => {
			return {
				...prevState,
				modalOpen: true,
				analyzer_pgIndex: pgIndex,
				analyzer_qnIndex: qnIndex,
				analyzerFlag: event.target.checked
			}
		})
	}

	const openBranchingModal = (event, pgIndex: number, qnIndex: number, type: string) => {
		try {
			event.preventDefault();
			//to check if single select question is having options more than 3
			if (type === 'Single Select' && questionsData?.pageArray[pgIndex][qnIndex]['ques_option'].length > 3) {
				setBranchingModal((prevState: any) => {
					return {
						...prevState,
						branchingSingleOptionsCheck: true,
						modalOpen: true
					}
				})

			} else {
				//if branching question is selected
				if (event.target.checked) {
					let dummyData: any = questionsData.pageArray;
					let branchingSelected: any;
					for (let itr0 = 0; itr0 < dummyData[pgIndex].length; itr0++) {
						branchingSelected = dummyData[pgIndex][itr0]['isBranchNode'] ? true : false;
						if (branchingSelected) break;
					}

					setBranchingModal((prevState: any) => {
						return {
							...prevState,
							modalOpen: true,
							branching_pgIndex: pgIndex,
							branching_qnIndex: qnIndex,
							branchingFlag: branchingSelected,
							tempChecker: branchingSelected ? '' : 'set'
						}
					})


				} else {
					//if branching question is unchecked
					setBranchingModal((prevState: any) => {
						return {
							...prevState,
							modalOpen: true,
							branching_pgIndex: pgIndex,
							branching_qnIndex: qnIndex,
							tempChecker: 'unSet'
						}
					})
				}
			}
		} catch (error) {
			showBoundary(error)
		}

	}

	const setbranchingQn = (type?: any) => {
		try {
			let dummyData: any = [...questionsData.pageArray];
			let linkData: any = [...qnOverlay.linkArray];

			for (let itr0 = 0; itr0 < dummyData[branchingModal.branching_pgIndex].length; itr0++) {
				dummyData[branchingModal.branching_pgIndex][itr0]['isBranchNode'] = false;

				if (dummyData[branchingModal.branching_pgIndex][itr0]['optn'] === 'Yes-No' || dummyData[branchingModal.branching_pgIndex][itr0]['optn'] === 'Single Select') {
					const optionsData = dummyData[branchingModal.branching_pgIndex][itr0]['ques_option'];

					// remove from stack on branch qn change (for submit btn validation)
					for (let itr1 = 0; itr1 < optionsData.length; itr1++) {
						dummyData[branchingModal.branching_pgIndex][itr0]['ques_option'][itr1]['nextHopOnBranch'] = 0;
						if (branchingModal.tempChecker === 'unSet' && dummyData[branchingModal.branching_pgIndex][branchingModal.branching_qnIndex]['optn'] === dummyData[branchingModal.branching_pgIndex][itr0]['optn']) {
							linkData.pop();
						} else if (branchingModal.tempChecker === '' && dummyData[branchingModal.branching_pgIndex][branchingModal.branching_qnIndex]['optn'] !== dummyData[branchingModal.branching_pgIndex][itr0]['optn']) {
							linkData.pop();

						}
					}
				}
			}

			if (type === 'set' || type === undefined) {
				let loopCount = (dummyData[branchingModal.branching_pgIndex][branchingModal.branching_qnIndex]['optn'] === 'Yes-No') ? 2 : 3;
				for (let itr1 = 0; itr1 < loopCount; itr1++) {
					linkData.push(1);

				}
			}

			if (type === 'unSet') {
				setQnOverlay((prevState: any) => {
					return {
						...prevState,
						pgEndIndex: -1,
						pgStartIndex: -1,
						overlayFlag: false,
					}
				})
			}

			setQnOverlay((prevState: any) => {
				return {
					...prevState,
					linkArray: linkData,
				}
			})


			if (type !== 'unSet') {
				dummyData[branchingModal.branching_pgIndex][branchingModal.branching_qnIndex]['isBranchNode'] = true;
			}

			//
			if (!dummyData[branchingModal.branching_pgIndex][branchingModal.branching_qnIndex]['isBranchNode']) {
				setQuestionData((prevState: any) => {
					return {
						...prevState,
						pageArray: [...dummyData]
					}
				})
			} else {
				// to change qn order if qn is set as a Branch Question
				setBranchingQnOrder(branchingModal.branching_pgIndex, branchingModal.branching_qnIndex, dummyData)
			}
		} catch (error) {
			showBoundary(error)
		}

	}

	const setBranchingQnOrder = (pgIndex: number, qnIndex: number, qnsData: any) => {
		try {
			setSortListIndexes((prevState: any) => {
				return {
					...prevState,
					flag: false,
					loaderFlag: false
				}
			})

			let splitData: any = qnsData[pgIndex];
			let node = splitData.splice(qnIndex, 1);

			// to rearrange qns index
			for (let j = 0; j < splitData.length; j++) {
				splitData[j]['indexInPage'] = j;
			}

			let dummyData: any = [];
			for (let i = 0; i < qnsData.length; i++) {
				if (pgIndex === i) {
					dummyData[i] = splitData
				} else {
					dummyData[i] = qnsData[i]
				}
			}

			dummyData[pgIndex].push({
				'optn': node[0].optn,
				'quesText': node[0].quesText,
				'ques_option': node[0].ques_option,
				"pageIndex": pgIndex + 1,
				"indexInPage": dummyData[pgIndex].length,
				"isBranchNode": true,
				'editFlag': node[0].editFlag
			});


			$('.pageBoder-inner').animate({
				scrollTop: $('.pageBoder-inner')[0].scrollHeight
			}, 'slow');

			setQuestionData((prevState: any) => {
				return {
					...prevState,
					pageArray: [...dummyData]
				}
			})
		} catch (error) {
			showBoundary(error)
		}

	}

	const setAnalyzerQn = () => {
		try {
			for (let i = 0; i < document.querySelectorAll(".analyzer-checkbox").length; i++) {
				const element = document.getElementsByClassName('analyzer-checkbox')[i] as HTMLInputElement;
				if (element !== undefined) {
					element.checked = false;
				}
			}

			setTimeout(() => {
				const elem = document.getElementById('analyzer_' + analyzerModal.analyzer_pgIndex + '_' + analyzerModal.analyzer_qnIndex) as HTMLInputElement;
				if (elem) {
					elem.checked = true
				}
			}, 0)


			let dummyData: any = questionsData.pageArray;
			for (let i = 0; i < dummyData.length; i++) {
				for (let j = 0; j < dummyData[i].length; j++) {
					dummyData[i][j]['analyzerCheck'] = false;

				}
			}
			dummyData[analyzerModal.analyzer_pgIndex][analyzerModal.analyzer_qnIndex]['analyzerCheck'] = true;

			setQuestionData((prevState: any) => {
				return {
					...prevState,
					pageArray: dummyData
				}
			})

			setQnType((prevState: any) => {
				return {
					...prevState,
					createBtnDisabled: true
				}
			})
		} catch (error) {
			showBoundary(error)
		}

	}

	let addChangeBtnProps = {
		dispatchModalCancel: closeAnalyzerModal,
		dispatchModalOk: setAnalyzerQn,
		okBtn: insightsLbls.change,
		cancelBtn: insightsLbls.cancel
	}
	let addChangeBranchingModalBtnProps = {
		dispatchModalCancel: closebranchingModal,
		dispatchModalOk: setbranchingQn,
		okBtn: insightsLbls.change,
		cancelBtn: insightsLbls.cancel
	}

	let removeChangeBtnProps = {
		dispatchModalCancel: closeAnalyzerModal,
		cancelBtn: insightsLbls.ok
	}
	let removeChangeBranchingModalBtnProps = {
		dispatchModalCancel: closebranchingModal,
		cancelBtn: insightsLbls.ok
	}

	let unSetBranchingBtnProps = {
		dispatchModalCancel: closebranchingModal,
		dispatchModalOk: setbranchingQn.bind(this, 'unSet'),
		okBtn: insightsLbls.change,
		cancelBtn: insightsLbls.cancel
	}

	let labelProps = {
		selectedLabels: props.formData.conversationLabels
	}


	const closeRefModal = () => {
		setRefModal((prevState: any) => {
			return {
				...prevState,
				modalOpen: false
			}
		})
	}

	const openRefModal = (event: any, pgIndex: number, qnIndex: number, optnIndex: number, linkText: any) => {
		event.preventDefault();
		setRefModal((prevState: any) => {
			return {
				...prevState,
				modalOpen: true,
				optnPgIndex: pgIndex,
				optnQnIndex: qnIndex,
				optnIndex: optnIndex,
				linkText: linkText,
				refDisabled: false
			}
		})
	}

	const setLink = () => {
		try {
			setQuestionData((prevState: any) => {
				return {
					...prevState,
					loaderFlag: true
				}
			})
			let dummyData: any = questionsData.pageArray;
			dummyData[refModal.optnPgIndex][refModal.optnQnIndex]['ques_option'][refModal.optnIndex]['optionReferenceLink'] = refModal.linkVal;

			setTimeout(() => {
				setQuestionData((prevState: any) => {
					return {
						...prevState,
						pageArray: dummyData,
						loaderFlag: false
					}
				})
			}, 0);
		} catch (error) {
			showBoundary(error)
		}

	}

	const checkLink = (inputVal: ChangeEvent<HTMLInputElement>) => {
		try {
			let regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/g;

			const inputValue = inputVal.target.value;
			if (inputValue.length === 0) {
				setRefModal((prevState: any) => {
					return {
						...prevState,
						refDisabled: false,
						linkVal: inputValue
					}
				})
			} else {
				setRefModal((prevState: any) => {
					return {
						...prevState,
						refDisabled: regex.test(inputValue) ? false : true,
						linkVal: inputValue

					}
				})
			}
		} catch (error) {
			showBoundary(error)
		}

	}

	const addMoreOptns = (event: any, pgIndex: number, qnIndex: number) => {
		try {
			event.preventDefault();
			let dummyData: any = questionsData.pageArray;
			let optnsLength = dummyData[pgIndex][qnIndex]['ques_option'].length;
			if (optnsLength <= 9) {
				dummyData[pgIndex][qnIndex]['ques_option'][optnsLength] = {};
				dummyData[pgIndex][qnIndex]['ques_option'][optnsLength].option = insightsLbls.option + ' ' + (optnsLength + 1);
			}

			setQuestionData((prevState: any) => {
				return {
					...prevState,
					pageArray: dummyData
				}
			})
		} catch (error) {
			showBoundary(error)
		}

	}

	const deleteOption = (event: any, pgIndex: number, qnIndex: number, optnIndex: number) => {
		try {
			event.preventDefault();
			let dummyData: any = questionsData.pageArray;
			dummyData[pgIndex][qnIndex]['ques_option'].splice(optnIndex, 1);


			setQuestionData((prevState: any) => {
				return {
					...prevState,
					pageArray: dummyData
				}
			})
		} catch (error) {
			showBoundary(error)
		}

	}

	const createConditionPage = (ev: any, pgIndex: number, qnIndex: number, qnOptionIndex: number) => {
		try {
			setQnOverlay((prevState: any) => {
				return {
					...prevState,
					pgEndIndex: questionsData.pageArray.length,
					pgStartIndex: pgIndex + 1,
					sourcePageIndex: pgIndex,
					sourceQnIndex: qnIndex,
					optionIndex: qnOptionIndex
				}
			})

			setQnType((prevState: any) => {
				return {
					...prevState,
					addPage_disabled: true,
				}
			})
		} catch (error) {
			showBoundary(error)
		}

	}

	const linkPageQuestion = (pgIndex: number) => {
		try {
			setQnType((prevState: any) => {
				return {
					...prevState,
					addPage_disabled: false,
				}
			})

			let dummyData: any = questionsData.pageArray;
			const optionsLength = dummyData[qnOverlay.sourcePageIndex][qnOverlay.sourceQnIndex]['ques_option'].length;

			let flag: number = 0;

			for (let itr1 = 0; itr1 < optionsLength; itr1++) {

				if (dummyData[qnOverlay.sourcePageIndex][qnOverlay.sourceQnIndex]['ques_option'][itr1]['nextHopOnBranch'] === pgIndex + 1) {

					flag = flag + 1;
					if (dummyData[qnOverlay.sourcePageIndex][qnOverlay.sourceQnIndex]['optn'] === 'Yes-No' && flag === 1) {
						break;
					} else if (dummyData[qnOverlay.sourcePageIndex][qnOverlay.sourceQnIndex]['optn'] === 'Single Select' && flag > 2) {
						break;
					}
				}
			}

			if (flag === 0 && dummyData[qnOverlay.sourcePageIndex][qnOverlay.sourceQnIndex]['optn'] === 'Yes-No') {
				dummyData[qnOverlay.sourcePageIndex][qnOverlay.sourceQnIndex]['ques_option'][qnOverlay.optionIndex]['nextHopOnBranch'] = pgIndex + 1;
				setQuestionData((prevState: any) => {
					return {
						...prevState,
						pageArray: [...dummyData]
					}
				})

				let linkData: any = [...qnOverlay.linkArray];
				linkData.pop();

				setQnOverlay((prevState: any) => {
					return {
						...prevState,
						pgEndIndex: -1,
						pgStartIndex: -1,
						overlayFlag: true,
						targetPageIndex: pgIndex,
						sameQnFlag: false,
						linkArray: linkData
					}
				})
			}

			else if (flag < 2 && dummyData[qnOverlay.sourcePageIndex][qnOverlay.sourceQnIndex]['optn'] === 'Single Select') {
				dummyData[qnOverlay.sourcePageIndex][qnOverlay.sourceQnIndex]['ques_option'][qnOverlay.optionIndex]['nextHopOnBranch'] = pgIndex + 1;
				setQuestionData((prevState: any) => {
					return {
						...prevState,
						pageArray: [...dummyData]
					}
				})

				let linkData: any = [...qnOverlay.linkArray];
				linkData.pop();

				setQnOverlay((prevState: any) => {
					return {
						...prevState,
						pgEndIndex: -1,
						pgStartIndex: -1,
						overlayFlag: true,
						targetPageIndex: pgIndex,
						sameQnFlag: false,
						linkArray: linkData
					}
				})
			}
			else {
				setQnOverlay((prevState: any) => {
					return {
						...prevState,
						sameQnFlag: true,
						pgEndIndex: -1,
						pgStartIndex: -1,
					}
				})
			}
		} catch (error) {
			showBoundary(error)
		}

	}


	const getQnValue = (value: string) => {
		try {
			switch (value) {
				case '3 Star-Rating':
					return insightsLbls.threeStarRating;

				case 'Yes-No':
					return insightsLbls.yesNo;

				case 'Text':
					return insightsLbls.text;

				case 'Single Select':
					return insightsLbls.singleSelect;

				case 'Multi Select':
					return insightsLbls.multiSelect;

				case 'Range':
					return insightsLbls.range;

				default:
					return insightsLbls.selectType;
			}
		} catch (error) {
			showBoundary(error)
		}
	}

	const validateEmailCheck = (event, pgIndex: number, qnIndex: number) => {
		try {
			let dummyData: any = questionsData.pageArray;
			dummyData[pgIndex][qnIndex]['validateEmail'] = event.target.checked;

			setQuestionData((prevState: any) => {
				return {
					...prevState,
					pageArray: dummyData
				}
			})
		} catch (error) {
			showBoundary(error)
		}

	}

	const swap = (data, pos1, pos2) => {
		let temp = data[pos1];
		data[pos1] = data[pos2];
		data[pos2] = temp;
		return data;
	}

	const onDragEndHandler = (event, pgIndex, qnIndex) => {
		let { active, over } = event;
		let dummyData: any = questionsData.pageArray;
		const data = dummyData[pgIndex][qnIndex]['ques_option'];
		dummyData[pgIndex][qnIndex]['ques_option'] = swap(data, active.id - 1, over.id - 1);

		setQuestionData((prevState: any) => {
			return {
				...prevState,
				pageArray: dummyData
			}
		})

	};

	const SortableQuestion = ({ qn_data, qnOptn_index, pgIndex, qnIndex, optns }) => {
		const { setNodeRef, transform, transition, listeners } = useSortable({ id: qnOptn_index + 1 });
		const style = {
			transform: cssDndKit.Transform.toString(transform),
			transition,
		};
		return (
			<li style={style}>
				<div className="marginTop-8 displayInlineFlex alignCenter positionRelative qnBox" key={qnOptn_index}>
					<label className="radio-container" ref={setNodeRef} {...listeners}>
						<input type="radio" checked disabled name="selectQuestion" value="selectQuestion" />
						<span className="radio-checkmark"></span>
					</label>
					<input className="singleChoice width200 marginLeft-20"
						id={'input_' + pgIndex + '_' + qnIndex + '_' + qnOptn_index}
						name="survey_title" autoComplete="off"
						value={optns['option']}
						disabled={qn_data['editFlag']}
						onChange={(ev) => optnTextChange(ev, pgIndex, qnIndex, qnOptn_index)} />


					<button className="linkBtn fa fa-link"
						style={{ borderRadius: "6px;", color: (optns['optionReferenceLink']) ? '#08b6c7' : '' }}
						onClick={(ev) => openRefModal(ev, pgIndex, qnIndex, qnOptn_index, optns['optionReferenceLink'])}
						id={"link_" + pgIndex + "_" + qnIndex + "_" + qnOptn_index} disabled={qn_data['editFlag']}>
					</button>


					{qn_data.isBranchNode &&
						(
							<button type="button"
								className="linkPage-btn"
								onClick={(ev) => createConditionPage(ev, pgIndex, qnIndex, qnOptn_index)}
								disabled={(questionsData.pageArray.length - 1 === pgIndex || questionsData.pageArray[questionsData.pageArray.length - 1].length === 0)}>
								{qn_data['ques_option'][qnOptn_index]['nextHopOnBranch'] > 0
									? (<>{insightsLbls.linkedPage} {qn_data['ques_option'][qnOptn_index]['nextHopOnBranch']}</>)
									: (<>{insightsLbls.linkPage}</>)
								}
							</button>
						)}

					{!qn_data['editFlag'] && (
						<button className="deletePage-btn-square deleteOptn fa fa-trash"
							onClick={(ev) => deleteOption(ev, pgIndex, qnIndex, qnOptn_index)}
							disabled={qn_data['ques_option'].length <= 3}>
						</button>
					)}

				</div>
			</li >
		);
	}

	const SortableMultiQuestion = ({ qn_data, qnOptn_index, pgIndex, qnIndex, optns }) => {
		const { attributes, setNodeRef, transform, transition, listeners } = useSortable({ id: qnOptn_index + 1 });
		const style = {
			transform: cssDndKit.Transform.toString(transform),
			transition,
		};

		return (
			<li style={style}>
				<div className="marginTop-8 displayInlineFlex alignCenter positionRelative qnBox" key={qnOptn_index}>
					<label className="check-container displayFlex alignCenter exportCheck marginBottom-0 font14" ref={setNodeRef} {...attributes} {...listeners}>
						<input className="custom-control-input chk checkbox form-checkbox" checked type="checkbox" name="multiQuestion" value="multiQuestion" disabled />
						<span className="checkmark checkmark-qn form-checkbox-span displayInlineBlock top9"></span> &nbsp;
					</label>
					<input disabled={qn_data['editFlag']} id={'input_' + pgIndex + '_' + qnIndex + '_' + qnOptn_index} className="multiChoice width200 marginLeft-20" name="survey_title" autoComplete="off" defaultValue={optns['option']} onChange={(ev) => optnTextChange(ev, pgIndex, qnIndex, qnOptn_index)} />
					{!qn_data['editFlag'] && (
						<button className="deletePage-btn-square deleteOptn fa fa-trash"
							onClick={(ev) => deleteOption(ev, pgIndex, qnIndex, qnOptn_index)}
							disabled={qn_data['ques_option'].length <= 3}>
						</button>
					)}

				</div>
			</li>
		)
	}


	return (
		<>
			<SectionFilters>
				<Datepicker source="component"></Datepicker>
			</SectionFilters>

			<div className='chatbotSection'>
				<div className="marginTop-147 width100 formChatbotSection">
					<div className="backList-div backToList">
						<span className="backToCatList" onClick={() => props.dispatchBack('click')}>
							<i className="fa fa-arrow-left" aria-hidden="true"></i>{insightsLbls.showList}
						</span>
					</div>

					<>

						<Form
							className="font14 positionRelative createEdit-form"
							style={{ display: 'inline-table' }}>

							<>
								{questionsData.requestFlag && (
									<div className="displayFlex alignCenter margin-0-auto formLoader">
										<Loader></Loader>
									</div>
								)}

							</>

							<>
								<div className="col-md-12 col-12 col-lg-12 row padding0 marginLR-0">
									<FormGroup controlId="formEmail" className="col-md-8 col-8 col-lg-8  padding0">
										<div className="font12 marginBottom5">{insightsLbls.chatBotTitle}<span className="requiredStar">*</span></div>
										<FormControl
											name="conversationTitle"
											type="text"
											placeholder={insightsLbls.chatBotTitle}
											autoComplete="off"
											required={true}
											onChange={(ev) => change_conversationTitle(ev)}
											defaultValue={questionsData.conversationTitle}
										/>
									</FormGroup>
									{/* <div className="col-md-4 col-4 col-lg-4 displayFlex">
								<label className="check-container displayFlex alignCenter exportCheck font14 marginRight-10">
									<input className="custom-control-input chk checkbox form-checkbox"
										type="checkbox"
										name=""
										value=""
										onChange={(ev) => markGlobal(ev)}
										defaultChecked={props.formData.appCode === "" ? true : false}
										disabled={questionsData.editFlag} />
									<span className="checkmark checkmark-tooltip form-checkbox-span displayInlineBlock"></span>
								</label>
								<span className="marginLeft-35 markGlobal-span">{insightsLbls.markGlobal}
									<br />({insightsLbls.availableAllAppsInAcc}
									<span className="font600"> {insightsLbls.mandatoryForChatBot}</span>).
								</span>
							</div> */}

								</div>

								<div className="width100 marginTop-15 col-md-12 col-12 col-lg-12 padding0">
									<span className="tagModalText">{insightsLbls.label}</span>
									{rulesModal.labelFlag && rulesModal.loadLabel ? (
										<Label
											labeldata={rulesModal.labelData}
											getLabelData={getLabelData}
											{...(questionsData.editFlag ? { ...labelProps } : "")}
										></Label>
									) : null}
								</div>

								{questionsData.duplicateFlag && (
									<div className="errorMsg-path marginTop-10 width100 marginTop-15 col-md-12 col-12 col-lg-12 padding0 paddingTop-10 paddingBottom-10">
										{insightsLbls.enterDiffQuestType}
									</div>
								)}

								{qnOverlay.sameQnFlag && (
									<div className="errorMsg-path marginTop-10 width100 marginTop-15 col-md-12 col-12 col-lg-12 padding0 paddingTop-10 paddingBottom-10">
										{insightsLbls.linkDiffPage}
									</div>
								)}

								<div className="questions-scrollDiv col-lg-12 displayFlex marginTop-30 padding0" id="scrollDiv">
									{questionsData.pageArray.map((pg_data: any, pgIndex: number) => {
										return (
											<>
												{questionsData.loaderFlag
													? (
														<div className="page-border positionRelative">
															<div className="pageBoder-inner">
																<Loader></Loader>
															</div>
														</div>
													)
													: (
														<>
															<div className="page-border positionRelative" key={pgIndex}>

																{(pgIndex <= qnOverlay.pgEndIndex && pgIndex >= qnOverlay.pgStartIndex) && (
																	<div className="qnOverlay-div">
																		<button type="button" className="qnLink-btn" onClick={() => linkPageQuestion(pgIndex)}>{insightsLbls.linkQuest}</button>
																	</div>
																)}

																<p className="page-title-header">
																	<span>{insightsLbls.pageNumber} {pgIndex + 1} </span>
																	{pgIndex >= 1 && (
																		<span className="floatRight font12" onClick={() => deletePage(pgIndex)}>
																			<i className="pointer fa fa-trash delete-page-icon" aria-hidden="true"></i>
																		</span>
																	)}
																</p>
																<div className="pageBoder-inner" id={"pageBoder-inner_" + pgIndex}>
																	{questionsData.pageArray[pgIndex].length > 0 && questionsData.pageArray[pgIndex].map((qn_data: any, qnIndex: number) => {

																		return (
																			<ul className="questionList-ul">
																				<li className="inner-question-mainDiv textAlignLeft positionRelative"
																					style={{ backgroundColor: (!qn_data['editFlag']) ? '#ffffff' : '#f4f4f4' }}>
																					<div className="hoverHeader">
																						<span className="font600 font12 questionNumber">{qnIndex + 1}.</span>

																						{!qn_data.isBranchNode && (<>
																							{qnIndex > 0 && (
																								<span className="floatRight" onClick={() => moveQn('up', pgIndex, qnIndex)}>
																									<i className="pointer marginLeft-15 fa fa-arrow-up" aria-hidden="true"></i>
																								</span>
																							)}

																							{qnIndex < (questionsData.pageArray[pgIndex].length - 1) && (<>
																								{!questionsData.pageArray[pgIndex][qnIndex + 1]['isBranchNode'] && (
																									<span className="floatRight" onClick={() => moveQn('down', pgIndex, qnIndex)}>
																										<i className="pointer marginLeft-15 fa fa-arrow-down" aria-hidden="true"></i>
																									</span>
																								)}
																							</>
																							)}
																						</>)}



																						<span className="floatRight font12" onClick={() => deleteQn(pgIndex, qnIndex, qn_data['optn'], qn_data['isBranchNode'])}>
																							<i className="pointer fa fa-trash" aria-hidden="true"></i>
																						</span>
																					</div>

																					<div className="marginTop-8 box-container">
																						{qn_data.validateEmail && <p className='chatBotEmailValidation-msg'>{insightsLbls.chatBotValidateEmailMsg}</p>}

																						<input list="pg_qn_list"
																							name={"pg_qn_lists_" + pgIndex + "_" + qnIndex}
																							id={"pg_qn_lists_" + pgIndex + "_" + qnIndex}
																							className="width100 padding5"
																							disabled={qn_data.editFlag}
																							autoComplete="off"
																							placeholder={insightsLbls['enterQuestion']}
																							minLength={3}
																							defaultValue={qn_data.quesText}
																							onChange={(ev) => getInputError(ev, pgIndex, qnIndex)}
																						/>
																						<datalist id="pg_qn_list">
																							{qnType.flag && qnType.selectedQns.map((option: any, index: number) => {
																								return <option value={option.q} key={index}>{option.q}</option>;
																							})}
																						</datalist>
																					</div>

																					{qn_data.optn === 'Text' && (
																						<>
																							<div className="analyzerDiv">
																								<label className="check-container displayFlex alignCenter exportCheck font14 width250">
																									<span className="marginLeft-35 marginTop-15" >{insightsLbls.validateEmail}</span>
																									<input className="custom-control-input chk checkbox form-checkbox analyzer-checkbox"
																										type="checkbox"
																										name="analyzer-checkbox"
																										value=""
																										id={"validateEmail_" + pgIndex + "_" + qnIndex}
																										onClick={(ev) => validateEmailCheck(ev, pgIndex, qnIndex)}
																										checked={qn_data.validateEmail} />
																									<span className="checkmark checkmark-tooltip form-checkbox-span displayInlineBlock"></span>
																								</label>
																							</div>
																						</>
																					)}


																					{qn_data.optn === 'Yes-No' && (
																						<>
																							<div className="analyzerDiv">
																								<label className="check-container displayFlex alignCenter exportCheck font14 width250">
																									<span className="marginLeft-35 marginTop-15" >{insightsLbls.branching}</span>
																									<input className={`custom-control-input chk checkbox form-checkbox branching-checkbox-${pgIndex}`}
																										type="checkbox"
																										name="branching-checkbox"
																										value=""
																										onClick={(ev) => openBranchingModal(ev, pgIndex, qnIndex, qn_data.optn)}
																										id={"branching_" + pgIndex + "_" + qnIndex}
																										checked={qn_data.isBranchNode}
																									/>
																									<span className="checkmark checkmark-tooltip marginTop-15 form-checkbox-span displayInlineBlock"></span>
																								</label>
																							</div>
																							<div className="font12 displayFlex alignCenter">
																								<label className="radio-container">
																									<input type="radio" checked disabled name="yesQuestion" value="yesQuestion" />
																									<span className="radio-checkmark"></span>
																								</label>
																								<span className="marginLeft-20 marginTop-7">{insightsLbls.yes}</span>

																								<button className="linkBtn fa fa-link"
																									id={"link_" + pgIndex + "_" + qnIndex + "_" + 0}
																									style={{ borderRadius: "6px;", color: (qn_data['ques_option'][0]['optionReferenceLink']) ? '#08b6c7' : '' }}
																									onClick={(ev) => openRefModal(ev, pgIndex, qnIndex, 0, qn_data['ques_option'][0]['optionReferenceLink'])}
																									disabled={qn_data['editFlag']}>
																								</button>


																								{qn_data.isBranchNode &&
																									(
																										<button type="button"
																											className="linkPage-btn"
																											onClick={(ev) => createConditionPage(ev, pgIndex, qnIndex, 0)}
																											disabled={(questionsData.pageArray.length - 1 === pgIndex || questionsData.pageArray[questionsData.pageArray.length - 1].length === 0)}>
																											{(qn_data['ques_option'][0]['nextHopOnBranch'] > 0)
																												? (<>{insightsLbls.linkedPage} {qn_data['ques_option'][0]['nextHopOnBranch']}</>)
																												: (<>{insightsLbls.linkPage}</>)
																											}
																										</button>
																									)}
																							</div>
																							<div className="font12 displayFlex alignCenter">
																								<label className="radio-container">
																									<input type="radio" checked disabled name="noQuestion" value="noQuestion" />
																									<span className="radio-checkmark"></span>
																								</label>
																								<span className="marginLeft-20 marginTop-7">{insightsLbls.no}</span>


																								<button className="linkBtn fa fa-link"
																									id={"link_" + pgIndex + "_" + qnIndex + "_" + 1}
																									style={{ borderRadius: "6px;", color: (qn_data['ques_option'][1]['optionReferenceLink']) ? '#08b6c7' : '' }}
																									onClick={(ev) => openRefModal(ev, pgIndex, qnIndex, 1, qn_data['ques_option'][1]['optionReferenceLink'])}
																									disabled={qn_data['editFlag']}>
																								</button>


																								{qn_data.isBranchNode &&
																									(<button type="button"
																										className="linkPage-btn"
																										onClick={(ev) => createConditionPage(ev, pgIndex, qnIndex, 1)}
																										disabled={(questionsData.pageArray.length - 1 === pgIndex || questionsData.pageArray[questionsData.pageArray.length - 1].length === 0)}>
																										{(qn_data['ques_option'][1]['nextHopOnBranch'] > 0)
																											? (<>{insightsLbls.linkedPage}  {qn_data['ques_option'][1]['nextHopOnBranch']}</>)
																											: (<>{insightsLbls.linkPage}</>)
																										}
																									</button>
																									)}
																							</div>
																						</>
																					)}

																					{qn_data.optn === 'Single Select' && (
																						<>
																							<div className="analyzerDiv">
																								<label className="check-container displayFlex alignCenter exportCheck font14 width250">
																									<span className="marginLeft-35 marginTop-15" >{insightsLbls.branching}</span>
																									<input className={`custom-control-input chk checkbox form-checkbox branching-checkbox-${pgIndex}`}
																										type="checkbox"
																										name="branching-checkbox"
																										value=""
																										onClick={(ev) => openBranchingModal(ev, pgIndex, qnIndex, qn_data.optn)}
																										id={"branching_" + pgIndex + "_" + qnIndex}
																										checked={qn_data.isBranchNode} />
																									<span className="checkmark checkmark-tooltip form-checkbox-span displayInlineBlock"></span>
																								</label>
																							</div>

																							<DndContext
																								collisionDetection={closestCenter}
																								onDragStart={({ active }) => {
																								}}
																								onDragEnd={(event) => {
																									onDragEndHandler(event, pgIndex, qnIndex)
																								}}>
																								<SortableContext items={qn_data.ques_option} strategy={verticalListSortingStrategy}>
																									<ul className="paddingLeft-0 listType">
																										{qn_data.ques_option.map((optns: any, qnOptn_index: number) => {
																											return (
																												<SortableQuestion qn_data={qn_data} qnOptn_index={qnOptn_index} pgIndex={pgIndex} qnIndex={qnIndex} optns={optns}></SortableQuestion>
																											);
																										})}
																									</ul>
																								</SortableContext>
																							</DndContext>

																							{!qn_data.isBranchNode && (
																								!qn_data['editFlag'] && (
																									<button className="create-survey-container addOption-btn modal-addMore-btn"
																										onClick={(ev) => addMoreOptns(ev, pgIndex, qnIndex)}
																										style={{
																											cursor: (qn_data['ques_option'].length > 9 || qn_data['ques_option'].length <= 2) ? 'default' : 'pointer',
																											backgroundColor: (qn_data['ques_option'].length > 9 || qn_data['ques_option'].length <= 2) ? '#efefef' : '',
																											color: (qn_data['ques_option'].length > 9 || qn_data['ques_option'].length <= 2) ? '#c1c1c1' : ''
																										}}
																										disabled={qn_data['ques_option'].length > 9 || qn_data['ques_option'].length <= 2}>
																										<span className="displayFlex margin-0-auto alignCenter">
																											<i className="fa fa-plus-circle floatLeft margin2" aria-hidden="true"></i>
																											<span className="add-btn-label">{insightsLbls.addOption}</span>
																										</span>
																									</button>
																								)
																							)}


																						</>
																					)}


																					{qn_data.optn === 'Multi Select' && (
																						<>
																							<DndContext collisionDetection={closestCenter}
																								onDragStart={({ active }) => {
																								}}
																								onDragEnd={(event) => {
																									onDragEndHandler(event, pgIndex, qnIndex)
																								}}>
																								<SortableContext items={qn_data.ques_option} strategy={verticalListSortingStrategy}>
																									<ul className="paddingLeft-0 listType">
																										{qn_data.ques_option.map((optns: any, qnOptn_index: number) => {
																											return (<SortableMultiQuestion qn_data={qn_data} qnOptn_index={qnOptn_index} optns={optns} pgIndex={pgIndex} qnIndex={qnIndex}></SortableMultiQuestion>)
																										})}
																									</ul>
																								</SortableContext>
																							</DndContext>
																							{!qn_data['editFlag'] && (
																								<button className="create-survey-container addOption-btn modal-addMore-btn"
																									onClick={(ev) => addMoreOptns(ev, pgIndex, qnIndex)}
																									style={{
																										cursor: (qn_data['ques_option'].length > 9 || qn_data['ques_option'].length <= 2) ? 'default' : 'pointer',
																										backgroundColor: (qn_data['ques_option'].length > 9 || qn_data['ques_option'].length <= 2) ? '#efefef' : '',
																										color: (qn_data['ques_option'].length > 9 || qn_data['ques_option'].length <= 2) ? '#c1c1c1' : ''
																									}}
																									disabled={qn_data['ques_option'].length > 9 || qn_data['ques_option'].length <= 2}>
																									<span className="displayFlex margin-0-auto alignCenter">
																										<i className="fa fa-plus-circle floatLeft margin2" aria-hidden="true"></i>
																										<span className="add-btn-label">{insightsLbls.addOption}</span>
																									</span>
																								</button>
																							)}

																						</>
																					)}


																					{(qn_data.optn === "3 Star-Rating" ||
																						qn_data.optn === "3 Star-rating") && (
																							<div className="displayInlineFlex marginTop-8 marginBottom-10">
																								{[...Array(starSelect)].map(() => {
																									return (
																										<div className="marginTop-8 marginLeft-5 displayInlineFlex marginRight-18">
																											<i className="fa fa-star starRating paddingLeft-10 paddingRight-0" aria-hidden="true"></i>
																										</div>
																									)
																								})}
																							</div>
																						)}

																					{qn_data.optn === 'Range' && (
																						<>
																							<div className="analyzerDiv">
																								<label className="check-container displayFlex alignCenter exportCheck font14 width250">
																									<span className="marginLeft-35 marginTop-15" >{insightsLbls.analyzer}</span>
																									<input className="custom-control-input chk checkbox form-checkbox analyzer-checkbox"
																										type="checkbox"
																										name="analyzer-checkbox"
																										value=""
																										onClick={(ev) => openModal(ev, pgIndex, qnIndex)}
																										id={"analyzer_" + pgIndex + "_" + qnIndex}
																										checked={qn_data.analyzerCheck} />
																									<span className="checkmark checkmark-tooltip form-checkbox-span displayInlineBlock"></span>
																								</label>
																							</div>

																							<div className="displayBlock">
																								{[...Array(10)].map((d: any, i: number) => {
																									return (
																										<div className="marginTop-8 displayInlineFlex marginLeft-16 marginRight-10">
																											<div className={"rangePadding " + getRangeClass(i)}>
																												{i < 6 && (
																													<span className="range-text">
																														<img className="faceIcon" src={require('../../assets/images/sadFace.png')} />
																													</span>)}

																												{(i === 8 || i === 9) && (
																													<span className="range-text">
																														<img className="faceIcon" src={require('../../assets/images/happyFace.png')} />
																													</span>)}


																												{(i === 6 || i === 7) && (
																													<span className="range-text">
																														<img className="faceIcon" src={require('../../assets/images/neutralFace.png')} />
																													</span>)}
																											</div>
																										</div>
																									)
																								})}
																							</div>

																							<div className="displayBlock">
																								{[...Array(10)].map((d: any, i: number) => {
																									return (

																										<div className="marginTop-0 displayInlineFlex marginLeft-16 marginRight-10">
																											<div className="rangePadding positionRelative bg-transparent">
																												<span className="range-text">{i + 1}</span>
																											</div>
																										</div>
																									)
																								})}

																								<div className="displayFlex">
																									<hr style={{ borderBottom: '2px solid red', margin: '1px', width: '58%' }} />
																									<hr style={{ borderBottom: '2px solid #f6c401', margin: '1px', width: '20%' }} />
																									<hr style={{ borderBottom: '2px solid #89ce01', margin: '1px', width: '20%' }} />
																								</div>
																								<div className="displayFlex">
																									<span style={{ width: '58%' }} className="rangeLegend-position">{insightsLbls.negative}</span>
																									<span style={{ width: '20%' }} className="rangeLegend-position">{insightsLbls.neutral}</span>
																									<span style={{ width: '20%' }} className="rangeLegend-position">{insightsLbls.positive}</span>
																								</div>
																							</div>
																						</>
																					)}
																				</li>
																			</ul>
																		)
																	}
																	)}
																</div>
																{
																	(qnType.selectedPage === pgIndex && qnType.inputError) && (
																		<div className="charMessage" id="charError">
																			{insightsLbls.must3CharLong}
																		</div>
																	)
																}

																<div className="addDivPosition inner-question-div bottom110" id={"addDivPosition_" + pgIndex}>
																	<div className="col-lg-12 displayFlex questionDropdown-div">
																		<ButtonToolbar id={"selectBtnType_" + pgIndex}>
																			<SplitButton
																				title={(pgIndex === qnType.selectedPage ? getQnValue(qnType.selectedType) : insightsLbls.selectType)}
																				drop={'up'}
																				align={'end'}
																				id={"selectType_" + pgIndex}
																				className="qs-dropdownBtn"
																				onSelect={(ev) => changeQn(ev, pgIndex)}
																				variant={'customBorder'}
																			>
																				<Dropdown.Item eventKey={"Text"} className="qsType">{insightsLbls.text}</Dropdown.Item>
																				<Dropdown.Item eventKey={"Single Select"} className="qsType">{insightsLbls.singleSelect}</Dropdown.Item>
																				<Dropdown.Item eventKey={"Multi Select"} className="qsType">{insightsLbls.multiSelect}</Dropdown.Item>
																				<Dropdown.Item eventKey={"Range"} className="qsType">{insightsLbls.range}</Dropdown.Item>
																				<Dropdown.Item eventKey={"Yes-No"} className="qsType">{insightsLbls.yesNo}</Dropdown.Item>
																				<Dropdown.Item eventKey={"3 Star-Rating"} className="qsType">{insightsLbls.threeStarRating}</Dropdown.Item>
																			</SplitButton>
																		</ButtonToolbar>

																		<input list="qn_list"
																			name="qn_lists"
																			id={"qn_lists_" + pgIndex}
																			className="text-input padding5 questionBox marginLR-5"
																			disabled={!qnType.flag || qnType.selectedType === 'Select Type'}
																			autoComplete="off"
																			placeholder={insightsLbls.enterQuestion}
																			minLength={3}
																			defaultValue={qnType.value}
																			onChange={(ev) => getInputError(ev, pgIndex)} />
																		<datalist id="qn_list">
																			{qnType.flag && qnType.selectedQns.map((option: any, index: number) => {

																				return <option value={option.q} key={index}>{option.q}</option>;
																			})}
																		</datalist>
																		<button
																			id={"addQuestionBtn_" + pgIndex}
																			className="question-button"
																			onClick={(ev) => addQuestion(ev, pgIndex)}
																			disabled={(qnType.selectedPage === pgIndex && qnType.inputError) || qnType.selectedType === insightsLbls.selectType}
																			style={{ cursor: ((qnType.selectedPage === pgIndex && qnType.inputError) || qnType.selectedType === insightsLbls.selectType) ? 'default' : 'pointer' }}>
																			<i className="fa fa-plus font20" aria-hidden="true"></i>
																		</button>
																	</div>
																</div>
															</div>

														</>
													)}
											</>
										)
									})}

									{(questionsData.pageArray.length > 0) && (
										<button
											className="create-survey-container add-page-btn"
											onClick={(e) => addPage(e)}
											disabled={qnType.addPage_disabled}>
											{qnType.addPage_disabled
												? (<img src={require("../../assets/images/new_page_inactive.png")} />)
												: (<img src={require("../../assets/images/new_page_active.png")} />)}


											<div className="add-btn-label">{insightsLbls.addPage}</div>
										</button>
									)}
								</div>

								<div className="marginTop-30 col-lg-12 displayFlex justify-content-end gap-15">
									<button type="button"
										className="btn btn-primary"
										onClick={() => createSurvey()}
										disabled={qnType.createBtnDisabled}>
										{insightsLbls.confirm}
									</button>
									<button type="button"
										className="btn btn-secondary"
										onClick={() => props.dispatchBack('click')}>
										{insightsLbls.cancel}
									</button>
								</div>
							</>
						</Form>

						<CommonModal
							{...(analyzerModal.analyzerFlag ? { ...addChangeBtnProps } : { ...removeChangeBtnProps })}
							modalState={analyzerModal.modalOpen}
							modalTitle={insightsLbls.analyzerQuestion}
							dispatchModalState={closeAnalyzerModal}
						>
							<>
								<div className="modal-body padding15">
									{!analyzerModal.analyzerFlag
										? (
											<p className="font14 font600"> {insightsLbls.mandatory1Analyzer}</p>
										)
										: (
											<>
												<p className="font14 font600">{insightsLbls.changeAnalyzerQuest}</p>
												<p className="font12 errorMsg-path">{insightsLbls.set1QuestAsAnalyzer}</p>
											</>
										)}

								</div>
							</>
						</CommonModal>
						<CommonModal
							// {...(branchingModal.branchingFlag && !branchingModal.branchingSingleOptionsCheck ? { ...addChangeBranchingModalBtnProps } : { ...removeChangeBranchingModalBtnProps })}
							{...(!branchingModal.branchingSingleOptionsCheck ? { ...(branchingModal.branchingFlag ? { ...addChangeBranchingModalBtnProps } : { ...(branchingModal.tempChecker === 'unSet' ? { ...unSetBranchingBtnProps } : { ...addChangeBranchingModalBtnProps }) }) } : { ...removeChangeBranchingModalBtnProps })}
							modalState={branchingModal.modalOpen}
							modalTitle={insightsLbls.branchingQuestion}
							dispatchModalState={closebranchingModal}
						>
							<>
								<div className="modal-body padding15">
									{branchingModal.branchingSingleOptionsCheck ?
										<>
											<p className="font14 font600"> {insightsLbls.conditionOfMoreThan3}</p>
											<p className="font12 errorMsg-path">{insightsLbls.noteAboutoptionsReduction}</p>
										</>
										: (branchingModal.branchingFlag ?
											(
												<>
													<p className="font14 font600">{insightsLbls.QuestForChangingBranchQuest}</p>
													<p className="font12 errorMsg-path">{insightsLbls.noteBranchQuestLimit}</p>
												</>
											)
											:
											branchingModal.tempChecker === 'unSet' ?
												(
													<>
														<p className="font14 font600">{insightsLbls.removeBranching}</p>
														{/* <p className="font12 errorMsg-path">(Note: Only 1 question can be set as branching question.)</p> */}
													</>
												) :

												(
													<>
														<p className="font14 font600">{insightsLbls.addBranching}</p>
														<p className="font12 errorMsg-path">{insightsLbls.noteBranchQuestLimit}</p>
													</>
												)
										)
									}
								</div>
							</>
						</CommonModal>

						<CommonModal
							modalState={refModal.modalOpen}
							modalTitle={insightsLbls.addOptionLink}
							dispatchModalState={closeRefModal}
							dispatchModalCancel={() => closeRefModal()}
							dispatchModalOk={setLink}
							okBtn={insightsLbls.save}
							cancelBtn={insightsLbls.cancel}
							okBtnState={refModal.refDisabled}
						>
							<>
								<div className="modal-body padding15">
									<input className="quesLink-inp"
										type="text"
										autoComplete="off"
										placeholder={insightsLbls.addLink}
										id="quesLink_inp"
										onChange={(ev) => checkLink(ev)}
										defaultValue={refModal.linkText} />
									{refModal.linkVal.length === 0
										&& (<p className="marginTop-5 font12 errorMsg">{insightsLbls.blankLinkMes}</p>)
									}
								</div>
							</>
						</CommonModal>
					</>
				</div >
			</div>
		</>

	)
};
export default ChatBotForm;