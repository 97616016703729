import * as ActionTypes from '../ActionTypes';
import axiosInstance, { getInstance } from '../../../network/apis/interceptor';
import { CommonUtils } from '../../../utils/CommonUtils';
import { ApiRelativePaths, RetryApi } from '../../../utils/GlobalConstants';


let ApiPaths: any = ApiRelativePaths;


export const setChatbotListData = (chatbotListData: any) => {
    return {
        type: ActionTypes.SET_CHATBOT_LIST,
        chatbotListData: chatbotListData
    }
};


export const setChatbotData = (chatbotData: any) => {
    return {
        type: ActionTypes.SET_CHATBOT_DATA,
        chatbotData: chatbotData
    }
};


export const getManageChatBotData = (params: any, url_path: any, caseType: any, apiErrorFlag: string, firstTimeload?: boolean) => {
    return new Promise((resolve, reject) => {
        let path = ApiPaths[url_path];
        let data: any = {};
        let paramObj = {};
        let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
        getInstance('chatbot/manage/');


        axiosInstance.get(generatedURL)
            .then(res => {
                data = {
                    "result": "success",
                    "data": res.data.data,
                    "firstTimeload": firstTimeload ?? false,
                    "requestUUID": res.data.requestUUID,
                };
            })
            .catch(err => {
                data = {
                    "result": "error",
                    "data": err,
                    "firstTimeload": firstTimeload ?? false,
                };
            })
            .then(() => {
                const callActionDispatch = () => {
                    switch (caseType) {
                        case ActionTypes.SET_CHATBOT_LIST:
                            resolve(setChatbotListData(data));
                            break;

                        case ActionTypes.SET_CHATBOT_DATA:
                            resolve(setChatbotData(data));
                            break;

                        default:
                            break;
                    }
                }

                if (data.result === 'retry') {
                    setTimeout(() => {
                        callActionDispatch();
                    }, RetryApi.TIMEOUT);
                } else {
                    callActionDispatch();
                }

            });
    });
}