if (GmXt === undefined) var GmXt = {};

GmXt.playerLbls = {};

GmXt.playerLbls.en_US = {
    yAxisLabelCount: "Count",
    playedCount: "Played Count",
    completedCount: "Completed Count",
    expandViews: "Expand Views",
    collapseViews: "Collapse Views",
    expand: "Expand",
    collapse: "Collapse",
    totalSteps: "Total Steps",
    slideshowStats: "Slideshow Stats",
    videoStats: "Video Stats",
    view: "View",
    deleted: "Deleted",
    published: "Published",
    unpublished: "Unpublished",
    unknown: "Unknown",
    allUsers: "All Users",
    download: "Download",
    viewOnline: "View Online",
    or: "Or",
    cioDashboardSubTitle: "Activity Across All Apps",
    cioDashboardModalTitle: "Export Data",
    play: "Play",
    totalPlayed: "Total Played",
    uniquePlayed: "Unique Played",
    completed: "Completed",
    pass: "Pass",
    fail: "Fail",
    panel: "Panel",
    withAudio: "With Audio",
    withouAudio: "Without Audio",
    linkedTable: "Linked Table",
    showGuideWithActivities: "Show Guides with Activities",
    submitted: "Submitted",
    cancelled: "Cancelled",
    hideDeleted: "Hide Deleted",
    totalPlayCount: "ShowMe Total Play Count",
    uniquePlayCount: "ShowMe Unique Play Count",
    testMeTotalPlayCount: "TestMe Total Play Count",
    testMeUniquePlayCount: "TestMe Unique Play Count",
    passCount: "Test Me Total Pass Count",
    failCount: "Test Me Total Fail Count",
    searchCount: "Number of times the search was performed.",
    actionCount: "Number of times action was taken when search results were displayed.",
    scheduledPlay: "Scheduled Play",
    manualPlay: "Manual Play",
    playedBy: "Played By",
    runType: "Run Type",
    result: "Result",
    status: "Status",
    srNo: "Sr. No",
    guideTitle: "Guide Title",
    description: "Description",
    moreInfo: "More Info",

    jobPrioritisedSuccessMsg: "Job Priortized Successfully!!",
    apiCacheDeletedMsg: "API Cache deleted successfully.",
    guideCreated: "Guides created",
    guideModified: "Guides modified",
    exportJobs: "Export Jobs",
    searchOrganization: "Search Organization",
    showing: "Showing",
    exportJobsList: "Export Jobs List",
    organization: "Organisation",
    requestTime: "Request Time",
    exportJobStatus: "Export Job Status",
    pending: "Pending",
    priortizeJob: "Priortize Job",
    priortized: "Priortized.",
    inProcess: "In Process",
    complete: "Complete",
    pendingEventsCount: "Pending Events Count",
    deleteCache: "Delete Cache",
    priortizeEventsForOrg: "Priortize Events for this Org.",
    tokenCancelledMsg: "Operation canceled due to new request.",
    selectAll: "Select All",
    application: "Application",
    appList: "App List",
    close: "Close",
    searchApp: "Search App",
    totalApps: "Total Apps",
    active: "Active",
    deletedApp: "Deleted App",
    operatingSystem: "Operating System",
    screenResolution: "Screen Resolution",
    webBrowser: "Web Browser",
    name: "Name",
    version: "Version",
    count: "Count",
    visitors: "Visitors",
    includeWeekend: "Include Weekends",
    last7Days: " Last 7 Days",
    last30Days: " Last 30 Days",
    last60Days: " Last 60 Days",
    last90Days: " Last 90 Days",
    last180Days: " Last 180 Days",
    last365Days: " Last 365 Days",
    lastCalendarYear: " Last Calendar Year",

    customDate: " Custom Date",
    weekendsIncluded: "Weekends Included",
    weekendsExcluded: "Weekends Excluded",
    withRealTimeData: "With Real-Time data",
    realTimeDataNotAvailable: "Real-Time data not available",
    includeRealTimeData: "Include Real-Time Data",
    excludeWeekends: "Exclude Weekends",
    from: "From",
    to: "To",
    invalidStartDate: "Invalid start date",
    invalidEndDate: "Invalid end date",
    selectDateRangeWithin12mmonths: "Select date range within 12 months",
    successfullyAdded: "Successfully Added!",
    dashboardName: "Dashboard Name",
    duplicateDashboardName: "Duplicate dashboard name",
    canAddUpto3Dashboards: "You can add upto 3 dashboards",
    dashboardName20Chars: "Dashboard name should only be upto 20 characters",
    pleaseEnterValidName: "Please enter a valid name",
    successfullyDeleted: "Successfully Deleted!",
    successfullyUpdated: "Successfully Updated!",
    chartsAlreadyAdded: "Chart already added in",
    createNewDashboardMsg: "You have no custom Dashboards, please create a new Dashboard.",
    createNewDashboard: "Create New Dashboard",
    allDashboardDataDeleted: "All Dashboard data will get deleted.",
    wantToDeleteDashboard: "Do you want to delete this dashboard? ",
    save: "Save",
    requestSent: "Request Sent!",
    requestCompleteTime: "Request Completed Time",
    jobStatus: "Job Status",
    type: "Type",
    surveyType: "Survey Type",
    surveyName: "Survey Name",
    stepName: "Step Name",
    selectFilter: "Select Filter",
    publicURL: "Public URL",
    filter: "Filter",
    filter1Placeholder: "Filter 1 (Guide Name, Step Name, Tooltip Name)",
    filter2Placeholder: "Filter 2 (Step Name)",
    tooltipList: "Tooltip List",
    clearAll: "Clear All",
    tooltipPlay: "Tooltip Play",
    played: "Played",
    lastPlayedOn: "Last played on",
    activityDetails: "Activity Details",
    exportExcel: "Export Excel",
    downloading: "Downloading...",
    exportPDF: "Export PDF",
    editFunnel: "Edit Funnel",
    clickHereToCreateFunnel: "Click Here To Create Funnel",
    step: "Step",
    funnelStepsInOrder: "Funnel steps in order",
    funnelStepsMsg: "Please select minimum of 3 steps and maximum of 7 steps to render the funnel.",
    funnelStepsStatsMsg: "* Stats for linked guides can be viewed on their respective detail pages.",
    max7Steps: "Maximum 7 steps can be selected.",
    clearList: "Clear List",
    submit: "Submit",
    playedVsCompleted: "Played Versus Completed",
    deleteChartMsg: "Do you want to delete this chart?",
    youAreHere: "You are here",
    successfullyRenamed: "Successfully Renamed!",
    dashboardName20CharsErrorMsg: "*Dashboard Name cannot be more than 20 characters",
    dashboardNameEmptyErrorMsg: "*Dashboard Name cannot empty",
    maintenanceInProgress: "Maintenance in progress.",
    pleaseContactUs: "Please contact us via",
    backToList: "Back to list",
    title: "Title",
    filterTitle: "Filter Title",
    max100chars: "Maximum 100 characters allowed",
    filterDescription: "Filter Description",
    selectOrganizations: "Select organizations",
    deleteFilter: "Delete Filter",
    deleteFilterMsg: "Do you want to delete this filter?",
    switchOrganization: "Switch Organization",
    ofPages: "OF PAGES",
    ofPagesViews: "OF PAGE VIEWS",
    untaggedURLS: "UNTAGGED URLS",
    taggedURLS: "TAGGED URLS",
    singleSelect: "Single Select",
    multiSelect: "Multi Select",
    text: "Text",
    range: "Range",
    rating: "Rating",
    yesNo: "Yes-No",
    URLHostname: "URL Hostname",
    URLPath: "URL Path",
    URLParameters: "URL Parameters",
    URLHash: "URL Hash",
    equals: "Equals",
    notEquals: "Not Equals",
    contains: "Contains",
    notContains: "Not Contains",
    startsWith: "Starts With",
    similarTo: "Similar to",
    endsWith: "Ends With",
    commonMsg: "Error: Invalid path, only alphanumeric characters and special characters (~,=,<,>,/,* only) are allowed, spaces aren\"t allowed in the path string",
    slashMsg: "Error: Path will always start with a forward slash",
    angularBracketMsg: "Error: Use open and close angular brackets(<>) together with some string inside",
    tilEqAngularMsg: "Error: Can\"t have <> and ~= together after forward slash",
    tilEqTogetMsg: "Error: Can\"t have ~ or = alone in the path, ~ will always be followed by =",
    starAloneMsg: "Error: Can\"t have anything in forward slashes if there is a star(*) already present, single star(*) will always be between two forward slashes",
    twoStarMsg: "Error: Can\"t have anything after **(double star) in the path string",
    noDoubleSlashMsg: "Error: Double slashes aren\"t allowed in the path string",
    onlyHyphenUnderMsg: "Error: Only _ and - is allowed in between angular brackets(<>)",
    noHyphenUnderMsg: "Error: _ and - is not allowed except between angular brackets(<>)",
    filedRequired: "Error: This field is required",
    hashMsg: "Error: Invalid URL Hash",
    paramaterAlphAndSpecialChar: "Error: Invalid Parameter, & followed with alphanumeric characters and special characters (-, _) are allowed",
    parameterStartMsg: "Error: Invalid Parameter, It will always start with a ? and Can\"t have ? followed with = in the parameter",
    parameterAllowedMsg: "Error: Invalid Parameter, only alphanumeric characters and special characters(-, _, =, &) are allowed",
    urlTypeSelected: "Type is already selected. Please select any other type.",
    errorHostnameAlreadyExists: "Error:- Hostname already exists",
    errorValueAlreadyExsts: "Error: Value already exists",
    URLHostnameMsg: "The URL Hostname is inherited from the application setting. And is the default rule across all Tags and Workflows inside an App",
    and: "AND",
    add: "Add",
    addGroup: "Add Group",
    searchStep: "Search Step",
    searchUser: "Search User",
    selectGuide: "Select Guide",
    segmentFilter: "Segment Filter",
    selectSegments: "Select Segments",
    summary: "Summary",
    edit: "Edit",
    delete: "Delete",
    bestTime: "Best Time",
    avgTime: "Avg. Time",
    worstTime: "Worst Time",
    tagPage: "Tag Page",
    label: "Label",
    cancel: "Cancel",
    deletePageTag: "Delete Page Tag",
    deleteConfirmation: "Are you sure you want to delete this Page Tag?",
    confirm: "Confirm",
    create: "Create",
    tagged: "Tagged",
    untagged: "Untagged",
    domainMessage: "App currently doesnt have a domain added. Please add a domain to Create or Tag Page.",
    workflowType: "Workflow Type",
    dynamicWorkflowPlayCount: "Dynamic Workflow Play Count",
    guideWorkflowPlayCount: "Guide Workflow Play Count",
    uniqueWorkflowPlayCount: "Unique Workflow Play Count",
    workflowDetails: "Workflow Details",
    workflowPlayCounts: "Workflows Play Count",
    positive: "Positive",
    neutral: "Neutral",
    negative: "Negative",
    surveyDetails: "Survey Details",
    copied: "Copied!",
    allowView: "Allow Anonymous View",
    linkExpiry: "Link Expires",
    change: "Change",
    ok: "Ok",
    showList: "Show List",
    surveyTitle: "Survey Title",
    markGlobal: "Mark as Global",
    availableAllAppsInAcc: "Available across all applications inside the account",
    mandatoryForExtSurvey: "Mandatory for Exit Survey",
    enterDiffQuestType: "Please enter different question of same type",
    linkDiffPage: "Can not link same page for the question. Please link different page.",
    linkQuest: "LINK QUESTIONS",
    addOption: "Add Option",
    analyzer: "Analyzer",
    must3CharLong: "Title must be at least 3 characters long.",
    threeStarRating: "3 Star-Rating",
    addPage: "Add Page",
    mandatory1Analyzer: "This cannot be unselected. One Analyzer question is mandatory.",
    changeAnalyzerQuest: "Are you sure you want to change the Analyzer question ?",
    set1QuestAsAnalyzer: "(Note: Only 1 question can be set as Analyzer question.)",
    conditionOfMoreThan3: "This cannot be selected as branching question if options are more than 3.",
    noteAboutoptionsReduction: "(Note: Reselect again after reducing the total number of options to 3)",
    QuestForChangingBranchQuest: "Are you sure you want to change the branching question ?",
    noteBranchQuestLimit: "(Note: Only 1 question per page can be set as branching question.)",
    removeBranching: "This action will remove the branching from the selected question.",
    addBranching: "This action will add the branching in the selected question.",
    blankLinkMes: "Blank link means no link is attached",
    goalDeleteConfirmation: "Are you sure you want to delete this goal?",
    viewDetails: "View Details",
    goalLiveOn: "Goal Live on",
    targetCompletion: "Target completion",
    currentStatus: "Current status",
    click: "Click",
    iconForNewGoal: "icon to create New Goal",
    clickForEditMessage: "Click on the goal from the list to edit that particular goal",
    goalTitle: "Goal Title",
    goalExistsMsg: "Goal already exists. Please select a different title.",
    goalDescription: "Goal Description",
    startTimeMsg: "Start Date/Time should be greater than 30minutes from current Date/Time",
    endTimeMsg: "End Date/Time should be greater than 24hrs from Start Date/Time",
    goalStartDate: "Goal Start Date",
    goalEndDate: "Goal End Date",
    canAddupto: "Can add upto",
    guideList: "Guide List",
    playCount: "Play Count",
    eventSource: "Event Source",
    playMode: "Play Mode",
    msgDoitFormeNotselect: "(when only tutorial guides are chosen, play mode \"doitforme\" should not be selected)",
    msgCannotEditGoal: "Cannot edit goal once published, Are you sure you want to publish this goal?",
    goalEmailNotification: "Goal email notifications are currently turned Off in your preferences. You may miss out on important Goals milestone notifications.",
    updatePreference: "To update your preferences at any time you can go to",
    goalNotificationPreference: "Goal Notification Preference",
    goalDetails: "Goal Details",
    startDate: "Start date",
    endDate: "End date",
    eventSources: "Event Sources",
    playModes: "Play Modes",
    targetPlayCount: "Target Play Count",
    oneEmailPublishedGuides: "One email per day, with details of all the step fails that occurred during the day for the published guides.",
    oneEmailonSundays: "One email per week, summarizing the usage of MyGuide across the organization. Emails will be sent out on Sundays (GMT).",
    receiveEmailOnMilestone: "This option allows you to receive email notifications whenever a milestone is achieved (50%, 80% and 100%). Considering the fact that you are already watching the goal.",
    receiveEmailOnAchievement: "This option allows you to receive emails whenever a goal is achieved. Considering the fact that you are already watching the goal.",
    emailNotificationPreference: "Email Notification Preference",
    detail: "Detail",
    moreDetails: "More details",
    lessDetails: "Less details",
    requestSentOn: "Request Sent on",
    requestCompletedOn: "Request Completed on",
    requestCompletedvia: "Request completed via",
    requestSourcedAt: "Request sourced at",
    requestGuideType: "Request guide type",
    appsSelectedForRequest: "Apps selected for request",
    dataNotAvailable: "Data Not Available.",
    exportViaEmail: "Export via Email",
    selectedDateRange: "Your selected date range",
    selectedApps: "Your selected apps",
    selectedDataSources: "Your selected data sources",
    noteAboutExclusion: "Note:Tooltip excludes Mobile Creator and Web Creator",
    selecetdEventSource: "Your selected event source",
    placeRequestLimitMsg: "You can only place request if you do not have any pending request in last 24 hrs.",
    textCommentsFormat: "It displays all the responses provided by users in the text(comments) format.",
    pieChartRepresentating: "A pie chart representing the percentage and counts of options answered by all the users.",
    tabularRepresentationOfResponse: "A tabular representation of response count and percentage of each option.",
    vennDiagramCombinationOption: "A Venn diagram showing a combination of options answered by users.",
    tableOfCombinationOption: "A tabular representation of all the combinations of options with their response counts and percentage.",
    barforRating: "Bar chart represents the rating provided by the users.",
    tableForCounts: "The table represents the counts and the percentage of the ratings.",
    barForRatings: "Bar chart represents the star rating provided by the users.",
    tableForRatings: "The table represents the counts and the percentage of the star ratings.",
    barChartForYesNo: "Bar chart represents \"yes\" and \"no\" responses.",
    tableofCountsPercentage: "The table represents the counts and the percentage.",
    timeLine: "Timeline",
    detailsNotAvailable: "Details are not available for this instance.",
    textType: "Text Type",
    selectSingleType: "Single Select Type",
    multiSelectType: "Multiple Select Type",
    rangeType: "Range Type",
    starRatingType: "Star Rating Type",
    yesnoType: "Yes/No Type",
    responses: "Responses",
    excelViewerQn: "Don't have an Excel viewer?",
    viewPlayDetails: "View Play Details",
    feedback: "Feedback",
    noResponseYet: "No Response Yet",
    responseStats: "Response Stats",
    answerChoices: "ANSWER CHOICES",
    responseCount: "RESPONSE COUNT",
    total: "TOTAL",
    max5AppsSelected: "Maximum 5 apps can be selected.",
    player: "player",
    webCreator: "Web Creator",
    webPlayer: "Web Player",
    desktopCreator: "Desktop Creator",
    mobileCreator: "Mobile Creator",
    mobilePlayer: "Mobile Player",
    dataTooltiptext7Days: "Data may take up to 7 days to reflect in the portal from the time of the actual event.",
    password1SpecialChar: "The Password field must contain atleast one special character.",
    password1Numeric: "The Password field must contain one numeric value.",
    passwordLowerCase: "The Password field must contain one lowercase character.",
    passwordUpperCase: "The Password field must contain one uppercase character.",
    passwordConfirmationField: "The Password Confirmation field must be equal to password field.",
    password8Char: "Confirm Password must be 8 characters long!",
    newPassword8Char: "New Password must be 8 characters long!",
    passwordChanged: "Password Changed Successfully!",
    curPassword: "Current Password",
    newPassword: "New Password",
    confirmPassword: "Confirm Password",
    admin: "Admin",
    changePassword: "Change Password",
    logout: "Logout",
    somethingWentWrong: "Something went wrong",
    showLabels: "Show Labels",
    max9Allowed: "Maximum 9 selections allowed.",
    showEntries: "Show Entries",
    activity: "Activity",
    guideMeActivity: "GuideMe Activity",
    showMeActivity: "ShowMe Activity",
    testMeActivity: "TestMe Activity",
    doItForMeActivity: "DoItForMe Activity",
    guideTutorialActivity: "Guide Tutorials Activity",
    activitiesOnGuidesonSelectApps: "Line Graph representing activities on guides on the selected apps",
    guideCounts: "Guide Counts:",
    guidesSelectedinTimeFrame: "Total no. of guides in selected mode within the given time frame",
    guidesInGuideModeinTimeFrame: "Total no. of guides in GuideMe mode within the given time frame",
    guidesInShowMeModeinTimeFrame: "Total no. of guides in ShowMe mode within the given time frame.",
    guidesInTestMeModeinTimeFrame: "Total no. of guides in TestMe mode within the given time frame.",
    guidesInSDoItForMeModeinTimeFrame: "Total no. of guides in DoItForMe mode within the given time frame.",
    tutorialGuidesinTimeFrame: "Total no. of Tutorial Guides played within the given time frame",
    guideSummary: "Guide Summary",
    top10Guides: "Top 10 Guides",
    tableTop10GuidesAcrossApps: "Table representing Top 10 Guides across Apps.",
    titleOfGuide: "Title of the Guide.",
    appTitle: "App Title",
    appToWhichGuideBelong: "App to which the Guide belongs.",
    labels: "Labels",
    labelsToGuide: "Labels applied to the Guide.",
    noOfResponsesForGuide: "Total number of responses recorded for the Guide.",
    guideDistribution: "Guide Distribution",
    distributionAnalyzedNegNeuPos: "Distribution of Guide responses analysed as Negative, Neutral and Positive.",
    lastUpdatedOn: "Last updated on",
    dateTimeofGuideModification: "Date and time on which the Guide was modified.",
    lineGraphonSelectedApps: "Line Graph representing activities on workflows on the selected apps",
    workflowSummary: "Workflow Summary",
    top10Workflows: "Top 10 Workflows",
    tableTop10WorkflowsAcrossApps: "Table representing Top 10 Workflows across Apps.",
    workflowTitle: "Workflow Title",
    titleWorkflow: "Title of the workflow.",
    typeWorkflow: "Type of workflow.",
    walkthroughCounts: "Walkthrough Counts",
    totalCountinWorkflow: "Total counts in a Workflow.",
    avgTimeonWorkflow: "Avg. time spent on workflow.",
    bestimeAchievedWorkflow: "Best time achieved on workflow.",
    lineGraphSurveysApps: "Line Graph representing activities on surveys on the selected apps",
    surveySummary: "Survey Summary",
    top10Survey: "Top 10 Surveys",
    tableTop10SurveysApps: "Table representing Top 10 Surveys across Apps.",
    nameSurvey: "Name of Survey.",
    appToWhichSurveyBelong: "App to which the survey belongs.",
    labelsToSurvey: "Labels applied to the Survey.",
    noOfResponsesForSurvey: "Total number of responses recorded for the Survey.",
    surveyDistribution: "Survey Distribution",
    distributionAnalyzedSurevyNegNeuPos: "Distribution of survey responses analyzed as Negative, Neutral and Positive.",
    dateTimeofSurveyModification: "Date and time on which the Survey was modified.",
    tableFilteredBasedOnApps: "This table is filtered based on the selected apps only.",
    locationInsights: "Location Insights",
    whereUsersAccessFrom: "Where are the Users accessing your App from?",
    pageStats: "Page Stats",
    pageAcivity: "Page Activity",
    blockDiagramTop50Pages: "A block diagram representing top 50 tag-enabled pages (in accordance with the number of hits against each page).",
    pageSummaryOverview: "Page Summary Overview",
    pageDetail: "Page Detail",
    collectionChartsTaggedPages: "A collection of charts representing activities across tagged pages.",
    pageName: "Page Name",
    noOfPages: "Name of page.",
    pageView: "Page Views",
    numberTimesPageViewed: "The number of times the page was viewed.",
    uniqueVisits: "Unique Visits",
    numberUniqueVisitorPage: "The number of unique visitors that viewed the page.",
    topPages: "Top Pages",
    topUntaggedVistis: "The top untagged pages visited by users.",
    pageDistribution: "Page Contribution",
    chartRepresentingPage: "A chart representing page activity contribution.",
    taggedPages: "Tagged Page",
    percentageContribution: "The percentage of pages contributing to 80 percent of views.",
    urlContibution: "URL Contribution",
    chartURLActivity: "A chart representing URL activity contribution.",
    untaggedURL: "Untagged URL",
    comparisonTaggedUntagged: "Percentage comparison of tagged and untagged URLs.",
    pageUserActivity: "Page User Activity",
    graphUserActiviyOnPage: "Graphs representing user activity on Page.",
    totalUserVisit: "Total User Visit",
    totalUsersOnPageinTime: "The total number of users who visited the page within the given time.",
    minSessionDuration: "Min. Session Duration",
    maxSessionDuration: "Max. Session Duration",
    avgSessionDuration: "Avg. Session Duration",
    minTimeonPageinTime: "Minimum amount of time spent on the page within the given time.",
    maxTimeonPageinTime: "Maximum amount of time spent on the page within the given time.",
    avgTimeonPageinTime: "Average amount of time spent on the page within the given time.",
    whenUsersVisit: "When do your users visit?",
    heatMapforMaxTraffic: "Heatmap representing the time of the day that has maximum traffic on the page",
    pageLoadTime: "Page Load Time",
    averageTime: "Average Time",
    workflowVisualSummary: "Workflow Visual Summary",
    workflowUsageStats: "Workflow Usage Stats",
    lineGraphWorkflowPlayed: "A Line graph represents the count of workflow played.",
    workflowPlayCount: "Workflow Play Count",
    workflowPlayedInTime: "Count of workflow played in a given time frame.",
    uniqueWorkflowInTime: "Unique count of workflow played in a given time frame.",
    tableWorkflowAvgBestTime: "Table representing the list of workflows along with their average time and best time.",
    titleOfWorkflow: "Title of workflow.",
    associatedLabelWorkflow: "Name of the label to which the workflow is associated.",
    totalUsersWalkthrough: "The total number of users who played the walkthrough.",
    avgTimeWorkflow: "Average time spent on the workflow.",
    bestTimeWorkflow: "Best time spent on the workflow.",
    topWorkflows: "Top workflows",
    top5WorkFLowByUsage: "Top 5 workflows by usage",
    barChartTop5Workflows: "A Bar graph representing the top 5 workflows in descending order of their play counts.",
    workflowUsageSummary: "Workflow Usage Summary",
    usageSummaryGraph: "Usage Summary Graph",
    lineChartWorkflowinTime: "A Line graph represents the count of workflow played by users in the given timeframe.",
    uniqueUserPlayCount: "Unique User Play Count",
    stepTitle: "Step Title",
    stepPlayedCount: "Step Played Count",
    numberTimesWorkflowPlayedbyAll: "Number of times the workflow was played by all users.",
    numberTimesWorkflowPlayedbyUnique: "Number of times the workflow was played by unique users.",
    usageHeatMap: "Usage Heat Map",
    heatmapOnWorkflow: "A heatmap that represents the time spent on workflow.",
    workflowUsageAnalysis: "Workflow Usage Analysis",
    scatterGraph: "Scatter Graph",
    scatterPlotforBestAvgWorst: "A scatter plot graph represents the best, average and worst time in the given timeframe.",
    bestTimeonWorkflow: "The best time spent on the workflow.",
    averageTimeonWorkflow: "The average time spent on the workflow.",
    worstTimeonWorkflow: "The worst time spent on the workflow.",
    stepsTimeUsageTable: "Steps Time Usage Table",
    tableUsageStepsinWorkflow: "A table show time usage of the steps in the selected workflow.",
    titleStepWorkflow: "The title of the step in the workflow.",
    numberStepsPlayed: "The number of times the step was played.",
    avgTimeSpentonStep: "The average time spent on the step.",
    bestTimeSpentonStep: "The best time spent on the step.",
    workflowUsageEngagement: "Workflow Usage Engagement",
    stepsFlowChart: "Steps Flow Chart",
    pathChartforWorkflow: "A path chart representing the flow of steps in the selected workflow.",
    stepDropOff: "Step Drop Off",
    funnelForworkflow: "Funnel representation of the selected steps from the current workflow. You can select a minimum of 3 steps and a maximum of 7 steps. The order of selection does not matter, as the funnel will be rendered according to the order of the steps in the workflow.",
    specificUserActivity: "Specific User Activity",
    lineProficiency: "A Line graph represents the proficiency graph of individual users. User Information Tracking should be enabled to view user proficiency data.",
    overallWorkflowUsage: "Overall Workflow Usage",
    workflowFootfallAnalysis: "Workflow footfall analysis",
    footfallGraph: "Footfall graph",
    graphWalkThroughEntireWorkflow: "A graph of the percentage of walk-throughs that each step gets against the total no. of walk-throughs of the entire workflow.",
    workflowPathAnalysis: "Workflow path analysis",
    pathChart: "Path Chart",
    graphStepsCombinationWorkflow: "A graph representing all of the combinations of step sequences followed by users for a particular workflow.",
    pathChartLegend: "Path Chart Legend",
    legendsStepsColorCode: "Legend to showcase the steps corresponding to their colour codes.",
    barGraphPosNegNeu: "A bar graph representing the survey response distribution as positive, negative and neutral.",
    tableActitiesofSurvey: "A table representing activities of Surveys.",
    titleSurveyCreated: "Title of Survey Created.",
    stats: "Stats",
    labelsofSurvey: "Labels of the survey.",
    numberResponsesEachSurvey: "Number of responses for each survey.",
    responseDistribution: "Response Distribution",
    graphPercentageSurevey: "Graph representing the percentage of survey responses.",
    countPositiveResponses: "Count of positive responses in the given time frame.",
    countNeutralResponses: "Count of Neutral responses in the given time frame.",
    countNegativeResponses: "Count of Negative responses in the given time frame.",
    pieChartPosNegNeu: "A pie chart showing count and percentage of positive, negative and neutral responses for this survey.",
    surveyQuestions: "Survey Questions",
    verifyAccount: "Verify Account",
    otpSent: "The OTP has been sent to your Email. Please enter the one time password to verify your account.",
    otp: "OTP",
    enterNumber: "Please enter numbers only",
    didntReceiveOTP: "Didn’t receive an OTP?",
    clicktoResendOTP: "click to resend OTP",
    backToLogin: "Back To Login",
    manageGoals: "Manage Goal",
    goalDeletedSuccessfully: "Goal Deleted Successfully.",
    manageSurvey: "Manage Survey",
    surevyStats: "Survey Stats",
    surveyStatsDetailed: "Survey Stats Detailed",
    graphActivitiesOnLiveGuide: "Graph representing activities on live guides.",
    totalNumberOfGuidesPlayed: "The total number of guides played in the given time frame.",
    totalNumberOfUniqueGuidesPlayed: "The total number of unique guides played (per unique user) in the given time frame.",
    totalNumberOfCompleteGuidesPlayed: "It's the total number of guides played from the very first step through the last step.",
    completionRatio: "Completion Ratio",
    slideshowPlayedInGivenTimeFrame: "The total number of slideshows played in the given time frame.",
    totalVideosPlayedInGivenTimeFrame: "Total number of Videos played in the given time frame.",
    totalGIFdPlayedInGivenTimeFrame: "Total number of GIFs played in the given time frame.",
    pieActivityPercentageDistribution: "A pie chart representing the percentage distribution of guides downloaded in various formats.",
    barGraphTestMe: "A bar graph representing the total number of users who performed TestMe activity where each bar is divided into 2 parts (number of users who passed in the test and the number of users who failed in the test).",
    guidePlayedVieDoItForMe: "Graph representing guides played via DoItForMe automation.",
    myGuideSurvey: "MyGuide Survey",
    totalNuberOfGuideAndExitResponses: "A graph representing the total number of Guide and Exit responses provided by users for the guides.",
    displayCount: "Display Count",
    guideName: "Guide Name",
    guidePlayed: "Guide Played",
    showMePlayed: "ShowMe Played",
    testMePlayed: "TestMe Played",
    totalGuidePlayed: "Total Played",
    myGuideSurveysPlayed: "MyGuide Surveys Played",
    tableRepresentingGuideActivites: "Table representing activities of Guides.",
    nameOfGuidesPlayed: "Name of guides played.",
    totalGuidesCompleteCount: "The total number of guides, unique guides played and the total number of guides played in the given time frame.",
    totalGuidesPlayCount: "The total number of guides played in GuideMe, DoItForMe and ShowMe mode.",
    totalSlideshowVideoGIFCount: "The total number of slideshows, videos, and GIFs played in the given time frame.",
    totalTestPassCount: "The total number of users who passed and failed the tests in the given time frame.",
    totalResponsesSubmitted: "Total number of responses submitted by users for the guide in the given time frame.",
    guideMePlay: "GuideMe Play",
    guidePlayedStats: "Guide Played Stats",
    avgGuidePlayed: "Average guide played in the given time frame per unique user.",
    guidePlayedOverview: "Guide Played Overview",
    radialChartLiveGuides: "A radial chart representing activities on live guides.",
    avgPlayed: "Average Played",
    guidePlaySources: "Guide Play Sources (Push-notifications, Beacons, Panel & URL)",
    pieChartGuideSources: "A pie chart representing the Guide Played Via Push-notifications, Beacons, Panel & URL.",
    audioGuidance: "Audio Guidance",
    audioConsumptionGuideMeShowMe: "Graph representing audio consumption on supported GuideMe/ShowMe modes.",
    guideMeDetails: "GuideMe Details",
    detailedSummary: "Detailed Summary",
    tableOfLiveGuideActivities: "Table representing activities on live guides steps.",
    startStep: "Started From This Step",
    endStep: "Ends On This Step",
    failedOnStep: "Failed On This Step",
    stepsFlow: "Steps Flow",
    titleOfStep: "Title of the step.",
    numberOfTimesStepPlayed: "How many times the step was played.",
    stepBeganFromCurrentStep: "How many times guide play began from the current step.",
    stepEndedOnCurrentStep: "How many times did guide play ended on the current step",
    stepErrors: "How many times has guide play interrupted on this step due to errors",
    sankeyPaths: "Sankey diagram representing the various paths taken by the users during guide play.",
    funnelWarningMsg: "Funnel representation of the selected steps from the current guide. You can select a minimum of 3 steps and a maximum of 7 steps. The order of selection does not matter, as the funnel will be rendered according to the order of the steps in the guide.",
    graphActivitiesOnShowMe: "Graph representing activities on ShowMe.",
    downloadDistribution: "A chart representing the distribution of guides downloaded in various formats.",
    showMePlaySources: "ShowMe Play Sources (Push-notifications, Beacons, Panel & URL)",
    pieChartShowMeModes: "A pie chart representing the ShowMe Played Via Push-notifications, Beacons, Panel & URL.",
    guidesPlayedViaDoItForMe: "Graph representing the no. of times this guide was played via DoItForMe automation.",
    doItForMeShowMeSources: "DoItforMe Play Sources (Push-notifications, Beacons, Panel & URL)",
    doItForMeTableActivities: "Table representing guide play activities in DoItForMe mode.",
    humanInterventionCount: "Human Intervention Count",
    humanInterventionCountTimes: "How many times a step required human intervention.",
    skippedStepCount: "Skipped Step Count",
    skippedStepCountTimes: "How many times a step was skipped",
    failedOnStepTimes: "How many times has guide play failed on this step due to errors.",
    myGuideSurveys: "MyGuide Surveys",
    guideExitStepSurveys: "Guide, Exit-Guide & Step Surveys",
    tutorialGuideStats: "Tutorial guides stats",
    lineChartTutorialGuide: "A line chart representing the stats of tutorial guides.",
    activitiesOnTutorialGraph: "Graph representing activities on tutorial guides.",
    tutorialGuideName: "Name of Tutorial Guide played.",
    totalNoOfTutorialGuidesPlayed: "Total No. of tutorial guides played",
    guideCompleted: "Guide Completed",
    totalNumberOfGuidesCompleted: "Total number of No. of tutorial guides completed.",
    tutorialGuideDetailSummary: "Tutorial Guide Detail Summary",
    tableOfGuidePlayActivities: "Table representing guide play activities.",
    tooltipGuidanceAndValidationGraph: "A line graph representing the stats of tooltip guidance and validation in the given time frame.",
    countOfTooltipHoverClicked: "No. of times the user interacted with the tooltip on a page either by hover, click or when the page was loaded.",
    countOfValidationPerformed: "No. of times the validation was performed on the page.",
    countOfValidationAndInteractionPerformed: "No. of times both tooltips were interacted with and validation was performed.",
    barChartPowerForm: "A stacked bar chart representing the status stats when power form was invoked in the given time frame.",
    countOfSubmittedPowerForm: "Count of all those users who submitted the power form.",
    countOfCancelledPowerForm: "Count of all those users who cancelled the power form.",
    tableOfPowerFormStats: "A table representing the status stats of power form in the given time frame.",
    titleOfPowerForm: "Title of a power form containing the step order and tooltip guide name.",
    countOfCancelledSpecificPowerForm: "Count of all those users who cancelled the specific power form.",
    countOfSubmittedSpecificPowerForm: "Count of all those users who submitted the specific power form.",
    tableOfStatsOfTooltip: "A table representing the stats of the tooltip in the given time frame",
    nameOfTooltipContainsMoreTooltips: "Name of the tooltip guide that contains one or more tooltips.",
    interactionHappenedWithGuidanceType: "Number of times user interaction happened with Guidance type tooltip inside a specific tooltip guide.",
    interactionHappenedWithValidationType: "Number of times user interaction happened with Validation type tooltip inside a specific tooltip guide.",
    interactionHappenedWithGuidanceValidationType: "Number of times user interaction happened with Guidance & Validation type tooltip inside a specific tooltip guide.",
    digitalDuctTapeRenderCount: "Number of times Digital Duct Tape was rendered and action was performed on it.",
    interactionHappenedWithPowerForm: "Number of times user interaction happened with Power Form.",
    tooltipValidationActivity: "Tooltip & Validation Activity",
    countOfTooltipClickedAndHovered: "No of times the tooltip was shown on a page either by hover, click or when the page was loaded.",
    countTooltipShownAndValidationPerformed: "No. of times both tooltips were shown and validation was performed.",
    ductTapeActivity: "Duct Tape Activity",
    statsOfDigitalDuctTape: "A graph representing the stats of digital duct tape in the given time frame.",
    countDigitalDuctTapeDisplayed: "No. of times the digital duct tape was displayed on the page.",
    powerFormActivity: "Power Form Activity",
    userActivityGraph: "Graph representing user activity on pages containing guides.",
    newUser: "New Users",
    totalUsers: "Total Users",
    engagedUsers: "Engaged Users",
    newUserCount: "The total number of new (having no sessions in the last 30 days) users visiting the pages containing guides.",
    numberOfUsersVisitingGuidePages: "The total number of users visiting the pages containing guides.",
    usersPerformedSomeActivity: "Users who performed some activity with MyGuide.",
    lineGraphUserEngagement: "Line Graph representing user engagements.",
    guideMeUniquePlayCount: "Guide Me Unique Play Count",
    guideMeTotalPlayCount: "Guide Me Total Play Count",
    guideMeCompletePlayCount: "Guide Me Complete Play Count",
    numberOfUsersPlayedInGuideMode: "Total no. of users who played guides in GuideMe mode within a given time frame.",
    numberOfUniqueUsersPlayedInGuideMode: "Unique no. of users who played guides in GuideMe mode within a given time frame.",
    numberOfTotalUsersPlayedInGuideMode: "Total no. of users who completed guides in GuideMe mode within a given time frame.",
    doItForMeUniquePlayCount: "DoItForMe Unique Play Count",
    doItForMeTotalPlayCount: "DoItForMe Total Play Count",
    doItForMeCompleteCount: "DoItForMe Complete Play Count",
    showMeTotalPlayCount: "Total no. of users who played guides in ShowMe mode within a given time frame.",
    showMeUniquePlayCount: "Unique no. of users who played guides in ShowMe mode within a given time frame.",
    testMeUserTestCount: "Total no. of users who performed tests in TestMe mode within a given time frame.",
    testMeUniqueUserTestCount: "Unique no. of users who performed the test in TestMe mode within a given time frame.",
    doItForMeUserPlayCount: "Total no. of users who played guides in DoItForMe mode within a given time frame.",
    doItForMeUniqueUserPlayCount: "Unique no. of users who played guides in DoItForMe mode within a given time frame.",
    doItForMeCompleteUserPlayCount: "Total no. of users who completed guides in DoItForMe mode within a given time frame.",
    tutorialGuidePlayCount: "Tutorial Guides Total Play Count",
    tutorialGuideUniquePlayCount: "Tutorial Guides Unique Play Count",
    tutorialGuideCompletePlayCount: "Tutorial Guides Complete Play Count",
    tutorialGuideUserPlayCount: "Total no. of users who played Tutorial Guides within a given time frame.",
    tutorialGuideUniqueUserPlayCount: "Unique no. of users who played Tutorial Guides within a given time frame.",
    tutorialGuideCompleteUserPlayCount: "Total no. of users who completed Tutorial Guides within a given time frame.",
    userEngagementSummaryOverview: "User Engagement Summary Overview",
    userEngagementSummary: "User Engagement Summary",
    tableOfUserEngagements: "Table representing the list of user engagements.",
    email: "E-Mail",
    emailIdOfUser: "Email ID of the user who has performed any activity.",
    nameOfUser: "Name of the user who has performed any activity.",
    guidePlayedByUser: "Total No. of guides played by the user in the given timeframe.",
    guidesPlayedByShowMeMod: "Total No. of guides played by the user in ShowMe mode within the given timeframe.",
    guidesPlayedByTestMeMod: "Total No. of guides played by the user in TestMe mode within the given timeframe.",
    userEngagementDetailSummary: "User Engagement Detail Summary Overview",
    userEngagementDetailSummaryTable: "Table representing activities of Guides for the user.",
    userEngagementDetailGuideName: "Name of the guides played.",
    userEngagementDetailTotalGuidePlay: "The total number of guides and the total number of guides played in the given time frame for the user.",
    userEngagementDetailShowMePlay: "The total number of slideshows, videos, and GIFs played in the given time frame for the user.",
    userEngagementDetailTestMePlay: "The total number of users who passed and failed the tests in the given time frame for the user.",
    userGuideGraph: "User Guides Graphs",
    graphOfLiveGuideActivities: "Graph representing activities on live guides for the user.",
    playsByMedia: "Plays by Media Type",
    donutChartShowMeDistribution: "Donut chart representing percentage distribution of activities on ShowMe.",
    playsByTime: "Plays by Time",
    lineChartOfTestMeActivities: "A line chart representing the total number of counts of the times the user passed or failed in the TestMe Activity.",
    graphOfDoItForMeMode: "Graph representing activities on live guides for the user played in DoItForMe mode.",
    totalGuidesPlayedInDoItForMeMode: "The total number of guides played in the given time frame in DoItForMe mode.",
    totalGuidesPlayedFromFirstStep: "It's the total number of guides played from the very first step through the last step in DoItForMe mode.",
    userEngagementTimeline: "User Engagement Timeline",
    userEngagementPlayDetails: "Play Details",
    tutorialGuideActivity: "Tutorial Guides Activity",
    userActivitiesTimelineCharts: "Timeline chart represents the series of activities performed by a specific user within a given timeframe. Also shows the mode in which the guide activity was performed.",
    listOfSteps: "It shows the detailed list of steps played by a user for a specific guide within a given timeframe.",
    userActivitiesOnTutorialGuide: "Graph representing activities on Tutorial Guides for the user.",
    totalNumberOfGuideInTutorialGuide: "The total number of Tutorial Guides played in the given time frame.",
    totalNumberOfGuideInTutorialGuideFromFirstStep: "It's the total number of Tutorial Guides played from the very first step through the last step.",
    searchOverview: "Searches Overview",
    searchKeywords: "Search Keywords",
    keyword: "Keyword",
    numberOfSearches: "Number of Searches",
    avgResultRetured: "Avg. Result Returned",
    clicks: "Clicks",
    searchSummary: "Search Summary",
    tableOfSearchSummary: "Table representing top 50 keywords (in accordance with the number of hits against each keyword) in the given time frame. You are free to search for the desired keyword using the search feature if you believe it did not qualify.",
    searchKeystrokes: "Search keystrokes as searched by various users.",
    keystrokesHitByUser: "Number of times each keystroke was hit by various users",
    avgKeystrokes: "The average number of results (minimum) returned, over the period against the number of searched performed for each keystroke.",
    totalClickSearchResult: "It represents how many times a click was made on any particular item in the search result",
    wordClouseTooltipText: "Word cloud diagram representing search volume against the top 50 keywords (in accordance with the number of hits against each keyword) in the given time frame.",
    ductTapeTable: "A table representing the stats of digital duct tape in the given time frame.",
    guideAutomationPlay: "Guide Automation Play",
    testCasesExecutedGraph: "A line graph showing no. of times test cases were executed in the given time frame.",
    numberOfTimesTestCasesAutoExecuted: "No. of times test cases were executed automatically as per their scheduled time in the given time frame.",
    numberOfTimesTestCasesManuallyExecuted: "No. of times test cases were executed manually by a user in the given time frame.",
    guideAutomationSummary: "Guide Automation Summary",
    testCasesTable: "A table representing the individual test cases status and stats.",
    playTime: "Play Time",
    nameOfTestCase: "Name of the test case.",
    testCaseTriggeredTime: "Time when the test case was triggered.",
    automaticallTriggered: "Denotes whether the test case was triggered automatically as per schedule or manually by the user.",
    testCaseModes: "It represents whether the test case triggered, stopped due to any reason, or completed successfully.",
    guideAutomationDetails: "Guide Automation Details",
    details: "Details",
    testCaseStatus: "A detailed and nested view of all the test cases along with their status.",
    testCaseTitle: "Title of a guide on which the test case was executed.",
    testCaseResult: "Test case passed or failed on a specific guide.",
    testCaseInfo: "Information supporting the status of test case that was executed on a specific guide.",
    testCaseGuideInfo: "Text describing some additional information about the execution status of the guide.",
    goalDetailOverview: "Goal Details Overview",
    goalPlayed: "Goal Played",
    doItForMePlayed: "DoItForMe Played",
    goalActivitiesChart: "LineChart representing activities on set goals.",
    totalGuidesAndGuidesPlayedCount: "The total number of guides and the total number of guides played in the given time frame.",
    totalTooltipsRenderCount: "Total number of tooltips rendered in the given time frame.",
    goalProgress: "Goal Progress",
    userGuidesSummary: "User Guides Summary",
    goalCompletion: "Goal Completion",
    goalCompletionOverhead: "Goal Completion Overhead",
    goalProgressBarChart: "A bar chart representing the progress made on the goal",
    targetGoalPercentage: "The percentage of set target goals achieved by the user.",
    goalsExceedingTargets: "The percentage of goals exceeding the set target goals achieved by the user.",
    guideModification: "Guides Modification",
    guideMeShowMeComparison: "A line chart comparing GuideMe and ShowMe plays in the given time frame.",
    guidesPlayedInGuideMeMode: "The total number of guides played in GuideMe mode in the given time frame.",
    guidesPlayedInShowMeMode: "The total number of guides played in ShowMe mode in the given time frame.",
    userInteractedWithPNBTMode: "A graph representing the total number of times users interacted with Push-notifications, Beacons & Tooltips on page visit.",
    totalModifiedGuides: "A bar graph representing the total number of guides that were modified in the given time frame.",
    serviceNotAvailable: "Service Not Available",
    conflictingParameters: "Conflicting parameters in request",
    insightsLogin: "Insights Login",
    emailId: "Email Id",
    emailPlaceholder: "name@domain.com",
    password: "Password",
    remember: "Remember",
    pleaseContact: "Please contact",
    accountSuspended: "Your account has been suspended.",
    forgotPassword: "Forgot password?",
    login: "Login",
    copyright: "Copyright ©",
    sessionExpiredMsg: "Your session has expired.",
    reLogin: "Please Re-Login.",
    sessionExpired: "Session Expired",
    reaTimeDateCondition: "Real-time data is included in all supported data sections. Please note that date selection will only be allowed till a day prior to current date in the date picker.",
    goToHome: "Go to Home",
    pageNotFound: "Oops.., page not found!",
    brandName: "MyGuide",
    buildversion: "Version",
    supportLinkText: "Need any help, please mail us at",
    mailto: "mailto",
    addChartinCustomDash: "No Charts Added Yet. Please Add Charts to the Custom Dashboard.",
    commentQnAnySuggestion: "Do you have any suggestions?",
    commentQnImprove: "What can we improve?",
    commentQnDescribe: "Describe product in few words.",
    multiselectFeaturesLiked: "What are features you liked the most?",
    multiselectFeaturesUsed: "Which of these features you already used?",
    multiselectPlatform: "On which platform you use the site?",
    rangeSelect: "Considering a range of 1 to 10, where do you rate us?",
    rangeProvide: "What would you rate us?",
    rateUs: "Rate us",
    satisfiedRate: "How satisfied are you with us?",
    singleselectLikeGuide: "Did you like the guide?",
    singleselectSuggestGuide: "Will you suggest the guide to your friends?",
    singleselectAccomplishGuide: "Did guide help you accomplish your goal?",
    yesNoLikeGuide: "Did you like the guide?",
    yesNoSuggest: "Will you suggest the guide to your friends?",
    yesNoAccomplish: "Did guide help you accomplish your goal?",
    downloadingOption: "Downloading",
    desktop: "Desktop",
    mobile: "Mobile",
    tablet: "Tablet",
    mayBe: "May be",
    minute: "minute",
    minutes: "minutes",
    second: "seccond",
    responsesTitle: "RESPONSES",
    dashboard: "Dashboard",
    manage: "Manage",
    selectType: "Select Type",
    linkedPage: "Linked Page",
    linkPage: "Link Page",
    branching: "Branching",
    addOptionLink: "Add Option Link",
    iconToCreateNewSurvey: "icon to create New Survey",
    clickToEditSurvey: "Click on the survey from the list to edit that particular survey",
    lastUpdated: "Last Updated",
    global: "Global",
    questions: "Questions",
    turnOnGoalNotif: 'Turn ON to receive emails for each milestone reached for a goal.',
    turnOffGoalNotif: 'Turn OFF to stop receiving emails for each milestone reached for a goal.',
    turnOnEmailNotif: 'Turn ON to receive emails when goal is achieved.',
    turnOffEmailNotif: 'Turn OFF to stop receiving emails when goal is achieved.',
    error: 'Error!',
    goalAlreadyExists: "Goal already exists. Please select a different title.",
    successfullyCreated: "Successfully Created",
    enterText: "Enter text",
    searchGoal: "Search Goal",
    enterQuestion: "Enter Your Question",
    addLink: "Add Link",
    searchSurvey: "Search Survey",
    nameTagOnPage: "Name the Tag on Page",
    describeTagOnPage: "Describe Tag on Page",
    selectUser: "Select User",
    pinToDashboard: "Pin to Dashboard",
    deleteDashboard: "Delete dashboard",
    publishGoal: "Publish Goal",
    deleteChart: "Delete Chart",
    analyzerQuestion: "Analyzer Question",
    branchingQuestion: "Branching Question",
    allDashboardDataDeletedMsg: "All Dashboard data will get deleted.",
    dashboardDeleteConfirmMsg: "Do you want to delete this dashboard?",
    exportDashboardData: "Export Dashboard Data",
    deleteGoal: "Delete Goal",
    viewPendingJobs: "View Pending Jobs",
    deleteSurvey: "Delete Survey",
    deleteSurveyConfirmMsg: "Are you sure you want to delete this survey?",
    shareSurvey: "Share Surveys",
    best: "Best",
    worst: "Worst",
    average: "Average",
    selectRange: "Select Range",
    searchSegment: "Search Segment",
    searches: "Searches",
    org: "ORGANIZATION",
    downloads: "Downloads",
    selectApps: "Select Apps",
    weeklyUsageStats: "Weekly Usage Stats",
    dailyFailureReport: "Daily Steps Failure Report",
    allApps: "All Apps",
    chooseDahsboard: "Choose Dashboard",
    userEngagementDetail: "User Engagement Detail",
    organizationDetail: "Organization Detail",
    organizationView: "Organization View",
    guideSurveyQuestionsAndResponses: "Guide Survey Questions & Responses",
    exitGuideSurveyQuestionsAndResponses: "Exit-Guide Survey Questions & Response",
    guideStepSurveyQuestionsAndResponses: "Guide Step Survey Questions & Responses",
    pageDetails: "Page Details",
    controlCenter: "Control Center",
    slideshows: "Slideshow(s)",
    videos: "Video(s)",
    docx: "DOCX",
    html: "HTML",
    gifs: "GIF(s)",
    guideDetail: "Guide Analytics Detail",
    tutorialGuideDetail: "Tutorial Guide Detail",
    docTitleGuideDetail: "Guide Insights - Guide Details",
    docTitleTutorialGuideDetail: "Guide Insights - Tutorial Guides Details",
    docTitleTooltipDetails: "Guide Insights - Tooltip Details",
    docTitleUserEngagementDetail: "Guide Insights - User Engagement Details",
    docTitleGuideAutomationDetail: "Automation Insights - Guide Automation Details",
    tooltipDetails: "Tooltip Details",
    userEngagement: "User Engagement",
    search: "Search",
    guidanceAndValidation: "Guidance & Validation",
    pusNotificationAndBeacons: "Push-notifications & Beacons",
    guideMeAndShowMe: "GuideMe & ShowMe",
    of: "of",
    tooltipAndValidation: "Tooltip & Validation",
    userActivity: "User Activity",
    eventType: "Event Type",
    guideDownloads: "Guide Downloads",
    time: "Time",
    combineWorkflowSteps: "Combined Workflows Steps",
    share: "Share",
    generating: "Generating...",
    never: "Never",
    custom: "Custom",
    surveyModalErrorMsg: "Max 250 characters are allowed",
    scheduled: "Scheduled",
    manual: "Manual",
    interrupted: "Interrupted",
    editGoal: "Edit Goal",
    stopWatching: "Stop Watching",
    startWatching: "Start Watching",
    invalidDate: "Invalid Date",
    invalidStartAndEndDate: "Invalid Start Date and End Date",
    goalNotificationEmail: "Goal Notification Email",
    goalCompletionPercent: "Goal Completion %",
    goalCompletionOverheadPercent: "Goal completion overhead %",
    actualPlays: "Actual Plays",
    targetPlays: "Target Plays",
    dataSource: "Data Source",
    selectIcon: "Select Icon",
    guideSteps: "Guide Steps",
    createFilter: "Create Filter",
    filterList: "Filter List",
    orgList: "Organization List",
    allOrganizations: "All Organizations",
    views: "Views",
    docTitleUserEngagement: "Guide Insights - User Engagement",
    docTitleSearches: "Guide Insights - Searches",
    orLater: "or later",
    pageNumber: "Page",
    live: "Live",
    achieved: "Achieved",
    ended: "Ended",
    achievedAndLive: "Achieved and Live",
    saved: "Saved",
    down: "Down",
    up: "Up",
    urlPath: 'URL Path',
    urlParameters: 'URL Parameters',
    urlHash: 'URL Hash',
    errorInvalidURL: "Error: Invalid URL",
    publish: "Publish",
    btnYes: "Yes",
    btnNo: "No",
    select: "Select",
    export: "Export",
    apply: "Apply",
    all: "All",
    slideShow: "Slideshow",
    video: "Video",
    seconds: "seconds",
    refresh: "Refresh",
    hour: "hour",
    hours: "hours",
    inputPlaceHolderSearch: "Search Guide",
    noRecordFound: "No Data Available",
    avgRating: "Average Rating",
    ratings: "Ratings",
    ranges: "Ranges",
    option: "Option",

    tag: "Tag",
    role: "ROLE",
    performedSearches: "Performed Searches",
    searchesResultedIntoAction: "Searches Resulted In Action",
    exportStatus: "Export Status",
    myGuideGuidesPlayed: "MyGuide Guides Played",
    myGuideTutorialGuidesPlayed: "MyGuide Tutorial Guides Played",
    totalNumberOfTimesGuidePlayedInDoItForMe: "The total number of times guide played in DoItForMe mode.",

    chatbotConversation: 'ChatBot Conversation',
    searchChatbot: 'Search Conversation',
    iconToCreateNewChatbot: 'icon to create New Conversation',
    clickToEditChatbot: 'Click on the conversation from the list to edit that particular conversation',
    chatBotTitle: 'Conversation Title',
    mandatoryForChatBot: "Mandatory for Conversation",
    validateEmail: "Validate Email",
    manageChatBot: "Manage ChatBot",
    geoLocationChartCountryWise1: "A geo-location chart representing usage of Apps country wise across the globe. (calculated based on user's system timezone)",
    guidesVsCompletion1: "The percentage of guides played all the way through to completion vs played but not completed.",
    totalNumbersUsersInteractedWithPNB1: "A graphs representing GuideMe, ShowMe and DoItForMe play initiation done using Push-notification or Beacons.",
    guideWithAudio1: "Total number of time a guide was played with step with audio (max. number of steps played with audio)",
    guideWithoutAudio1: "Total number of time a guide was played with step without audio (max. number of steps played without audio)",
    done: "Done",
    discard: "Discard",
    enableExitSurvey: "Enable Exit Survey",
    selectExitSurvey: "Select Exit Survey",
    labelsFilterTaggedPages: "Labels are used to filter tagged pages.",
    numberUniqueUsersonPages: "The number of unique users visited the page within the given time.",
    exitSurveyWarningMsg: "This action will be applied across all the apps.",
    orgAdmin: 'Org Admin',
    deleteChatBot: "Delete ChatBot",
    deleteChatBotConfirmMsg: "Are you sure you want to delete this ChatBot?",
    chatBotValidateEmailMsg: "(Only valid email addresses will be accepted in the response to this question. Please frame your questions accordingly.)",

    // API Values
    nameOfTheApp: "Name of the App",
    guideMePlays: "GuideMe Plays",
    guidePlayedInGuideMeMode: "No. of time guide played in GuideMe Mode",
    showMePlays: "ShowMe Plays",
    guidePlayedInShowMeMode: "No. of time guide played in ShowMe Mode",
    testMePlays: "TestMe Plays",
    gupidePlayedInTestMeMode: "No. of time guide played in TestMe Mode",
    doItForMePlayes: "DoItForMe Plays",
    guidePlayedInDoItForMeMode: "No. of time guide played in DoItForMe Mode",
    tutorialPlays: "Tutorial Plays",
    guidePlayedInTutorialMode: "No. of time guide played in Tutorial Mode",
    nameOfWorkflow: "Name of the Workflow",
    workflowCounts: "Walkthrough Counts",
    counts: "counts",
    avgTimeSpentOnWorkflow: "Avg. time spent on workflow",
    minTimeSpentOnWorkflow: "Min. time spent on workflow",
    numberOfGuidesPlayed: "No. of guides played",

    numberOfShowMePlayed: "No. of ShowMe played",
    numberOfTestMePlayed: "No. of TestMe played",
    numberOfSurveyResponses: "No. of Survey Responses",
    nameOfSTep: "Name of Step",
    stepPlayeCount: "Step Played Count",
    numberOfTimeStepWasPlayed: "No. of time step was played",
    stepStartedFrom: "Started From This Step",
    numberOfTimesSTepStartedFrom: "No. of times play started from this step",
    stepEndedOn: "Ends On This Step",
    numberOfTimeStepEndedOn: "No. of times play ended on this step",
    failedOnSTep: "Failed On This Step",
    numberIfTimesErroreOnStep: "No. of time play errored on this step",
    humaneInterventionCount: "Human Intervention Count",
    numberOfTimesHUmanInterventionPerformed: "No. of times human intervention was performed",
    numberOfTimesStepWasSkiepped: "No. of times step was skipped",
    tutorialGuideTitle: "Tutorial Guide Title",
    nameOfTutorialGuide: "Name of Tutorial Guide",
    tutorialGuidePlayed: "Tutorial Guide Played",
    numberOfTutorialGuidesPlayed: "No. of tutorial guides played",
    tutorialGuideCompleted: "Tutorial Guide Completed",
    numberOfTutorialGuidesCompleted: "No. of tutorial guides completed",
    nameOfPowerForm: "Name of Power Form",
    numberOfTimePowerFormCancelled: "No. of times Power Form Cancelled",
    numberOfTimesPowerFormSubmitted: "No. of times Power Form Submitted",
    nameOfGuide: "Name of Guide",
    numberOfGuidanceCounts: "No. of guidance counts",
    numberOfValidationCounts: "No. of validation counts",
    numberOfGuidanceAndValidationCounts: "No. of guidance & validation counts",
    numberOfDigitalDuctTapeCount: "No. of digital duct tape counts",
    numberOfPowerFormsCount: "No. of power Form counts",
    guideMePlayCount: "GuideMe Play Count",
    showMePlayCount: "ShowMe Play Count",
    testMePlayCount: "TestMe Play Count",
    doItForMePlayCount: "DoItForMe Play Count",
    tutorialPlayCount: "Tutorial Play Count",
    uniqueKeywordSearched: "Unique Keyword Searched",
    searchCountTitle: "Search Count",
    avgResultReaturned: "Avg. Result Returned",
    avgNumberOfResultsShown: "Average No. of results shown",
    numberOfTimeSearchResultedIntoAction: "No. of time search resulted into action",
    nameOfPageTag: "Name of page tag",
    pageViews: "Page Views",
    numberOfPageViews: "No. of page views",
    numberOfUniqueVisitors: "No. of unique visitors",
    totalUniqueUsers: "Total Unique Users",
    newUsers: "New Users",
    numberOfSessions: "No. of Sessions",
    bouncedUsers: "Bounced Users",
    nameOfStep: "Name of the Step",
    stepPlayedCOunts: "Step played Counts",
    abgTimeSpentOnStep: "Avg. time spent on step",
    minTimeSpentOnStep: "Min. time spent on step",
    nameOfSurvey: "Name of Survey",
    numberOfResponses: "No. of responses",
    userSurveyAnalysis: "User Survey analysis",
    lastUpdateTime: "Last update time",
    receiveGoalEmailNotification: "Receive Goal Email Notification",
    receiveGoalMilestoneNotification: "Receive Goal Milestone Notification",
    other: "other",
    tooltipPlays: "Tooltip Plays",
    watcherUpdatedSuccessfully: "Watcher updated successfully!",



    partnerAdmin: "Partner Admin",
    totalCountSummary: "Total Count Summary",
    PNBandTooltips: "Push-notifications, Beacons & Tooltips",
    buttonText: "Login",
    environmentFilterTitle: 'Environment',
    segmentFilterTitle: 'Segment',
    eventSourceFilterTitle: 'Event Source',
    guideStatusFilterTitle: 'Guide Status',
    tooltipFilterTitle: 'Tooltip List',
    applications: "Applications",
    none: 'None',
    yes: 'Yes',
    no: 'No',
    totalGuidesPlayedTitle: 'Sum of: Guide Played + ShowMe + DoItForMe + Tutorial Plays',
    tooltipActivity: "Tooltip Activity",
    pushnotificationActivity: "Push-Notification Activity",
    totalGuidePlayedInDoItForMeMode: "No. of guides played in DoItForMe Mode",
    creatorExtensionRequired: "For this feature to work, latest version of MyGuide Creator extension must be installed as extension in the browser",

    // new values
    guidesInTooltipModeinTimeFrame: "Total no. of Tooltip shown within the given time frame.",
    guidesInPNModeinTimeFrame: "Total no. of guides played using Push-Notifications within the given time frame.",
    guidesInBeaconModeinTimeFrame: "Total no. of guides played using Beacons within the given time frame.",
    guidesInSurveyModeinTimeFrame: "Total no. of survey responses in given time frame.",
    conversationStats: "Conversation Stats",
    chatBot: "ChatBot",
    conversationsResponse: "Conversation Response",
    chatBotDetail: "Conversation Details",
    chatBotResponse: "Conversation Responses",
    remarks: "Remarks",
    noDataFound: "No Data found",
    noRemarkFound: "No Remark found",
    viewQuestions: "View Questions",
    viewRemarks: "View Remarks",
    remarksPlaceholder: "Add a Remark...",
    remarkPostSuccessful: "Remark posted successfully",
    remarkPostFailed: "Remark post failed. Please try again",
    conversation: "Conversation",
    conversationSummary: "Conversation Summary",
    tableActitiesofConversation: "A table representing activities of Conversation",
    conversationName: "Conversation Name",
    nameConversationCreated: "Name of Conversation Created.",
    labelsofConversation: "Labels of the Conversation.",
    numberResponsesEachConversation: "Number of responses for each Conversation.",
    dateTimeofConversationModification: "Date and time on which the Conversation was modified.",
    lineChartConversationStatTooltip: "A line chart representing the conversation response distribution",

    //featuredetail
    featureInteractions: "Feature Interactions",
    featureDetail: "Feature Detail",
    heatmapTooltipFeatureDetail: "Heatmap representing the time of the day that has maximum feature interactions",
    lineChartTooltipFeatureDetail: "A line chart representing number of feature interactions over time",
    featureSummary: "Feature Summary",
    tableActivitiesOfFeature: "A table representing activities of Features.",
    featureSetName: "Feature Set Name",
    nameOfFeatureSet: "Name of Feature Set",
    interactions: "Interactions",
    numOfInteractions: "Number of interaction for each Feature Set",
    dateTimeOfFeatureModification: "Date and time on which the Feature was modified.",

    rawURLActivities: 'Raw URL activities',
    pageTagInfo: 'NOTE: Changes expected as a result of creation/deletion of tags will take some time to take effect.',


    createdBy: "Created By",
    createdByText: "Name of the user who created the tag.",
    lastUpdatedTimespan: "The last updated timespan of the tag.",
    blockDiagramTop50Pages1: "A block diagram representing top 50 raw page events (in accordance with the number of hits against each page).",
    webBrowserTooltip: "Section containing usage breakdown based on browser (in accordance with the number of hits against each page).",
    osTooltip: "Section containing usage breakdown based on operating system (in accordance with the number of hits against each page).",
    screenResolutionTooltip: "Section containing usage breakdown based on screen resolution (in accordance with the number of hits against each page).",
    completeURL: 'Complete URL',
    completeURLText: "Complete URL of the page of which activity has been recorded.",
    URLVisits: 'URL Visits',
    URLVisitsText: "No. of visits on a particular URL",
    uniqueUserVisits: 'Unique URL Visits',
    uniqueUserVisitsText: "No. of unique (unique users) visits on a particular URL.",
    associatedPageTags: 'Associated Page Tags',
    associatedPageTagsText: "No. of page tags this URL is associated to.",


    totalUniqueUsersGraph: "Graph representing unique user stats for given timespan",
    noOfSessionsGraph: "Graph representing no. of sessions (continuous activity with time gaps not more than 30mins) for given timespan",
    avgSessionDurationGraph: "Graph representing avg. session (continuous activity with time gaps not more than 30mins) duration for given timespan",
    pageViewsGraph: "Graph representing total page views for given timespan",


    // 2023.11
    featureList: "Feature List",
    userName: "User Name",
    userNameTooltip: "Name of User",
    userEmail: "User Email",
    userEmailTooltip: "Email Address of User",
    numOfInteractionsforUser: "Number of interaction for each User",
    lineChartTooltipUserEngagement: "A line chart representing number of feature interactions & feature users over time",

    //2024.1
    appEnvSelect: "Select Application & Environment",
    totalCount: "Total Count",
    selectedAppsTitle: "Selected Apps",


    // 2024.2
    selectDateRangeWithin180Days: "Select date range within 180 days!",
    pageVisits: "Page Visits",
    pageUsers: "Page Users",
    lineChartTooltipUserEngagementPage: "A line chart representing number of page visits and page users over time",
    lineChartTooltipUserEngagementPageModal: "A line chart representing number of page visits over time",
    numOfPageVisitsforUser: "Number of Page Visits for each User",
    ytd: 'YTD',
    userActivityGraphDashboard: "Line graph representing total user foot-fall across  selected set of application.",
    totalUserCount: "Total User Count",
    bestTimePageLoad1: "The optimal taken to load the page.",
    averageTimePageLoad1: "The average time taken to load the page.",
    worstTimePageLoad1: "The maximum time taken to load the page.",
    timeOfPageLoading1: "Time taken to load the page.",


    tooltipSummary: "Tooltip Summary",
    ytdCalendarYear: " YTD (Calendar Year)",
    top10Tooltips: "Top 10 Tooltips",
    tableTop10TooltipsAcrossApps: "Table representing Top 10 Tooltips across Apps.",
    totalPlaysLabel: "Total Plays",
    guidanceLabel: "Guidance",
    validationLabel: "Validation",
    digitalDuctTapeLabel: "Digital Duct Tape",
    powerFormLabel: "Power From",
    totalPlays: "Total no. of tooltip interacted",
    guidanceTooltip: "No. of times user interaction happened in Guidance Mode",
    validationTooltip: "No. of times user interaction happened in Validation Mode",
    guidanceAndValidationTooltip: "No. of times user interaction happened in Guidance & Validation Mode",
    digitalDuctTapeTooltip: "No. of times user interaction happened in Digital Duct Tape Mode",
    powerFormTooltip: "No. of times user interaction happened in Power Form Mode",
    applicationAndPlayType: "Application and Play Type",
    selectApplication: "Select Application:",
    selectEventSource: "Select Event Source:",
    selectPlayType: "Select Play Type:",
    appSelectionNote: "Note: For granular selection please select only one application",

    chartFilteredBasedOnApps: "This chart is filtered based on the selected apps only.",
    pushNotificationInteractions: "Push Notifications Interaction",
    pushNotificationDisplayed: "Push Notifications Displayed",
    beaconsInteractions: "Beacons Interaction",
    beaconsDisplayed: "Beacons Displayed",
    guidesPlayedByPushAndBeacons: "Guides Played by Push & Beacon",
    emailCCPlaceholder: "Type Email Id and press enter!",
    emailCC: "Email CC",
    invalidEmailId: "Invalid Email Id",
    duplicateEmailId: "Duplicate Email Id",
    selectedPlayTypes: "Your selected play types",
    selectedTooltip: "Select Tooltip",
    newTemplate: "New Template",
    templateTitle: "Template Title",
    newSchedule: "New Schedule",
    scheduleTitle: "Schedule Title",
    useTemplate: "Use Template",
    saveSchedule: "Save Schedule",
    updateSchedule: "Update Schedule",
    deleteTemplate: "Delete Template",
    deleteSchedule: "Delete Schedule",
    deleteTemplateConfirmationMsg: "Do you want to delete this template?",
    deleteScheduleConfirmationMsg: "Do you want to delete this schedule?",
    saveTemplate: "Save Template",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    triggerTime: "Trigger Time",
    activate: "Activate",
    deactivate: "Deactivate",
    activateScheduleMsg: "Do you want to activate this schedule?",
    deactivateScheduleMsg: "Do you want to deactivate this schedule?",
    templateTitleErrorMsg: "Please add template title",
    scheduleTitleErrorMsg: "Please add schedule title",
    scheduleFutureDateErrorMsg: "Please select future date for the schedule",
    scheduleDaySelectionErrorMsg: "Please select days for which scheduler will run",
    scheduleMaxDaySelectionErrorMsg: "Maximum of 7 days can be selected",
    scheduleMaxActiveErrorMsg: "Maximum 3 schedules can be active at the same time.",
    activeScheduleNonEditableInfoMsg: "Active schedule can't be edited",
    selectTime: "Select Time",
    oneTime: "One Time",
    repetitive: "Repetitive",
    tutorial: "Tutorial",
    updatedSuccessfully: "Updated Successfully!!",
    pushNotificationInteractedKey: 'PushNotification Interacted',
    pushNotificationDisplayedKey: 'PushNotification Displayed',
    beaconInteractedKey: 'Beacon Interacted',
    pushNotificationInteractedValue: 'Number of instances where user interacted with Push Notification.',
    pushNotificationDisplayedValue: 'Number of times push notification were displayed on user screen (please note that displays are captured once for each user & push combination per day).',
    beaconInteractedValue: 'Number of instances where user interacted with beacon.',
    beaconDisplayedValue: 'Number of times beacons were displayed on user screen (please note that displays are captured once for each user & beacon combination per day).',
    emailCCWarningMessage: 'Only a maximum of 10 email addresses are allowed',

    help: 'Help',
    updateTemplate: "Update Template",
    beaconDisplayedKey1: 'Beacon Displayed',
    taskList: 'Task List',
    taskListActivity: "Task List Activity",
    taskListSummary: "Task List Summary",
    tasksTotalPlayed: "Total no. of task list engaged within a given time frame.",
    tasksCompleted: "Total no. of task list completed within a given time frame.",
    taskListNameLabel: 'Task List Name',
    taskListEngagementsLabel: 'Task List Engaged',
    taskListCompletedLabel: 'Task List Completed',
    taskListLastUpdatedLabel: 'Last Updated',
    taskListName: 'Name of task list',
    taskListEngagements: 'Total count of task list engagements',
    taskListCompleted: 'Total number of task list completed',
    taskListLastUpdated: 'Date and time on which the task list was modified',
    taskListDetail: 'Task List Detail',
    taskListGuidePlay: 'Guide Play',
    completionStatus: 'Completion Status',
    completionTime: 'Completion Time',
    totalTaskListPlayedMsgTooltip: 'No. of time task list is played',
    completedTaskListTooptip: 'No. of time task list is completed',
    guidePlays: 'Guide Plays',
    guidePlayTaskListTooltip: 'No of guide plays via task list',
    taskListInteracted: 'Task List Interacted',
    taskListInteractedTooltip: 'User have played at least one guide in the task list',
    taskListCompletedTooltip: 'User have completed all guides in the task list',
    viewGuides: 'View Guides',
    min7DaysSelection: "The minimum time frame selection for User Engagement Section is 7 days.",
    visits: "Visits",
    desktopPlayer: "Desktop Player",
    guideErrors: 'Guide Errors',
    guideErrorsDetails: 'Guide Errors Details',
    guideStepErrors: 'Guide Step Errors',
    guideStepErrorDetails: 'Guide step error details',
    guideStepErrorsActivity: 'Guide step errors activity',
    guideErrorOccurrences: 'Guide Error Occurrences',
    guideErrorOccurrencesValue: 'Total no. of guide error in the selected time frame',
    guideErrorSummary: 'Guide Error Summary List',
    guideErrorSummaryValue: 'Summary of all the guides with steps that have experienced steps error in the given time frame',
    stepInGuideWithErrors: 'Steps in guide with errors',
    totalGuidePlayErrors: 'Total Guide Play Errors',
    latestErrorRecorded: 'Latest error recorded on',
    stepInGuideWithErrorsValue: 'The total no. steps in the guide are experiencing errors.',
    totalGuidePlayErrorsValue: 'Total number of times an error was encountered in guide play',
    latestErrorRecordedValue: 'Time stamp of the last recorded error',
    latestStartTimestamp: 'Latest StartTimestamp',
    stepErrorCounts: 'Step Error Counts',
    latestStartTimestampValue: 'Latest timestamp of error occurrence on the step.',
    stepErrorCountsValue: 'Total number of times an error occurred on the step',
    stepErrorSummary: 'Step Error Summary',
    guideError: 'Guide Error',
    //translated -- 14 Aug

    actions: 'Actions',
    createTemplate: 'Create Template',
    createSchedule: 'Create Schedule',
    createJob: 'Create Job',
    playType: 'Play Type',
    doYouWantToDelete: "Do you want to delete?",
    dateRange: "Date Range",
    triggerType: "Trigger Type",
    triggerFrequency: "Trigger Frequency",
    rowsPerPage: 'Rows per page',
    exportToExcel: "Export To Excel",
    triggerDay: "Trigger Day",
    triggerDate: "Trigger Date",
    dayOfWeek: "Day of Week",
    dateOfMonth: "Date of Month",
    pauseSchedule: "Pause Schedule",
    playSchedule: "Play Schedule",
    editSchedule: "Edit Schedule",
    editTemplate: "Edit Template",
    businessIntelligenceToolsIntegrations: 'Business Intelligence Tools Integration',
    bitiInfoHeading0: "How to get Power BI Desktop connector for ‘CornerstoneGuide’ ?",
    bitiInfo0_1: "Click here",
    bitiInfo0_2: "to download the connector (.mez) file",
    bitiInfo0_3: "Copy the extension file into",
    bitiInfo0_4: "Check the option",
    bitiInfo0_5: "Allow any extension to load without validation or warning",
    bitiInfo0_6: "in Power BI Desktop under",
    bitiInfo0_7: "Restart Power BI Desktop",
    bitiInfoHeading1: "How to pull data from Cornerstone Guide Insights directly to your PowerBI Desktop instance ?",
    bitiInfo1_1: "Generate an authenticated URL by clicking on the ‘Generate’ button below",
    bitiInfo1_2: "Copy the generated URL",
    bitiInfo1_3: "Go to PowerBI Desktop on your Windows computer",
    bitiInfo1_4: "Click on ‘Home’ tab on the Navigation bar and then click on the ‘Get Data’ option. From the drop down click on ‘More’",
    bitiInfo1_5: "From the dialog box that opens, click on ‘Other’ and search for ‘Cornerstone Guide (Beta) (Custom)’ and then click on ‘Connect’.",
    bitiInfo1_6: "On the new dialog box paste the authenticated URL and click on ‘Ok’",
    bitiInfo1_7: "It will open a ‘Navigation’ window. Navigate and select the data you want to pull into PowerBI Desktop and click on ‘Load’",
    bitiInfoHeading2: "Important Instructions",
    bitiInfo2_1: "Each authenticated URL generate in this portal are unique to individual users and are tied to their user account.",
    bitiInfo2_2: "While URL themselves to not have any expiry but they are tied to sessions which expires after 60 minutes of inactivity.",
    bitiInfo2_3: "URLs can be re-authorized by clicking on the ‘Re-Authorize’ button above in the section.",
    bitiInfo2_4: "If your wish to change the URL (at will or in case of the URL being compromised) you can do so by clicking on the ‘Re-Generate’ button.",
    bitiInfoNote: "Note: Authenticated URL is applicable for 60 minutes from the time of generation",
    generateUrl: "Generate URL",
    authenticateUrl: "Authenticate URL",
    //translation file include till this point -- 21 October
    
    copy: "Copy",
    initiateDataSynchronization: "Initiate Data Synchronization",
    initiationInProgressInfo: "Your Initiation request is in progress. Please come after a while",
    initiationFailedInfo: "Last Initiation request got failed. Please try again",
    userResponses: "A table displaying details of user responses",
    surveyResponses: "A table displaying details of survey responses",
}

// export default GmXt.playerLbls.en_US;
module.exports = GmXt.playerLbls.en_US
