// Modified by: Mrunal Mane
// On: 2024-05-13
// Reason: Removed language dependancy from useEffect (props)

import React, { useState, useEffect } from 'react';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';

import { useSelector } from "react-redux";
import { LanguageState } from "store/Reducers";

import './QuestionsList.scss'
import SingleSelect from 'components/Chart/QuestionListCharts/SingleSelect';
import MultiSelect from 'components/Chart/QuestionListCharts/MultiSelect';
import Comment from 'components/Chart/QuestionListCharts/Comment';
import Range from 'components/Chart/QuestionListCharts/Range';
import YesNo from 'components/Chart/QuestionListCharts/YesNo';
import Rating from 'components/Chart/QuestionListCharts/Rating';
import { useErrorBoundary } from 'react-error-boundary';
import { InsightLabels } from 'labels';

interface CurrentState {
    languageData: LanguageState
}
const QuestionsList = (props: any) => {
    let fetched_details = useSelector((state: CurrentState) => state);
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);
    const { showBoundary } = useErrorBoundary();
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [props])

    const getQuestionType = (type: string) => {
        try {
            switch (type) {
                case 'select':
                    return (insightsLbls.singleSelect);

                case 'multi-select':
                    return (insightsLbls.multiSelect);

                case 'comment':
                    return (insightsLbls.text);

                case 'range':
                    return (insightsLbls.range);

                case 'rating':
                    return (insightsLbls.rating);

                case 'yes-no':
                    return (insightsLbls.yesNo);
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const questionData = (data: any, index: number) => {
        return (
            <p className="question">
                <span className="questionIndex">Q{index + 1}.</span>
                <span className="marginLeft-10 questionText">{data.question}</span>
                <span className="marginLeft-10 deletedText errorMsg">{data.isDeleted && insightsLbls.deleted}</span>
                <span className="marginLeft-10 questionType">{getQuestionType(data.questionType)}</span>
                <span className="marginLeft-10 responses">{insightsLbls.responses}</span>
            </p>
        )
    };



    return (
        <div className="">
            <Accordion allowZeroExpanded>
                {props?.data?.map((data: any, index: number) => {
                    return (


                        <AccordionItem key={index} id={'collapse_' + index}>



                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    {questionData(data, index)}
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>


                                <div>
                                    {(() => {
                                        switch (data.questionType) {
                                            case 'select':
                                                return (
                                                    <SingleSelect tableData={data.options} chartData={data.options} divIndex={index}></SingleSelect>
                                                );


                                            case 'multi-select':
                                                return (
                                                    <MultiSelect tableData={data.options} chartData={data.options} divIndex={index}></MultiSelect>
                                                );


                                            case 'range':
                                                return (
                                                    <Range tableData={data.options} chartData={data.options} divIndex={index}></Range>
                                                );

                                            case 'comment':
                                                return (
                                                    <Comment tableData={data.comments} totalCount={data.totalComments} divIndex={index}></Comment>
                                                );

                                            case 'rating':
                                                return (
                                                    <Rating tableData={data.options} chartData={data.options} divIndex={index}></Rating>
                                                );

                                            case 'yes-no':
                                                return (
                                                    <YesNo answeredYes={data.answeredYes} answeredNo={data.answeredNo} divIndex={index}></YesNo>
                                                );
                                        }
                                    })()}
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>

                    );
                })}
            </Accordion>
        </div>
    )
};

export default React.memo(QuestionsList);
