import * as actionTypes from '../../Actions/ActionTypes';

export interface GuideErrorsState {
    guideErrorsChartData: any,
    guideErrorsSummary: any,
}

const initialState = {
    guideErrorsChartData: [],
    guideErrorsSummary: [],
};


const guideErrorsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SET_GUIDE_ERRORS_CHART_DATA:
            return {
                ...state,
                guideErrorsChartData: action.guideErrorsChartData,
            };


        case actionTypes.SET_GUIDE_ERRORS_SUMMARY:
            return {
                ...state,
                guideErrorsSummary: action.guideErrorsSummary,
            };

        default:
            return state;
    }
};

export default guideErrorsReducer;