import React from "react";
import { useState, useEffect } from "react";
import "./UserInfoSection.scss";
import moment from "moment";
import { add, differenceInDays, format, getYear, parseISO, sub, subDays } from "date-fns";
import { InsightLabels } from "labels";


import { useSelector } from "react-redux";
import { LanguageState, AppsState } from "store/Reducers";
import { useLocation } from 'react-router-dom';


interface CurrentState {
    languageData: LanguageState,
    apps: AppsState;
}

const UserInfoSection = React.memo((props: any) => {
    const location = useLocation();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    let fetched_details = useSelector((state: CurrentState) => state);

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    return (
        <div className="col-sm-12 col-md-12 col-lg-12 user-info-main-div" data-html2canvas-ignore="true">
            {props.showUserInfo ? (
                <p className="userName">Hi {localStorage.getItem('USER_FNAME')} {localStorage.getItem('USER_LNAME')}. {props.messageText}</p>
            ) : (
                <p className="userName">{props.messageText}</p>
            )}

           

            <p className="marginBottom-0"> <span>{props.children}</span>
                {props.startDate && props.endDate && (
                    <>
                        {!location.pathname.includes('dashboard') &&
                            <span className="infoChip marginLeft-5">{fetched_details?.apps?.appName}</span>
                        }
                    </>
                )}

                {!(location.pathname.includes('app-insights') ||
                    location.pathname.includes('dashboard') ||
                    location.pathname.includes('organization') ||
                    props.source === 'modal' || props.source === 'exportEmail' ||
                    location.pathname.includes('task-list')) && props?.dateObject?.realTimeCheck &&  (

                    <span className="infoChip marginLeft-5">{insightsLbls.withRealTimeData}</span>
                )}

                {!(location.pathname.includes('app-insights') ||
                    location.pathname.includes('dashboard') ||
                    location.pathname.includes('automation-insights') ||
                    location.pathname.includes('organization') ||
                    location.pathname.includes('survey') ||
                    location.pathname.includes('chatbot') ||
                    props.source === 'modal' || props.source === 'exportEmail' ||
                    location.pathname.includes('task-list')) &&
                    (!props?.dateObject?.excludeFlag ? (
                        <span className="infoChip marginLeft-5">{insightsLbls.weekendsIncluded}</span>
                    ) : (
                        <span className="infoChip marginLeft-5">{insightsLbls.weekendsExcluded}</span>
                    ))

                }

                {props.startDate && props.endDate && (
                    <>
                        <span className="dateChip marginLeft-5">{moment(new Date(props.startDate)).format("DD MMM yyyy")} to {moment(new Date(props.endDate)).format("DD MMM yyyy")}</span>
                    </>
                )}

         

            </p>
        </div>

    );
});

export default UserInfoSection;