import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useErrorBoundary } from 'react-error-boundary';

import './CommonModal.scss';
import Icon from 'components/Icon/Icon';

const CommonModal = (props: any) => {
    const { showBoundary } = useErrorBoundary();
    const [modalState, setModalState] = useState({
        show: false
    });


    useEffect(() => {
        setModalState((prevState: any) => {
            return {
                ...prevState,
                show: props.modalState
            }
        })

    }, [props.modalState])


    const closeModal = () => {
        try {
            props.dispatchModalState(false)
            setTimeout(() => {
                setModalState((prevState: any) => {
                    return {
                        ...prevState,
                        show: false
                    }
                })
            }, 100)
        } catch (error) {
            showBoundary(error)
        }

    }

    const modalOk = () => {
        props.dispatchModalOk();
        closeModal();
    }

    const modalCancel = () => {
        props.dispatchModalCancel();
    }

    return (
        <Modal
            show={modalState.show}
            onHide={() => closeModal()}
            size={props.size}
            dialogClassName={props.modalDialogClass}
            backdrop="static">
            <Modal.Header>
                <Modal.Title>{props.modalTitle}</Modal.Title>
                <button type="button" className="close" onClick={() => closeModal()} >
                    <span aria-hidden="true"><Icon img="img/close-icon-large.svg"></Icon></span>
                    <span className="sr-only">Close</span>
                </button>
            </Modal.Header>
            <Modal.Body className="paddingBottom-0" style={props.padding ? { padding: '16px' } : { padding: '0px' }}>
                <div>
                    {props.children}
                </div>
            </Modal.Body>
            <Modal.Footer style={props.footerDisabled ? { display: 'none' } : { display: 'block' }}>
                <div className="col-lg-12" >

                    {props.cancelBtn &&
                        (<button className={props.size === "md" ? ("submit-btn btn greyBtn disableClrBtn modal-cancel-btn floatRight marginLeft-10 btn-secondary") : ("submit-btn btn greyBtn disableClrBtn modal-cancel-btn-sm floatRight marginLeft-10 btn-secondary")} onClick={modalCancel}>{props.cancelBtn}</button>)}

                    {props.okBtn &&
                        (<button className={props.size === "md" ? ("btn btn-primary floatRight marginLeft-10") : ("btn modal-save-btn-sm floatRight marginLeft-10 btn-primary")} onClick={modalOk} disabled={props.okBtnState}>{props.okBtn}</button>)}

                </div>
            </Modal.Footer>
        </Modal>
    )
};

export default React.memo(CommonModal);
