
// Modified by: Mrunal Mane
// On: 2024-05-10 05:48 PM
// Reason: Removed language dependancy from useEffects of (props)

import React, { useRef, useEffect, useState } from "react";
import NVD3Chart from "react-nvd3";
import { useSelector } from "react-redux";
import * as d3 from "d3";
import { useErrorBoundary } from 'react-error-boundary';
import $ from "jquery";
import { CommonUtils } from "utils/CommonUtils";
import { InsightLabels } from "labels";
import { LanguageState } from "store/Reducers";


interface CurrentState {
    languageData: LanguageState
}

const BarChart = (props: any) => {
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    let fetched_details = useSelector((state: CurrentState) => state);
    const { showBoundary } = useErrorBoundary();

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    let barChartRef = [];
    barChartRef[props.refId] = useRef(null);


    const [renderData, setRenderData] = useState({
        chart: [],
        options: [],
        flag: false
    })


    useEffect(() => {
        try {
            if (props.chartData) {
                const data = CommonUtils.dateSeparator(props.chartData);

                let initialArray: any = [];
                for (let itx1 = 0; itx1 < props.chartEntities.length; itx1++) {
                    let temp_arr: any = [];
                    for (let itx2 = 0; itx2 < data.length; itx2++) {
                        let temp_obj: any = {};
                        temp_obj["startTimestamp"] = data[itx2].startTimestamp;
                        temp_obj["x"] = data[itx2].startTimestamp;
                        temp_obj["date"] = data[itx2].startTimestamp;

                        temp_obj["y"] = props.parseIntFlag ? parseInt(data[itx2]['counts'][props.chartEntities[itx1]], 10) : data[itx2]['counts'][props.chartEntities[itx1]];
                        temp_arr.push(temp_obj);
                    }
                    initialArray.push(temp_arr)
                }

                let chartOptions: any = [];
                for (let itx1 = 0; itx1 < props.chartEntities.length; itx1++) {
                    let dummy_chartOptions: any = {};
                    dummy_chartOptions["key"] = props.optionEntities[itx1]['key'];
                    dummy_chartOptions["values"] = initialArray[itx1];
                    dummy_chartOptions["color"] = props.optionEntities[itx1]['color'];
                    dummy_chartOptions["area"] = props.optionEntities[itx1]['area'];
                    chartOptions.push(dummy_chartOptions);
                }

                setRenderData((prevState: any) => {
                    return {
                        ...prevState,
                        chart: [...data],
                        options: [...chartOptions]
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [props])

    useEffect(() => {
        try {
            if (renderData.options) {
                setRenderData((prevState: any) => {
                    return {
                        ...prevState,
                        flag: true
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [renderData.options])

    return (
        <div>
            <div id={props.refId}>
                {renderData.flag && (
                    <NVD3Chart
                        ref={barChartRef[props.refId]}
                        type="multiBarChart"
                        datum={renderData.options}
                        showValues={true}
                        wrapLabels={false}
                        useInteractiveGuideline={false}
                        clipEdge={false}
                        legend={{
                            maxKeyLength: 100,
                            margin: {
                                right: 0,
                                top: 5,
                                left: -50,
                                bottom: 15,
                            }
                        }
                        }
                        x={function (d: any, i: any) {
                            return i;
                        }}
                        y={function (d: any) {
                            return d.y;
                        }}
                        margin={{
                            left: props.margin["left"],
                            right: props.margin["right"],
                            top: props.margin["top"],
                            bottom: props.margin["bottom"],
                        }}
                        xAxis={{
                            tickFormat: function (d: any) {
                                if (renderData.chart[d] !== undefined) {

                                    return (renderData.chart[d]['startTimestamp']);
                                }
                            },
                            showMaxMin: true
                        }}
                        yAxis={{
                            axisLabel: insightsLbls.playCount,
                            axisLabelDistance: 5,
                            tickFormat: function (d: any) {
                                return Number.isInteger(d)?d:"";
                            },
                        }}
                        duration={50}
                        showControls={false}
                        stacked={true}
                        height={props.height}
                        tooltip={{
                            contentGenerator: function (key: any) {
                                d3.selectAll('.nvtooltip').style('opacity', 0);

                                let time;
                                if (key.data.startTimeStamp) {
                                    time = key.data.startTimeStamp;
                                } else {
                                    time = key.data.startTimestamp;
                                }

                                if (time.includes('|')) {
                                    $('.nv-point-paths path, .nv-groups rect').css('cursor', 'default');
                                    return `<div><b>${key.data.date} ${time}</b><br/><div style='height:10px; display:inline-block; margin-right:5px;width:10px; background-color:${key.color}'></div>${key.series[0].key} <b>${key.data.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</b></div>`;
                                } else {
                                    $(' .nv-point-paths path, .nv-groups rect').css('cursor', 'default');
                                    return `<div><b>${(time)}</b><br/><div style='height:10px; display:inline-block; margin-right:5px;width:10px; background-color:${key.color}'></div>${key.series[0].key} <b>${key.data.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</b></div>`;
                                }
                            }
                        }}
                    />
                )}
            </div>
        </div>
    );
};
export default React.memo(BarChart);