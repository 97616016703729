import { InsightLabels } from "labels";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import "./exportUtils.scss"

interface AppComponentProps {
    appsList: any[];
    insightsLbls: InsightLabels;
    selectedApps: any[];
    selectedAppsData: any
}

export const GetAppDropdown: React.FC<AppComponentProps> = ({ appsList, insightsLbls, selectedApps, selectedAppsData }) => {

    const [appDropdown, setAppDropdown] = useState({
        isOpen: false,
        previous: {
            value: selectedApps.length == appsList.length ? [] : selectedApps,
            allSelected: selectedApps.length == 0 || selectedApps.length == appsList.length ? true : false
        },
        value: selectedApps.length == appsList.length ? [] : selectedApps,
        allSelected: selectedApps.length == 0 || selectedApps.length == appsList.length ? true : false
    });

    const toggleAppDropdown = (event) => {
        setAppDropdown((prevState: any) => {
            return {
                ...prevState,
                isOpen: event
            }
        })
    }

    const handleAppSelect = (event) => {
        let allSelected = false;
        let value = [];
        if (appDropdown.allSelected) {
            //all selected, disselect one element
            if (!event.target.checked) {
                appsList.forEach((app) => {
                    if (event.target.value != app.appId) {
                        value.push(app.appId);
                    }
                });
            }
        } else {
            value = [...appDropdown.value];
            let selectedVal = parseInt(event.target.value);
            //all not selected
            if (event.target.checked) {
                if (appDropdown.value.length === appsList.length - 1) {
                    //Case to select all, if manually all is selected
                    if (!value.includes(selectedVal)) {
                        allSelected = true;
                        value = [];
                    }
                } else {
                    //if value don't exist already, add it
                    if (!value.includes(selectedVal)) {
                        value.push(selectedVal);
                    }
                }
            } else {
                //if value exists, remove it
                if (value.includes(selectedVal)) {
                    value = value.filter((e) => e != selectedVal);
                }
            }
        }
        setAppDropdown((prevState: any) => {
            return {
                ...prevState,
                allSelected: allSelected,
                value: value
            }
        })
    }

    const handleAppSelectAll = (event) => {
        setAppDropdown((prevState: any) => {
            return {
                ...prevState,
                allSelected: event.target.checked,
                value: []
            }
        })
    }

    const getSelectedAppCount = () => appDropdown.previous.allSelected ? appsList.length : appDropdown.previous.value.length;

    //this method is used to find the first key, as values array may be out of order
    const findKey = () => appsList.find(app => appDropdown.previous.value.includes(app.appId))?.appName;

    // const getSelectedAppLabel = () => appDropdown.previous.allSelected ? appsList[0].appName : findKey();

    const getSelectedAppLabel = () => {
        const getValue = (list, key) => list.find(app => app.appId == key)?.appName;
        if (!appDropdown.allSelected && appDropdown.value.length == 0) { return ""; }
        const dataList = appDropdown.value.length === 0 ? appsList : appDropdown.value;
        const limit = 3;
        let displayedData = dataList.slice(0, limit).map(item => {
            const value = appDropdown.value.length === 0 ? item.appName : getValue(appsList, item);
            const truncatedValue = value.length > 15 ? `${value.slice(0, 15)}...` : value;
            return <span className="chips">{truncatedValue}</span>;
        });
        if (dataList.length > limit) {
            displayedData.push(<span className="chips">...{dataList.length - limit} more</span>);
        }

        return displayedData;
    };

    const getAppCheckStatus = (appId) => {
        return appDropdown.allSelected || appDropdown.value.includes(appId);
    }

    const appSetter = (val) => {
        if (val.length == 0) {
            val = appsList.map(ds => ds.appId);
        }
        selectedAppsData(val);
    }

    useEffect(() => {
        appSetter(selectedApps);
    }, []);

    const handleApply = () => {
        setAppDropdown((prevState) => {
            return {
                ...prevState,
                isOpen: false,
                previous: {
                    value: appDropdown.value,
                    allSelected: appDropdown.allSelected
                }
            }
        })
        appSetter(appDropdown.value);
    }

    const disableApply = () => appDropdown.allSelected ? false : appDropdown.value.length == 0;

    return (
        <Dropdown className="width-100" show={appDropdown.isOpen} onToggle={(event) => toggleAppDropdown(event)}>
            <Dropdown.Toggle className={"btn btn-lg btn-outline-primary export-dropdown"} >
                {/* {getSelectedAppCount() > 1 && (<span className="dropdown-count">+{getSelectedAppCount() - 1}</span>)} */}
                <span className="dropdown-label">{getSelectedAppLabel()}</span>
                <img src={require("../../assets/img/caret-down.png")} alt="" />
            </Dropdown.Toggle>
            <Dropdown.Menu className={`dropdown-menu ${appDropdown.isOpen ? 'show' : ''}`} >
                <div className="custom-control custom-checkbox mr-sm-2 displayFlex alignCenter check-container">
                    <label className="check-container displayFlex alignCenter exportCheck">
                        <span className="marginLeft-35">{insightsLbls.selectAll}</span>
                        <input type="checkbox" className="custom-control-input  chk checkbox"
                            name="chk-box" value="all" onChange={(event) => handleAppSelectAll(event)} checked={appDropdown.allSelected}></input>
                        <span className="checkmark positionAbsolute checkmarkBox-color displayInlineBlock top0"></span>
                    </label>
                </div>

                <hr className="marginBottom-0 marginTop-0"></hr>

                <div style={{ maxHeight: "180px", overflowY: "auto" }}>
                    {appsList.map((elem: any) => {
                        return (
                            <div className="custom-control custom-checkbox mr-sm-2 displayFlex alignCenter check-container">
                                <label className="check-container displayFlex alignCenter exportCheck">
                                    <span className="marginLeft-35">{elem.appName}</span>
                                    <input type="checkbox" className={"custom-control-input chk checkbox"}
                                        id="customControlAutosizing" name={elem.appName}
                                        value={elem.appId} onChange={(event) => handleAppSelect(event)} checked={getAppCheckStatus(elem.appId)}></input>
                                    <span className="checkmark positionAbsolute checkmarkBox-color displayInlineBlock top0"></span>
                                </label>
                            </div>
                        );
                    })
                    }
                </div>

                <div className="applyButton">
                    <button type="button" className="btn btn-primary"
                        onClick={handleApply} disabled={disableApply()}>{insightsLbls.apply}</button>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}

interface EventSourceComponentProps {
    eventSourceList: any[];
    insightsLbls: InsightLabels;
    selectedEvents: any[];
    selectedEventSourceData: any
}

export const GetEventSourceDropdown: React.FC<EventSourceComponentProps> = ({ eventSourceList, insightsLbls, selectedEvents, selectedEventSourceData }) => {
    const [eventDropdown, setEventDropdown] = useState({
        isOpen: false,
        previous: {
            value: selectedEvents.length == eventSourceList.length ? [] : selectedEvents,
            allSelected: selectedEvents.length == 0 || selectedEvents.length == eventSourceList.length ? true : false
        },
        value: selectedEvents.length == eventSourceList.length ? [] : selectedEvents,
        allSelected: selectedEvents.length == 0 || selectedEvents.length == eventSourceList.length ? true : false
    });

    const toggleEventDropdown = (event) => {
        setEventDropdown((prevState: any) => {
            return {
                ...prevState,
                isOpen: event
            }
        })
    }

    const handleEventSelect = (event) => {
        let allSelected = false;
        let value = [];
        if (eventDropdown.allSelected) {
            //all selected, disselect one element
            if (!event.target.checked) {
                eventSourceList.forEach((es) => {
                    if (event.target.value != es.key) {
                        value.push(es.key);
                    }
                });
            }
        } else {
            value = [...eventDropdown.value];
            let selectedVal = event.target.value;
            //all not selected
            if (event.target.checked) {
                if (eventDropdown.value.length === eventSourceList.length - 1) {
                    //Case to select all, if manually all is selected
                    if (!value.includes(selectedVal)) {
                        allSelected = true;
                        value = [];
                    }
                } else {
                    //if value don't exist already, add it
                    if (!value.includes(selectedVal)) {
                        value.push(selectedVal);
                    }
                }
            } else {
                //if value exists, remove it
                if (value.includes(selectedVal)) {
                    value = value.filter((e) => e != selectedVal);
                }
            }
        }
        setEventDropdown((prevState: any) => {
            return {
                ...prevState,
                allSelected: allSelected,
                value: value
            }
        })
    }

    const handleEventSelectAll = (event) => {
        setEventDropdown((prevState: any) => {
            return {
                ...prevState,
                allSelected: event.target.checked,
                value: []
            }
        })
    }

    const getSelectedEventCount = () => eventDropdown.previous.allSelected ? eventSourceList.length : eventDropdown.previous.value.length;

    //this method is used to find the first key, as values array may be out of order
    const findKey = () => eventSourceList.find(es => eventDropdown.previous.value.includes(es.key))?.value;

    // const getSelectedEventLabel = () => eventDropdown.previous.allSelected ? eventSourceList[0].value : findKey();

    const getSelectedEventLabel = () => {
        const getValue = (list, key) => list.find(pair => pair.key == key)?.value;
        if (!eventDropdown.allSelected && eventDropdown.value.length == 0) { return ""; }
        const dataList = eventDropdown.value.length === 0 ? eventSourceList : eventDropdown.value;
        const limit = 3;
        let displayedData = dataList.slice(0, limit).map(item => {
            const value = eventDropdown.value.length === 0 ? item.value : getValue(eventSourceList, item);
            const truncatedValue = value.length > 15 ? `${value.slice(0, 15)}...` : value;
            return <span className="chips">{truncatedValue}</span>;
        });
        if (dataList.length > limit) {
            displayedData.push(<span className="chips">...{dataList.length - limit} more</span>);
        }

        return displayedData;
    };

    const getEventCheckStatus = (key) => eventDropdown.allSelected || eventDropdown.value.includes(key);

    const eventSourceSetter = (val) => {
        if (val.length == 0) {
            val = eventSourceList.map(ds => ds.key);
        }
        selectedEventSourceData(val);
    }

    useEffect(() => {
        eventSourceSetter(selectedEvents);
    }, [])

    const handleApply = () => {
        setEventDropdown((prevState) => {
            return {
                ...prevState,
                isOpen: false,
                previous: {
                    value: eventDropdown.value,
                    allSelected: eventDropdown.allSelected
                }
            }
        })
        eventSourceSetter(eventDropdown.value);
    }

    const disableApply = () => eventDropdown.allSelected ? false : eventDropdown.value.length == 0;

    return (
        <Dropdown className="width-100" show={eventDropdown.isOpen} onToggle={(event) => toggleEventDropdown(event)}>
            <Dropdown.Toggle className={"btn btn-lg btn-outline-primary export-dropdown"}>
                {/* {getSelectedEventCount() > 1 && (<span className="dropdown-count">+{getSelectedEventCount() - 1}</span>)} */}
                <span className="dropdown-label">{getSelectedEventLabel()}</span>
                <img src={require("../../assets/img/caret-down.png")} alt="" />
            </Dropdown.Toggle>
            <Dropdown.Menu className={`dropdown-menu ${eventDropdown.isOpen ? 'show' : ''}`}>
                <div className="custom-control custom-checkbox mr-sm-2 displayFlex alignCenter check-container">
                    <label className="check-container displayFlex alignCenter exportCheck">
                        <span className="marginLeft-35">{insightsLbls.selectAll}</span>
                        <input type="checkbox" className="custom-control-input  chk checkbox"
                            name="chk-box" value="all" onChange={(event) => handleEventSelectAll(event)} checked={eventDropdown.allSelected}></input>
                        <span className="checkmark positionAbsolute checkmarkBox-color displayInlineBlock top0"></span>
                    </label>
                </div>

                <hr className="marginBottom-0 marginTop-0"></hr>

                {eventSourceList.map((elem: any, index: number) => {
                    return (
                        <div className="custom-control custom-checkbox mr-sm-2 displayFlex alignCenter check-container">
                            <label className="check-container displayFlex alignCenter exportCheck">
                                <span className="marginLeft-35">{elem.value}</span>
                                <input type="checkbox" className={"custom-control-input chk checkbox"}
                                    id="customControlAutosizing" name={elem.key}
                                    value={elem.key} onChange={(event) => handleEventSelect(event)} checked={getEventCheckStatus(elem.key)}></input>
                                <span className="checkmark positionAbsolute checkmarkBox-color displayInlineBlock top0"></span>
                            </label>
                        </div>
                    );
                })
                }
                <div className="applyButton">
                    <button type="button" className="btn btn-primary"
                        onClick={handleApply} disabled={disableApply()}>{insightsLbls.apply}</button>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}

interface DataSourceComponentProps {
    dataSourcesList: any[];
    insightsLbls: InsightLabels;
    selectedDataSource: any[];
    selectedDataSourceData: any
}

export const GetDataSourceDropdown: React.FC<DataSourceComponentProps> = ({ dataSourcesList, insightsLbls, selectedDataSource, selectedDataSourceData }) => {

    const [dataDropdown, setDataDropdown] = useState({
        isOpen: false,
        previous: {
            value: selectedDataSource.length == dataSourcesList.length ? [] : selectedDataSource,
            allSelected: selectedDataSource.length == 0 || selectedDataSource.length == dataSourcesList.length ? true : false
        },
        value: selectedDataSource.length == dataSourcesList.length ? [] : selectedDataSource,
        allSelected: selectedDataSource.length == 0 || selectedDataSource.length == dataSourcesList.length ? true : false
    });

    const toggleDataDropdown = (data) => {
        setDataDropdown((prevState: any) => {
            return {
                ...prevState,
                isOpen: data
            }
        })
    }

    const handleDataSelect = (event) => {
        let allSelected = false;
        let value = [];
        if (dataDropdown.allSelected) {
            //all selected, disselect one element
            if (!event.target.checked) {
                dataSourcesList.forEach((es) => {
                    if (event.target.value != es.key) {
                        value.push(es.key);
                    }
                });
            }
        } else {
            value = [...dataDropdown.value];
            let selectedVal = event.target.value;
            //all not selected
            if (event.target.checked) {
                if (dataDropdown.value.length === dataSourcesList.length - 1) {
                    //Case to select all, if manually all is selected
                    if (!value.includes(selectedVal)) {
                        allSelected = true;
                        value = [];
                    }
                } else {
                    //if value don't exist already, add it
                    if (!value.includes(selectedVal)) {
                        value.push(selectedVal);
                    }
                }
            } else {
                //if value exists, remove it
                if (value.includes(selectedVal)) {
                    value = value.filter((e) => e != selectedVal);
                }
            }
        }
        setDataDropdown((prevState: any) => {
            return {
                ...prevState,
                allSelected: allSelected,
                value: value
            }
        })
    }

    const handleDataSelectAll = (data) => {
        setDataDropdown((prevState: any) => {
            return {
                ...prevState,
                allSelected: data.target.checked,
                value: []
            }
        })
    }

    const getSelectedDataCount = () => dataDropdown.previous.allSelected ? dataSourcesList.length : dataDropdown.previous.value.length;

    //this method is used to find the first key, as values array may be out of order
    const findKey = () => dataSourcesList.find(es => dataDropdown.previous.value.includes(es.key))?.value;

    // const getSelectedDataLabel = () => dataDropdown.previous.allSelected ? dataSourcesList[0].value : findKey()

    const getSelectedDataLabel = () => {
        const getValue = (list, key) => list.find(pair => pair.key == key)?.value;
        if (!dataDropdown.allSelected && dataDropdown.value.length == 0) { return ""; }
        const dataList = dataDropdown.value.length === 0 ? dataSourcesList : dataDropdown.value;
        const limit = 3;
        let displayedData = dataList.slice(0, limit).map(item => {
            const value = dataDropdown.value.length === 0 ? item.value : getValue(dataSourcesList, item);
            const truncatedValue = value.length > 15 ? `${value.slice(0, 15)}...` : value;
            return <span className="chips">{truncatedValue}</span>;
        });
        if (dataList.length > limit) {
            displayedData.push(<span className="chips">...{dataList.length - limit} more</span>);
        }

        return displayedData;
    };

    const getDataCheckStatus = (key) => dataDropdown.allSelected || dataDropdown.value.includes(key);

    const dataSourceSetter = (val) => {
        if (val.length == 0) {
            val = dataSourcesList.map(ds => ds.key);
        }
        selectedDataSourceData(val);
    }

    useEffect(() => {
        dataSourceSetter(selectedDataSource);
    }, [])
    const handleApply = () => {
        setDataDropdown((prevState) => {
            return {
                ...prevState,
                isOpen: false,
                previous: {
                    value: dataDropdown.value,
                    allSelected: dataDropdown.allSelected
                }
            }
        })
        dataSourceSetter(dataDropdown.value);
    }

    const disableApply = () => dataDropdown.allSelected ? false : dataDropdown.value.length == 0;

    return (
        <Dropdown className="width-100" show={dataDropdown.isOpen} onToggle={(event) => toggleDataDropdown(event)} >
            <Dropdown.Toggle className={"btn btn-lg btn-outline-primary export-dropdown"}>
                {/* {getSelectedDataCount() > 1 && (<span className="dropdown-count">+{getSelectedDataCount() - 1}</span>)} */}
                <span className="dropdown-label">{getSelectedDataLabel()}</span>
                <img src={require("../../assets/img/caret-down.png")} alt="" />
            </Dropdown.Toggle>
            <Dropdown.Menu className={`dropdown-menu ${dataDropdown.isOpen ? 'show' : ''}`}>
                <div className="custom-control custom-checkbox mr-sm-2 displayFlex alignCenter check-container">
                    <label className="check-container displayFlex alignCenter exportCheck">
                        <span className="marginLeft-35">{insightsLbls.selectAll}</span>
                        <input type="checkbox" className="custom-control-input  chk checkbox"
                            name="chk-box" value="all" onChange={(event) => handleDataSelectAll(event)} checked={dataDropdown.allSelected}></input>
                        <span className="checkmark positionAbsolute checkmarkBox-color displayInlineBlock top0"></span>
                    </label>
                </div>

                <hr className="marginBottom-0 marginTop-0"></hr>

                {dataSourcesList.map((elem: any, index: number) => {
                    return (
                        <div className="custom-control custom-checkbox mr-sm-2 displayFlex alignCenter check-container">
                            <label className="check-container displayFlex alignCenter exportCheck">
                                <span className="marginLeft-35">{elem.value}</span>
                                <input type="checkbox" className={"custom-control-input chk checkbox"}
                                    id="customControlAutosizing" name={elem.key}
                                    value={elem.key} onChange={(event) => handleDataSelect(event)} checked={getDataCheckStatus(elem.key)}></input>
                                <span className="checkmark positionAbsolute checkmarkBox-color displayInlineBlock top0"></span>
                            </label>
                        </div>
                    );
                })
                }
                <div className="applyButton">
                    <button type="button" className="btn btn-primary"
                        onClick={handleApply} disabled={disableApply()}>{insightsLbls.apply}</button>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}

interface PlayTypeComponentProps {
    playTypesList: any[];
    insightsLbls: InsightLabels;
    selectedPlayType: any[];
    selectedPlayTypeData: any
}

export const GetPlayTypeDropdown: React.FC<PlayTypeComponentProps> = ({ playTypesList, insightsLbls, selectedPlayType, selectedPlayTypeData }) => {

    const [playType, setPlayType] = useState({
        isOpen: false,
        value: selectedPlayType.length == playTypesList.length ? [] : selectedPlayType,
        allSelected: selectedPlayType.length == 0 || selectedPlayType.length == playTypesList.length ? true : false
    });

    const togglePlayTypeDropdown = (event) => {
        setPlayType((prevState: any) => {
            return {
                ...prevState,
                isOpen: event
            }
        })
    }

    const playTypeSetter = (val) => {
        if (val.length == 0) {
            val = playTypesList.map(play => play.key);
        }
        selectedPlayTypeData(val);
    }

    useEffect(() => {
        playTypeSetter(selectedPlayType);
    }, [])

    const handlePlayTypeSelect = (event) => {
        let allSelected = false;
        let value = [];
        if (playType.allSelected) {
            //all selected, disselect one element
            if (!event.target.checked) {
                playTypesList.forEach((elem) => {
                    if (event.target.value != elem.key) {
                        value.push(elem.key);
                    }
                });
            }
        } else {
            value = [...playType.value];
            let selectedVal = event.target.value;
            //all not selected
            if (event.target.checked) {
                if (playType.value.length === playTypesList.length - 1) {
                    //Case to select all, if manually all is selected
                    if (!value.includes(selectedVal)) {
                        allSelected = true;
                        value = [];
                    }
                } else {
                    //if value don't exist already, add it
                    if (!value.includes(selectedVal)) {
                        value.push(selectedVal);
                    }
                }
            } else {
                //if value exists, remove it
                if (value.includes(selectedVal)) {
                    value = value.filter((e) => e != selectedVal);
                }
            }
        }
        setPlayType((prevState: any) => {
            return {
                ...prevState,
                allSelected: allSelected,
                value: value
            }
        })
        playTypeSetter(value);
    }

    const handlePlayTypeSelectAll = (event) => {
        setPlayType((prevState: any) => {
            return {
                ...prevState,
                allSelected: event.target.checked,
                value: []
            }
        })
        playTypeSetter([]);
    }

    useEffect(() => {
        if (!playType.allSelected && playType.value.length == 0) {
            setPlayType((prevState: any) => {
                return {
                    ...prevState,
                    allSelected: true
                }
            })
        }
    }, [playType.isOpen])

    const getSelectedPlayTypeCount = () => playType.allSelected ? playTypesList.length : playType.value.length;

    //this method is used to find the first key, as values array may be out of order
    const findKey = () => playTypesList.find(item => playType.value.includes(item.key))?.value;

    // const getSelectedPlayTypeLabel = () => playType.allSelected ? playTypesList[0].value : findKey();

    const getSelectedPlayTypeLabel = () => {
        const getValue = (list, key) => list.find(pair => pair.key == key)?.value;
        if (!playType.allSelected && playType.value.length == 0) { return ""; }
        const dataList = playType.value.length === 0 ? playTypesList : playType.value;
        const limit = 3;
        let displayedData = dataList.slice(0, limit).map(item => {
            const value = playType.value.length === 0 ? item.value : getValue(playTypesList, item);
            const truncatedValue = value.length > 15 ? `${value.slice(0, 15)}...` : value;
            return <span className="chips">{truncatedValue}</span>;
        });
        if (dataList.length > limit) {
            displayedData.push(<span className="chips">...{dataList.length - limit} more</span>);
        }

        return displayedData;
    };


    const getPlayTypeCheckStatus = (key) => playType.allSelected || playType.value.includes(key);

    return (
        <Dropdown className="width-100" show={playType.isOpen} onToggle={(event) => togglePlayTypeDropdown(event)}>
            <Dropdown.Toggle className={"btn btn-lg btn-outline-primary export-dropdown"}>
                {/* {getSelectedPlayTypeCount() > 1 && (<span className="dropdown-count">+{getSelectedPlayTypeCount() - 1}</span>)} */}
                <span className="dropdown-label">{getSelectedPlayTypeLabel()}</span>
                <img src={require("../../assets/img/caret-down.png")} alt="" />
            </Dropdown.Toggle>
            <Dropdown.Menu className={`dropdown-menu ${playType.isOpen ? 'show' : ''}`}>
                <div className="custom-control custom-checkbox mr-sm-2 displayFlex alignCenter check-container">
                    <label className="check-container displayFlex alignCenter exportCheck">
                        <span className="marginLeft-35">{insightsLbls.selectAll}</span>
                        <input type="checkbox" className="custom-control-input  chk checkbox"
                            name="chk-box" value="all" onChange={(event) => handlePlayTypeSelectAll(event)} checked={playType.allSelected}></input>
                        <span className="checkmark positionAbsolute checkmarkBox-color displayInlineBlock top0"></span>
                    </label>
                </div>

                <hr className="marginBottom-0 marginTop-0"></hr>

                {playTypesList.map((elem: any) => {
                    return (
                        <div className="custom-control custom-checkbox mr-sm-2 displayFlex alignCenter check-container">
                            <label className="check-container displayFlex alignCenter exportCheck">
                                <span className="marginLeft-35">{elem.value}</span>
                                <input type="checkbox" className={"custom-control-input chk checkbox"}
                                    id="customControlAutosizing" name={elem.value}
                                    value={elem.key} onChange={(event) => handlePlayTypeSelect(event)} checked={getPlayTypeCheckStatus(elem.key)}></input>
                                <span className="checkmark positionAbsolute checkmarkBox-color displayInlineBlock top0"></span>
                            </label>
                        </div>
                    );
                })
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}

interface GuideComponentProps {
    guideList: any[];
    insightsLbls: InsightLabels;
    selectedGuide: any[];
    selectedGuideData: any
}

export const GetGuideDropdown: React.FC<GuideComponentProps> = ({ guideList, insightsLbls, selectedGuide, selectedGuideData }) => {

    const [guide, setGuide] = useState({
        isOpen: false,
        value: selectedGuide.length == guideList.length ? [] : selectedGuide,
        allSelected: selectedGuide.length == 0 || selectedGuide.length == guideList.length ? true : false
    });

    const toggleGuideDropdown = (event) => {
        setGuide((prevState: any) => {
            return {
                ...prevState,
                isOpen: event
            }
        })
    }

    const guideSetter = (val) => {
        if (val.length == 0) {
            val = guideList.map(guide => guide.key);
        }
        selectedGuideData(val);
    }

    useEffect(() => {
        guideSetter(selectedGuide);
    }, [])

    const handleGuideSelect = (event) => {
        let allSelected = false;
        let value = [];
        if (guide.allSelected) {
            //all selected, disselect one element
            if (!event.target.checked) {
                guideList.forEach((elem) => {
                    if (event.target.value != elem.key) {
                        value.push(elem.key);
                    }
                });
            }
        } else {
            value = [...guide.value];
            let selectedVal = parseInt(event.target.value);
            //all not selected
            if (event.target.checked) {
                if (guide.value.length === guideList.length - 1) {
                    //Case to select all, if manually all is selected
                    if (!value.includes(selectedVal)) {
                        allSelected = true;
                        value = [];
                    }
                } else {
                    //if value don't exist already, add it
                    if (!value.includes(selectedVal)) {
                        value.push(selectedVal);
                    }
                }
            } else {
                //if value exists, remove it
                if (value.includes(selectedVal)) {
                    value = value.filter((e) => e != selectedVal);
                }
            }
        }
        setGuide((prevState: any) => {
            return {
                ...prevState,
                allSelected: allSelected,
                value: value
            }
        })
        guideSetter(value);
    }

    const handleGuideSelectAll = (event) => {
        setGuide((prevState: any) => {
            return {
                ...prevState,
                allSelected: event.target.checked,
                value: []
            }
        })
        guideSetter([]);
    }

    useEffect(() => {
        if (!guide.allSelected && guide.value.length == 0) {
            setGuide((prevState: any) => {
                return {
                    ...prevState,
                    allSelected: true
                }
            })
        }
    }, [guide.isOpen])

    const getSelectedGuideCount = () => guide.allSelected ? guideList.length : guide.value.length;

    //this method is used to find the first key, as values array may be out of order
    const findKey = () => guideList.find(item => guide.value.includes(item.key))?.value;

    // const getSelectedGuideLabel = () => guide.allSelected ? guideList[0].value : findKey();

    const getSelectedGuideLabel = () => {
        const getValue = (list, key) => list.find(pair => pair.key == key)?.value;
        if (!guide.allSelected && guide.value.length == 0) { return ""; }
        const dataList = guide.value.length === 0 ? guideList : guide.value;
        const limit = 3;
        let displayedData = dataList.slice(0, limit).map(item => {
            const value = guide.value.length === 0 ? item.value : getValue(guideList, item);
            const truncatedValue = value.length > 15 ? `${value.slice(0, 15)}...` : value;
            return <span className="chips">{truncatedValue}</span>;
        });
        if (dataList.length > limit) {
            displayedData.push(<span className="chips">...{dataList.length - limit} more</span>);
        }

        return displayedData;
    };

    const getGuideCheckStatus = (key) => guide.allSelected || guide.value.includes(key);

    return (
        <Dropdown className="width-100" show={guide.isOpen} onToggle={(event) => toggleGuideDropdown(event)}>
            <Dropdown.Toggle className={"btn btn-lg btn-outline-primary export-dropdown"}>
                {/* {getSelectedGuideCount() > 1 && (<span className="dropdown-count">+{getSelectedGuideCount() - 1}</span>)} */}
                <span className="dropdown-label">{getSelectedGuideLabel()}</span>
                <img src={require("../../assets/img/caret-down.png")} alt="" />
            </Dropdown.Toggle>
            <Dropdown.Menu className={`dropdown-menu ${guide.isOpen ? 'show' : ''}`}>
                <div className="custom-control custom-checkbox">
                    <label className="check-container alignCenter exportCheck">
                        <span className="marginLeft-35">{insightsLbls.selectAll}</span>
                        <input type="checkbox" className="custom-control-input  chk checkbox"
                            name="chk-box" value="all" onChange={(event) => handleGuideSelectAll(event)} checked={guide.allSelected}></input>
                        <span className="checkmark positionAbsolute checkmarkBox-color disguideInlineBlock top0"></span>
                    </label>
                </div>

                <hr className="marginBottom-0 marginTop-0"></hr>

                {guideList.map((elem: any) => {
                    return (
                        <div className="custom-control custom-checkbox">
                            <label className="check-container alignCenter exportCheck">
                                <span className="marginLeft-35">{elem.value}</span>
                                <input type="checkbox" className={"custom-control-input chk checkbox"}
                                    id="customControlAutosizing" name={elem.value}
                                    value={elem.key} onChange={(event) => handleGuideSelect(event)} checked={getGuideCheckStatus(elem.key)}></input>
                                <span className="checkmark positionAbsolute checkmarkBox-color disguideInlineBlock top0"></span>
                            </label>
                        </div>
                    );
                })
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}

interface TooltipComponentProps {
    tooltipList: any[];
    insightsLbls: InsightLabels;
    selectedTooltip: any[];
    selectedTooltipData: any
}

export const GetTooltipDropdown: React.FC<TooltipComponentProps> = ({ tooltipList, insightsLbls, selectedTooltip, selectedTooltipData }) => {

    const [tooltip, setTooltip] = useState({
        isOpen: false,
        value: selectedTooltip.length == tooltipList.length ? [] : selectedTooltip,
        allSelected: selectedTooltip.length == 0 || selectedTooltip.length == tooltipList.length ? true : false
    });

    const toggleTooltipDropdown = (event) => {
        setTooltip((prevState: any) => {
            return {
                ...prevState,
                isOpen: event
            }
        })
    }

    const tooltipSetter = (val) => {
        if (val.length == 0) {
            val = tooltipList.map(tooltip => tooltip.key);
        }
        selectedTooltipData(val);
    }

    useEffect(() => {
        tooltipSetter(selectedTooltip);
    }, [])

    const handleTooltipSelect = (event) => {
        let allSelected = false;
        let value = [];
        if (tooltip.allSelected) {
            //all selected, disselect one element
            if (!event.target.checked) {
                tooltipList.forEach((elem) => {
                    if (event.target.value != elem.key) {
                        value.push(elem.key);
                    }
                });
            }
        } else {
            value = [...tooltip.value];
            let selectedVal = event.target.value;
            //all not selected
            if (event.target.checked) {
                if (tooltip.value.length === tooltipList.length - 1) {
                    //Case to select all, if manually all is selected
                    if (!value.includes(selectedVal)) {
                        allSelected = true;
                        value = [];
                    }
                } else {
                    //if value don't exist already, add it
                    if (!value.includes(selectedVal)) {
                        value.push(selectedVal);
                    }
                }
            } else {
                //if value exists, remove it
                if (value.includes(selectedVal)) {
                    value = value.filter((e) => e != selectedVal);
                }
            }
        }
        setTooltip((prevState: any) => {
            return {
                ...prevState,
                allSelected: allSelected,
                value: value
            }
        })
        tooltipSetter(value);
    }

    const handleTooltipSelectAll = (event) => {
        setTooltip((prevState: any) => {
            return {
                ...prevState,
                allSelected: event.target.checked,
                value: []
            }
        })
        tooltipSetter([]);
    }

    useEffect(() => {
        if (!tooltip.allSelected && tooltip.value.length == 0) {
            setTooltip((prevState: any) => {
                return {
                    ...prevState,
                    allSelected: true
                }
            })
        }
    }, [tooltip.isOpen])

    const getSelectedTooltipCount = () => tooltip.allSelected ? tooltipList.length : tooltip.value.length;

    //this method is used to find the first key, as values array may be out of order
    const findKey = () => tooltipList.find(item => tooltip.value.includes(item.key))?.value;

    // const getSelectedTooltipLabel = () => tooltip.allSelected ? tooltipList[0].value : findKey();

    const getSelectedTooltipLabel = () => {
        const getValue = (list, key) => list.find(pair => pair.key == key)?.value;
        if (!tooltip.allSelected && tooltip.value.length == 0) { return ""; }
        const dataList = tooltip.value.length === 0 ? tooltipList : tooltip.value;
        const limit = 3;
        let displayedData = dataList.slice(0, limit).map(item => {
            const value = tooltip.value.length === 0 ? item.value : getValue(tooltipList, item);
            const truncatedValue = value.length > 15 ? `${value.slice(0, 15)}...` : value;
            return <span className="chips">{truncatedValue}</span>;
        });
        if (dataList.length > limit) {
            displayedData.push(<span className="chips">...{dataList.length - limit} more</span>);
        }

        return displayedData;
    };

    const getTooltipCheckStatus = (key) => tooltip.allSelected || tooltip.value.includes(key);

    return (
        <Dropdown className="width-100" show={tooltip.isOpen} onToggle={(event) => toggleTooltipDropdown(event)}>
            <Dropdown.Toggle className={"btn btn-lg btn-outline-primary export-dropdown"}>
                {/* {getSelectedTooltipCount() > 1 && (<span className="dropdown-count">+{getSelectedTooltipCount() - 1}</span>)} */}
                <span className="dropdown-label">{getSelectedTooltipLabel()}</span>
                <img src={require("../../assets/img/caret-down.png")} alt="" />
            </Dropdown.Toggle>
            <Dropdown.Menu className={`dropdown-menu ${tooltip.isOpen ? 'show' : ''}`}>
                <div className="custom-control custom-checkbox">
                    <label className="check-container alignCenter exportCheck">
                        <span className="marginLeft-35">{insightsLbls.selectAll}</span>
                        <input type="checkbox" className="custom-control-input  chk checkbox"
                            name="chk-box" value="all" onChange={(event) => handleTooltipSelectAll(event)} checked={tooltip.allSelected}></input>
                        <span className="checkmark positionAbsolute checkmarkBox-color distooltipInlineBlock top0"></span>
                    </label>
                </div>

                <hr className="marginBottom-0 marginTop-0"></hr>

                {tooltipList.map((elem: any) => {
                    return (
                        <div className="custom-control custom-checkbox">
                            <label className="check-container alignCenter exportCheck">
                                <span className="marginLeft-35">{elem.value}</span>
                                <input type="checkbox" className={"custom-control-input chk checkbox"}
                                    id="customControlAutosizing" name={elem.value}
                                    value={elem.key} onChange={(event) => handleTooltipSelect(event)} checked={getTooltipCheckStatus(elem.key)}></input>
                                <span className="checkmark positionAbsolute checkmarkBox-color distooltipInlineBlock top0"></span>
                            </label>
                        </div>
                    );
                })
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}

