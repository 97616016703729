import * as actionTypes from '../../Actions/ActionTypes';

export interface TooltipState {
    tooltipActivityChart: any,
    powerformChart:any,
    tooltipList:any,
    powerformSummary:any,
    smartTipSummary: any,
}

const initialState = {
    tooltipActivityChart: [],
    powerformChart:[],
    tooltipList:[],
    powerformSummary:[],
    smartTipSummary:[]
};


const tooltipReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SET_TOOLTIP_ACTIVITY_CHART:
            return {
                ...state,
                tooltipActivityChart: action.tooltipActivityChart,
            };

        case actionTypes.SET_POWER_FORM_CHART:
            return {
                ...state,
                powerformChart: action.powerformChart,
            };
        
        case actionTypes.SET_TOOLTIP_LIST:
            return {
                ...state,
                tooltipList: action.tooltipList,
            };

        case actionTypes.SET_POWER_FORM_SUMMARY:
            return {
                ...state,
                powerformSummary: action.powerformSummary,
            };

        case actionTypes.SET_SMART_TIP_SUMMARY:
            return {
                ...state,
                smartTipSummary: action.smartTipSummary,
            };

        default:
            return state;
    }
};

export default tooltipReducer;