import * as actionTypes from '../../Actions/ActionTypes';

export interface FeatureDetailState {
    featureDetailStatsChartData: any,
    userActivity: any,
    userVisit: any,
    pageLoadTime: any,
    userSystemData: any,
    pageTag: any,
    featureListData: any,
    userEngagementStatsChartData: any,
    userEngagementStatsSummaryData: any,
    userEngagementFeatureDetailChartData: any
}

const initialState: FeatureDetailState = {
    featureDetailStatsChartData: [],
    userActivity: [],
    userVisit: [],
    pageLoadTime: [],
    userSystemData: [],
    pageTag: [],
    featureListData: [],
    userEngagementStatsChartData: [],
    userEngagementStatsSummaryData: [],
    userEngagementFeatureDetailChartData:[]
};
const featureDetailReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SET_FEATURE_DETAIL_STATS_CHART:
            return {
                ...state,
                featureDetailStatsChartData: action.featureStatsChartData,
            };
        case actionTypes.SET_FEATURE_DETAIL_HEATMAP:
            return {
                ...state,
                userVisit: action.userVisit,
            };
        case actionTypes.USAGE_STATS:
            return {
                ...state,
                userSystemData: action.userSystemData,
            };

        case actionTypes.SET_FEATURE_LIST:
            return {
                ...state,
                featureListData: action.featureListData,
            }; 

        case actionTypes.SET_USER_ENGAGEMENT_STATS_CHART:
            return {
                ...state,
                userEngagementStatsChartData: action.userEngagementStatsChartData,
            };
            
        case actionTypes.SET_USER_ENGAGEMENT_STATS_SUMMARY:            
            return {
                ...state,
                userEngagementStatsSummaryData: action.userEngagementStatsSummaryData,
            };

        case actionTypes.SET_USER_ENGAGEMENT_FEATURE_DETAIL_STATS:
            return {
                ...state,
                userEngagementFeatureDetailChartData: action.userEngagementFeatureDetailChartData,
            };
        default:
            return state;
    }
}

export default featureDetailReducer;