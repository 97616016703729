// @author: Mrunal Mane
// modified on: 2023-05-23
// Description: Removed language dependany from getOrgList() call.


import React, { useState, useRef, useEffect, ReactElement } from "react";
import { Row, Col, Dropdown, Modal, Form, FormGroup, FormControl } from "react-bootstrap";
import withRouter from "hoc/withRouter";



import { getCall } from "../../utils/ApiCallActions";

import { useLocation, useNavigate } from "react-router-dom";
import { OverlayTrigger } from "react-bootstrap";
import { Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";

import { useSelector, useDispatch } from "react-redux";
import { postCall, putCall, getAssimaURLCall } from "../../utils/ApiCallActions";
import { AppsState, VisibilityState, HandoffState, LanguageState } from "../../store/Reducers";
import axios from "axios";
import axiosInstance from "../../network/apis/interceptor";


import { CommonUtils } from "../../utils/CommonUtils";
import { ApiRelativePaths } from "../../utils/GlobalConstants";
import { RetryApi } from "utils/GlobalConstants";

import AppSwitcher from "../AppSwitcher/AppSwitcher";
import OrgSwitcher from "../OrgSwitcher/OrgSwitcher";

// import mainLogo_1 from "assets/js/images/mainLogo_1";
// import mainLogo_2 from "assets/js/images/mainLogo_2";
import newLogo from "assets/js/images/newLogo";

import LanguageFilter from "../LanguageFilter/LanguageFilter";
import { setLanguage } from "../../store/Actions";

import "../../style.scss";
import "./Header.scss";

import { useErrorBoundary } from 'react-error-boundary';
import { InsightLabels } from "labels";
import { Placement } from "react-bootstrap/esm/types";
import Icon from "components/Icon/Icon";
interface headerState {
    orgFlag_val: boolean;
}

interface CurrentState {
    apps: AppsState;
    sectionVisibility: VisibilityState;
    handoff: HandoffState;
    languageData: LanguageState
}

const Header = (props: any): ReactElement => {
    const { showBoundary } = useErrorBoundary();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);



    let cancelToken: any;
    // const userId = localStorage.getItem('USER_ID');

    const adminRedirectLink = process.env.REACT_APP_ADMIN_URL;
    const environment = process.env.REACT_APP_ENV;
    let userProfileAssima = localStorage.getItem("USER_PROFILE")! + localStorage.getItem('USER_SIGN')!;
    let orgImgUrlAssima = localStorage.getItem('ORG_IMG_URL')! + localStorage.getItem('USER_SIGN')!;
    let orgLogoAssima = localStorage.getItem('ORG_IMG_URL')! + localStorage.getItem('USER_SIGN')!;
    let cdnSig = localStorage.getItem('USER_SIGN');

    let org_logo_img = '';
    let user_full_name =
        localStorage.getItem("USER_FNAME") +
        " " +
        localStorage.getItem("USER_LNAME");
    let user_fname = localStorage.getItem("USER_FNAME");
    let email_id = localStorage.getItem("EMAIL_ID");
    let org_name = localStorage.getItem("ORG_NAME");
    let role = localStorage.getItem("USER_ROLE");
    let userProfile = localStorage.getItem("USER_PROFILE");



    let orgSwitcher;

    let initial = "";

    if (user_fname != undefined) {
        initial = user_fname.charAt(0);
    }

    const [imgError_flag, setImgError_flag] = useState(false)
    // const [app_img, setApp_img] = useState('');

    const [appLogoFetch, setAppLogoFetch] = useState({
        flag: true,
        app_img: ''
    });
    const [orgList, setOrgList] = useState({
        dataFetched: false,
        totalCount: 0
    });




    const [dropDownToggle, setDropDownToggle] = useState({
        state: false
    });

    const initial_char = useRef<HTMLDivElement>(null);
    const initial_char1 = useRef<HTMLDivElement>(null);

    let items: any = {
        a: "#AF9595",
        b: "#EBADAD",
        c: "#FE9469",
        d: "#FFA477",
        e: "#FFA54D",
        f: "#ECC084",
        g: "#C5FF6C",
        h: "#A1C87A",
        i: "#8DE5BA",
        j: "#4E9FFE",
        k: "#D18CFF",
        l: "#FF4DFF",
        m: "#C0FF00",
        n: "#00FF7F",
        o: "#E9967A",
        p: "#FFE5B4",
        q: "#FF66CC",
        r: "#F88379",
        s: "#FBEC5D",
        t: "#FAD6A5",
        u: "#99CCFF",
        v: "#FFFFCC",
        w: "#FFFF99",
        x: "#E0E0E0",
        y: "#A5BBCA",
        z: "#BEA5CA",
    };

    const styleObject = (initials: any) => {
        try {
            if (initial_char.current) {
                if (
                    userProfile == undefined ||
                    userProfile == null ||
                    userProfile == ""
                ) {
                    initial_char.current.style.backgroundColor = items[initials];
                } else {
                    initial_char.current.style.backgroundColor = "#c4e5f2";
                }
            }

            if (initial_char1.current) {
                if (
                    userProfile == undefined ||
                    userProfile == null ||
                    userProfile == ""
                ) {
                    initial_char1.current.style.backgroundColor = items[initials];
                } else {
                    initial_char1.current.style.backgroundColor = "#c4e5f2";
                }
            }
        } catch (error) {
            showBoundary(error)
        }
    };


    styleObject(initial.toLowerCase());

    let fetched_details = useSelector((state: CurrentState) => state);

    let appName = fetched_details.apps.appName;

    // let superAdmin_flag = fetched_details.sectionVisibility.superAdmin;
    let appImg = org_logo_img;

    const [orgFlag, setOrgFlag] = useState<headerState>({
        orgFlag_val: false,
    });

    const orgBgImage = useRef<HTMLDivElement>(null);
    const orgLogoDivider = useRef<HTMLDivElement>(null);

    const [ModFlag, setModFlag] = useState({
        show: false,
    });

    const openModal = () => {
        setModFlag({
            show: true,
        });
    };

    const closeModal = () => {
        setModFlag({
            show: false,
        });
    };


    const updateCdnSignature = () => {
        try {
            let path = ApiRelativePaths.GET_SIGNATURE;
            let host = process.env.REACT_APP_SERVER_API_ENDPOINT;

            let generatedURL =
                host + path + "?organization_id=" + localStorage.getItem("ORG_ID");
            axiosInstance.get(generatedURL).then((res) => {
                if (res !== undefined) {
                    cdnSig = res?.data?.data?.cdn_signature;
                    localStorage.setItem("USER_SIGN", res?.data?.data?.cdn_signature);
                    setTimeout(() => {
                        if (orgBgImage.current) {
                            orgBgImage.current.style.backgroundImage =
                                "url(" +
                                localStorage.getItem("ORG_IMG_URL") +
                                localStorage.getItem("USER_SIGN") +
                                ")";
                            orgBgImage.current.style.backgroundSize = "100%";
                        }
                    }, 10);
                }
            });
        } catch (error) {
            showBoundary(error)
        }

    };


    // const updateCdnSignatureAssima = (imgUrl: string, type: string) => {
    //     try {
    //         const domainVal = process.env.REACT_APP_ANALYTICS_HOST!;

    //         if (!imgUrl.includes(domainVal) && !imgUrl.includes('data:image/png')) {

    //             if (imgUrl.includes('X-Amz-Algorithm')) {
    //                 imgUrl = imgUrl.split('?X-Amz-Algorithm')[0];
    //             }

    //             getAssimaURLCall(imgUrl, type).then((data: any) => {
    //                 if (data) {
    //                     if (data.result === 'success') {
    //                         if (data.type === 'userProfile') {
    //                             localStorage.setItem('USER_PROFILE', data.data.url)
    //                         } else if (data.type === 'orgImg') {
    //                             localStorage.setItem('ORG_IMG_URL', data.data.url);
    //                         } else if (data.type === 'appImg') {
    //                             setApp_img(data?.data?.url);
    //                             setAppLogoFetch(true)
    //                         }
    //                     } else {

    //                     }
    //                 }
    //             })
    //         } else {
    //             setApp_img(imgUrl);
    //         }
    //     } catch (error) {
    //         showBoundary(error)
    //     }

    // };

    const getOrgList = (apiErrorFlag?: string) => {
        try {
            if (typeof cancelToken !== typeof undefined) {
                cancelToken.cancel(insightsLbls?.tokenCancelledMsg);
            }

            cancelToken = axios.CancelToken.source();

            let params = {
                'page_index': 1,
                'page_size': 99999,
                'search_text': ''
            };

            getCall(params, 'ORGANIZATION_LIST', apiErrorFlag ?? '').then((data: any) => {
                if (data.result === 'success') {
                    if (data.data !== undefined) {
                        setOrgList((prevState: any) => {
                            return {
                                ...prevState,
                                dataFetched: true,
                                totalCount: data.data.totalCount
                            }
                        })
                    }
                } else if (data.result === 'retry') {
                    setTimeout(() => {
                        getOrgList('retry')
                    }, RetryApi.TIMEOUT)
                } else {
                    setOrgList((prevState: any) => {
                        return {
                            ...prevState,
                            dataFetched: false,
                            totalCount: 0
                        }
                    })
                }
            });
        } catch (error) {
            showBoundary(error)
        }

    }

    useEffect(() => {
        try {
            if (fetched_details.languageData.languageData) {
                setDropDownToggle((prevState: any) => {
                    return {
                        ...prevState,
                        state: false
                    }
                })
                setInsightsLbls(fetched_details.languageData.languageData);
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        getOrgList();
    }, [])

    useEffect(() => {
        try {
            if (fetched_details.handoff['setHandoff'] === false && (localStorage.getItem('USER_SIGN') !== null || localStorage.getItem('USER_SIGN') !== undefined)) {
                setAppDetails();
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [fetched_details.apps, fetched_details.handoff]);


    const setAppDetails = () => {
        if (fetched_details.apps.appImg === "" || fetched_details.apps.appImg === null || fetched_details.apps.appImg === undefined) {
            setAppLogoFetch((prevState: any) => {
                return {
                    ...prevState,
                    flag: false,
                    app_img: <AppImg></AppImg>
                }
            });
        } else {
            let tempImage = <img
                src={fetched_details.apps.appImg}
                alt={appName}
                className="myIntel_logo1"
                onError={(e) => urlError(e, 2)}
            />
            setAppLogoFetch((prevState: any) => {
                return {
                    ...prevState,
                    flag: true,
                    app_img: tempImage
                }
            });
        }
    }

    setInterval(() => {
        if (localStorage.getItem("SECONDSFLAG") == "true") {
            updateCdnSignature();

        }
    }, 3600000);

    let borderStyle;

    const HeaderTooltip = () => {
        let tooltipText = insightsLbls?.dataTooltiptext7Days;
        let placement: Placement = "right";
        const tooltip = (
            <Tooltip id="tooltip">
                <br></br>
                <div>
                    <span className="">{tooltipText}</span>
                </div>
                <br></br>
            </Tooltip>
        );
        return (
            <div className={`orgPriorityProcessing ${process.env.REACT_APP_ENV === 'prod' ? 'top0 positionRelative' : ' top5 positionRelative'}`}>
                <OverlayTrigger placement={placement} overlay={tooltip}>
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                    {/* <i className="fa fa-info-circle info-icon-color marginLeft-10" > </i> */}
                </OverlayTrigger>
            </div>
        );
    };

    const LoginForm = () => {
        const [state, setStateValues] = useState({
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            errors: {
                currentPassword: " ",
                newPassword: " ",
                confirmPassword: " ",
            },
            touched: {
                currentPassword: false,
                newPassword: false,
                confirmPassword: false,
            },
        });
        const [initialState, setInitialStateValues] = useState({
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            errors: {
                currentPassword: " ",
                newPassword: " ",
                confirmPassword: " ",
            },
            touched: {
                currentPassword: false,
                newPassword: false,
                confirmPassword: false,
            },
        });

        const [errorMsg, setErrorMsg] = useState({
            errorMsg_flag: false,
            errorMessage: "",
        });

        const [currentErrorMsg, setCurrentErrorMsg] = useState({
            currentErrorMsg_flag: false,
            currentErrorMessage: "",
        });

        const [touchedNoErrors, setTouchedNoErrors] = useState({
            touchedNoErrors_flag: true,
        });

        const [confirm, setConfirm] = useState({
            confirm_flag: false,
        });

        const [saveDisabled, setSaveDisabled] = useState({
            saveDisabled_flag: true,
        });


        const validateForm = (errors: any, touched: any) => {
            let errorsv = false;
            let touchedv = false;
            const isEmpty = (val: any) => val.length == 0;

            errorsv = Object.values(errors).every(isEmpty);

            touchedv = Object.values(touched).every((x) => x === true);

            if (errorsv === true && touchedv === true) {
                setTouchedNoErrors((prevState) => {
                    return {
                        ...prevState,
                        touchedNoErrors_flag: true,
                    };
                });
                return true;
            } else {
                setTouchedNoErrors((prevState) => {
                    return {
                        ...prevState,
                        touchedNoErrors_flag: false,
                    };
                });
                return false;
            }
        };


        const NewConfirm = () => {
            if (
                state.newPassword.length !== 0 &&
                state.confirmPassword.length !== 0 &&
                state.newPassword === state.confirmPassword
            ) {
                // setConfirm({ confirm_flag: true });
                setConfirm((prevState) => {
                    return {
                        ...prevState,
                        confirm_flag: true,
                    };
                });
                return true;
            } else {
                // setConfirm({ confirm_flag: false });
                setConfirm((prevState) => {
                    return {
                        ...prevState,
                        confirm_flag: false,
                    };
                });
            }
            return false;
        };

        useEffect(() => {
            if (validateForm(state.errors, state.touched) && NewConfirm()) {
                setSaveDisabled((prevState) => {
                    return {
                        ...prevState,
                        saveDisabled_flag: false,
                    };
                });
            } else {
                setSaveDisabled((prevState) => {
                    return {
                        ...prevState,
                        saveDisabled_flag: true,
                    };
                });
            }
        }, [
            state.currentPassword,
            state.newPassword,
            state.confirmPassword,
            // touchedNoErrors.touchedNoErrors_flag,
            // confirm.confirm_flag,
            // saveDisabled.saveDisabled_flag,
            // errorMsg.errorMsg_flag,
        ]);

        const handleChange = (e: any) => {
            e.preventDefault();
            const { name, value } = e.target;
            let errors = state.errors;
            let regex_upperCase = /^(.*[A-Z].*)$/;
            let regex_lowerCase = /^(.*[a-z].*)$/;
            let regex_numCase = /^(.*[0-9].*)$/;
            let regex_specialChar = /[!@#$%^&*(),.?":{}|<>]/;

            switch (name) {
                case "currentPassword":
                    setStateValues((prevState) => {
                        return {
                            ...prevState,
                            currentPassword: value,
                        };
                    });
                    errors.currentPassword =
                        value.length < 8
                            ? insightsLbls?.password8Char
                            : regex_upperCase.test(value)
                                ? regex_lowerCase.test(value)
                                    ? regex_numCase.test(value)
                                        ? regex_specialChar.test(value)
                                            ? ""
                                            : insightsLbls?.password1SpecialChar
                                        : insightsLbls?.password1Numeric
                                    : insightsLbls?.passwordLowerCase
                                : insightsLbls?.passwordUpperCase;
                    // setStateValues({ ...state, currentPassword: name });
                    // errors.currentPassword =
                    //   errorMsg.errorMsg_flag ? errorMsg.errorMessage: "";
                    break;
                case "newPassword":
                    // setStateValues({ ...state, newPassword: name });
                    setStateValues((prevState) => {
                        return {
                            ...prevState,
                            newPassword: value,
                        };
                    });
                    errors.newPassword =
                        value.length < 8
                            ? insightsLbls?.newPassword8Char
                            : regex_upperCase.test(value)
                                ? regex_lowerCase.test(value)
                                    ? regex_numCase.test(value)
                                        ? regex_specialChar.test(value)
                                            ? ""
                                            : insightsLbls?.password1SpecialChar
                                        : insightsLbls?.password1Numeric
                                    : insightsLbls?.passwordLowerCase
                                : insightsLbls?.passwordUpperCase;


                    errors.confirmPassword =
                        (value !== state.confirmPassword && state.confirmPassword !== "")
                            ? insightsLbls?.passwordConfirmationField
                            : ""
                    break;
                case "confirmPassword":
                    // setStateValues({ ...state, confirmPassword: name });
                    setStateValues((prevState) => {
                        return {
                            ...prevState,
                            confirmPassword: value,
                        };
                    });
                    errors.confirmPassword =
                        value.length < 8
                            ? insightsLbls?.password8Char
                            : regex_upperCase.test(value)
                                ? regex_lowerCase.test(value)
                                    ? regex_numCase.test(value)
                                        ? regex_specialChar.test(value)
                                            ? value === state.newPassword
                                                ? ""
                                                : insightsLbls?.passwordConfirmationField
                                            : insightsLbls?.password1SpecialChar
                                        : insightsLbls?.password1Numeric
                                    : insightsLbls?.passwordLowerCase
                                : insightsLbls?.passwordUpperCase;
                    break;
                default:
                    break;
            }

            // setStateValues({ ...state, errors, [name]: value });
            setStateValues((prevState) => {
                return {
                    ...prevState,
                    errors,
                    [name]: value,
                };
            });
            //  isValid();
        };

        const handleTouch = (e: any) => {
            e.preventDefault();

            let name = e.target.name;

            let touched: any = state.touched;

            if (name && touched[name] !== true) {
                touched[name] = true;
                // setStateValues({ ...state, touched, [name]: true });
                setStateValues((prevState) => {
                    return {
                        ...prevState,
                        touched,
                    };
                });
            }
            // isValid(state, touchedNoErrors, confirm);
        };

        const onSubmit = (e: any) => {
            e.preventDefault();
            const data = {
                user_id: "2713",
                new_password: state.newPassword,
                existing_password: state.currentPassword,
            };

            let path = ApiRelativePaths.CHANGE_PASSWORD;
            let params = {};
            let paramObj = {};
            let a = CommonUtils.URLGenerator(params, path, paramObj);

            putCall(data, "CHANGE_PASSWORD", "").then((data: any) => {
                if (data.result == "success") {
                    if (data.data.data.error) {
                        if (data.data.data.code === 1021 || data.data.data.code === 500) {
                            // setCurrentErrorMsg({ currentErrorMsg_flag: true, currentErrorMessage: data.data.data.message[0] });
                            setCurrentErrorMsg((prevState) => {
                                return {
                                    ...prevState,
                                    currentErrorMsg_flag: true,
                                    currentErrorMessage: data.data.data.message[0],
                                };
                            });
                        } else {
                            setErrorMsg((prevState) => {
                                return {
                                    ...prevState,
                                    errorMessage: data.data.data.message[0],
                                };
                            });
                            setStateValues({ ...initialState });
                        }
                    } else {
                        setErrorMsg((prevState) => {
                            return {
                                ...prevState,
                                errorMsg_flag: false,
                                errorMessage: "",
                            };
                        });
                        // setErrorMsg({ errorMsg_flag: false, errorMessage: "" });
                        // setCurrentErrorMsg({ currentErrorMsg_flag: false, currentErrorMessage: "" });
                        setCurrentErrorMsg((prevState) => {
                            return {
                                ...prevState,
                                currentErrorMsg_flag: false,
                                currentErrorMessage: "",
                            };
                        });
                        closeModal();
                        toast.success(insightsLbls?.passwordChanged, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }
            });
        };



        return (
            <div>
                <Form
                    className="font14 paddingLeft-35 paddingR-60"
                    onSubmit={onSubmit}
                    noValidate
                >
                    {/* onSubmit={onSubmit} */}
                    <div className="requiredErrorMsg errorMsg">
                        {errorMsg.errorMessage}
                    </div>
                    <FormGroup className="marginTop12" controlId="formCurrentPassword">
                        <div className="tagModalText floatLeft">
                            {insightsLbls?.curPassword}
                            <span className="requiredStar12">*</span>
                        </div>
                        <FormControl
                            name="currentPassword"
                            type="password"
                            placeholder={insightsLbls?.curPassword}
                            value={state.currentPassword}
                            onInput={handleChange}
                            onFocus={handleTouch}
                            maxLength={250}
                            autoComplete="off"
                        // (e) => setCurrentPassword(e.target.value)
                        />
                    </FormGroup>
                    <div className="errorContainer">
                        <div className="requiredErrorMsg">
                            {state.errors.currentPassword.length > 0 && (
                                <span className="error">{state.errors.currentPassword}</span>
                            )}
                        </div>
                    </div>
                    <FormGroup className="marginTop12" controlId="formNewPassword">
                        <div className="tagModalText floatLeft">
                            {insightsLbls?.newPassword}
                            <span className="requiredStar12">*</span>
                        </div>
                        <FormControl
                            name="newPassword"
                            type="password"
                            placeholder={insightsLbls?.curPassword}
                            value={state.newPassword}
                            onInput={handleChange}
                            onFocus={handleTouch}
                            maxLength={250}
                            autoComplete="off"
                        // onInput={(e) => setNewPassword(e.target.value)}
                        />
                    </FormGroup>
                    <div className="errorContainer">
                        <div className="requiredErrorMsg">
                            {state.errors.newPassword.length > 0 && (
                                <span className="error">{state.errors.newPassword}</span>
                            )}
                        </div>
                    </div>
                    <FormGroup className="marginTop12" controlId="formConfirmPassword">
                        <div className="tagModalText floatLeft">
                            {insightsLbls?.confirmPassword}
                            <span className="requiredStar12">*</span>
                        </div>
                        <FormControl
                            name="confirmPassword"
                            type="password"
                            placeholder={insightsLbls?.curPassword}
                            value={state.confirmPassword}
                            onInput={handleChange}
                            onFocus={handleTouch}
                            maxLength={250}
                            autoComplete="off"
                        // disabled={state.errors.newPassword?true:false}
                        // onInput={(e) => setConfirmPassword(e.target.value)}
                        />
                    </FormGroup>
                    <div className="errorContainer">
                        <div className="requiredErrorMsg">
                            {state.errors.confirmPassword.length > 0 && (
                                <span className="error">{state.errors.confirmPassword}</span>
                            )}
                        </div>
                    </div>
                    <div className="marginTop12"></div>
                </Form>
                <Modal.Footer>
                    <button
                        disabled={saveDisabled.saveDisabled_flag}
                        type="submit"
                        onClick={onSubmit}
                        className="modal-save-btn marginRight-10"
                    >
                        {insightsLbls?.save}
                    </button>
                    <button
                        className="modal-cancel-btn marginRight-10"
                        onClick={closeModal}
                        value="submit"
                    >
                        {insightsLbls?.cancel}
                    </button>
                </Modal.Footer>
            </div>
        );
    };

    // if (
    //     location.pathname.includes("dashboard") &&
    //     location.pathname.includes("export-list") &&
    //     location.pathname.includes("/org-view") &&
    //     location.pathname.includes("/control-center") &&
    //     location.pathname.includes("/org-detail")
    // ) {
    //     // disable app switcher
    //     borderStyle = "disabledBorder";
    // } else {
    //     borderStyle = "enabledBorder";
    // }

    if (
        location.pathname.includes("dashboard") ||
        location.pathname.includes("/org-view") ||
        location.pathname.includes("export-list") ||
        location.pathname.includes("/control-center") ||
        location.pathname.includes("chatbot/manage") ||
        location.pathname.includes("detail")
    ) {
        // disable app switcher
        borderStyle = "disabledBorder";
    } else {
        borderStyle = "enabledBorder";
    }

    useEffect(() => {
        setTimeout(() => {
            if (orgBgImage.current) {
                orgBgImage.current.style.backgroundImage =
                    "url(" +
                    localStorage.getItem("ORG_IMG_URL") +
                    localStorage.getItem("USER_SIGN") +
                    ")";
                orgBgImage.current.style.backgroundSize = "100%";
            }
        }, 1000);
    }, [fetched_details]);

    const logout = () => {
        try {
            postCall({ 'refreshToken': localStorage.getItem('REFRESH_TOKEN') }, "LOGOUT").then((data: any) => {
                if (data.result === "success") {
                    localStorage.setItem("IS_AUTHENTICATED", "false");
                    CommonUtils.clearLocalStorage([]);
                    CommonUtils.clearCookie();
                    if (process.env.NODE_ENV === 'production') {
                        window.open(process.env.REACT_APP_ADMIN_URL + "?redirectURL=" + btoa('/logout'), "_self");
                    } else {
                        navigate({
                            pathname: '/login/',
                        });
                        window.location.reload();
                    }
                } else if (data.result === "error") {
                    toast.error(insightsLbls?.somethingWentWrong, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    navigate({
                        pathname: '/login/',
                    });
                    window.location.reload();
                } else {
                    localStorage.setItem("IS_AUTHENTICATED", "false");
                    CommonUtils.clearLocalStorage([]);
                    CommonUtils.clearCookie();
                    if (process.env.NODE_ENV === 'production') {
                        window.open(process.env.REACT_APP_ADMIN_URL + "?redirectURL=" + btoa('/logout'), "_self");
                    } else {
                        navigate({
                            pathname: '/login/',
                        });
                        window.location.reload();
                    }
                }
            });
        } catch (error) {
            showBoundary(error)
        }


    };



    const AppImg = () => {
        try {
            let appChar = ''
            if (appName) {
                appChar = appName.charAt(0).toLowerCase();
            }

            return (
                <div style={{ backgroundColor: items[appChar] }} className="initialAppChar">
                    {appChar.toUpperCase()}
                </div>
            )
        } catch (error) {
            showBoundary(error)
        }

    }

    const imgError = () => {
        setImgError_flag(true)
    }

    const urlError = (e: any, option: number) => {
        e.target.src = require("../../assets/images/organization_42x42.png");
    }

    const getRole = () => {
        try {
            switch (role) {
                case 'owner':
                    return insightsLbls?.orgAdmin;
                case 'user':
                    return insightsLbls?.player.charAt(0).toUpperCase() + insightsLbls?.player.slice(1);
                case 'app_admin':
                    return insightsLbls?.appAdmin;
                case 'mg_admin':
                    return insightsLbls?.mgAdmin;
                case 'superadmin':
                    return insightsLbls?.superAdmin;
                case 'creator':
                    return insightsLbls?.creator;
                case 'partner_admin':
                    return insightsLbls?.partnerAdmin;
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const generateAdminHandoffToken = () => {
        try {
            let generatedURL = process.env.REACT_APP_ANALYTICS_HOST + '/accounts/v1/handoff-token/generate/';
            let data = {
                organization_id: localStorage.getItem('ORG_SWITCH_ID')
            }
            axios.post(generatedURL, data, {
                headers: {
                    'AppKey': process.env.REACT_APP_APP_KEY,
                    'Content-Type': 'application/json',
                    'AccessToken': localStorage.getItem('ACCESS_TOKEN'),
                    'RefreshToken': localStorage.getItem('REFRESH_TOKEN'),
                }
            }).then(res => {
                if (res.data.status === 'success') {
                    let data = res.data;
                    window.open(process.env.REACT_APP_ADMIN_URL + "?handoff-token=" + data.data['handoff-token'], "_blank");
                }
            }).catch(err => {
                if (window.location.href.includes('firstRedirect')) {
                    window.open(process.env.REACT_APP_ADMIN_URL + "?handoff-token=", "_blank");
                }
            });
        } catch (error) {
            showBoundary(error)
        }

    }

    const toggleDropDown = (event: boolean) => {
        try {
            setDropDownToggle((prevState: any) => {
                return {
                    ...prevState,
                    state: event
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    };

    const showHelpUrl = () => {
        let role = localStorage.getItem("USER_ROLE");
        if (["mg_admin", "owner", "app_admin"].includes(role)) {
            return true;
        }
        return false;
    }

    const ensureHttps = (url) => {
        if (!/^https?:\/\//i.test(url)) {
            url = 'https://' + url;
        }
        return url;
    }

    const redirectToHelpUrl = () => {
        let helpUrl = localStorage.getItem("ORG_HELP_URL");
        if (helpUrl != undefined && helpUrl != "") {
            return window.open(ensureHttps(helpUrl), "_blank");
        } else {
            return window.open("https://help.csod.com/MyGuide/", "_blank");
        }
    }

    return (
        <div className="header">
            <header className="header-new">
                <div className="header-menu">
                    <Row>
                        <Col
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            className="displayFlex alignCenter padding0"
                        >
                            <div className="width100 displayFlex alignCenter">
                                <div className="logo-img-div">
                                    <a
                                        className={
                                            "headerLogoDiv pointer displayFlex positionRelative " +
                                            `${process.env.REACT_APP_ENV}` + "_icon"
                                        }
                                    >

                                        <img
                                            src={newLogo}
                                            className={
                                                "logoSize myguideLogo paddingLR-0"
                                            }
                                        />
                                    </a>
                                </div>


                                {/* <div className="logoDivider" ref={orgLogoDivider}></div> */}
                                {orgList.dataFetched ?
                                    <>
                                        <div className="orgLogo_Div" style={process.env.REACT_APP_ENV === 'prod' ? { top: '0px' } : { top: '5px' }}>
                                            <div
                                                className={
                                                    "orgLogo_bg logoSize orgLogo new-logo paddingLR-0"
                                                }
                                                ref={orgBgImage}>
                                            </div>
                                            <span className="orgName_span">
                                                {(location?.pathname.includes('organization') ? insightsLbls?.allOrganizations : org_name)}
                                            </span>
                                            {orgList.totalCount > 1 && <OrgSwitcher></OrgSwitcher>}
                                        </div>
                                        <HeaderTooltip></HeaderTooltip>
                                    </>
                                    : <div className="orgLoaderDiv">
                                        <img src={require(`${process.env.REACT_APP_LOADER}`)}
                                            className="loaderImg" />
                                    </div>}
                            </div>

                        </Col>

                        <Col
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            className="displayFlex positionRelative alignCenter"
                        >
                            <div className="user_app_data">
                                <div
                                    style={{ visibility: location.pathname.includes('dashboard') ? 'hidden' : 'visible' }}
                                    className="appApplication_div displayFlex alignCenter"
                                >
                                    <div className="displayFlex alignCenter">

                                        <AppSwitcher></AppSwitcher>
                                        {/* <div
                                            className="selected_app_div borderStyle"
                                        >
                                            <a
                                                className="analyticsLogo_div dropdown-toggle userData_div paddingTop-4"
                                            >
                                                <div className="appLogo">
                                                    {(location?.pathname.includes('dashboard') || location?.pathname.includes('export-list') || location?.pathname.includes('organization') || location?.pathname.includes('control-center') || location.pathname.includes("chatbot/manage"))
                                                        ? (<img
                                                            src={require('../../assets/images/myIntell.png')}
                                                            alt="Edcast"
                                                            className="myIntel_logo1"
                                                            onError={(e) => urlError(e, 1)}
                                                        />)
                                                        : (fetched_details.handoff['setHandoff'] === false) ? 
                                                            appLogoFetch.app_img : <AppImg />
                                                    }
                                                </div>
                                                <span className="appDetails displayGrid">
                                                    <span
                                                        className="analytics_text appName-span">
                                                        {(location?.pathname.includes('dashboard') || location?.pathname.includes('export-list') || location?.pathname.includes('organization') || location?.pathname.includes('control-center') || location.pathname.includes("chatbot/manage")) ? insightsLbls?.allApps : appName}
                                                    </span>
                                                </span>
                                            </a>
                                        </div> */}
                                    </div>
                                </div>



                                <div
                                    className="user_area dropdown float-right displayFlex alignCenter"
                                >
                                    <Dropdown id="dropdown-custom-header" show={dropDownToggle.state} onToggle={(event) => toggleDropDown(event)}>
                                        <Dropdown.Toggle>
                                            <a
                                                className="dropdown-toggle pointer intelligenceDropdown headerDropDown pull-right"
                                                id="userDropdown"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <span className="avatar-wrapper">
                                                    <div className="userImg-div">
                                                        <div
                                                            className="initialsDiv"
                                                            ref={initial_char}
                                                        >
                                                            {imgError_flag ? (initial) : (
                                                                userProfile != undefined &&
                                                                    userProfile != null &&
                                                                    userProfile != "" ? (
                                                                    <img
                                                                        className={
                                                                            "widthProfileImage user-avatar rounded-circle"
                                                                        }
                                                                        src={userProfile + cdnSig}
                                                                        onError={imgError}
                                                                    />
                                                                ) : (initial)
                                                            )}
                                                        </div>
                                                    </div>
                                                </span>
                                                <span className="user_avatar_name textCenter">
                                                    <span>{user_fname}</span>
                                                    <i className="userArrow fa fa-angle-down"></i>
                                                </span>
                                                
                                                {/* <img src={require(`../../assets/img/angle-down.svg`)} /> */}

                                            </a>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="">
                                            {/* <MenuItem eventKey="1"> */}
                                            <div>
                                                <div
                                                    className="user_menu user_menu positionAbsolute"
                                                >
                                                    <div className="switch_org_btn">
                                                        {/* <div className="user_info"> */}
                                                            {/* <div className="displayFlex">
                                                                <div className="initialsDiv marginRight-10"
                                                                    ref={initial_char1}>
                                                                    {imgError_flag ? (initial) : (
                                                                        userProfile != undefined &&
                                                                            userProfile != null &&
                                                                            userProfile != "" ? (
                                                                            <div className="org_logo_div">
                                                                                <img
                                                                                    className="widthProfileImage user-avatar rounded-circle"
                                                                                    src={userProfile + cdnSig}
                                                                                    onError={imgError}
                                                                                />
                                                                            </div>
                                                                        ) : (initial)
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    <p className="userName marginBottom-5 font12  height24 ellipsisTextUserMenu">
                                                                        <span>{user_full_name}</span>
                                                                    </p>
                                                                    <p className="userEmailId_text marginBottom-5  height24 ellipsisTextUserMenu">
                                                                        {email_id}
                                                                    </p>
                                                                </div>
                                                            </div> */}
                                                            {/* <p className="userDetails_text orgName_ext org_name_p marginBottom-5 font12 pb-2"><Row>
                                                                <Col lg="4">
                                                                    <span className="orgText">
                                                                        {insightsLbls?.org}:{" "}
                                                                    </span>{" "}</Col>
                                                                <Col lg="8"><span className="orgName">
                                                                    {org_name}
                                                                </span></Col>
                                                            </Row>
                                                            </p>
                                                            <p className="userDetails_text orgName_ext org_name_p font12 pt-1"><Row>
                                                                <Col lg="4">
                                                                    <span className="orgText">
                                                                        {insightsLbls?.role}:{""}
                                                                    </span>{" "}</Col>
                                                                <Col lg="8"><span className="orgName" >
                                                                    {getRole()}
                                                                </span></Col>
                                                            </Row>
                                                            </p> */}
                                                        {/* </div> */}
                                                        <div className="user_settings">
                                                        <p className="resetPassword_text marginBottom-0 font12">
                                                                {/* <i className="fa fa-power-off"></i>  */}
                                                                {/* <Icon img="img/logout.svg" className="logout_svg" /> */}
                                                                {insightsLbls?.role}: {getRole()}
                                                            </p>
                                                            <p className="adminLink resetPassword_text font12 width100">
                                                                <a href={void (0)} onClick={() => generateAdminHandoffToken()} target="_blank" rel="noopener noreferrer">
                                                                    {/* <img
                                                                        src={
                                                                            require("../../assets/images/admin.png")
                                                                        }
                                                                        alt="Edcast"
                                                                        className="myIntel_logo width26 marginRight-10"
                                                                    /> */}
                                                                    <span
                                                                        className="analytics_links_text"
                                                                    >
                                                                        {insightsLbls?.admin}
                                                                    </span>
                                                                </a>
                                                            </p>
                                                            <p className="font12 width100  language-para">
                                                                <LanguageFilter></LanguageFilter>
                                                            </p>
                                                            <p className="resetPassword_text marginBottom-0 font12" onClick={openModal}>
                                                                {/* <i className="fa fa-key" aria-hidden="true"></i> */}
                                                                {/* <Icon img="img/new-change-password.svg" className="change_password" /> */}
                                                                {insightsLbls?.changePassword}
                                                            </p>
                                                            {showHelpUrl() && <p className="resetPassword_text marginBottom-0 font12" onClick={redirectToHelpUrl}>
                                                                {/* <i className="fa fa-key" aria-hidden="true"></i> */}
                                                                {/* <Icon img="img/help_icon.svg" className="help_url" /> */}
                                                                {insightsLbls?.help}
                                                            </p>}
                                                            <p className="resetPassword_text marginBottom-0 font12" onClick={() => logout()}>
                                                                {/* <i className="fa fa-power-off"></i>  */}
                                                                <Icon img="img/logoutNew.svg" className="logout_svg" />
                                                                {insightsLbls?.logout}
                                                            </p>
                                                        </div>
                                                        <Modal
                                                            show={ModFlag.show}
                                                            onHide={closeModal}
                                                            backdrop="static"
                                                            keyboard={false}
                                                            size={'lg'}
                                                            aria-labelledby="contained-modal-title-vcenter"
                                                        // centered
                                                        >
                                                            <Modal.Header>
                                                                <Modal.Title id="contained-modal-title-vcenter">
                                                                    {insightsLbls?.changePassword}
                                                                </Modal.Title>
                                                                <button
                                                                    type="button"
                                                                    className="close"
                                                                    onClick={closeModal}
                                                                >
                                                                    <span aria-hidden="true">×</span>
                                                                    <span className="sr-only">{insightsLbls?.close}</span>

                                                                </button>
                                                            </Modal.Header>
                                                            <Modal.Body className="padding-20">
                                                                <LoginForm />
                                                            </Modal.Body>
                                                        </Modal>
                                                    </div>
                                                </div>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </Col>


                    </Row>
                </div>
            </header>
        </div>
    );
};

export default React.memo(withRouter(Header));

