import React from "react";

import ReactApexChart from "react-apexcharts";

import { useErrorBoundary } from 'react-error-boundary';

import $ from 'jquery';

const RadialChart = (props) => {
    const { showBoundary } = useErrorBoundary();
    $('pieChart').empty();
    const chartColours = [];
    const gradientChartColours = [];
    const chartKeys = [];
    let ratio = 0;
    let data;

    try {
        if (props.chartData !== undefined) {
            chartColours[0] = props.chartData[0].color;
            gradientChartColours[0] = props.chartData[0].gradientColor
            chartKeys[0] = props.chartData[0].key;
            ratio = props.chartData[0].values;
        }

        ratio = ratio.toFixed(0)
        data =
        {
            series: [ratio],

            options: {
                chart: {
                    height: props.height,
                    width: props.width,
                    type: 'radialBar',
                },

                plotOptions: {
                    radialBar: {
                        startAngle: -135,
                        endAngle: 135,
                        track: {
                            background: '#b6b6be',
                            startAngle: -135,
                            endAngle: 135,
                        },
                        dataLabels: {
                            name: {
                                show: false,
                            },
                            value: {
                                fontSize: '25px',
                                show: true
                            }
                        }
                    }
                },

                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        type: 'horizontal',
                        gradientToColors: gradientChartColours,
                        stops: [0, 100]
                    }
                },

                stroke: {
                    lineCap: 'butt'
                },

                colors: chartColours,

                labels: chartKeys,
            },
        }

    } catch (error) {
        showBoundary(error)
    }

    return (
        <div id="chart" className={props.className}>
            <ReactApexChart options={data.options} series={data.series} type="radialBar" height={290} />
        </div>
    );
};
export default React.memo(RadialChart);