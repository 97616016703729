const menuOptions =
    [{
        title: 'CIO Dashboard',
        isParent: true,
        svg: "dashboard_ic.svg",
        routerLink: "/dashboard/",
        level:1,
        items: [{
            title: 'Dashboard 1',
            isParent: false,
            svg: "custom_dashboard.svg",
            routerLink: "/custom-dashboard/1/",
            level:2,
        }, {
            title: 'Dashboard 2',
            isParent: false,
            svg: "custom_dashboard.svg",
            routerLink: "/custom-dashboard/2/",
            level:2,
        }, {
            title: 'Dashboard 3',
            isParent: false,
            svg: "custom_dashboard.svg",
            routerLink: "/custom-dashboard/3/",
            level:2,
        }, {
            title: 'MyGuide Dashboard',
            isParent: false,
            svg: "dashboard_ic.svg",
            routerLink: "/dashboard/",
            level:2,
        }]
    }, {
        title: 'Guide Insights',
        isParent: true,
        svg: "guideAnalytics.svg",
        routerLink: "/guide-insights/guide-analytics/",
        level: 1,
        items: [{
            title: 'Guide',
            isParent: true,
            svg: "GuideMe.svg",
            routerLink: "/guide-insights/guide-analytics/",
            level: 2,
            items: [{
                title: 'GuideMe',
                isParent: false,
                svg: "GuideMe.svg",
                routerLink: "/guide-insights/guide-analytics/",
                level: 3,
            },
            {
                title: 'ShowMe',
                isParent: false,
                svg: "ShowMe.svg",
                routerLink: "/guide-insights/guide-analytics/",
                level: 3,

            },
            {
                title: 'TestMe',
                isParent: false,
                svg: "TestMe.svg",
                routerLink: "/guide-insights/guide-analytics/",
                level: 3,

            }, {
                title: 'Survey',
                isParent: false,
                svg: "Sentiment.svg",
                routerLink: "/guide-insights/guide-analytics/",
                level: 3,


            }, {
                title: 'Push Notifications',
                isParent: false,
                svg: "push-notification.svg",
                routerLink: "/guide-insights/guide-analytics/",
                level: 3,

            }, {
                title: 'Summary',
                isParent: false,
                svg: "Summary.svg",
                routerLink: "/guide-insights/guide-analytics/",
                level: 3,

            }]
        }, {
            title: 'Tooltips',
            isParent: true,
            svg: "tooltip.svg",
            routerLink: "/guide-insights/tooltips/",
            level: 2,
            items: [{
                title: 'Tooltips & Validations',
                isParent: false,
                svg: "tooltip-validation.svg",
                routerLink: "/guide-insights/tooltips/",
                level: 3,

            }, {
                title: 'Power Form',
                isParent: false,
                svg: "tooltip-power-form.svg",
                routerLink: "/guide-insights/tooltips/",
                level: 3,

            }, {
                title: 'Duct Tape',
                isParent: false,
                svg: "tooltip-html.svg",
                routerLink: "/guide-insights/tooltips/",
                level: 3,

            }]
        }, {
            title: 'User Engagement',
            isParent: false,
            svg: "userEngagement.svg",
            routerLink: "/guide-insights/user-engagement/",
            level: 2,


        }, {
            title: 'Searches',
            isParent: false,
            svg: "searchActivity.svg",
            routerLink: "/guide-insights/search-activities/",
            level: 2,


        }, {
            title: 'Export',
            isParent: false,
            svg: "export.svg",
            routerLink: "/guide-insights/export/",
            level: 2,
        }]
    }, {
        title: 'App Insights',
        isParent: true,
        svg: "intelligence.svg",
        routerLink: "/app-insights/pages/",
        level: 1,
        items: [{
            title: 'Pages',
            isParent: false,
            svg: "page.svg",
            routerLink: "/app-insights/pages/",
            level: 2,
        }, {
            title: 'Worlflows',
            isParent: false,
            svg: "WorkFlow_panel.svg",
            routerLink: "/app-insights/workflows/",
            level: 2,
        }]

    }, {
        title: 'Survey',
        isParent: true,
        svg: "Sentiment.svg",
        routerLink: "/survey/survey/",
        level: 1,

        items: [{
            title: 'Stats',
            isParent: false,
            svg: "stats.svg",
            routerLink: "/survey/survey/",
            level: 2,
        }, {
            title: 'Manage',
            isParent: false,
            svg: "manage.svg",
            routerLink: "/survey/manage-survey/",
            level: 2,
        }]

    }, {
        title: 'Automation Insights',
        isParent: true,
        svg: "qa_automation.svg",
        routerLink: "/automation-insights/guide-automation",
        level: 1,
        items: [{
            title: 'Guide Automation',
            isParent: false,
            svg: "myBot.svg",
            routerLink: "/automation-insights/guide-automation",
            level: 2,

        }, {
            title: 'QA Automation',
            isParent: false,
            svg: "automator.svg",
            routerLink: "/automation-insights/qa-automator",
            level: 2,

        }]
    }, {
        title: 'Organization View',
        isParent: false,
        svg: "organization.svg",
        routerLink: "/organization/org-view/",
        level: 1,

    }]


export default menuOptions;