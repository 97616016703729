import * as actionTypes from '../../Actions/ActionTypes';

export interface ChatBotStatsDetailsState {
    chatBotStatsDetailsChartData: any,
    chatBotStatsDetailsResponseListData: any,
}

const initialState = {
    chatBotStatsDetailsChartData: [],
    chatBotStatsDetailsResponseListData: [],
};


const chatBotStatsDetailsReducer = (state = initialState, action:any) => {
    switch (action.type) {
        case actionTypes.SET_CHATBOT_STATS_DETAILS_CHART_DATA:
            return {
                ...state,
                chatBotStatsDetailsChartData: action.chatbotStatsDetailsChartData,
            };
        case actionTypes.SET_CHATBOT_STATS_DETAILS_RESPONSE_LIST_DATA:
            return {
                ...state,
                chatBotStatsDetailsResponseListData: action.chatbotStatsDetailsResponseListData,
            };
        default:
            return state;
    }
};

export default chatBotStatsDetailsReducer;