import * as actionTypes from '../../Actions/ActionTypes';

export interface UserEngagementDetailState {
    userEngagementDetailSummaryTableData: any,
    userEngagementDetailListData: any,
    userEngagementDetailTimelineData: any,
    userEngagementDetailGuideMeChart: any,
    userEngagementDetailShowMeChart: any,
    userEngagementDetailTestMeChart: any,
    userEngagementDetailDoItForMeChart: any,
    userEngagementDetailTutorialGuidesChart: any,
    userEngagementDetailUserName: any
}

const initialState = {
    userEngagementDetailSummaryTableData: [],
    userEngagementDetailListData: [],
    userEngagementDetailTimelineData: [],
    userEngagementDetailGuideMeChart: [],
    userEngagementDetailShowMeChart: [],
    userEngagementDetailTestMeChart: [],
    userEngagementDetailDoItForMeChart: [],
    userEngagementDetailTutorialGuidesChart: [],
    userEngagementDetailUserName: []
};


const userEngagementDetailReducer = (state = initialState, action:any) => {
    switch (action.type) {
        case actionTypes.SET_USER_ENGAGEMENT_DETAIL_SUMMARY_TABLE_DATA:
            return {
                ...state,
                userEngagementDetailSummaryTableData: action.userEngagementDetailSummaryTableData,
            };

        case actionTypes.SET_USER_ENGAGEMENT_DETAIL_LIST_DATA:
            return {
                ...state,
                userEngagementDetailListData: action.userEngagementDetailListData,
            };

        case actionTypes.SET_USER_ENGAGEMENT_DETAIL_TIMELINE_DATA:
            return {
                ...state,
                userEngagementDetailTimelineData: action.userEngagementDetailTimelineData,
            };

        case actionTypes.SET_USER_ENGAGEMENT_DETAIL_GUIDEME_CHART:
            return {
                ...state,
                userEngagementDetailGuideMeChart: action.userEngagementDetailGuideMeChart,
            };
        
        case actionTypes.SET_USER_ENGAGEMENT_DETAIL_SHOWME_CHART:
            return {
                ...state,
                userEngagementDetailShowMeChart: action.userEngagementDetailShowMeChart,
            };

        case actionTypes.SET_USER_ENGAGEMENT_DETAIL_TESTME_CHART:
            return {
                ...state,
                userEngagementDetailTestMeChart: action.userEngagementDetailTestMeChart,
            };

        case actionTypes.SET_USER_ENGAGEMENT_DETAIL_DO_IT_FOR_ME_CHART:
            return {
                ...state,
                userEngagementDetailDoItForMeChart: action.userEngagementDetailDoItForMeChart,
            };

        case actionTypes.SET_USER_ENGAGEMENT_DETAIL_TUTORIAL_GUIDES_CHART_DATA:
            return {
                ...state,
                userEngagementDetailTutorialGuidesChart: action.userEngagementDetailTutorialGuidesChart,
            };

        case actionTypes.SET_USER_ENGAGEMENT_DETAIL_USER_NAME:
            return {
                ...state,
                userEngagementDetailUserName: action.userEngagementDetailUserName,
            };

        default:
            return state;
    }
};

export default userEngagementDetailReducer;