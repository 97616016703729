import * as actionTypes from '../../Actions/ActionTypes';

export interface GuideAnalyticsState {
    guideAnalyticsGuideMeChartData: any,
    guideAnalyticsShowMeChartData: any,
    guideAnalyticsTestMeChartData: any,
    guideAnalyticsDoItForMeChartData: any,
    guideAnalyticsSurveyChartData: any,
    guideAnalyticsPBChartData: any,
    guideAnalyticsDoItForMePBChartData: any,
    guideAnalyticsTutorialGuidesChartData: any,
    guideAnalyticsSummaryTableData: any,
}

const initialState = {
    guideAnalyticsGuideMeChartData: [],
    guideAnalyticsShowMeChartData: [],
    guideAnalyticsTestMeChartData: [],
    guideAnalyticsDoItForMeChartData: [],
    guideAnalyticsSurveyChartData: [],
    guideAnalyticsPBChartData: [],
    guideAnalyticsDoItForMePBChartData: [],
    guideAnalyticsTutorialGuidesChartData: [],
    guideAnalyticsSummaryTableData: [],
};


const guideAnalyticsReducer = (state = initialState, action:any) => {
    switch (action.type) {
        case actionTypes.SET_GUIDE_ANALYTICS_GUIDEME_CHART_DATA:
            return {
                ...state,
                guideAnalyticsGuideMeChartData: action.guideAnalyticsGuideMeChartData,
            };

        case actionTypes.SET_GUIDE_ANALYTICS_SHOWME_CHART_DATA:
            return {
                ...state,
                guideAnalyticsShowMeChartData: action.guideAnalyticsShowMeChartData,
            };

        case actionTypes.SET_GUIDE_ANALYTICS_TESTME_CHART_DATA:
            return {
                ...state,
                guideAnalyticsTestMeChartData: action.guideAnalyticsTestMeChartData,
            };

        case actionTypes.SET_GUIDE_ANALYTICS_DOITFORME_CHART_DATA:
            return {
                ...state,
                guideAnalyticsDoItForMeChartData: action.guideAnalyticsDoItForMeChartData,
            };

        case actionTypes.SET_GUIDE_ANALYTICS_SURVEY_CHART_DATA:
            return {
                ...state,
                guideAnalyticsSurveyChartData: action.guideAnalyticsSurveyChartData,
            };

        case actionTypes.SET_GUIDE_ANALYTICS_PB_CHART_DATA:
            return {
            ...state,
            guideAnalyticsPBChartData: action.guideAnalyticsPBChartData,
        };

        case actionTypes.SET_GUIDE_ANALYTICS_DOITFORME_PB_CHART_DATA:
            return {
            ...state,
            guideAnalyticsDoItForMePBChartData: action.guideAnalyticsDoItForMePBChartData,
        };

        case actionTypes.SET_GUIDE_ANALYTICS_TUTORIAL_GUIDES_CHART_DATA:
            return {
            ...state,
            guideAnalyticsTutorialGuidesChartData: action.guideAnalyticsTutorialGuidesChartData,
        };

        case actionTypes.SET_GUIDE_ANALYTICS_SUMMARY_TABLE_DATA:
            return {
                ...state,
                guideAnalyticsSummaryTableData: action.guideAnalyticsSummaryTableData,
            };

        default:
            return state;
    }
};

export default guideAnalyticsReducer;