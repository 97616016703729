// @author: Mrunal Mane
// Created on: 2023-05-07 10:35 AM
// Description: Reducer file for Task List component.

// @author: Mohit Tiwari
// Updated on: 2024-05-13 14:05
// Description: graph and guide play chart of Task list detail component reducer added

import * as actionTypes from '../../Actions/ActionTypes';

export interface TaskListState {
    taskListChartData: any,
    taskListSummaryData: any,
    taskListDetailGraphData: any,
    taskListDetailGuidePlayData: any
    taskListDetailUserEngagementSummaryData: any;
}


const initialState = {
    taskListChartData: [],
    taskListSummaryData: [],
    taskListDetailGraphData: [],
    taskListDetailGuidePlayData: [],
    taskListDetailUserEngagementSummaryData: []
};


const taskListReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SET_TASK_LIST_CHART:
            return {
                ...state,
                taskListChartData: action.taskListChartData,
            };

        case actionTypes.SET_TASK_LIST_SUMMARY:
            return {
                ...state,
                taskListSummaryData: action.taskListSummaryData,
            };

        case actionTypes.SET_TASK_LIST_DETAIL_GRAPH:
            return {
                ...state,
                taskListDetailGraphData: action.taskListDetailGraphData,
            };

        case actionTypes.SET_TASK_LIST_DETAIL_GUIDE_PLAY:
            return {
                ...state,
                taskListDetailGuidePlayData: action.taskListDetailGuidePlayData,
            };

        case actionTypes.SET_TASK_LIST_DETAIL_USER_ENGAGEMENT_SUMMARY:
            return {
                ...state,
                taskListDetailUserEngagementSummaryData: action.taskListDetailUserEngagementSummaryData
            }
        default:
            return state;
    }
};

export default taskListReducer;