import * as actionTypes from '../../Actions/ActionTypes';

export interface TutorialGuidesDetailState {
    guideAnalyticsDetailTutorialGuidesChartData: any,
    guideAnalyticsDetailTutorialGuidesTableData: any,
}

const initialState = {
    guideAnalyticsDetailTutorialGuidesChartData: [],
    guideAnalyticsDetailTutorialGuidesTableData: [],
};


const tutorialGuideAnalyticsDetailReducer = (state = initialState, action: any) => {
    switch (action.type) {

        case actionTypes.SET_GUIDE_ANALYTICS_DETAILS_TUTORIAL_GUIDES_CHART_DATA:
            return {
                ...state,
                guideAnalyticsDetailTutorialGuidesChartData: action.guideAnalyticsDetailTutorialGuidesChartData,
            };


        case actionTypes.SET_GUIDE_TUTORIAL_SUMMARY_TABLE_DATA:
            return {
                ...state,
                guideAnalyticsDetailTutorialGuidesTableData: action.guideAnalyticsDetailTutorialGuidesTableData,
            };


        default:
            return state;
    }
};

export default tutorialGuideAnalyticsDetailReducer;