import * as ActionTypes from '../ActionTypes';
import axiosInstance, { getInstance } from '../../../network/apis/interceptor';
import { CommonUtils } from '../../../utils/CommonUtils';
import { ApiRelativePaths, RetryApi } from '../../../utils/GlobalConstants';


let ApiPaths: any = ApiRelativePaths;


export const goalDetailsLineChartData = (goalDetailsLineChartData: any) => {
    return {
        type: ActionTypes.SET_GOAL_DETAILS_LINE_CHART_DATA,
        goalDetailsLineChartData: goalDetailsLineChartData
    }
};


export const setGoalDetailsProgressBarData = (goalDetailsProgressBarData: any) => {
    return {
        type: ActionTypes.SET_GOAL_DETAILS_PROGRESS_BAR_DATA,
        goalDetailsProgressBarData: goalDetailsProgressBarData
    }
};

export const setGoalDetailsWatcherData = (goalDetailsWatcherData: any) => {
    return {
        type: ActionTypes.SET_GOAL_DETAILS_WATCHER_DATA,
        goalDetailsWatcherData: goalDetailsWatcherData
    }
};


export const getGoalDetailsData = (params: any, url_path: any, caseType: any, apiErrorFlag: string, firstTimeload?: boolean) => {
    return new Promise((resolve, reject) => {
        let path = ApiPaths[url_path];
        let data:any = {};
        let paramObj = {};
        let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
        getInstance('goal-details');

        axiosInstance.get(generatedURL)
            .then(res => {
                data = {
                    "result": "success",
                    "data": res.data.data,
                    "firstTimeload": firstTimeload ?? false,
                };
            })
            .catch(err => {
                data = {
                    "result": "error",
                    "data": err,
                    "errorCode":err?.response?.status ?? '',
                    "firstTimeload": firstTimeload ?? false,
                };
            })
            .then(() => {
                const callActionDispatch = () => {
                    switch (caseType) {
                        case ActionTypes.SET_GOAL_DETAILS_LINE_CHART_DATA:
                            resolve(goalDetailsLineChartData(data));
                            break;

                        case ActionTypes.SET_GOAL_DETAILS_PROGRESS_BAR_DATA:
                            resolve(setGoalDetailsProgressBarData(data));
                            break;
                        
                        case ActionTypes.SET_GOAL_DETAILS_WATCHER_DATA:
                            resolve(setGoalDetailsWatcherData(data));
                            break;

                        default:
                            break;
                    }
                }

                if (data.result === 'retry') {
                    setTimeout(() => {
                        callActionDispatch();
                    }, RetryApi.TIMEOUT);
                } else {
                    callActionDispatch();
                }

            });
    });
}