
import {thunk} from "redux-thunk";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { pagePostReducer } from "./Reducers/AppInsights/pages";

import {
    AppReducer,
    DateReducer,
    DomainReducer,
    OrgReducer,
    VisibilityReducer,
    HandoffReducer,
    StateFilterReducer,
    ExportRequestSourceFilterReducer,
    EventSourceReducer,
    UrlOptionReducer,
    DashboardsReducer,
    AuthReducer,
    SegmentsFilterReducer,
    TooltipFilterReducer,
    EnvFilterReducer,
    CommonComponentReducer,
    LanguageReducer,
    ExportDateReducer,
    NavigationStatusReducer
} from "./Reducers";

const createReducer = asyncReducers => combineReducers({
    apps: AppReducer,
    dates: DateReducer,
    domain: DomainReducer,
    org: OrgReducer,
    sectionVisibility: VisibilityReducer,
    handoff: HandoffReducer,
    pagePost: pagePostReducer,
    statusFilter: StateFilterReducer,
    eventSource: EventSourceReducer,
    exportRequestSource: ExportRequestSourceFilterReducer,
    segmentsFilter: SegmentsFilterReducer,
    tooltipList: TooltipFilterReducer,
    urlOption: UrlOptionReducer,
    dashboards: DashboardsReducer,
    auth: AuthReducer,
    envFilter: EnvFilterReducer,
    commonComponentData: CommonComponentReducer,
    languageData: LanguageReducer,
    exportDates: ExportDateReducer,
    navigationStatus: NavigationStatusReducer,
    ...asyncReducers
});

let store;

const initializeStore = () => {
    // createStore returns a plain object so we'll mess with it after creation.
    // const store = createStore(createReducer(), compose(applyMiddleware(thunk)));
    if(store) {
        return store;
    }

    store = createStore(
        createReducer(),
        applyMiddleware(thunk)
    )

    // Creates a convenient method for adding reducers later
    // See withReducer.js for this in use.
    store.injectReducer = (key, reducer) => {
        store.asyncReducers = {};
        store.asyncReducers[key] = reducer;
        store.replaceReducer(createReducer(store.asyncReducers));

        return store;
    };
    return store;
};

export default initializeStore;