import React, { useEffect, useState } from 'react';
import NVD3Chart from "react-nvd3";
import { useErrorBoundary } from 'react-error-boundary';
import { useSelector } from "react-redux";


const SingleSelect = (props) => {
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState({})
    let fetched_details = useSelector((state) => state);

    const [tableData, setTableData] = useState({
        showData: false,
        total: 0,
        qResponses: [],
        percentageSort: false,
        valueSort: false,
    })

    const [chart, setChart] = useState({
        data: [],
        flag: false
    })

    var data = [
        { key: "Eight", y: 6 },
        { key: "Nine", y: 2 },
        { key: "Ten", y: 11 },
    ];

    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (Object.keys(fetched_details.languageData.languageData).length) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
    }, [fetched_details])


    useEffect(() => {
        try {
            if (props.chartData.length) {
                setTimeout(() => {
                    initChart(props.chartData)

                }, 100);
                initResponseTable(props.tableData);
            }

            setTableData((prevState) => {
                return {
                    ...prevState,
                    showData: props.chartData.length ? true : false
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }, [props.tableData, props.chartData])


    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const initResponseTable = (questionData) => {
        try {
            let total = 0;
            let qResponses_data = [];
            let percentage = 0;
            for (let i = 0; i < questionData.length; i++) {
                total = questionData[i].value + total;
            }

            for (let i = 0; i < questionData.length; i++) {
                percentage = (parseFloat(questionData[i].value) / total) * 100;
                qResponses_data.push({ option: questionData[i].option, value: questionData[i].value, percentage: percentage });
            }

            setTableData((prevState) => {
                return {
                    ...prevState,
                    showTable: true,
                    total: total,
                    qResponses: qResponses_data
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const initChart = (questionData) => {
        try {
            let data = [];
            for (let i = 0; i < questionData.length; i++) {
                data[i] = {};
                data[i].key = questionData[i].option;
                data[i].y = questionData[i].value
            }

            setChart((prevState) => {
                return {
                    ...prevState,
                    data: data,
                    flag: true
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const sortData = (key, flag, field) => {
        try {
            const table_data = tableData.qResponses;
            if (tableData[field] === true) {
                table_data.sort((a, b) => (a[key] < b[key]) ? 1 : -1)
                setTableData((prevState) => {
                    return {
                        ...prevState,
                        qResponses: table_data,
                        [field]: !flag,
                    }
                })
            } else if (tableData[field] === false) {
                table_data.sort((a, b) => (a[key] > b[key]) ? 1 : -1)
                setTableData((prevState) => {
                    return {
                        ...prevState,
                        qResponses: table_data,
                        [field]: !flag,
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    return (
        <>
            <div className="marginTop-60 minWidth800">

                {tableData.showData
                    ? (<>
                        <div style={{ float: 'left' }} className="col-md-12 col-sm-12 col-xs-12">

                            {chart.flag && (
                                <NVD3Chart
                                    id="chart"
                                    width={600}
                                    height={370}
                                    type="pieChart"
                                    datum={chart.data}
                                    x="key"
                                    y="y"
                                    labelType={'percent'}
                                    tooltip={{
                                        contentGenerator: function (key, x, y, e, graph) {
                                            return `<div><div style='height:10px; display:inline-block; margin-right:5px;width:10px; background-color:${key.color}'></div>${key.data.key}<br><b><span style="margin-left:"10px">${key.data.y}<span></b></div>`
                                            // return key
                                        }
                                    }}
                                />
                            )}

                        </div>

                        <div className="col-md-12 col-sm-12 col-xs-12 block-grph-table">
                            <p></p>
                            <div className="col-md-12 col-sm-12 col-xs-12 block-grph-table displayInlineBlock chartTables">
                                <h5>{insightsLbls.responseStats}</h5>
                                <table class=" color_table table table-bordered" >
                                    <thead class="" >
                                        <tr>
                                            <th>{insightsLbls.answerChoices}</th>
                                            <th>
                                                {insightsLbls.responsesTitle}
                                                <i class={"marginLeft-5 fa pointer " + `${tableData.percentageSort ? 'fa-caret-up' : 'fa-caret-down'}`}
                                                    onClick={() => sortData('percentage', tableData.percentageSort, 'percentageSort')}></i>
                                            </th>
                                            <th>
                                                {insightsLbls.responseCount}
                                                <i class={"marginLeft-5 fa pointer " + `${tableData.valueSort ? 'fa-caret-up' : 'fa-caret-down'}`}
                                                    onClick={() => sortData('value', tableData.valueSort, 'valueSort')}></i>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tfoot class="">
                                        <tr>
                                            <td><strong>{insightsLbls.total}</strong></td>
                                            <td>&nbsp;</td>
                                            <td><strong>{tableData.total}</strong></td>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {/* {tableData.sortFlag &&  */}
                                        {tableData.qResponses.map((data, index) => {

                                            return (
                                                <tr key={index}>
                                                    <td>{data.option}</td>
                                                    <td>{Math.round(data.percentage)}%</td>
                                                    <td>{data.value}</td>
                                                </tr>
                                            );
                                        })}
                                        {/* } */}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>)
                    : (<div style={{ textAlign: 'center', paddingBottom: '50px', fontSize: '13px', color: 'black', fontWeight: '600' }}>
                        {insightsLbls['noResponseYet']}
                    </div>)
                }

            </div>
        </>
    )
};

export default React.memo(SingleSelect);
