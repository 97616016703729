import React from "react";
import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";


import { useDispatch, useSelector } from "react-redux";
import { LanguageState, StatusFilterState } from "../../store/Reducers";
import { setStateFilter } from "../../store/Actions";
import { useErrorBoundary } from 'react-error-boundary';

import "./StateFilter.scss";
import { useLocation } from "react-router-dom";




import { InsightLabels } from "labels";

interface CurrentState {
    statusFilter: StatusFilterState;
    languageData: LanguageState
}

const StateFilter = (props: any) => {
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);


    const dispatch = useDispatch();
    const location = useLocation();

    let fetched_details = useSelector((state: CurrentState) => state);



    let filterState = [
        { key: 'Published', value: insightsLbls['published'] },
        { key: 'Unpublished', value: insightsLbls['unpublished'] },
        { key: 'Deleted', value: insightsLbls['deleted'] },
        { key: 'All', value: insightsLbls['all'] }
    ];

    const [stateFilter, setStatusFilter] = useState({
        isOpen: false,
        selectedState: props.component === "customDashboard" ? (props.defaultValue ?? 'All') : ((localStorage.getItem('guideState') ?? 'All'))
    });

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        if (insightsLbls) {
            filterState['Published'] = insightsLbls['published'];
            filterState['Unpublished'] = insightsLbls['unpublished'];
            filterState['Deleted'] = insightsLbls['deleted'];
            filterState['All'] = insightsLbls['all'];
        }
    }, [insightsLbls])

    useEffect(() => {
        if (props.component === "customDashboard") {
            props.guideStatusValue("applyGuideStatus", props.defaultValue);
        }
    }, [props.defaultValue])



    useEffect(() => {
    }, [stateFilter])

    useEffect(() => {
        try {
            let urlData: any = {};
            const query = new URLSearchParams(location.search);
            query.forEach((v, k) => {
                urlData[k] = v;
            });

            if (urlData['state'] == null || urlData['state'] == "" || urlData['state'] == undefined) {
                // localStorage.setItem('guideState', urlData['state']);
                // setStatusFilter((prevState: any) => {
                //     return {
                //         ...prevState,
                //         selectedState: 'published'
                //     };
                // });

                if (localStorage.getItem('guideState') != null) {
                    setStatusFilter((prevState: any) => {
                        return {
                            ...prevState,
                            selectedState: localStorage.getItem('guideState')
                        };
                    });
                } else {
                    setStatusFilter((prevState: any) => {
                        return {
                            ...prevState,
                            selectedState: 'All'
                        };
                    });
                }
            } else {
                if (props.component !== "customDashboard") {
                    let stateData = urlData['state'].toLowerCase();
                    let stateData_selected = urlData['state']


                    let cnt = 1;
                    for (const key in filterState) {
                        if (filterState[key]) {
                            const keyValue = filterState[key]['value']?.toLowerCase();
                            if (keyValue === stateData) {
                                cnt--;
                            }
                        }
                    }

                    if (cnt > 0) {
                        stateData = 'all';
                        stateData_selected = 'All';
                    }


                    localStorage.setItem('guideState', stateData);
                    setStatusFilter((prevState: any) => {
                        return {
                            ...prevState,
                            selectedState: stateData_selected
                        };
                    });
                    dispatch(setStateFilter(stateData));

                }
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [])

    const toggleDropDown = (event: boolean) => {
        try {
            setStatusFilter((prevState: any) => {
                return {
                    ...prevState,
                    isOpen: event,
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    const changeStatus = (key: string, value: string) => {
        try {
            if (props.component === "customDashboard") {
                props.guideStatusValue("applyGuideStatus", key);
            }
            else {
                localStorage.setItem('guideState', key);
                dispatch(setStateFilter(key));
            }
        } catch (error) {
            showBoundary(error)
        }
        setStatusFilter((prevState: any) => {
            return {
                ...prevState,
                isOpen: false,
                selectedState: value
            };
        });

    };

    const getFilterValue = (filterVal: string) => {
        try {
            if (filterVal === 'All' || filterVal === 'all') {
                return insightsLbls['all'];
            } else {
                return filterVal;
            }
        } catch (error) {
            showBoundary(error)
        }

    }


    return (
        <div className="marginRight-5">
            <div className="guideStatusTitle">{insightsLbls.guideStatusFilterTitle}</div>
            <Dropdown id="state-filter-dropdown" show={stateFilter.isOpen} onToggle={(event) => toggleDropDown(event)}>
                {insightsLbls && (
                    <Dropdown.Toggle className={"btn btn-lg btn-outline-primary  bootstrapDCWorkaround2 " + (props.component === "customDashboard" ? "source-but-state-filter-dashboard buttonPositionStateFilter" : "source-but-state-filter buttonPositionStateFilter")}>
                        <span>{getFilterValue(stateFilter.selectedState)}</span>
                        <i aria-hidden="true" className={"switchApp-btn-icon " + (stateFilter.isOpen ? "fa fa-angle-up" : "fa fa-angle-down")}></i>
                    </Dropdown.Toggle>
                )}

                {/* dropdown-menu-state-filter userList-dropdown  */}
                <Dropdown.Menu className={"dropdown-menu " + (props.component === "customDashboard" ? "source-menu-state-filter-dashboard" : ((props.component === 'guideAnalytics' || props.component === 'dashboard' || props.component === 'guideErrors') ? "source-menu-state-filter guideAnalytics-source-menu-state-filter" : (props.component === 'userEngagementDetail') ? "source-menu-userEngagement-state-filter" : "source-menu-state-filter"))}>
                    {
                        filterState.map((state: any, index: number) => {
                            return (
                                <div className="dropdown-status-menu" key={index}>
                                    <button onClick={() => changeStatus(state.key, state.value)} id={"status_" + state.key}
                                        className={"pointer dropdown-menu-item statusBtn " + (stateFilter.selectedState === state.key ? "active-button" : "")}>
                                        {state.value}
                                    </button>
                                </div>
                            );
                        })

                    }
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default StateFilter;