if (GmXt === undefined) var GmXt = {};
GmXt.playerLbls = {};
GmXt.playerLbls.ko_KR = {
    "yAxisLabelCount": "개수",
    "playedCount": "재생 횟수",
    "completedCount": "완료된 횟수",
    "expandViews": "보기 확장",
    "collapseViews": "보기 축소",
    "expand": "확장",
    "collapse": "축소",
    "totalSteps": "총 단계",
    "slideshowStats": "슬라이드 쇼 통계",
    "videoStats": "비디오 상태",
    "view": "보기",
    "deleted": "삭제됨",
    "published": "게시됨",
    "unpublished": "게시 취소됨",
    "unknown": "알 수 없음",
    "allUsers": "모든 사용자",
    "download": "다운로드하기",
    "viewOnline": "온라인 보기",
    "or": "또는",
    "cioDashboardSubTitle": "모든 앱에서 활동",
    "cioDashboardModalTitle": "데이터 내보내기",
    "play": "재생",
    "totalPlayed": "총 재생 횟수",
    "uniquePlayed": "고유 재생 횟수",
    "completed": "완료됨",
    "pass": "합격",
    "fail": "불합격",
    "panel": "패널",
    "withAudio": "오디오 사용",
    "withouAudio": "오디오 없음",
    "linkedTable": "연결 테이블",
    "showGuideWithActivities": "활동과 함께 Guides 표시",
    "submitted": "전송 완료",
    "cancelled": "취소됨",
    "hideDeleted": "삭제됨 숨기기",
    "totalPlayCount": "ShowMe 총 재생 횟수",
    "uniquePlayCount": "ShowMe 고유 재생 횟수",
    "testMeTotalPlayCount": "TestMe 총 재생 횟수",
    "testMeUniquePlayCount": "TestMe 고유 재생 횟수",
    "passCount": "Test Me 총 합격 횟수",
    "failCount": "Test Me 총 불합격 횟수",
    "searchCount": "검색을 수행한 횟수입니다.",
    "actionCount": "검색 결과가 표시될 때 작업이 수행된 횟수입니다.",
    "scheduledPlay": "예약된 재생",
    "manualPlay": "수동 재생",
    "playedBy": "재생한 사람",
    "runType": "실행 유형",
    "result": "결과",
    "status": "상태",
    "srNo": "일련 번호",
    "guideTitle": "Guide 제목",
    "description": "설명",
    "moreInfo": "추가 정보",
    "jobPrioritisedSuccessMsg": "직무 우선 순위가 지정되었습니다!",
    "apiCacheDeletedMsg": "API 캐시가 삭제되었습니다.",
    "guideCreated": "Guides 생성됨",
    "guideModified": "Guides 수정됨",
    "exportJobs": "직무 내보내기",
    "searchOrganization": "조직 검색",
    "showing": "표시",
    "exportJobsList": "직무 목록 내보내기",
    "organization": "조직",
    "requestTime": "요청 시간",
    "exportJobStatus": "직무 상태 내보내기",
    "pending": "보류 중",
    "priortizeJob": "직무 우선 순위 지정",
    "priortized": "우선 순위가 지정됨.",
    "inProcess": "진행 중",
    "complete": "완료",
    "pendingEventsCount": "보류 중인 이벤트 수",
    "deleteCache": "캐시 삭제",
    "priortizeEventsForOrg": "이 조직에 대한 이벤트의 우선 순위를 지정합니다.",
    "tokenCancelledMsg": "새 요청으로 인해 작업이 취소되었습니다.",
    "selectAll": "모두 선택",
    "application": "응용 프로그램",
    "appList": "앱 목록",
    "close": "닫기",
    "searchApp": "앱 검색",
    "totalApps": "총 앱 수",
    "active": "활성",
    "deletedApp": "삭제된 앱",
    "operatingSystem": "운영 체제",
    "screenResolution": "화면 해상도",
    "webBrowser": "웹 브라우저",
    "name": "이름",
    "version": "버전",
    "count": "개수",
    "visitors": "방문자",
    "includeWeekend": "주말 포함",
    "last7Days": "최근 7일",
    "last30Days": "최근 30일",
    "last60Days": "최근 60일",
    "last90Days": "최근 90일",
    "last180Days": "최근 180일",
    "last365Days": "최근 365일",
    "lastCalendarYear": "마지막 역년",
    "customDate": "사용자 지정 날짜",
    "weekendsIncluded": "주말 포함됨",
    "weekendsExcluded": "주말 제외됨",
    "withRealTimeData": "실시간 데이터 사용",
    "realTimeDataNotAvailable": "사용 가능한 실시간 데이터가 없습니다.",
    "includeRealTimeData": "실시간 데이터 포함",
    "excludeWeekends": "주말 제외",
    "from": "시작",
    "to": "종료",
    "invalidStartDate": "잘못된 시작 날짜",
    "invalidEndDate": "잘못된 종료 날짜",
    "selectDateRangeWithin12mmonths": "12개월 이내의 날짜 범위를 선택하십시오.",
    "successfullyAdded": "성공적으로 추가됨!",
    "dashboardName": "Dashboard 이름",
    "duplicateDashboardName": "대시보드 이름 중복",
    "canAddUpto3Dashboards": "최대 3개의 대시보드를 추가할 수 있습니다.",
    "dashboardName20Chars": "Dashboard 이름은 20자 이하여야 합니다.",
    "pleaseEnterValidName": "올바른 이름을 입력하십시오.",
    "successfullyDeleted": "성공적으로 삭제되었습니다!",
    "successfullyUpdated": "성공적으로 업데이트되었습니다!",
    "chartsAlreadyAdded": "차트가 이미 추가됨",
    "createNewDashboardMsg": "사용자 지정 대시보드가 없습니다. 새 Dashboard를 생성하십시오.",
    "createNewDashboard": "새 Dashboard 만들기",
    "allDashboardDataDeleted": "모든 Dashboard 데이터가 삭제됩니다.",
    "wantToDeleteDashboard": "이 대시보드를 삭제하시겠습니까?",
    "save": "저장",
    "requestSent": "요청이 전송되었음!",
    "requestCompleteTime": "요청 완료 시간",
    "jobStatus": "직무 상태",
    "type": "유형",
    "surveyType": "Survey 유형",
    "surveyName": "Survey 이름",
    "stepName": "단계 이름",
    "selectFilter": "필터 선택",
    "publicURL": "공개 URL",
    "filter": "필터",
    "filter1Placeholder": "필터 1(Guide 이름, 단계 이름, Tooltip 이름)",
    "filter2Placeholder": "필터 2(단계 이름)",
    "tooltipList": "Tooltip 목록",
    "clearAll": "모두 지우기",
    "tooltipPlay": "Tooltip 재생",
    "played": "재생함",
    "lastPlayedOn": "마지막 재생 날짜",
    "activityDetails": "활동 세부 정보",
    "exportExcel": "Excel 내보내기",
    "downloading": "다운로드하는 중…",
    "exportPDF": "PDF 내보내기",
    "editFunnel": "퍼넬 편집",
    "clickHereToCreateFunnel": "퍼넬을 생성하려면 여기를 클릭하십시오.",
    "step": "단계",
    "funnelStepsInOrder": "순서대로 퍼넬 단계",
    "funnelStepsMsg": "퍼넬을 렌더링하려면 최소 3단계와 최대 7단계를 선택하십시오.",
    "funnelStepsStatsMsg": "* 연결된 안내서의 통계를 해당 세부 정보 페이지에서 볼 수 있습니다.",
    "max7Steps": "최대 7단계를 선택할 수 있습니다.",
    "clearList": "목록 지우기",
    "submit": "전송",
    "playedVsCompleted": "재생 및 완료됨",
    "deleteChartMsg": "이 차트를 삭제하시겠습니까?",
    "youAreHere": "현재 위치",
    "successfullyRenamed": "이름 변경 완료됨!",
    "dashboardName20CharsErrorMsg": "*Dashboard 이름은 20자 이하여야 합니다.",
    "dashboardNameEmptyErrorMsg": "*Dashboard 이름은 비워둘 수 없습니다.",
    "maintenanceInProgress": "유지 관리가 진행 중입니다.",
    "pleaseContactUs": "다음 연락처를 통해 문의하십시오.",
    "backToList": "목록으로 돌아가기",
    "title": "제목",
    "filterTitle": "제목 필터링",
    "max100chars": "최대 100자 허용",
    "filterDescription": "필터 설명",
    "selectOrganizations": "조직 선택",
    "deleteFilter": "필터 삭제",
    "deleteFilterMsg": "이 필터를 삭제하시겠습니까?",
    "switchOrganization": "조직 전환",
    "ofPages": "페이지",
    "ofPagesViews": "페이지 보기",
    "untaggedURLS": "태그 미지정 URL",
    "taggedURLS": "태그 지정 URL",
    "singleSelect": "단일 선택",
    "multiSelect": "복수 선택",
    "text": "텍스트",
    "range": "범위",
    "rating": "등급",
    "yesNo": "예-아니요",
    "URLHostname": "URL 호스트 이름",
    "URLPath": "URL 경로",
    "URLParameters": "URL 매개 변수",
    "URLHash": "URL 해시",
    "equals": "같음",
    "notEquals": "같지 않음",
    "contains": "포함",
    "notContains": "비포함",
    "startsWith": "시작",
    "similarTo": "다음과 유사",
    "endsWith": "종료",
    "commonMsg": "오류: 잘못된 경로입니다. 영숫자 문자와 특수 문자(~,=,<,>,/,*만)만 사용할 수 있습니다. 경로 문자열에는 공백이 허용되지 않습니다.",
    "slashMsg": "오류: 경로는 항상 슬래시로 시작됩니다.",
    "angularBracketMsg": "오류: 여는/닫는 꺾쇠 괄호(<>)를 내부에 있는 몇 가지 문자열과 함께 사용하십시오.",
    "tilEqAngularMsg": "오류: 슬래시 뒤에서는 <>와 ~=를 함께 사용할  수 없습니다.",
    "tilEqTogetMsg": "오류: 경로에 ~ 또는 =만 있을 수는 없습니다. ~에는 항상 =이 있어야 합니다.",
    "starAloneMsg": "오류: 스타(*)가 이미 있는 경우에는 슬래시에 아무것도 포함할 수 없습니다. 단일 스타(*)는 항상 두 개의 슬래시 사이에 있습니다",
    "twoStarMsg": "오류: 경로 문자열의 **(이중 별표) 뒤에는 아무 것도 포함할 수 없습니다.",
    "noDoubleSlashMsg": "오류: 경로 문자열에는 이중 슬래시가 허용되지 않습니다.",
    "onlyHyphenUnderMsg": "오류: 꺾쇠 괄호(<>) 사이에는 _ 및 -만 허용됩니다.",
    "noHyphenUnderMsg": "오류: _ 및 -는 꺾쇠 괄호(<>) 사이를 제외하고는 허용되지 않습니다.",
    "filedRequired": "오류: 이 필드는 필수 필드입니다.",
    "hashMsg": "오류: 잘못된 URL 해시",
    "paramaterAlphAndSpecialChar": "오류: 잘못된 매개 변수입니다. & 뒤에는 영숫자 문자와 특수 문자(-, _)가 허용됩니다.",
    "parameterStartMsg": "오류: 잘못된 매개 변수입니다. 항상 ?로 시작되며 매개 변수에서 ? 다음에 =가 있을 수 없습니다.",
    "parameterAllowedMsg": "오류: 잘못된 매개 변수입니다. 영숫자 문자와 특수 문자(-, _, =, &)만 허용됩니다.",
    "urlTypeSelected": "유형이 이미 선택되었습니다. 다른 유형을 선택하십시오.",
    "errorHostnameAlreadyExists": "오류:- 호스트 이름이 이미 있습니다.",
    "errorValueAlreadyExsts": "오류: 값이 이미 있음",
    "URLHostnameMsg": "URL 호스트 이름은 응용 프로그램 설정에서 상속 됩니다. 응용 프로그램 내의 모든 태그와 워크플로에 대한 기본 규칙입니다.",
    "and": "AND",
    "add": "추가",
    "addGroup": "그룹 추가",
    "searchStep": "단계 검색",
    "searchUser": "사용자 검색",
    "selectGuide": "안내서선택",
    "segmentFilter": "세그먼트 필터",
    "selectSegments": "세그먼트 선택",
    "summary": "요약",
    "edit": "편집",
    "delete": "삭제",
    "bestTime": "최상위 시간",
    "avgTime": "평균 시간",
    "worstTime": "최하위 시간",
    "tagPage": "Page 태그 지정",
    "label": "레이블",
    "cancel": "취소",
    "deletePageTag": "Page 태그 삭제",
    "deleteConfirmation": "이 Page 태그를 삭제하시겠습니까?",
    "confirm": "확인",
    "create": "만들기",
    "tagged": "태그 지정됨",
    "untagged": "태그 미지정됨",
    "domainMessage": "현재 앱에 추가된 도메인이 없습니다. Page를 만들거나 태그를 지정할 도메인을 추가하십시오.",
    "workflowType": "Workflow 유형",
    "dynamicWorkflowPlayCount": "동적 Workflow 재생 횟수",
    "guideWorkflowPlayCount": "Guide Workflow 재생 횟수",
    "uniqueWorkflowPlayCount": "고유 Workflow 재생 횟수",
    "workflowDetails": "Workflow 세부 정보",
    "workflowPlayCounts": "워크플로우 재생 횟수",
    "positive": "긍정적",
    "neutral": "중립",
    "negative": "부정적",
    "surveyDetails": "Survey 세부 정보",
    "copied": "복사됨!",
    "allowView": "익명 보기 허용",
    "linkExpiry": "링크 만료",
    "change": "변경",
    "ok": "확인",
    "showList": "목록 표시",
    "surveyTitle": "Survey 제목",
    "markGlobal": "글로벌로 표시",
    "availableAllAppsInAcc": "계정 내부의 모든 응용 프로그램에서 사용 가능",
    "mandatoryForExtSurvey": "Survey 종료를 위해 필수",
    "enterDiffQuestType": "동일한 유형의 다른 질문을 입력하십시오.",
    "linkDiffPage": "질문에 대해 동일한 페이지를 연결할 수 없습니다. 다른 페이지를 연결하십시오.",
    "linkQuest": "링크 질문",
    "addOption": "옵션 추가",
    "analyzer": "분석기",
    "must3CharLong": "직함 길이는 3자 이상이어야 합니다.",
    "threeStarRating": "별점 3개 등급",
    "addPage": "Page 추가",
    "mandatory1Analyzer": "이를 선택 취소할 수 없습니다. 하나의 분석기 질문이 필수입니다.",
    "changeAnalyzerQuest": "분석기 질문을 변경하시겠습니까?",
    "set1QuestAsAnalyzer": "(참고: 질문 1개만 분석기 질문으로 설정할 수 있습니다.)",
    "conditionOfMoreThan3": "옵션이 3 이상인 경우 분기 질문으로 선택할 수 없습니다.",
    "noteAboutoptionsReduction": "(참고: 총 옵션 수를 3으로 줄인 후 다시 선택하십시오.)",
    "QuestForChangingBranchQuest": "분기 질문을 변경하시겠습니까?",
    "noteBranchQuestLimit": "(참고: 페이지당 1개의 질문만 분기 질문으로 설정할 수 있습니다.)",
    "removeBranching": "이 작업을 수행하면 선택한 질문에서 분기가 제거됩니다.",
    "addBranching": "이 작업은 선택한 질문에 분기를 추가합니다.",
    "blankLinkMes": "빈 링크는 첨부된 링크가 없음을 의미합니다.",
    "goalDeleteConfirmation": "이 목표를 삭제하시겠습니까?",
    "viewDetails": "세부 정보 보기",
    "goalLiveOn": "Goal 라이브 날짜",
    "targetCompletion": "목표 완료",
    "currentStatus": "현재 상태",
    "click": "클릭",
    "iconForNewGoal": "새 Goal 만들기 아이콘",
    "clickForEditMessage": "목록에서 목표를 클릭하여 특정 목표를 편집하십시오.",
    "goalTitle": "Goal 제목",
    "goalExistsMsg": "Goal가 이미 있습니다. 다른 직함을 선택하십시오.",
    "goalDescription": "Goal 설명",
    "startTimeMsg": "시작 날짜/시간은 현재 날짜/시간에서 30분 이후여야 합니다.",
    "endTimeMsg": "종료 날짜/시간은 시작 날짜/시간에서 24시간 이후여야 합니다.",
    "goalStartDate": "Goal 시작 날짜",
    "goalEndDate": "Goal 종료 날짜",
    "canAddupto": "최대 추가할 수 있음",
    "guideList": "Guide 목록",
    "playCount": "재생 횟수",
    "eventSource": "이벤트 소스",
    "playMode": "재생 모드",
    "msgDoitFormeNotselect": "(자습서 안내서만 선택된 경우 재생 모드 \"doitforme \"를 선택하면 안 됩니다.",
    "msgCannotEditGoal": "목표를 게시한 후에는 편집할 수 없습니다 .이 목표를 게시하시겠습니까?",
    "goalEmailNotification": "Goal 전자 메일 알림이 현재 기본 설정에서 해제되어 있습니다. 중요한 목표 마일스톤 알림을 놓칠 수 있습니다.",
    "updatePreference": "언제든지 기본 설정을 업데이트하려면 다음으로 이동하십시오.",
    "goalNotificationPreference": "Goal 알림 기본 설정",
    "goalDetails": "Goal 세부 정보",
    "startDate": "시작 날짜",
    "endDate": "종료 날짜",
    "eventSources": "이벤트 소스",
    "playModes": "재생 모드",
    "targetPlayCount": "목표 재생 횟수",
    "oneEmailPublishedGuides": "게시된 안내서에 대해 당일에 발생한 모든 단계 실패에 대한 세부 정보가 포함된 전자 메일이 하루에 하나씩 보냅니다.",
    "oneEmailonSundays": "조직 전체에서 MyGuide 사용 현황을 요약하여 매주 1개의 전자 메일을 보냅니다. 전자 메일은 일요일(GMT)에 전송됩니다.",
    "receiveEmailOnMilestone": "이 옵션을 사용하면 마일스톤이 달성될 때마다 전자 메일 알림을 받을 수 있습니다(50%, 80% 및 100%). 이미 목표를 보고 있다는 사실을 고려하고 있습니다.",
    "receiveEmailOnAchievement": "이 옵션을 사용하면 목표가 달성될 때마다 전자 메일을 받을 수 있습니다. 이미 목표를 보고 있다는 사실을 고려하고 있습니다.",
    "emailNotificationPreference": "전자 메일 알림 기본 설정",
    "detail": "세부 정보",
    "moreDetails": "추가 세부 정보",
    "lessDetails": "세부 정보 부족",
    "requestSentOn": "요청 전송 날짜",
    "requestCompletedOn": "요청 완료 날짜",
    "requestCompletedvia": "다음을 통해 요청 완료됨",
    "requestSourcedAt": "다음 소스에서 요청",
    "requestGuideType": "요청 안내서 유형",
    "appsSelectedForRequest": "요청을 위해 선택한 앱",
    "dataNotAvailable": "데이터를 사용할 수 없음.",
    "exportViaEmail": "전자 메일로 내보내기",
    "selectedDateRange": "선택한 날짜 범위",
    "selectedApps": "선택한 앱",
    "selectedDataSources": "선택한 데이터 소스",
    "noteAboutExclusion": "참고:Tooltip 모바일 생성자 및 웹 생성자 제외",
    "selecetdEventSource": "선택한 이벤트 소스",
    "placeRequestLimitMsg": "마지막 24시간 동안 보류 중인 요청이 없는 경우에만 요청을 저장할 수 있습니다.",
    "textCommentsFormat": "사용자가 제공하는 모든 응답을 텍스트(의견) 형식으로 표시합니다.",
    "pieChartRepresentating": "모든 사용자가 답변한 옵션의 비율과 개수를 표시하는 원형 차트입니다.",
    "tabularRepresentationOfResponse": "테이블 형식 표시는 각 옵션의 백분율 및 응답 횟수입니다.",
    "vennDiagramCombinationOption": "사용자가 답변한 옵션 조합을 표시하는 벤 다이어그램입니다.",
    "tableOfCombinationOption": "모든 옵션 조합의 응답 수 및 백분율로 표시되는 테이블 형식 표현입니다.",
    "barforRating": "가로 막대형 차트는 사용자가 제공한 등급을 나타냅니다.",
    "tableForCounts": "테이블은 등급의 개수와 백분율을 나타냅니다.",
    "barForRatings": "가로 막대형 차트는 사용자가 제공한 별점 등급을 나타냅니다.",
    "tableForRatings": "테이블은 별점 등급의 개수와 백분율을 나타냅니다.",
    "barChartForYesNo": "가로 막대형 차트는 \"예\" 및 \"아니요\" 응답을 나타냅니다.",
    "tableofCountsPercentage": "테이블은 개수와 백분율을 나타냅니다.",
    "timeLine": "타임라인",
    "detailsNotAvailable": "이 인스턴스에 대한 세부 정보를 사용할 수 없습니다.",
    "textType": "텍스트 유형",
    "selectSingleType": "단일 선택 유형",
    "multiSelectType": "복수 선택 유형",
    "rangeType": "범위 유형",
    "starRatingType": "별점 등급 유형",
    "yesnoType": "예/아니요 유형",
    "responses": "응답",
    "excelViewerQn": "Excel 뷰어가 없습니까?",
    "viewPlayDetails": "재생 세부 정보 보기",
    "feedback": "피드백",
    "noResponseYet": "아직 응답이 없음",
    "responseStats": "응답 통계",
    "answerChoices": "답변 선택",
    "responseCount": "응답 개수",
    "total": "합계",
    "max5AppsSelected": "최대 5개 앱을 선택할 수 있습니다.",
    "player": "플레이어",
    "webCreator": "웹 생성자",
    "webPlayer": "웹 플레이어",
    "desktopCreator": "데스크톱 생성자",
    "mobileCreator": "모바일 생성자",
    "mobilePlayer": "모바일 플레이어",
    "dataTooltiptext7Days": "데이터는 실제 이벤트 시간부터 포털에 반영되는 데 최대 7일이 소요될 수 있습니다.",
    "password1SpecialChar": "암호 필드에는 특수 문자가 한 개 이상 포함되어야 합니다.",
    "password1Numeric": "암호 필드에는 하나의 숫자 값이 포함되어야 합니다.",
    "passwordLowerCase": "암호 필드에는 소문자 한 개가 포함되어야 합니다.",
    "passwordUpperCase": "암호 필드에는 대문자 한 개가 포함되어야 합니다.",
    "passwordConfirmationField": "암호 확인 필드는 암호 필드와 같아야 합니다.",
    "password8Char": "암호 확인은 8자여야 합니다!",
    "newPassword8Char": "새 암호는 8자여야 합니다!",
    "passwordChanged": "암호가 변경되었습니다!",
    "curPassword": "현재 암호",
    "newPassword": "새 암호",
    "confirmPassword": "암호 확인",
    "admin": "Admin",
    "changePassword": "암호 변경",
    "logout": "로그아웃",
    "somethingWentWrong": "오류가 발생했습니다.",
    "showLabels": "레이블 표시",
    "max9Allowed": "최대 9개의 선택 항목이 허용됩니다.",
    "showEntries": "항목 표시",
    "activity": "활동",
    "guideMeActivity": "GuideMe 활동",
    "showMeActivity": "ShowMe 활동",
    "testMeActivity": "TestMe 활동",
    "doItForMeActivity": "DoItForMe 활동",
    "guideTutorialActivity": "Guide 자습서 활동",
    "activitiesOnGuidesonSelectApps": "선택한 앱의 안내선에 대한 활동을 나타내는 선 그래프",
    "guideCounts": "Guide 개수:",
    "guidesSelectedinTimeFrame": "지정된 기간 내에 선택한 모드의 총 안내선 수",
    "guidesInGuideModeinTimeFrame": "지정된 기간 내에 GuideMe 모드의 총 안내선 수",
    "guidesInShowMeModeinTimeFrame": "지정된 기간 내에 ShowMe 모드의 총 안내선 수.",
    "guidesInTestMeModeinTimeFrame": "지정된 기간 내에 TestMe 모드의 총 안내선 수.",
    "guidesInSDoItForMeModeinTimeFrame": "지정된 기간 내에 DoItForMe 모드의 총 안내선 수.",
    "tutorialGuidesinTimeFrame": "지정된 기간 내에 재생된 TutorialGuides 총 횟수",
    "guideSummary": "Guide 요약",
    "top10Guides": "상위 10개 Guides",
    "tableTop10GuidesAcrossApps": "앱 전체에서 상위 10개 Guides를 나타내는 테이블.",
    "titleOfGuide": "Guide의 제목입니다.",
    "appTitle": "앱 제목",
    "appToWhichGuideBelong": "Guide가 속한 앱입니다.",
    "labels": "레이블",
    "labelsToGuide": "Guide에 적용되는 레이블입니다.",
    "noOfResponsesForGuide": "Guide에 대해 기록된 총 응답 수입니다.",
    "guideDistribution": "Guide 분포",
    "distributionAnalyzedNegNeuPos": "Guide개의 응답 분포가 음성, 중성 및 양성으로 분석되었습니다.",
    "lastUpdatedOn": "마지막 업데이트 날짜",
    "dateTimeofGuideModification": "Guide가 수정된 날짜 및 시간입니다.",
    "lineGraphonSelectedApps": "선택한 앱의 workflows에 대한 활동을 나타내는 선 그래프",
    "workflowSummary": "Workflow 요약",
    "top10Workflows": "상위 10개 워크플로우",
    "tableTop10WorkflowsAcrossApps": "앱 전체를 상위 10개 워크플로우를 나타내는 테이블.",
    "workflowTitle": "Workflow 제목",
    "titleWorkflow": "워크플로우의 제목입니다.",
    "typeWorkflow": "워크플로우 유형입니다.",
    "walkthroughCounts": "연습 횟수",
    "totalCountinWorkflow": "Workflow의 총 개수입니다.",
    "avgTimeonWorkflow": "워크플로우에 소요된 평균 시간입니다.",
    "bestimeAchievedWorkflow": "워크플로우에서 가장 좋은 시간입니다.",
    "lineGraphSurveysApps": "선택한 앱의 설문 조사에 대한 활동을 나타내는 선 그래프",
    "surveySummary": "Survey 요약",
    "top10Survey": "상위 10개 설문 조사",
    "tableTop10SurveysApps": "앱 전체를 상위 10개 설문 조사를 나타내는 테이블.",
    "nameSurvey": "Survey 이름입니다.",
    "appToWhichSurveyBelong": "설문 조사가 속한 앱입니다.",
    "labelsToSurvey": "Survey에 적용되는 레이블입니다.",
    "noOfResponsesForSurvey": "Survey에 대해 기록된 총 응답 수입니다.",
    "surveyDistribution": "Survey 분포",
    "distributionAnalyzedSurevyNegNeuPos": "부정적, 중립 및 긍정적으로 분석된 설문 조사 응답의 분포입니다.",
    "dateTimeofSurveyModification": "Survey가 수정된 날짜 및 시간입니다.",
    "tableFilteredBasedOnApps": "이 테이블은 선택한 앱만을 기준으로 필터링되었습니다.",
    "locationInsights": "위치 Insights",
    "whereUsersAccessFrom": "사용자가 앱에 액세스하는 위치는 어디입니까?",
    "pageStats": "Page 통계",
    "pageAcivity": "Page 활동",
    "blockDiagramTop50Pages": "각 페이지의 방문 횟수에 따라 상위 50개 태그 사용 페이지를 나타내는 블록 다이어그램입니다.",
    "pageSummaryOverview": "Page 요약 개요",
    "pageDetail": "Page 세부 정보",
    "collectionChartsTaggedPages": "태그 지정 페이지 간의 활동을 나타내는 차트 모음입니다.",
    "pageName": "Page 이름",
    "noOfPages": "페이지 이름입니다.",
    "labelsFilterTaggedPages": "레이블은 태그 지정 페이지를 필터링하는 데 사용됩니다.",
    "pageView": "Page 보기",
    "numberTimesPageViewed": "페이지를 본 횟수입니다.",
    "uniqueVisits": "고유 방문 횟수",
    "numberUniqueVisitorPage": "페이지를 본 고유 방문자 수입니다.",
    "topPages": "상위 Pages",
    "topUntaggedVistis": "사용자가 방문한 상위 태그 미지정 페이지입니다.",
    "pageDistribution": "Page 기고",
    "chartRepresentingPage": "페이지 활동 기고를 나타내는 차트입니다.",
    "taggedPages": "태그 지정 Page",
    "percentageContribution": "보기의 80 퍼센트에 기여하는 페이지 비율입니다.",
    "urlContibution": "URL 기고",
    "chartURLActivity": "URL 활동 기고를 나타내는 차트입니다.",
    "untaggedURL": "태그 미지정 URL",
    "comparisonTaggedUntagged": "태그 지정 및 태그 미지정 URL의 백분율 비교입니다.",
    "pageUserActivity": "Page 사용자 활동",
    "graphUserActiviyOnPage": "Page에서 사용자 활동을 나타내는 그래프입니다.",
    "totalUserVisit": "총 사용자 방문 수",
    "totalUsersOnPageinTime": "지정된 시간 내에 페이지를 방문한 총 사용자 수입니다.",
    "minSessionDuration": "최소 세션 기간",
    "maxSessionDuration": "최대 세션 기간",
    "avgSessionDuration": "평균 세션 기간",
    "minTimeonPageinTime": "주어진 시간 내에 페이지에 소요된 최소 시간입니다.",
    "maxTimeonPageinTime": "주어진 시간 내에 페이지에 소요된 최대 시간입니다.",
    "avgTimeonPageinTime": "주어진 시간 내에 페이지에 소요된 평균 시간입니다.",
    "whenUsersVisit": "사용자는 언제 방문합니까?",
    "heatMapforMaxTraffic": "페이지에 최대 트래픽이 발생하는 날의 시간을 나타내는 히트맵",
    "pageLoadTime": "Page 로드 시간",
    "averageTime": "평균 시간",
    "workflowVisualSummary": "Workflow 시각적 요약",
    "workflowUsageStats": "Workflow 사용 통계",
    "lineGraphWorkflowPlayed": "선 그래프는 재생된 워크플로우 수를 나타냅니다.",
    "workflowPlayCount": "Workflow 재생 횟수",
    "workflowPlayedInTime": "지정된 시간 프레임 내에 재생된 워크플로우 수입니다.",
    "uniqueWorkflowInTime": "지정된 시간 프레임 내에 재생된 고유한 워크플로우 수입니다.",
    "tableWorkflowAvgBestTime": "평균 시간 및 최상위 시간과 함께 workflows의 목록을 나타내는 테이블입니다.",
    "titleOfWorkflow": "워크플로우의 제목입니다.",
    "associatedLabelWorkflow": "워크플로우가 연결된 레이블의 이름입니다.",
    "totalUsersWalkthrough": "연습을 재생한 총 사용자 수입니다.",
    "avgTimeWorkflow": "워크플로우에 소요된 평균 시간입니다.",
    "bestTimeWorkflow": "워크플로우에 소요된 최상위 시간입니다.",
    "topWorkflows": "상위 workflows",
    "top5WorkFLowByUsage": "사용별 상위 5개 workflows",
    "barChartTop5Workflows": "재생 횟수의 내림차순으로 상위 5개 workflows를 표시하는 막대 그래프입니다.",
    "workflowUsageSummary": "Workflow 사용 요약",
    "usageSummaryGraph": "사용 요약 그래프",
    "lineChartWorkflowinTime": "선 그래프는 지정된 기간에 사용자가 재생한 워크플로의 수를 나타냅니다.",
    "uniqueUserPlayCount": "고유 사용자 재생 횟수",
    "stepTitle": "단계 제목",
    "stepPlayedCount": "단계 재생 횟수",
    "numberTimesWorkflowPlayedbyAll": "모든 사용자가 워크플로우를 재생한 횟수입니다.",
    "numberTimesWorkflowPlayedbyUnique": "고유한 사용자가 워크플로우를 재생한 횟수입니다.",
    "usageHeatMap": "사용 히트맵",
    "heatmapOnWorkflow": "워크플로우에 소요된 시간을 나타내는 히트맵입니다.",
    "workflowUsageAnalysis": "Workflow 사용 분석",
    "scatterGraph": "스캐터 그래프",
    "scatterPlotforBestAvgWorst": "스캐터 플롯 그래프는 지정된 기간에 최상위, 평균 및 최하위 시간을 나타냅니다.",
    "bestTimeonWorkflow": "워크플로우에 소요된 최상위 시간입니다.",
    "averageTimeonWorkflow": "워크플로우에 소요된 평균 시간입니다.",
    "worstTimeonWorkflow": "워크플로우에 소요된 최하위 시간입니다.",
    "stepsTimeUsageTable": "단계 시간 사용 테이블",
    "tableUsageStepsinWorkflow": "테이블에는 선택한 워크플로우의 단계에 대한 시간이 표시됩니다.",
    "titleStepWorkflow": "워크플로우의 단계 제목입니다.",
    "numberStepsPlayed": "단계를 재생한 횟수입니다.",
    "avgTimeSpentonStep": "단계에 소요된 평균 시간입니다.",
    "bestTimeSpentonStep": "단계에 소요된 최상위 시간입니다.",
    "workflowUsageEngagement": "Workflow 사용 참여",
    "stepsFlowChart": "단계 순서도",
    "pathChartforWorkflow": "선택한 워크플로우의 단계 흐름을 나타내는 경로 차트입니다.",
    "stepDropOff": "단계 드롭 오프",
    "funnelForworkflow": "현재 워크플로우에서 선택한 단계의 퍼넬 표현입니다. 최소 3단계와 최대 7단계를 선택할 수 있습니다. 퍼넬은 워크플로우의 단계 순서에 따라 렌더링되므로 선택 순서는 중요하지 않습니다.",
    "specificUserActivity": "특정 사용자 활동",
    "lineProficiency": "선 그래프는 개별 사용자의 능숙도 그래프를 나타냅니다. 사용자 능숙도 데이터를 보려면 사용자 정보 추적을 사용해야 합니다.",
    "overallWorkflowUsage": "전체 Workflow 사용",
    "workflowFootfallAnalysis": "Workflow 고객 수 분석",
    "footfallGraph": "고객 수 그래프",
    "graphWalkThroughEntireWorkflow": "각 단계에서 전체 워크플로우의 총 수에 대한 기여도를 백분율로 나타낸 그래프입니다.",
    "workflowPathAnalysis": "Workflow 경로 분석",
    "pathChart": "경로 차트",
    "graphStepsCombinationWorkflow": "특정 워크플로우에 대해 사용자가 팔로우하는 모든 단계 시퀀스의 조합을 나타내는 그래프입니다.",
    "pathChartLegend": "경로 차트 범례",
    "legendsStepsColorCode": "색 코드에 해당하는 단계를 보여주는 범례입니다.",
    "barGraphPosNegNeu": "'긍정적', '부정적', '중립'으로 설문 조사 응답 분포를 나타내는 막대 그래프입니다.",
    "tableActitiesofSurvey": "설문 조사의 활동을 나타내는 테이블입니다.",
    "titleSurveyCreated": "생성된 Survey의 제목입니다.",
    "stats": "통계",
    "labelsofSurvey": "설문 조사의 레이블입니다.",
    "numberResponsesEachSurvey": "각 설문 조사에 대한 응답 수입니다.",
    "responseDistribution": "응답 분포",
    "graphPercentageSurevey": "설문 조사 응답의 백분율을 나타내는 그래프입니다.",
    "countPositiveResponses": "지정된 시간 프레임에서 긍정적인 응답 수입니다.",
    "countNeutralResponses": "지정된 시간 프레임에서 중립 응답 수입니다.",
    "countNegativeResponses": "지정된 시간 프레임에서 부정적인 응답 수입니다.",
    "pieChartPosNegNeu": "이 설문 조사의 긍정적, 부정적 및 중립 응답의 개수와 백분율을 표시하는 원형 차트입니다.",
    "surveyQuestions": "Survey 질문",
    "verifyAccount": "계정 확인",
    "otpSent": "OTP가 사용자 전자 메일로 전송되었습니다. 계정을 확인하려면 일회용 암호를 입력하십시오.",
    "otp": "OPT",
    "enterNumber": "숫자만 입력하십시오.",
    "didntReceiveOTP": "OTP를 수신하지 않았습니까?",
    "clicktoResendOTP": "OTP를 재전송하려면 클릭",
    "backToLogin": "로그인으로 돌아가기",
    "manageGoals": "Goal 관리",
    "goalDeletedSuccessfully": "Goal가 성공적으로 삭제되었습니다.",
    "manageSurvey": "Survey 관리",
    "surevyStats": "Survey 통계",
    "surveyStatsDetailed": "Survey 통계 세부 정보",
    "graphActivitiesOnLiveGuide": "라이브 안내서에 대한 활동을 표시하는 그래프입니다.",
    "totalNumberOfGuidesPlayed": "지정된 시간 프레임에 재생된 총 안내서 수입니다.",
    "totalNumberOfUniqueGuidesPlayed": "지정된 기간 내에 (고유 사용자 당) 재생된 고유 안내서의 총 수입니다.",
    "totalNumberOfCompleteGuidesPlayed": "첫 번째 단계부터 마지막 단계까지 재생된 총 안내서 수입니다.",
    "completionRatio": "완료 비율",
    "slideshowPlayedInGivenTimeFrame": "지정된 시간 프레임에 재생된 슬라이드 쇼의 총 수입니다.",
    "totalVideosPlayedInGivenTimeFrame": "지정된 시간 프레임에 재생된 총 비디오 수입니다.",
    "totalGIFdPlayedInGivenTimeFrame": "지정된 시간 프레임에 재생된 총 GIF 수입니다.",
    "pieActivityPercentageDistribution": "다양한 형식으로 다운로드한 안내서의 백분율 분포를 나타내는 원형 차트입니다.",
    "barGraphTestMe": "각 막대가 두 부분으로 나누어져 있는(테스트에 합격한 사용자 수와 테스트에 불합격한 사용자 수) TestMe 활동을 수행한 총 사용자 수를 나타내는 막대 그래프입니다.",
    "guidePlayedVieDoItForMe": "DoItForMe 자동화를 통해 재생된 안내서를 표시하는 그래프입니다.",
    "myGuideSurvey": "MyGuide Survey",
    "totalNuberOfGuideAndExitResponses": "총 Guide 수 및 사용자가 안내서에 대해 제공한 종료 응답 수를 나타내는 그래프입니다.",
    "displayCount": "개수 표시",
    "guideName": "Guide 이름",
    "guidePlayed": "Guide 재생함",
    "showMePlayed": "ShowMe 재생함",
    "testMePlayed": "TestMe 재생함",
    "myGuideSurveysPlayed": "MyGuide 설문 조사 재생함",
    "tableRepresentingGuideActivites": "Guides의 활동을 나타내는 테이블입니다.",
    "nameOfGuidesPlayed": "재생된 안내서의 이름입니다.",
    "totalGuidesCompleteCount": "총 안내서 수, 재생된 고유 안내서(고유 사용자 당) 및 지정된 기간 동안 재생된 총 안내서 수입니다.",
    "totalSlideshowVideoGIFCount": "지정된 시간 프레임에 재생된 슬라이드 쇼, 비디오 및 GIF의 총 수입니다.",
    "totalTestPassCount": "지정된 시간 프레임에서 테스트에 합격하고 불합격한 총 사용자 수입니다.",
    "totalResponsesSubmitted": "지정된 기간 내에 안내서에 대해 사용자가 전송한 응답의 총 수입니다.",
    "guideMePlay": "GuideMe 재생",
    "guidePlayedStats": "Guide 재생 통계",
    "avgGuidePlayed": "고유 사용자당 지정된 시간 프레임 내에 재생된 평균 안내서입니다.",
    "guidePlayedOverview": "Guide 재생 개요",
    "radialChartLiveGuides": "라이브 안내서에 대한 활동을 나타내는 방사형 차트입니다.",
    "avgPlayed": "평균 재생",
    "guidePlaySources": "Guide 재생 소스(푸시 알림, Beacons, 패널 및 URL)",
    "pieChartGuideSources": "푸시 알림, Beacons, 패널 및 URL을 통해 재생된 Guide를 표시하는 원형 차트입니다.",
    "audioGuidance": "오디오 지침",
    "audioConsumptionGuideMeShowMe": "지원되는 GuideMe/ShowMe 모드에 대한 오디오 소비를 나타내는 그래프입니다.",
    "guideMeDetails": "GuideMe 세부 정보",
    "detailedSummary": "세부 요약",
    "tableOfLiveGuideActivities": "라이브 안내서 단계에 대한 활동을 표시하는 테이블입니다.",
    "startStep": "이 단계에서 시작됨",
    "endStep": "이 단계에서 종료",
    "failedOnStep": "이 단계에서 실패함",
    "stepsFlow": "단계 흐름",
    "titleOfStep": "단계의 제목입니다.",
    "numberOfTimesStepPlayed": "단계가 재생된 횟수입니다.",
    "stepBeganFromCurrentStep": "현재 단계에서 안내서 재생이 시작된 횟수입니다.",
    "stepEndedOnCurrentStep": "현재 단계에서 안내서 재생이 종료된 횟수입니다.",
    "stepErrors": "오류로 인해 이 단계에서 안내서 재생이 중단된 횟수입니다.",
    "sankeyPaths": "안내서를 재생하는 동안 사용자가 취한 다양한 경로를 나타내는 Sankey 다이어그램입니다.",
    "funnelWarningMsg": "현재 안내서에서 선택한 단계의 퍼넬 표현입니다. 최소 3단계와 최대 7단계를 선택할 수 있습니다. 퍼넬은 안내서의 단계 순서에 따라 렌더링되므로 선택 순서는 중요하지 않습니다.",
    "graphActivitiesOnShowMe": "ShowMe의 활동을 나타내는 그래프입니다.",
    "downloadDistribution": "다양한 형식으로 다운로드한 안내서의 분포를 나타내는 차트입니다.",
    "showMePlaySources": "ShowMe 재생 소스(푸시 알림, Beacons, 패널 및 URL)",
    "pieChartShowMeModes": "푸시 알림, Beacons, 패널 및 URL을 통해 재생된 ShowMe를 표시하는 원형 차트입니다.",
    "guidesPlayedViaDoItForMe": "이 안내서가 DoItForMe 자동화를 통해 재생된 횟수를 나타내는 그래프입니다.",
    "doItForMeShowMeSources": "DoItforMe 재생 소스(푸시 알림, Beacons, 패널 및 URL)",
    "doItForMeTableActivities": "DoItForMe 모드에서 안내서 재생 활동을 나타내는 테이블입니다.",
    "humanInterventionCount": "사람 작업 횟수",
    "humanInterventionCountTimes": "단계가 필요한 사람의 작업 횟수입니다.",
    "skippedStepCount": "건너뛴 단계 수",
    "skippedStepCountTimes": "단계를 건너뛴 횟수",
    "failedOnStepTimes": "오류로 인해 이 단계에서 안내서 재생이 실패한 횟수입니다.",
    "myGuideSurveys": "MyGuide 설문 조사",
    "guideExitStepSurveys": "Guide, 종료-Guide 및 단계 설문 조사",
    "tutorialGuideStats": "Tutorial 안내서 통계",
    "lineChartTutorialGuide": "자습서 안내서의 통계를 나타내는 꺾은선형 차트입니다.",
    "activitiesOnTutorialGraph": "자습서 안내서에 대한 활동을 표시하는 그래프입니다.",
    "tutorialGuideName": "Tutorial Guide 재생 이름입니다.",
    "totalNoOfTutorialGuidesPlayed": "재생한 총 자습서 안내서 수",
    "guideCompleted": "Guide 완료됨",
    "totalNumberOfGuidesCompleted": "완료된 총 자습서 안내서 수",
    "tutorialGuideDetailSummary": "Tutorial Guide 세부 정보 요약",
    "tableOfGuidePlayActivities": "안내서 재생 활동을 나타내는 테이블입니다.",
    "tooltipGuidanceAndValidationGraph": "지정된 시간 프레임에서 도구 설명 지침 및 유효성 검사의 통계를 표시하는 선 그래프입니다.",
    "countOfTooltipHoverClicked": "사용자가 마우스 커서로 이동하거나, 클릭하거나, 페이지가 로드되었을 때 페이지의 도구 설명과 상호 작용한 횟수입니다.",
    "countOfValidationPerformed": "페이지에 대한 유효성 검사가 수행된 횟수입니다.",
    "countOfValidationAndInteractionPerformed": "두 도구 설명이 상호 작용하여 유효성 검사가 수행된 횟수입니다.",
    "barChartPowerForm": "지정된 시간 범위에서 전원 양식이 호출되었을 때의 상태 통계를 나타내는 누적 막대형 차트입니다.",
    "countOfSubmittedPowerForm": "전원 양식을 전송한 모든 사용자의 수입니다.",
    "countOfCancelledPowerForm": "전원 양식을 취소한 모든 사용자의 수입니다.",
    "tableOfPowerFormStats": "지정된 시간 범위에서 전원 양식의 상태 통계를 나타내는 테이블입니다.",
    "titleOfPowerForm": "단계 순서와 도구 설명 안내서 이름이 들어 있는 전원 양식의 제목입니다.",
    "countOfCancelledSpecificPowerForm": "특정 전원 양식을 취소한 모든 사용자의 수입니다.",
    "countOfSubmittedSpecificPowerForm": "특정 전원 양식을 제출한 모든 사용자의 수입니다.",
    "tableOfStatsOfTooltip": "지정된 시간 범위에서 도구 설명의 통계를 나타내는 테이블입니다.",
    "nameOfTooltipContainsMoreTooltips": "하나 이상의 도구 설명이 들어 있는 도구 설명 안내서의 이름입니다.",
    "interactionHappenedWithGuidanceType": "특정 도구 설명 안내서 내에서 안내 유형 도구 설명과 사용자 상호 작용이 발생한 횟수입니다.",
    "interactionHappenedWithValidationType": "특정 도구 설명 안내서 내에서 Validation 유형 도구 설명과 사용자 상호 작용이 발생한 횟수입니다.",
    "interactionHappenedWithGuidanceValidationType": "특정 도구 설명 안내서 내에서 안내 및 Validation 유형 도구 설명과 사용자 상호 작용이 발생한 횟수입니다.",
    "digitalDuctTapeRenderCount": "디지털 Duct Tape가 렌더링되고 작업이 수행된 횟수입니다.",
    "interactionHappenedWithPowerForm": "Power Form과 사용자 상호 작용이 발생한 횟수입니다.",
    "tooltipValidationActivity": "Tooltip & Validation 활동",
    "countOfTooltipClickedAndHovered": "마우스 커서로 이동하거나, 클릭하거나, 페이지가 로드되었을 때 페이지에 도구 설명이 표시된 횟수입니다.",
    "countTooltipShownAndValidationPerformed": "두 도구 설명이 표시되고 유효성 검사가 수행된 횟수입니다.",
    "ductTapeActivity": "Duct Tape 활동",
    "statsOfDigitalDuctTape": "지정된 시간 프레임에 있는 디지털 덕트 테이프의 통계를 나타내는 그래프입니다.",
    "countDigitalDuctTapeDisplayed": "페이지에 디지털 덕트 테이프가 표시된 횟수입니다.",
    "powerFormActivity": "Power Form 활동",
    "newUser": "신규 사용자",
    "totalUsers": "총 사용자",
    "engagedUsers": "배정된 사용자",
    "newUserCount": "안내서가 포함된 페이지를 방문한 신규(최근 30일 동안 세션이 없음) 사용자의 총 수입니다.",
    "numberOfUsersVisitingGuidePages": "안내서가 포함된 페이지를 방문하는 총 사용자 수입니다.",
    "usersPerformedSomeActivity": "MyGuide를 사용하여 활동을 수행한 사용자입니다.",
    "lineGraphUserEngagement": "사용자 참여를 나타내는 선 그래프입니다.",
    "guideMeUniquePlayCount": "Guide Me 고유 재생 횟수",
    "guideMeTotalPlayCount": "Guide Me 총 재생 횟수",
    "guideMeCompletePlayCount": "Guide Me 완료 재생 횟수",
    "numberOfUsersPlayedInGuideMode": "지정된 기간 내에 GuideMe 모드에서 안내서를 재생한 총 사용자 수입니다.",
    "numberOfUniqueUsersPlayedInGuideMode": "지정된 기간 내에 GuideMe 모드에서 안내서를 재생한 고유 사용자 수입니다.",
    "numberOfTotalUsersPlayedInGuideMode": "지정된 기간 내에 GuideMe 모드에서 안내서를 완료한 총 사용자 수입니다.",
    "doItForMeUniquePlayCount": "DoItForMe 고유 재생 횟수",
    "doItForMeTotalPlayCount": "DoItForMe 총 재생 횟수",
    "doItForMeCompleteCount": "DoItForMe 완료 재생 횟수",
    "showMeTotalPlayCount": "지정된 기간 내에 ShowMe 모드에서 안내서를 재생한 총 사용자 수입니다.",
    "showMeUniquePlayCount": "지정된 기간 내에 ShowMe 모드에서 안내서를 재생한 고유 사용자 수입니다.",
    "testMeUserTestCount": "지정된 기간 내에 TestMe 모드에서 테스트를 수행한 총 사용자 수입니다.",
    "testMeUniqueUserTestCount": "지정된 기간 내에 TestMe 모드에서 테스트를 수행한 고유 사용자 수입니다.",
    "doItForMeUserPlayCount": "지정된 기간 내에 DoItForMe 모드에서 안내서를 재생한 총 사용자 수입니다.",
    "doItForMeUniqueUserPlayCount": "지정된 기간 내에 DoItForMe 모드에서 안내서를 재생한 고유 사용자 수입니다.",
    "doItForMeCompleteUserPlayCount": "지정된 기간 내에 DoItForMe 모드에서 안내서를 완료한 총 사용자 수입니다.",
    "tutorialGuidePlayCount": "Tutorial Guides 총 재생 횟수",
    "tutorialGuideUniquePlayCount": "Tutorial Guides 고유 재생 횟수",
    "tutorialGuideCompletePlayCount": "Tutorial Guides 완료 재생 횟수",
    "tutorialGuideUserPlayCount": "지정된 기간 내에 Tutorial Guides를 재생한 총 사용자 수입니다.",
    "tutorialGuideUniqueUserPlayCount": "지정된 기간 내에 Tutorial Guides를 재생한 고유 사용자 수입니다.",
    "tutorialGuideCompleteUserPlayCount": "지정된 기간 내에 Tutorial Guides를 완료한 총 사용자 수입니다.",
    "userEngagementSummaryOverview": "사용자 참여 요약 개요",
    "userEngagementSummary": "사용자 참여 요약",
    "tableOfUserEngagements": "사용자 참여 목록을 나타내는 테이블입니다.",
    "email": "전자 메일",
    "emailIdOfUser": "활동을 수행한 사용자의 전자 메일 ID입니다.",
    "nameOfUser": "활동을 수행한 사용자의 이름입니다.",
    "guidePlayedByUser": "지정된 시간 프레임에 사용자가 재생한 총 안내서 수입니다.",
    "guidesPlayedByShowMeMod": "지정된 시간 프레임 내에 ShowMe 모드에서 사용자가 재생한 총 안내서 수입니다.",
    "guidesPlayedByTestMeMod": "지정된 시간 프레임 내에 TestMe 모드에서 사용자가 재생한 총 안내서 수입니다.",
    "userEngagementDetailSummary": "사용자 참여 세부 정보 요약 개요",
    "userEngagementDetailSummaryTable": "사용자의 Guides 활동을 나타내는 테이블입니다.",
    "userEngagementDetailGuideName": "재생된 안내서의 이름입니다.",
    "userEngagementDetailTotalGuidePlay": "총 안내서 수 및 사용자에 대해 주어진 시간 프레임에서 재생된 총 안내서 수입니다.",
    "userEngagementDetailShowMePlay": "사용자에 대해 지정된 시간 프레임에 재생된 슬라이드 쇼, 비디오 및 GIF의 총 수입니다.",
    "userEngagementDetailTestMePlay": "사용자에 대해 지정된 시간 프레임에서 테스트에 합격하고 불합격한 총 사용자 수입니다.",
    "userGuideGraph": "사용자 Guides 그래프",
    "graphOfLiveGuideActivities": "사용자에 대해 라이브 안내서에 대한 활동을 표시하는 그래프입니다.",
    "playsByMedia": "미디어 유형별로 재생",
    "donutChartShowMeDistribution": "ShowMe에 대한 활동의 백분율 분포를 나타내는 도넛 차트입니다.",
    "playsByTime": "시간별 재생",
    "lineChartOfTestMeActivities": "사용자가 TestMe 활동에서 합격하거나 불합격한 총 횟수를 나타내는 꺾은선형 차트입니다.",
    "graphOfDoItForMeMode": "DoItForMe 모드에서 재생된 사용자에 대한 라이브 안내서의 활동을 나타내는 그래프입니다.",
    "totalGuidesPlayedInDoItForMeMode": "DoItForMe 모드에서 지정된 시간 프레임에 재생된 총 안내서 수입니다.",
    "totalGuidesPlayedFromFirstStep": "DoItForMe 모드에서 첫 번째 단계부터 마지막 단계까지 재생된 총 안내서 수입니다.",
    "userEngagementTimeline": "사용자 참여 타임라인",
    "userEngagementPlayDetails": "재생 세부 정보",
    "tutorialGuideActivity": "Tutorial Guides 활동",
    "userActivitiesTimelineCharts": "타임라인 막대 차트는 지정된 시간 프레임 내에 특정 사용자가 수행하는 일련의 활동을 나타냅니다. 또한 안내서 활동이 수행된 모드를 표시합니다.",
    "listOfSteps": "지정된 시간 프레임 내에 특정 안내서에 대해 사용자가 재생한 단계의 세부 목록을 표시합니다.",
    "userActivitiesOnTutorialGuide": "사용자의 Tutorial Guides에 대한 활동을 나타내는 그래프입니다.",
    "totalNumberOfGuideInTutorialGuide": "지정된 시간 프레임에 재생된 Tutorial Guides의 총 수입니다.",
    "totalNumberOfGuideInTutorialGuideFromFirstStep": "첫 번째 단계부터 마지막 단계까지 재생된 총 Tutorial Guides 수입니다.",
    "searchOverview": "검색 개요",
    "searchKeywords": "키워드 검색",
    "keyword": "키워드",
    "numberOfSearches": "검색 수",
    "avgResultRetured": "반환된 평균 결과",
    "clicks": "클릭",
    "searchSummary": "요약 검색",
    "tableOfSearchSummary": "주어진 시간 프레임에서 (각 키워드에 대한 적중 횟수에 따른) 상위 50개 키워드를 나타내는 테이블입니다. 적합하지 않다고 생각되는 경우 검색 기능을 사용하여 원하는 키워드를 자유롭게 검색할 수 있습니다.",
    "searchKeystrokes": "여러 사용자가 검색한 키 입력을 검색합니다.",
    "keystrokesHitByUser": "여러 사용자가 각 키 입력에 도달했을 때의 횟수",
    "avgKeystrokes": "각 키 입력에 대해 수행된 검색 수와 비교하여 기간 동안 반환된 평균 결과 수(최소)입니다.",
    "totalClickSearchResult": "검색 결과의 특정 항목에 클릭이 이루어진 횟수를 나타냅니다.",
    "wordClouseTooltipText": "지정된 시간 프레임에서 상위 50개 키워드에 대한 검색 볼륨을 나타내는 단어 클라우드 다이어그램(각 키워드에 대한 검색 횟수에 따름)입니다.",
    "ductTapeTable": "지정된 시간 프레임에 있는 디지털 덕트 테이프의 통계를 나타내는 테이블입니다.",
    "guideAutomationPlay": "Guide 자동화 재생",
    "testCasesExecutedGraph": "지정된 시간 프레임에서 테스트 사례가 실행된 횟수를 보여주는 선 그래프입니다.",
    "numberOfTimesTestCasesAutoExecuted": "지정된 시간 프레임에서 예약된 시간에 테스트 사례가 자동으로 실행된 횟수입니다.",
    "numberOfTimesTestCasesManuallyExecuted": "지정된 시간 프레임에서 사용자가 테스트 사례를 수동으로 실행한 횟수입니다.",
    "guideAutomationSummary": "Guide 자동화 요약",
    "testCasesTable": "개별 테스트 사례 상태 및 통계를 나타내는 테이블입니다.",
    "playTime": "재생 시간",
    "nameOfTestCase": "테스트 사례의 이름입니다.",
    "testCaseTriggeredTime": "테스트 사례가 트리거된 시간입니다.",
    "automaticallTriggered": "테스트 사례가 일정에 따라 자동으로 또는 사용자에 의해 수동으로 트리거되었는지 여부를 나타냅니다.",
    "testCaseModes": "테스트 사례가 트리거되었는지, 어떤 이유로 중지되었는지, 성공적으로 완료되었는지 여부를 나타냅니다.",
    "guideAutomationDetails": "Guide 자동화 세부 정보",
    "details": "세부 정보",
    "testCaseStatus": "모든 테스트 사례와 상태에 대한 상세하고 중첩된 보기입니다.",
    "testCaseTitle": "테스트 사례가 실행된 안내선의 제목입니다.",
    "testCaseResult": "특정 안내서에서 합격 또는 불합격한 테스트 사례입니다.",
    "testCaseInfo": "특정 안내서에서 실행된 테스트 사례의 상태를 지원하는 정보입니다.",
    "testCaseGuideInfo": "안내서 실행 상태에 대한 일부 추가 정보를 설명하는 텍스트입니다.",
    "goalDetailOverview": "Goal 세부 정보 개요",
    "goalPlayed": "Goal 재생함",
    "doItForMePlayed": "DoItForMe 재생함",
    "goalActivitiesChart": "설정된 목표에 대한 활동을 나타내는 꺾은선형 차트입니다.",
    "totalGuidesAndGuidesPlayedCount": "총 안내서 수 및 주어진 시간 프레임에서 재생된 총 안내서 수입니다.",
    "totalTooltipsRenderCount": "지정된 시간 프레임에 렌더링된 총 도구 설명 수입니다.",
    "goalProgress": "Goal 진행률",
    "userGuidesSummary": "사용자 Guides 요약",
    "goalCompletion": "Goal 완료",
    "goalCompletionOverhead": "Goal 완료 오버헤드",
    "goalProgressBarChart": "목표에 대한 진행률을 표시하는 막대 차트입니다.",
    "targetGoalPercentage": "사용자가 달성한 설정된 대상 목표의 백분율입니다.",
    "goalsExceedingTargets": "사용자가 달성한 설정된 대상 목표를 초과하는 목표의 백분율입니다.",
    "guideModification": "Guides 수정",
    "guideMeShowMeComparison": "지정된 시간 프레임에서 GuideMe 및 ShowMe 재생을 비교하는 꺾은선형 차트입니다.",
    "guidesPlayedInGuideMeMode": "지정된 시간 프레임에 GuideMe 모드에서 재생된 총 안내서 수입니다.",
    "guidesPlayedInShowMeMode": "지정된 시간 프레임에 ShowMe 모드에서 재생된 총 안내서 수입니다.",
    "userInteractedWithPNBTMode": "사용자가 페이지 방문 시 푸시 알림, Beacons 및 도구 설명과 상호 작용한 총 횟수를 나타내는 그래프입니다.",
    "totalModifiedGuides": "지정된 시간 프레임에서 수정된 총 안내선 수를 나타내는 막대 그래프입니다.",
    "serviceNotAvailable": "서비스를 사용할 수 없음",
    "conflictingParameters": "요청의 충돌하는 매개 변수",
    "insightsLogin": "Insights 로그인",
    "emailId": "전자 메일 ID",
    "emailPlaceholder": "name@domain.com",
    "password": "암호",
    "remember": "정보 저장",
    "pleaseContact": "문의하십시오",
    "accountSuspended": "계정이 일시 중단되었습니다.",
    "forgotPassword": "암호를 잊으셨습니까?",
    "login": "로그인",
    "copyright": "Copyright ©",
    "sessionExpiredMsg": "세션이 만료되었습니다.",
    "reLogin": "다시 로그인하십시오.",
    "sessionExpired": "세션이 만료되었습니다.",
    "reaTimeDateCondition": "실시간 데이터는 지원되는 모든 데이터 섹션에 포함되어 있습니다. 날짜 선택은 날짜 선택에서 현재 날짜 전날까지만 허용됩니다.",
    "goToHome": "홈으로 이동",
    "pageNotFound": "죄송합니다. 페이지를 찾을 수 없습니다!",
    "brandName": "MyGuide",
    "buildversion": "빌드 버전",
    "supportLinkText": "도움이 필요하십니까? 메일을 보내 주십시오.",
    "mailto": "메일 받는 사람",
    "addChartinCustomDash": "아직 추가된 차트가 없습니다. 사용자 지정 Dashboard에 차트를 추가하십시오.",
    "commentQnAnySuggestion": "제안할 사항이 있으십니까?",
    "commentQnImprove": "무엇을 개선할 수 있습니까?",
    "commentQnDescribe": "제품을 몇 단어로 설명하십시오.",
    "multiselectFeaturesLiked": "가장 마음에 들었던 기능은 무엇입니까?",
    "multiselectFeaturesUsed": "다음 중 이미 사용한 기능은 무엇입니까?",
    "multiselectPlatform": "사이트를 사용하는 플랫폼은 어느 것입니까?",
    "rangeSelect": "1에서 10까지의 범위를 고려하여, 저희를 어떻게 평가하시겠습니까?",
    "rangeProvide": "저희를 어떻게 평가하시겠습니까?",
    "rateUs": "평가",
    "satisfiedRate": "저희에게 얼마나 만족하십니까?",
    "singleselectLikeGuide": "저희가 마음에 드셨습니까?",
    "singleselectSuggestGuide": "친구에게 제안서를 추천하시겠습니까?",
    "singleselectAccomplishGuide": "목표를 달성하는 데 도움이 필요하십니까?",
    "yesNoLikeGuide": "저희가 마음에 드셨습니까?",
    "yesNoSuggest": "친구에게 제안서를 추천하시겠습니까?",
    "yesNoAccomplish": "목표를 달성하는 데 도움이 필요하십니까?",
    "downloadingOption": "다운로드하는 중",
    "desktop": "데스크톱",
    "mobile": "모바일",
    "tablet": "태블릿",
    "mayBe": "아마도",
    "minute": "분",
    "minutes": "분",
    "second": "초",
    "responsesTitle": "응답",
    "dashboard": "Dashboard",
    "manage": "관리",
    "selectType": "유형 선택",
    "linkedPage": "연결된 Page",
    "linkPage": "Page 연결",
    "branching": "분기",
    "addOptionLink": "옵션 링크 추가",
    "iconToCreateNewSurvey": "새 Survey 만들기 아이콘",
    "clickToEditSurvey": "목록에서 설문 조사를 클릭하여 특정 설문 조사를 편집하십시오.",
    "lastUpdated": "마지막 업데이트",
    "global": "전체",
    "questions": "질문",
    "turnOnGoalNotif": "목표에 도달한 각 마일스톤에 대한 전자 메일을 수신하려면 설정합니다.",
    "turnOffGoalNotif": "목표에 도달한 각 마일스톤에 대한 전자 메일 수신을 중지하려면 해제합니다.",
    "turnOnEmailNotif": "목표가 달성될 때 전자 메일을 수신하려면 설정합니다.",
    "turnOffEmailNotif": "목표가 달성될 때 전자 메일 수신을 중지하려면 해제합니다.",
    "error": "오류!",
    "goalAlreadyExists": "Goal가 이미 있습니다. 다른 직함을 선택하십시오.",
    "successfullyCreated": "성공적으로 생성되었음",
    "enterText": "텍스트 입력",
    "searchGoal": "Goal 검색",
    "enterQuestion": "질문을 입력하십시오.",
    "addLink": "링크 추가",
    "searchSurvey": "Survey 검색",
    "nameTagOnPage": "Page에 태그 이름 입력",
    "describeTagOnPage": "Page에 태그 설명",
    "selectUser": "사용자 선택",
    "pinToDashboard": "Dashboard에 고정",
    "deleteDashboard": "대시보드 삭제",
    "publishGoal": "Goal 게시",
    "deleteChart": "차트 삭제",
    "analyzerQuestion": "분석기 질문",
    "branchingQuestion": "분기 질문",
    "allDashboardDataDeletedMsg": "모든 Dashboard 데이터가 삭제됩니다.",
    "dashboardDeleteConfirmMsg": "이 대시보드를 삭제하시겠습니까?",
    "exportDashboardData": "Dashboard 데이터 내보내기",
    "deleteGoal": "Goal 삭제",
    "viewPendingJobs": "보류 중인 직무 보기",
    "deleteSurvey": "Survey 삭제",
    "deleteSurveyConfirmMsg": "이 설문 조사를 삭제하시겠습니까?",
    "shareSurvey": "설문 조사 공유",
    "best": "최상위",
    "worst": "최하위",
    "average": "평균",
    "selectRange": "범위 선택",
    "searchSegment": "세그먼트 검색",
    "searches": "검색",
    "org": "조직",
    "downloads": "다운로드",
    "selectApps": "앱 선택",
    "weeklyUsageStats": "주간 사용 통계",
    "dailyFailureReport": "일일 단계 실패 보고서",
    "allApps": "모든 앱",
    "chooseDahsboard": "Dashboard} 선택",
    "userEngagementDetail": "사용자 참여 세부 정보",
    "organizationDetail": "조직 세부 정보",
    "organizationView": "조직 보기",
    "guideSurveyQuestionsAndResponses": "Guide Survey 질문 및 응답",
    "exitGuideSurveyQuestionsAndResponses": "종료-Guide Survey 질문 및 응답",
    "guideStepSurveyQuestionsAndResponses": "Guide 단계 Survey 질문 및 응답",
    "pageDetails": "Page 세부 정보",
    "controlCenter": "제어 센터",
    "slideshows": "슬라이드 쇼",
    "videos": "비디오",
    "docx": "DOCX",
    "html": "HTML",
    "gifs": "GIF",
    "guideDetail": "Guide Analytics 세부 정보",
    "tutorialGuideDetail": "Tutorial Guide 세부 정보",
    "docTitleGuideDetail": "Guide Insights - Guide 세부 정보",
    "docTitleTutorialGuideDetail": "Guide Insights - Tutorial Guides 세부 정보",
    "docTitleTooltipDetails": "Guide Insights - Tooltip 세부 정보",
    "docTitleUserEngagementDetail": "Guide Insights - 사용자 참여 세부 정보",
    "docTitleGuideAutomationDetail": "자동화 Insights - Guide 자동화 세부 정보",
    "tooltipDetails": "Tooltip 세부 정보",
    "userEngagement": "사용자 참여",
    "search": "검색",
    "guidanceAndValidation": "지침 및 Validation",
    "pusNotificationAndBeacons": "푸시 알림 및 Beacons",
    "guideMeAndShowMe": "GuideMe & ShowMe",
    "of": "/",
    "tooltipAndValidation": "Tooltip & Validation",
    "userActivity": "사용자 활동",
    "eventType": "이벤트 유형",
    "guideDownloads": "Guide 다운로드",
    "time": "시간",
    "combineWorkflowSteps": "결합된 워크플로우 단계",
    "share": "공유",
    "generating": "생성 중...",
    "never": "없음",
    "custom": "사용자 지정",
    "surveyModalErrorMsg": "최대 250자가 허용됨",
    "scheduled": "예약됨",
    "manual": "수동",
    "interrupted": "중단됨",
    "editGoal": "Goal 편집",
    "stopWatching": "관찰 안 함",
    "startWatching": "관찰 시작",
    "invalidDate": "잘못된 날짜",
    "invalidStartAndEndDate": "잘못된 시작 날짜 및 종료 날짜",
    "goalNotificationEmail": "Goal 알림 전자 메일",
    "goalCompletionPercent": "Goal 완료 비율",
    "goalCompletionOverheadPercent": "Goal 완료 오버헤드 비율",
    "actualPlays": "실제 재생",
    "targetPlays": "목표 재생",
    "dataSource": "데이터 소스",
    "selectIcon": "아이콘 선택",
    "guideSteps": "Guide 단계",
    "createFilter": "필터 만들기",
    "filterList": "필터 목록",
    "orgList": "조직 목록",
    "allOrganizations": "모든 조직",
    "views": "보기",
    "docTitleUserEngagement": "Guide Insights - 사용자 참여",
    "docTitleSearches": "Guide Insights - 검색",
    "orLater": "이상",
    "pageNumber": "Page",
    "live": "라이브",
    "achieved": "달성됨",
    "ended": "종료됨",
    "achievedAndLive": "달성됨 및 라이브",
    "saved": "저장됨",
    "down": "아래로",
    "up": "위로",
    "urlPath": "URL 경로",
    "urlParameters": "URL 매개 변수",
    "urlHash": "URL 해시",
    "errorInvalidURL": "오류: 잘못된 URL",
    "publish": "게시",
    "btnYes": "예",
    "btnNo": "아니요",
    "select": "선택",
    "export": "내보내기",
    "apply": "신청",
    "all": "모두",
    "slideShow": "슬라이드 쇼",
    "video": "비디오",
    "seconds": "초",
    "refresh": "새로 고침",
    "hour": "시간",
    "hours": "시간",
    "inputPlaceHolderSearch": "Guide 검색",
    "noRecordFound": "사용할 수 있는 데이터가 없음",
    "avgRating": "평균 등급",
    "ratings": "등급",
    "ranges": "범위",
    "option": "옵션",
    "nameOfTheApp": "앱 이름",
    "guideMePlays": "GuideMe 재생",
    "guidePlayedInGuideMeMode": "GuideMe 모드에서 안내서 재생 횟수",
    "showMePlays": "ShowMe 재생",
    "guidePlayedInShowMeMode": "ShowMe 모드에서 안내서 재생 횟수",
    "testMePlays": "TestMe 재생",
    "gupidePlayedInTestMeMode": "TestMe 모드에서 안내서 재생 횟수",
    "doItForMePlayes": "DoItForMe 재생",
    "guidePlayedInDoItForMeMode": "DoItForMe 모드에서 안내서 재생 횟수",
    "tutorialPlays": "Tutorial 재생",
    "guidePlayedInTutorialMode": "Tutorial 모드에서 안내서 재생 횟수",
    "nameOfWorkflow": "Workflow 이름",
    "workflowCounts": "연습 횟수",
    "counts": "개수",
    "avgTimeSpentOnWorkflow": "워크플로우에 소요된 평균 시간",
    "minTimeSpentOnWorkflow": "워크플로우에 소요된 최소 시간",
    "numberOfGuidesPlayed": "재생된 안내서 수",
    "numberOfShowMePlayed": "ShowMe 재생 횟수",
    "numberOfTestMePlayed": "TestMe 재생 횟수",
    "numberOfSurveyResponses": "Survey 응답 수",
    "nameOfSTep": "단계 이름",
    "stepPlayeCount": "단계 재생 횟수",
    "numberOfTimeStepWasPlayed": "단계 재생 횟수",
    "stepStartedFrom": "이 단계에서 시작됨",
    "numberOfTimesSTepStartedFrom": "이 단계에서 재생 시작 횟수",
    "stepEndedOn": "이 단계에서 종료",
    "numberOfTimeStepEndedOn": "이 단계에서 재생 종료 횟수",
    "failedOnSTep": "이 단계에서 실패함",
    "numberIfTimesErroreOnStep": "이 단계에서 재생 오류 횟수",
    "humaneInterventionCount": "사람 작업 횟수",
    "numberOfTimesHUmanInterventionPerformed": "사람의 개입이 수행된 횟수",
    "numberOfTimesStepWasSkiepped": "단계를 건너뛴 횟수",
    "tutorialGuideTitle": "Tutorial Guide 제목",
    "nameOfTutorialGuide": "Tutorial Guide 이름",
    "tutorialGuidePlayed": "Tutorial Guide 재생함",
    "numberOfTutorialGuidesPlayed": "재생한 자습서 안내서 수",
    "tutorialGuideCompleted": "Tutorial Guide 완료됨",
    "numberOfTutorialGuidesCompleted": "완료된 자습서 안내서 수",
    "nameOfPowerForm": "Power Form 이름",
    "numberOfTimePowerFormCancelled": "Power Form 취소 횟수",
    "numberOfTimesPowerFormSubmitted": "Power Form 전송 횟수",
    "nameOfGuide": "Guide 이름",
    "numberOfGuidanceCounts": "지침 개수",
    "numberOfValidationCounts": "유효성 검사 횟수",
    "numberOfGuidanceAndValidationCounts": "지침 및 유효성 검사 횟수",
    "numberOfDigitalDuctTapeCount": "디지털 덕트 테이프 개수",
    "numberOfPowerFormsCount": "전원 양식 개수",
    "guideMePlayCount": "GuideMe 재생 횟수",
    "showMePlayCount": "ShowMe 재생 횟수",
    "testMePlayCount": "TestMe 재생 횟수",
    "doItForMePlayCount": "DoItForMe 재생 횟수",
    "tutorialPlayCount": "Tutorial 재생 횟수",
    "uniqueKeywordSearched": "고유한 키워드 검색",
    "searchCountTitle": "검색 횟수",
    "avgResultReaturned": "반환된 평균 결과",
    "avgNumberOfResultsShown": "표시된 결과의 평균 개수",
    "numberOfTimeSearchResultedIntoAction": "검색 결과 실행 횟수",
    "nameOfPageTag": "페이지 태그 이름",
    "pageViews": "Page 보기",
    "numberOfPageViews": "페이지 보기 수",
    "numberOfUniqueVisitors": "고유 방문자 수",
    "totalUniqueUsers": "총 고유 사용자 수",
    "newUsers": "신규 사용자",
    "numberOfSessions": "세션 수",
    "bouncedUsers": "반송된 사용자",
    "nameOfStep": "단계 이름",
    "stepPlayedCOunts": "단계 재생 횟수",
    "abgTimeSpentOnStep": "단계에 소요된 평균 시간",
    "minTimeSpentOnStep": "단계에 소요된 최소 시간",
    "nameOfSurvey": "Survey 이름",
    "numberOfResponses": "응답 수",
    "userSurveyAnalysis": "사용자 Survey 분석",
    "lastUpdateTime": "마지막 업데이트 시간",
    "receiveGoalEmailNotification": "Goal 전자 메일 알림 수신",
    "receiveGoalMilestoneNotification": "Goal 마일스톤 알림 수신",
    "other": "기타",
    "tooltipPlays": "Tooltip 재생",
    "watcherUpdatedSuccessfully": "시청자를 업데이트했습니다.",
    "tag": "태그 지정",
    "chatbotConversation": "챗봇 대화",
    "searchChatbot": "챗봇 검색",
    "iconToCreateNewChatbot": "새 챗봇 만들기 아이콘",
    "clickToEditChatbot": "목록에서 챗봇을 클릭하여 특정 챗봇을 편집하십시오",
    "chatBotTitle": "챗봇 제목",
    "mandatoryForChatBot": "챗봇에 필수",
    "validateEmail": "이메일 유효성 검사",
    "manageChatBot": "챗봇 관리",
    "geoLocationChartCountryWise1": "전 세계의 앱 국가 사용을 나타내는 지리적 위치 차트입니다. (사용자의 시스템 시간대를 기준으로 계산됨)",
    "guidesVsCompletion1": "가이드가 완료될 때까지 계속 재생된 비율과 완료되지 않은 비율입니다.",
    "totalNumbersUsersInteractedWithPNB1": "GuideMe, ShowMe 및 DoItForMe를 나타내는 그래프는 푸시 알림 또는 Beacons을 사용하여 수행된 시작을 재생합니다.",
    "guideWithAudio1": "가이드가 오디오와 함께 단계로 재생된 총 시간(오디오와 함께 재생된 최대 단계 수)",
    "guideWithoutAudio1": "가이드가 오디오 없이 단계로 재생된 총 시간(오디오 없이 재생된 최대 단계 수)",
    "done": "완료",
    "discard": "취소",
    "enableExitSurvey": "Survey 종료 사용",
    "selectExitSurvey": "Survey 종료 선택",
    "numberUniqueUsersonPages": "지정된 시간 내에 페이지를 방문한 고유 사용자 수입니다.",
    "exitSurveyWarningMsg": "이 작업은 모든 앱에 적용됩니다.",
    "orgAdmin": "조직 Admin",
    "deleteChatBot": "챗봇 삭제",
    "deleteChatBotConfirmMsg": "이 챗봇을 삭제하시겠습니까?",
    "chatBotValidateEmailMsg": "(이 질문에 대한 답변에는 유효한 이메일 주소만 허용됩니다. 그에 따라 질문을 구성하십시오.)",
    "partnerAdmin": "파트너 Admin",
    "totalCountSummary": "총 횟수 요약",
    "PNBandTooltips": "Push-notifications, Beacons & Tooltips",
    "buttonText": "로그인",
    "environmentFilterTitle": "환경",
    "segmentFilterTitle": "세그먼트",
    "eventSourceFilterTitle": "이벤트 소스",
    "guideStatusFilterTitle": "Guide 상태",
    "tooltipFilterTitle": "Tooltip 목록",
    "applications": "신청",
    "none": "없음",
    "yes": "예",
    "no": "아니요",
    "totalGuidesPlayedTitle": "합계: Guide 재생됨 + ShowMe + DoItForMe + Tutorial 재생",
    "tooltipActivity": "Tooltip 활동",
    "pushnotificationActivity": "Push-Notification 활동",
    "totalGuidePlayedInDoItForMeMode": "DoItForMe 모드에서 재생한 안내서 수",
    "userActivityGraph": "선택한 앱에서 사용자가 수행한 활동을 나타내는 꺾은선형 차트입니다.",
    "conversationStats": "대화 통계",
    "chatBot": "챗봇",
    "conversationsResponse": "대화 응답",
    "chatBotDetail": "대화 세부 정보",
    "chatBotResponse": "대화 응답",
    "remarks": "설명",
    "noDataFound": "데이터가 없습니다.",
    "noRemarkFound": "설명이 없습니다.",
    "viewQuestions": "질문 보기",
    "viewRemarks": "설명 보기",
    "remarksPlaceholder": "설명 추가...",
    "remarkPostSuccessful": "설명이 성공적으로 게시되었습니다.",
    "remarkPostFailed": "설명 게시에 실패했습니다. 다시 시도하십시오.",
    "conversation": "대화",
    "conversationSummary": "대화 요약",
    "tableActitiesofConversation": "대화의 활동을 나타내는 테이블입니다.",
    "conversationName": "대화 이름",
    "nameConversationCreated": "만든 대화의 이름입니다.",
    "labelsofConversation": "대화의 레이블입니다.",
    "numberResponsesEachConversation": "각 대화에 대한 응답 수입니다.",
    "dateTimeofConversationModification": "대화가 수정된 날짜 및 시간입니다.",
    "lineChartConversationStatTooltip": "대화 응답 분포를 나타내는 꺾은선형 차트",
    "featureInteractions": "기능 상호 작용",
    "featureDetail": "기능 세부 정보",
    "heatmapTooltipFeatureDetail": "최대 기능 상호 작용이 발생하는 날의 시간을 나타내는 히트맵",
    "lineChartTooltipFeatureDetail": "시간 경과에 따른 기능 상호 작용 수를 나타내는 꺾은선형 차트",
    "featureSummary": "기능 요약",
    "tableActivitiesOfFeature": "기능의 활동을 나타내는 테이블입니다.",
    "featureSetName": "기능 집합 이름",
    "nameOfFeatureSet": "기능 집합의 이름",
    "interactions": "상호 작용",
    "numOfInteractions": "각 기능 집합에 대한 상호 작용 수",
    "dateTimeOfFeatureModification": "기능이 수정된 날짜 및 시간입니다.",
    "rawURLActivities": "원시 URL 활동",
    "pageTagInfo": "참고: 태그 생성/삭제로 인해 예상되는 변경 사항은 적용되기까지 다소 시간이 걸릴 수 있습니다.",
    "createdBy": "만든 사람",
    "createdByText": "태그를 만든 사용자 이름입니다.",
    "lastUpdatedTimespan": "태그의 마지막 업데이트 기간입니다.",
    "blockDiagramTop50Pages1": "각 페이지의 방문 횟수에 따라 상위 50개 원시 페이지 이벤트를 나타내는 블록 다이어그램입니다.",
    "webBrowserTooltip": "각 페이지의 방문 횟수에 따라 브라우저를 기준으로 한 사용 현황 분석이 포함된 섹션입니다.",
    "osTooltip": "각 페이지의 방문 횟수에 따라 운영 체제를 기준으로 한 사용 현황 분석이 포함된 섹션입니다.",
    "screenResolutionTooltip": "각 페이지의 방문 횟수에 따라 화면 해상도를 기준으로 한 사용 현황 분석이 포함된 섹션입니다.",
    "completeURL": "완료 URL",
    "completeURLText": "활동이 기록된 페이지의 완료 URL입니다.",
    "URLVisits": "URL 방문",
    "URLVisitsText": "특정 URL에 대한 방문 수",
    "uniqueUserVisits": "고유 URL 방문",
    "uniqueUserVisitsText": "특정 URL에 대한 고유(고유 사용자) 방문 수입니다.",
    "associatedPageTags": "연결된 Page Tags",
    "associatedPageTagsText": "이 URL이 연결된 페이지 태그 태그 수입니다.",
    "totalUniqueUsersGraph": "지정된 기간 동안 고유한 사용자 통계를 나타내는 그래프",
    "noOfSessionsGraph": "주어진 기간 동안의 세션 수(시간 간격이 30분 이하인 연속 활동)를 나타내는 그래프",
    "avgSessionDurationGraph": "주어진 기간 동안의 평균 세션(시간 간격이 30분 이하인 연속 활동) 기간을 나타내는 그래프",
    "pageViewsGraph": "지정된 기간 동안의 총 페이지 보기를 나타내는 그래프",
    "featureList": "기능 목록",
    "userName": "사용자 이름",
    "userNameTooltip": "사용자 이름",
    "userEmail": "사용자 전자 메일",
    "userEmailTooltip": "사용자의 전자 메일 주소",
    "numOfInteractionsforUser": "각 사용자에 대한 상호 작용 수",
    "lineChartTooltipUserEngagement": "시간 경과에 따른 기능 상호 작용 및 기능 사용자의 수를 나타내는 꺾은선형 차트",
    "appEnvSelect": "응용 프로그램 및 환경 선택",
    "totalCount": "총 횟수",
    "selectedAppsTitle": "선택한 앱",
    "selectDateRangeWithin180Days": "180일 이내의 날짜 범위를 선택하십시오.",
    "pageVisits": "Page 방문",
    "pageUsers": "Page 사용자",
    "lineChartTooltipUserEngagementPage": "시간 경과에 따른 페이지 방문 수와 페이지 사용자 수를 나타내는 꺾은선형 차트",
    "lineChartTooltipUserEngagementPageModal": "시간 경과에 따른 페이지 방문 수를 나타내는 꺾은선형 차트",
    "numOfPageVisitsforUser": "각 사용자에 대한 Page 방문 수",
    "ytd": "YTD",
    "userActivityGraphDashboard": "선택한 애플리케이션 세트 전체의 총 사용자 수를 나타내는 선 그래프입니다.",
    "totalUserCount": "총 사용자 수",
    "bestTimePageLoad1": "페이지를 로드하는 데 소요된 최적의 시간입니다.",
    "averageTimePageLoad1": "페이지를 로드하는 데 소요된 평균 시간입니다.",
    "worstTimePageLoad1": "페이지를 로드하는 데 소요된 최대 시간입니다.",
    "timeOfPageLoading1": "페이지를 로드하는 데 소요된 시간입니다.",
    "tooltipSummary": "Tooltip 요약",
    "top10Tooltips": "상위 10개 Tooltips",
    "totalPlaysLabel": "총 재생 횟수",
    "guidanceLabel": "지침",
    "validationLabel": "Validation",
    "digitalDuctTapeLabel": "디지털 Duct Tape",
    "powerFormLabel": "전원 공급원",
    "totalPlays": "상호 작용한 총 도구 설명 수",
    "guidanceTooltip": "지침 모드에서 사용자 상호 작용이 발생한 횟수",
    "validationTooltip": "Validation 모드에서 사용자 상호 작용이 발생한 횟수",
    "guidanceAndValidationTooltip": "지침 및 Validation 모드에서 사용자 상호 작용이 발생한 횟수",
    "digitalDuctTapeTooltip": "디지털 Duct Tape 모드에서 사용자 상호 작용이 발생한 횟수",
    "powerFormTooltip": "Power Form 모드에서 사용자 상호 작용이 발생한 횟수",
    "applicationAndPlayType": "응용 프로그램 및 재생 유형",
    "selectApplication": "응용 프로그램 선택:",
    "selectEventSource": "이벤트 소스 선택:",
    "selectPlayType": "재생 유형 선택:",
    "appSelectionNote": "참고: 세부적인 선택을 위해 하나의 응용 프로그램만 선택하십시오.",
    "chartFilteredBasedOnApps": "이 차트는 선택한 앱만을 기준으로 필터링됩니다.",
    "pushNotificationInteractions": "푸시 알림 상호 작용",
    "pushNotificationDisplayed": "푸시 알림 표시",
    "beaconsInteractions": "Beacons 상호 작용",
    "beaconsDisplayed": "Beacons 표시됨",
    "guidesPlayedByPushAndBeacons": "Guides 푸시 & Beacon에 의해 재생됨",
    "emailCCPlaceholder": "이메일 ID를 입력하고 Enter 키를 누릅니다!",
    "emailCC": "Email 참조",
    "invalidEmailId": "잘못된 전자 메일 ID",
    "duplicateEmailId": "중복된 전자 메일 ID",
    "selectedPlayTypes": "선택한 플레이 유형",
    "selectedTooltip": "선택 Tooltip",
    "newTemplate": "새 템플릿",
    "templateTitle": "템플릿 제목",
    "newSchedule": "새 일정",
    "scheduleTitle": "일정 제목",
    "useTemplate": "템플릿 사용",
    "saveSchedule": "일정 저장",
    "updateSchedule": "일정 업데이트",
    "deleteTemplate": "템플릿 삭제",
    "deleteSchedule": "일정 삭제",
    "deleteTemplateConfirmationMsg": "이 템플릿을 삭제하시겠습니까?",
    "deleteScheduleConfirmationMsg": "이 일정을 삭제하시겠습니까?",
    "saveTemplate": "템플릿 저장",
    "monday": "월요일",
    "tuesday": "화요일",
    "wednesday": "수요일",
    "thursday": "목요일",
    "friday": "금요일",
    "saturday": "토요일",
    "sunday": "일요일",
    "triggerTime": "트리거 시간",
    "activate": "활성화",
    "deactivate": "비활성화",
    "templateTitleErrorMsg": "템플릿 제목을 추가하세요.",
    "scheduleTitleErrorMsg": "일정 제목을 추가하세요.",
    "scheduleFutureDateErrorMsg": "일정의 미래 날짜를 선택하세요.",
    "scheduleDaySelectionErrorMsg": "스케줄러를 실행할 일수를 선택하세요.",
    "scheduleMaxDaySelectionErrorMsg": "최대 7일 선택 가능합니다",
    "scheduleMaxActiveErrorMsg": "최대 3개의 일정을 동시에 활성화할 수 있습니다.",
    "activeScheduleNonEditableInfoMsg": "활성화된 일정은' 편집할 수 없습니다.",
    "selectTime": "시간 선택",
    "oneTime": "한 번",
    "repetitive": "반복적인",
    "tutorial": "Tutorial",
    "updatedSuccessfully": "성공적으로 업데이트되었습니다!",
    "pushNotificationInteractedKey": "푸시알림 상호작용됨",
    "pushNotificationDisplayedKey": "푸시알림 표시됨",
    "beaconInteractedKey": "Beacon상호 작용함",
    "pushNotificationInteractedValue": "사용자가 푸시 알림과 상호 작용한 인스턴스 수입니다.",
    "pushNotificationDisplayedValue": "사용자의 화면에 푸시 알림이 표시된 횟수입니다. (디스플레이는 하루에 각 사용자 & 푸시 조합마다 한 번씩 캡처됩니다.)",
    "beaconInteractedValue": "사용자 비콘과 상호 작용한 인스턴스 수입니다.",
    "beaconDisplayedValue": "사용자의 화면에 beacon이 표시된 횟수입니다. (디스플레이는 하루에 각 사용자 & beacon 조합마다 한 번씩 캡처됩니다.)",
    "emailCCWarningMessage": "최대 10개의 이메일 주소만 허용됩니다",
    "ytdCalendarYear": "YTD (Calendar Year)",
    "activateScheduleMsg": "이 일정을 활성화하시겠습니까?",
    "deactivateScheduleMsg": "이 일정을 비활성화하시겠습니까?",
    "help": "도움말",
    "updateTemplate": "템플릿 업데이트",
    "beaconDisplayedKey1": "Beacon 표시됨",
    "taskList": "과제 목록",
    "taskListActivity": "과제 목록 활동",
    "taskListSummary": "과제 목록 요약",
    "tasksTotalPlayed": "주어진 시간 동안 참여한 과제 목록의 총 수.",
    "tasksCompleted": "지정된 기간 내에 완료한 과제 목록의 총 수입니다.",
    "taskListNameLabel": "과제 목록 이름",
    "taskListEngagementsLabel": "참여한 과제 목록",
    "taskListCompletedLabel": "과제 목록 완료됨",
    "taskListLastUpdatedLabel": "마지막 업데이트",
    "taskListName": "과제 목록 이름",
    "taskListEngagements": "총 과제 목록 참여 수",
    "taskListCompleted": "완료된 과제 목록의 총 수",
    "taskListLastUpdated": "과제 목록을 수정한 날짜 및 시간",
    "taskListDetail": "과제 목록 세부 정보",
    "taskListGuidePlay": "Guide 재생",
    "completionStatus": "완료 상태",
    "completionTime": "완료 시간",
    "totalTaskListPlayedMsgTooltip": "과제 목록이 재생된 횟수",
    "completedTaskListTooptip": "과제 목록이 완료된 횟수",
    "guidePlays": "Guide 재생",
    "guidePlayTaskListTooltip": "과제 목록을 통한 가이드 재생 수",
    "taskListInteracted": "상호 작용한 과제 목록",
    "taskListInteractedTooltip": "사용자는 과제 목록에서 적어도 하나의 가이드를 재생하였습니다.",
    "taskListCompletedTooltip": "사용자 과제 목록의 모든 가이드를 완료했습니다.",
    "viewGuides": "보기 Guides",
    "min7DaysSelection": "사용자 참여 섹션의 최소 시간 범위 선택은 7일입니다.",
    "totalGuidesPlayCount": "}, GuideMe, DoItForMe 및 ShowMe 모드에서 재생된 총 안내서 수입니다.",
    "role": "역할",
    "performedSearches": "수행된 검색",
    "searchesResultedIntoAction": "검색 결과 수행한 작업",
    "exportStatus": "내보내기 상태",
    "myGuideGuidesPlayed": "MyGuide Guides 재생함",
    "myGuideTutorialGuidesPlayed": "MyGuide Tutorial Guides 재생함",
    "totalNumberOfTimesGuidePlayedInDoItForMe": "DoItForMe 모드에서 재생한 안내서의 총 횟수입니다.",
    "creatorExtensionRequired": "이 기능이 작동하려면 최신 버전의 MyGuide Creator 확장이 브라우저에 확장으로 설치되어 있어야 합니다.",
    "guidesInTooltipModeinTimeFrame": "지정된 기간 내에 표시된 Tooltip의 총 횟수입니다.",
    "guidesInPNModeinTimeFrame": "주어진 시간 동안 푸시 알림을 사용하여 재생된 안내서의 총 수입니다.",
    "guidesInBeaconModeinTimeFrame": "지정된 기간 내에 Beacons}을(를) 사용하여 재생된 안내서의 총 수입니다.",
    "guidesInSurveyModeinTimeFrame": "주어진 시간 동안 설문 조사 응답의 총 수입니다.",
    "visits": "방문",
    "desktopPlayer": "데스크톱 플레이어",
    "guideErrors": "Guide 오류",
    "guideErrorsDetails": "Guide 오류 세부 정보",
    "guideStepErrors": "Guide 단계 오류",
    "guideStepErrorDetails": "Guide 단계 오류 세부 정보",
    "guideStepErrorsActivity": "Guide 단계 오류 활동",
    "guideErrorOccurrences": "Guide 오류 발생",
    "guideErrorOccurrencesValue": "선택한 기간에 안내서 오류의 총 수",
    "guideErrorSummary": "Guide 오류 요약 목록",
    "guideErrorSummaryValue": "지정된 기간에 단계 오류가 발생한 단계의 모든 안내서 요약",
    "stepInGuideWithErrors": "오류가 있는 안내서의 단계",
    "totalGuidePlayErrors": "Guide 재생 오류 총 수",
    "latestErrorRecorded": "다음 시간에 기록된 최신 오류",
    "stepInGuideWithErrorsValue": "안내서의 총 단계 수에서 오류가 발생했습니다.",
    "totalGuidePlayErrorsValue": "Guide 플레이에서 오류가 발생한 총 횟수",
    "latestErrorRecordedValue": "마지막으로 기록된 오류의 타임스탬프",
    "latestStartTimestamp": "최신 시작 타임스탬프",
    "stepErrorCounts": "스텝 오류 카운트",
    "latestStartTimestampValue": "단계에서 발생한 오류의 최신 타임스탬프입니다.",
    "stepErrorCountsValue": "단계에서 오류가 발생한 총 횟수",
    "stepErrorSummary": "단계 오류 요약",
    "guideError": "Guide 오류",
    "actions": "작업",
    "createTemplate": "템플릿 만들기",
    "createSchedule": "일정 만들기",
    "createJob": "직무 만들기",
    "playType": "플레이 유형",
    "doYouWantToDelete": "삭제하시겠습니까?",
    "dateRange": "날짜 범위",
    "triggerType": "트리거 유형",
    "triggerFrequency": "트리거 빈도",
    "rowsPerPage": "페이지당 행 수",
    "exportToExcel": "Excel로 내보내기",
    "triggerDay": "트리거 일",
    "triggerDate": "트리거 날짜",
    "dayOfWeek": "요일",
    "dateOfMonth": "월별 날짜",
    "pauseSchedule": "일정 일시 중지",
    "playSchedule": "일정 재생",
    "editSchedule": "일정 편집",
    "editTemplate": "템플릿 편집",
    "businessIntelligenceToolsIntegrations": "비즈니스 인텔리전스 도구 통합",
    "bitiInfoHeading0": "'CornerstoneGuide'용 Power BI Desktop 커넥터를 얻으려면 어떻게 합니까?",
    "bitiInfo0_1": "여기를 클릭하여",
    "bitiInfo0_2": "커넥터(.mez) 파일을 다운로드합니다.",
    "bitiInfo0_3": "확장 파일을 여기에 복사합니다",
    "bitiInfo0_4": "옵션을 선택합니다.",
    "bitiInfo0_5": "유효성 검사 또는 경고 없이 어떤 확장이든 로드할 수 있게 허용합니다.",
    "bitiInfo0_6": "Power BI Desktop에서",
    "bitiInfo0_7": "Power BI Desktop을 다시 시작합니다.",
    "bitiInfoHeading1": "Cornerstone Guide Insights에서 PowerBI Desktop 인스턴스로 직접 데이터를 가져오는 방법은 무엇입니까?",
    "bitiInfo1_1": "아래의 '생성' 버튼을 클릭하여 인증된 URL을 생성합니다.",
    "bitiInfo1_2": "생성된 URL을 복사합니다.",
    "bitiInfo1_3": "Windows 컴퓨터에서 PowerBI Desktop으로 이동합니다.",
    "bitiInfo1_4": "탐색 모음에서 '홈' 탭을 클릭한 다음 '데이터 가져오기' 옵션을 클릭합니다. 드롭다운에서 '더보기'를 클릭합니다.",
    "bitiInfo1_5": "대화창이 열리면 '기타'를 클릭하고 'Cornerstone Guide(베타)(사용자 지정)'을 검색한 다음 '연결'을 클릭합니다.",
    "bitiInfo1_6": "새 대화 상자에서 인증된 URL을 붙여넣고 '확인'을 클릭합니다.",
    "bitiInfo1_7": "'내비게이션' 창이 열립니다. PowerBI Desktop으로 가져올 데이터를 탐색하고 선택한 다음 '로드'를 클릭합니다.",
    "bitiInfoHeading2": "중요 지침",
    "bitiInfo2_1": "이 포털에서 생성되는 각 인증된 URL은 개별 사용자에게 고유하며 사용자 계정에 연결됩니다.",
    "bitiInfo2_2": "URL 자체에는 만료가 없지만 60분 동안 활동하지 않으면 만료되는 세션에 연결됩니다.",
    "bitiInfo2_3": "URL은 섹션에서 위의 '재승인' 버튼을 클릭하여 재인증할 수 있습니다.",
    "bitiInfo2_4": "URL을 변경하려면(자의로 또는 URL이 침해된 경우) '다시 생성' 버튼을 클릭하여 변경할 수 있습니다.",
    "bitiInfoNote": "참고: 인증된 URL은 생성 시점부터 60분 동안 적용됩니다.",
    "generateUrl": "생성 URL",
    "authenticateUrl": "인증 URL"
};

module.exports = GmXt.playerLbls.ko_KR;