import * as ActionTypes from '../ActionTypes';
import axiosInstance, { getInstance } from '../../../network/apis/interceptor';
import { CommonUtils } from '../../../utils/CommonUtils';
import { ApiRelativePaths, RetryApi } from '../../../utils/GlobalConstants';


let ApiPaths: any = ApiRelativePaths;


export const setSearchChartData = (searchChartData: any) => {
    return {
        type: ActionTypes.SET_SEARCH_CHART_DATA,
        searchChartData: searchChartData
    }
};

export const setSearchStatsData = (searchStatsData: any) => {
    return {
        type: ActionTypes.SET_SEARCH_STATS_DATA,
        searchStatsData: searchStatsData
    }
};

export const setSearchSummary = (searchSummary:any) => {
    return {
        type: ActionTypes.SET_SEARCH_SUMMARY,
        searchSummary: searchSummary
    }
}

export const setSearchUserList = (searchUserList:any) => {
    return {
        type: ActionTypes.SET_SEARCH_USER_LIST,
        searchUserList: searchUserList
    }
}


export const getSearchData = (params: any, url_path: any, caseType: any, apiErrorFlag: string, firstTimeload?: boolean) => {
    return new Promise((resolve, reject) => {
        let path = ApiPaths[url_path];
        let data:any = {};
        let paramObj = {};
        let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
        getInstance('guide-insights/search-activities/');


        axiosInstance.get(generatedURL)
            .then(res => {
                data = {
                    "result": "success",
                    "data": res.data.data,
                    "firstTimeload": firstTimeload ?? false,
                    "requestUUID": res.data.requestUUID,
                };
            })
            .catch(err => {
                if (apiErrorFlag === '') {
                    data = {
                        "result": "retry",
                        "data": err,
                        "firstTimeload": firstTimeload ?? false,
                    };
                } else {
                    data = {
                        "result": "error",
                        "data": err,
                        "firstTimeload": firstTimeload ?? false,
                    };
                }
            })
            .then(() => {
                const callActionDispatch = () => {
                    switch (caseType) {
                        case ActionTypes.SET_SEARCH_CHART_DATA:
                            resolve(setSearchChartData(data));
                            break;

                        case ActionTypes.SET_SEARCH_STATS_DATA:
                            resolve(setSearchStatsData(data));
                            break;

                        case ActionTypes.SET_SEARCH_SUMMARY:
                            resolve(setSearchSummary(data));
                            break;

                        case ActionTypes.SET_SEARCH_USER_LIST:
                            resolve(setSearchUserList(data));
                            break;

                        default:
                            break;
                    }
                }
                

                if (data.result === 'retry') {
                    setTimeout(() => {
                        callActionDispatch();
                    }, RetryApi.TIMEOUT);
                } else {
                    callActionDispatch();
                }

            });
    });
}