

import React, { useEffect, useState } from 'react';
import { ApiRelativePaths } from "utils/GlobalConstants";

import SiteLayout from './containers/SiteLayout/SiteLayout';
import { HashRouter as Router, useLocation } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Authentication from 'containers/Authentication/Authentication';
import Loader from 'components/Loader/Loader';
import { setLanguage } from "store/Actions";
import { CommonUtils } from "utils/CommonUtils";


import axios from "axios";
import { useDispatch } from 'react-redux';
import { ErrorBoundary } from "react-error-boundary";
import { v4 as uuidv4 } from 'uuid';


const App: React.FC = () => {

	const [errorInfo, setErrorInfo] = useState('')
	const [errorMessage, setErrorMessage] = useState("");

	let urlData: any = {};

	const dispatch = useDispatch();

	const [loaderState, setLoaderState] = useState(false);


	const ErrorFallback = ({ error }: any) => {
		// we can customize the UI as we want
		return (
			<div>
				<h2>
					Oops! An error occurred
					{error.message}
					{error}
				</h2>
				{/* Additional custom error handling */}
			</div>
		);
	};

	

	const logError = (error: Error, info: { componentStack: string }) => {
		setErrorMessage(error.message);
		setErrorInfo(info.componentStack)

		const uuid = uuidv4();
		let tempObj = {
			"errorType": 7,
			"errorLog": error.message,
			"severity": 4,
			"filePath": info.componentStack,
			"urlPath": "",
			"logUUID": uuid
		}

		let generatedURL = process.env.REACT_APP_ANALYTICS_HOST + '/services/v1/log/error/frontend/';
		axios.post(generatedURL, tempObj, {
			headers: {
				'AppKey': process.env.REACT_APP_APP_KEY,
				'Content-Type': 'application/json',
				'AccessToken': localStorage.getItem('ACCESS_TOKEN'),
				'RefreshToken': localStorage.getItem('REFRESH_TOKEN'),
			}
		}).then(res => {
		}).catch(err => {
			console.log("error while persisting errorlog ", err);
		});
	};


	useEffect(() => {
		const errorHandler = (error: ErrorEvent) => {
			const uuid = uuidv4();
			let tempObj = {
				"errorType": 7,
				"errorLog": error.message,
				"severity": 4,
				"filePath": errorInfo,
				"urlPath": "",
				"logUUID": uuid
			}

			let generatedURL = process.env.REACT_APP_ANALYTICS_HOST + '/services/v1/log/error/frontend/';
			axios.post(generatedURL, tempObj, {
				headers: {
					'AppKey': process.env.REACT_APP_APP_KEY,
					'Content-Type': 'application/json',
					'AccessToken': localStorage.getItem('ACCESS_TOKEN'),
					'RefreshToken': localStorage.getItem('REFRESH_TOKEN'),
				}
			}).then(res => {
			}).catch(err => {
				console.log("error while persisting errorlog ", err);
			});
		}

		window.addEventListener('error', errorHandler);

		return () => {
			window.removeEventListener('error', errorHandler);
		};
	}, [])

	useEffect(() => {
		const query = new URLSearchParams(window.location.href);
		query.forEach((v, k) => {
			urlData[k] = v;
		});

		if (urlData['handoff-token']) {
			localStorage.setItem('IS_AUTHENTICATED', 'true');
		}

		if (window.location.href.includes('firstRedirect')) {
			setLoaderState(true);
			if (localStorage.getItem('ACCESS_TOKEN') && localStorage.getItem('REFRESH_TOKEN')) {
				generateAdminHandoffToken();
			} else {
				window.open(process.env.REACT_APP_ADMIN_URL + "?handoff-token=", "_self");
			}
		} else {
			setLoaderState(false)
		}
	}, []);



	const generateAdminHandoffToken = () => {
		let generatedURL = process.env.REACT_APP_ANALYTICS_HOST + '/accounts/v1/handoff-token/generate/';
		let data = {
			organization_id: localStorage.getItem('ORG_SWITCH_ID')
		}
		axios.post(generatedURL, data, {
			headers: {
				'AppKey': process.env.REACT_APP_APP_KEY,
				'Content-Type': 'application/json',
				'AccessToken': localStorage.getItem('ACCESS_TOKEN'),
				'RefreshToken': localStorage.getItem('REFRESH_TOKEN'),
			}
		}).then(res => {
			if (res.data.status === 'success') {
				let data = res.data;
				window.open(process.env.REACT_APP_ADMIN_URL + "?handoff-token=" + data.data['handoff-token'], "_self");
			}
		}).catch(err => {
			if (window.location.href.includes('firstRedirect')) {
				window.open(process.env.REACT_APP_ADMIN_URL + "?handoff-token=", "_self");
			}
		});
	}

	return (
		<ErrorBoundary
			onError={logError}
			FallbackComponent={ErrorFallback}
		>
			{/* <GlobalErrorHandler errorMsgInfo={errorInfo}> */}
			<div className="App" style={{ minHeight: '100vh!important' }}>
				<Router>
					{localStorage.getItem('IS_AUTHENTICATED') === 'true' || window.location.href.includes('handoff-token') ?
						loaderState ? (<Loader />) : (<SiteLayout />)

						: <Authentication />}
				</Router>
				<ToastContainer />
			</div>
			{/* </GlobalErrorHandler> */}
		</ErrorBoundary>



	);
};

export default App;
