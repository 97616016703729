import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ChartColors } from "utils/GlobalConstants";
import $ from 'jquery';
import { useErrorBoundary } from 'react-error-boundary';
import { useSelector } from "react-redux";
import { LanguageState } from "store/Reducers";
import { InsightLabels } from "labels";
import ReactApexChart from "react-apexcharts";

interface CurrentState {
    languageData: LanguageState
}

const HorizontalStackedBarChart = (props: any) => {
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    let data: any = {};
    let fetched_details = useSelector((state: CurrentState) => state);

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
    }, [fetched_details.languageData.languageData])
    data =
    {
        series: props.chartOptions,
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            colors: [ChartColors.lightseagreen, ChartColors.mediumpurple],
            grid: {
                show: true
            },
            yaxis: {
                show: true
            },
            xaxis: {
                categories: [
                    insightsLbls['goalCompletionPercent']
                ],
                min: 0,
                max: props.chartData.goalActualPlays > props.chartData.goalTargetPlays ? (Math.ceil(props.chartData.goalActualPlays / props.chartData.goalTargetPlays) * 100) : 100,
                range: 100,
                labels: {
                    formatter(value: any) {
                        return `${value}%`;
                    }
                },
            },
            tooltip: {
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    return (
                        '<div class="arrow_box">'
                        +
                        "<div>" + insightsLbls['actualPlays'] + ": <b>" + props.chartData.goalActualPlays + "</b>" + "</div>"
                        +
                        "<div>" + insightsLbls['targetPlays'] + ": <b>" + props.chartData.goalTargetPlays + "</b>" + "</div>"
                        +
                        "</div>"
                    );
                }
            },
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: '12px',
                    fontWeight: 'bold',
                    textOutline: false
                },
                background: {
                    enabled: true,
                    fill: ChartColors.lightseagreen,
                    foreColor: '#fff',
                    borderRadius: 2,
                    padding: 4,
                    opacity: 0.9,
                    borderWidth: 1,
                    borderColor: '#fff'
                },
                formatter: function (val: any, opt: any) {
                    return val + "%"
                },
                offsetX: 0,
                dropShadow: {
                    enabled: true
                }
            },
        },
    }

    $(props.refId).empty();

    return (
        <>
            <div id={props.refId}>
                {
                    props.chartData.goalActualPlays === 0 ?
                        (
                            <div className="noDataText-font textCenter-absolute">
                                {insightsLbls.noRecordFound}
                            </div>
                        )
                        :
                        (
                            Object.keys(data).length > 0 &&
                            <Chart options={data.options} series={data.series} type="bar" height={200} width={800} />
                        )
                }
            </div>
        </>
    );
}

export default React.memo(HorizontalStackedBarChart);

