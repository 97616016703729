import * as actionTypes from '../../Actions/ActionTypes';

export interface FeatureStatsState {
    featureStatsChartData: any,
    featureStatsSummaryTableData: any,
}

const initialState = {
    featureStatsChartData: [],
    featureStatsSummaryTableData: []
};


const featureStatsReducer = (state = initialState, action:any) => {
    switch (action.type) {
        case actionTypes.SET_FEATURE_STATS_CHART:
            return {
                ...state,
                featureStatsChartData: action.featureStatsChartData,
            };

        case actionTypes.SET_FEATURE_STATS_SUMMARY:
            return {
                ...state,
                featureStatsSummaryTableData: action.featureStatsSummaryTableData,
            };
        default:
            return state;
    }
};

export default featureStatsReducer;