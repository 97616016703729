import * as actionTypes from '../../Actions/ActionTypes';


export interface OtpState {
    otpData: any,
    otpResendData: any
}

const initialState = {
    otpData: [],
    otpResendData: []
};

const otpReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SET_OTP_DATA:
            return {
                ...state,
                otpData: action.otpData,
            };
        
        case actionTypes.SET_RESEND_OTP_DATA:
            return {
                ...state,
                otpResendData: action.otpResendData,
            };
        default:
            return state;
    }
};

export default otpReducer;
