import React, { useState, useEffect, useRef } from "react";
import withRouter from "hoc/withRouter";
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";

import { Form, FormGroup, FormControl } from "react-bootstrap";
import ReCaptchaV2 from "react-google-recaptcha"
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";


import * as loginActions from "store/Actions/index";
import loginReducer from "store/Reducers/Authentication/login";
import { LoginState } from "store/Reducers/Authentication/login";
import { useSelector, useDispatch } from "react-redux";
import { CommonUtils } from "utils/CommonUtils";
import { withReducer } from "store/withReducer";
import { getCall } from "utils/ApiCallActions";
import { setAuth } from "store/Actions";


import qs from "querystring";

import mainLogo_1 from "assets/js/images/mainLogo_1";
import mainLogo_2 from "assets/js/images/mainLogo_2";


import Loader from "components/Loader/Loader";
import CommonModal from "components/CommonModal/CommonModal";


import './Authentication.scss';
import { RetryApi } from "utils/GlobalConstants";



// import insightsLabels from "utils/stringConstants/trans_utils";
import { getSelectedLabels } from "utils/stringConstants/trans_utils";
import { InsightLabels } from "labels";
// let insightsLbls = insightsLabels;

interface CurrentState {
    login: LoginState,
}


const Login: React.FC = (props: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    const currentYear = new Date().getFullYear();
    const componentRef = useRef() as React.RefObject<HTMLDivElement>;
    let fetched_details = useSelector((state: CurrentState) => state.login);
    const dispatch = useDispatch();

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)

    useEffect(() => {
        if (insightsLbls) {
            getSelectedLabels.then((data: InsightLabels) => {
                setInsightsLbls(data);
            });
        }
    }, [insightsLbls])

    const [loginData, setLoginData] = useState({
        suspended: false,
        errorMsg: '',
        errorFlag: false,
        loader: false
    })

    const [state, setStateValues] = useState({
        username: "",
        password: "",
        touched: {
            username: false,
            password: false,
        },
        captcha: '',
        keepLogin: 0
    });

    const [sessionModal, setSessionModal] = useState({
        open: false,
    })

    let urlData: any = {};

    const [redirectData, setRedirectData] = useState({
        info: ''
    })

    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        localStorage.setItem('OTP_RELAY', '');
        window.scrollTo(0, 0);
        document.title = "Login";
        let queryParams = {
            // app_code: "",
        };
        navigate({
            pathname: `/login/`,
            search: qs.stringify(queryParams),
        });

        const query = new URLSearchParams(location.search);
        query.forEach((v, k) => {
            urlData[k] = v;
        });

        if (urlData['sessionExpired']) {
            setRedirectData((prevState: any) => {
                return {
                    ...prevState,
                    info: urlData['redirectUrl']
                }
            })
            openSessionModal();
        } else {
            setRedirectData((prevState: any) => {
                return {
                    ...prevState,
                    info: ''
                }
            })
        }
    }, []);


    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getLoginData = () => {
        setLoginData((prevState: any) => {
            return {
                ...prevState,
                loader: true
            }
        })

        let data = {
            'email_id': state.username,
            'password': state.password,
            'keep_login': state.keepLogin,
            'dual_auth_token': null,
            'captcha': state.captcha
        };

        loginActions.getLoginData(
            data,
            "LOGIN",
        ).then((res: any) => dispatch(res));
    }

    const getDashboardRedirectData = (apiErrorFlag?: string) => {
        let params = {};
        getCall(params, "CREATE_DASHBOARD", apiErrorFlag ?? '').then((data: any) => {
            if (data.result === 'success') {
                let defaultDash = '/dashboard/';
                for (let item of data.data) {
                    if (item['isDefault']) {
                        defaultDash = '/custom-dashboard/' + item['dashboardNumber'] + '/';
                        break;
                    }
                }

                let queryParams = {
                    app_id: "",
                };
                navigate({
                    pathname: defaultDash,
                    search: qs.stringify(queryParams),
                });

                window.location.reload();
            } else if (data.result === 'retry') {
                setTimeout(() => {
                    getDashboardRedirectData('retry')
                }, RetryApi.TIMEOUT)
            }
        }
        );
    }


    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const surveyRedirect = () => {
        let queryParams = {
            app_code: "",
            app_id: "",
        }
        navigate({
            pathname: `/survey/manage-survey/`,
            search: qs.stringify(queryParams)
        })
        window.location.reload();
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        switch (name) {
            case "username":
                setStateValues((prevState) => {
                    return {
                        ...prevState,
                        username: name,
                    };
                });
                break;

            case "password":
                setStateValues((prevState) => {
                    return {
                        ...prevState,
                        password: name,
                    };
                });
                break;

            default:
                break;
        }

        setStateValues((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    };

    const handleTouch = (e: any) => {
        let name = e.target.name;
        let touched: any = state.touched;
        if (name && touched[name] !== true) {
            touched[name] = true;
            setStateValues((prevState) => {
                return {
                    ...prevState,
                    touched,
                };
            });
        }
    };


    const handleToken = (e: any) => {
        setStateValues((prevState: any) => {
            return {
                ...prevState,
                captcha: e
            }
        })
    };

    const handleExpire = () => {
        setStateValues((prevState: any) => {
            return {
                ...prevState,
                captcha: null
            }
        })
    };

    const onRemenberCheck = (e: any) => {
        setStateValues((prevState: any) => {
            return {
                ...prevState,
                keepLogin: e.target.checked ? 1 : 0
            }
        })
    }

    const closeSessionModal = () => {
        setSessionModal((prevState: any) => {
            return {
                ...prevState,
                open: false
            }
        })
    }

    const openSessionModal = () => {
        setSessionModal((prevState: any) => {
            return {
                ...prevState,
                open: true,
            }
        })
    }


    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // To set logon data and redirect to landing page
    useEffect(() => {
        if (fetched_details?.loginData?.data !== undefined && fetched_details?.loginData?.result === 'success') {
            if (fetched_details.loginData.data.data) {
                localStorage.setItem('OTP_RELAY', fetched_details?.loginData?.data?.data.otp_relay);
                localStorage.setItem('EMAIL_ID', state.username);

                var authData: object = {
                    userName: state.username,
                    password: state.password,
                    keepLogin: state.keepLogin,
                }

                dispatch(setAuth(authData));

                navigate({
                    pathname: '/otp/',
                });
            } else {

                if (fetched_details?.loginData?.data?.user?.is_suspend === '0') {

                    // to show suspended error msg
                    setLoginData((prevState: any) => {
                        return {
                            ...prevState,
                            suspended: false,
                            loader: false
                        }
                    })

                    localStorage.setItem('SESSION_EXP', 'false');
                    localStorage.setItem('IS_AUTHENTICATED', 'true');
                    localStorage.setItem('EMAIL_ID', state.username);

                    CommonUtils.storeAuthData(fetched_details?.loginData.data.user);
                    CommonUtils.routeOnLogin();

                    if (localStorage.getItem('USER_ROLE') !== 'creator') {
                        if (redirectData.info === "") {
                            getDashboardRedirectData();
                        } else {
                            const data = JSON.parse(atob(redirectData.info));
                            if (data['emailId'] === state.username) {

                                var sessionData: object = {
                                    switchAppCode: data['switchAppCode'],
                                    switchAppId: data['switchAppId'],
                                    selectedOptn: data['selectedOptn'],
                                    selectedAppName: data['selectedAppName'],
                                    orgName: data['orgName'],
                                    orgImgUrl: data['orgImgUrl'],
                                    orgId: data['orgId'],
                                }

                                setRedirectData((prevState: any) => {
                                    return {
                                        ...prevState,
                                        info: ''
                                    }
                                })


                                if (data['urlPath'].includes('?')) {
                                    let url_path = data['urlPath'] + '&redirectInfo=' + btoa(JSON.stringify(sessionData));

                                    window.location.replace(url_path)
                                    window.location.reload();
                                } else {
                                    let url_path = data['urlPath'] + '?redirectInfo=' + btoa(JSON.stringify(sessionData));

                                    window.location.replace(url_path)
                                    window.location.reload();
                                }
                            } else {
                                getDashboardRedirectData();
                            }
                        }
                    } else {
                        surveyRedirect();
                    }
                } else {
                    setLoginData((prevState: any) => {
                        return {
                            ...prevState,
                            suspended: true
                        }
                    })
                }
            }
        } else if (fetched_details?.loginData?.result === "error") {
            setStateValues((prevState: any) => {
                return {
                    ...prevState,
                    username: "",
                    password: "",
                    touched: {
                        username: false,
                        password: false,
                    },
                    captcha: ''
                }
            })

            setLoginData((prevState: any) => {
                return {
                    ...prevState,
                    errorMsg: fetched_details.loginData?.data?.data?.message[0] ?? fetched_details.loginData.data?.error,
                    errorFlag: true,
                    loader: false
                }
            })
        }
    }, [fetched_details])

    // On session expire show session expire modal
    useEffect(() => {
        setTimeout(() => {
            if (sessionModal.open) {
                setSessionModal((prevState: any) => {
                    return {
                        ...prevState,
                        open: false,
                    }
                })
            }
        }, 10000);
    }, [sessionModal.open])


    return (
        <React.Fragment>
            <section className="login" ref={componentRef}>
                <div className="loginContainer">
                    <div className="loginContainerParts">
                        <div
                            className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 loginForm">
                            <React.Fragment>
                                <div className="loginLogo">
                                    <img className="mainLogo" alt="" src={process.env.REACT_APP_ENV === 'prod' ? mainLogo_1 : mainLogo_2} />
                                </div>

                                {loginData.loader
                                    ? (
                                        <div className="login-loaderHeight width100 displayFlex height100-percent">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <Loader></Loader>
                                            </div>
                                        </div>
                                    )
                                    : (
                                        <>
                                            <div className="w-60">
                                                <h3 className="loginHead"></h3>
                                                {loginData.errorFlag && (
                                                    <div className="alert alert-danger pointer errorMsg padding10" data-dismiss="alert">
                                                        {loginData.errorMsg}
                                                    </div>
                                                )}


                                                {loginData.suspended
                                                    && (
                                                        <div className="alert alert-warning pointer" data-dismiss="alert">
                                                            <span className="">
                                                                <div>Please contact <b> <a href={insightsLbls.supportLink} className="supportLink">{insightsLbls.displaySupportLink}</a></b></div>
                                                            </span>
                                                        </div>
                                                    )}
                                                <div>
                                                    <Form
                                                        className="font14"
                                                        noValidate
                                                    >
                                                        <FormGroup controlId="formEmail">
                                                            <div className="font700 marginBottom5">
                                                                {insightsLbls.emailId}
                                                            </div>
                                                            <FormControl
                                                                name="username"
                                                                type="email"
                                                                onInput={handleChange}
                                                                onFocus={handleTouch}
                                                                autoComplete="off"
                                                            />
                                                        </FormGroup>
                                                        <FormGroup controlId="formpassword">
                                                            <div className="font700 marginBottom5 marginTop-10">
                                                                {insightsLbls.password}
                                                            </div>
                                                            <FormControl
                                                                name="password"
                                                                type="password"
                                                                placeholder="**********"
                                                                onInput={handleChange}
                                                                onFocus={handleTouch}
                                                                autoComplete="off"
                                                            />
                                                        </FormGroup>
                                                    </Form>
                                                    <div className="loginRemember marginTop-10">
                                                        <div className="checkbox pull-left marginTop-0">
                                                            <div className="custom-control custom-checkbox mr-sm-2 check-container padding-chk-0 marginTop-0">
                                                                <label className="check-container displayFlex alignCenter exportCheck marginBottom-0 marginTop-0 marginLeft--5">
                                                                    <input type="checkbox" className={"custom-control-input chk checkbox"}
                                                                        id="loginCheckbox" name={"keep_logged_in"}
                                                                        onChange={(ev) => onRemenberCheck(ev)}></input>
                                                                    <span className="checkmark checkmarkBox-color displayInlineBlock top0"></span>
                                                                    <span className="marginLeft-25">{insightsLbls.remember}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="pull-right d-flex align-items-center justify-content-between">
                                                            {/* style="text-decoration: none;" */}
                                                            {/* routerLink="/password" */}
                                                            <Link to={"/password"} className="passwordLink">{insightsLbls.forgotPassword}</Link>
                                                        </div>
                                                    </div>
                                                    <FormGroup className="loginCaptcha">
                                                        <ReCaptchaV2
                                                            sitekey={process.env.REACT_APP_ANALYTICS_HOST === "https://analytics-qa.guideme.io" ? "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" : process.env.REACT_APP_SITE_KEY}
                                                            onChange={handleToken}
                                                            onExpire={handleExpire}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup className="marginTop-25">
                                                        <button
                                                            className="btn btn-fla modal-save-btn loginBtn width-100"
                                                            onClick={() => getLoginData()}
                                                            disabled={(state.username && state.password && state.captcha) ? false : true}>{insightsLbls.buttonText}</button>
                                                    </FormGroup>
                                                </div>

                                            </div>
                                        </>
                                    )}

                                {/* [className.d-none]="!calling" */}
                                <small className="loginFooter">
                                    {insightsLbls.supportLinkText} <a href={insightsLbls.supportLink} className="supportLink">{insightsLbls.displaySupportLink}</a><br />
                                    {insightsLbls.copyright} {currentYear} - {insightsLbls.brandName} <br /> {insightsLbls.version}
                                </small>
                            </React.Fragment>
                        </div>
                        <div
                            className="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 d-none d-sm-none d-md-none d-lg-flex d-xl-flex align-items-center justify-content-center bg-login">
                            <img height="100%" width="100%" alt="" src={require(`${process.env.REACT_APP_SECTION_IMAGE}`)} />
                        </div>
                    </div>
                </div>
            </section>

            <CommonModal
                modalState={sessionModal.open}
                dispatchModalState={closeSessionModal}
                modalTitle={insightsLbls.modalTitle}
                size={'md'}
                footerDisabled={false}
                exportModal={false}
                padding={true}
                modalDialogClass={''}
                dispatchModalOk={() => closeSessionModal()}
                okBtn={'Ok'}
            >
                <div className="deleteChart" style={{textAlign: "center"}}>
                        <p className="">{insightsLbls.sessionExpiredMsg}</p>
                        <p className="">{insightsLbls.reLogin}</p>
                </div>
            </CommonModal>
        </React.Fragment>
    )
}
export default withRouter(withReducer("login", loginReducer)(Login));