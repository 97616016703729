import React, { useEffect, useState } from "react";
import "./ConversationResponse.scss";
import { InsightLabels } from "labels";
import { useSelector } from "react-redux";
import { LanguageState } from "store/Reducers";

interface CurrentState {
    languageData: LanguageState
}


const ConversationResponse = (props: any) => {
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    let fetched_details = useSelector((state: CurrentState) => state);

    useEffect(() => {
        if(Object.keys(fetched_details.languageData.languageData).length) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
    },[fetched_details])

    return (
        <div className="response-card colFlex" key={props.data.responseCode}>
            <div className="row userDetailContainer">
                <div className="col-sm-10 col-md-10 col-lg-10">
                    <span className="fontW600 font13 mr10">{props.data.userName}</span>
                    <span className="font12 text-secondary">{props.data.userEmail}</span>
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2">
                    <i className="fa fa-external-link responseBtn" onClick={props.openConversationModal}></i>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-10 col-md-10 col-lg-10 font12">
                    <span className="">{insightsLbls.lastUpdated}:&nbsp;</span>
                    <span className="font600">{props.data.timestamp}</span>
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2">
                    <div className="alignCenter responseCount">
                        {insightsLbls.remarks}:&nbsp;<span className="font600">{props.data.remarkCounts}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConversationResponse;