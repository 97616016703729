import * as ActionTypes from '../ActionTypes';
import axiosInstance, { getInstance } from '../../../network/apis/interceptor';
import { CommonUtils } from '../../../utils/CommonUtils';
import { ApiRelativePaths, RetryApi } from '../../../utils/GlobalConstants';


let ApiPaths: any = ApiRelativePaths;


export const setSurveyListData = (surveyListData: any) => {
    return {
        type: ActionTypes.SET_SURVEY_LIST_DATA,
        surveyListData: surveyListData
    }
};


export const setSurveyData = (surveyData: any) => {
    return {
        type: ActionTypes.SET_SURVEY_DATA,
        surveyData: surveyData
    }
};


export const getManageSurveyData = (params: any, url_path: any, caseType: any, apiErrorFlag: string, firstTimeload?: boolean) => {
    return new Promise((resolve, reject) => {
        let path = ApiPaths[url_path];
        let data:any = {};
        let paramObj = {};
        let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
        getInstance('survey/manage-survey/');

        axiosInstance.get(generatedURL)
            .then(res => {
                data = {
                    "result": "success",
                    "data": res.data.data,
                    "firstTimeload": firstTimeload ?? false,
                    "requestUUID": res.data.requestUUID,
                };
            })
            .catch(err => {
                data = {
                    "result": "error",
                    "data": err,
                    "firstTimeload": firstTimeload ?? false,
                };
            })
            .then(() => {
                const callActionDispatch = () => {
                    switch (caseType) {
                        case ActionTypes.SET_SURVEY_LIST_DATA:
                            resolve(setSurveyListData(data));
                            break;

                        case ActionTypes.SET_SURVEY_DATA:
                            resolve(setSurveyData(data));
                            break;

                        default:
                            break;
                    }
                }

                if (data.result === 'retry') {
                    setTimeout(() => {
                        callActionDispatch();
                    }, RetryApi.TIMEOUT);
                } else {
                    callActionDispatch();
                }

            });
    });
}