import React from "react";
import { useEffect, useRef, useState } from "react";
import "./SectionFilters.scss";

import { useSelector } from "react-redux";
import { LanguageState } from "store/Reducers";
import { InsightLabels } from "labels";
interface CurrentState {
    languageData: LanguageState
}

const SectionFilters = (props: any) => {
    let fetched_details = useSelector((state: CurrentState) => state);

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)


    useEffect(() => {
        if(fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
     
    },[fetched_details.languageData.languageData])
    
    useEffect(() => {

        // const handleScroll = () => {
            // const currentScrollY = window.scrollY;
            const filterDiv = document.getElementById('filters_container');

            // if(filterDiv && currentScrollY > 200) {
                filterDiv.style.backgroundColor = 'white';
                filterDiv.style.top = '74px';
                filterDiv.style.borderBottom = '2px solid lightgrey';


            // } else {
            //     filterDiv.style.backgroundColor = 'transparent';
            //     filterDiv.style.top = '90px';
            //     filterDiv.style.borderBottom = '2px solid transparent';

            // }

        // };

        // window.addEventListener("scroll", handleScroll, { passive: true });

        // return () => window.removeEventListener("scroll", handleScroll);

    }, []);

    return (
        <div className="height74 filter-outer-div" data-html2canvas-ignore="true" id="filters_container">
                {props.children}
        </div>

    )
}

export default SectionFilters;