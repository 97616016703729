import * as actionTypes from '../../Actions/ActionTypes';

export interface ChatBotStatsState {
    chatBotStatsChartData: any,
    chatBotStatsSummaryTableData: any,
}

const initialState = {
    chatBotStatsChartData: [],
    chatBotStatsSummaryTableData: []
};


const chatBotStatsReducer = (state = initialState, action:any) => {
    switch (action.type) {
        case actionTypes.SET_CHATBOT_STATS_CHART:
            return {
                ...state,
                chatBotStatsChartData: action.chatBotStatsChartData,
            };

        case actionTypes.SET_CHATBOT_STATS_SUMMARY:
            return {
                ...state,
                chatBotStatsSummaryTableData: action.chatBotStatsSummaryTableData,
            };
        default:
            return state;
    }
};

export default chatBotStatsReducer;