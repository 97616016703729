import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import  withRouter  from "hoc/withRouter";
import { useSelector } from "react-redux";
import "../../style.scss";
import "./Maintenance.scss";
import { InsightLabels } from "labels";
import { LanguageState } from "store/Reducers";

interface CurrentState {
    languageData: LanguageState
}
const Maintenance = () => {
    let fetched_details = useSelector((state: CurrentState) => state);

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)


    useEffect(() => {
        if(fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
     
    },[fetched_details.languageData.languageData])
    
    const ButtonMailto = ({ mailto, label }:any) => {
        return (
            <Link
                className="supportLink marginLeft-5"
                to='#'
                onClick={(e) => {
                    window.location = mailto;
                    e.preventDefault();
                }}
            >
                {label}
            </Link>
        );
    };

    return (
        <section className="demo maintenanceMain-div">
            <div className="message">
                <h2> {insightsLbls.maintenanceInProgress}</h2>
                <h4 style = {{ lineHeight: 2.2}}>
                    <span style={{display: 'block'}}> {insightsLbls.pleaseContactUs}
                        <ButtonMailto label={insightsLbls.mailTo} mailto={"mailto:" + `${insightsLbls.mailTo}` } />
                    </span>
                </h4>
            </div>
        </section>
    )
}

export default withRouter(Maintenance);

