import React, { useState, useEffect } from "react";
import CommonModal from "../CommonModal";
import "./ConversationModal.scss";

import axiosInstance from '../../../network/apis/interceptor';
import { CommonUtils } from '../../../utils/CommonUtils';
import { ApiRelativePaths, RetryApi } from '../../../utils/GlobalConstants';
import { LanguageState } from "store/Reducers";

import Loader from "components/Loader/Loader";
import { toast } from "react-toastify";
import { InsightLabels } from "labels";
import { useSelector } from "react-redux";
import { useErrorBoundary } from 'react-error-boundary';
interface CurrentState {
    languageData: LanguageState
}


const ConversationModal = (props: any) => {
    const { showBoundary } = useErrorBoundary();
    let ApiPaths: any = ApiRelativePaths;

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    ;
    let fetched_details = useSelector((state: CurrentState) => state);

    const [isViewQuestion, setIsViewQuestion] = useState(false);
    const [isViewRemark, setIsViewRemark] = useState(false);
    const [apiDetails, setApiDetails] = useState({
        isApiLoading: false,
        isApiLoaded: false,
        isError: false,
        errorMsg: "",
        data: {}
    });
    const [postRemarkDetails, setPostRemarkDetails] = useState({
        isLoading: false,
    })

    useEffect(() => {
        if (Object.keys(fetched_details.languageData.languageData).length) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
    }, [fetched_details])

    useEffect(() => {
        viewQuestionHandler();
    }, [])

    const viewQuestionHandler = () => {
        setIsViewQuestion(isViewQuestion => !isViewQuestion);
        setIsViewRemark(false);
        getResponseDetail();
    }

    const viewRemarkHandler = () => {
        setIsViewQuestion(false);
        setIsViewRemark(isViewRemark => !isViewRemark);
        getResponseDetail();
    }

    const getResponseDetail = (isDefaultVal?: boolean) => {
        try {
            if (!apiDetails.isApiLoaded && !apiDetails.isApiLoading) {
                setApiDetails((prevState) => {
                    return {
                        ...prevState,
                        isApiLoading: true
                    }
                })
                let path = ApiPaths["CHATBOT_DETAIL_BY_RESPONSE_CODE"];
                const dateZone: any = new Date().toString();
                const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];
                let params = {
                    "app_code": props.appCode,
                    "time_zone": timeZone,
                    "conversation_code": props.conversationCode,
                    "response_code": props.responseCode
                };
                let generatedURL = CommonUtils.URLGenerator(params, path, {});
                axiosInstance.get(generatedURL)
                    .then(res => {
                        setApiDetails((prevState) => {
                            return {
                                ...prevState,
                                isApiLoading: false,
                                isApiLoaded: true,
                                isError: false,
                                errorMsg: '',
                                data: res.data.data
                            }
                        })
                    })
                    .catch(err => {
                        setApiDetails((prevState) => {
                            return {
                                ...prevState,
                                isApiLoading: false,
                                isApiLoaded: true,
                                isError: true,
                                errorMsg: err,
                                data: {}
                            }
                        })
                    })
            }
        } catch (error) {
            showBoundary(error)
        }

    };

    const postRemark = () => {
        try {
            setPostRemarkDetails((prevState) => {
                return {
                    ...prevState,
                    isLoading: true
                }
            })
            let path = ApiPaths["CHATBOT_POST_REMARK"];
            let params = {};
            let generatedURL = CommonUtils.URLGenerator(params, path, {});
            let data = {
                "appCode": props.appCode,
                "conversationCode": props.conversationCode,
                "responseCode": props.responseCode,
                "remarkText": btoa(remarkText)
            }
            axiosInstance.post(generatedURL, data)
                .then(res => {
                    setPostRemarkDetails((prevState) => {
                        return {
                            ...prevState,
                            isLoading: false
                        }
                    })
                    setRemarkText("");
                    setApiDetails((prevState) => {
                        return {
                            ...prevState,
                            isApiLoading: false,
                            isApiLoaded: false,
                            isError: false,
                            errorMsg: '',
                        }
                    })
                    toast.success(insightsLbls['remarkPostSuccessful'], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    viewRemarkHandler();
                    setTimeout(() => {
                        toast.dismiss();
                    }, 5000);
                })
                .catch(err => {
                    setPostRemarkDetails((prevState) => {
                        return {
                            ...prevState,
                            isLoading: false
                        }
                    });

                    toast.error(insightsLbls["remarkPostFailed"], {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTimeout(() => {
                        toast.dismiss();
                    }, 5000);
                })
        } catch (error) {
            showBoundary(error)
        }


    };

    const getCard = (ques: any, quesType: any, ans: any) => {
        return (
            <div className="inner-card">
                <div className="inner-card-title">
                    {ques} &nbsp; <span>({quesType})</span>
                </div>
                <div className="inner-card-body">
                    {ans}
                </div>
            </div>
        )
    }


    const getQuestionTemplate = () => {
        let template: any[] = [];
        (apiDetails.data["questionAndAnswers"] as []).map((qaEntry) => {
            template.push(getCard(qaEntry["question_name"], qaEntry["question_type"], qaEntry["question_answer"]));
        })

        if (template.length != 0) {
            return template;
        } else {
            return <div className="noDataFound"><p>{insightsLbls.noDataFound}</p></div>
        }
    }

    const getRemark = (userName: any, userEmail: any, timestamp: any, remarkText: any) => {
        let remarkTextConverted = atob(remarkText)
        return (
            <div className="inner-card">
                <div className="inner-card-title">
                    <div className="remarkData">
                        <div className="font600 font12">
                            <span className="mr-10">{userName}</span>
                            <span className="text-secondary">{userEmail}</span>
                        </div>
                        <div className="font12 text-secondary mt-2">
                            <span className="">{timestamp}</span>
                        </div>
                    </div>
                </div>
                <div className="inner-card-body">
                    <span className="">{remarkTextConverted}</span>
                </div>
            </div>
        )
    }

    const getRemarkTemplate = () => {
        let template: any = [];
        (apiDetails.data["remarks"] as []).map((remarkEntry) => {
            template.push(getRemark(remarkEntry["userName"], remarkEntry["userEmail"], remarkEntry["timestamp"], remarkEntry["remarkText"]));
        })
        if (template.length == 0) {
            template.push(<div className="noDataFound"><p>{insightsLbls.noRemarkFound}</p></div>);
        }

        return (
            <div>
                <div className="inputSection displayFlex">
                    <div className="remarkInputContainer">
                        <input className="remarkInput" type="text" placeholder={insightsLbls.remarksPlaceholder} value={remarkText} onChange={handleInputChange} />
                    </div>
                    <div className="loaderArea">
                        {postRemarkDetails.isLoading ? <Loader></Loader> :
                            <button className="createCat-button">
                                <i className="fa fa-plus font20" aria-hidden="true" onClick={postRemark}></i>
                            </button>}
                    </div>
                </div>
                <div className="remarksSection">
                    {template}
                </div>
            </div>
        )
    }

    const [remarkText, setRemarkText] = useState('');

    const handleInputChange = (e) => {
        setRemarkText(e.target.value);
    };
    return (
        <div className="conversation-modal">
            <CommonModal modalState={props.data.isModalOpen} modalTitle="Conversation Response" size={'lg'} footerDisabled={true} exportModal={false}
                padding={true} dispatchModalState={props.closeConversationModal} modalDialogClass={"conversation-modal"} key="">
                <div className="custom-modal-body">
                    <div className="userDetailModalContainer">
                        <div className="font600 font13">{props.data.userName}</div>
                        <div className="text-secondary font12">{props.data.userEmail}</div>
                        <div className="font12">
                            <span className="">{insightsLbls.lastUpdated}:&nbsp;</span>
                            <span className="font600">{props.data.timestamp}</span>
                        </div>
                    </div>

                    <div className={`card ${isViewQuestion ? 'open' : ''}`}>
                        <div className="card-title" id="toggleButton" onClick={viewQuestionHandler}>{insightsLbls.viewQuestions}</div>
                        {isViewQuestion && <div className="card-content" id="cardContent">
                            {
                                apiDetails.isApiLoading ? <Loader></Loader> : (
                                    apiDetails.isApiLoaded ?
                                        getQuestionTemplate()
                                        : ''
                                )
                            }
                        </div>}
                    </div>
                    <div className={`card ${isViewRemark ? 'open' : ''}`}>
                        <div className="card-title" id="toggleButton" onClick={viewRemarkHandler}>{insightsLbls.viewRemarks}</div>
                        {isViewRemark && <div className="card-content" id="cardContent">
                            {
                                apiDetails.isApiLoading ? <Loader></Loader> : (
                                    apiDetails.isApiLoaded ?
                                        getRemarkTemplate()
                                        : ''
                                )
                            }
                        </div>}
                    </div>
                </div>
            </CommonModal>
        </div>

    );
}

export default ConversationModal;