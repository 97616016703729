import React, { useRef, useState, useEffect } from "react";


import $ from "jquery";
import { ChartColors } from '../../utils/GlobalConstants';
import * as d3 from "d3";
import * as cloud from 'd3-cloud';

import { useErrorBoundary } from 'react-error-boundary';

const WordCloud = (props) => {
    const { showBoundary } = useErrorBoundary();
    const wordCloudRef = useRef(null);
    let cloudFile = cloud();

    const colors = [ChartColors.yellowsandybrown, ChartColors.orangesandybrown, ChartColors.salmon, ChartColors.lightseagreen, ChartColors.darkturquoise];
    const [colors_range, setColors_range] = useState({
        rangeData: [],
        rangeFetched: false
    });

    useEffect(() => {
        setTimeout(() => {
            drawWordCloud(props.chartData)
        }, 0);
    }, [props.chartData])

    const drawWordCloud = (wordData) => {
        try {
            $('#cloudChart svg').remove();

            let toolTipArr = document.getElementsByClassName('tooltipVisibility');
            for (let itr1 = 0; itr1 < (toolTipArr.length); itr1++) {
                toolTipArr[itr1].remove();
            }

            let max = 0;
            let boudaryValues = []
            for (let i = 0; i < wordData.length; i++) {
                if (max < wordData[i].value) {
                    max = wordData[i].value;
                }
            }

            let number = max;
            do {
                number++;
            }
            while ((number % 5) !== 0);

            let BoundarySet = number / 5;

            let itrx = 0;
            while (boudaryValues.length < 6) {
                if (Math.round(BoundarySet * itrx) != 1) {
                    boudaryValues.push(Math.round(BoundarySet * itrx));
                }
                itrx++;
            }

            d3.select('body')
                .append('div')
                .style('position', 'absolute')
                .style('z-index', '10')
                .style('display', 'none')
                .style('color', '#222')
                .style('padding', '8px')
                .style('background-color', 'rgba(255, 255, 255, 0.75)')
                .style('border-radius', '4px')
                .style('border', '1px solid #666')
                .style('font', '14px sans-serif')
                .attr('class', 'tooltipVisibility')
                .text('tooltip');

            var svg_location = "#cloudChart";
            var width = 695;
            var height = 450;

            // var fill = d3.scaleOrdinal().range(schemeCategory20);

            var svg = d3.select(svg_location).append("svg");

            // var focus = svg;
            //   .attr("transform", "translate(" + [width/2, height/2+margin.top] + ")")

            var xScale = d3.scaleLinear()
                .domain([0, d3.max(wordData, function (d) {
                    return d.value;
                })
                ])
                .range([20, 50]);

            cloudFile.size([width, height])
                .timeInterval(20)
                .words(wordData)
                .fontSize(function (d) {
                    return xScale(d.value) + 10;
                })
                .text(function (d) { return d.key; })
                .rotate(function () { return ~~(Math.random() * 2) * 0; })
                // .font("Impact")
                .on("end", draw)
                .start();

            // Define the div for the tooltip
            var div = d3.select("body").append("div")
                .attr("class", "tooltip")
                .style("opacity", 0);


            let colorsRange = []
            for (let i = 0; i < 5; i++) {
                colorsRange[i] = {};
                colorsRange[i].color = colors[i];
                colorsRange[i].range = boudaryValues[i];
            }

            colorsRange[0].range = 1;
            for (let i = 1; i < 5; i++) {
                colorsRange[i].range = boudaryValues[i];
            }

            setColors_range((prevState) => {
                return {
                    ...prevState,
                    rangeData: colorsRange,
                    rangeFetched: true
                }
            })

            function draw(words) {
                svg
                    .attr("width", width)
                    .attr("height", height)
                    //  .style("position", 'relative')
                    //   .style("left", '50%')
                    //   .style("transform", 'translateX(-50%)')
                    .append("g")
                    .attr("transform", "translate(" + [width >> 1, height >> 1] + ")")
                    .selectAll("text")
                    .data(words)
                    .enter().append("text")
                    .style("font-size", function (d) { return xScale(d.value) + "px"; })
                    .style("font-family", "Impact")
                    .style("fill", function (d) {
                        if (d.value < boudaryValues[1]) {
                            return colors[0];

                        } else if (d.value >= boudaryValues[1] && d.value < boudaryValues[2]) {
                            return colors[1];

                        } else if (d.value >= boudaryValues[2] && d.value < boudaryValues[3]) {
                            return colors[2];

                        } else if (d.value >= boudaryValues[3] && d.value < boudaryValues[4]) {
                            return colors[3];

                        } else {
                            return colors[4];
                        }

                        // return fill(1); 
                    })
                    .attr("text-anchor", "middle")
                    .attr("transform", function (d) {
                        return "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")";
                    })
                    .text(function (d) { return d.key; })
                    .on("mouseover", function (event, d) {
                        div.transition()
                            .duration(200)
                            .style("opacity", .9);

                        div.html(d.key + ": <b>" + d.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "</b>")
                            .style('color', '#222')
                            .style('padding', '8px')
                            .style('background-color', 'rgba(255, 255, 255, 1)')
                            .style('border-radius', '4px')
                            .style('border', '1px solid #666')
                            .style('height', '15px')
                            .style('box-sizing', 'content-box')

                            .style('z-index', '100')
                            .style('font', '14px sans-serif')
                            .style("left", (event.pageX) + "px")
                            .style("top", (event.pageY - 28) + "px")
                            .attr('class', 'tooltip tooltipVisibility')
                            .style('display', 'block');

                    })
                    .on("mouseout", function (d) {
                        div.transition()
                            .duration(500)
                            .style('display', 'none')
                            .style("opacity", 0);
                    });
            }
        } catch (error) {
            showBoundary(error)
        }

    }



    return (
        <>
            <div ref={wordCloudRef} id="cloudChart"></div>

            <div className="rangeBox">
                {colors_range.rangeFetched && colors_range.rangeData.map((data, index) => {
                    return (
                        <div className="keyword-range" >
                            <span className="keyword-range" style={{ backgroundColor: data.color }}></span>
                            <span className="fontRange keyword-range"> ≥ {data.range}</span>
                        </div>
                    )
                })}
            </div>
        </>

    );
}
export default React.memo(WordCloud);
