import * as ActionTypes from '../ActionTypes';
import axiosInstance, { getInstance } from '../../../network/apis/interceptor';
import { CommonUtils } from '../../../utils/CommonUtils';
import {  ApiRelativePaths, RetryApi } from '../../../utils/GlobalConstants';


let ApiPaths: any = ApiRelativePaths;


export const setTooltipActivityChart = (tooltipActivityChart: any) => {
    return {
        type: ActionTypes.SET_TOOLTIP_ACTIVITY_CHART,
        tooltipActivityChart: tooltipActivityChart
    }
};

export const setPowerFormChart = (powerformChart: any) => {
    return {
        type: ActionTypes.SET_POWER_FORM_CHART,
        powerformChart: powerformChart
    }
};

export const setTooltipList = (tooltipList: any) => {
    return {
        type: ActionTypes.SET_TOOLTIP_LIST,
        tooltipList: tooltipList
    }
};

export const setPowerformSummary = (powerformSummary: any) => {
    return {
        type: ActionTypes.SET_POWER_FORM_SUMMARY,
        powerformSummary: powerformSummary
    }
};

export const setSmartTipSummary = (smartTipSummary: any) => {
    return {
        type: ActionTypes.SET_SMART_TIP_SUMMARY,
        smartTipSummary: smartTipSummary
    }
};

export const getTooltipData = (params: any, url_path: any, caseType: any, apiErrorFlag: string, firstTimeload?: boolean, extraData?:any) => {
    return new Promise((resolve, reject) => {
        let path = ApiPaths[url_path];
        let data:any = {};
        let paramObj = {};
        let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
        getInstance('guide-insights/tooltips/');


        if (extraData){
            generatedURL = generatedURL + extraData;
        }
        axiosInstance.get(generatedURL)
            .then(res => {
                data = {
                    "result": "success",
                    "data": res.data.data,
                    "firstTimeload": firstTimeload ?? false,
                    "requestUUID": res.data.requestUUID,
                };
            })
            .catch(err => {
                if (apiErrorFlag === '') {
                    data = {
                        "result": "retry",
                        "data": err,
                        "firstTimeload": firstTimeload ?? false,
                    };
                } else {
                    data = {
                        "result": "error",
                        "data": err,
                        "firstTimeload": firstTimeload ?? false,
                        "errorCode":err?.response?.status ?? '',
                    };
                }
            })
            .then(() => {
                const callActionDispatch = () => {
                    switch (caseType) {
                        case ActionTypes.SET_TOOLTIP_ACTIVITY_CHART:
                            resolve(setTooltipActivityChart(data));
                            break;

                        case ActionTypes.SET_POWER_FORM_CHART:
                            resolve(setPowerFormChart(data));
                            break;

                        case ActionTypes.SET_TOOLTIP_LIST:
                            resolve(setTooltipList(data));
                            break;

                        case ActionTypes.SET_POWER_FORM_SUMMARY:
                            resolve(setPowerformSummary(data));
                            break;

                        case ActionTypes.SET_SMART_TIP_SUMMARY:
                            resolve(setSmartTipSummary(data));
                            break;

                        default:
                            break;
                    }
                }
                
                if (data.result === 'retry') {
                    setTimeout(() => {
                        callActionDispatch();
                    }, RetryApi.TIMEOUT);
                } else {
                    callActionDispatch();
                }

            });
    });
}