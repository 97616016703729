import React from 'react';
import { ChangeEvent } from "react";
import { useErrorBoundary } from 'react-error-boundary';
import "./Filter.scss";


const Filter = (props: any) => {
    const { showBoundary } = useErrorBoundary();
    const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
        try {
            props.clickEvent(props.filterType, e.target.value);
        } catch (error) {
            showBoundary(error)
        }
    };



    return (

        <div className={"font13 paddingEntries" + props.filterID}>
            <div className="selectCustomColor displayGrid">
                <span className="startAlign">{props.filterName}</span>
                <select
                    className={"entries-dropdown" + props.filterID}
                    onChange={(event) => onChange(event)}
                    disabled={props.filterDisabled}
                >
                    {props.data.map((option, index) => {
                        return <option value={option.key} key={index}>{option.value}</option>;
                    })}
                </select>
            </div>
        </div>

    )
}

export default React.memo(Filter);