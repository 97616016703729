import React from 'react';


import "./SectionHeader.scss";


const CardContainer = (props: any) => {

    return (

        <div className={props.div1Classes}>
            <div className={props.div2Classes}>
                <div className={props.div3Classes}>
                    {props.children}
                </div>
            </div>
        </div>

    )
}

export default React.memo(CardContainer);