import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';


import { useSelector, useDispatch } from 'react-redux';
import { DatesState, EventSourceState, LanguageState } from 'store/Reducers';
import * as ActionTypes from "store/Actions/ActionTypes";
import * as guideAnalyticsDetailActions from "store/Actions/index";
import { GuideAnalyticsDetailState } from 'store/Reducers/GuideInsights/guideAnalyticsDetail';


import CommonModal from '../CommonModal';
import QuestionsList from 'components/QuestionsList/QuestionsList';
import Loader from 'components/Loader/Loader';
import { useErrorBoundary } from 'react-error-boundary';

import './GuideSurvey.scss'
import { InsightLabels } from 'labels';


interface CurrentState {
    dates: DatesState,
    eventSource: EventSourceState,
    guideAnalyticsDetail: GuideAnalyticsDetailState,
    languageData: LanguageState

}


const GuideSurvey = (props: any) => {

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const { showBoundary } = useErrorBoundary();

    const dispatch = useDispatch();

    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];

    let fetched_details = useSelector((state: CurrentState) => state.dates);
    let fetched_lang_details = useSelector((state: CurrentState) => state);
    let fetched_event_details = useSelector((state: CurrentState) => state.eventSource);

    let fetched_guide_details = useSelector((state: CurrentState) => state.guideAnalyticsDetail.guideAnalyticsDetailSurveyModalData);

    const [stepGuide, setStepGuide] = useState({
        flag: false
    });

    const [surveyModal, setSurveyModal] = useState({
        open: false,
        title: '',
        surveyType: 'guide_sentiment'
    });

    const [filterData, setFilterData] = useState({
        data: [],
        dataFetched: false,
        filter1: [],
        filter2: [],
        filter3: [],
        filter1Title: '',
        filter2Title: '',
        filter3Title: '',
        questionsData: [],
        questionsFlag: false,
        filter3_flag: false
    })

    const [stateFilter, setStatusFilter] = useState({
        isOpen1: false,
        isOpen2: false,
        isOpen3: false,
    });

    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_lang_details.languageData.languageData) {
            setInsightsLbls(fetched_lang_details.languageData.languageData);
        }

    }, [fetched_lang_details.languageData.languageData])

    useEffect(() => {
        setSurveyModal((prevState: any) => {
            return {
                ...prevState,
                surveyType: props.surveyType
            }
        })
    }, [])


    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getGuideAnalyticsDetailSurveyModalData = () => {
        let params = {
            'guide_id': props.guideId,
            'app_id': fetched_details.appId,
            "start_date": fetched_details.fdate + "_00:00:00",
            "end_date": fetched_details.realTime_tdate + "_23:59:59",
            "time_zone": timeZone,
            "event_source": fetched_event_details.eventSourceGuides,
        };

        guideAnalyticsDetailActions.getGuideAnalyticsDetailData(
            params,
            "GUIDE_ANALYTICS_DETAILS_SURVEY_MODAL",
            ActionTypes.SET_GUIDE_ANALYTICS_DETAIL_SURVEY_MODAL,
            ''
        ).then((res: any) => {
            dispatch(res);
        })
    };


    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const toggleDropDown = (event: boolean, optn: number) => {
        try {
        } catch (error) {
            showBoundary(error)
        }
        if (optn === 1) {
            setStatusFilter((prevState: any) => {
                return {
                    ...prevState,
                    isOpen1: event,
                    isOpen2: false,
                    isOpen3: false,
                };
            });
        } else if (optn === 2) {
            setStatusFilter((prevState: any) => {
                return {
                    ...prevState,
                    isOpen1: false,
                    isOpen2: event,
                    isOpen3: false,
                };
            });
        } else {
            setStatusFilter((prevState: any) => {
                return {
                    ...prevState,
                    isOpen1: false,
                    isOpen2: false,
                    isOpen3: event,
                };
            });
        }
    };

    // const changeStatus = (key: string) => {
    //     setStatusFilter((prevState: any) => {
    //         return {
    //             ...prevState,
    //             isOpen1: false,
    //         };
    //     });
    // };

    const closeSurveyModal = () => {
        try {
        } catch (error) {
            showBoundary(error)
        }
        setSurveyModal((prevState: any) => {
            return {
                ...prevState,
                open: false,
            };
        });
        props.closeModal();
    }

    const getAllFiltersData = () => {
        try {
            let filter1_data: any = [];
            let filter2_data: any = [];

            if (fetched_guide_details?.data['guideDetails'].length) {
                let keys: any = {};
                let step: any = {};
                const survey_type = surveyModal.surveyType;
                for (let i = 0; i < fetched_guide_details.data.guideDetails.length; i++) {
                    var key = fetched_guide_details.data.guideDetails[i].filterLevel1;
                    if (!keys.hasOwnProperty(key)) {
                        keys[key] = {};
                        keys[key]['filterLevel2Text'] = [];
                    }
                    step = {};
                    if (surveyModal.surveyType === 'guide_step_sentiment') {
                        step['stepID'] = (fetched_guide_details.data.guideDetails[i].stepID);
                        step['stepTitle'] = (fetched_guide_details.data.guideDetails[i].stepTitle);
                    } else {
                        step['stepID'] = null;
                        step['stepTitle'] = null;
                    }
                    step['isExportAllowed'] = (fetched_guide_details.data.guideDetails[i].isExportAllowed);
                    step['sentimentCode'] = (fetched_guide_details.data.guideDetails[i].sentimentCode);
                    step['filterLevel2Text'] = (fetched_guide_details.data.guideDetails[i].filterLevel2Text);
                    keys[key]['filterLevel2Text'].push(step);
                    keys[key]['filterLevel1Text'] = fetched_guide_details.data.guideDetails[i].filterLevel1Text;
                    keys[key]['surveyDetails'] = fetched_guide_details.data.guideDetails[i].surveyDetails;
                }

                for (const property in keys) {
                    filter1_data.push(keys[property]['filterLevel1Text']);
                }

                if (keys[survey_type]) {
                    filter2_data = keys[survey_type]['filterLevel2Text'];
                    const seen = new Set();
                    const filteredArr = filter2_data.filter((el: any) => {
                        const duplicate = seen.has(el.sentimentCode);
                        seen.add(el.sentimentCode);
                        return !duplicate;
                    });

                    stepSentimentsList(filter2_data, filter2_data[0]['filterLevel2Text']);

                    setFilterData((prevState: any) => {
                        return {
                            ...prevState,
                            dataFetched: true,
                            filter1: filter1_data,
                            filter2: filteredArr,
                            filter1Title: keys[survey_type]['filterLevel1Text'],
                            filter2Title: filter2_data[0]['filterLevel2Text'],
                            keys: keys,
                            data: fetched_guide_details.data.guideDetails,
                        }
                    })
                }
            } else {
                setFilterData((prevState: any) => {
                    return {
                        ...prevState,
                        dataFetched: true,
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    // first time
    const stepSentimentsList = (data: any, filter2_title: string) => {
        try {
            if (surveyModal.surveyType === 'guide_step_sentiment' || stepGuide.flag) {
                let filter3_data: any = [];

                let filter2_data = (filterData.filter2Title !== undefined && filterData.filter2Title !== null && filterData.filter2Title !== '') ? filterData.filter2Title : filter2_title;

                for (const property in data) {
                    if (filter2_data === data[property]['filterLevel2Text']) {
                        var a = data[property];
                        filter3_data.push(a);
                    }
                }

                if (filter3_data[0]) {
                    setFilterData((prevState: any) => {
                        return {
                            ...prevState,
                            filter3Title: filter3_data[0].stepTitle,
                            filter3: filter3_data,
                            filter3_flag: true
                        }
                    })
                }
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const sentimentFilter = (val: any, optn: number, index: number) => {
        try {
            setFilterData((prevState: any) => {
                return {
                    ...prevState,
                    questionsFlag: false
                }
            });

            if (optn === 1) {
                switch (val) {
                    case 'Guide Survey':
                        // localStorage.setItem('SURVEY_TYPE', 'guide_sentiment');
                        setSurveyModal((prevState: any) => {
                            return {
                                ...prevState,
                                title: insightsLbls.guideSurveyQuestionsAndResponses,
                                surveyType: 'guide_sentiment'
                            }
                        })

                        setStepGuide((prevState: any) => {
                            return {
                                ...prevState,
                                flag: false
                            }
                        })
                        break;

                    case 'Guide Exit Survey':
                        // localStorage.setItem('SURVEY_TYPE', 'guide_exit_sentiment');
                        setSurveyModal((prevState: any) => {
                            return {
                                ...prevState,
                                title: insightsLbls.exitGuideSurveyQuestionsAndResponses,
                                surveyType: 'guide_exit_sentiment'
                            }
                        })

                        setStepGuide((prevState: any) => {
                            return {
                                ...prevState,
                                flag: false
                            }
                        })
                        break;

                    case 'Guide Step Survey':
                        // localStorage.setItem('SURVEY_TYPE', 'guide_step_sentiment');
                        setSurveyModal((prevState: any) => {
                            return {
                                ...prevState,
                                title: insightsLbls.guideStepSurveyQuestionsAndResponses,
                                surveyType: 'guide_step_sentiment'
                            }
                        })

                        setStepGuide((prevState: any) => {
                            return {
                                ...prevState,
                                flag: true
                            }
                        })
                        break;

                    default:
                        localStorage.setItem('SURVEY_TYPE', 'guide_sentiment');
                        setSurveyModal((prevState: any) => {
                            return {
                                ...prevState,
                                title: insightsLbls.guideSurveyQuestionsAndResponses
                            }
                        })
                }

                setFilterData((prevState: any) => {
                    return {
                        ...prevState,
                        filter1Title: val
                    }
                })

                setStatusFilter((prevState: any) => {
                    return {
                        ...prevState,
                        isOpen1: false
                    }
                })

                getFilter1Questions(val);
            } else if (optn === 2) {
                setStatusFilter((prevState: any) => {
                    return {
                        ...prevState,
                        isOpen2: false,
                    }
                })

                setFilterData((prevState: any) => {
                    return {
                        ...prevState,
                        filter2Title: val.filterLevel2Text,
                        // filter3_flag: false
                    }
                })

                getFilter2Questions(val);
            } else {
                setStatusFilter((prevState: any) => {
                    return {
                        ...prevState,
                        isOpen3: false
                    }
                })

                for (let i = 0; i < filterData.data.length; i++) {
                    if (filterData.filter1Title === filterData.data[i]['filterLevel1Text'] && filterData.filter2Title === filterData.data[i]['filterLevel2Text']) {
                        setFilterData((prevState: any) => {
                            return {
                                ...prevState,
                                questionsData: filterData.data[i]['surveyDetails'],
                                questionsFlag: true,
                                filter3Title: val.stepTitle,
                            }
                        })
                    }
                }
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const getFilter1Questions = (data: any) => {
        try {
            let filter1_dummy_data: any = [];

            for (let i = 0; i < filterData.data.length; i++) {
                if (data === filterData.data[i]['filterLevel1Text']) {
                    filter1_dummy_data.push(filterData.data[i])
                }
            }

            setFilterData((prevState: any) => {
                return {
                    ...prevState,
                    questionsData: filter1_dummy_data[0]['surveyDetails'],
                    questionsFlag: true,
                    filter2Title: filter1_dummy_data[0].filterLevel2Text,
                    filter2: filter1_dummy_data
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const getFilter2Questions = (data: any) => {
        try {
            let filter3_dummy_data: any = [];

            if (surveyModal.surveyType === 'guide_step_sentiment') {
                for (let i = 0; i < filterData.data.length; i++) {
                    if (data.sentimentCode === filterData.data[i]['sentimentCode'] && filterData.data[i]['filterLevel1'] === 'guide_step_sentiment') {
                        filter3_dummy_data.push(filterData.data[i])
                    }
                }

                setFilterData((prevState: any) => {
                    return {
                        ...prevState,
                        questionsData: filter3_dummy_data[0]['surveyDetails'],
                        filter3: filter3_dummy_data,
                        filter3Title: filter3_dummy_data[0].stepTitle,
                    }
                })
            }

            setFilterData((prevState: any) => {
                return {
                    ...prevState,
                    questionsFlag: true,
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }



    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // On fliter change call filters data
    useEffect(() => {
        try {
            if (surveyModal.surveyType === 'guide_step_sentiment') {
                if (
                    fetched_guide_details.data !== undefined &&
                    fetched_guide_details.result === "success"
                ) {
                    getAllFiltersData();
                } else if (fetched_guide_details.result === "error") {

                }
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_guide_details, surveyModal.surveyType,])

    // On props change set survey modal open
    useEffect(() => {
        setSurveyModal((prevState: any) => {
            return {
                ...prevState,
                open: true,
            }
        });
    }, [props])

    // On filter modal open call api
    useEffect(() => {
        try {
            if (surveyModal.open) {
                setSurveyModal((prevState: any) => {
                    return {
                        ...prevState,
                        surveyType: props.surveyType
                    }
                })
                getGuideAnalyticsDetailSurveyModalData();
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [surveyModal.open, fetched_lang_details.languageData.languageData])

    // Call filters data
    useEffect(() => {
        if (
            fetched_guide_details.data !== undefined &&
            fetched_guide_details.result === "success"
        ) {
            getAllFiltersData();
        }
    }, [fetched_guide_details, fetched_lang_details.languageData.languageData])

    // Question data
    useEffect(() => {
        try {
            for (let i = 0; i < filterData.data.length; i++) {
                if (filterData.filter1Title === filterData.data[i]['filterLevel1Text'] && filterData.filter2Title === filterData.data[i]['filterLevel2Text']) {
                    setFilterData((prevState: any) => {
                        return {
                            ...prevState,
                            questionsData: filterData.data[i]['surveyDetails'],
                            questionsFlag: true
                        }
                    })
                }
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [filterData.filter1Title, filterData.filter2Title, , filterData.filter3Title])


    return (
        <CommonModal
            modalState={surveyModal.open}
            dispatchModalState={closeSurveyModal}
            modalTitle={insightsLbls.guideSurveyQuestionsAndResponses}
            size={'lg'}
            footerDisabled={true}
            exportModal={true}
            padding={true}
            modalDialogClass={'guideSurvey-modal'}
        // dispatchModalOk={getTooltipList}
        // dispatchModalCancel={clearTooltips}
        // okBtn={'Apply'}
        // cancelBtn={'Clear All'}
        >
            <div>

                {filterData.dataFetched

                    ? ((fetched_guide_details?.data['guideDetails'].length > 0) ? (
                        <div className="minHeight400 guideSurvey">
                            <div className="row col-12 font14 filterBtns-div">
                                <div className="col-4 col-lg-4 col-md-4  displaySentimentsFilter marginBottom-0 font14 displayBlock">
                                    <div className="marginTop-0 marginBottom-5 marginLeft-5">{insightsLbls.surveyType} &nbsp;</div>
                                    <Dropdown id="state-filter-dropdown" show={stateFilter.isOpen1} onToggle={(event) => toggleDropDown(event, 1)}>
                                        <Dropdown.Toggle className="btn btn-lgbtn-outline-primary survey-filter bootstrapDCWorkaround2">
                                            <span className="">{filterData.filter1Title}</span>
                                            <i aria-hidden="true" className={"switchApp-btn-icon " + (stateFilter.isOpen1 ? "fa fa-chevron-up" : "fa fa-chevron-down")}></i>
                                        </Dropdown.Toggle>
                                        {/* dropdown-menu-state-filter userList-dropdown  */}
                                        <Dropdown.Menu className="dropdown-menu  survey-dropdown">
                                            {
                                                filterData.filter1.map((data: any, index: number) => {
                                                    return (
                                                        <div className="dropdown-status-menu" key={"list1_" + index}>
                                                            <button onClick={() => sentimentFilter(data, 1, index)} id={"list1_" + index}
                                                                className={"pointer dropdown-menu-item statusBtn " + (filterData.filter1Title === data ? "active-button" : "")}>
                                                                {data}
                                                            </button>
                                                        </div>
                                                    );
                                                    // return (
                                                    //     <p className={`${filterData.filter1Title === data ? 'listHighlight' : ''}` + " sentimentsDropDownList dropdownList1"}
                                                    //         id={"list1_" + index}
                                                    //         onClick={() => sentimentFilter(data, 1, index)} >
                                                    //         {data}
                                                    //     </p>
                                                    // );
                                                })
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="col-4 col-lg-4 col-md-4  displaySentimentsFilter marginBottom-5 font14 displayBlock">
                                    <div className="marginTop-0 marginBottom-5 marginLeft-5">{insightsLbls.surveyName} &nbsp;</div>
                                    <Dropdown id="state-filter-dropdown" show={stateFilter.isOpen2} onToggle={(event) => toggleDropDown(event, 2)}>
                                        <Dropdown.Toggle className="btn btn-lg btn-outline-primary survey-filter bootstrapDCWorkaround2">
                                            <span>{filterData.filter2Title}</span>
                                            <i aria-hidden="true" className={"switchApp-btn-icon " + (stateFilter.isOpen2 ? "fa fa-chevron-up" : "fa fa-chevron-down")}></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu survey-dropdown">
                                            {
                                                filterData.filter2.map((data2: any, index: number) => {
                                                    return (
                                                        <div className="dropdown-status-menu" key={"list2_" + index}>
                                                            <button onClick={() => sentimentFilter(data2, 2, index)} id={"list2_" + index}
                                                                className={"pointer dropdown-menu-item statusBtn " + (filterData.filter2Title === data2.filterLevel2Text ? "active-button" : "")}>
                                                                {data2.filterLevel2Text}
                                                            </button>

                                                        </div>
                                                    );
                                                    // return (
                                                    //     <p className={`${filterData.filter2Title === data2.filterLevel2Text ? 'listHighlight' : ''}` + " sentimentsDropDownList dropdownList1"}
                                                    //         id={"list2_" + index}
                                                    //         onClick={() => sentimentFilter(data2, 2, index)} >
                                                    //         {data2.filterLevel2Text}
                                                    //     </p>
                                                    // );
                                                })
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>

                                {(surveyModal.surveyType === 'guide_step_sentiment' || stepGuide.flag) &&
                                    (<div className="col-4 col-lg-4 col-md-4 displaySentimentsFilter marginBottom-0 font14 displayBlock">
                                        <div className="marginTop-0 marginBottom-5 marginLeft-5">{insightsLbls.stepName} &nbsp;</div>
                                        <Dropdown id="state-filter-dropdown" show={stateFilter.isOpen3} onToggle={(event) => toggleDropDown(event, 3)}>
                                            {filterData.filter3Title && (
                                                <Dropdown.Toggle className="btn btn-lg btn-outline-primary survey-filter bootstrapDCWorkaround2">
                                                    <span className="marginLeft-0">{filterData.filter3Title}</span>
                                                    <i aria-hidden="true" className={"switchApp-btn-icon " + (stateFilter.isOpen3 ? "fa fa-chevron-up" : "fa fa-chevron-down")}></i>
                                                </Dropdown.Toggle>
                                            )}

                                            <Dropdown.Menu className="dropdown-menu  survey-dropdown">
                                                {
                                                    filterData.filter3.map((data3: any, index: number) => {
                                                        return (
                                                            <>
                                                                {(surveyModal.surveyType === 'guide_step_sentiment' && filterData.filter3_flag) &&
                                                                    (
                                                                        <div className="dropdown-status-menu" key={"list3_" + index}>
                                                                            <button onClick={() => sentimentFilter(data3, 3, index)} id={"list3_" + index}
                                                                                className={"pointer dropdown-menu-item statusBtn " + (filterData.filter3Title === data3.stepTitle ? "active-button" : "")}>
                                                                                {data3.stepTitle}
                                                                            </button>
                                                                        </div>
                                                                        // <p className={`${filterData.filter3Title === data3.stepTitle ? 'listHighlight' : ''}` + " sentimentsDropDownList dropdownList1"}
                                                                        //     id={"list3_" + index}
                                                                        //     onClick={() => sentimentFilter(data3, 3, index)} >
                                                                        //     {data3.stepTitle}
                                                                        // </p>
                                                                    )}
                                                            </>
                                                        );
                                                    })
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>)
                                }

                            </div>
                            <div className="modal-question">
                                {filterData.questionsFlag
                                    ? (<QuestionsList data={filterData.questionsData}></QuestionsList>)
                                    : (null)
                                }
                            </div>
                        </div>

                    ) : (<div className="minHeight400">
                        <div className="noDataText-font textCenter-absolute">
                            {insightsLbls.noRecordFound}
                        </div>
                    </div>))
                    : (<div className="minHeight400">
                        <div className="displayFlex alignCenter margin-0-auto">
                            <Loader></Loader>
                        </div>
                    </div>)
                }

            </div>
        </CommonModal>
    )


};

export default React.memo(GuideSurvey);
