import React, { useEffect, useRef, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import withRouter from '../../hoc/withRouter';
import { withReducer } from '../../store/withReducer';
import { InsightLabels } from './../../labels';
import Datepicker from './../../components/Calender/Datepicker';
import DatePicker from "react-datepicker";
import Loader from "./../../components/Loader/Loader";
import * as exportActions from "./../../store/Actions/index";
import * as ActionTypes from "./../../store/Actions/ActionTypes";
import { useSelector, useDispatch } from 'react-redux';
import { AppsState, EventSourceState, ExportDatesState, ExportRequestSourceFilterState, LanguageState } from './../../store/Reducers';
import qs from 'querystring';
import './ExportList.scss';
import { useNavigate } from 'react-router-dom';
import { CommonUtils } from './../../utils/CommonUtils';
import exportReducer, { ExportState } from 'store/Reducers/Export/export';
import { Modal, Tooltip } from 'react-bootstrap';
import { Dropdown, OverlayTrigger, Tab, Tabs, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { deleteCall, getCall, patchCall, postCall, putCall } from 'utils/ApiCallActions';
import { ApiRelativePaths, RetryApi } from 'utils/GlobalConstants';
import { toast } from 'react-toastify';
import { GetAppDropdown, GetDataSourceDropdown, GetEventSourceDropdown, GetGuideDropdown, GetPlayTypeDropdown, GetTooltipDropdown } from "./exportUtils";
import axios from 'axios';
import InputLabel from 'components/Label/InputLabel';
import Icon from 'components/Icon/Icon';

interface CurrentState {
    apps: AppsState,
    export: ExportState,
    exportDates: ExportDatesState,
    languageData: LanguageState,
    exportRequestSource: ExportRequestSourceFilterState,
    eventSource: EventSourceState,
}

const ExportList = (props: any) => {
    const isInitialMount = useRef(true);

    const { showBoundary } = useErrorBoundary();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const componentRef = useRef() as React.RefObject<HTMLDivElement>;
    const dateZone: any = new Date().toString();
    let fetched_details = useSelector((state: CurrentState) => state);
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);

    const jobListHeader = [
        { title: insightsLbls.requestSentOn, class: '', img: false },
        { title: insightsLbls.requestCompletedOn, class: '', img: false },
        { title: insightsLbls.requestSourcedAt, class: 'displayFlex align-items-start justify-space-between', img: false },
        { title: insightsLbls.requestGuideType, class: 'displayFlex align-items-start justify-space-between', img: false },
        { title: insightsLbls.appsSelectedForRequest, class: '', img: false },
        { title: insightsLbls.requestCompletedvia, class: '', img: false },
        { title: insightsLbls.status, class: 'displayFlex align-items-start justify-space-between', img: false }
    ]

    const templateListHeader = [
        { title: insightsLbls.templateTitle, class: 'displayFlex align-items-start justify-space-between', img: false },
        { title: insightsLbls.requestSourcedAt, class: 'displayFlex align-items-start justify-space-between', img: false },
        { title: insightsLbls.requestGuideType, class: 'displayFlex align-items-start justify-space-between', img: false },
        { title: insightsLbls.appsSelectedForRequest, class: '', img: false },
        { title: insightsLbls.actions, class: '', img: false }
    ]

    const scheduleListHeader = [
        { title: insightsLbls.scheduleTitle, class: 'displayFlex align-items-start justify-space-between', img: false },
        { title: insightsLbls.requestSourcedAt, class: 'displayFlex align-items-start justify-space-between', img: false },
        { title: insightsLbls.requestGuideType, class: 'displayFlex align-items-start justify-space-between', img: false },
        { title: insightsLbls.appsSelectedForRequest, class: '', img: false },
        { title: insightsLbls.actions, class: '', img: false }
    ]

    let calendarOptions: any = [
        { dateSelection: 3, text: insightsLbls.last7Days },
        { dateSelection: 10, text: insightsLbls.last30Days },
        { dateSelection: 16, text: insightsLbls.last60Days },
        { dateSelection: 11, text: insightsLbls.last90Days },
        { dateSelection: 12, text: insightsLbls.last180Days },
        { dateSelection: 13, text: insightsLbls.last365Days },
        { dateSelection: 14, text: insightsLbls.lastCalendarYear },
        { dateSelection: 17, text: insightsLbls.ytdCalendarYear },
        { dateSelection: 15, text: insightsLbls.customDate },
    ];

    const getDateIn15MinGap = () => {
        let date = new Date();
        date.setMinutes(date.getMinutes() + 5 - (date.getMinutes() % 5));
        date.setSeconds(0);
        return date.getTime();
    }

    const parseDateString = (dateString) => {
        const [datePart, timePart] = dateString.split('_');
        const [year, month, day] = datePart.split('-').map(Number);
        const [hours, minutes, seconds] = timePart.split(':').map(Number);
        return new Date(year, month - 1, day, hours, minutes, seconds).getTime();
    }

    /** States */
    const [pageTab, setPageTab] = useState({
        key: "exJobList"
    })

    const [isLoading, setIsLoading] = useState(false);

    const [exportList, setExportList] = useState({
        data: [],
        listDataFetched: false,
        apiError: 0,
        firstLoad: true,
    })

    const [templateList, setTemplateList] = useState({
        data: [],
        listDataFetched: false,
        apiError: 0,
        firstLoad: true,
    })

    const [schedulerList, setSchedulerList] = useState({
        data: [],
        listDataFetched: false,
        apiError: 0,
        firstLoad: true,
    })

    const [newJobData, setNewJobData] = useState({
        "app_ids": [],
        "guide_ids": [],
        "tooltip_ids": [],
        "guide_type": ['guide', 'tooltip'],
        "guide_type_breakdown": [],
        "event_source": ['player']
    })

    const [templateData, setTemplateData] = useState({
        "template_id": "",
        "template_title": "",
        "app_ids": [],
        "guide_ids": [],
        "tooltip_ids": [],
        "guide_type": ['guide', 'tooltip'],
        "guide_type_breakdown": [],
        "event_source": ['player']
    })

    const [scheduleData, setScheduleData] = useState({
        "schedule_id": "",
        "schedule_title": "",
        "schedule": {
            "type": "one-time",
            "trigger_time": getDateIn15MinGap(),
            "sub_type": "day-of-week",
            "day_selection": ["mon"],
            "time_of_day": "18:00",
        },
        "app_ids": [],
        "guide_ids": [],
        "tooltip_ids": [],
        "guide_type": ['guide', 'tooltip'],
        "guide_type_breakdown": [],
        "event_source": ['player'],
        "export_timeframe_in_days": "7",
        "start_timestamp": new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)).getTime(),
        "end_timestamp": new Date().getTime(),
        "schedule_status": "inactive",
        "email_cc": [],
        "selected_id": '',
        "emailLoadFlag": true
    })

    const [emailIds, setEmailIds] = useState({
        ids: [],
        valid: true
    })

    const [exportBtnDisabled, setExportBtnDisabled] = useState({
        exportBtnDisabled: false,
    })

    const [arrowIndex, setArrowIndex] = useState({
        index_up: null
    });

    const updateTemplateTitle = (value) => {
        setTemplateData((prevState) => {
            return {
                ...prevState,
                template_title: value
            }
        })
    }

    const updateScheduleTitle = (value) => {
        setScheduleData((prevState) => {
            return {
                ...prevState,
                schedule_title: value
            }
        })
    }
    /** States */

    /** App Dropdown part started */

    //State to track if application data is fetched or not
    const [appsFetched, setAppsFetched] = useState({
        data: [],
        isFetched: false,
        error: true
    })

    //State to set selected app data from export utils
    const [selectedAppData, setSelectedAppData] = useState([]);

    //short hand set app fetched property to avoid redundant code
    const setAppsFetchedShortHand = (data, isFetched, isError) => {
        setAppsFetched((prevState) => {
            return {
                ...prevState,
                data: data,
                isFetched: isFetched,
                isError: isError
            }
        })
    }

    //Handler to set selected app from export utils
    const setSelectedAppDataHandler = appList => setSelectedAppData(appList);

    //Api call to get app data and set app fetched
    const getAppList = (apiErrorFlag?: string) => {
        try {
            let exportAppList: any = localStorage.getItem('EXPORT_APP_LIST');
            if (exportAppList && exportAppList != null) {
                setAppsFetchedShortHand(JSON.parse(exportAppList), true, false);
            } else {
                let cancelToken: any;
                if (typeof cancelToken !== typeof undefined) {
                    cancelToken.cancel(insightsLbls.tokenCancelledMsg);
                }
                cancelToken = axios.CancelToken.source();
                let params = {
                    "type": 'org',
                    "sort_by": "app_name",
                    "order": "asc",
                    "search_text": '',
                    "page_index": 1,
                    "page_size": 9999,
                    "hide_deleted": 0
                };
                getCall(params, 'APP_LIST', apiErrorFlag ?? '').then((data: any) => {
                    if (data?.result === 'success') {
                        if (data && data.data && data.data.apps && data.data.apps.length > 0) {
                            let appData = data.data.apps.map(app => ({ "appId": app.app_id, "appName": app.app_name }))
                            setAppsFetchedShortHand(appData, true, false);
                        } else {
                            setAppsFetchedShortHand([], true, true);
                        }
                    } else if (data.result === 'retry') {
                        setTimeout(() => {
                            getAppList('retry')
                        }, RetryApi.TIMEOUT)
                    }
                    else if (data?.result === "error") {
                        setAppsFetchedShortHand([], true, true);
                    }
                });
            }
        } catch (error) {
            showBoundary(error)
        }
    }

    //Get App name by app id
    const getAppName = (appId) => appsFetched.data.find(app => app.appId === appId)?.appName;

    //Get App chip by app id
    const getAppChip = (appId) => <span className="infoChip appChips">{getAppName(appId)}</span>

    //Return JSX to show selected app chips
    const getAppsSelectionData = () => {
        return <div className={selectedAppData.length == 1 ? "row-flex" : ""}>
            <div className={selectedAppData.length == 1 ? "exportRequestFilterListTitleDiv width-fit" : "exportRequestFilterListTitleDiv"}>
                {insightsLbls.selectedApps}:
            </div>
            <div className="exportRequestAppsListDiv">
                <div className="exportRequestAppNameDiv">
                    <p className="font14 chipParagraph">
                        {
                            appsFetched.isFetched ?
                                (selectedAppData.length == 0 ? appsFetched.data.map(app => getAppChip(app.appId)) : selectedAppData.map(appId => getAppChip(appId))) :
                                <Loader width={100}></Loader>
                        }
                    </p>
                </div>
            </div>
        </div>
    }

    //Return JSX to show app names in export job/template/scheduler list
    const appRequestedData = (appIdList: []) => {
        try {
            if (appsFetched.data.length == 0) return;
            let processData = appIdList.length == 0 ? appsFetched.data.map(app => app.appId) : appIdList;
            let finalData = processData.map(appId => getAppName(appId));
            return finalData.map(obj => <span className='chips'>{obj}</span>)
        } catch (error) {
            showBoundary(error)
        }
    }

    /** App Dropdown part ended */


    /** EventSource Dropdown part started */

    let eventSourcesList = [
        { key: 'creator', value: insightsLbls.webCreator },
        { key: 'player', value: insightsLbls.webPlayer },
        { key: 'desktop_player', value: insightsLbls.desktopPlayer },
        { key: 'desktop_creator', value: insightsLbls.desktopCreator },
        { key: 'mobile_creator', value: insightsLbls.mobileCreator },
        { key: 'mobile_player', value: insightsLbls.mobilePlayer },
    ];

    //State to set selected event source data from export utils
    const [selectedEventSourceData, setSelectedEventSourceData] = useState([]);

    //Handler to set selected event source data from export utils
    const setSelectedEventSourceDataHandler = eventSourceList => setSelectedEventSourceData(eventSourceList);

    //Get event source name by key
    const getEventSourceValue = (esKey) => eventSourcesList.find(es => es.key === esKey)?.value;

    //Get event source chip by key
    const getEventSourceChip = (esKey) => <span className="infoChip appChips">{getEventSourceValue(esKey)}</span>

    const getEventSourceDropdown = (selectedEvents) => {
        return <GetEventSourceDropdown eventSourceList={eventSourcesList} insightsLbls={insightsLbls}
            selectedEvents={selectedEvents} selectedEventSourceData={setSelectedEventSourceDataHandler}></GetEventSourceDropdown>
    }
    /** Event Source dropdown part ended */


    /** Data Source Dropdown part started */

    let dataSourcesList = [
        { key: 'guide', value: insightsLbls.guidesTab },
        { key: 'tooltip', value: insightsLbls.tooltipLabel },
        { key: 'search', value: insightsLbls.searches },
    ];

    //State to set selected data source data from export utils
    const [selectedDataSourceData, setSelectedDataSourceData] = useState([]);

    //Handler to set selected data source data from export utils
    const setSelectedDataSourceDataHandler = dataSourcesList => setSelectedDataSourceData(dataSourcesList);

    //Get data source name by key
    const getDataSourceValue = (dsKey) => dataSourcesList.find(ds => ds.key === dsKey)?.value;

    //Get data source chip by key
    const getDataSourceChip = (dsKey) => <span className="infoChip appChips">{getDataSourceValue(dsKey)}</span>

    const getDataSourceDropdown = (selectedDataSource) => {
        return <GetDataSourceDropdown dataSourcesList={dataSourcesList} insightsLbls={insightsLbls}
            selectedDataSource={selectedDataSource} selectedDataSourceData={setSelectedDataSourceDataHandler}></GetDataSourceDropdown>
    }
    /** Data Source dropdown part ended */


    /** Play Type  Dropdown part started */

    let playTypeList = [
        { key: 'guideme', value: insightsLbls.guideMe },
        { key: 'showme', value: insightsLbls.showMe },
        { key: 'testme', value: insightsLbls.testMe },
        { key: 'doitforme', value: insightsLbls.doItForMe },
        { key: 'tutorial', value: insightsLbls.tutorial }
    ]

    //State to set selected play type data from export utils
    const [selectedPlayTypeData, setSelectedPlayTypeData] = useState([]);

    //Handler to set selected play type data from export utils
    const setSelectedPlayTypeDataHandler = playTypeList => setSelectedPlayTypeData(playTypeList);

    //Get play type name by key
    const getPlayTypeValue = (playKey) => playTypeList.find(play => play.key === playKey)?.value;

    //Get play type chip by key
    const getPlayTypeChip = (playKey) => <span className="infoChip appChips">{getPlayTypeValue(playKey)}</span>

    const getPlayTypeDropdown = (selectedPlayType) => {
        return <GetPlayTypeDropdown insightsLbls={insightsLbls} playTypesList={playTypeList}
            selectedPlayType={selectedPlayType} selectedPlayTypeData={setSelectedPlayTypeDataHandler}></GetPlayTypeDropdown>
    }
    /** Play Type dropdown part ended */


    /** Guide Tooltip dropdown part started */

    const [guideData, setGuideData] = useState({
        isLoading: false,
        isError: false,
        data: [],
        lastAppID: ""
    })

    const [tooltipData, setTooltipData] = useState({
        isLoading: false,
        isError: false,
        data: [],
        lastAppID: ""
    })

    const getGuideTooltipList = (appId, isGuide) => {
        return new Promise((resolve, reject) => {
            const params = {
                'time_zone': timeZone,
                'app_id': appId
            };
            exportActions.getGuideTutorialListData(
                params,
                isGuide ? "GET_GUIDES_BY_APP" : "GET_TOOLTIPS_BY_APP"
            ).then((res: any) => resolve(res));
        })
    }

    //State to set selected guide type data from export utils
    const [selectedGuideData, setSelectedGuideData] = useState([]);

    //Handler to set selected guide type data from export utils
    const setSelectedGuideDataHandler = guideList => setSelectedGuideData(guideList);

    const getGuideDropdown = (selectedGuide) => {
        return (
            guideData.isLoading ? <Loader></Loader> :
                (guideData.data.length == 0 ? <div className="noData-div">{insightsLbls.noRecordFound}</div> :
                    <GetGuideDropdown guideList={guideData.data} insightsLbls={insightsLbls} selectedGuide={selectedGuide}
                        selectedGuideData={setSelectedGuideDataHandler}></GetGuideDropdown>
                )
        )
    }

    //State to set selected tooltip type data from export utils
    const [selectedTooltipData, setSelectedTooltipData] = useState([]);

    //Handler to set selected tooltip type data from export utils
    const setSelectedTooltipDataHandler = tooltipList => setSelectedTooltipData(tooltipList);

    const getTooltipDropdown = (selectedTooltip) => {
        return (
            tooltipData.isLoading ? <Loader></Loader> :
                (tooltipData.data.length == 0 ? <div className="noData-div">{insightsLbls.noRecordFound}</div> :
                    <GetTooltipDropdown tooltipList={tooltipData.data} insightsLbls={insightsLbls} selectedTooltip={selectedTooltip}
                        selectedTooltipData={setSelectedTooltipDataHandler}></GetTooltipDropdown>
                )
        )
    }

    /** Guide Tooltip dropdown part ended */


    /** Schedule Frequency dropdown part started */

    let frequencyList = [{ key: "one-time", value: insightsLbls.oneTime }, { key: "recurring", value: insightsLbls.repetitive }]

    const [frequencyDropdown, setFrequencyDropdown] = useState(false);

    const updateFrequencyState = (value) => {
        setScheduleData((prevState) => {
            return {
                ...prevState,
                schedule: {
                    ...scheduleData.schedule,
                    type: value,
                    sub_type: "day-of-week",
                    day_selection: ["mon"]
                }
            }
        });
        setFrequencyDropdown(false);
    }

    const getFilterValue = (key) => frequencyList.find(freq => freq.key == key)?.value;

    const getFrequencyDropdown = () => {
        return (
            <>
                <Dropdown className="width-100" show={frequencyDropdown} onToggle={(event) => setFrequencyDropdown(event)}>
                    {insightsLbls && (
                        <Dropdown.Toggle disabled={scheduleData.schedule_id != ""} className={"btn btn-lg btn-outline-primary export-dropdown"} style={{ zIndex: "1" }}>
                            <span className="dropdown-label">{getFilterValue(scheduleData.schedule.type)}</span>
                            <img src={require("../../assets/img/caret-down.png")} alt="" />
                        </Dropdown.Toggle>
                    )}
                    <Dropdown.Menu className={`dropdown-menu ${frequencyDropdown ? 'show' : ''}`}>
                        {
                            frequencyList.map((freq: any, index: number) => {
                                return (
                                    <div className={`custom-control ${freq.key === scheduleData.schedule.type ? 'active' : ''}`}
                                        key={index} onClick={() => updateFrequencyState(freq.key)}>
                                        <label>
                                            <span>{freq.value}</span>
                                        </label>
                                    </div>
                                );
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </>
        )
    }

    /** Schedule Frequency dropdown part ended */


    /** Schedule Frequency subtype dropdown part started */

    let freqSubTypeList = [{ key: "day-of-week", value: insightsLbls.dayOfWeek }, { key: "date-of-month", value: insightsLbls.dateOfMonth }]

    const [freqSubTypeDropdown, setFreqSubTypeDropdown] = useState(false);

    const updateFrequencySubTypeState = (value) => {
        setScheduleData((prevState) => {
            return {
                ...prevState,
                schedule: {
                    ...scheduleData.schedule,
                    sub_type: value,
                    day_selection: value === "day-of-week" ? ["mon"] : ["1"]
                }
            }
        });
        setFreqSubTypeDropdown(false);
    }

    const getFilterValueForSubType = (key) => freqSubTypeList.find(freq => freq.key == key)?.value;

    const getFrequencySubTypeDropdown = () => {
        return (
            <>
                <Dropdown className="width-100" show={freqSubTypeDropdown} onToggle={(event) => setFreqSubTypeDropdown(event)}>
                    {insightsLbls && (
                        <Dropdown.Toggle disabled={scheduleData.schedule_id != ""} className={"btn btn-lg btn-outline-primary export-dropdown"}>
                            <span className='dropdown-label'>{getFilterValueForSubType(scheduleData.schedule.sub_type)}</span>
                            <img src={require("../../assets/img/caret-down.png")} alt="" />
                        </Dropdown.Toggle>
                    )}

                    <Dropdown.Menu className={`dropdown-menu ${freqSubTypeDropdown ? 'show' : ''}`}>
                        {
                            freqSubTypeList.map((freqSubType: any, index: number) => {
                                return (
                                    <div className={`custom-control ${freqSubType.key === scheduleData.schedule.sub_type ? 'active' : ''}`}
                                        key={index} onClick={() => updateFrequencySubTypeState(freqSubType.key)}>
                                        <label>
                                            <span>{freqSubType.value}</span>
                                        </label>
                                    </div>
                                );
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </>
        )
    }

    /** Schedule Frequency subtype dropdown part ended */


    /** Day selection dropdown part started */

    let dayOfWeekList = [
        { key: "mon", value: insightsLbls.monday },
        { key: "tue", value: insightsLbls.tuesday },
        { key: "wed", value: insightsLbls.wednesday },
        { key: "thu", value: insightsLbls.thursday },
        { key: "fri", value: insightsLbls.friday },
        { key: "sat", value: insightsLbls.saturday },
        { key: "sun", value: insightsLbls.sunday },
    ]

    let dateOfMonthList = Array.from({ length: 31 }, (_, index) => ({ key: (index + 1).toString(), value: (index + 1).toString() }));

    const [daySelectionDropdown, setDaySelectionDropdown] = useState(false);

    const handleDaySelect = (key, event) => {
        let tempState = [...scheduleData.schedule.day_selection]
        if (event.target.checked) {
            if (!tempState.includes(key)) {
                tempState.push(key);
            }
            if (tempState.length > 7) {
                tempState = tempState.slice(-7);
            }
        } else {
            if (tempState.includes(key)) {
                tempState = tempState.filter(s => s != key);
            }
            if (tempState.length == 0) {
                tempState.push(scheduleData.schedule.sub_type == "day-of-week" ? dayOfWeekList[0].key : dateOfMonthList[0].key);
            }
        }
        setScheduleData((prevState) => {
            return {
                ...prevState,
                schedule: {
                    ...scheduleData.schedule,
                    day_selection: tempState
                }
            }
        })
    }

    const getSelectedDayLabel = () => {
        const isDayOfWeek = scheduleData.schedule.sub_type === "day-of-week";
        const limit = isDayOfWeek ? 2 : 5;
        const selectionList = scheduleData.schedule.day_selection;
        const sourceList = isDayOfWeek ? dayOfWeekList : dateOfMonthList;

        let displayedData = selectionList.slice(0, limit).map(item => {
            const value = sourceList.find(pair => pair.key == item)?.value;
            return <span className="chips">{value}</span>;
        });

        if (selectionList.length > limit) {
            displayedData.push(<span className="chips">...{selectionList.length - limit} more</span>);
        }

        return displayedData;
    }

    const getMappingList = () => {
        return scheduleData.schedule.sub_type == "day-of-week" ? dayOfWeekList : dateOfMonthList;
    }

    const getDaySelectionDropdown = () => {
        return <Dropdown className="width-100" show={daySelectionDropdown} onToggle={(event) => setDaySelectionDropdown(event)}>
            <Dropdown.Toggle className={"btn btn-lg btn-outline-primary export-dropdown"} style={{ zIndex: 1 }}>
                <span className="dropdown-label">{getSelectedDayLabel()}</span>
                <img src={require("../../assets/img/caret-down.png")} alt="" />
            </Dropdown.Toggle>
            <Dropdown.Menu className={`dropdown-menu ${daySelectionDropdown ? 'show' : ''}`} style={{ maxHeight: "200px", overflow: "auto" }}>
                {getMappingList().map((elem: any, index: number) => {
                    return (
                        <div className="custom-control custom-checkbox mr-sm-2 displayFlex alignCenter check-container">
                            <label className="check-container displayFlex alignCenter exportCheck">
                                <span className="marginLeft-25">{elem.value}</span>
                                <input type="checkbox" className={"custom-control-input chk checkbox"}
                                    id="customControlAutosizing" name={elem.value}
                                    value={elem.key} onChange={(event) => handleDaySelect(elem.key, event)} checked={scheduleData.schedule.day_selection.includes(elem.key)}></input>
                                <span className="checkmark positionAbsolute checkmarkBox-color displayInlineBlock top0"></span>
                            </label>
                        </div>
                    );
                })
                }
            </Dropdown.Menu>
        </Dropdown>
    }

    /** Day select dropdown part ended */

    /** Time dropdown part started */

    const [isOpenTimePicker, setIsOpenTimePicker] = useState(false);

    const handleTimeChange = (time) => {
        let formattedTime = null;
        if (time) {
            formattedTime = `${time.getHours().toString().padStart(2, '0')}:${time.getMinutes().toString().padStart(2, '0')}`;
        }

        setScheduleData((prevState) => {
            return {
                ...prevState,
                schedule: {
                    ...scheduleData.schedule,
                    time_of_day: formattedTime
                }
            }
        })
    };

    const CustomInput = ({ }) => (
        <input
            type="text"
            value={scheduleData.schedule.time_of_day}
            onClick={() => setIsOpenTimePicker(!isOpenTimePicker)}
            placeholder={insightsLbls.selectTime}
            readOnly
        />
    );

    const getTimeDropdown = () => {
        return <div className='datepicker-container'>
            <DatePicker
                selected={null}
                onChange={handleTimeChange}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                timeFormat="HH:mm"
                customInput={<CustomInput />}
                open={isOpenTimePicker}
                onClickOutside={() => setIsOpenTimePicker(false)} //
            />
            <img src={require("../../assets/img/clock.png")} alt="" />
        </div>
    }

    /** Time dropdown part ended */

    /** Date Time dropdown started */

    const handleDateTimeChange = (datetime) => {
        setScheduleData((prevState) => {
            return {
                ...prevState,
                schedule: {
                    ...scheduleData.schedule,
                    trigger_time: datetime
                }
            }
        })
    };

    const getDateTimeDropdown = () => {
        return <div className='datepicker-container'>
            <DatePicker
                className="custom-datepicker"
                selected={new Date(scheduleData.schedule.trigger_time)}
                onChange={(dateVal) => { handleDateTimeChange(dateVal.getTime()) }}
                showTimeSelect
                dateFormat="MMMM d, yyyy HH:mm"
                minDate={new Date()}
                timeIntervals={15}
                timeFormat="HH:mm"
                dropdownMode="select"
            />
            <img src={require("../../assets/img/calendar.png")} alt="" />
        </div>
    }

    /** Date Time dropdown ended */

    /** Recurring date picker card started */

    let recurringDatePickerList = [
        { key: "7", value: insightsLbls.last7Days },
        { key: "30", value: insightsLbls.last30Days },
        { key: "60", value: insightsLbls.last60Days },
        { key: "90", value: insightsLbls.last90Days },
        { key: "180", value: insightsLbls.last180Days },
        { key: "365", value: insightsLbls.last365Days }
    ]

    const [recurringDatePickerState, setRecurringDatePickerState] = useState(false)

    const updateRecurringDatePickerState = (value) => {
        setScheduleData((prevState) => {
            return {
                ...prevState,
                export_timeframe_in_days: value
            }
        });
        setRecurringDatePickerState(false);
    }

    const getFilterValueForRecurringDatePicker = (key) => recurringDatePickerList.find(picker => picker.key == key)?.value;

    const getRecurringDatePickerCard = () => {
        return <div className='displayFlexColumn'>
            <div className='custom-label'>{insightsLbls.dateRange}</div>
            <Dropdown className="width-100" show={recurringDatePickerState} onToggle={(event) => setRecurringDatePickerState(event)}>
                {insightsLbls && (
                    <Dropdown.Toggle className={"btn btn-lg btn-outline-primary export-dropdown"}>
                        <span className="dropdown-label">{getFilterValueForRecurringDatePicker(scheduleData.export_timeframe_in_days)}</span>
                        <img src={require("../../assets/img/calendar.png")} alt="" />
                    </Dropdown.Toggle>
                )}

                <Dropdown.Menu className={`dropdown-menu ${recurringDatePickerState ? 'show' : ''}`}>
                    {recurringDatePickerList.map((picker: any, index: number) => {
                        return (
                            <div className={`custom-control ${picker.key === scheduleData.export_timeframe_in_days ? 'active' : ''}`}
                                key={index} onClick={() => updateRecurringDatePickerState(picker.key)}>
                                <label>
                                    <span>{picker.value}</span>
                                </label>
                            </div>
                        );
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    }

    /** Recurring data picker card ended */

    /** Schedule start end date selector started */
    var d = new Date();
    d.setDate(d.getDate() - 6);
    const [scheduleDataStartDate, setScheduleDataStartDate] = useState<Date | Number>(d);
    const [scheduleDataEndDate, setScheduleDataEndDate] = useState<Date | Number>(new Date());



    const [isOpen, setIsOpen] = useState(false);

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setScheduleDataStartDate(start);
        setScheduleDataEndDate(end);

        if (start && end) {
            setIsOpen(false)
        }
    };

    const toggleDatepicker = () => {
        if (isOpen) {
            let startTime = scheduleData.start_timestamp;
            let endTime = scheduleData.end_timestamp;
            let isChangeReq = false;
            if (endTime == null) {
                endTime = scheduleData.start_timestamp + (7 * 24 * 60 * 60 * 1000);
                if (endTime > scheduleData.schedule.trigger_time) {
                    endTime = scheduleData.schedule.trigger_time
                }
                isChangeReq = true;
            }
            const currentDate = new Date(scheduleData.schedule.trigger_time);
            const oneYearAgo = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate());
            if (startTime < oneYearAgo.getTime()) {
                startTime = oneYearAgo.getTime();
                isChangeReq = true
            }
            if (isChangeReq) {
                setScheduleData(prevState => {
                    return {
                        ...prevState,
                        start_timestamp: startTime,
                        end_timestamp: endTime
                    }
                })

                setScheduleDataEndDate(new Date(endTime))
                setScheduleDataStartDate(new Date(startTime))

            }
        }
        setIsOpen(!isOpen);
    }

    const getDateInString = (dt, isHyphenReq = true) => {

        // let dateString = dt.toString();
        // let date;
        // if(dateString.includes('_00')) {
        //     dateString = dt;
        //     dateString = dateString.replace("_", " ");
        //      date = new Date(dateString);
        // } else {
        //     date = dt;
        // }



        if (dt == null) return;
        let tempDate = new Date(dt);
        const day = tempDate.getDate().toString().padStart(2, '0');
        const monthShortName = tempDate.toLocaleString('default', { month: 'short' });
        const yearShort = String(tempDate.getFullYear()).slice(-2)
        let separator = isHyphenReq ? "-" : " ";

        return day + separator + monthShortName + separator + yearShort;
    }

    const getFilterValueForStartEndDatePicker = (startDt, endDt) => {
        if (startDt && endDt) {
            return getDateInString(startDt, false) + " to " + getDateInString(endDt, false);
        }
    }

    const getStartEndDatePickerCard = () => {
        let dt = new Date(scheduleData.schedule.trigger_time);
        let minDate = new Date(dt.getFullYear() - 2, dt.getMonth(), dt.getDate());
        return <div className="displayFlexColumn">
            <div className="custom-label">Date Range</div>
            <Dropdown className='width-100' show={isOpen} onToggle={(event) => toggleDatepicker()}>
                {insightsLbls && (
                    <Dropdown.Toggle className='btn btn-lg btn-outline-primary export-dropdown'>
                        <span className="dropdown-label">{getFilterValueForStartEndDatePicker(scheduleDataStartDate, scheduleDataEndDate)}</span>
                        <img src={require("../../assets/img/calendar.png")} alt="" />
                    </Dropdown.Toggle>
                )}

                <Dropdown.Menu className={`dropdown-menu ${isOpen ? 'show' : ''} export-datepicker-dropdown` }>
                    <div>
                        <DatePicker
                            selected={scheduleDataStartDate}
                            onChange={handleDateChange}
                            startDate={scheduleDataStartDate}
                            endDate={scheduleDataEndDate}
                            selectsRange
                            selectsDisabledDaysInRange
                            inline
                            maxDate={new Date(scheduleData.schedule.trigger_time)}
                            showPopper
                        />
                        {/* <DatePicker
                            onChange={handleDateChange}
                            startDate={scheduleData.start_timestamp}
                            endDate={scheduleData.end_timestamp}
                            maxDate={new Date(scheduleData.schedule.trigger_time)}
                            minDate={minDate}
                            monthsShown={1}
                            selectsRange
                            inline
                        /> */}
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    }
    /** Schedule start end date selector ended */


    const fdate: string = (fetched_details.exportDates.export_fdate)!
    const tdate: string = (fetched_details.exportDates.export_tdate)!

    /** fetching list for export/template/scheduler */

    /** Utility methods for list start */
    const changeTab = (e: any) => {
        try {
            if (e === "exTemList") {
                if (!templateList.listDataFetched) {
                    getExportTemplateCall();
                }
            }
            if (e === "exSchList") {
                if (!schedulerList.listDataFetched) {
                    getExportSchedulerListCall();
                }
            }
            setPageTab((prevState: any) => {
                return {
                    ...prevState,
                    key: e
                };
            });
            setArrowIndex((prevState) => {
                return {
                    ...prevState,
                    index_up: null
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    };

    const modifiedData = (data: [], type: string) => {
        try {
            let finalData: any;
            let processData;
            if (type === 'datasource') {
                processData = data.length == 0 ? dataSourcesList.map(ds => ds.key) : data;
                finalData = processData.map((source) => getDataSourceValue(source));
            } else if (type === 'eventsource') {
                processData = data.length == 0 ? eventSourcesList.map(es => es.key) : data;
                finalData = processData.map((event) => getEventSourceValue(event));
            }
            return finalData.map(obj => <span className='chips'>{obj}</span>)
        } catch (error) {
            showBoundary(error)
        }

    }

    const getHeaderData = (headers) => {
        return headers.map(header => {
            if (header.img) {
                return <th>
                    <div className={header.class}>
                        <div>{header.title}</div>
                        <div>
                            <img src={require("../../assets/img/sortable-icon.png")} alt="" />
                        </div>
                    </div>
                </th>
            } else {
                return <th><div className={header.class}>{header.title}</div></th>
            }
        })
    }

    const loadTemplateSchedulerData = (cardData, isScheduler) => {
        setIsLoading(true);
        const convertToLocal = (timeStr) => {
            var [hours, minutes] = timeStr.split(':').map(Number);
            var gmtDate = new Date();
            gmtDate.setUTCHours(hours);
            gmtDate.setUTCMinutes(minutes);
            return gmtDate.getHours().toString().padStart(2, '0') + ":" + gmtDate.getMinutes().toString().padStart(2, '0');
        }
        const setData = (cardData, isScheduler) => {
            if (isScheduler) {
                setScheduleData((prevState) => {
                    return {
                        ...prevState,
                        schedule_id: cardData.job_code,
                        schedule_title: cardData.payload.schedule_title,
                        schedule: {
                            type: cardData.payload.schedule.type,
                            sub_type: cardData.payload.schedule.sub_type ? cardData.payload.schedule.sub_type : "day-of-week",
                            day_selection: cardData.payload.schedule.day_selection ? cardData.payload.schedule.day_selection : ["mon"],
                            trigger_time: cardData.payload.schedule.trigger_time ? (cardData.payload.schedule.trigger_time * 1000) : getDateIn15MinGap(),
                            time_of_day: cardData.payload.schedule.time_of_day ? convertToLocal(cardData.payload.schedule.time_of_day) : "18:00",
                        },
                        app_ids: cardData.payload.app_ids,
                        event_source: cardData.payload.event_source,
                        guide_type: cardData.payload.guide_type,
                        guide_type_breakdown: cardData.payload.guide_type_breakdown,
                        guide_ids: cardData.payload.guide_ids,
                        tooltip_ids: cardData.payload.tooltip_ids,
                        start_timestamp: cardData.payload.start_timestamp ? parseDateString(cardData.payload.start_timestamp) :
                            new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)).getTime(),
                        end_timestamp: cardData.payload.end_timestamp ? parseDateString(cardData.payload.end_timestamp) :
                            new Date().getTime(),
                        export_timeframe_in_days: cardData.payload.export_timeframe_in_days ? cardData.payload.export_timeframe_in_days : 7,
                        schedule_status: cardData.schedule_status,
                        email_cc: cardData.payload.email_cc,
                        selected_id: cardData.job_code,
                        emailLoadFlag: true
                    }
                })

                setScheduleDataStartDate(
                    cardData.payload.start_timestamp
                        ? parseDateString(cardData.payload.start_timestamp)
                        : new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)) // Creates a Date object 7 days ago
                );

                setScheduleDataEndDate(
                    cardData.payload.end_timestamp
                        ? parseDateString(cardData.payload.end_timestamp)
                        : new Date() // Creates a Date object with the current time
                );



            } else {
                setTemplateData((prevState) => {
                    return {
                        ...prevState,
                        template_id: cardData.job_code,
                        template_title: cardData.payload.template_title,
                        app_ids: cardData.payload.app_ids,
                        event_source: cardData.payload.event_source,
                        guide_type: cardData.payload.guide_type,
                        guide_type_breakdown: cardData.payload.guide_type_breakdown,
                        guide_ids: cardData.payload.guide_ids,
                        tooltip_ids: cardData.payload.tooltip_ids,
                    }
                })
            }
        }

        if (cardData.payload.app_ids.length == 1) {
            let guidePromise;
            let tooltipPromise;
            if (cardData.payload.guide_type.includes('guide')) {
                setGuideData((prevState: any) => {
                    return {
                        ...prevState,
                        isLoading: true,
                        isError: false,
                        lastAppID: cardData.payload.app_ids[0],
                    }
                });
                guidePromise = getGuideTooltipList(cardData.payload.app_ids[0], true);
            }
            if (cardData.payload.guide_type.includes('tooltip')) {
                setTooltipData((prevState: any) => {
                    return {
                        ...prevState,
                        isLoading: true,
                        isError: false,
                        lastAppID: cardData.payload.app_ids[0],
                    }
                });
                tooltipPromise = getGuideTooltipList(cardData.payload.app_ids[0], false);
            }
            Promise.all([guidePromise, tooltipPromise]).then((values) => {
                let guideObj = values[0];
                let tooltipObj = values[1];
                if (guideObj && guideObj.result === "success") {
                    let guideList = guideObj.data.map(guide => ({ key: guide.tour_id, value: guide.tour_title }))
                    setGuideData((prevState: any) => {
                        return {
                            ...prevState,
                            isLoading: false,
                            isError: false,
                            data: guideList,
                        }
                    })
                }

                if (tooltipObj && tooltipObj.result === "success") {

                    let tooltipList = [];
                    tooltipObj.data.map((guide) => {
                        guide['steps'].forEach((item) => {
                            tooltipList.push({ key: item['combined_id'], value: item['combined_title'] })
                        });
                    })

                    setTooltipData((prevState: any) => {
                        return {
                            ...prevState,
                            isLoading: false,
                            isError: false,
                            data: tooltipList
                        }
                    })
                }
                setData(cardData, isScheduler)
                setIsLoading(false);
            })
        } else {
            setData(cardData, isScheduler);
            setTimeout(() => setIsLoading(false), 500);
        }

        openModal();
    }

    const loadNewJobTemplate = (templateData) => {
        setNewJobData((prevState) => {
            return {
                ...prevState,
                app_ids: templateData.app_ids,
                event_source: templateData.event_source,
                guide_ids: templateData.guide_ids,
                guide_type: templateData.guide_type,
                guide_type_breakdown: templateData.guide_type_breakdown,
                tooltip_ids: templateData.tooltip_ids
            }
        })
        changeTab("exJobList");
        openModal();
    }

    const loadNewTemplate = () => {
        setTemplateData((prevState) => {
            return {
                ...prevState,
                "template_id": "",
                "template_title": "",
                "app_ids": [],
                "guide_ids": [],
                "tooltip_ids": [],
                "guide_type": [],
                "guide_type_breakdown": [],
                "event_source": []
            }
        })
    }

    const loadNewSchedule = () => {
        setScheduleData((prevState: any) => {
            return {
                ...prevState,
                emailLoadFlag: false
            }
        })
        setScheduleData((prevState: any) => {
            return {
                ...prevState,
                "schedule_id": "",
                "schedule_title": "",
                "schedule": {
                    "type": "one-time",
                    "trigger_time": getDateIn15MinGap(),
                    "sub_type": "day-of-week",
                    "day_selection": ["mon"],
                    "time_of_day": "18:00",
                },
                "app_ids": [],
                "guide_ids": [],
                "tooltip_ids": [],
                "guide_type": ['guide', 'tooltip'],
                "guide_type_breakdown": [],
                "event_source": ['player'],
                "export_timeframe_in_days": "7",
                "start_timestamp": new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)).getTime(),
                "end_timestamp": new Date().getTime(),
                "schedule_status": "inactive",
                "email_cc": [],
                "selected_id": '',
                emailLoadFlag: true
            }
        })


    }

    const getEmailIds = (data: any) => {
        let email_ids = []
        for (let item of data) {
            email_ids.push(item.value)
        }
        setEmailIds((prevState: any) => {
            return {
                ...prevState,
                ids: email_ids,
                valid: true
            }
        })
    }

    const getValidity = (flag: boolean) => {
        setExportBtnDisabled((prevState: any) => {
            return {
                ...prevState,
                valid: flag
            }
        })
    }
    /** Utility methods for list ended */

    /** API calls */
    const getExportListCall = (apiErrorFlag?: string) => {
        const params = {
            'time_zone': timeZone,
        };
        exportActions.getExportListData(
            params,
            "EXPORT_LIST_DATA",
            ActionTypes.SET_EXPORT_LIST_DATA,
            apiErrorFlag ?? '',
            exportList.firstLoad,
        ).then((res: any) => dispatch(res));
    }

    const getExportTemplateCall = (apiErrorFlag?: string) => {
        const params = {
            'time_zone': timeZone,
        };
        exportActions.getExportListData(
            params,
            "EXPORT_TEMPLATE_DATA",
            ActionTypes.SET_EXPORT_TEMPLATE_DATA,
            apiErrorFlag ?? '',
            exportList.firstLoad,
        ).then((res: any) => dispatch(res));
    }

    const getExportSchedulerListCall = (apiErrorFlag?: string) => {
        const params = {
            'time_zone': timeZone,
        };
        exportActions.getExportListData(
            params,
            "EXPORT_SCHEDULER_DATA",
            ActionTypes.SET_EXPORT_SCHEDULER_DATA,
            apiErrorFlag ?? '',
            schedulerList.firstLoad,
        ).then((res: any) => dispatch(res));
    }
    /** API calls */

    /** fetching list for export/template/scheduler ended*/

    /** CRUD Operations */

    const exportExcelRequest = () => {
        try {
            setArrowIndex((prevState: any) => {
                return {
                    ...prevState,
                    index_up: null
                };
            });
            if (appsFetched.isFetched) {
                const data = {
                    'start_timestamp': fetched_details?.exportDates?.export_fdate + "_00:00:00",
                    'end_timestamp': fetched_details?.exportDates?.export_tdate + "_23:59:59",
                    'app_ids': selectedAppData,
                    "event_source": selectedEventSourceData,
                    "guide_type": selectedDataSourceData,
                    "guide_ids": (selectedAppData.length == 1 && selectedDataSourceData.includes('guide')) && guideData.data.length != selectedGuideData.length
                        ? selectedGuideData : [],
                    "tooltip_ids": (selectedAppData.length == 1 && selectedDataSourceData.includes('tooltip') && tooltipData.data.length != selectedTooltipData.length)
                        ? selectedTooltipData : [],
                    "guide_type_breakdown": selectedDataSourceData.includes('guide') ? selectedPlayTypeData : [],
                    "email_cc": emailIds.ids
                };

                const param = {
                    'time_zone': timeZone,
                };

                let path = ApiRelativePaths.EXPORT_REQUEST;
                let paramObj = {};
                let a = CommonUtils.URLGenerator(param, path, paramObj);
                postCall(data, "EXPORT_REQUEST", param).then((data: any) => {
                    if (data.result === "success") {

                        toast.success(insightsLbls.requestSent, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        closeModal();
                        getExportListCall();
                    }
                });
            }
        } catch (error) {
            showBoundary(error)
        }
    }

    const saveTemplate = (isEdit) => {
        if (templateData.template_title != "") {
            const utilMethod = (res) => {
                if (res.result === "success") {
                    toast.success(isEdit ? insightsLbls.updatedSuccessfully : insightsLbls.requestSent, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    closeModal();
                    loadNewTemplate();
                    getExportTemplateCall();
                }
            }
            if (appsFetched.isFetched) {
                const { template_id, template_title, app_ids, event_source, guide_type, guide_ids, tooltip_ids, guide_type_breakdown } = templateData;
                const param = { 'time_zone': timeZone };
                if (isEdit) {
                    let data = {
                        "job_code": templateData.template_id,
                        template_id: template_id,
                        template_title: template_title,
                        app_ids: app_ids,
                        event_source: event_source,
                        guide_type: guide_type,
                        guide_ids: (app_ids.length == 1 && guide_type.includes('guide')) && guideData.data.length != guide_ids.length
                            ? guide_ids : [],
                        tooltip_ids: (app_ids.length == 1 && guide_type.includes('tooltip')) && tooltipData.data.length != tooltip_ids.length
                            ? tooltip_ids : [],
                        guide_type_breakdown: guide_type_breakdown
                    };
                    putCall(data, "SAVE_TEMPLATE_DATA", '').then((res: any) => {
                        utilMethod(res);
                    });
                } else {
                    let data = {
                        template_title: template_title,
                        app_ids: app_ids,
                        event_source: event_source,
                        guide_type: guide_type,
                        guide_ids: (app_ids.length == 1 && guide_type.includes('guide')) && guideData.data.length != guide_ids.length
                            ? guide_ids : [],
                        tooltip_ids: (app_ids.length == 1 && guide_type.includes('tooltip')) && tooltipData.data.length != tooltip_ids.length
                            ? tooltip_ids : [],
                        guide_type_breakdown: guide_type_breakdown
                    };
                    postCall(data, "SAVE_TEMPLATE_DATA", param).then((res: any) => {
                        utilMethod(res);
                    });
                }
            }
        } else {
            toast.error(insightsLbls.templateTitleErrorMsg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const saveSchedule = (isEdit) => {
        const convertToGMT = (timeStr) => {
            // Parse the time string to get hours and minutes
            var [hours, minutes] = timeStr.split(':').map(Number);

            // Create a date object with the given time and timezone
            var date = new Date();
            date.setHours(hours);
            date.setMinutes(minutes);

            // Format the GMT time as HH:MM
            var gmtHours = date.getUTCHours().toString().padStart(2, '0');
            var gmtMinutes = date.getUTCMinutes().toString().padStart(2, '0');

            return gmtHours + ':' + gmtMinutes;
        }

        const getDateFromEpoch = (dt) => {
            let tempDate = new Date(dt);
            return tempDate.getFullYear() + "-" + (tempDate.getMonth() + 1).toString().padStart(2, '0') + "-" +
                (tempDate.getDate()).toString().padStart(2, '0') + "_" + "00:00:00"
        }

        if (validateSave()) {
            const utilMethod = (res => {
                if (res.result === "success") {
                    toast.success(isEdit ? insightsLbls.updatedSuccessfully : insightsLbls.requestSent, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    closeModal();
                    loadNewSchedule();
                    getExportSchedulerListCall();
                }
            });
            if (appsFetched.isFetched) {
                const param = { 'time_zone': timeZone };
                let data;
                const { schedule_title, app_ids, event_source, guide_type, guide_ids, tooltip_ids, guide_type_breakdown } = scheduleData;

                if (scheduleData.schedule.type == "one-time") {
                    data = {
                        schedule: {
                            "type": scheduleData.schedule.type,
                            "trigger_time": parseInt((scheduleData.schedule.trigger_time / 1000).toString())
                        },
                        start_timestamp: getDateFromEpoch(scheduleData.start_timestamp),
                        end_timestamp: getDateFromEpoch(scheduleData.end_timestamp),
                        schedule_title: schedule_title,
                        app_ids: app_ids,
                        event_source: event_source,
                        guide_type: guide_type,
                        guide_ids: (app_ids.length == 1 && guide_type.includes('guide')) && guideData.data.length != guide_ids.length
                            ? guide_ids : [],
                        tooltip_ids: (app_ids.length == 1 && guide_type.includes('tooltip')) && tooltipData.data.length != tooltip_ids.length
                            ? tooltip_ids : [],
                        guide_type_breakdown: guide_type_breakdown,
                        email_cc: emailIds.ids
                    }


                    setScheduleDataEndDate(new Date(scheduleData.end_timestamp));
                    setScheduleDataStartDate(new Date(scheduleData.start_timestamp));

                } else {
                    data = {
                        schedule: {
                            type: scheduleData.schedule.type,
                            sub_type: scheduleData.schedule.sub_type,
                            day_selection: scheduleData.schedule.day_selection,
                            time_of_day: convertToGMT(scheduleData.schedule.time_of_day)
                        },
                        schedule_title: schedule_title,
                        app_ids: app_ids,
                        event_source: event_source,
                        guide_type: guide_type,
                        guide_ids: (app_ids.length == 1 && guide_type.includes('guide')) && guideData.data.length != guide_ids.length
                            ? guide_ids : [],
                        tooltip_ids: (app_ids.length == 1 && guide_type.includes('tooltip')) && tooltipData.data.length != tooltip_ids.length
                            ? tooltip_ids : [],
                        guide_type_breakdown: guide_type_breakdown,
                        export_timeframe_in_days: scheduleData.export_timeframe_in_days,
                        email_cc: emailIds.ids
                    }
                }

                if (isEdit) {
                    data = {
                        ...data,
                        job_code: scheduleData.schedule_id
                    }
                    putCall(data, "SAVE_SCHEDULE_DATA", '').then((res: any) => {
                        utilMethod(res);
                    })
                } else {
                    postCall(data, "SAVE_SCHEDULE_DATA", param).then((res: any) => {
                        utilMethod(res);
                    });
                }
            }
        }
    }

    const refreshList = () => {
        try {
            if (pageTab.key == "exJobList") {
                setExportList((prevState: any) => {
                    return {
                        ...prevState,
                        data: [],
                        apiError: 0,
                        firstLoad: true,
                        listDataFetched: false
                    };
                })
                getExportListCall();
            } else if (pageTab.key == "exTemList") {
                setTemplateList((prevState: any) => {
                    return {
                        ...prevState,
                        data: [],
                        apiError: 0,
                        firstLoad: true,
                        listDataFetched: false
                    };
                })
                getExportTemplateCall();
            } else if (pageTab.key == "exSchList") {
                setSchedulerList((prevState: any) => {
                    return {
                        ...prevState,
                        data: [],
                        apiError: 0,
                        firstLoad: true,
                        listDataFetched: false
                    };
                })
                getExportSchedulerListCall();
            }
        } catch (error) {
            showBoundary(error)
        }
    }

    /** CRUD Operations */

    /** Modal method started */
    const [deleteModal, setDeleteModal] = useState({
        open: false,
        id: "",
        optn: ''
    })

    const closeDeleteModal = () => {
        setDeleteModal((prevState: any) => {
            return {
                ...prevState,
                open: false
            }
        })
    }

    const referToDeleteModal = (ev, job_code, optn) => {
        ev.stopPropagation();
        setDeleteModal((prevState: any) => {
            return {
                ...prevState,
                open: true,
                id: job_code,
                optn: optn
            }
        })
    }

    const deleteFromModal = () => {
        let type = "";
        let params = { job_code: deleteModal.id };
        if (deleteModal.optn == 'template') {
            type = "SAVE_TEMPLATE_DATA";
        } else if (deleteModal.optn == 'scheduler') {
            type = "EXPORT_SCHEDULE_UPDATE";
        }
        if (type != "") {
            deleteCall(params, type, "").then((data: any) => {
                closeDeleteModal();
                if (data.result === "success") {
                    toast.error(insightsLbls.successfullyDeleted, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (deleteModal.optn == 'template') {
                        getExportTemplateCall();
                        if (templateData.template_id === deleteModal.id) {
                            loadNewTemplate();
                        }
                    } else if (deleteModal.optn == 'scheduler') {
                        getExportSchedulerListCall();
                        if (scheduleData.schedule_id === deleteModal.id) {
                            loadNewSchedule();
                        }
                    }
                }
            });
        }
    }

    const [playPauseModal, setPlayPauseModal] = useState({
        open: false,
        id: "",
        optn: ''
    })

    const closePlayPauseModal = () => {
        setPlayPauseModal((prevState: any) => {
            return {
                ...prevState,
                open: false
            }
        })
    }

    const referToPlayPauseModal = (job_code, optn) => {
        if (optn == "inactive") {
            let count = 0;
            for (let i = 0; i < schedulerList.data.length; i++) {
                if (schedulerList.data[i].schedule_status == "active") {
                    count++;
                }
            }

            if (count < 3) {
                let originalSchedularData = schedulerList.data.find(sch => sch.job_code === job_code);
                if (originalSchedularData.payload.schedule.type == "one-time") {
                    let currentTime;
                    if (scheduleData.schedule_id != "" && scheduleData.schedule_id == job_code) {
                        //already in milliseconds
                        currentTime = scheduleData.schedule.trigger_time;
                    } else {
                        //convert to milliseconds
                        currentTime = originalSchedularData.payload.schedule.trigger_time * 1000;
                    }
                    if (currentTime < new Date().getTime()) {
                        //check if date is crossed for schedule run
                        toast.error(insightsLbls.scheduleFutureDateErrorMsg, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        setPlayPauseModal((prevState: any) => {
                            return {
                                ...prevState,
                                open: true,
                                id: job_code,
                                optn: optn
                            }
                        })
                    }
                } else {
                    //for recurring update
                    setPlayPauseModal((prevState: any) => {
                        return {
                            ...prevState,
                            open: true,
                            id: job_code,
                            optn: optn
                        }
                    })
                }
            } else {
                toast.error(insightsLbls.scheduleMaxActiveErrorMsg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            setPlayPauseModal((prevState: any) => {
                return {
                    ...prevState,
                    open: true,
                    id: job_code,
                    optn: optn
                }
            })
        }
    }

    const validateSave = () => {
        let errMessage = "";
        if (scheduleData.schedule_title == "") {
            errMessage = insightsLbls.scheduleTitleErrorMsg;
        }

        if (scheduleData.schedule.type == "one-time" && scheduleData.schedule.trigger_time < new Date().getTime()) {
            errMessage = insightsLbls.scheduleFutureDateErrorMsg;
        }

        if (scheduleData.schedule.type == "recurring" && scheduleData.schedule.day_selection.length == 0) {
            errMessage = insightsLbls.scheduleDaySelectionErrorMsg;
        }

        if (scheduleData.schedule.day_selection.length > 7) {
            errMessage = insightsLbls.scheduleMaxDaySelectionErrorMsg;
        }

        if (errMessage != "") {
            toast.error(errMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return false;
        }
        return true;
    }

    const playPauseUpdate = () => {
        let params = { "job_code": playPauseModal.id };
        let payload = { "schedule_status": playPauseModal.optn == 'active' ? "inactive" : "active" };
        patchCall(params, payload, "EXPORT_SCHEDULE_UPDATE", '').then((data: any) => {
            if (data.result === 'success') {
                toast.success(data.data.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                getExportSchedulerListCall();
            } else if (data.result === 'error') {
                if (data.error) {
                    toast.error(data.error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
            closePlayPauseModal();
        });
    }
    /** Modal method ended */

    /** Use effect started */
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        document.title = insightsLbls.export;
    }, [insightsLbls])

    useEffect(() => {
        window.scrollTo(0, 0);
        let queryParams = {}
        navigate({
            pathname: `/export/export-list/`,
            search: qs.stringify(queryParams)
        })
        CommonUtils.LeftPanelHighlight(8, 0, 0, false, false);


        if (isInitialMount.current) {
            isInitialMount.current = false;
            getAppList();
            getExportListCall();
        }


    }, [])

    useEffect(() => {
        try {
            if (
                fetched_details.export.exportListData.data !== undefined &&
                fetched_details.export.exportListData.result === "success"
            ) {
                setExportList((prevState: any) => {
                    return {
                        ...prevState,
                        data: fetched_details.export.exportListData.data,
                        listDataFetched: true,
                        apiError: 0,
                    }
                })
                setExportBtnDisabled((prevState: any) => {
                    return {
                        ...prevState,
                        exportBtnDisabled: fetched_details.export.exportListData.data.length !== 0 ? ((fetched_details.export.exportListData.data[0]['job_status'] === '0' || fetched_details.export.exportListData.data[0]['job_status'] === '1') ? (true) : (false)) : (false)

                    }
                })
            }
            else if (fetched_details.export.exportListData.result === 'retry') {
                getExportListCall('retry');
            }
            else if (fetched_details.export.exportListData.result === "error") {
                setExportList((prevState: any) => {
                    return {
                        ...prevState,
                        listDataFetched: true,
                        apiError: 1,
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [fetched_details.export.exportListData])

    useEffect(() => {
        try {
            if (
                fetched_details.export.exportTemplateData.data !== undefined &&
                fetched_details.export.exportTemplateData.result === "success"
            ) {
                setTemplateList((prevState: any) => {
                    return {
                        ...prevState,
                        data: fetched_details.export.exportTemplateData.data,
                        listDataFetched: true,
                        apiError: 0,
                    }
                })
            }
            else if (fetched_details.export.exportTemplateData.result === 'retry') {
                getExportTemplateCall('retry');
            }
            else if (fetched_details.export.exportTemplateData.result === "error") {
                setTemplateList((prevState: any) => {
                    return {
                        ...prevState,
                        listDataFetched: true,
                        apiError: 1,
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [fetched_details.export.exportTemplateData])

    useEffect(() => {
        try {
            if (
                fetched_details.export.exportSchedulerData.data !== undefined &&
                fetched_details.export.exportSchedulerData.result === "success"
            ) {
                setSchedulerList((prevState: any) => {
                    return {
                        ...prevState,
                        data: fetched_details.export.exportSchedulerData.data,
                        listDataFetched: true,
                        apiError: 0,
                    }
                })

                if (scheduleData.schedule_id != "") {
                    let scheduleObj = fetched_details.export.exportSchedulerData.data.find(obj => obj.job_code == scheduleData.schedule_id);
                    if (scheduleObj) {
                        loadTemplateSchedulerData(scheduleObj, true);
                    }
                }
            }
            else if (fetched_details.export.exportSchedulerData.result === 'retry') {
                getExportSchedulerListCall('retry');
            }
            else if (fetched_details.export.exportSchedulerData.result === "error") {
                setSchedulerList((prevState: any) => {
                    return {
                        ...prevState,
                        listDataFetched: true,
                        apiError: 1,
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.export.exportSchedulerData])

    useEffect(() => {
        if (selectedAppData.length == 1) {
            /*Fetch data only if guides or tooltip is selected in data source, already api call is not in pending request
            & either last call has error or last app id was diff. from current app id, to remove unnecessary calling*/
            if (!guideData.isLoading && selectedDataSourceData.includes("guide") && (guideData.isError || selectedAppData[0] != guideData.lastAppID)) {
                /* condition to set selected guide as empty, so if in update mode, user change application multiple times, 
                 then selected guide will be updated accordingly */
                if (scheduleData.guide_ids.length > 0) {
                    setSelectedGuideData([]);
                }
                //set loading to true
                setGuideData((prevState: any) => {
                    return {
                        ...prevState,
                        isLoading: true,
                        isError: false,
                        lastAppID: selectedAppData[0],
                    }
                });
                //fetch guide data
                getGuideTooltipList(selectedAppData[0], true).then(res => {
                    if (res["result"] === "success") {
                        let guideList = res["data"].map(guide => ({ key: guide.tour_id, value: guide.tour_title }))
                        setGuideData((prevState: any) => {
                            return {
                                ...prevState,
                                isLoading: false,
                                isError: false,
                                data: guideList
                            }
                        })
                    } else {
                        setGuideData((prevState: any) => {
                            return {
                                ...prevState,
                                isLoading: false,
                                isError: true,
                                data: []
                            }
                        });
                    }
                });
            }
            if (!tooltipData.isLoading && selectedDataSourceData.includes("tooltip") && (tooltipData.isError || selectedAppData[0] != tooltipData.lastAppID)) {
                /* condition to set selected guide as empty, so if in update mode, user change application multiple times, 
                then selected guide will be updated accordingly */
                if (scheduleData.tooltip_ids.length > 0) {
                    setSelectedTooltipData([]);
                }
                //set loading to true
                setTooltipData((prevState: any) => {
                    return {
                        ...prevState,
                        isLoading: true,
                        isError: false,
                        lastAppID: selectedAppData[0]
                    }
                })
                //fetch tooltip data
                getGuideTooltipList(selectedAppData[0], false).then(res => {
                    if (res["result"] === "success") {

                        let tooltipList = [];
                        res["data"].map((guide) => {
                            guide['steps'].forEach((item) => {
                                tooltipList.push({ key: item['combined_id'], value: item['combined_title'] })
                            });
                        })

                        // let tooltipList = res["data"].map(guide => ({ key: guide.tour_id, value: guide.tour_title }))

                        setTooltipData((prevState: any) => {
                            return {
                                ...prevState,
                                isLoading: false,
                                isError: false,
                                data: tooltipList
                            }
                        })
                    } else {
                        setTooltipData((prevState: any) => {
                            return {
                                ...prevState,
                                isLoading: false,
                                isError: true,
                                data: []
                            }
                        });
                    }
                });
            }
        }
    }, [selectedDataSourceData, selectedAppData])

    useEffect(() => {
        if (pageTab.key == "exTemList") {
            setTemplateData((prevState) => {
                return {
                    ...prevState,
                    event_source: selectedEventSourceData,
                    app_ids: selectedAppData,
                    guide_type: selectedDataSourceData,
                    guide_type_breakdown: selectedPlayTypeData,
                    guide_ids: selectedGuideData,
                    tooltip_ids: selectedTooltipData
                }
            })
        } if (pageTab.key == "exSchList") {
            setScheduleData((prevState) => {
                return {
                    ...prevState,
                    event_source: selectedEventSourceData,
                    app_ids: selectedAppData,
                    guide_type: selectedDataSourceData,
                    guide_type_breakdown: selectedPlayTypeData,
                    guide_ids: selectedGuideData,
                    tooltip_ids: selectedTooltipData
                }
            })
        }
    }, [selectedEventSourceData, selectedAppData, selectedDataSourceData, selectedPlayTypeData, selectedGuideData, selectedTooltipData])
    /** Use effect ended */

    const getType = (type: string) => {
        try {
            if (type === 'email') {
                return insightsLbls.email
            } else {
                return type
            }
        } catch (error) {
            showBoundary(error)
        }
    }

    const getStatus = (status) => {
        switch (status) {
            case "0":
                return insightsLbls.pending;
            case "1":
                return insightsLbls.inProcess;
            case "2":
                return insightsLbls.complete;
            case "3":
                return insightsLbls.noRecordFound;
            default:
                return insightsLbls.fail;
        }
    }

    const getDatePicker = () => {
        return <div className='displayFlex align-items-center width-100 positionRelative datepicker-container'>
            <Datepicker source="exportEmail" width100="true"></Datepicker>
            <img className='caret-icon' src={require("../../assets/img/caret-down.png")} alt="" />
        </div>;
    }

    // const getDatePickerCard = () => {

    //     return <div className="exportRequestDatePickerDiv">
    //         <div className="exportRequestCardDiv">
    //             <div className="exportRequestFilterDiv dateDiv">
    //                 <Datepicker source="exportEmail"></Datepicker>
    //             </div>
    //             <div className="exportRequestFilterInformationDiv">
    //                 <div className="exportRequestFilterListTitleDiv">
    //                     {insightsLbls.selectedDateRange}:
    //                 </div>
    //                 {
    //                     <div className="exportRequestDateListDiv">
    //                         {
    //                             calendarOptions.map((data: any, key: number) => {
    //                                 return (

    //                                     fetched_details.exportDates.dateSelection == data.dateSelection ?
    //                                         (
    //                                             <>
    //                                                 <p className="font14 chipParagraph">
    //                                                     <span className="infoChip dateChips">{`${data.text} (${fetched_details.exportDates.export_fdate} to ${fetched_details.exportDates.export_tdate})`}</span>
    //                                                 </p>
    //                                             </>
    //                                         )
    //                                         :
    //                                         (null)
    //                                 );
    //                             })
    //                         }
    //                     </div>
    //                 }
    //             </div>
    //         </div>
    //     </div>
    // }

    // const getFilterCard = () => {
    //     return <div className="exportRequestDatePickerDiv">
    //         <div className="exportRequestCardDiv">
    //             <div className="exportRequestFilterDiv dateDiv positionRelative">
    //                 {getFrequencyDropdown()}
    //                 {scheduleData.schedule.type == "recurring" && getFrequencySubTypeDropdown()}
    //             </div>
    //             <div className="exportRequestFilterInformationDiv">
    //                 <div className="exportRequestFilterListTitleDiv displayFlexColumn">
    //                     {scheduleData.schedule.type == "recurring" && <div className='daySelectionDiv'>
    //                         <div>{scheduleData.schedule.sub_type == "day-of-week" ? insightsLbls.dayOfWeek : insightsLbls.dateOfMonth} </div>
    //                         <div className='daySelectDropdown'>{getDaySelectionDropdown()}</div>
    //                     </div>
    //                     }
    //                     <div className={`timeSelectionDiv ${scheduleData.schedule.type == "one-time" ? ' popperDivOneTime' : ''}`} >
    //                         <div>{insightsLbls.triggerTime}: </div>
    //                         <div>{scheduleData.schedule.type == "one-time" ? getDateTimeDropdown() : getTimeDropdown()}</div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // }

    const getAppDropdown = (appSelected) => {
        return appsFetched.isFetched ?
            (appsFetched.data.length > 0 ?
                <GetAppDropdown appsList={appsFetched.data} insightsLbls={insightsLbls}
                    selectedApps={appSelected} selectedAppsData={setSelectedAppDataHandler}></GetAppDropdown>
                : <div className='noData-div'>{insightsLbls.noDataFound}</div>)
            : <Loader></Loader>
    }

    const getModalBodyEmail = () => {
        return <div className='displayFlexColumn'>
            <div className="custom-label">{insightsLbls.emailCC}</div>
            <InputLabel dispatchEmailIds={getEmailIds} dispatchValidity={getValidity} email_cc={[]}></InputLabel>
        </div>
    }

    const getModalBodyTitle = (label, title, updateChangeHandler) => {
        return <div className='displayFlexColumn'>
            <div className='custom-label'>{label}</div>
            <input type="text" className="input-field" placeholder={label} value={title} onChange={(event) => updateChangeHandler(event.target.value)} />
        </div>
    }

    const getModalBodyDatePicker = () => {
        return <div className="displayFlexColumn">
            <div className="custom-label">* {insightsLbls.dateRange}</div>
            {getDatePicker()}
        </div>
    }

    const getModalBodyTriggerTypeDropdown = () => {
        return <div className="displayFlexColumn">
            <div className="custom-label">{insightsLbls.triggerType}</div>
            {getFrequencyDropdown()}
        </div>
    }

    const getModalBodyTriggerFrequencyDropdown = () => {
        return <div className="displayFlexColumn width-100">
            <div className="custom-label">{insightsLbls.triggerFrequency}</div>
            {getFrequencySubTypeDropdown()}
        </div>
    }

    const getModalBodyTriggerDayTimeDropdown = () => {
        return <div className="displayFlex justify-space-between gap-10">
            <div className="displayFlexColumn width-100">
                <div className="custom-label">{scheduleData.schedule.sub_type == "day-of-week" ? insightsLbls.triggerDay : insightsLbls.triggerDate}</div>
                {getDaySelectionDropdown()}
            </div>

            <div className="displayFlexColumn width-100">
                <div className="custom-label">{insightsLbls.triggerTime}</div>
                {getTimeDropdown()}
            </div>
        </div>
    }

    const getModalBodyDateTimePickerDropdown = () => {
        return <div className="displayFlexColumn">
            <div className="custom-label">{insightsLbls.triggerTime}</div>
            {getDateTimeDropdown()}
        </div>
    }

    const getModalBodyEventSourceDropdown = (id, defaultValue, preSelectedValue) => {
        return <div className='displayFlexColumn'>
            <div className='custom-label'>* {insightsLbls.eventSource}</div>
            {id == "" && getEventSourceDropdown(defaultValue)}
            {id != "" && getEventSourceDropdown(preSelectedValue)}
        </div>
    }

    const getModalBodyAppListDropdown = (id, defaultValue, preSelectedValue) => {
        return <div className='displayFlexColumn'>
            <div className='custom-label'>{insightsLbls.application}</div>
            {id == "" && getAppDropdown(defaultValue)}
            {id != "" && getAppDropdown(preSelectedValue)}
        </div>
    }

    const getModalBodyDataSourceDropdown = (id, defaultValue, preSelectedValue) => {
        return <div className='displayFlexColumn'>
            {selectedDataSourceData.includes('tooltip') && <span className='note'>{insightsLbls.noteAboutExclusion}</span>}
            <div className='custom-label'>{insightsLbls.dataSource}</div>
            {id == "" && getDataSourceDropdown(defaultValue)}
            {id != "" && getDataSourceDropdown(preSelectedValue)}
        </div>
    }

    const getModalBodyPlayTypeDropdown = (id, defaultValue, preSelectedValue) => {
        if (selectedDataSourceData.includes("guide")) {
            return <div className='displayFlexColumn'>
                <div className='custom-label'>{insightsLbls.playType}</div>
                {id == "" && getPlayTypeDropdown(defaultValue)}
                {id != "" && getPlayTypeDropdown(preSelectedValue)}
            </div>
        } else return;
    }

    const getModalBodyGuideDropdown = (id, defaultValue, preSelectedValue) => {
        if (selectedAppData.length == 1 && selectedDataSourceData.includes("guide")) {
            return <div className='displayFlexColumn'>
                <div className='custom-label'>{insightsLbls.guide}</div>
                {id == "" && getGuideDropdown(defaultValue)}
                {id != "" && getGuideDropdown(preSelectedValue)}
            </div>
        } else return;
    }

    const getModalBodyTooltipDropdown = (id, defaultValue, preSelectedValue) => {
        if (selectedAppData.length == 1 && selectedDataSourceData.includes("tooltip")) {
            return <div className='displayFlexColumn'>
                <div className='custom-label'>{insightsLbls.tooltip}</div>
                {id == "" && getTooltipDropdown(defaultValue)}
                {id != "" && getTooltipDropdown(preSelectedValue)}
            </div>
        } else return;
    }

    const getModalBody = () => {
        if (isLoading) {
            return <Loader></Loader>
        }
        if (pageTab.key == 'exJobList') {
            return <>
                {getModalBodyEmail()}
                {getModalBodyDatePicker()}
                {getModalBodyEventSourceDropdown('', newJobData.event_source, '')}
                {getModalBodyAppListDropdown('', newJobData.app_ids, '')}
                {getModalBodyDataSourceDropdown('', newJobData.guide_type, '')}
                {getModalBodyPlayTypeDropdown('', newJobData.guide_type_breakdown, '')}
                {getModalBodyGuideDropdown('', newJobData.guide_ids, '')}
                {getModalBodyTooltipDropdown('', newJobData.tooltip_ids, '')}
            </>
        } else if (pageTab.key == 'exTemList') {
            return <>
                {getModalBodyTitle(insightsLbls.templateTitle, templateData.template_title, updateTemplateTitle)}
                {getModalBodyEventSourceDropdown(templateData.template_id, ['player'], templateData.event_source)}
                {getModalBodyAppListDropdown(templateData.template_id, [], templateData.app_ids)}
                {getModalBodyDataSourceDropdown(templateData.template_id, ['guide', 'tooltip'], templateData.guide_type)}
                {getModalBodyPlayTypeDropdown(templateData.template_id, [], templateData.guide_type_breakdown)}
                {getModalBodyGuideDropdown(templateData.template_id, [], templateData.guide_ids)}
                {getModalBodyTooltipDropdown(templateData.template_id, [], templateData.tooltip_ids)}
            </>
        } else if (pageTab.key == 'exSchList') {
            return <>
                {getModalBodyTitle(insightsLbls.scheduleTitle, scheduleData.schedule_title, updateScheduleTitle)}
                {getModalBodyEmail()}
                {getModalBodyTriggerTypeDropdown()}
                {scheduleData.schedule.type == "one-time" && getModalBodyDateTimePickerDropdown()}
                {scheduleData.schedule.type == "one-time" && getStartEndDatePickerCard()}
                {scheduleData.schedule.type == "recurring" && getModalBodyTriggerFrequencyDropdown()}
                {scheduleData.schedule.type == "recurring" && getModalBodyTriggerDayTimeDropdown()}
                {scheduleData.schedule.type == "recurring" && getRecurringDatePickerCard()}
                {getModalBodyEventSourceDropdown(scheduleData.schedule_id, ['player'], scheduleData.event_source)}
                {getModalBodyAppListDropdown(scheduleData.schedule_id, [], scheduleData.app_ids)}
                {getModalBodyDataSourceDropdown(scheduleData.schedule_id, ['guide', 'tooltip'], scheduleData.guide_type)}
                {getModalBodyPlayTypeDropdown(scheduleData.schedule_id, [], scheduleData.guide_type_breakdown)}
                {getModalBodyGuideDropdown(scheduleData.schedule_id, [], scheduleData.guide_ids)}
                {getModalBodyTooltipDropdown(scheduleData.schedule_id, [], scheduleData.tooltip_ids)}
            </>
        }
    }

    const getModalFooter = () => {
        if (pageTab.key == 'exJobList') {
            return <>
                <button className="btn btn-primary save-btn"
                    onClick={() => exportExcelRequest()}
                    disabled={appsFetched.isFetched ? (exportBtnDisabled.exportBtnDisabled) : false}>
                    {insightsLbls.exportJobs}
                </button>
            </>
        } else if (pageTab.key == 'exTemList') {
            return <>
                <button className="btn btn-primary save-btn" onClick={() => saveTemplate(templateData.template_id != "")}>
                    {templateData.template_id != "" ? insightsLbls.updateTemplate : insightsLbls.saveTemplate}
                </button>
            </>
        } else if (pageTab.key == 'exSchList') {
            return <>
                <button className="btn btn-primary save-btn" onClick={() => saveSchedule(scheduleData.schedule_id != "")}
                    disabled={scheduleData.schedule_id != "" ? scheduleData.schedule_status == "active" : false}>
                    {scheduleData.schedule_id != "" ? insightsLbls.updateSchedule : insightsLbls.saveSchedule}
                </button>
            </>
        }
    }

    return (
        <section className="exportComponent" ref={componentRef}>
            <div className="exportDiv">
                <div className="header">
                    {insightsLbls.export}
                    <button className="pointer refreshBtn marginRight-15 floatRight" onClick={() => refreshList()}> {insightsLbls.refresh}</button>
                </div>
                <div className="content-container">
                    <div className="tab-section">
                        <Tabs activeKey={pageTab.key}
                            onSelect={changeTab}
                            id="showMe-tabs">
                            <Tab eventKey={"exJobList"} title="Job List">
                                <button className='btn btn-primary create-btn marginTop-25' onClick={openModal}>{insightsLbls.createJob}</button>
                                {exportList.listDataFetched ? <table className="custom-table jobTable">
                                    <thead>
                                        <tr>
                                            {getHeaderData(jobListHeader)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            exportList.data.map((cardData: any, key: number) => {
                                                return (<tr>
                                                    <td>{cardData.request_time}</td>
                                                    <td>{cardData.request_completed_time}</td>
                                                    <td>{modifiedData(cardData.payload.event_source, 'eventsource')}</td>
                                                    <td>{modifiedData(cardData.payload.guide_type, 'datasource')}</td>
                                                    <td>{appRequestedData(cardData.payload.app_ids)}</td>
                                                    <td>{getType(cardData.job_type)}</td>
                                                    <td>{getStatus(cardData.job_status)}</td>
                                                </tr>)
                                            })
                                        }
                                    </tbody>
                                </table> : <Loader></Loader>}
                            </Tab>
                            <Tab eventKey={"exTemList"} title="Template List">
                                <button className='btn btn-primary create-btn marginTop-25' onClick={() => { loadNewTemplate(); openModal() }}>{insightsLbls.createTemplate}</button>
                                {templateList.listDataFetched ? <table className="custom-table templateTable">
                                    <thead>
                                        <tr>
                                            {getHeaderData(templateListHeader)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            templateList.data.map((cardData: any, key: number) => {
                                                return (<tr>
                                                    <td>{cardData.payload.template_title}</td>
                                                    <td>{modifiedData(cardData.payload.event_source, 'eventsource')}</td>
                                                    <td>{modifiedData(cardData.payload.guide_type, 'datasource')}</td>
                                                    <td>{appRequestedData(cardData.payload.app_ids)}</td>
                                                    <td>
                                                        <div className='displayFlex align-items-start gap-10'>
                                                            <div>
                                                                <img src={require("../../assets/img/edit-icon.png")} title={insightsLbls.editTemplate} onClick={() => loadTemplateSchedulerData(cardData, false)} alt="edit" />
                                                            </div>
                                                            <div>
                                                                <img src={require("../../assets/img/emit_delete.png")} title={insightsLbls.deleteTemplate} onClick={(event) => referToDeleteModal(event, cardData.job_code, 'template')} className="" />
                                                            </div>
                                                            <div>
                                                                <span onClick={() => loadNewJobTemplate(cardData.payload)} className='linkText'>{insightsLbls.useTemplate}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>)
                                            })
                                        }
                                    </tbody>
                                </table> : <Loader></Loader>}
                            </Tab>
                            <Tab eventKey={"exSchList"} title="Scheduler List">
                                <button className='btn btn-primary create-btn marginTop-25' onClick={() => { loadNewSchedule(); openModal() }}>{insightsLbls.createSchedule}</button>
                                {schedulerList.listDataFetched ? <table className="custom-table templateTable">
                                    <thead>
                                        <tr>
                                            {getHeaderData(scheduleListHeader)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            schedulerList.data.map((cardData: any, key: number) => {
                                                return (<tr>
                                                    <td>{cardData.payload.schedule_title}</td>
                                                    <td>{modifiedData(cardData.payload.event_source, 'eventsource')}</td>
                                                    <td>{modifiedData(cardData.payload.guide_type, 'datasource')}</td>
                                                    <td>{appRequestedData(cardData.payload.app_ids)}</td>
                                                    <td>
                                                        <div className='displayFlex align-items-start gap-10'>
                                                            <div style={{ cursor: 'pointer' }} title={cardData.schedule_status == "active" ? insightsLbls.pauseSchedule : insightsLbls.playSchedule}
                                                                onClick={() => referToPlayPauseModal(cardData.job_code, cardData.schedule_status)}>
                                                                {cardData.schedule_status == "active" && <i className="fa fa-solid fa-pause"></i>}
                                                                {cardData.schedule_status == "inactive" && <i className="fa fa-solid fa-play"></i>}
                                                            </div>
                                                            <div>
                                                                <img src={require("../../assets/img/edit-icon.png")} title={insightsLbls.editSchedule} onClick={() => loadTemplateSchedulerData(cardData, true)} alt="edit" />
                                                            </div>
                                                            <div>
                                                                <img src={require("../../assets/img/emit_delete.png")} title={insightsLbls.deleteSchedule} onClick={(event) => referToDeleteModal(event, cardData.job_code, 'scheduler')} className="" />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>)
                                            })
                                        }
                                    </tbody>
                                </table> : <Loader></Loader>}
                            </Tab>
                        </Tabs>

                        <Modal show={modalIsOpen} onHide={closeModal} className="custom-modal">
                            <Modal.Header>
                                <div className='modal-title'>
                                    {insightsLbls.exportViaEmail}
                                </div>
                                <div className='close-icon' onClick={closeModal}>
                                    <img src={require("../../assets/img/close-icon.png")} alt='close-icon' />
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                {getModalBody()}
                            </Modal.Body>
                            <Modal.Footer className='displayFlex align-items-center justify-content-center'>
                                {getModalFooter()}
                            </Modal.Footer>
                        </Modal>

                        <Modal show={deleteModal.open} onHide={closeDeleteModal} className="custom-modal delete-modal">
                            <Modal.Header>
                                <div className='modal-title'>
                                    {deleteModal.optn == 'template' ? insightsLbls.deleteTemplate : insightsLbls.deleteSchedule}
                                </div>
                                <div className='close-icon' onClick={closeDeleteModal}>
                                    <img src={require("../../assets/img/close-icon.png")} alt='close-icon' />
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    {deleteModal.optn == 'template' ? insightsLbls.deleteTemplateConfirmationMsg : insightsLbls.deleteScheduleConfirmationMsg}
                                </div>
                            </Modal.Body>
                            <Modal.Footer className='displayFlex align-items-center justify-content-end'>
                                <button type="button" className="btn btn-secondary minWidth120 mx-2" data-dismiss="modal"
                                    onClick={closeDeleteModal}>{insightsLbls.cancel}</button>
                                <button type="button" className="btn btn-danger minWidth120 mx-2" data-dismiss="modal"
                                    onClick={deleteFromModal}>{insightsLbls.delete}</button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={playPauseModal.open} onHide={closePlayPauseModal} className="custom-modal">
                            <Modal.Header>
                                <div className='modal-title'>
                                    {playPauseModal.optn == 'active' ? insightsLbls.deactivate : insightsLbls.activate}
                                </div>
                                <div className='close-icon' onClick={closePlayPauseModal}>
                                    <img src={require("../../assets/img/close-icon.png")} alt='close-icon' />
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    {playPauseModal.optn == 'active' ? insightsLbls.deactivateScheduleMsg : insightsLbls.activateScheduleMsg}
                                </div>
                            </Modal.Body>
                            <Modal.Footer className='displayFlex align-items-center justify-content-end'>
                                <button type="button" className="btn btn-secondary minWidth120 mx-2" data-dismiss="modal"
                                    onClick={closePlayPauseModal}>{insightsLbls.cancel}</button>
                                <button type="button" className="btn btn-primary minWidth120 mx-2" data-dismiss="modal"
                                    onClick={playPauseUpdate}>
                                    {playPauseModal.optn == 'active' ? insightsLbls.deactivate : insightsLbls.activate}</button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div >
            </div >
        </section >
    );
}
export default withRouter(withReducer('export', exportReducer)(ExportList));

