import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';


// import { createStore } from 'redux';
import { Provider } from "react-redux";
import "./index.css";
import "./style.scss";
// import "./style_variables.scss";


import "./assets/css/font-awesome.min.css";
// import "./assets/css/font-awesomev5.8.2.css";
import "./assets/css/font-awesome4.7.0.css";
// import "./assets/css/font-awesome.min.css";



// import './assets/css/colorPick.css';
import './assets/js/sankey';
import "./assets/js/venn.js";

// import { EnvFiles } from "./env-files";


import reportWebVitals from "./reportWebVitals";

import initializeStore from "./store/store";

// import {store} from "./store/store";


const store = initializeStore();

const rootElement = document.getElementById('root');

// Create a root object using ReactDOM.createRoot
const root = ReactDOM.createRoot(rootElement);

// Render your app wrapped in StrictMode and Provider
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
