import * as ActionTypes from '../ActionTypes';
import axiosInstance, { getInstance } from '../../../network/apis/interceptor';
import { CommonUtils } from '../../../utils/CommonUtils';
import { ApiRelativePaths, RetryApi } from '../../../utils/GlobalConstants';


let ApiPaths: any = ApiRelativePaths;


export const setExportListData = (exportListData: any) => {
    return {
        type: ActionTypes.SET_EXPORT_LIST_DATA,
        exportListData: exportListData
    }
};

export const setExportSchedulerData = (exportSchedulerData: any) => {
    return {
        type: ActionTypes.SET_EXPORT_SCHEDULER_DATA,
        exportSchedulerData: exportSchedulerData
    }
};

export const setExportTemplateData = (exportTemplateData: any) => {
    return {
        type: ActionTypes.SET_EXPORT_TEMPLATE_DATA,
        exportTemplateData: exportTemplateData
    }
};


export const getExportListData = (params: any, url_path: any, caseType: any, apiErrorFlag: string, firstTimeload?: boolean) => {
    return new Promise((resolve, reject) => {
        let path = ApiPaths[url_path];
        let data: any = {};
        let paramObj = {};
        let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
        getInstance('export/export-list/');


        axiosInstance.get(generatedURL)
            .then(res => {
                data = {
                    "result": "success",
                    "data": res.data.data,
                    "firstTimeload": firstTimeload ?? false,
                };
            })
            .catch(err => {
                if (apiErrorFlag === '') {
                    data = {
                        "result": "retry",
                        "data": err,
                        "firstTimeload": firstTimeload ?? false,
                    };
                } else {
                    data = {
                        "result": "error",
                        "data": err,
                        "firstTimeload": firstTimeload ?? false,
                    };
                }
            })
            .then(() => {
                const callActionDispatch = () => {
                    switch (caseType) {
                        case ActionTypes.SET_EXPORT_LIST_DATA:
                            resolve(setExportListData(data));
                            break;
                        case ActionTypes.SET_EXPORT_TEMPLATE_DATA:
                            resolve(setExportTemplateData(data));
                            break;
                        case ActionTypes.SET_EXPORT_SCHEDULER_DATA:
                            resolve(setExportSchedulerData(data));
                            break;
                        default:
                            break;
                    }
                }
                if (data.result === 'retry') {
                    setTimeout(() => {
                        callActionDispatch();
                    }, RetryApi.TIMEOUT);
                } else {
                    callActionDispatch();
                }

            });
    });
}

export const getGuideTutorialListData = (params: any, url_path: any) => {
    return new Promise((resolve, reject) => {
        let path = ApiPaths[url_path];
        let data: any = {};
        let paramObj = {};
        let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
        getInstance('export/export-list/');

        axiosInstance.get(generatedURL)
            .then(res => {
                data = {
                    "result": "success",
                    "data": res.data.data,
                };
                resolve(data);
            })
            .catch(err => {
                data = {
                    "result": "error",
                    "data": err,
                };
                resolve(data);
            });
    });
}