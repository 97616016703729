import React, { useState } from "react";

function usePagination(totalCount, pageLimit, pageIndex) {
    const [pageNumber, setPageNumber] = useState(pageIndex);
    const pageCount = Math.ceil(totalCount / pageLimit);
    const [limit, setLimit] = useState(5)
    const changePage = (pN) => {
        setPageNumber(pN);
    };

    //   const pageData = () => {
    //     const s = pageNumber * pageLimit;
    //     const e = s + pageLimit;
    //     return items.slice(s, e);
    //   };

    const currRecordStart = () => {
        return (pageNumber) * pageLimit + 1;
    }

    const currRecordEnd = () => {
        return pageNumber === pageCount-1 ? totalCount : (pageNumber + 1) * pageLimit;
    }

    const nextPage = () => {
        setPageNumber(Math.min(pageNumber + 1, pageCount - 1));
    };

    const previousPage = () => {
        setPageNumber(Math.max(pageNumber - 1, 0));
    };

    const gotoFirstPage = () => {
        setPageNumber(0);
    }
    const gotoLastPage = () => {
        setPageNumber(pageCount - 1 );
    }

    const changeLimit = (e) => {
        setLimit(e);
        setPageNumber(0);
    };

    // const getLimit = () => { return limit }

    return {
        pageNumber,
        pageCount,
        changePage,
        // pageData,
        nextPage,
        previousPage,
        currRecordStart,
        currRecordEnd,
        changeLimit,
        limit,
        gotoFirstPage,
        gotoLastPage
    };
}

export default usePagination;