// Modified by: Mrunal Mane
// On: 2024-05-10
// Reason: Removed language dependancy from useEffects of (props.tableData, currentPage)

import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import './QuestionListCharts.scss';

import { InsightLabels } from 'labels';
import { useSelector } from "react-redux";
import { LanguageState } from "store/Reducers";
import { useErrorBoundary } from 'react-error-boundary';
import Pagination from 'components/Pagination/Pagination';

interface CurrentState {
    languageData: LanguageState
}

const Comment = (props: any) => {
    let fetched_details = useSelector((state: CurrentState) => state);

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const { showBoundary } = useErrorBoundary();

    const [tableData, setTableData] = useState({
        dataFetched: false,
        responseData: [],
        page: 1,
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 5,
            currentPage: 0
        },
    });

    const [currentPage, setCurrentPage] = useState(0);
    const PER_PAGE = 10;
    const offset = currentPage * PER_PAGE;
    const pageCount = Math.ceil(props.totalCount / PER_PAGE);


    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])


    useEffect(() => {
        try {
            const currentPageData = props.tableData
                .slice(offset, offset + PER_PAGE)

            setTableData((prevState: any) => {
                return {
                    ...prevState,
                    dataFetched: props.tableData.length ? true : false,
                    responseData: currentPageData
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }, [props.tableData, currentPage]);

    function handlePageClick({ selected: selectedPage }: any) {
        try {
            setCurrentPage(selectedPage);
        } catch (error) {
            showBoundary(error)
        }

    }

    return (
        <>
            {tableData.dataFetched
                ? (<div style={{ width: '100%' }}>
                    <ul className="list-group">
                        <li className="list-group-item bg-secondary">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h5><strong>{insightsLbls.feedback}</strong></h5>
                                </div>
                            </div>
                        </li>

                        {tableData.responseData.map((option: any, index: number) => {
                            return (
                                <li className="list-group-item" key={index}>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <span className="responseComment">{option.comment}</span>
                                            <span className="feedback-timestamp"><small>
                                                <i className="fa fa-clock-o surveyTime-icon" aria-hidden="true"></i>{option.timestamp}</small>
                                            </span>
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>


                    <div className="paginationContainer">
                        {/* <ReactPaginate
                            previousLabel={<span aria-hidden="true">«</span>}
                            nextLabel={<span className={"nexBtn"} aria-hidden="true">»</span>}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"pagination__link--disabled"}
                            activeClassName={"pagination__link--active"}
                        /> */}
                        <Pagination
                        pageNumber={0}
                        showEntries={false}
                        totalCount={props.totalCount}
                        pageLimit={PER_PAGE}
                        handleChange={(event) => handlePageClick(event)}
                        changeLimit={(event) => {}}
                        rowsLabel={insightsLbls.rowsPerPage}
                        />
                    </div>
                </div>)
                : (
                    <div style={{ textAlign: 'center', paddingBottom: '50px', fontSize: '13px', color: 'black', fontWeight: '600' }}>
                        {insightsLbls.noResponseYet}
                    </div >
                )
            }

        </>
    )
};

export default React.memo(Comment);
