import * as ActionTypes from '../ActionTypes';
import axiosInstance, { getInstance } from '../../../network/apis/interceptor';
import { CommonUtils } from '../../../utils/CommonUtils';
import { ApiRelativePaths, RetryApi } from '../../../utils/GlobalConstants';

let ApiPaths: any = ApiRelativePaths;

export const getUserStats = (userActivity: any) => {
    return {
        type: ActionTypes.USER_STATS,
        userActivity: userActivity
    }
};

export const getUserVisit = (userVisit: any) => {
    return {
        type: ActionTypes.PAGE_BLOCK_CHART,
        userVisit: userVisit
    }
};

export const getPageLoadTime = (pageLoadTime: any) => {
    return {
        type: ActionTypes.INSIGHTS_LOADTIME,
        pageLoadTime: pageLoadTime
    }
};

export const getUserSystemStats = (userSystemData: any) => {
    return {
        type: ActionTypes.USAGE_STATS,
        userSystemData: userSystemData
    }
};

export const getPageTag = (pageTag: any) => {
    return {
        type: ActionTypes.TAG_PAGE,
        pageTag: pageTag
    }
};

export const setPageUserEngagementStatsChartData = (userEngagementStatsChartData: any) => {
    return {
        type: ActionTypes.SET_PAGE_USER_ENGAGEMENT_STATS_CHART,
        userEngagementStatsChartData: userEngagementStatsChartData
    }
};

export const setPageUserEngagementSummary = (userEngagementStatsSummaryData: any) => {
    return {
        type: ActionTypes.SET_PAGE_USER_ENGAGEMENT_SUMMARY,
        userEngagementStatsSummaryData: userEngagementStatsSummaryData
    }
};

export const setPageUserEngagementModalChart = (userEngagementModalChartData: any) => {
    return {
        type: ActionTypes.SET_PAGE_USER_ENGAGEMENT_MODAL_CHART,
        userEngagementModalChartData: userEngagementModalChartData
    }
};

export const getPageDetailData = (params: any, url_path: any, caseType: any, apiErrorFlag: string, optionalData?:any, firstTimeload?: Boolean) => {
    return new Promise((resolve, reject) => {
        let path = ApiPaths[url_path];
        let data:any = {};
        let paramObj = {};
        let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
        getInstance('app-insights/page-detail')

        let urlData;

        if (optionalData !== '' && optionalData !== undefined) {
            urlData = generatedURL + optionalData;
        } else {
            urlData = generatedURL;
        }

        axiosInstance.get(urlData)
        .then(res => {
            data = {
                "result": "success",
                "data": res.data.data,
            };
        })
        .catch(err => {
            if (apiErrorFlag === '') {
                data = {
                    "result": "retry",
                    "data": err,
                };
            } else {
                data = {
                    "result": "error",
                    "data": err,
                    "errorCode":err?.response?.status ?? '',
                };
            }
        })
        .then(() => {
            const callActionDispatch = () => {
                switch (caseType) {
                    case ActionTypes.USER_STATS:
                        resolve(getUserStats(data));
                        break;

                    case ActionTypes.PAGE_BLOCK_CHART:
                        resolve(getUserVisit(data));
                        break;

                    case ActionTypes.INSIGHTS_LOADTIME:
                        resolve(getPageLoadTime(data));
                        break;

                    case ActionTypes.USAGE_STATS:
                        resolve(getUserSystemStats(data));
                        break;

                    case ActionTypes.TAG_PAGE:
                        resolve(getPageTag(data));
                        break;

                    case ActionTypes.SET_PAGE_USER_ENGAGEMENT_STATS_CHART:
                        resolve(setPageUserEngagementStatsChartData(data));
                        break;
                    case ActionTypes.SET_PAGE_USER_ENGAGEMENT_SUMMARY:                      
                    resolve(setPageUserEngagementSummary(data));
                        break;
                    case ActionTypes.SET_PAGE_USER_ENGAGEMENT_MODAL_CHART:                      
                    resolve(setPageUserEngagementModalChart(data));
                        break;

                    default:
                        break;
                }
            }
            
            if (data.result === 'retry') {
                setTimeout(() => {
                    callActionDispatch();
                }, RetryApi.TIMEOUT);
            } else {
                callActionDispatch();
            }
            
        });
    });
}