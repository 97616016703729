import React from 'react';
import { useState } from 'react';
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { LanguageState } from "store/Reducers";

import { getCallExportExcel } from '../../utils/ApiCallActions';


import './ExportExcel.scss';


import { useEffect } from "react";
// import insightsLabels from "utils/stringConstants/trans_utils";
import {getSelectedLabels} from "utils/stringConstants/trans_utils";
import { InsightLabels } from 'labels';
// let insightsLbls = insightsLabels; 

import { useErrorBoundary } from 'react-error-boundary';
interface CurrentState {
    languageData: LanguageState
}
interface Props {
    path: string,
    params: any
}

const ExportExcel = (props: Props) => {

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)

    const { showBoundary } = useErrorBoundary();
    let fetched_details = useSelector((state: CurrentState) => state);
    
    const [exportExcelModal, setExportExcelVal] = useState({
        openExportExcelModal: false,
    })

    useEffect(() => {
        if(fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
     
    },[fetched_details.languageData.languageData])

    const openModal = () => {
        setExportExcelVal({ ...exportExcelModal, openExportExcelModal: true });
    }

    const getCsv = (viewOl: boolean) => {
        try {
                // props.tableEvent('exportExcel', viewOl);
        setExportExcelVal({ ...exportExcelModal, openExportExcelModal: false });
        
        let params = props.params;
        let path = props.path;
        getCallExportExcel(params, path, viewOl);
        } catch (error) {
                    showBoundary(error)
                }
    
    }


    return (

        <div>
            <p className="pull-right export-button" >
                <button className="form-control btn btn-primary" data-toggle="modal" onClick={() => openModal()}>
                    {/* <img src={require("../../assets/images/export-excel.png")} width="22" height="auto" alt="" className="excel-icon" /> */}
                    {insightsLbls.exportToExcel}
                 {/* {insightsLbls.export} */}
                </button>
            </p>
            <Modal onHide={() => setExportExcelVal({ ...exportExcelModal, openExportExcelModal: false })} show={exportExcelModal.openExportExcelModal} bsSize="medium">
                <Modal.Header>
                    <Modal.Title>{insightsLbls.exportExcel}</Modal.Title>
                    <button type="button" className="close" onClick={() => setExportExcelVal({ ...exportExcelModal, openExportExcelModal: false })} >
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">{insightsLbls.close}</span>
                    </button>
                </Modal.Header>
                <Modal.Body className="displayFlex height100-percent exportExcel-div">
                    <div className="col-lg-12 paddingTB-20 displayFlex btns-div">
                        <div className="downloadCsv col-lg-6 padding0">
                            <span className="btn btn-primary font13" onClick={() => getCsv(false)}> <i className="fa fa-download marginRight-5" aria-hidden="true"></i>{insightsLbls.download}</span>
                        </div>
                        <div className="marginTop-25">
                            <div className="vl"></div>
                            <div className="or-div">{insightsLbls.or}</div>
                        </div>
                        <span className="text font14 marginLeft-10 font500">
                           {insightsLbls.excelViewerQn}
                      </span>
                       
                       
                        <div className="downloadCsv col-lg-6 padding0">
                            <span className="btn btn-primary font13" onClick={() => getCsv(true)}><i className="fa fa-cloud marginRight-5" aria-hidden="true"></i>{insightsLbls.viewOnline}</span>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ExportExcel;