import * as ActionTypes from '../ActionTypes';
import axiosInstance, { getInstance } from '../../../network/apis/interceptor';
import { CommonUtils } from '../../../utils/CommonUtils';
import { ApiRelativePaths, RetryApi } from '../../../utils/GlobalConstants';


let ApiPaths: any = ApiRelativePaths;


export const setGuideAnalyticsDetailTutorialGuidesChart = (guideAnalyticsDetailTutorialGuidesChartData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_ANALYTICS_DETAILS_TUTORIAL_GUIDES_CHART_DATA,
        guideAnalyticsDetailTutorialGuidesChartData: guideAnalyticsDetailTutorialGuidesChartData
    }
};


export const setGuideAnalyticsDetailTutorialGuidesSummaryTable = (guideAnalyticsDetailTutorialGuidesTableData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_TUTORIAL_SUMMARY_TABLE_DATA,
        guideAnalyticsDetailTutorialGuidesTableData: guideAnalyticsDetailTutorialGuidesTableData
    }
};



export const getTutorialGuideAnalyticsDetailData = (params: any, url_path: any, caseType: any, apiErrorFlag: string, firstTimeload?: boolean, optionalData?: any) => {
    return new Promise((resolve, reject) => {
        let path = ApiPaths[url_path];
        let data:any = {};
        let paramObj = {};
        // let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
        let urlData;

        if (optionalData !== '' && optionalData !== undefined) {
            urlData = urlData = CommonUtils.URLGenerator(params, path + "/" + optionalData, paramObj);
        } else {
            urlData = CommonUtils.URLGenerator(params, path, paramObj);
        }
        getInstance('tutorial-guides-detail');

        axiosInstance.get(urlData)
            .then(res => {
                data = {
                    "result": "success",
                    "data": res.data.data,
                    "firstTimeload": firstTimeload ?? false,
                };
            })
            .catch(err => {
                if (apiErrorFlag === '') {
                    data = {
                        "result": "retry",
                        "data": err,
                        "firstTimeload": firstTimeload ?? false,
                    };
                } else {
                    data = {
                        "result": "error",
                        "data": err,
                        "errorCode":err?.response?.status ?? '',
                        "firstTimeload": firstTimeload ?? false,
                    };
                }
            })
            .then(() => {
                const callActionDispatch = () => {
                    switch (caseType) {
         
                        case ActionTypes.SET_GUIDE_ANALYTICS_DETAILS_TUTORIAL_GUIDES_CHART_DATA:
                            resolve(setGuideAnalyticsDetailTutorialGuidesChart(data));
                            break;

                        
                        case ActionTypes.SET_GUIDE_TUTORIAL_SUMMARY_TABLE_DATA:
                            resolve(setGuideAnalyticsDetailTutorialGuidesSummaryTable(data));
                            break;
              
                        default:
                            break;
                    }  
                }

                if (data.result === 'retry') {
                    setTimeout(() => {
                        callActionDispatch();
                    }, RetryApi.TIMEOUT);
                } else {
                    callActionDispatch();
                }
                

            });
    });
}