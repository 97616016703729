import React from "react";
import Chart from "react-apexcharts";

import { useErrorBoundary } from 'react-error-boundary';

import $ from 'jquery';
import { yearsToQuarters } from "date-fns";
import { setGuideAnalyticsDetailShowMeChart } from "store/Actions/GuideInsights/guideAnalyticsDetail";
import { randomBeta } from "d3";


const Piechart = (props) => {
    const { showBoundary } = useErrorBoundary();
    // this.pieChartFetched = true;

    try {
    } catch (error) {
        showBoundary(error)
    }
    $('pieChart').empty();
    let type = "";
    type = props.name;
    let bd = [];
    bd = props.pieData;
    bd.sort(compare);
    var pieOptNames = [];
    var pieOptCounts = [];

    function compare(a, b) {
        try {
            const bandA = a.name.toLowerCase();
            const bandB = b.name.toLowerCase();

            let comparison = 0;
            if (bandA > bandB) {
                comparison = 1;
            } else if (bandA < bandB) {
                comparison = -1;
            }
            return comparison;
        } catch (error) {
            showBoundary(error)
        }
    }

    try {
        // nv.utils.windowResize(this.optionsShowme.chart.update);
        const bcount = [];
        const bname = [];

        if (type === 'browser' && bd.length !== 0) {
            bcount[0] = bd[0].count;
            bname[0] = bd[0].name;
            var j = 0;
            for (let i = 1; i < bd.length; i++) {
                if (bd[i].name == bd[i - 1].name) {
                    bcount[j] = bcount[j] + bd[i].count;
                    bname[j] = bd[i].name;
                } else {
                    j++;
                    bcount[j] = 0;
                    bname[j] = bd[i].name;
                    bcount[j] += bd[i].count;
                }
            }
            pieOptCounts = bcount;
            pieOptNames = bname;
        }

        const ocount = [];
        const oname = [];
        if (type === 'os' && bd.length !== 0) {

            ocount[0] = bd[0].count;
            oname[0] = bd[0].name;
            var j = 0;
            for (let i = 1; i < bd.length; i++) {
                if (bd[i].name == bd[i - 1].name) {
                    ocount[j] += bd[i].count;
                    oname[j] = bd[i].name;
                } else {
                    j++;
                    ocount[j] = 0;
                    oname[j] = bd[i].name;
                    ocount[j] += bd[i].count;
                }
            }
            pieOptCounts = ocount;
            pieOptNames = oname;
        }

        const dcount = [];
        const dname = [];
        if (type === 'device' && bd.length !== 0) {
            dcount[0] = bd[0].count;
            dname[0] = bd[0].name;
            var j = 0;
            for (let i = 1; i < bd.length; i++) {
                if (bd[i].name == bd[i - 1].name) {
                    dcount[j] += bd[i].count;
                    dname[j] = bd[i].name;
                } else {
                    j++;
                    dcount[j] = 0;
                    dname[j] = bd[i].name;
                    dcount[j] += bd[i].count;
                }
            }
            pieOptCounts = dcount;
            pieOptNames = dname;
        }

        const scount = [];
        const sname = [];
        if (type === 'screen_resolution' && bd.length !== 0) {
            scount[0] = bd[0].count;
            sname[0] = bd[0].name;
            var j = 0;
            for (let i = 1; i < bd.length; i++) {
                if (bd[i].name == bd[i - 1].name) {
                    scount[j] += bd[i].count;
                    sname[j] = bd[i].name;
                } else {
                    j++;
                    scount[j] = 0;
                    sname[j] = bd[i].name;
                    scount[j] += bd[i].count;
                }
            }
            pieOptCounts = scount;
            pieOptNames = sname;
        }

        var setLegendTooltip = function () {
            try {
            } catch (error) {
                showBoundary(error)
            }
            pieOptNames.forEach(function (cd, i) {
                let idx = i + 1;
                let id = '.apexcharts-legend-series[rel="' + idx + '"]';
                let tooltipHtml = '<div class="legend-tooltip-' + idx + '">' + pieOptNames[i] + '</div>';
                let tooltipCss =
                    '<style type="text/css">' +
                    '.legend-tooltip-' + idx + '{' +
                    'display: none;' +
                    'position: absolute;' +
                    'border: 1px solid #666;' +
                    'background: white;' +
                    'color: black;' +
                    'border-radius: 4px;' +
                    'padding: 5px;' +
                    'z-index: 1500;' +
                    'font-size: 13px;' +
                    'overflow: auto;' +
                    'left:30%;' +
                    'min-width:100px!important;' +
                    'max-width:140px;' +
                    '}' +

                    '.apexcharts-legend-series[rel="' + idx + '"] {' +
                    'position: relative;' +
                    '}' +

                    '.apexcharts-legend-series[rel="' + idx + '"]:not(.apexcharts-inactive-legend):hover > .legend-tooltip-' + idx + '{' +
                    'display: block' +
                    '}' +
                    '</style>';

                $(id).append(tooltipCss + tooltipHtml);
            })
            $(".apexcharts-legend").css('overflow', 'unset !important');
        };
    } catch (error) {
        showBoundary(error)
    }

    let data;
    try {
        data =
        {
            series: pieOptCounts,

            options: {
                chart: {
                    height: 230,
                    type: 'pie',
                    background: '#fff',
                    events: {
                        updated: function () {
                            if (props.legendTooltip) {
                                setLegendTooltip();
                            }
                        },
                        mounted: function () {
                            if (props.legendTooltip) {
                                setLegendTooltip();
                            }
                        }
                    }
                },

                labels: pieOptNames,

                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'top'
                        }
                    }
                }],
            },
        }

    } catch (error) {
        showBoundary(error)
    }


    return (
        <>
            <div id="piechart">
                <Chart
                    options={data.options}
                    series={data.series}
                    type="pie"
                    width={380}

                />
            </div>
        </>
    );
};
export default React.memo(Piechart);