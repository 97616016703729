/**
 * Author: Mohit Tiwari
 */
import { InsightLabels } from "labels";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { LanguageState } from "store/Reducers";
import "./BusinessIntelligenceToolsIntegration.scss";
import { getCall, getCallDownloadTool, postCall, putCall } from "utils/ApiCallActions";
import { RetryApi } from "utils/GlobalConstants";
import Loader from "components/Loader/Loader";
import { toast } from "react-toastify";

interface CurrentState {
    languageData: LanguageState
}

const BusinessIntelligenceToolsIntegration = (props) => {

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);
    let fetched_details = useSelector((state: CurrentState) => state);
    const inputRef = useRef(null);
    const [downloadInfoAccordian, setDownloadInfoAccordian] = useState(true);
    const [pullDataInfoAccordian, setPullDataInfoAccordian] = useState(true);
    const [tokenData, setTokenData] = useState({
        isError: false,
        errorMsg: '',
        data: {},
        isInitiated: '',
        isLoading: false
    })

    /** Use effect started */
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
    }, [fetched_details.languageData.languageData]);

    useEffect(() => {
        getPowerBiToken();
    }, [])
    /** Use effect ended */

    /** Method started */
    const getPowerBiToken = (apiErrorFlag?: string) => {
        setTokenDataUtil(true, false, '', {});
        getCall({}, 'POWERBI_ODATA_TOKEN', apiErrorFlag ?? '').then((data: any) => {
            utilAPIMethod(data, getPowerBiToken);
            setTokenData((prevState) => {
                return {
                    ...prevState,
                    isInitiated: data.data.odata_initiation_status
                }
            })
        });
    }

    const initializeODataToken = (apiErrorFlag?: string) => {
        setTokenDataUtil(true, false, '', {})
        postCall({}, 'ODATA_TOKEN_INITIATION').then((data: any) => {
            getPowerBiToken();
        });
    }

    const generatePowerBiToken = (apiErrorFlag?: string) => {
        setTokenDataUtil(true, false, '', {})
        postCall({}, 'POWERBI_ODATA_TOKEN').then((data: any) => {
            utilAPIMethod(data, generatePowerBiToken);
        });
    }

    const authenticatePowerBIToken = (apiErrorFlag?: string) => {
        setTokenDataUtil(true, false, '', {})
        putCall({}, 'POWERBI_ODATA_TOKEN').then((data: any) => {
            if (data?.result === 'success') {
                setTokenDataUtil(false, false, '', data.data.data);
            } else if (data.result === 'retry') {
                setTimeout(() => {
                    authenticatePowerBIToken('retry')
                }, RetryApi.TIMEOUT)
            } else if (data?.result === "error") {
                setTokenDataUtil(false, true, data.error, {});
                toast.error(data.error ? data.error : insightsLbls.somethingWentWrong, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        });
    }

    const setTokenDataUtil = (isLoading, isError, errorMsg, data) => {
        setTokenData((prevState) => {
            return {
                ...prevState,
                isLoading: isLoading,
                isError: isError,
                errorMsg: errorMsg,
                data: data
            }
        })
    }

    const utilAPIMethod = (data, callbackMethod) => {
        if (data?.result === 'success') {
            setTokenDataUtil(false, false, '', data.data);
        } else if (data.result === 'retry') {
            setTimeout(() => {
                callbackMethod('retry')
            }, RetryApi.TIMEOUT)
        } else if (data?.result === "error") {
            setTokenDataUtil(false, true, data.error, {});
            toast.error(data.error ? data.error : insightsLbls.somethingWentWrong, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const downloadTool = () => {
        getCallDownloadTool({}, 'POWERBI_CONNECTOR').then((data: any) => {
            if (data?.result === "error") {
                toast.error(data.error ? data.error : insightsLbls.somethingWentWrong, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }

    const getButtons = () => {
        if (tokenData.isInitiated != "initiated") {
            switch (tokenData.isInitiated) {
                case "not_initiated":
                    return <button className="btn btn-primary generateUrlBtn" onClick={() => initializeODataToken()}>{insightsLbls.initiateDataSynchronization}</button>

                case "initiation_in_progress":
                    return <div className="text-danger note">
                        {insightsLbls.initiationInProgressInfo}
                    </div>

                case "initiation_failed":
                    return <>
                        <div className="displayFlexColumn">
                            <div className="text-danger note">
                                {insightsLbls.initiationFailedInfo}
                            </div>
                            <button className="btn btn-primary generateUrlBtn" onClick={() => initializeODataToken()}>{insightsLbls.initiateDataSynchronization}</button>

                        </div>
                    </>
            }
        } else {
            return <>
                <button className="btn btn-primary generateUrlBtn" onClick={() => generatePowerBiToken()}>{insightsLbls.generateUrl}</button>
                {
                    tokenData.data['authenticated_url'] && tokenData.data['authenticated_url'] != '' &&
                    <button className="btn btn-primary generateUrlBtn" onClick={() => authenticatePowerBIToken()}>{insightsLbls.authenticateUrl}</button>
                }
                {
                    !tokenData.isError && tokenData.data['authenticated_url'] != '' && <div className="positionRelative width-100 urlInput">
                        <input type="text" className="form-control" readOnly value={tokenData.data['authenticated_url']} ref={inputRef} />
                        <span className="copy-button" onClick={copyToClipboard}>{insightsLbls.copy}</span>
                    </div>
                }
            </>
        }

    }

    const copyToClipboard = () => {
        const input = inputRef.current;
        if (input) {
            navigator.clipboard.writeText(input.value) // Copy the input value to the clipboard
                .then(() => {
                    toast.success(insightsLbls.copied, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                })
                .catch(err => {
                    console.error('Failed to copy: ', err);
                });
        }
    };
    /** Method ended */

    return <section className="bitiComponent">
        <div className="bitiDiv">
            <div className="header">
                {insightsLbls.businessIntelligenceToolsIntegrations}
            </div>
            <div className="content-container">
                <div className="displayFlexColumn gap-15">
                    <div className="accordian">
                        <div className="accordion-header" onClick={() => setDownloadInfoAccordian(!downloadInfoAccordian)}>
                            {insightsLbls.bitiInfoHeading0}
                            {downloadInfoAccordian ? <img src={require("../../assets/img/caret-up-medium.png")} alt="" />
                                : <img src={require("../../assets/img/caret-down-medium.png")} alt="" />}
                        </div>
                        <div className="accordion-body" id="accordion-body" style={{ display: downloadInfoAccordian ? "block" : "none" }}>
                            <ul>
                                <li><span className="link" onClick={() => downloadTool()}>{insightsLbls.bitiInfo0_1}</span> {insightsLbls.bitiInfo0_2}</li>
                                <li>{insightsLbls.bitiInfo0_3} <span className="bold">[Documents]/Power BI Desktop/Custom Connectors</span></li>
                                <li>{insightsLbls.bitiInfo0_4} <span className="bold">{insightsLbls.bitiInfo0_5}</span> {insightsLbls.bitiInfo0_6}
                                    <span className="bold"> (File &gt; Options and settings &gt; Options &gt; Security &gt; Data Extensions)</span></li>
                                <li>{insightsLbls.bitiInfo0_7}</li>
                            </ul>
                        </div>
                    </div>

                    <div className="accordian">
                        <div className="accordion-header" onClick={() => setPullDataInfoAccordian(!pullDataInfoAccordian)}>
                            {insightsLbls.bitiInfoHeading1}
                            {pullDataInfoAccordian ? <img src={require("../../assets/img/caret-up-medium.png")} alt="" />
                                : <img src={require("../../assets/img/caret-down-medium.png")} alt="" />}
                        </div>
                        <div className="accordion-body" id="accordion-body" style={{ display: pullDataInfoAccordian ? "block" : "none" }}>
                            <ul>
                                <li>{insightsLbls.bitiInfo1_1}</li>
                                <li>{insightsLbls.bitiInfo1_2}</li>
                                <li>{insightsLbls.bitiInfo1_3}</li>
                                <li>{insightsLbls.bitiInfo1_4}</li>
                                <li>{insightsLbls.bitiInfo1_5}</li>
                                <li>{insightsLbls.bitiInfo1_6}</li>
                                <li>{insightsLbls.bitiInfo1_7}</li>
                            </ul>
                            <div className="headingText">{insightsLbls.bitiInfoHeading2}</div>
                            <ul>
                                <li>{insightsLbls.bitiInfo2_1}</li>
                                <li>{insightsLbls.bitiInfo2_2}</li>
                                <li>{insightsLbls.bitiInfo2_3}</li>
                                <li>{insightsLbls.bitiInfo2_4}</li>
                            </ul>
                        </div>
                    </div>

                    {tokenData.isLoading ? <Loader></Loader> : <div className="displayFlexColumn gap-10 generateUrl width-100">
                        <div className="displayFlex gap-15 width-100">
                            {getButtons()}
                        </div>
                        {tokenData.data['authenticated_url'] && tokenData.data['authenticated_url'] != '' && <div className="note text-info">
                            {insightsLbls.bitiInfoNote}
                        </div>}
                    </div>}
                </div>
            </div>
        </div>
    </section>
}

export default BusinessIntelligenceToolsIntegration;