import React, { useEffect, useState } from 'react';


import * as d3 from 'd3';
import { ChartColors } from 'utils/GlobalConstants';
import { useSelector } from "react-redux";
import { useErrorBoundary } from 'react-error-boundary';
const Rating = (props) => {

    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState({})
    let fetched_details = useSelector((state) => state);


    const [tableData, setTableData] = useState({
        showData: false,
        total: 0,
        qResponses: [],
        sum: 0,
        percentageSort: false,
        valueSort: false,
    })

    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if(fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
     
    },[fetched_details.languageData.languageData])

    useEffect(() => {
        try {
            if (props.chartData.length && insightsLbls !== undefined) {
                setTimeout(() => {
                    initChart(props.chartData)
                }, 0);
                initResponseTable(props.tableData);
            }

            setTableData((prevState) => {
                return {
                    ...prevState,
                    showData: props.chartData.length ? true : false
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }, [props.tableData, props.chartData, insightsLbls])


    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const initResponseTable = (questionData) => {
        try {
            let total = 0;
            let qResponses_data = [];
            let percentage = 0;
            let sum_val = 0;
            for (let i = 0; i < questionData.length; i++) {
                total = questionData[i].value + total;
            }

            for (let i = 0; i < questionData.length; i++) {
                percentage = (parseFloat(questionData[i].value) / total) * 100;
                qResponses_data.push({ option: questionData[i].option, value: questionData[i].value, percentage: percentage });
                sum_val = sum_val + questionData[i].option * questionData[i].value;
            }

            sum_val = sum_val / total;

            setTableData((prevState) => {
                return {
                    ...prevState,
                    showTable: true,
                    total: total,
                    qResponses: qResponses_data,
                    sum: sum_val
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const initChart = (questionData) => {
        try {
            if (document.getElementById('ratingData' + props.divIndex) != undefined) {
                document.getElementById('ratingData' + props.divIndex).innerHTML = '';
                // $('#ratingData').empty();
            }
            let x;
            let y;
            let xAxis;
            let yAxis;
            let newIndex;
            let svg;
            let height;
            let width;

            let margin;

            var formatxAxis = d3.format('d');

            if (questionData.length > 0) {

                const containerWidth = 500;
                const data = questionData;
                // margin conventions"
                margin = { top: 20, right: 20, bottom: 20, left: 40 };
                width = containerWidth - margin.left - margin.right;
                height = 400 - margin.top - margin.bottom;

                // D3 scales = just math
                // x is a function that transforms from "domain" (data) into "range" (usual pixels)
                // domain gets set after the data loads
                // D3 Axis - renders a d3 scale in SVG
                // xAxis = d3.svg.axis()
                //     .scale(x)
                //     .orient('bottom');

                // yAxis = d3.svg.axis()
                //     .scale(y)
                //     .orient('left')
                //     .tickFormat(formatxAxis)
                //     .ticks(10);

                x = d3.scaleBand()
                    .rangeRound([0, width]);
                y = d3.scaleLinear()
                    .range([height, 0]);
                xAxis = d3.axisBottom(x);
                yAxis = d3.axisLeft(y).tickFormat(d => Number.isInteger(d)?d:"").ticks(10);

                // create an SVG element (appended to body)
                // set size
                // add a "g" element (think "group")
                // annoying d3 gotcha - the 'svg' variable here is a 'g' element
                // the final line sets the transform on <g>, not on <svg>
                newIndex = props.divIndex;
                svg = d3.select('#ratingData' + props.divIndex).append('svg')
                    .attr('width', width + margin.left + margin.right)
                    .attr('height', height + margin.top + margin.bottom)
                    .style('margin-right', "50")
                    .append('g')
                    .attr('transform', 'translate(' + margin.left + ',5)');

                svg.append('g')
                    .attr('class', 'x axis')
                    .style('transform', 'translate(0px, 360px)')
                    .append('text')
                    .text(insightsLbls['ratings'])
                    .style('transform', 'translate(40%, 30px)');

                svg.append('g')
                    .attr('class', 'y axis')
                    .append('text')
                    .attr('y', 6)
                    .attr('dy', '.71em')
                    .attr("class", "y-axis-title")
                    .text(insightsLbls['count'])
                    .style('transform', 'translate(-9%, 55%) rotate(-90deg)');

                replay(questionData);
            }

            function replay(data) {
                const slices = [];
                for (let i = 0; i < data.length; i++) {
                    slices.push(data.slice(0, i + 1));
                }
                slices.forEach(function (slice, index) {
                    setTimeout(function () {
                        draw(slice, newIndex);
                    }, index * 300);
                });
            }

            function draw(data, index) {
                // var color = ["#bcddf6", "#ffd956", "#9fc42f", "#fda46b","#289b9b","#e67878","#a56ea5","#789c50","#c2bb35","#31a2db"];
                // measure the domain (for x, unique letters) (for y [0,maxFrequency])
                // now the scales are finished and usable
                x.domain(data.map(function (d) { return d['option']; }));
                y.domain([0, d3.max(data, function (d) { return d['value']; })]);

                // // another g element, this time to move the origin to the bottom of the svg element
                // // someSelection.call(thing) is roughly equivalent to thing(someSelection[i])
                // //   for everything in the selection\
                // // the end result is g populated with text and lines!
                svg.select('.x.axis').transition().duration(300).call(xAxis);

                // // same for yAxis but with more transform and a title
                svg.select('.y.axis').transition().duration(300).call(yAxis);

                // // THIS IS THE ACTUAL WORK!
                const bars = svg.selectAll('.bar')
                    .data(data, function (d) { return d['option']; });
                bars.exit()
                    .transition()
                    .duration(300)
                    .attr('y', y(0))
                    .attr('height', height - y(0))
                    .style('fill-opacity', 1e-6)
                    .remove();
                // // data that needs DOM = enter() (a set/selection, not an event!)
                bars.enter().append('rect')
                    .attr('class', 'bar')
                    .attr("x", function (d) { return x(d.option); })
                    .attr("y", function (d) { return y(d.value); })
                    .attr("width", x.bandwidth())
                    .attr("height", function (d) { return height - y(d.value); })
                    // .attr("fill", '#F79B42')
                    // .attr('y', y(0))
                    // .attr("fill", function(d, i) {
                    //   return color[i];
                    // })
                    .attr('fill', 'url(#gradient' + index + ')')
                    // .attr('height', height - y(0))
                    //     .attr('rx', '5')
                    //     .attr('ry', '5')
                    .on('mouseover', function (event, d) {
                        div.transition()
                            .duration(100)
                            .style('opacity', 1);
                        div.html(insightsLbls.count + ': <b>' + d['value'].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '</b>')
                            .style('left', (event['pageX']) + 'px')
                            .style('top', (event['pageY'] - 28) + 'px')
                            .style('position', 'absolute')
                            .style('z-index', '9999')
                            .style('display', 'block')
                            .style('border-color', 'black')
                            .style('color', '#222')
                            .style('padding', '12px')
                            .style('box-sizing', 'border-box')
                            .style('height', '40px')
                            .style('background-color', 'rgba(255, 255, 255, 0.9)')
                            .style('border-radius', '4px')
                            .style('border', '1px solid #666')
                            .style('font', '14px sans-serif')
                            .style('line-height', '1');
                    })
                    .on('mouseout', function (d) {
                        // d3.select(this).attr("fill", "steelblue");
                        div.transition()
                            .duration(500)
                            .style('display', 'none')
                            .style('opacity', 0);
                    });

                const gradient = svg.append('svg:defs')
                    .append('svg:linearGradient')
                    .attr('id', 'gradient' + index)
                    .attr('x1', '0%')
                    .attr('y1', '0%')
                    .attr('x2', '0%')
                    .attr('y2', '100%')
                    .attr('spreadMethod', 'pad');

                gradient.append('svg:stop')
                    .attr('offset', '0%')
                    .attr('stop-color', ChartColors.orangesandybrown)
                    .attr('stop-opacity', 1);

                gradient.append('svg:stop')
                    .attr('offset', '100%')
                    .attr('stop-color', ChartColors.orangesandybrown)
                    .attr('stop-opacity', 1);

                // // the "UPDATE" set:
                bars.transition().duration(300).attr('x', function (d) { return x(d.option); }) // (d) is one item from the data array, x is the scale object from above
                    .attr('width', x.bandwidth()) // constant, so no callback function(d) here
                    .attr('y', function (d) { return y(d.value); })
                    .attr('height', function (d) { return height - y(d.value); }); // flip the height, because y's domain is bottom up, but SVG renders top down
                // // Prep the tooltip bits, initial display is hidden
                const div = d3.select('body').append('div')
                    .attr('class', 'tooltip')
                    .style('opacity', 0);
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const sortData = (key, flag, field) => {
        try {
            const table_data = tableData.qResponses;
            if (tableData[field] === true) {
                table_data.sort((a, b) => (a[key] < b[key]) ? 1 : -1)
                setTableData((prevState) => {
                    return {
                        ...prevState,
                        qResponses: table_data,
                        [field]: !flag,
                    }
                })
            } else if (tableData[field] === false) {
                table_data.sort((a, b) => (a[key] > b[key]) ? 1 : -1)
                setTableData((prevState) => {
                    return {
                        ...prevState,
                        qResponses: table_data,
                        [field]: !flag,
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }

    const avgBox = {
        border: '2px solid grey',
        borderRadius: '4px',
        height: '120px'
    }

    return (
        <>
            <div className="marginTop-60">
                {tableData.showData
                    ? (<>
                        <div className="col-md-12 col-sm-12 col-xs-12 row">
                            <div style={{ float: 'left' }} className="col-md-8 col-sm-8 col-xs-8" id={"ratingData" + props.divIndex}>
                            </div>

                            <div className="col-md-4 col-sm-4 col-xs-4" style={avgBox}>
                                <div style={{ textAlign: 'center', margin: '20px', height: '95px;' }}>
                                    <h5>{insightsLbls.avgRating}</h5>
                                    <h2>{Math.round(tableData.sum)}</h2>
                                </div>
                                <div style={{ marginRight: '15px;' }} ></div>
                            </div>
                        </div>

                        <div className="col-md-12 col-sm-12 col-xs-12 block-grph-table row">
                            <div className="col-md-12 col-sm-12 col-xs-12 block-grph-table row displayInlineBlock chartTables">
                                <h5>{insightsLbls.responseStats}</h5>
                                <table class=" color_table table table-bordered" >
                                    <thead class="" >
                                        <tr>
                                            <th>{insightsLbls.answerChoices}</th>
                                            <th>
                                                {insightsLbls.responsesTitle}
                                                <i class={"marginLeft-5 fa pointer " + `${tableData.percentageSort ? 'fa-caret-up' : 'fa-caret-down'}`}
                                                    onClick={() => sortData('percentage', tableData.percentageSort, 'percentageSort')}></i>
                                            </th>
                                            <th>
                                                {insightsLbls.responseCount}
                                                <i class={"marginLeft-5 fa pointer " + `${tableData.valueSort ? 'fa-caret-up' : 'fa-caret-down'}`}
                                                    onClick={() => sortData('value', tableData.valueSort, 'valueSort')}></i>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tfoot class="">
                                        <tr>
                                            <td><strong>{insightsLbls.total}</strong></td>
                                            <td>&nbsp;</td>
                                            <td><strong>{tableData.total}</strong></td>
                                        </tr>
                                    </tfoot>
                                    <tbody>

                                        {tableData.qResponses.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{data.option}</td>
                                                    <td>{Math.round(data.percentage)}%</td>
                                                    <td>{data.value}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>)
                    : (<div style={{ textAlign: 'center', paddingBottom: '50px', fontSize: '13px', color: 'black', fontWeight: '600' }}>
                        {insightsLbls['noResponseYet']}
                    </div >)
                }

            </div>
        </>
    )
};

export default React.memo(Rating);
