import * as ActionTypes from '../ActionTypes';
import axiosInstance, { getInstance } from '../../../network/apis/interceptor';
import { CommonUtils } from '../../../utils/CommonUtils';
import { ApiRelativePaths, RetryApi } from '../../../utils/GlobalConstants';

let ApiPaths: any = ApiRelativePaths;

export const setControlCenterData = (controlCenterData: any) => {
    return {
        type: ActionTypes.SET_PREFERENCE_DATA,
        controlCenterData: controlCenterData
    }
};


export const getControlCenterData = (params: any, url_path: any, caseType: any, apiErrorFlag: string, firstTimeload?: boolean) => {
    return new Promise((resolve, reject) => {
        let path = ApiPaths[url_path];
        let data:any = {};
        let paramObj = {};
        let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
        getInstance('control-center/');


        axiosInstance.get(generatedURL)
            .then(res => {
                data = {
                    "result": "success",
                    "data": res.data.data,
                    "firstTimeload": firstTimeload ?? false,
                };
            })
            .catch(err => {
                if (apiErrorFlag === '') {
                    data = {
                        "result": "retry",
                        "data": err,
                        "firstTimeload": firstTimeload ?? false,
                    };
                } else {
                    data = {
                        "result": "error",
                        "data": err,
                        "firstTimeload": firstTimeload ?? false,
                    };
                }
            })
            .then(() => {
                const callActionDispatch = () => {
                    switch (caseType) {
                        case ActionTypes.SET_PREFERENCE_DATA:
                            resolve(setControlCenterData(data));
                            break;

                        default:
                            break;
                    }
                }
                

                if (data.result === 'retry') {
                    setTimeout(() => {
                        callActionDispatch();
                    }, RetryApi.TIMEOUT);
                } else {
                    callActionDispatch();
                }

            });
    });
}