/**
 * @author mtiwari
 * Updated on 21 May, 2024
 * Updated key structure while calling Type1 component and added marginTop for export pdf to prevent overlapping of breadcrumb
 */

import React, { useState, useEffect, useRef, useCallback } from "react";
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { toast } from "react-toastify";


// Redux Imports
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AppsState, DatesState, EventSourceState, DashboardsState, LanguageState } from "store/Reducers";
import { getCall, postCall } from "utils/ApiCallActions";
import { setDashboards } from "store/Actions";


import qs from "querystring";
import { CommonUtils } from "../../utils/CommonUtils";
import { ApiRelativePaths, RetryApi } from "../../utils/GlobalConstants";


import SectionHeader from "../../components/Layouts/SectionHeader";
import SectionButtons from "../../components/Layouts/SectionButtons";
import Datepicker from "../../components/Calender/Datepicker";
import ExportToPdf from "../../components/ExportToPdf/ExportToPdf";
import Loader from 'components/Loader/Loader';
import Type1 from "components/Layouts/SectionLayouts/Type1";
import { useErrorBoundary } from 'react-error-boundary';

// SCSS Imports
import "./CustomDashboard.scss";

import SectionFilters from "components/Layouts/SectionFilters";

import { InsightLabels } from "labels";
import { Dropdown, ButtonGroup, Button } from "react-bootstrap";
import UserInfoSection from "components/Layouts/UserInfoSection";

interface CurrentState {
    apps: AppsState,
    dates: DatesState,
    eventSource: EventSourceState,
    dashboards: DashboardsState,
    languageData: LanguageState
}

const CustomDashboard: React.FC = (props: any) => {
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)
    const isInitialMount = useRef(true);

    const { dashboardNumber } = useParams();
    const navigate = useNavigate();
    const location = useLocation();


    const dispatch = useDispatch();

    const componentRef = useRef() as React.RefObject<HTMLDivElement>;

    let fetched_dashboard_details = useSelector((state: CurrentState) => state.dashboards);
    let fetched_details = useSelector((state: CurrentState) => state);



    // const [dashboardState, setDashboardState] = useState<DashboardsState>(fetched_dashboard_details)
    const [dashboardPinnedChartsData, setDashboardPinnedChartsData] = useState({
        dashboardPinnedChartsDataFetched: false,
        dashboardData: [],
        dateChange: false,
        serviceAvailable: true,
        dashLoader: true
    });


    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        window.scrollTo(0, 0);

        let queryParams = {
            app_id: '',
        };

        navigate({
            pathname: `/custom-dashboard/` + dashboardNumber + `/`,
            search: qs.stringify(queryParams),
        })
        setTimeout(() => {
            CommonUtils.LeftPanelHighlight(0, (parseInt(dashboardNumber) - 1), 0, true, false);
        }, 2000);
    }, [])

    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getDashboardData = (from?: string, apiErrorFlag?: string) => {
        try {
            setDashboardPinnedChartsData((prevState: any) => {
                return {
                    ...prevState,
                    dashLoader: true
                }
            })

            setTimeout(() => {
                CommonUtils.LeftPanelHighlight(0, (parseInt(dashboardNumber) - 1), 0, true, false);
            }, 100);

            setDashboardPinnedChartsData((prevState: any) => {
                return {
                    ...prevState,
                    dashboardPinnedChartsDataFetched: false,
                    dateChange: false
                }
            })



            let params = {};
            getCall(params, "CREATE_DASHBOARD", apiErrorFlag ?? '').then((data: any) => {
                let dashDummy: any = [];

                if (data.result === 'success') {
                    if (from === 'load') {
                        const dashboardData: any = data.data;
                        const dummyData: any = [];
                        for (let i = 0; i < dashboardData.length; i++) {
                            dummyData[i] = {
                                ['dashboard' + (i + 1)]: {
                                    active: true,
                                    name: dashboardData[i].profileName,
                                    profileID: dashboardData[i].profileID,
                                    isDefault: dashboardData[i].isDefault,
                                    dashboardIcon: dashboardData[i].dashboardIcon,
                                    profileDetails: dashboardData[i].profileDetails
                                }
                            }
                            // dashboardData[i].profileDetails.length > 0 ? true : false
                            // localStorage.setItem('CUSTOM_DASHBOARD_' + (i + 1), dashboardData[i].profileName);
                            // localStorage.setItem('CUSTOM_DASHBOARD_' + (i + 1) + '_flag', 'true');
                        }
                        dispatch(setDashboards(dummyData))
                    }

                    if (data.result === "success" && data.data !== undefined) {
                        dashDummy.profileID = data.data[(parseInt(dashboardNumber) - 1)].profileID;
                        dashDummy.isDefault = data.data[(parseInt(dashboardNumber) - 1)].isDefault;
                        populateCharts(data.data[(parseInt(dashboardNumber) - 1)].profileDetails, dashDummy.profileID);
                    }

                    setDashboardPinnedChartsData((prevState: any) => {
                        return {
                            ...prevState,
                            dashLoader: false
                        }
                    })
                } else if (data.result === 'retry') {
                    setTimeout(() => {
                        getDashboardData('', 'retry')
                    }, RetryApi.TIMEOUT)
                }
            });
        } catch (error) {
            showBoundary(error)
        }

    }

    const moveByDirection = (direction: string, chartIndex: number) => {
        try {
            setDashboardPinnedChartsData((prevState: any) => {
                return {
                    ...prevState,
                    dashboardPinnedChartsDataFetched: false
                }
            })

            let dummyData = fetched_dashboard_details.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]['profileDetails'];
            if (direction === 'up') {
                let i_val = 0;
                let j_val = 0;

                for (let i = 0; i < dummyData.length; i++) {
                    if (dummyData[i].chartIndex === chartIndex) {
                        i_val = i
                    }

                    if (dummyData[i].chartIndex === (chartIndex - 1)) {
                        j_val = i
                    }

                }

                dummyData[i_val].chartIndex = chartIndex - 1;
                dummyData[j_val].chartIndex = chartIndex;
            }

            if (direction === 'down') {
                let i_val = 0;
                let j_val = 0;

                for (let i = 0; i < dummyData.length; i++) {
                    if (dummyData[i].chartIndex === chartIndex) {
                        i_val = i
                    }

                    if (dummyData[i].chartIndex === (chartIndex + 1)) {
                        j_val = i
                    }

                }

                dummyData[i_val].chartIndex = chartIndex + 1;
                dummyData[j_val].chartIndex = chartIndex;
            }

            const data = {
                profileID: fetched_dashboard_details?.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]['profileID'],
                profileName: fetched_dashboard_details?.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]['name'],
                isDefault: fetched_dashboard_details?.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]['isDefault'],
                dashboardIcon: fetched_dashboard_details?.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]['dashboardIcon'],
                dashboardNumber: 1,
                isDashboard: false,
                profileDetails: dummyData
            };


            let path = ApiRelativePaths.CREATE_DASHBOARD;
            let params = {};
            let paramObj = {};
            let a = CommonUtils.URLGenerator(params, path, paramObj);

            postCall(data, "CREATE_DASHBOARD").then((data: any) => {
                if (data.result === "success") {
                    let dashDummy_data: any = [];
                    for (let i = 0; i < data.data.length; i++) {
                        dashDummy_data.push({
                            ['dashboard' + (i + 1)]: {
                                active: true,
                                name: data.data[i].profileName,
                                profileID: data.data[i].profileID,
                                isDefault: data.data[i].isDefault,
                                dashboardIcon: data.data[i].dashboardIcon,
                                profileDetails: data.data[i].profileDetails
                            }
                        })
                    }

                    // setDashboardState(dashDummy_data);
                    dispatch(setDashboards(dashDummy_data));

                    toast.success(insightsLbls['successfullyUpdated'], {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    if (!window.location.href.includes('isMaintenance')) {
                        getDashboardData();

                    }
                }
            });
        } catch (error) {
            showBoundary(error)
        }
    }


    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const setAttributes = (sectionType: string) => {
        try {
            let params = {
                width1: '',
                width2: '',
                width3: '',
                sections: 1
            }

            switch (sectionType) {
                case 'type1':
                    params = { ...params, width1: 'col-sm-12 col-md-12 col-lg-12', sections: 1 }
                    break;

                case 'type2':
                    params = { ...params, width1: 'col-sm-6 col-md-6 col-lg-6  marginRight-10 outerDiv', width2: 'col-sm-6 col-md-6 col-lg-6 marginLeft-10 outerDiv', sections: 2 }
                    break;

                case 'type3':
                    params = { ...params, width1: 'col-sm-4 col-md-4 col-lg-4 marginRight-10 outerDiv', width2: 'col-sm-4 col-md-4 col-lg-4  outerDiv', width3: 'col-sm-4 col-md-4 col-lg-4 marginLeft-10 outerDiv', sections: 3 }
                    break;
            }
            return params;
        } catch (error) {
            showBoundary(error)
        }
    }

    const populateCharts = (chartData: any, profileID: string) => {
        try {
            let dummyData: any = [];

            for (let i = 0; i < chartData.length; i++) {
                dummyData[i] = [];
                dummyData[i].chartData = chartData[i];
                dummyData[i].sectionType = chartData[i].sectionType;
                dummyData[i].profileID = profileID;
            }

            setDashboardPinnedChartsData((prevState: any) => {
                return {
                    ...prevState,
                    dashboardData: dummyData,
                    dashboardPinnedChartsDataFetched: true,
                    dateChange: true
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    }

    const handleApiCalls = (serviceAvailable: boolean) => {
        try {
            setDashboardPinnedChartsData((prevData: any) => {
                return {
                    ...prevData,
                    serviceAvailable: (dashboardPinnedChartsData.serviceAvailable && serviceAvailable)
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    // fetched_date_details.fdate
    const getDashboardName = useCallback(() => {
        try {
            if (fetched_dashboard_details) {
                return fetched_dashboard_details.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]['name'];
            }
                
            else
                return "";
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_dashboard_details])


    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // On props change call dashboard api
    useEffect(() => {
        try {
            if (isInitialMount.current) {
                let title = fetched_dashboard_details?.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]['name'];
                let doc_title = (title !== null && title !== '' && title.length > 0) ? title : ('Dashboard-' + `${dashboardNumber}`);
                document.title = doc_title;
                getDashboardData('load');
                isInitialMount.current = false

                setTimeout(() => {
                    isInitialMount.current = true
                }, 10);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [props.params, dashboardNumber]);


    return (
        <section className="demo guideAnalytics width100" ref={componentRef}>
            {/* Breadcrumb */}
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <SectionHeader>
                        <li className="active">{fetched_dashboard_details ? fetched_dashboard_details?.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]["name"] : ""}</li>
                    </SectionHeader>
                </div>

                <SectionFilters>
                    {/* <Datepicker source={'component'}/> */}
                </SectionFilters>
            </div>

            {/* <SectionButtons
                sectionTitle={getDashboardName}
                svgImage={"customDashboard"}
                type={{
                    component: "customDashboard",
                    option: +`${dashboardNumber}`,
                    iconNumber: fetched_dashboard_details ? fetched_dashboard_details?.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]["dashboardIcon"] : ""
                }}
                className={'marginTop-40'}>
                <ExportToPdf
                    componentRef={componentRef}
                    source={fetched_dashboard_details.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]['name'] + " " + `${new Date()}`}
                    widthDividedBy={11}
                    disabled={!(dashboardPinnedChartsData.serviceAvailable)}
                ></ExportToPdf>
            </SectionButtons> */}

            <div className="marginTop-60 exportBtn-div">
                <Dropdown as={ButtonGroup}>
                    <Button variant="info">{insightsLbls.export}</Button>
                    <Dropdown.Toggle split variant="success" id="dropdown-custom-2" />
                    <Dropdown.Menu className="super-colors">
                        <Dropdown.Item eventKey="1" >
                            <ExportToPdf
                                componentRef={componentRef}
                                source={fetched_dashboard_details.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]['name'] + " " + `${new Date()}`}
                                widthDividedBy={12}
                                disabled={!(dashboardPinnedChartsData.serviceAvailable)}
                            ></ExportToPdf>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <UserInfoSection
                startDate={''}
                endDate={''}
                dateObject={''}
                messageText={fetched_dashboard_details ? fetched_dashboard_details?.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]["name"] : ""}
                >
            </UserInfoSection>

            {
                dashboardPinnedChartsData.dashLoader ?
                    (
                        <div className="displayFlex alignCenter margin-0-auto width100">
                            <Loader></Loader>
                        </div>
                    )
                    :
                    (
                        fetched_dashboard_details.data[(parseInt(dashboardNumber) - 1)]["dashboard" + `${dashboardNumber}`]['profileDetails'].length !== 0 ?
                            (
                                <>
                                    {
                                        dashboardPinnedChartsData.dashboardPinnedChartsDataFetched && dashboardPinnedChartsData.dashboardData
                                            .sort(function (a, b) { return a['chartData']['chartIndex'] - b['chartData']['chartIndex'] })
                                            .map(
                                                (item: any, key: number) => {
                                                    return (
                                                        <Type1
                                                            data={item.chartData}
                                                            profileID={item.profileID}
                                                            keyVal={key}
                                                            type={item.sectionType}
                                                            width1Class={setAttributes(item.sectionType)?.width1}
                                                            width2Class={setAttributes(item.sectionType)?.width2}
                                                            width3Class={setAttributes(item.sectionType)?.width3}
                                                            sections={setAttributes(item.sectionType)?.sections}
                                                            date={dashboardPinnedChartsData.dateChange}
                                                            dispatchDelete={() => getDashboardData()}
                                                            dispatchDirection={moveByDirection}
                                                            lastElem={dashboardPinnedChartsData.dashboardData.length === (key + 1) ? true : false}
                                                            noError={handleApiCalls}
                                                            guide_filter={item.chartData.guide_filter}
                                                            event_filter={item.chartData.event_filter}
                                                            tooltip_filter={item.chartData.tooltip_filter}
                                                            key={item.profileID + key}
                                                        >
                                                        </Type1>
                                                    );
                                                }
                                            )
                                    }
                                </>
                            )
                            :
                            (
                                <div className="noChartsAdded">
                                    {insightsLbls['addChartinCustomDash']}
                                </div>
                            )
                    )
            }
            <div className="overlayCursor" id="overlayCursor"></div>
        </section>

    )
}

export default withRouter(CustomDashboard);


