// @author: Mrunal Mane
// Created on: 2023-05-15
// Description: Removed language dependany to fix multiple app list api calls.

import React from "react";
import { useState, useEffect, useRef, useCallback } from "react";
import { Modal } from "react-bootstrap";
import moment from 'moment';
import withRouter from "hoc/withRouter";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useLocation, useNavigate } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getCall, getAssimaURLCall } from "utils/ApiCallActions";
import { setAppDetails, setDomain, setHandOffDetails } from "store/Actions";
import { AppsState, DomainState, OrgState, HandoffState, LanguageState } from "store/Reducers";



// Common 

import { AppDomains, RetryApi, DateSelection } from 'utils/GlobalConstants';
import axios from "axios";
import $ from "jquery";
import { useErrorBoundary } from 'react-error-boundary';

// Components
import Loader from "../Loader/Loader";

//scss
import "./AppSwitcher.scss";

import { InsightLabels } from "labels";
import Icon from "components/Icon/Icon";
import { handleCancelRequest } from "network/apis/interceptor";

let searchTxt: any = '';

interface CurrentState {
    apps: AppsState,
    domain: DomainState,
    org: OrgState,
    handoff: HandoffState,
    languageData: LanguageState
}

export const AppSwitcher = (props: any) => {

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)

    const { showBoundary } = useErrorBoundary();

    const location = useLocation();
    const navigate = useNavigate();

    let urlData: any = {};
    const environment = process.env.REACT_APP_ENV;
    const dispatch = useDispatch();
    let cdnSig = localStorage.getItem('USER_SIGN');
    const redirectRef = useRef(false);

    let fetched_details = useSelector((state: CurrentState) => state);

    var selectedAppCode = fetched_details?.apps?.appCode;
    var selectedAppId = fetched_details?.apps?.appId;
    var selectedAppStartDate = fetched_details?.apps?.appStartDate;
    var selectedAppEndDate = fetched_details?.apps?.appEndDate;
    var selectedAppName = fetched_details?.apps?.appName;


    const [appRedirectInfo, setAppRedirectInfo] = useState({
        flag: false,
        data: {
            switchAppCode: '',
            switchAppId: '',
            selectedAppName: '',
            selectedOptn: '',
            min_date: '',
            max_date: '',
            orgName: '',
            orgImgUrl: '',
            orgId: '',
            app_guide_automation_enabled: false
        }
    })

    const [appSwitcher, setValue] = useState({
        selectedAppCode: selectedAppCode,
        selectedAppId: selectedAppId,
        selectedAppStartDate: selectedAppStartDate,
        selectedAppEndDate: selectedAppEndDate,
        selectedAppName: selectedAppName,
        show: false,
        hide_deleted: 0,
        searchTxt: '',
        app_list: {
            apps: [],
            total_count: 0
        },
        appLoader: true,
        selectedAppImg: ''
    });

    const [listLoadFlag, setListLoadFlag] = useState(false)

    let orgDetails = useSelector((state: CurrentState) => state.org);



    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    const useSkipFirstRender = (fn: any, args: any) => {
        const isMounted = useRef(false);

        useEffect(() => {
            if (isMounted.current) {
                return fn();
            }
        }, args)

        useEffect(() => {
            isMounted.current = true;
            getAppList(2);
        }, [])
    }

    useEffect(() => {
        try {
            if (fetched_details.handoff.setHandoff) {
                let urlData: any = {};
                const query = new URLSearchParams(location.search);
                query.forEach((v, k) => {
                    urlData[k] = v;
                });

                getAppList(3);

            }
        } catch (error) {
            showBoundary(error)
        }
    }, [fetched_details.handoff])

    useEffect(() => {
        try {
            if (orgDetails.orgSwitch) {
                setValue((prevState: any) => {
                    return {
                        ...prevState,
                        appLoader: false
                    }
                })
                getAppList('org_switch')
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [orgDetails])

    useEffect(() => {
        try {
            const query = new URLSearchParams(location.search);
            query.forEach((v, k) => {
                urlData[k] = v;
            });

            if (urlData['redirectInfo']) {
                setAppRedirectInfo((prevState: any) => {
                    return {
                        ...prevState,
                        flag: true,
                        data: JSON.parse(atob(urlData['redirectInfo']))
                    }
                })
            }

            dispatch(setHandOffDetails({ setHandoff: false }));

        } catch (error) {
            showBoundary(error)
        }
    }, [])


    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getAppList = (optn?: string | number, apiErrorFlag?: string) => {
        try {
            let cancelToken: any;
            setListLoadFlag(false);
            if (optn) {
                if (optn === 'fromBtn') {
                    searchTxt = '';
                }
            }

            $('.createPage-btn-square').prop('disabled', true);


            let params = {
                "type": "org",
                "sort_by": "app_id",
                "order": "desc",
                "search_text": encodeURIComponent(searchTxt),
                "page_index": 1,
                "page_size": 99999,
                "hide_deleted": appSwitcher.hide_deleted
            };

            if (typeof cancelToken != typeof undefined) {
                cancelToken.cancel("Operation canceled due to new request.")
            }

            //Save the cancel token for the current request
            cancelToken = axios.CancelToken.source()


            getCall(params, 'APP_LIST', apiErrorFlag ?? '', '').then((data: any) => {
                if (data.result === 'success') {


                    if (data.data.apps.length) {
                        let data_list: any = [];
                        data_list = data.data.apps;
                        let app_code = localStorage.getItem("switch_app_code");

                        let maxDateArr = [];
                        let minDateArr = [];

                        for (let i = 0; i < data_list.length; i++) {
                            maxDateArr[i] = data_list[i].data_end_time;
                            minDateArr[i] = data_list[i].data_start_time;
                        }

                        let momentsMax = maxDateArr.map(d => moment(d)),
                            maxDate = moment.max(momentsMax);

                        let momentsMin = minDateArr.map(d => moment(d)),
                            minDate = moment.min(momentsMin);

                        localStorage.setItem('dashboard_min_date', minDate.format("YYYY-MM-DD HH:mm:ss"));
                        localStorage.setItem('dashboard_max_date', maxDate.format("YYYY-MM-DD HH:mm:ss"));

                        for (var i = 0; i < data_list.length; i++) {
                            if (app_code === data_list[i].app_code) {
                                AppDomains.domains = data_list[i].domains;
                                if (selectedAppEndDate === null || selectedAppStartDate === null) {
                                    switchApp(data_list[i], true, 'fromCall');
                                }
                                break;
                            }
                        }

                        if (optn === 'org_switch') {
                            localStorage.removeItem('firstApp_name')
                            localStorage.removeItem('switch_app_code')
                        }

                        if (fetched_details.handoff.setHandoff && urlData["app_id"]) {
                            for (let itr0 = 0; itr0 < data_list.length; itr0++) {
                                if (app_code === data_list[itr0].app_code) {
                                    AppDomains.domains = data_list[itr0].domains;

                                    switchApp(data_list[itr0], true, 'fromCall');
                                    break;
                                }
                            }
                        } else {
                            const query = new URLSearchParams(location.search);
                            query.forEach((v, k) => {
                                urlData[k] = v;
                            });

                            let validMatch_flag = 0;

                            if (urlData['app_id']) {
                                for (let itr0 = 0; itr0 < data_list.length; itr0++) {
                                    if (urlData['app_id'] == data_list[itr0].app_id) {
                                        setInitialApp(
                                            data_list[itr0].app_code,
                                            data_list[itr0].app_id,
                                            data_list[itr0].app_name,
                                            data_list[itr0].data_start_time,
                                            data_list[itr0].data_end_time,
                                            data_list[itr0].is_archived,
                                            minDate.format("YYYY-MM-DD HH:mm:ss"),
                                            maxDate.format("YYYY-MM-DD HH:mm:ss"),
                                            data_list[itr0].app_icon_image_url,
                                            data_list[itr0].app_guide_automation_enabled,
                                            optn,
                                            data_list[itr0].is_page_tracking_enabled,
                                            data_list[itr0].is_feature_tracking_enabled,
                                        )
                                        validMatch_flag = 1;
                                        break;
                                    }
                                }
                            }


                            // validMatch_flag === 0 && (localStorage.getItem('firstApp_name') === '' ||localStorage.getItem('firstApp_name') === null)
                            if (validMatch_flag === 0) {

                                setInitialApp(
                                    data_list[0].app_code,
                                    data_list[0].app_id,
                                    data_list[0].app_name,
                                    data_list[0].data_start_time,
                                    data_list[0].data_end_time,
                                    data_list[0].is_archived,
                                    minDate.format("YYYY-MM-DD HH:mm:ss"),
                                    maxDate.format("YYYY-MM-DD HH:mm:ss"),
                                    data_list[0].app_icon_image_url,
                                    data_list[0].app_guide_automation_enabled,
                                    optn,
                                    data_list[0].is_page_tracking_enabled,
                                    data_list[0].is_feature_tracking_enabled
                                )
                            }
                        }

                        var domainData: object = {
                            domainSet: true,
                            allAppsData: data.data.apps
                        }
                        dispatch(setDomain(domainData));
                        $('.createPage-btn-square').prop('disabled', false);
                    }

                    setValue((prevState: any) => {
                        return {
                            ...prevState,
                            app_list: data.data,
                            appLoader: false,
                        }
                    });
                } else if (data.result === 'retry') {
                    // setTimeout(() => {
                    //     getAppList('retry')
                    // }, RetryApi.TIMEOUT)
                } else {

                }
            });
        } catch (error) {
            showBoundary(error)
        }

    }


    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const switchApp = (appDetail: any, setDomain: boolean, optn?: string) => {
        try {
            // if (fetched_details?.apps?.appEndDate !== null && fetched_details?.apps?.appStartDate !== null) {

            localStorage.setItem("switch_app_code", appDetail.app_code);
            localStorage.setItem("switch_app_id", appDetail.app_id);
            localStorage.setItem("min_date", appDetail.data_start_time);
            localStorage.setItem("max_date", appDetail.data_end_time);
            localStorage.setItem("firstApp_name", appDetail.app_name);
            localStorage.setItem("app_img", appDetail.app_icon_image_url);
            localStorage.setItem("app_guide_automation_enabled",
                appDetail.app_guide_automation_enabled != undefined ? appDetail.app_guide_automation_enabled.toString() : "");

            var appData: object = {
                appCode: appDetail.app_code,
                appId: appDetail.app_id,
                appStartDate: appDetail.data_start_time,
                appEndDate: appDetail.data_end_time,
                appName: appDetail.app_name,
                appImg: appDetail.app_icon_image_url,
                dashboardMaxDate: localStorage.getItem('dashboard_max_date'),
                dashboardMinDate: localStorage.getItem('dashboard_min_date'),
                appGuideAutomationEnabled: appDetail.app_guide_automation_enabled.toString(),
                pageTrackingEnabled: appDetail.is_page_tracking_enabled,
                featureTrackingEnabled: appDetail.is_feature_tracking_enabled
            }

            dispatch(setAppDetails(appData));

            if (!setDomain)
                AppDomains.domains = appDetail.domains

            dispatch(setHandOffDetails({ setHandoff: false }));

            setValue((prevState: any) => {
                return {
                    ...prevState,
                    show: false,
                    selectedAppCode: appDetail.app_code,
                    selectedAppId: appDetail.app_id,
                    selectedAppStartDate: appDetail.data_start_time,
                    selectedAppEndDate: appDetail.data_end_time,
                    selectedAppName: appDetail.app_name,
                    selectedAppImg: appDetail.app_icon_image_url
                }
            });
            // }
        } catch (error) {
            showBoundary(error)
        }
    }

    useSkipFirstRender(() => {
        try {
            setValue((prevState: any) => {
                return {
                    ...prevState,
                    appLoader: true,
                }
            });

            getAppList(6);
        } catch (error) {
            showBoundary(error)
        }
    }, [appSwitcher.hide_deleted])

    const openModal = () => {
        try {
            setValue((prevState: any) => {
                return {
                    ...prevState,
                    show: true
                }
            });

            var domainData: object = {
                domainSet: false
            }

            dispatch(setDomain(domainData));
        } catch (error) {
            showBoundary(error)
        }
    }

    const searchInAppList = (e: any) => {
        try {
            // handleCancelRequest();

            searchTxt = e.target.value;
            setValue((prevState: any) => {
                return {
                    ...prevState,
                    appLoader: true
                }
            });
            getAppList('search');

        } catch (error) {
            showBoundary(error)
        }

    }

    const urlError = (e: any) => {
        e.target.src = require("../../assets/images/organization_42x42.png");
    }

    function hideDeleted(event: any) {
        try {
            let hideDeleted: any = 0;
            if (event.target.checked === true) {
                hideDeleted = 1;
                //   this.getAppList(false, true);
            } else {
                hideDeleted = 0;
                //   this.getAppList(false, true);
            }

            setValue((prevState: any) => {
                return {
                    ...prevState,
                    hide_deleted: hideDeleted
                }
            });
        } catch (error) {
            showBoundary(error)
        }

    }

    const setInitialApp = useCallback((
        app_code: string,
        app_id: string,
        app_name: string,
        appStartDate: string,
        appEndDate: string,
        app_archived: string,
        dash_startDate: string,
        dash_endDate: string,
        app_image: string,
        app_guide_automation_enabled: boolean,
        optn: any,
        tracking_enabled: number,
        feature_tracking_enabled: number) => {
        try {
            if (appRedirectInfo.flag) {
                localStorage.setItem('switch_app_code', appRedirectInfo.data['switchAppCode']);
                localStorage.setItem('switch_app_id', appRedirectInfo.data['switchAppId']);
                localStorage.setItem('firstApp_name', appRedirectInfo.data['selectedAppName']);
                localStorage.setItem('SELECTED_OPTN', appRedirectInfo.data['selectedOptn']);
                localStorage.setItem('min_date', appRedirectInfo.data['min_date']);
                localStorage.setItem('max_date', appRedirectInfo.data['max_date']);
                localStorage.setItem('ORG_NAME', appRedirectInfo.data['orgName']);
                localStorage.setItem('ORG_IMG_URL', appRedirectInfo.data['orgImgUrl']);
                localStorage.setItem('ORG_ID', appRedirectInfo.data['orgId']);
                localStorage.setItem('app_guide_automation_enabled', appRedirectInfo.data['app_guide_automation_enabled'] != undefined ? appRedirectInfo.data['app_guide_automation_enabled'].toString() : "");
                localStorage.setItem('firstApp_name_dashboard', insightsLbls.allApps);
            } else {
                if (optn !== 'search' && optn !== 'fromBtn') {
                    // if (localStorage.getItem('firstApp_name') == null && localStorage.getItem('switch_app_code') == null) {
                    const startDate = appStartDate.split(' ')[0];
                    const endDate = moment(appEndDate.split(' ')[0]).subtract(0, 'd').format('YYYY-MM-DD');

                    localStorage.setItem('SELECTED_OPTN', DateSelection.defaultSelection.toString());
                    const tdate = moment(endDate).format('YYYY-MM-DD');
                    const fdate = moment(endDate).subtract(DateSelection.defaultDays - 1, 'd').format('YYYY-MM-DD');

                    localStorage.setItem('min_date', startDate);
                    localStorage.setItem('max_date', endDate);
                    localStorage.setItem('FDATE', fdate);
                    localStorage.setItem('TDATE', tdate);
                    localStorage.setItem('firstApp_name', app_name);
                    localStorage.setItem('switch_app_code', app_code);
                    localStorage.setItem('app_img', app_image);
                    localStorage.setItem('switch_app_id', app_id);
                    localStorage.setItem('dashboard_min_date', dash_startDate);
                    localStorage.setItem('dashboard_max_date', dash_endDate);
                    localStorage.setItem('switch_app_code_dashboard', '');
                    localStorage.setItem('firstApp_name_dashboard', insightsLbls.allApps);
                    localStorage.setItem('app_guide_automation_enabled', app_guide_automation_enabled.toString());
                    localStorage.setItem("REAL_TIME_CHECK", localStorage.getItem('REAL_TIME_CHECK') ?? 'false');

                    const appData: object = {
                        appCode: app_code,
                        appId: app_id,
                        appStartDate: startDate,
                        appEndDate: endDate,
                        appName: app_name,
                        appImg: app_image,
                        dashboardMaxDate: dash_endDate,
                        dashboardMinDate: dash_startDate,
                        appGuideAutomationEnabled: app_guide_automation_enabled.toString(),
                        pageTrackingEnabled: tracking_enabled,
                        featureTrackingEnabled: feature_tracking_enabled
                    }

                    setValue((prevState: any) => {
                        return {
                            ...prevState,
                            selectedAppCode: app_code,
                            selectedAppId: app_id,
                            selectedAppStartDate: startDate,
                            selectedAppEndDate: endDate,
                            selectedAppName: app_name,
                        }
                    })
                    dispatch(setAppDetails(appData));
                }
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [appRedirectInfo])


    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        try {
            setListLoadFlag(appSwitcher.app_list.total_count ? true : false);
        } catch (error) {
            showBoundary(error)
        }
    }, [appSwitcher.app_list])

    useEffect(() => {
        try {
            if (appSwitcher.app_list.total_count && listLoadFlag) {
                let dummyData1: any = appSwitcher.app_list;
                for (let i = 0; i < dummyData1.total_count; i++) {
                    let logo = dummyData1.apps[i].app_icon_image_url;

                    dummyData1[i] = appSwitcher.app_list.apps[i];
                    dummyData1[i].appDummyIcon = logo;
                }

                setValue((prevState: any) => {
                    return {
                        ...prevState,
                        app_list: dummyData1,
                    }
                });
            }

        } catch (error) {
            showBoundary(error)
        }
    }, [listLoadFlag])




    const query = new URLSearchParams(location.search);
    query.forEach((v, k) => {
        urlData[k] = v;
    });

    //To check if redirect flag is avilable when location pathname is "/survey/survey-details/".
    //This flag will only appear once, when survey is clicked from CIO dashboard & will disappear after that.
    //It is added, so that during re-directing, corresponding app of survey should be set in store & in page.                        
    useEffect(() => {
        if (+urlData['redirect'] === 1) {
            getAppList(1);
            // redirectRef.current = true;
        }
    }, [urlData])

    const getMargin = (type: string) => {
        if (location.pathname.includes('guide-analytics-detail/') ||
            location.pathname.includes('tutorial-guides-detail/') ||
            location.pathname.includes('push-notifications-beacons/') ||
            location.pathname.includes('tooltips-detail/') ||
            location.pathname.includes('guide-automation/') ||
            location.pathname.includes('feature-detail/')|| 
            location.pathname.includes('survey-details/')) {
            if (type == 'filter') {
                return '182px';
            } else {
                return '287px';
            }
        } else if (location.pathname.includes('guide-errors-detail/') ||
            location.pathname.includes('task-list/') ||
            location.pathname.includes('task-list-detail/') ||
            location.pathname.includes('app-insights/') ||
            location.pathname.includes('survey/survey/') ||
            location.pathname.includes('chatbot/chatbot/') ||
            location.pathname.includes('chatbot-details/') || 
            location.pathname.includes('automation-detail/')){

            if (type == 'filter') {
                return '-3px';
            } else {
                return '93px';
            }
        } else if (location.pathname.includes('guide-errors/') ||
            location.pathname.includes('user-engagement/') ||
            location.pathname.includes('user-engagement-detail/') ||
            location.pathname.includes('search-activities/')) {
            if (type === 'filter') {
                return '366px';
            } else {
                return '471px';
            }
        } else if (location.pathname.includes('manage/') ||
            location.pathname.includes('manage-survey/') || location.pathname.includes('goal/') ) {
            if (type == 'filter') {
                return '-193px';
            } else {
                return '-88px';
            }
        }
        else {

            if (type === 'filter') {
                return '736px'
            } else {
                return '841px';
            }
        }
    }

    return (
        <>
            {!(
                location.pathname.includes('control-center') ||
                location.pathname.includes('export-list') ||
                location.pathname.includes('business-intelligence-tools-integrations')) && (
                    <div className="appSwitcher-div">
                        {/* <h1>Application</h1> */}
                        <div className="appSwitcher-label" style={{ right: `${getMargin('label')}` }}>Application</div>
                        <button id="switchApp_btn"
                            className="switchApp-btn btn-lg btn-outline-primary"
                            onClick={() => { openModal(); getAppList('fromBtn') }}
                            data-test-id="switchApp-btn"
                            style={{ right: `${getMargin('filter')}` }}
                            disabled={location?.pathname.includes('detail') || location?.pathname.includes('export-list') || location?.pathname.includes('dashboard') || location?.pathname.includes('organization') || location?.pathname.includes('control-center') || 
                            window.location.href.includes('chatbot_form=1') || window.location.href.includes('survey_form=1') || window.location.href.includes('goal_form=1') }>
                            {/* <Icon img={"img/apps_ic_old.svg"} className="app_icon switchApp-btn-icons" /> */}
                            {appSwitcher.selectedAppName}
                        </button>
                        <Modal onHide={() => setValue((prevState: any) => {
                            return {
                                ...prevState,
                                show: false
                            }
                        })} show={appSwitcher.show} size="lg" centered >
                            <Modal.Header>
                                <Modal.Title>{insightsLbls.appList}</Modal.Title>
                                <button type="button" className="close" onClick={() => { setValue({ ...appSwitcher, show: false }); }} >
                                    <span aria-hidden="true">×</span>
                                    <span className="sr-only">{insightsLbls.close}</span>
                                </button>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="col-lg-12 paddingLR-35 searchBox">
                                    <span className="font13 appStatus-point bg-green"></span>
                                    <span className="font13 marginRight-10">{insightsLbls.active}</span>
                                    <div className="displayInlineBlock" title={insightsLbls.deletedApp}>
                                        <span className="font13 appStatus-point bg-red"></span>
                                        <span className="font13 marginRight-10">{insightsLbls.deleted}</span>
                                    </div>
                                    <p className="pull-right search-field-wrapper">
                                        <input type="text" className="searchIcon form-control round-input" placeholder={insightsLbls.searchApp} autoComplete="off" onInput={(event) => searchInAppList(event)} />
                                    </p>
                                    <div className="appSwitcher custom-control custom-checkbox mr-sm-2 check-container padding-chk-0 pull-right marginTop-5 font14">
                                        <label className="check-container displayFlex alignCenter exportCheck marginBottom-0 marginLeft--5">
                                            <span className="marginLeft-10">{insightsLbls.hideDeleted}</span>
                                            <input type="checkbox" className="custom-control-input chk checkbox" checked={appSwitcher.hide_deleted === 1}
                                                id="app_deleted_check" name="chk-box" value="delete" onChange={hideDeleted} />
                                            <span className="checkmark checkmarkBox-color displayInlineBlock top0"></span>
                                        </label>
                                    </div>
                                </div>
                                {/* <div *ngIf="dataFetched === false;then appPageLoader else app_list_content"></div> */}
                                {appSwitcher.appLoader ?
                                    (<div className="col-lg-12 appList-container paddingLR-0" id="appList_container_val">
                                        <Loader></Loader>
                                    </div>)
                                    : (<div className="col-lg-12 appList-container paddingLR-0" id="appList_container_val">
                                        {appSwitcher.app_list.apps.length === 0 ? (
                                            <div className="appList-div 1">
                                                <div className="noData-div ">{insightsLbls.noRecordFound}</div>
                                            </div>
                                        ) : (
                                            <div className="appList-div">
                                                {appSwitcher.app_list.apps.map(function (app: any) {

                                                    if ((app?.app_icon_image_url?.includes('http:') || app?.app_icon_image_url?.includes('https:'))) {
                                                        var imageTag = <img className="app-bgimg" src={app?.app_icon_image_url + cdnSig} onError={() => urlError.bind(app)} />
                                                    } else {
                                                        var imageTag = <img src={require("../../assets/images/organization_42x42.png")} className="app-img" />
                                                    }

                                                    return (
                                                        <div className="appSwitcher col-md-6 displayInlineBlock appList" key={app.app_id}>
                                                            <div className="appCard width100 positionRelative">
                                                                <div className={"appContainer row nomargin " + (app.app_code === appSwitcher.selectedAppCode ? "highlight" : "")}
                                                                    data-tip data-for={"txtTooltip_" + app.app_code} data-class="reactTooltipStyle" >
                                                                    <div className="app-data pointer" onClick={() => switchApp(app, false, 'fromBtn')}>
                                                                        <div className="tri-container">
                                                                            <div className="cover">
                                                                                <div className={"arrow-left "
                                                                                    + (app.is_app_verified === true && app.is_archived === false ? "bg-green " : "")
                                                                                    + (app.is_tracking_enabled === false && app.is_archived === false ? "bg-yellow " : "")
                                                                                    + (app.is_archived === true ? "bg-red" : "")}>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="app-img-div">
                                                                            {imageTag}
                                                                        </div>
                                                                        <div className="app-content-div">
                                                                            <p className="nomargin font14 fontW600 textWrap"></p>
                                                                            <div className="height30">
                                                                                <p className="width100 font13 appDesc nomargin textWrap height30 font600">
                                                                                    <span className="ellipsis">{app.app_name}</span>
                                                                                </p>
                                                                                <p className="width100 font13 appDesc nomargin textWrap height30">
                                                                                    <span className="ellipsis">{app.app_description}</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="app-actions-div">
                                                                        <div className="app-card"></div>
                                                                    </div>

                                                                    {(app.app_description.length === 0 && app.app_name.length === 0)
                                                                        ? (<ReactTooltip id={"txtTooltip_" + app.app_code} place="bottom">{"No data available."}</ReactTooltip>)
                                                                        : null
                                                                    }

                                                                    {(app.app_description.length > 0 || app.app_name.length > 0)
                                                                        ? (<ReactTooltip id={"txtTooltip_" + app.app_code} place="bottom">
                                                                            {app.app_name.length > 0 && <p className="font600">{app.app_name}</p>}
                                                                            {app.app_description.length > 0 && <p>{app.app_description}</p>}
                                                                        </ReactTooltip>)
                                                                        : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                    )}
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="col-lg-12">
                                    <span className="font13 floatLeft">{insightsLbls.totalApps}: &nbsp;
                                        <b>{appSwitcher.app_list.apps.length}</b>
                                    </span>
                                </div>
                            </Modal.Footer>
                        </Modal>
                    </div>
                )}

            {/* <div
                className="selected_app_div borderStyle"
            >
                <a
                    className="analyticsLogo_div dropdown-toggle userData_div paddingTop-4"
                >
                    <div className="appLogo">
                        <img
                            src={appSwitcher.selectedAppImg + localStorage.getItem('USER_SIGN')}
                            alt="Edcast"
                            className="myIntel_logo1"
                        />
                    </div>
                    
                </a>
            </div> */}
        </>

    );
};

export default withRouter(AppSwitcher);