import React from "react";

import ReactApexChart from "react-apexcharts";
import { useErrorBoundary } from 'react-error-boundary';

import $ from 'jquery';

const RadialChart = (props) => {

    let data;
    $('pieChart').empty();
    const { showBoundary } = useErrorBoundary();
    const chartColours = [];
    const chartKeys = [];
    let total, unique, completed = 0;

    try {
        if (props.chartData !== undefined) {
            for (let i = 0; i < props.chartData.length; i++) {
                chartColours[i] = props.chartData[i].color;
                chartKeys[i] = props.chartData[i].key;
                if (i === 0) {
                    total = props.chartData[i].values;
                }
                else if (i === 1) {
                    unique = props.chartData[i].values;
                }
                else if (i === 2) {
                    completed = props.chartData[i].values;
                }
            }
        }

        let avg = 0;
        // to find max
        let max1 = total > completed ? total : completed;
        let max2 = unique > avg ? unique : avg;
        let max_val = 0;

        if (max1 > max2) {
            max_val = max1;
        } else {
            max_val = max2;
        }

        // to convert values in percentage, by considering max value as 100%
        let avg_percentage = 0;
        let total_percentage = 0;
        let completed_percentage = 0;
        let unique_percentage = 0;

        total_percentage = (total / max_val) * 100;
        completed_percentage = (completed / max_val) * 100;
        unique_percentage = (unique / max_val) * 100;

        if (unique === 0 || total === 0) {
            avg_percentage = 0;
        } else {
            avg_percentage = (Math.ceil(total / unique) / max_val) * 100;
        }

        if (total === 0) {
            total_percentage = 0;
        }
        if (completed === 0) {
            completed_percentage = 0;
        }
        if (unique === 0) {
            unique_percentage = 0;
        }

        data =
        {
            series: [total_percentage, unique_percentage, completed_percentage, avg_percentage],
            options: {
                chart: {
                    height: props.height,
                    width: props.width,
                    type: 'radialBar',
                },

                plotOptions: {
                    radialBar: {
                        offsetX: 5,
                        offsetY: 40,
                        startAngle: 0,
                        endAngle: 270,
                        hollow: {
                            margin: 3,
                            size: '10%',
                            background: 'transparent',
                            image: undefined,
                        },
                        dataLabels: {
                            name: {
                                show: false,
                            },
                            value: {
                                show: false,
                            }
                        }
                    }
                },

                colors: chartColours,

                labels: chartKeys,

                legend: {
                    show: true,
                    floating: true,
                    fontSize: '12px',
                    position: 'left',
                    offsetX: -70,
                    offsetY: 65,
                    labels: {
                        useSeriesColors: true,
                    },
                    markers: {
                        size: 1
                    },
                    formatter: function (seriesName, opts) {
                        if (opts.w.globals.series[opts.seriesIndex] == 0) {
                            return seriesName + ":  " + 0
                        }
                        else {
                            return seriesName + ":  " + (Math.ceil((opts.w.globals.series[opts.seriesIndex] / 100) * max_val)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                    },
                    itemMargin: {
                        horizontal: 60,
                        vertical: 2
                    },
                }
            },
        }
    } catch (error) {
        showBoundary(error)
    }




    return (
        <div id="chart">
            <ReactApexChart options={data.options} series={data.series} type="radialBar" height={390} />
        </div>
    );
};
export default React.memo(RadialChart);
