import React, { useRef, useState, useEffect } from "react";
import NVD3Chart from "react-nvd3";

import * as d3 from 'd3';
import $ from 'jquery'
import { CommonUtils } from "utils/CommonUtils";
import { useErrorBoundary } from 'react-error-boundary';

const Piechart = (props: any) => {
    const PieChartRef = useRef(null);
    const { showBoundary } = useErrorBoundary();
    const [renderData, setRenderData] = useState({
        chart: [],
        options: [],
        flag: false
    })

    useEffect(() => {
        try {
            if (props.readyData === undefined || props.readyData === false) {
                if (props.chartData) {
                    const data = props.chartData;

                    let initialArray: any = [];
                    for (let itx1 = 0; itx1 < props.chartEntities.length; itx1++) {
                        let temp: number = 0;
                        for (let itx2 = 0; itx2 < data.length; itx2++) {
                            temp += data[itx2]['counts'][props.chartEntities[itx1]];
                        }
                        initialArray.push(temp)
                    }

                    let chartOptions: any = [];
                    for (let itx1 = 0; itx1 < props.chartEntities.length; itx1++) {
                        let dummy_chartOptions: any = {};
                        dummy_chartOptions["key"] = props.optionEntities[itx1]['key'];
                        dummy_chartOptions["y"] = initialArray[itx1];
                        dummy_chartOptions["color"] = props.optionEntities[itx1]['color'];
                        chartOptions.push(dummy_chartOptions);
                    }

                    setRenderData((prevState: any) => {
                        return {
                            ...prevState,
                            chart: [...data],
                            options: [...chartOptions]
                        }
                    })
                }
            } else {
                setRenderData((prevState: any) => {
                    return {
                        ...prevState,
                        chart: [...props.chartData],
                        options: [...props.chartData]
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [props])

    useEffect(() => {
        try {
            if (renderData.options) {
                setRenderData((prevState: any) => {
                    return {
                        ...prevState,
                        flag: true
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [renderData.options])

    return (
        <div id="linechart_mainDiv" className="marginAuto">
            <div id="wrapper_div">
                {renderData.flag && <NVD3Chart

                    ref={PieChartRef}
                    id="chart"
                    type="pieChart"
                    datum={renderData.options}
                    showValues={true}
                    showLabels={true}
                    labelThreshold={0.03}
                    labelType={props.labelType === 'percent' ? function (d: any) {
                        var percent = (d.endAngle - d.startAngle) / (2 * Math.PI);
                        return d3.format('.1%')(percent);
                    } : props.labelType}
                    x={function (d: any) {

                        return d.key;
                    }}
                    y={function (d: any) {

                        return d.y;
                    }}
                    margin={props.pieMargin}


                    height={props.height}
                    width={props.width}
                    valueFormat={function (d: any) {
                        // d = Number(d);


                        // if (props.valueFormat === 'time') {
                        //     // return this.timeConverter.transform(d, true);
                        // } else if (props.valueFormat === 'value') {


                        //     return d3.format(",.2%");
                        // }

                    }}
                    max_KeyLength={props.max_KeyLength}

                    duration={500}
                    donut={props.chartType === 'donut' ? true : false}
                    labelsOutside={true}
                    showLegend={props.showLegends}
                    legend={{
                        top: 50,
                        right: 0,
                        bottom: 50,
                        left: 0
                    }}
                    tooltip={{
                        contentGenerator: function (key: any) {
                            if (props.valueFormat === 'time') {
                                // key.data.y = Number(key.data.y);
                                // const label = self.timeConverter.transform(key.data.y, false);
                                // return `<div class="nv-tooltipText displayFlex"><div style='height:10px; margin-top: 5px; min-width:10px; display:inline-block;margin-left: 10px;
                                // margin-right:5px;min-width:10px;background-color:${key.color}'></div><div style="text-align:left">${key.data.key} <b>${label}</b></div></div>`;
                            } else if (props.valueFormat === 'value') {
                                let y_val = 0;
                                if (key.data.avgTime) {
                                    y_val = key.data.avgTime;
                                } else {
                                    y_val = key.data.y;
                                }

                                return `<div class="nv-tooltipText displayFlex"><div style='height:10px; margin-top: 5px; min-width:10px; display:inline-block;margin-left: 10px;
                                    margin-right:5px;min-width:10px;background-color:${key.color}'></div><div style="text-align:left"><span>${key.data.key}</span> <b>${y_val}</b></div></div>`;
                            }
                        }
                    }}
                />}
            </div>
        </div>
    );
};
export default React.memo(Piechart);
