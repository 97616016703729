import * as actionTypes from '../../Actions/ActionTypes';


export interface LoginState {
    loginData: any
}

const initialState = {
    loginData: [],
};

const loginReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SET_LOGIN_DATA:
            return {
                ...state,
                loginData: action.loginData,
            };
        default:
            return state;
    }
};

export default loginReducer;
