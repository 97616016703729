import * as actionTypes from '../../Actions/ActionTypes';

export interface SurveyStatsState {
    surveyStatsBarChartData: any,
    surveyStatsSummaryTableData: any,
}

const initialState = {
    surveyStatsBarChartData: [],
    surveyStatsSummaryTableData: [],
};


const surveyStatsReducer = (state = initialState, action:any) => {
    switch (action.type) {
        case actionTypes.SET_SURVEY_STATS_BAR_CHART_DATA:
            return {
                ...state,
                surveyStatsBarChartData: action.surveyStatsBarChartData,
            };
            
        case actionTypes.SET_SURVEY_STATS_SUMMARY_TABLE_DATA:
            return {
                ...state,
                surveyStatsSummaryTableData: action.surveyStatsSummaryTableData,
            };

        default:
            return state;
    }
};

export default surveyStatsReducer;