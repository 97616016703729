import * as actionTypes from '../../Actions/ActionTypes';

export interface ExportState {
    exportListData: any,
    exportTemplateData: any,
    exportSchedulerData: any
}

const initialState = {
    exportListData: [],
    exportTemplateData: [],
    exportSchedulerData: []
};


const exportReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SET_EXPORT_LIST_DATA:
            return {
                ...state,
                exportListData: action.exportListData,
            };
        case actionTypes.SET_EXPORT_SCHEDULER_DATA:
            return {
                ...state,
                exportSchedulerData: action.exportSchedulerData,
            };
        case actionTypes.SET_EXPORT_TEMPLATE_DATA:
            return {
                ...state,
                exportTemplateData: action.exportTemplateData,
            };

        default:
            return state;
    }
};

export default exportReducer;