
// Modified by: Mrunal Mane
// On: 2024-05-10 05:48 PM
// Reason: Removed language dependancy from useEffects of (startDate, endDate, errorMsg)

import React, { useEffect, useState, useRef } from "react";
import withRouter from "hoc/withRouter";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { LanguageState } from "../../store/Reducers";
import { useErrorBoundary } from 'react-error-boundary';
import moment from "moment";
import "./Monthpicker.scss";
import { InsightLabels } from "labels";
    
interface CurrentState {
    languageData: LanguageState
}

const Monthpicker = (props: any) => {
    let fetched_details = useSelector((state: CurrentState) => state);

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)

    const { showBoundary } = useErrorBoundary();

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [dateValues, setDateValues] = useState({
        endMaxDate: new Date(),
        errorMsg: '',
        exportDisabled: false
    })

    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const changeDate = (date: any, optn: string) => {
        if (optn === 'startDate') {
            setStartDate(date);
        } else {
            setEndDate(date);
        }
    }

    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        try {
            const moment_startDate = moment(startDate).format("MMMM YYYY");
            const moment_endDate = moment(endDate).format("MMMM YYYY");
            const startDateVal = new Date(endDate);
            const endMaxDate = startDateVal.setMonth(startDateVal.getMonth() - 12);

            let errorMsg = '';
            errorMsg = startDate > endDate ? insightsLbls.invalidStartDate : startDate < new Date(endMaxDate) ? insightsLbls.selectDateRangeWithin12mmonths : '';

            if (moment_endDate === moment_startDate) {
                errorMsg = '';
            }

            setDateValues((prevState: any) => {
                return {
                    ...prevState,
                    errorMsg: errorMsg
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    }, [startDate])



    useEffect(() => {
        try {
            const moment_startDate = moment(startDate).format("MMMM YYYY");
            const moment_endDate = moment(endDate).format("MMMM YYYY");

            const endDateVal = new Date(startDate);
            const startDateMinDate = endDateVal.setMonth(endDateVal.getMonth() + 12);


            let errorMsg = '';
            errorMsg = endDate < startDate ? insightsLbls.invalidEndDate : endDate > new Date(startDateMinDate) ? insightsLbls.selectDateRangeWithin12mmonths : '';

            if (moment_endDate === moment_startDate) {
                errorMsg = '';
            }

            setDateValues((prevState: any) => {
                return {
                    ...prevState,
                    errorMsg: errorMsg
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    }, [endDate])




    useEffect(() => {
        try {
            setDateValues((prevState: any) => {
                return {
                    ...prevState,
                    exportDisabled: dateValues.errorMsg.length ? true : false
                }
            })

            props.errorDispacthed(dateValues.errorMsg.length ? true : false)
        } catch (error) {
            showBoundary(error)
        }
    }, [dateValues.errorMsg])



    useEffect(() => {
        try {
            if (dateValues.errorMsg.length === 0) {
                const start_date = moment(startDate).format("YYYY-MM-01") + '_00:00:00';
                const end_date = moment(endDate).endOf('month').format('YYYY-MM-DD') + '_23:59:59';

                const data = {
                    start_date: start_date,
                    end_date: end_date
                }
                props.datesDispatch(data)
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [startDate, endDate, dateValues.errorMsg])



    return (
        <section className="monthPicker-section">
            <div className="displayFlex col-sm-12 col-md-12 col-lg-12 col-12 height20  marginTop-30 width100 paddingLeft-20 marginBottom-10">
                <p className="errorMsg">{dateValues.errorMsg}</p>
            </div>
            <div className="row marginLR-0 displayFlex width675">

                <div className="displayFlex col-sm-6 col-md-6 col-lg-6 col-6 ">
                    <label className="font14 monthPicker-label">{insightsLbls.from}</label>
                    <DatePicker
                        calendarClassName="monthInput"
                        selected={startDate}
                        onChange={(date) => changeDate(date, 'startDate')}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker
                        maxDate={new Date()}
                    />
                </div>

                <div className="displayFlex col-sm-6 col-md-6 col-lg-6 col-6 positionRelative left-20">
                    <label className="font14 monthPicker-label">{insightsLbls.to}</label>
                    <DatePicker
                        calendarClassName="monthInput"
                        selected={endDate}
                        onChange={(date) => changeDate(date, 'endDate')}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="MMMM yyyy"
                        showMonthYearPicker
                        maxDate={new Date()}
                    />
                </div>
            </div>
        </section>

    );
};

export default withRouter(React.memo(Monthpicker));