import axios from "axios";
import { ApiRelativePaths } from "../../utils/GlobalConstants";
import { v4 as uuidv4 } from 'uuid';
import { setNavigationStatus } from "store/Actions";
import initializeStore from "./../../store/store";


let apiCallCount = [];
const store = initializeStore();
let axiosInstance = axios.create({
    headers: {
        'AppKey': process.env.REACT_APP_APP_KEY,
        'AccessToken': localStorage.getItem('ACCESS_TOKEN'),
        'RefreshToken': localStorage.getItem('REFRESH_TOKEN'),
        'Content-Type': 'application/json',
    },
});

let cancelTokenSource = axios.CancelToken.source();

let managingApiMap = new Map();
let lazyLoadCalls = false;

axiosInstance.interceptors.request.use(function (config: any) {

    config.headers['AccessToken'] = localStorage.getItem('ACCESS_TOKEN');
    config.cancelToken = cancelTokenSource.token;
    let requestUrl = config.url;
    apiCallCount.push(true);
    if (!(requestUrl.includes('metadata') || requestUrl.includes('organization') || requestUrl.includes('account'))) {
        if(requestUrl.includes('userengagement')) {
            try{
                let finalDate = store.getState()["dates"]["fdate"];
                let startDate = requestUrl.split("start_date=")[1].split("&")[0];
                startDate = startDate.split("_")[0];
                let finalDateObj = new Date(finalDate);
                let startDateObj = new Date(startDate);
                if(finalDateObj>=startDateObj) {
                    lazyLoadCalls = false;
                } else {
                    lazyLoadCalls = true;
                }
            }catch(e) {
                lazyLoadCalls = false;
            }
        }
        dispatchAction(false);
    }
    config.cancelTokenSource = cancelTokenSource;

    if (localStorage.getItem('SECONDSFLAG') === 'false') {
        checkUserTime();
    }
    else {
        localStorage.setItem('SESSION_EXP', 'false');
        localStorage.setItem('SECONDSFLAG', 'false');
        // window.open(urlPath + "#/login", "_self");
    }
    return config

}, error => {
    // Do something with request error
    return Promise.reject(error);
});

export const handleCancelRequest = (handleCancelRequest) => {
    if (handleCancelRequest) {
        cancelTokenSource.cancel('Component switched');
    } else {

    }
};



axiosInstance.defaults.cancelToken = cancelTokenSource.token;

createAxiosResponseInterceptor(axiosInstance);

function createAxiosResponseInterceptor(axiosInstance: any) {
    if (axiosInstance !== undefined) {
        let responseUrl;
        axiosInstance.interceptors.response.use(
            (response: any) => {
                apiCallCount.pop();
                var url = window.location.href;
                responseUrl = response.config.url;

                if (url.includes('?isMaintenance=true') && !responseUrl.includes('organization/signature')) {
                    url = url.split('?isMaintenance=true')[0];
                    window.location.href = url;
                } else if (url.includes('&isMaintenance=true') && !responseUrl.includes('organization/signature')) {
                    url = url.split('&isMaintenance=true')[0];
                    window.location.href = url;
                }

                if (!(responseUrl.includes('metadata') || responseUrl.includes('organization') || responseUrl.includes('account'))) {
                    dispatchAction(true);
                    let fdateVal = localStorage.getItem('FDATE');
                    let tdateVal = localStorage.getItem('TDATE');
                    let appId = localStorage.getItem('switch_app_id');

                    if (!window.location.hash.includes(api_pathname)) {
                        cancelTokenSource.cancel('Component switched');
                        cancelTokenSource = axios.CancelToken.source();
                    }

                    // if(!(window.location.href.includes('goal') || window.location.href.includes('control-center') || window.location.href.includes('export'))) {
                    //     if(!(responseUrl.includes(fdateVal) && responseUrl.includes(tdateVal))){
                    //         cancelTokenSource.cancel('Date Changed');
                    //         cancelTokenSource = axios.CancelToken.source();
                    //     }
                    // } 
                }
                if (apiCallCount.length == 0 && !lazyLoadCalls) {
                    managingApiMap.clear();
                }
                return response;
            },
            (error: any) => {

                if (error !== undefined) {

                    // const { config } = error;
                    // const originalRequest = config;

                    apiCallCount.pop();
                    dispatchAction(true);
                    if (apiCallCount.length == 0 && !lazyLoadCalls) {
                        managingApiMap.clear();
                    }
                    if (error.response !== undefined) {
                        if (error.response.status !== 401) {
                            let urlPath = error.config.url;
                            //calling api
                            const uuid = uuidv4();
                            let tempObj = {
                                "errorType": 8,
                                "errorLog": JSON.stringify(error.message),
                                "severity": 4,
                                "filePath": "",
                                "urlPath": urlPath,
                                "logUUID": uuid
                            }
                            let generatedURL = process.env.REACT_APP_ANALYTICS_HOST + '/services/v1/log/error/frontend/';
                            axios.post(generatedURL, tempObj, {
                                headers: {
                                    'AppKey': process.env.REACT_APP_APP_KEY,
                                    'Content-Type': 'application/json',
                                    'AccessToken': localStorage.getItem('ACCESS_TOKEN'),
                                    'RefreshToken': localStorage.getItem('REFRESH_TOKEN'),
                                }
                            }).then(res => {
                            }).catch(err => {
                                // console.log("error while persisting errorlog ", err);
                            });
                            // return Promise.reject(error);
                            // return axiosInstance(error);
                        }
                        /*
                        * When response code is 401, try to refresh the token.
                        * Eject the interceptor so it doesn't loop in case
                        * token refresh causes the 401 response
                        */

                        // axiosInstance.interceptors.response.eject(interceptor);
                        if ((error.response.status === 401 || error.response.status === 403) && error.config && !error.config.__isRetryRequest) {
                            return getAuthToken().then((response: any) => {
                                if (response) {
                                    saveToken(response);
                                    error.config.__isRetryRequest = true;
                                    error.config.headers['AccessToken'] = response.data.data.accesstoken;
                                    return axiosInstance(error.response.config);
                                } else {
                                    getModal();
                                }
                            }).catch((error: any) => {
                                getModal('error');
                                return Promise.reject(error);
                            }).finally(createAxiosResponseInterceptor);
                        }

                        if (error.response.status === 400) {
                            if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
                                // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
                                // return this.logoutUser();
                            }
                            if (error && error.error && error.error.error) {
                                // to show erro toast
                            }
                        }

                        if (error.response.status === 503 && apiCallCount.length === 0) {
                            window.open(process.env.REACT_APP_ADMIN_URL! + "/maintenance_mode?redirectfrommaintenance=" + btoa(window.location.href), "_self");
                        }

                    } else if (error.message === "Network Error") {
                        // if (!error.config.__isRetryRequest) {
                        //     error.config.__isRetryRequest = true;
                        //     delayRequest(5000, originalRequest);
                        // }
                    }
                    return Promise.reject(error);

                }
            }
        );
    }
}



let api_pathname = '';

export const getInstance = (pathname: string) => {
    api_pathname = pathname;
}

function checkUserTime() {
    const startDate = new Date().getTime();
    const time_val = +(localStorage.getItem('SECONDS'))!;
    // 86400
    if (time_val !== 0) {
        if (((startDate - time_val) / 1000) > 86400) {

            if (window.location.href.includes('login')) {
                localStorage.setItem('SECONDSFLAG', 'false');
                localStorage.setItem('SECONDS', String(new Date().getTime()));
            } else {
                localStorage.setItem('SECONDS', String(startDate));
                localStorage.setItem('SECONDSFLAG', 'true');
                getModal();
            }

            localStorage.setItem('SECONDSFLAG', 'true');
            localStorage.setItem('SECONDS', String(startDate));
        } else {
            localStorage.setItem('SESSION_EXP', 'false');
            localStorage.setItem('SECONDSFLAG', 'false');

        }
    }

    if (localStorage.getItem('SUPER_ADMIN') === 'false') {
        if ((window.location.href).includes('org-view') || (window.location.href).includes('org-detail')) {
            routeToNotFound();
        }
    }

    if (localStorage.getItem('MYBOT_VISIBILITY') === 'false') {
        if ((window.location.href).includes('guide-automation')) {
            routeToNotFound();
        }
    }

    // if (localStorage.getItem('QAAUTOMATOR_VISIBILITY') === 'false') {
    //     if ((window.location.href).includes('qa-automator')) {
    //         routeToNotFound();
    //     }
    // }
    if (localStorage.getItem('APPINSIGHTS_VISIBILITY') === 'false') {
        if ((window.location.href).includes('pages') || (window.location.href).includes('workflow') || (window.location.href).includes('features')) {
            routeToNotFound();
        }
    }
    if (localStorage.getItem('GUIDEINSIGHTS_VISIBILITY') === 'false') {
        if ((window.location.href).includes('guide-analytics')) {
            routeToNotFound();
        }
    }
    if (localStorage.getItem('GUIDEINSIGHTS_VISIBILITY') === 'false' && localStorage.getItem('APPINSIGHTS_VISIBILITY') === 'false') {
        if ((window.location.href).includes('dashboard') || (window.location.href).includes('guide-analytics') || (window.location.href).includes('app-insights')) {
            window.open(process.env.REACT_APP_HOST + "#/survey/manage-survey?app_code=" + localStorage.getItem('switch_app_code') + "&app_id=" + localStorage.getItem('switch_app_id'), "_self")
        }
    }
    localStorage.setItem('SECONDS', String(startDate));
}

function getModal(optn?: string) {
    localStorage.setItem('SESSION_EXP', 'true');
    localStorage.setItem('IS_AUTHENTICATED', 'false');

    let location_val = window.location.hash;

    if (window.location.href.includes('pages-details')) {
        location_val = '#/app-insights/pages';
    } else if (window.location.href.includes('workflow-details')) {
        location_val = '#/app-insights/workflow';
    } else if (window.location.href.includes('guide-analytics-detail')) {
        location_val = '#/guide-insights/guide-analytics';
    } else if (window.location.href.includes('user-engagement-detail')) {
        location_val = '#/guide-insights/user-engagement';
    }


    if (!location_val.includes('login')) {
        const redirectUrlInfo = {
            switchAppCode: localStorage.getItem('switch_app_code'),
            app_guide_automation_enabled: localStorage.getItem('app_guide_automation_enabled'),
            switchAppId: localStorage.getItem('switch_app_id'),
            emailId: localStorage.getItem('EMAIL_ID'),
            urlPath: location_val,
            selectedOptn: localStorage.getItem('SELECTED_OPTN'),
            selectedAppName: localStorage.getItem('firstApp_name'),
            min_date: localStorage.getItem('min_date'),
            max_date: localStorage.getItem('max_date'),
            orgName: localStorage.getItem('ORG_NAME'),
            orgImgUrl: localStorage.getItem('ORG_IMG_URL'),
            orgId: localStorage.getItem('ORG_ID')
        }

        let urlString = btoa(JSON.stringify(redirectUrlInfo));
        if (process.env.NODE_ENV === 'production') {
            if (optn === 'error') {
                localStorage.clear();
                window.open(process.env.REACT_APP_ADMIN_URL + "?redirectURL=" + btoa('/logout'), "_self");
            } else {
                const path = window.location.hash.split('#');
                const path_name = path[1].split('/');
                if (path_name[1] !== 'dashboard') {
                    window.open(process.env.REACT_APP_ADMIN_URL! + "?redirectURL=" + btoa(location_val), "_self");

                } else {
                    window.open(process.env.REACT_APP_ADMIN_URL! + "?redirectURL=" + btoa('/' + path_name[1]), "_self");
                }
            }
        } else {
            urlString = '#/login?sessionExpired=true&redirectUrl=' + urlString;
            window.location.href = urlString;
            window.location.reload();
        }
    }
}

function routeToNotFound() {
    window.location.href = "#/page-not-found"
}

let authTokenRequest: any = '';
// This function makes a call to get the auth token
// or it returns the same promise as an in-progress call to get the auth token
function getAuthToken() {
    if (!authTokenRequest) {
        authTokenRequest = axios
            .get(process.env.REACT_APP_ANALYTICS_HOST + ApiRelativePaths.REFRESH_TOKEN, {
                headers: {
                    AppKey: process.env.REACT_APP_APP_KEY,
                    RefreshToken: localStorage.getItem("REFRESH_TOKEN"),
                },
            })
            .catch(function (error) {
                localStorage.clear();
                setTimeout(() => {
                    window.open(process.env.REACT_APP_HOST, "_self");
                }, 0)
            });

        authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest);
    }
    return authTokenRequest;
}


function resetAuthTokenRequest() {
    authTokenRequest = null;
}

function saveToken(res: any) {
    localStorage.removeItem('ACCESS_TOKEN');
    localStorage.setItem('ACCESS_TOKEN', res.data.data.accesstoken);
    localStorage.setItem('REFRESH_TOKEN', res.data.data.refreshtoken);
}

function getValueOrDefaultValue(currentLocation) {
    if (managingApiMap.has(currentLocation)) {
        return managingApiMap.get(currentLocation);
    } else {
        lazyLoadCalls = false;
        managingApiMap.clear();
    }
    return false;
}

function dispatchAction(status) {
    let currentLocation = window.location.hash.split("?")[0];
    let val = getValueOrDefaultValue(currentLocation);
    if (status) {
        if (val) {
            store.dispatch(setNavigationStatus(status));
        }
    } else {
        if (!val) {
            managingApiMap.set(currentLocation, true);
            store.dispatch(setNavigationStatus(status));
        }
    }
}

export { cancelTokenSource }
export default axiosInstance;