import * as actionTypes from '../../Actions/ActionTypes';

export interface GuideErrorsDetailState {
    guideErrorsChartData: any,
    guideErrorsSummary: any,
    guideErrorData: any,
}

const initialState = {
    guideErrorsChartData: [],
    guideErrorsSummary: [],
    guideErrorData: []
};


const guideErrorsDetailReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.SET_GUIDE_ERRORS_CHART_DATA:
            return {
                ...state,
                guideErrorsChartData: action.guideErrorsChartData,
            };


        case actionTypes.SET_GUIDE_ERRORS_DETAIL_SUMMARY:
            return {
                ...state,
                guideErrorsSummary: action.guideErrorsSummary,
            };

        case actionTypes.SET_GUIDE_ANALYTICS_ERROR_DETAILS:
            return {
                ...state,
                guideErrorData: action.guideErrorData,
            };

        default:
            return state;
    }
};

export default guideErrorsDetailReducer;