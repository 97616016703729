import * as ActionTypes from '../ActionTypes';
import { ApiRelativePaths } from '../../../utils/GlobalConstants';
import axios from 'axios';



let ApiPaths: any = ApiRelativePaths;

export const setOtpData = (otpData: any) => {
    return {
        type: ActionTypes.SET_OTP_DATA,
        otpData: otpData
    }
};

export const setResendOtpData = (otpResendData: any) => {
    return {
        type: ActionTypes.SET_RESEND_OTP_DATA,
        otpResendData: otpResendData
    }
}



export const getOtpData = (data: any, url_path: string, caseType: string) => {
    return new Promise((resolve, reject) => {
        let path = ApiPaths[url_path];
        let host = process.env.REACT_APP_ANALYTICS_HOST;
        let generatedURL: string;

        generatedURL = generatedURL = host + '/' + path;

        axios.post(generatedURL, data, {
            headers: {
                'AppKey': process.env.REACT_APP_APP_KEY,
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                data = {
                    "result": "success",
                    "data": res.data.data,
                };
            })
            .catch(err => {
                data = {
                    "result": "error",
                    "data": err.response.data,
                };
            })
            .then(() => {
                switch (caseType) {
                    case ActionTypes.SET_OTP_DATA:
                        resolve(setOtpData(data));
                        break;

                    case ActionTypes.SET_RESEND_OTP_DATA:
                        resolve(setResendOtpData(data));
                        break;
                }

            });
    });
}
