import React, { useEffect, useState } from 'react';

import $ from 'jquery';


import { exportComponentAsJPEG } from '../../assets/js/exportPDF';
import { CommonUtils } from "utils/CommonUtils";
import exportPdf_img from 'assets/js/images/exportPdf_img'
import { useSelector } from "react-redux";
import { LanguageState } from "store/Reducers";
import { useErrorBoundary } from 'react-error-boundary';
import "./ExportToPdf.scss";



import { InsightLabels } from 'labels';

interface CurrentState {
    languageData: LanguageState
}


const ExportToPdf = (props: any) => {
    let fetched_details = useSelector((state: CurrentState) => state);
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)

    const [exportPdf, setExportPdf] = useState({
        text: 'Export PDF',
        // disabled: props.disabled,
        stopProp: 0,
        scrollFlag: 0,
        exportText: "Export PDF",
    });

    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        try {
            if (fetched_details.languageData.languageData) {
                setExportPdf((prevState: any) => {
                    return {
                        ...prevState,
                        exportText: insightsLbls.exportPDF
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.languageData.languageData])


    useEffect(() => {
        if (props.disabled) {
            CommonUtils.addRemove_class("add", "exportToPdf", "opaque");
        }
        else {
            CommonUtils.addRemove_class("remove", "exportToPdf", "opaque");
        }
    }, [props.disabled])


    const exportToPdf = () => {
        try {
            $("#overlayCursor").show();

            setExportPdf((prevState: any) => {
                return {
                    ...prevState,
                    stopProp: 1,
                    scrollFlag: 1,
                    exportText: insightsLbls.downloading,
                }
            })

            exportComponentAsJPEG(props.componentRef, {
                fileName: props.source,
                widthDivideBy: props.widthDividedBy,
            }).then(() => {
                $("#overlayCursor").hide();

                setExportPdf((prevState: any) => {
                    return {
                        ...prevState,
                        stopProp: 0,
                        scrollFlag: 0,
                        exportText: insightsLbls.exportPDF,
                    };
                });
            });
        } catch (error) {
            showBoundary(error)
        }

    }


    return (


        <button title={insightsLbls.exportPDF} className="exportToPdf" id="exportButton" disabled={props.disabled}
            onClick={() => exportToPdf()}>
            {/* <img src={exportPdf_img} width="22" height="auto" alt="loader" className="pdf-icon" /> */}
            {insightsLbls.exportPDF}
        </button>


    )
}


export default React.memo(ExportToPdf);