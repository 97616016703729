import React, { useEffect, useRef, useState } from 'react';
import Switch from "react-switch";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom';
import withRouter from "hoc/withRouter";
import { withReducer } from 'store/withReducer';
import controlCenterReducer from 'store/Reducers/ControlCenter/controlCenter';
import { AppsState, DatesState, LanguageState, CommonComponentState } from "store/Reducers";
import { ControlCenterState } from 'store/Reducers/ControlCenter/controlCenter';
import * as controlCenterActions from "store/Actions/index";
import * as ActionTypes from "store/Actions/ActionTypes";
import { CommonUtils } from 'utils/CommonUtils';
import qs from 'querystring';
import "./ControlCenter.scss";
import Loader from 'components/Loader/Loader';
import { postCall } from 'utils/ApiCallActions';
import { ApiRelativePaths } from 'utils/GlobalConstants';
import { toast } from 'react-toastify';
import { useErrorBoundary } from 'react-error-boundary';
import { InsightLabels } from 'labels';
import { Tab, Tabs } from 'react-bootstrap';

interface CurrentState {
    apps: AppsState,
    dates: DatesState,
    controlCenter: ControlCenterState,
    languageData: LanguageState,
    commonComponentData: CommonComponentState,

}

const ControlCenter: React.FC = (props: any) => {
    const isInitialMount = useRef(true);

    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)

    const { showBoundary } = useErrorBoundary();

    let fetched_details = useSelector((state: CurrentState) => state);

    const componentRef = useRef() as React.RefObject<HTMLDivElement>;

    const dispatch = useDispatch();

    const location = useLocation();
    const navigate = useNavigate();

    const [preferenceData, setPreferenceData] = useState({
        dataFetched: false,
        allData: [],
        disabledFlag: false
    })

    const [pageTab, setPageTab] = useState("email");
    const titleDetailMap = new Map();

    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
    }, [fetched_details.languageData.languageData])

    useEffect(() => {
        document.title = insightsLbls.controlCenter;
        if (insightsLbls) {
            document.title = insightsLbls.controlCenter;
        }
        window.scrollTo(0, 0);
        CommonUtils.LeftPanelHighlight(7, 0, 0, true, false);
    }, [insightsLbls])

    useEffect(() => {
        navigate({
            pathname: `/control-center/`,
            search: qs.stringify({})
        });

        if (isInitialMount.current) {
            getPreferenceData();
            isInitialMount.current = false;
        }

        setTimeout(() => {
            isInitialMount.current = true;
        }, 100)
    }, []);


    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getPreferenceData = (apiErrorFlag?: string) => {
        const params = {};
        controlCenterActions.getControlCenterData(
            params,
            "PREFERENCE_DATA",
            ActionTypes.SET_PREFERENCE_DATA,
            apiErrorFlag ?? '',
        ).then((res: any) => dispatch(res));
    }


    const savePreferenceData = () => {
        try {
            let path = ApiRelativePaths.SAVE_PREFERENCE_DATA;
            let params = {};
            let paramObj = {};
            let a = CommonUtils.URLGenerator(params, path, paramObj);

            postCall(preferenceData.allData, "SAVE_PREFERENCE_DATA").then((data: any) => {
                if (data.result === "success") {
                    toast.success(insightsLbls.successfullyUpdated, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                    setPreferenceData((prevState: any) => {
                        return {
                            ...prevState,
                            dataFetched: false
                        }
                    })
                    getPreferenceData();
                } else {
                    toast.error(insightsLbls.error, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const handleChange = (checked: boolean, id: string, option: string | any) => {
        try {
            let dummyData: any = preferenceData.allData
            dummyData[option][id] = checked;

            let disabledFlag = false;

            if (option === 'goal') {
                if (id === 'receive_goal_email_notification' && checked === false) {
                    for (let data in dummyData[option]) {
                        if (dummyData[option].hasOwnProperty(data)) {
                            dummyData[option][data] = false;
                            disabledFlag = true;
                        }
                    }
                } else {
                    disabledFlag = false;
                }
            }

            setPreferenceData((prevState: any) => {
                return {
                    ...prevState,
                    allData: dummyData,
                    dataFetched: true,
                    disabledFlag: disabledFlag
                }
            })
        } catch (error) {
            showBoundary(error)
        }
    }

    // const getHandleColor = (option: string) => {
    //     try {
    //         if (process.env.REACT_APP_ENV === 'prod') {
    //             return option === 'on' ? '#38b6a0' : '#acadc1'
    //         } else {
    //             return option === 'on' ? '#0267c1' : '#acadc1'
    //         }
    //     } catch (error) {
    //         showBoundary(error)
    //     }
    // }

    const getCheckedValue = (val: boolean) => {
        try {
            return val;
        } catch (error) {
            showBoundary(error)
        }
    }

    const changeTab = (e: any) => setPageTab(e);

    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    useEffect(() => {
        try {
            if (
                fetched_details.controlCenter.controlCenterData.data !== undefined &&
                fetched_details.controlCenter.controlCenterData.result === "success"
            ) {

                setPreferenceData((prevState: any) => {
                    return {
                        ...prevState,
                        allData: fetched_details.controlCenter.controlCenterData.data,
                        disabledFlag: fetched_details.controlCenter.controlCenterData.data['goal']['receive_goal_email_notification'] === false ? true : false
                    }
                })
            } else if (fetched_details.controlCenter.controlCenterData.result === "retry") {
                getPreferenceData('retry')
            }
            else if (fetched_details.controlCenter.controlCenterData.result === "error") {
                setPreferenceData((prevState: any) => {
                    return {
                        ...prevState,
                        allData: [],
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details.controlCenter.controlCenterData])


    useEffect(() => {
        try {
            if (preferenceData.allData) {
                setPreferenceData((prevState: any) => {
                    return {
                        ...prevState,
                        dataFetched: true,
                    }
                })
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [preferenceData.allData])

    const getTitleNDetail = (key, keyNum) => {
        if (titleDetailMap.size == 0) {
            titleDetailMap.set('weekly_usage_stats', [insightsLbls.weeklyUsageStats, insightsLbls.oneEmailonSundays]);
            titleDetailMap.set('daily_steps_failure_report', [insightsLbls.dailyFailureReport, insightsLbls.oneEmailPublishedGuides]);
            titleDetailMap.set('receive_goal_email_notification', [insightsLbls.receiveGoalEmailNotification, insightsLbls.receiveEmailOnAchievement]);
            titleDetailMap.set('receive_goal_milestone_notification', [insightsLbls.receiveGoalMilestoneNotification, insightsLbls.receiveEmailOnMilestone]);
        }
        return titleDetailMap.get(key)[keyNum];
    }

    const getTabDataList = () => {
        if (preferenceData.dataFetched) {
            if (preferenceData.allData && preferenceData.allData[pageTab]) {
                return Object.keys(preferenceData.allData[pageTab]).map((key) => {
                    return <div className='displayFlex justify-space-between align-items-start settingRow'>
                        <div className='displayFlexColumn'>
                            <div className='title'>{getTitleNDetail(key, 0)}</div>
                            <div className='detail'>{getTitleNDetail(key, 1)}</div>
                        </div>
                        <div>
                            <Switch
                                onChange={(event) => handleChange(event, key, pageTab)}
                                checked={getCheckedValue(preferenceData.allData[pageTab][key])}
                                checkedIcon={false}
                                uncheckedIcon={false}
                                onColor="#005BF0"
                                offColor="#8D8D8D"
                                height={24}
                                width={48}
                                handleDiameter={16}
                                id={key}
                                disabled={(preferenceData.disabledFlag && key === 'receive_goal_milestone_notification') ? true : false}
                            />
                        </div>
                    </div>
                })
            }
        }
        return <Loader></Loader>;
    }

    return (
        <section className='controlCenterComponent' ref={componentRef}>
            <div className="controlCenterDiv">
                <div className="header">
                    {insightsLbls.controlCenter}
                </div>
                <div className="content-container">
                    <div className="tab-section">
                        <Tabs activeKey={pageTab} onSelect={changeTab}>
                            <Tab eventKey={"email"} title={insightsLbls.emailNotificationPreference}>
                                <div className='tab-data-container'>{getTabDataList()}</div>
                            </Tab>
                            <Tab eventKey={"goal"} title={insightsLbls.goalNotificationPreference}>
                                <div className='tab-data-container'>{getTabDataList()}</div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                {preferenceData.dataFetched && (
                    <div className="displayFlex align-items-center justify-content-center">
                        <button className="btn btn-primary" onClick={() => savePreferenceData()}>{insightsLbls.save}</button>
                    </div>
                )}
            </div>
        </section>
    )
}

export default withRouter(withReducer("controlCenter", controlCenterReducer)(ControlCenter));
