import * as ActionTypes from '../ActionTypes';
import axiosInstance, { getInstance } from '../../../network/apis/interceptor';
import { CommonUtils } from '../../../utils/CommonUtils';
import { ApiRelativePaths, RetryApi } from '../../../utils/GlobalConstants';

let ApiPaths: any = ApiRelativePaths;

export const setTooltipsDetailList = (tooltipsDetailListData: any) => {
    return {
        type: ActionTypes.SET_TOOLTIPS_DETAIL_LIST_DATA,
        tooltipsDetailListData: tooltipsDetailListData
    }
};

export const setTooltipsDetailChart = (tooltipsDetailChartData: any) => {
    return {
        type: ActionTypes.SET_TOOLTIPS_DETAIL_CHART,
        tooltipsDetailChartData: tooltipsDetailChartData
    }
};

export const setTooltipsDetailPowerformChart = (tooltipsDetailPowerformChartData: any) => {
    return {
        type: ActionTypes.SET_TOOLTIPS_DETAIL_POWERFORM_CHART,
        tooltipsDetailPowerformChartData: tooltipsDetailPowerformChartData
    }
};

export const setTooltipsDetailUserActivity = (tooltipsDetailUserActivity: any) => {
    return {
        type: ActionTypes.SET_TOOLTIPS_DETAIL_USER_ACTIVITY,
        tooltipsDetailUserActivity: tooltipsDetailUserActivity
    }
}

export const getTooltipsDetailData = (params: any, url_path: any, caseType: any, apiErrorFlag: string, firstTimeload?: boolean, optionalData?: any) => {
    return new Promise((resolve, reject) => {
        let path = ApiPaths[url_path];
        let data:any = {};
        let paramObj = {};
        // let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);

        let urlData;
        if (optionalData !== '' && optionalData !== undefined) {
            urlData = urlData = CommonUtils.URLGenerator(params, path + "/" + optionalData, paramObj);
        } else {
            urlData = CommonUtils.URLGenerator(params, path, paramObj);
        }

        getInstance('tooltips-detail');


        axiosInstance.get(urlData)
        .then(res => {
            data = {
              "result": "success",
              "data": res.data.data,
              "firstTimeload": firstTimeload ?? false,
            };
        })
        .catch(err => {
            if (apiErrorFlag === '') {
                data = {
                    "result": "retry",
                    "data": err,
                    "firstTimeload": firstTimeload ?? false,
                };
            } else {
                data = {
                    "result": "error",
                    "data": err,
                    "errorCode": err?.response?.status ?? '',
                    "firstTimeload": firstTimeload ?? false,
                };
            }
        })
        .then(() => {
            
            const callActionDispatch = () => {
                switch (caseType) {
                    case ActionTypes.SET_TOOLTIPS_DETAIL_LIST_DATA:
                        resolve(setTooltipsDetailList(data));
                        break;

                    case ActionTypes.SET_TOOLTIPS_DETAIL_CHART:
                        resolve(setTooltipsDetailChart(data));
                        break;

                    case ActionTypes.SET_TOOLTIPS_DETAIL_POWERFORM_CHART:
                        resolve(setTooltipsDetailPowerformChart(data));
                        break;

                    case ActionTypes.SET_TOOLTIPS_DETAIL_USER_ACTIVITY:
                        resolve(setTooltipsDetailUserActivity(data));
                        break;

                    default:
                        break;
                }
            }

            if (data.result === 'retry') {
                setTimeout(() => {
                    callActionDispatch();
                }, RetryApi.TIMEOUT);
            } else {
                callActionDispatch();
            }
        });           
    });
}