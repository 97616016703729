import * as ActionTypes from '../ActionTypes';
import axiosInstance, { getInstance } from '../../../network/apis/interceptor';
import { CommonUtils } from '../../../utils/CommonUtils';
import { ApiRelativePaths, RetryApi } from '../../../utils/GlobalConstants';


let ApiPaths: any = ApiRelativePaths;


export const setGuideAutomationLineChart = (guideAutomationLineChartData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_AUTOMATION_LINE_CHART_DATA,
        guideAutomationLineChartData: guideAutomationLineChartData
    }
};

export const setGuideAutomationSummaryTable = (guideAutomationSummaryTableData: any) => {
    return {
        type: ActionTypes.SET_GUIDE_AUTOMATION_SUMMARY_TABLE_DATA,
        guideAutomationSummaryTableData: guideAutomationSummaryTableData
    }
};


export const getGuideAutomationData = (params: any, url_path: any, caseType: any, apiErrorFlag: string, firstTimeload?: boolean) => {
    return new Promise((resolve, reject) => {
        let path = ApiPaths[url_path];
        let data:any = {};
        let paramObj = {};
        let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
        getInstance('automation-insights/guide-automation/');

        axiosInstance.get(generatedURL)
        .then(res => {
            data = {
              "result": "success",
              "data": res.data.data,
              "firstTimeload": firstTimeload ?? false,
            };
        })
        .catch(err => {
            if (apiErrorFlag === '') {
                data = {
                    "result": "retry",
                    "data": err,
                    "firstTimeload": firstTimeload ?? false,
                };
            } else {
                data = {
                    "result": "error",
                    "data": err,
                    "firstTimeload": firstTimeload ?? false,
                };
            }
        })
        .then(() => {
            const callActionDispatch = () => {
                switch (caseType) {
                    case ActionTypes.SET_GUIDE_AUTOMATION_LINE_CHART_DATA:
                        resolve(setGuideAutomationLineChart(data));
                        break;

                    case ActionTypes.SET_GUIDE_AUTOMATION_SUMMARY_TABLE_DATA:
                        resolve(setGuideAutomationSummaryTable(data));
                        break;

                    default:
                        break;
                }
            }
            

            if (data.result === 'retry') {
                setTimeout(() => {
                    callActionDispatch();
                }, RetryApi.TIMEOUT);
            } else {
                callActionDispatch();
            }
        });           
    });
}