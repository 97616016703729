import * as actionTypes from '../../Actions/ActionTypes';

export interface PushNotificationsBeaconsState {
    guideAnalyticsPushNotificationsChartData: any,
    guideAnalyticsBeaconsChartData: any,

}



const initialState = {
    guideAnalyticsPushNotificationsChartData: [],
    guideAnalyticsBeaconsChartData: []
};


const pushNotificationsBeaconsReducer = (state = initialState, action:any) => {
    switch (action.type) {
        case actionTypes.SET_PUSH_NOTIFICATIONS_CHART:
            return {
            ...state,
            guideAnalyticsPushNotificationsChartData: action.guideAnalyticsPushNotificationsChartData,
        };

        case actionTypes.SET_BEACONS_CHART:
            return {
            ...state,
            guideAnalyticsBeaconsChartData: action.guideAnalyticsBeaconsChartData,
        };

        default:
            return state;
    }
};

export default pushNotificationsBeaconsReducer;