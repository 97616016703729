import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import ReactDOM from 'react-dom';

const fileType = {
    PNG: 'image/png',
    JPEG: 'image/jpeg',
    PDF: 'application/pdf'
};



const DEFAULT_JPEG = {
    fileName: 'component.jpg',
    type: fileType.JPEG,
    html2CanvasOptions: {},
    widthDivideBy:10
};

/**
 * @param  {string} uri
 * @param  {string} filename
 */

const saveAs = (uri, filename) => {
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
        link.href = uri;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
        window.open(uri);
    }
};

/**
 * @param  {React.RefObject} node
 * @param  {string} fileName
 * @param  {string} type
 * @param  {object} html2CanvasOptions={}
 */



const exportComponent = (node, {
    fileName,
    type,
    html2CanvasOptions,
    pdfOptions,
    widthDivideBy
}) => {
    if (!node.current) {
        throw new Error("'node' must be a RefObject")
    }
    const element = ReactDOM.findDOMNode(node.current);

    return html2canvas(element, {
        scrollY: -window.scrollY,
        useCORS: true,
        ...html2CanvasOptions
    }).then(canvas => {
        if (type === fileType.JPEG) {
            var imgData = canvas.toDataURL(type, 1.0);

            var img = new Image();
            img.src = imgData;

            var imgSize;
            img.onload = function () {
                imgSize = {
                    w: 200,
                    h: img.height
                };

                let pdf_width = (imgSize.w / widthDivideBy) <= 200 ? 200 : (imgSize.w / widthDivideBy);
                let pdf_height = (imgSize.h / 10) <= 200 ? 250 : imgSize.h / 10;

                var doc = new JsPDF('p', 'mm', [pdf_width + 30, pdf_height + 10]);  // optional parameters
                doc.addImage(img, 'JPEG', 15, 0, pdf_width, pdf_height);
                doc.save(fileName);
            };
        }
    });
};

/**
 * @param  {React.RefObject} node
 * @param  {string} fileName='component.jpeg'
 * @param  {string} type=fileType.JPEG
 * @param  {object} html2CanvasOptions={}
 */
const exportComponentAsJPEG = (node, parameters = {}) => exportComponent(node, { ...DEFAULT_JPEG, ...parameters });

export {
    exportComponentAsJPEG
};