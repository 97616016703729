// @author: Mrunal Mane
// Created on: 2023-05-07 10:35 AM
// Description: Action file for Task List component.

// @author: Mohit Tiwari
// Updated on: 2023-05-13 13:35
// Description: Task List Detail component action added.


import * as ActionTypes from '../ActionTypes';
import axiosInstance, { getInstance } from '../../../network/apis/interceptor';
import { CommonUtils } from '../../../utils/CommonUtils';
import { ApiRelativePaths, RetryApi } from '../../../utils/GlobalConstants';


let ApiPaths: any = ApiRelativePaths;


export const setTaskListChartData = (taskListChartData: any) => {
    return {
        type: ActionTypes.SET_TASK_LIST_CHART,
        taskListChartData: taskListChartData
    }
};

export const setTaskListSummaryData = (taskListSummaryData: any) => {
    return {
        type: ActionTypes.SET_TASK_LIST_SUMMARY,
        taskListSummaryData: taskListSummaryData
    }
};

export const setTaskListDetailGraphData = (taskListDetailGraphData: any) => {
    return {
        type: ActionTypes.SET_TASK_LIST_DETAIL_GRAPH,
        taskListDetailGraphData: taskListDetailGraphData
    }
};

export const setTaskListDetailGuidePlayData = (taskListDetailGuidePlayData: any) => {
    return {
        type: ActionTypes.SET_TASK_LIST_DETAIL_GUIDE_PLAY,
        taskListDetailGuidePlayData: taskListDetailGuidePlayData
    }
};

export const setTaskListDetailUserEngagementSummaryData = (taskListDetailUserEngagementSummaryData: any) => {
    return {
        type: ActionTypes.SET_TASK_LIST_DETAIL_USER_ENGAGEMENT_SUMMARY,
        taskListDetailUserEngagementSummaryData: taskListDetailUserEngagementSummaryData
    }
};

export const getTaskListData = (params: any, url_path: any, caseType: any, apiErrorFlag: string, firstTimeload?: boolean) => {
    return new Promise((resolve, reject) => {
        let path = ApiPaths[url_path];
        let data: any = {};
        let paramObj = {};
        let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
        getInstance('guide-insights/task-list/');


        axiosInstance.get(generatedURL)
            .then(res => {
                data = {
                    "result": "success",
                    "data": res.data.data,
                    "firstTimeload": firstTimeload ?? false,
                    "requestUUID": res.data.requestUUID,
                };
            })
            .catch(err => {
                if (apiErrorFlag === '') {
                    data = {
                        "result": "retry",
                        "data": err,
                        "firstTimeload": firstTimeload ?? false,
                    };
                } else {
                    data = {
                        "result": "error",
                        "data": err,
                        "firstTimeload": firstTimeload ?? false,
                    };
                }
            })
            .then(() => {
                const callActionDispatch = () => {
                    switch (caseType) {
                        case ActionTypes.SET_TASK_LIST_CHART:
                            resolve(setTaskListChartData(data));
                            break;

                        case ActionTypes.SET_TASK_LIST_SUMMARY:
                            resolve(setTaskListSummaryData(data));
                            break;

                        default:
                            break;
                    }
                }


                if (data.result === 'retry') {
                    setTimeout(() => {
                        callActionDispatch();
                    }, RetryApi.TIMEOUT);
                } else {
                    callActionDispatch();
                }

            });
    });
}

export const getTaskListDetailData = (params: any, url_path: any, caseType: any, apiErrorFlag: string, firstTimeload?: boolean) => {
    return new Promise((resolve, reject) => {
        let path = ApiPaths[url_path];
        let data: any = {};
        let paramObj = {};
        let generatedURL = CommonUtils.URLGenerator(params, path, paramObj);
        getInstance('guide-insights/task-list-detail/');


        try {
            axiosInstance.get(generatedURL)
                .then(res => {
                    data = {
                        "result": "success",
                        "data": res.data.data,
                        "firstTimeload": firstTimeload ?? false,
                        "requestUUID": res.data.requestUUID,
                    };
                })
                .catch(err => {
                    if (apiErrorFlag === '') {
                        data = {
                            "result": "retry",
                            "data": err,
                            "firstTimeload": firstTimeload ?? false,
                        };
                    } else {
                        data = {
                            "result": "error",
                            "data": err,
                            "firstTimeload": firstTimeload ?? false,
                        };
                    }
                })
                .then(() => {
                    const callActionDispatch = () => {
                        switch (caseType) {
                            case ActionTypes.SET_TASK_LIST_DETAIL_GRAPH:
                                resolve(setTaskListDetailGraphData(data));
                                break;

                            case ActionTypes.SET_TASK_LIST_DETAIL_GUIDE_PLAY:
                                resolve(setTaskListDetailGuidePlayData(data));
                                break;

                            case ActionTypes.SET_TASK_LIST_DETAIL_USER_ENGAGEMENT_SUMMARY:
                                resolve(setTaskListDetailUserEngagementSummaryData(data))
                                break;

                            default:
                                break;
                        }
                    }

                    if (data.result === 'retry') {
                        setTimeout(() => {
                            callActionDispatch();
                        }, RetryApi.TIMEOUT);
                    } else {
                        callActionDispatch();
                    }

                });
        } catch (err) {
            console.log("error while api call ", err);
        }
    });
}