import React from "react";
import { useState, useEffect } from "react";
import "./SectionHeader.scss";

import { useSelector } from "react-redux";
import { LanguageState } from "store/Reducers";

import { InsightLabels } from "labels";

interface CurrentState {
    languageData: LanguageState
}

const SectionHeader = (props: any) => {
    let fetched_details = useSelector((state: CurrentState) => state);
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);

    useEffect(() => {
        if(fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }
     
    },[fetched_details.languageData.languageData])

    return (
        <div className="displayFlex col-sm-6 col-md-6 col-lg-6 no-padding-left" data-html2canvas-ignore="true">
            <ol className="breadcrumbText">
                <span className="lightgreyTxt">{insightsLbls.youAreHere}: &nbsp;</span>
                {props.children}
            </ol>
        </div>

    )
}

export default SectionHeader;